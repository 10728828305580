import React, { useEffect } from "react";
import AnalogWatch from "../AnalogWatch";
import DigitalWatch from "../DigitalWatch";
import { useSelector } from "react-redux";

const ObjelerTop = (props) => {
  const {
    soket,
    lightColorIndex,
    analogWatch,
    digitalWatch,
    yataySensor,
    dikeySensor,
    makeupMirror,
    isiSensor,
    makeupMirrorSensorPos,
    makeupMirrorSensor,
    yataySensorPos,
    dikeySensorPos,
    soketPos,
    watchPos,
    isiSensorPos,
    ciftSensorPos,
    mirrorWidth,
    mirrorHeight,
  } = useSelector((state) => state.mirror);

  const watchLeftTop = (digitalWatch || analogWatch) && watchPos == 3;
  const watchMidTop = (digitalWatch || analogWatch) && watchPos == 4;
  const watchRightTop = (digitalWatch || analogWatch) && watchPos == 5;

  const watchHorizontalPosition = () => {
    switch (true) {
      case watchLeftTop:
        return 0.13 - mirrorWidth * 0.0013 + mirrorHeight * 0.0001;
      case watchMidTop:
        return 0;
      case watchRightTop:
        return -0.13 + mirrorWidth * 0.0013 - mirrorHeight * 0.0001;
    }
  };

  useEffect(() => {
    watchHorizontalPosition();
  }, [analogWatch, digitalWatch, watchPos]);

  return (
    <group position={[watchHorizontalPosition(), -0.3 + mirrorHeight * 0.0017 - mirrorWidth * 0.00006, 0.1]} {...props} scale={0.75 + mirrorWidth * 0.00075 + mirrorHeight * 0.00075}>
      {analogWatch && (watchRightTop || watchLeftTop || watchMidTop) && <AnalogWatch position={[0, 0.05, 0.01]} />}
      {digitalWatch && (watchRightTop || watchLeftTop || watchMidTop) && <DigitalWatch position={[0, 0.05, 0.01]} />}
    </group>
  );
};

export default ObjelerTop;
