import { useGLTF } from "@react-three/drei";
import React from "react";
import { useSelector } from "react-redux";

const ColumbuMirror = ({ type }) => {
  const { nodes, materials } = useGLTF("/newModels/columbus.glb");
  const { nodes: columbusNodes, materials: columbusMaterials } = useGLTF("/columbus.glb");
  const { lightColor } = useSelector((state) => state.cabinetMirror);

  return (
    <>
      {type === "first" && (
        <group rotation-z={Math.PI}>
          <mesh geometry={nodes.leftDoorRight_1.geometry} material={materials["ledlightTexture.003"]}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
          <mesh geometry={nodes.leftDoorRight_2.geometry} material={materials.mirror} />
          <mesh geometry={nodes.leftDoorRight_3.geometry} material={materials.mirror} />
          {/* <mesh geometry={nodes.leftDoorRight_4.geometry} material={materials["aluminum.001"]} /> */}
        </group>
      )}
      {type === "mid" && (
        <group scale={[0.815, 0.38, 1]}>
          <mesh geometry={columbusNodes["2"].geometry} material={columbusMaterials.mirror} />
          <mesh geometry={columbusNodes["2_1"].geometry} material={columbusMaterials.ledlightTexture}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
          <mesh geometry={columbusNodes["2_2"].geometry} material={columbusMaterials.mirror} />
        </group>
      )}
      {type === "last" && (
        <group>
          <mesh geometry={nodes.leftDoorLeft_1.geometry} material={materials["ledlightTexture.003"]}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
          <mesh geometry={nodes.leftDoorLeft_2.geometry} material={materials.mirror} />
          <mesh geometry={nodes.leftDoorLeft_3.geometry} material={materials.mirror} />
          {/* <mesh geometry={nodes.leftDoorLeft_4.geometry} material={materials["aluminum.001"]} /> */}
        </group>
      )}
      {type === "single" && (
        <>
          {/* FIRST */}
          <group scale-x={0.5} rotation-z={Math.PI} position-x={0.25}>
            <mesh geometry={nodes.leftDoorRight_1.geometry} material={materials["ledlightTexture.003"]}>
              <meshBasicMaterial toneMapped={false} color={lightColor} />
            </mesh>
            <mesh geometry={nodes.leftDoorRight_2.geometry} material={materials.mirror} />
            <mesh geometry={nodes.leftDoorRight_3.geometry} material={materials.mirror} />
          </group>
          {/* LAST */}
          <group scale-x={0.5} position-x={0}>
            <mesh geometry={nodes.leftDoorLeft_1.geometry} material={materials["ledlightTexture.003"]}>
              <meshBasicMaterial toneMapped={false} color={lightColor} />
            </mesh>
            <mesh geometry={nodes.leftDoorLeft_2.geometry} material={materials.mirror} />
            <mesh geometry={nodes.leftDoorLeft_3.geometry} material={materials.mirror} />
          </group>
        </>
      )}
    </>
  );
};

export default ColumbuMirror;
