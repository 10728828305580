import React, { useRef, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";
import OvalMirrorTop from "./components/OvalMirrorTop";
import OvalMirrorBottom from "./components/OvalMirrorBottom";

const F610L4K = () => {
  const { lightColor, mirrorHeight, mirrorWidth } = useSelector((state) => state.mirror);
  const { nodes, materials } = useGLTF("/rectangle-mirror-rev01.glb");
  const ovalMerkez = useRef(null);
  const scaleMultiplier = 0.6;

  useEffect(() => {
    if (ovalMerkez.current) {
      ovalMerkez.current.scale.z = 1.4 + mirrorWidth * 0.0032;
      ovalMerkez.current.scale.x = 0.1 + mirrorHeight * 0.00152;
    }
  }, [mirrorHeight, mirrorWidth]);

  return (
    <>
      <group>
        <OvalMirrorBottom />
        <OvalMirrorTop />
      </group>

      <mesh
        ref={ovalMerkez}
        geometry={nodes.Miami.geometry}
        material={materials.ledlightTexture}
        position={[0.015, 0.425, 0.0085]}
        rotation={[Math.PI / 2, Math.PI * 0.5, 0]}
        scale={[1 * scaleMultiplier, 1, 1.02]}
      >
        <mesh geometry={nodes.New_Jersey_2.geometry} position-y={-0.0054} position-z={-0.004} scale-x={1} scale-z={0.938} scale-y={1.9} renderOrder={-1} material={materials.mirror} />
        <meshBasicMaterial color={lightColor} toneMapped={false} />
      </mesh>
    </>
  );
};

export default F610L4K;
