import { useGLTF, useTexture, Effects } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";

const RightHinge = () => {
  const { nodes, materials } = useGLTF("/Frankfurt-test2.glb");
  const { cabinetWidth } = useSelector((state) => state.cabinet);

  return (
    <mesh
      name="hingeBodyLeft"
      castShadow
      receiveShadow
      geometry={nodes.hingeBodyRight.geometry}
      material={materials.aluminum}
      position={[1.32 + cabinetWidth * 0.000005, 1.47, 0.06]}
      scale={[cabinetWidth == 400 ? 1 : 1 - cabinetWidth * 0.00008, 1, 1]}
    />
  );
};

export default RightHinge;
