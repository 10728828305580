import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function TempSensor(props) {
  const { nodes, materials } = useGLTF("/ButtonMist.glb");
  return (
    <group {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.buttonMist.geometry} material={materials.light}>
        {/* <meshBasicMaterial color={[100, 100, 100]} toneMapped={false} /> */}
      </mesh>
    </group>
  );
}

useGLTF.preload("/ButtonMist.glb");
