import React, { useState } from "react";
import { Stack, Slider, Typography, TextField } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setCamCikintiAlt } from "../../../store/cabinet";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const CamCikintiAlt = () => {
  const dispatch = useDispatch();
  const { camCikintiAlt } = useSelector((state) => state.cabinet);
  const [sliderValue, setSliderValue] = useState(0);

  const handleSliderChange = (e) => {
    setSliderValue(e.target.value);
  };
  const handleBlur = () => {
    dispatch(setCamCikintiAlt(sliderValue));
  };



  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="21">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Glasüberstand (unten)
        </Typography>
      </RenderModal>
      <input name="id[42]" value={sliderValue / 5 + 444} />


      <Slider
        sx={{
          ml: 3,
          ".MuiSlider-thumb": {
            minWidth: "50px",
            borderRadius: "10px",
          },
        }}
        onChange={handleSliderChange}
        onMouseUp={handleBlur}
        onChangeCommitted={handleBlur}
        size="medium"
        defaultValue={camCikintiAlt}
        min={0}
        max={50}
        step={5}
        valueLabelDisplay="auto"
        value={sliderValue}
      />
    </Stack>
  );
};

export default CamCikintiAlt;
