import React, { useEffect } from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeMakeUpMirrorSensorPos } from "../../../store/mirrors";
import { EnumMirrors } from "../../../store/mirrors";

const MakyajAynasiSensorPos = () => {
  const dispatch = useDispatch();
  const { makeupMirrorSensorPos, selectedMirror } = useSelector((state) => state.mirror);
  const isDaire = selectedMirror == EnumMirrors.CHARON || selectedMirror == EnumMirrors.DIANA || selectedMirror == EnumMirrors.HELENA;
  useEffect(() => {
    dispatch(changeMakeUpMirrorSensorPos(5));
  }, []);
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Position Lichtschalter
      </Typography>

      {isDaire ? (
        <>
          <input
            name="id[126]"
            value={
              makeupMirrorSensorPos == 0
                ? 1041
                : makeupMirrorSensorPos == 1
                ? 10055
                : makeupMirrorSensorPos == 2
                ? 10056
                : makeupMirrorSensorPos == 3
                ? 10057
                : makeupMirrorSensorPos == 4
                ? 10058
                : makeupMirrorSensorPos == 5
                ? 10059
                : makeupMirrorSensorPos == 6
                ? 10060
                : makeupMirrorSensorPos == 7
                ? 10061
                : makeupMirrorSensorPos == 7
                ? 10062
                : null
            }
          />

          <Select
            onChange={(e) => {
              const value = e.target.value;
              dispatch(changeMakeUpMirrorSensorPos(value));
            }}
            value={makeupMirrorSensorPos}
            sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
          >
            <MenuItem value={0}>---</MenuItem>
            <MenuItem value={1}>1:30 Uhr (rechts oben)</MenuItem>
            <MenuItem value={2}>3:00 Uhr (rechts)</MenuItem>
            <MenuItem value={3}>4:30 Uhr (rechts unten)</MenuItem>
            <MenuItem value={4}>6:00 Uhr (unten mittig)</MenuItem>
            <MenuItem value={5}>7:30 Uhr (links unten)</MenuItem>
            <MenuItem value={6}>9:00 Uhr (links)</MenuItem>
            <MenuItem value={7}>10:30 Uhr (links oben)</MenuItem>
            <MenuItem value={8}>12:00 Uhr (oben)</MenuItem>
          </Select>
        </>
      ) : !isDaire ? (
        <>
          <input name="id[127]" value={makeupMirrorSensorPos == 5 ? 1047 : makeupMirrorSensorPos == 4 ? 1048 : makeupMirrorSensorPos == 3 ? 1049 : null} />
          <Select
            onChange={(e) => {
              const value = e.target.value;
              dispatch(changeMakeUpMirrorSensorPos(value));
            }}
            value={makeupMirrorSensorPos}
            sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
          >
            {/* <MenuItem value={0}>---</MenuItem> */}
            <MenuItem value={5}>unten links</MenuItem>
            <MenuItem value={4}>unten mitte</MenuItem>
            <MenuItem value={3}>unten rechts</MenuItem>
          </Select>
        </>
      ) : (
        ""
      )}
    </Stack>
  );
};

export default MakyajAynasiSensorPos;
