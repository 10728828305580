import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenAllDoors } from "../../../../store/cabinetMirror";
import { useMediaQuery } from "@mui/material";

const OpenAllDoors = ({ isMenuOpen }) => {
  const dispatch = useDispatch();
  const { openAllDoors } = useSelector((state) => state.cabinetMirror);

  const desktopMatches1200 = useMediaQuery("(min-width:1200px)");

  const classnameForPosition = desktopMatches1200 ? "right-[30vw]" : "right-2";

  const classNameForZIndex = !desktopMatches1200 && isMenuOpen ? "z-0" : "z-50";

  return (
    <button
      onClick={() => dispatch(setOpenAllDoors())}
      data-counter="0"
      className={`${
        openAllDoors === true ? "bg-black" : "bg-white"
      } flex items-center justify-center w-10 h-10 rounded-full bg-cover overflow-hidden outline-none cursor-pointer border-2 border-[#edf0f0] control-btn absolute ${classnameForPosition} top-40 ${classNameForZIndex}`}
    >
      <svg className="outline-none" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" svg-inline="" role="presentation" focusable="false" tabindex="-1">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 2h17v15l-8 2.5V17H2V2zm.944 7.26c1.23.828 2.924 1.42 4.859 1.67l-1.821 1.373.677.899 3.444-2.595-2.596-3.444-.898.677 1.503 1.995c-2.257-.266-4.114-1.026-5.168-2V3h15.04L11 5.183V16H2.944V9.26zM12 18.213V6l6-2V16.28l-6 1.932z"
          fill={openAllDoors === true ? "white" : "black"}
        ></path>
      </svg>
    </button>
  );
};

export default OpenAllDoors;
