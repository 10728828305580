import { Html } from "@react-three/drei";
import { useRef } from "react";

import { useSelector } from "react-redux";

const CentimeterDepthLines = ({ position, lineWidth, lineLeft, textTop, textValue, textPadding, textWidth, textSize = "1.4em", textHeight = "35px", scale = 0.12, textPos = [0, 0.9, 0.03] }) => {
  return (
    <mesh position={position}>
      <Html transform={true} position={[0.146, 0.9, 0.02]} scale={scale} rotation={[0, -Math.PI * 0.5, 0]}>
        <div>
          <div
            className="bg-black"
            style={{
              width: lineWidth,
              position: "absolute",
              left: lineLeft,
              height: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <div className="w-0.5 h-3 bg-black" />
            <div className="w-0.5 h-3 bg-black" />
          </div>
        </div>
      </Html>
      <Html scale={[0.1, 0.1, 0.1]} rotation={[0, -Math.PI * 0.5, 0]} transform position={textPos}>
        <div
          style={{
            width: textWidth,
            height: textHeight,
            top: textTop,

            backgroundColor: "rgba(0, 0, 0, 0.9)",
            color: "#fff",
            borderRadius: 100,
            display: "flex",
            padding: textPadding,
          }}
        >
          <span
            style={{
              fontSize: textSize,
            }}
          >
            {textValue}
          </span>
        </div>
      </Html>
    </mesh>
  );
};

export default CentimeterDepthLines;
