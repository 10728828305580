import { useGLTF } from "@react-three/drei";
import React from "react";
import { useSelector } from "react-redux";

const DresdenMirror = ({ type }) => {
  const { nodes, materials } = useGLTF("/newModels/dresden.glb");
  const { nodes: dresdenNodes } = useGLTF("/dresden.glb");
  const { lightColor } = useSelector((state) => state.cabinetMirror);

  return (
    <group rotation-z={Math.PI}>
      <mesh geometry={nodes.leftDoorRight_1.geometry} material={materials.glassshelf} />
      <mesh geometry={nodes.leftDoorRight_2.geometry} material={materials["mirror.004"]} />
      <mesh geometry={nodes.leftDoorRight_3.geometry} material={materials["mirror.004"]} />
      {type !== "mid" && type !== "single" && (
        <mesh
          rotation-x={type === "last" ? Math.PI * 0.5 : 0}
          position-x={type === "last" ? -0.2 : 0}
          position-z={type === "last" ? 0.01 : 0}
          geometry={type === "first" ? nodes.leftDoorRight_4.geometry : dresdenNodes.ayna_kapak_kapalı_005_3.geometry}
          material={materials.ledtexture}
        >
          <meshBasicMaterial toneMapped={false} color={lightColor} />
        </mesh>
      )}
      {type === "single" && (
        <>
          <mesh scale-x={0.5} rotation-x={0} position-x={-0.25} position-z={0} geometry={nodes.leftDoorRight_4.geometry} material={materials.ledtexture}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
          <mesh scale-x={0.5} rotation-x={Math.PI * 0.5} position-x={-0.1} position-z={0.005} geometry={dresdenNodes.ayna_kapak_kapalı_005_3.geometry} material={materials.ledtexture}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
        </>
      )}
    </group>
  );
};

export default DresdenMirror;
