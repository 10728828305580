import { Stack, Typography, Button, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector, useDispatch } from "react-redux";
import { setOrderAmount } from "../../../store/cabinet";
const AynaAdedi = () => {
  const { orderAmount } = useSelector((state) => state.cabinet);
  const dispatch = useDispatch();
  const handleInc = () => {
    dispatch(setOrderAmount(orderAmount + 1));
  };
  const handleDec = () => {
    orderAmount > 1 && dispatch(setOrderAmount(orderAmount - 1));
  };
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Stack>
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Menge
        </Typography>
      </Stack>
      <input name="products_qty" value={orderAmount} />
      <Stack
        spacing={1}
        direction="row"
        sx={{
          flexGrow: "1",
          fontSize: "0.7rem !important",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Button disabled={orderAmount > 1 ? false : true} variant="contained" onClick={handleDec}>
          <RemoveIcon fontSize="1rem" />
        </Button>
        <Box
          sx={{
            width: "2rem",
            height: "2.25rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "500",
            fontSize: "1.2rem",
            color: "rgb(10,10,10)",
          }}
        >
          {orderAmount}
        </Box>
        <Button variant="contained" onClick={handleInc}>
          <AddIcon fontSize="1rem" />
        </Button>
      </Stack>
    </Stack>
  );
};

export default AynaAdedi;
