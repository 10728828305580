import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setHasMakeupMirror } from "../../../../store/cabinetMirror";
import { setMakeupMirrorFiyatı } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const MakyajAynası = () => {
  const dispatch = useDispatch();
  const { hasMakeupMirror } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const handleChange = (e) => {
    dispatch(setHasMakeupMirror(e.target.value));
    dispatch(setMakeupMirrorFiyatı(e.target.value === true ? 59 : 0));
  };
  useEffect(() => {
    dispatch(setHasMakeupMirror(false));
    dispatch(setMakeupMirrorFiyatı(0));
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="26">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Schminkspiegel
        </Typography>
      </RenderModal>
      <input name="id[66]" value={hasMakeupMirror ? 589 : 587} />
      <Select onChange={handleChange} value={hasMakeupMirror} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={false}>---</MenuItem>
        <MenuItem value={true}>5-fach Vergrößerung, 152 mm, unbeleuchtet + 59,00 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default MakyajAynası;
