import React from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSoketPos, EnumMirrors } from "../../../store/mirrors";

const SoketPozisyon = () => {
  const dispatch = useDispatch();
  const { soket, soketPos, selectedMirror } = useSelector((state) => state.mirror);
  const isDaire = selectedMirror == EnumMirrors.CHARON || selectedMirror == EnumMirrors.DIANA || selectedMirror == EnumMirrors.HELENA;

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Position Steckdose
      </Typography>
      <input name="id[93]" value={soketPos == 0 ? 770 : soketPos == 1 ? 769 : soketPos == 2 ? 771 : null} />
      {isDaire ? (
        <Select onChange={(e) => dispatch(setSoketPos(e.target.value))} value={soketPos} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
          <MenuItem value={5}>1:30 Uhr (rechts oben)</MenuItem>
          <MenuItem value={6}>3:00 Uhr (rechts)</MenuItem>
          <MenuItem value={2}>4:30 Uhr (rechts unten)</MenuItem>
          <MenuItem value={1}>6:00 Uhr (unten mittig)</MenuItem>
          <MenuItem value={0}>7:30 Uhr (links unten)</MenuItem>
          <MenuItem value={7}>9:00 Uhr (links)</MenuItem>
          <MenuItem value={3}>10:30 Uhr (links oben)</MenuItem>
          <MenuItem value={4}>12:00 Uhr (oben)</MenuItem>
        </Select>
      ) : (
        <Select onChange={(e) => dispatch(setSoketPos(e.target.value))} value={soketPos} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
          <MenuItem value={0}>links</MenuItem>
          <MenuItem value={1}>rechts</MenuItem>
          {soket > 2 && <MenuItem value={2}>1 x links + 1 x rechts</MenuItem>}
        </Select>
      )}
    </Stack>
  );
};

export default SoketPozisyon;
