import React from "react";
import { useGLTF } from "@react-three/drei";

const DoubleSensor = (props) => {
  const { nodes, materials } = useGLTF("/rectangle-mirror-rev01.glb");

  return (
    <group {...props}>
      <mesh geometry={nodes["touch-led"].geometry} material={materials.touchdimmertexture} position={[-0.03, 0.175, 0.1]} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes["touch-led"].geometry} material={materials.touchdimmertexture} position={[0, 0.175, 0.1]} rotation={[Math.PI / 2, 0, 0]} />
    </group>
  );
};

export default DoubleSensor;
