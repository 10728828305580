import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";
import { EnumMirrors } from "../../store/mirrors";

export function Plug(props) {
  const { nodes, materials } = useGLTF("/newModels/plugForMirrors.glb");
  const { mirrorWidth, mirrorHeight, selectedMirror } = useSelector((state) => state.mirror);
  const isDaire = [EnumMirrors.CHARON].includes(selectedMirror);

  return (
    <group scale={1} {...props} dispose={null}>
      <group name="Steckdose_schwarz" rotation={[0, Math.PI * 0.5, 0]} position={[0.01, 0.01, -0.01]}>
        <mesh name="Mesh" geometry={nodes.Mesh.geometry} material-color={props.color} material={materials["Material #1"]} />
        <mesh name="Mesh_1" geometry={nodes.Mesh_1.geometry} material-color={props.color} material={materials["02 - Defaultf"]} />
        <mesh name="Mesh_2" geometry={nodes.Mesh_2.geometry} material-color={props.color} material={materials["Chrome Clean"]} />
      </group>
    </group>
  );
}

useGLTF.preload("/plugForMirrors.glb");
