import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useMediaQuery } from "@mui/material";
import StraightenIcon from "@mui/icons-material/Straighten";
import { useDispatch } from "react-redux";
import { changeDepthCmVisible, changeHeightCmVisible, changeWidthCmVisible } from "../../../../store/cabinetMirror";

const CmButton = ({ isMenuOpen }) => {
  const dispatch = useDispatch();

  const desktopMatches1200 = useMediaQuery("(min-width:1200px)");

  const classnameForPosition = desktopMatches1200 ? "right-[30vw]" : "right-2";

  const classNameForZIndex = !desktopMatches1200 && isMenuOpen ? "z-0" : "z-50";

  return (
    <button
      id="cmButton-2"
      // onClick={() => setShown(!shown)}
      onClick={() => {
        dispatch(changeHeightCmVisible(true));
        dispatch(changeWidthCmVisible(true));
        dispatch(changeDepthCmVisible(true));
      }}
      data-counter="0"
      className={` bg-white flex items-center justify-center w-10 h-10 rounded-full bg-cover overflow-hidden outline-none cursor-pointer border-2 border-[#edf0f0] control-btn absolute ${classnameForPosition} top-28 ${classNameForZIndex}`}
    >
      <StraightenIcon />
    </button>
  );
};

export default CmButton;
