import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PaintedMirrors from "./Mirrors/PaintedMirrors";
import { Man2 } from "../OrtakComponents/Man2";
import { Kare } from "./Mirrors/Kare";
import { useThree } from "@react-three/fiber";
import { EnumMirrors } from "../../store/mirrors";

const KareAyna = () => {
  const { selectedMirror } = useSelector((state) => state.mirror);
  const [showPandora, setShowPandora] = useState();
  const [showPandoraLight, setShowPandoraLight] = useState();

  const showPandoraHandler = (info) => {
    setShowPandora(info);
  };

  const pandoraLightHandler = (info) => {
    setShowPandoraLight(info);
  };
  const { mirrorWidth, mirrorHeight } = useSelector((state) => state.mirror);
  const manHeight = useSelector((state) => state.utils.selectedHeightLevel);
  const isPaintedMirror = [EnumMirrors.F620TL, EnumMirrors.F621TL, EnumMirrors.F622TL, EnumMirrors.F623TL].includes(selectedMirror);

  return (
    <>
      {/* <Kare pandoraLightHandler={pandoraLightHandler} showPandoraHandler={showPandoraHandler} /> */}

      {isPaintedMirror ? <PaintedMirrors /> : <Kare showPandoraHandler={showPandoraHandler} pandoraLightHandler={pandoraLightHandler} />}
      {/* <Kristallspiegel /> */}
      <Man2 position={[(mirrorWidth > 2000 ? -4.2 : mirrorWidth > 1600 ? -3.65 : -3.12) - mirrorWidth * 0.0008, -3.5, -0.2]} scale={25 + manHeight} manHeight={manHeight} />
    </>
  );
};

export default KareAyna;
