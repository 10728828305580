import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";
import * as THREE from "three";
export default function OvalMirrorBottom(props) {
  const [scale, setScale] = useState([1.5, 1, 1]);
  const { mirrorWidth, mirrorHeight, lightColor } = useSelector((state) => state.mirror);
  const { nodes } = useGLTF("/newMirrorModels/F610L4K.glb");
  const { materials } = useGLTF("/rectangle-mirror-rev01.glb");

  const bottom = useRef(null);

  return (
    <group ref={bottom} rotation-y={Math.PI} scale={[0.991 + mirrorWidth * 0.0022576, 1.27, 1.27]} position={[-0.006 - mirrorWidth * 0.000046, -1.295 - mirrorHeight * 0.001, -0.0095]}>
      <mesh
        name="mirrorLower"
        geometry={nodes.mirrorLower.geometry}
        material={materials.mirror}
        scale={[1, mirrorWidth > 1000 ? 0.85 : 0.95, 1]}
        position={[-0.015, mirrorWidth > 1000 ? 1.065 : 1.037, -0.0145]}
      />
      <mesh name="ledLightLower" geometry={nodes.ledLightLower.geometry} material={materials.led} scale-x={1.0015} position={[-0.0136, 1.008, -0.014]}>
        <meshStandardMaterial toneMapped={false} color={lightColor} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/F610L4K.glb");
