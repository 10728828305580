import { useGLTF } from "@react-three/drei";
import React from "react";
import { useSelector } from "react-redux";

export const LeftHinge = () => {
  const { nodes, materials } = useGLTF("/Frankfurt-test2.glb");
  const { cabinetMirrorWidth, cabinetMirrorDepth } = useSelector((state) => state.cabinetMirror);

  return (
    <mesh
      name="hingeBodyLeft"
      castShadow
      receiveShadow
      geometry={nodes.hingeBodyLeft.geometry}
      material={materials.aluminum}
      position={[-0.22 - cabinetMirrorWidth * 0.000007, 0, 0.11 + cabinetMirrorDepth * 0.000025]}
      scale={[cabinetMirrorWidth == 400 ? 1 : 1 - cabinetMirrorWidth * 0.00008, 1, cabinetMirrorDepth == 400 ? 1 : 1 - cabinetMirrorDepth * 0.0003]}
    />
  );
};
