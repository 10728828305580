import React from "react";
import { useDispatch } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";

const MenuToggleButton = ({ isMenuOpen, setIsMenuOpen }) => {
  const dispatch = useDispatch();
  const desktopMatches1200 = useMediaQuery("(min-width:1200px)");
  const classnameForDisplay = isMenuOpen || desktopMatches1200 ? "z-0" : "z-50";

  return (
    <button
      onClick={() => setIsMenuOpen()}
      data-counter="0"
      className={` bg-white flex items-center justify-center w-10 h-10 rounded-full bg-cover overflow-hidden outline-none cursor-pointer border-2 border-[#edf0f0] control-btn absolute right-2 top-4 ${classnameForDisplay}`}
    >
      <MenuIcon sx={{ width: "24px", height: "24px" }} />
    </button>
  );
};

export default MenuToggleButton;
