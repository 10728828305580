import { Stack, Slider, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EnumCabinetMirrors, setMontajCevresiDerinligi, setCustomDoorWidthValues, setDoorNumber } from "../../../store/cabinetMirror";
import { useState, useEffect } from "react";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const MontajCercevesiDerinligi = () => {
  const dispatch = useDispatch();
  const { montajCevresiDerinligi, doorNumber } = useSelector((state) => state.cabinet);

  const { selectedMirror } = useSelector((state) => state.mirror);

  const [alertMessage, setAlertMessage] = useState(false);

  const MAX_VALUE = 9999999999999999999;
  const MIN_VALUE = 70;
  const DEFAULT_VALUE = montajCevresiDerinligi;

  const [input, setInput] = useState(DEFAULT_VALUE);

  useEffect(() => {
    setInput(montajCevresiDerinligi);
  }, [montajCevresiDerinligi]);

  const handleStateChange = (width) => {
    dispatch(setMontajCevresiDerinligi(Number(width)));
  };

  let debounceTimer;

  const handlemontajCevresiDerinligiChange = (e) => {
    var value = parseInt(e.target.value);
    setInput(value);
    let point = 0;
    if (!value) {
      dispatch(setMontajCevresiDerinligi(MIN_VALUE));
      setAlertMessage(true);
    } else {
      if (value > MAX_VALUE) {
        dispatch(setMontajCevresiDerinligi(MAX_VALUE));
        e.target.style.color = "tomato";
        setAlertMessage(true);

        point++;
      } else {
        point = point != 0 ? point-- : 0;
        setAlertMessage(false);
      }

      if (value < MIN_VALUE) {
        dispatch(setMontajCevresiDerinligi(MIN_VALUE));
        e.target.style.color = "tomato";
        setAlertMessage(true);

        point++;
      } else {
        point = point != 0 ? point-- : 0;
        setAlertMessage(false);
      }

      if (point == 0) {
        dispatch(setMontajCevresiDerinligi(value));
        e.target.style.color = "#000";
        setAlertMessage(false);
      } else {
        setAlertMessage(true);
      }
    }

    // if (e.target.value > MAX_VALUE) {
    //   handleStateChange(Number(MAX_VALUE));
    //   setInputValue(MAX_VALUE);
    //   setAlertMessage(true);
    // } else if (e.target.value < MIN_VALUE) {
    //   handleStateChange(Number(MIN_VALUE));
    //   setInputValue(MIN_VALUE);
    //   setAlertMessage(true);
    // } else {
    //   handleStateChange(Number(e.target.value));
    // }

    // setAlertMessage(false);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Tiefe Einbaurahmen
        </Typography>

        <input type="hidden" name="id[194]" value="2135" />
        <input type="hidden" class="form-control" name="txt_2135" value={montajCevresiDerinligi} />

        <TextField
          value={input}
          type="number"
          onChange={handlemontajCevresiDerinligiChange}
          sx={{
            height: "2.2rem",
            width: "100%",
            ".MuiInputBase-input": {
              padding: "8px 8px 8px 18px",
              fontSize: 12,
            },
          }}
        />
      </Stack>
      {alertMessage ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            position: "relative",
            top: -10,
          }}
        >
          <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem", mt: 0 }}></Typography>
          <small
            style={{
              color: "red",
            }}
          >
            Die Tiefe des Einbaurahmens muss mind. 70mm betragen.
          </small>
        </Stack>
      ) : null}
    </>
  );
};

export default MontajCercevesiDerinligi;
