import { useRef, useState, useEffect } from "react";

import * as THREE from "three";
import gsap from "gsap";
import { useGLTF, useTexture } from "@react-three/drei";

import { useSelector } from "react-redux";

import DışAydınlatma from "../../../OrtakComponents/DışAydınlatma";

import CabinetLeftDoor from "../../cabinetBodyParts/cabinetLeftDoors/CabinetLeftDoor";
import CabinetRightDoor from "../../cabinetBodyParts/cabinetRightDoors/CabinetRightDoor";

import { EnumCabinetMirrors } from "../../../../store/cabinetMirror";

import { DoorOpener, DoorHandleLeft, DoorHandleRight, CabinetShelves, LeftHinge, RightHinge, MakyajAynasi, IndividualWidth, SocketUSB, DimmerSocketUSB } from "../../cabinetBodyParts/smallParts";

export const KapakliBolme = ({ cabinetRef, doorIndex, isLeftSided, socketUsbBase, doorType, isSided }) => {
  const { nodes, materials } = useGLTF("/Frankfurt-test2.glb");
  const { nodes: budapestNodes } = useGLTF("/newModels/budapest.glb");

  const { selectedMirror } = useSelector((state) => state.mirror);
  const {
    bottomCompartmentHeight,
    cabinetMirrorType,
    doorNumber,
    externalLeds,
    draggerVisible,
    backPanel,
    hasMakeupMirror,
    bodyDecor,
    camCikintiAlt,
    camCikintiUst,
    openAllDoors,
    cabinetMirrorWidth,
    cabinetMirrorHeight,
  } = useSelector((state) => state.cabinetMirror);

  const [rightOpen, setRightOpen] = useState(false);
  const [leftOpen, setLeftOpen] = useState(false);

  const rightDoorRef = useRef();
  const leftDoorRef = useRef();
  const mainBodyRef = useRef();
  const lowerBodyRef = useRef();

  const cabinetVisible = doorNumber > doorIndex;

  const cabinetBody = useRef();
  const cabinetBackPanel = useRef();
  const cabinetBackPanelBottom = useRef();

  const bodyTexture = useTexture(`./decors/${bodyDecor == 88 ? "U763_ST9.jpeg" : bodyDecor}`);
  bodyTexture.rotation = Math.PI * 0.5;
  bodyTexture.repeat.set(0.4, 5);
  bodyTexture.wrapS = THREE.RepeatWrapping;
  bodyTexture.wrapT = THREE.RepeatWrapping;

  const isAllVisible = externalLeds === 5;

  const hasBottomShelf = [
    EnumCabinetMirrors.MUNCHEN_6,
    EnumCabinetMirrors.BUDAPEST,
    EnumCabinetMirrors.SANTANDER,
    EnumCabinetMirrors.LISSABON,
    EnumCabinetMirrors.LINZ,
    EnumCabinetMirrors.CORTINA,
    EnumCabinetMirrors.WINDSOR,
    EnumCabinetMirrors.VERONICA_2,
    EnumCabinetMirrors.PANDORA_2,
    EnumCabinetMirrors.KATHERINE_2,
    EnumCabinetMirrors.GENT,
    EnumCabinetMirrors.VENEZIA,
    EnumCabinetMirrors.VERONICA_4,
    EnumCabinetMirrors.PANDORA_4,
    EnumCabinetMirrors.KATHERINE_4,
  ].includes(selectedMirror);

  useEffect(() => {
    cabinetBody.current.material.map = bodyTexture;

    if (backPanel === 0) {
      //
      cabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#ffffff",
      });
      cabinetBackPanelBottom.current.material = new THREE.MeshBasicMaterial({
        color: "#ffffff",
      });
    } else if (backPanel === 1) {
      cabinetBackPanel.current.material = materials.mirror;
      cabinetBackPanelBottom.current.material = materials.mirror;
    } else if (backPanel === 2) {
      cabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#919191",
      });
      cabinetBackPanelBottom.current.material = new THREE.MeshBasicMaterial({
        color: "#919191",
      });
    } else if (backPanel === 3) {
      cabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#FCFF94",
      });
      cabinetBackPanelBottom.current.material = new THREE.MeshBasicMaterial({
        color: "#FCFF94",
      });
    } else if (backPanel === 4) {
      cabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#333333",
      });
      cabinetBackPanelBottom.current.material = new THREE.MeshBasicMaterial({
        color: "#333333",
      });
    }
  }, [backPanel, bodyDecor]);

  useEffect(() => {
    if (leftDoorRef.current) {
      leftDoorRef.current.scale.y = 1 + camCikintiAlt * 0.0015 + camCikintiUst * 0.0015;

      leftDoorRef.current.position.y = 0 - camCikintiAlt * 0.00036 + camCikintiUst * 0.00036;
    }
    if (rightDoorRef.current) {
      rightDoorRef.current.scale.y = 1 + camCikintiAlt * 0.0015 + camCikintiUst * 0.0015;
      rightDoorRef.current.position.y = 0 - camCikintiAlt * 0.00036 + camCikintiUst * 0.00036;
    }
  }, [camCikintiAlt, camCikintiUst]);

  useEffect(() => {
    if (isLeftSided) {
      leftDoorRef.current.visible = true;
      rightDoorRef.current.visible = false;
    } else {
      leftDoorRef.current.visible = false;
      rightDoorRef.current.visible = true;
    }
  }, [isLeftSided]);

  useEffect(() => {
    if (openAllDoors) {
      if (isLeftSided) {
        setLeftOpen(true);
        setRightOpen(false);
      } else {
        setLeftOpen(false);
        setRightOpen(true);
      }
    } else {
      setLeftOpen(false);
      setRightOpen(false);
    }
  }, [openAllDoors, isLeftSided]);

  useEffect(() => {
    if (cabinetRef.current) {
      //SCALE
      const minWidthDoor = 400;
      const currentWidthPerDoor = cabinetMirrorWidth / doorNumber;
      const scaleRatio = currentWidthPerDoor / minWidthDoor;
      cabinetRef.current.scale.x = scaleRatio * 2;
      // cabinetRef.current.position.y = doorIndex % 2 === 0 ? 0.5 : -0.5;

      //POSITION
      cabinetRef.current.position.x = scaleRatio * doorIndex;
    }
  }, [cabinetMirrorWidth, doorNumber]);

  useEffect(() => {
    if (leftOpen) {
      gsap.to(leftDoorRef.current.scale, {
        x: (cabinetMirrorWidth / doorNumber / 400) * 0.85,
      });
      gsap.to(leftDoorRef.current.rotation, { y: -Math.PI * 0.5 });
    } else {
      gsap.to(leftDoorRef.current.scale, { x: 1 });
      gsap.to(leftDoorRef.current.rotation, { y: 0 });
    }
    if (rightOpen) {
      gsap.to(rightDoorRef.current.scale, {
        x: (cabinetMirrorWidth / doorNumber / 400) * 0.85,
      });
      gsap.to(rightDoorRef.current.rotation, { y: Math.PI * 0.5 });
    } else {
      gsap.to(rightDoorRef.current.scale, { x: 1 });
      gsap.to(rightDoorRef.current.rotation, { y: 0 });
    }
  }, [openAllDoors, leftOpen, rightOpen, cabinetMirrorWidth]);

  const isSideLedVisible = isAllVisible || externalLeds === 4;
  const bottomShelfIsAllVisible = isAllVisible && hasBottomShelf;

  const IS_MUNCHEN_TYPE = [EnumCabinetMirrors.MUNCHEN_1, EnumCabinetMirrors.MUNCHEN_4, EnumCabinetMirrors.MUNCHEN_5, EnumCabinetMirrors.MUNCHEN_6].includes(selectedMirror);

  useEffect(() => {
    if (mainBodyRef.current && lowerBodyRef.current) {
      if (hasBottomShelf) {
        const bottomCompScaleDiffRatio = (bottomCompartmentHeight - 100) / 200;
        const cabinetHeightRatio = (cabinetMirrorHeight - 600) / 600;
        if (bottomCompScaleDiffRatio <= 2) {
          //--MAIN-BODY
          mainBodyRef.current.scale.y = 1 - bottomCompScaleDiffRatio * 0.18 + cabinetHeightRatio * 0.075;
          mainBodyRef.current.position.y = 0 + bottomCompScaleDiffRatio * 0.073 - cabinetHeightRatio * 0.0238;
          //--LOWER-BODY
          lowerBodyRef.current.scale.y = 1 + bottomCompScaleDiffRatio - cabinetHeightRatio * 0.3;
          lowerBodyRef.current.position.y = 0 + bottomCompScaleDiffRatio * 0.55 - cabinetHeightRatio * 0.17;
        }
      } else {
        mainBodyRef.current.position.y = 0;
        lowerBodyRef.current.position.y = 0;
      }
    }
  }, [cabinetMirrorHeight, bottomCompartmentHeight]);

  useEffect(() => {
    if (mainBodyRef.current && lowerBodyRef.current) {
      mainBodyRef.current.position.y = 0;
      lowerBodyRef.current.position.y = 0;
    }
  }, [selectedMirror]);

  return (
    <group visible={cabinetVisible} position-z={cabinetMirrorType} scale={[1, 1, 1]} ref={cabinetRef}>
      <group name="bodyParts" ref={mainBodyRef} position={[0, 0, 0]}>
        <SocketUSB base={socketUsbBase} />
        <DimmerSocketUSB base={socketUsbBase} />
        {isAllVisible && <DışAydınlatma scaleY={0.5} position={[0, 0.4, 0.065]} rotation={[Math.PI * 1.5, 0, Math.PI * 1.5]} />}
        {isAllVisible && <DışAydınlatma scaleY={0.5} position={[0, hasBottomShelf ? -0.55 : -0.4, 0.065]} rotation={[Math.PI * 0.5, 0, Math.PI * 0.5]} />}
        {isSideLedVisible && !isSided && (doorType === "first" || doorType === "single") && (
          <DışAydınlatma scaleY={bottomShelfIsAllVisible ? 0.95 : isAllVisible ? 0.8 : 0.7} position={[-0.25, bottomShelfIsAllVisible ? -0.07 : 0, 0.065]} rotation={[0, Math.PI * 1.5, 0]} />
        )}
        {isSideLedVisible && !isSided && (doorType === "last" || ((doorType === "first" || doorType === "single") && doorNumber === 1)) && (
          <DışAydınlatma scaleY={bottomShelfIsAllVisible ? 0.95 : isAllVisible ? 0.8 : 0.7} position={[0.25, bottomShelfIsAllVisible ? -0.07 : 0, 0.065]} rotation={[0, Math.PI * 0.5, 0]} />
        )}

        {cabinetVisible && <DoorOpener testCabinet={true} posX={1} posX2={0.85} openLeft={setLeftOpen} openRight={setRightOpen} rightDoor={rightOpen} leftDoor={leftOpen} isLeftSided={isLeftSided} />}

        {cabinetVisible && cabinetRef.current && draggerVisible && <IndividualWidth testCabinet={true} width={cabinetRef.current.scale.x} />}

        {/* ÜST BÖLME */}
        <mesh ref={cabinetBody} castShadow receiveShadow geometry={nodes.body.geometry} material={materials.Material} position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]} />
        <mesh ref={cabinetBackPanel} castShadow receiveShadow geometry={nodes.bodyRear.geometry} material={materials.white} position={[0, 0, -0.1]} />

        <CabinetShelves />
        {rightOpen && <RightHinge />}
        {leftOpen && <LeftHinge />}
        <group ref={leftDoorRef} position={[-0.25, 0, IS_MUNCHEN_TYPE ? 0.12 : 0.1]}>
          {doorIndex === 0 && hasMakeupMirror && <MakyajAynasi isLeftDoor={true} />}
          <DoorHandleLeft />
          <CabinetLeftDoor type={doorType} />
        </group>

        <group ref={rightDoorRef} position={[0.25, 0, IS_MUNCHEN_TYPE ? 0.12 : 0.1]} rotation={[0, 0, Math.PI]}>
          {leftDoorRef?.current?.visible === false && <DoorHandleRight />}
          {doorIndex === 0 && hasMakeupMirror && <MakyajAynasi isLeftDoor={false} />}
          <CabinetRightDoor type={doorType} />
        </group>
      </group>

      {/* ALT BÖLME */}
      <group visible={hasBottomShelf ? true : false} ref={lowerBodyRef} position={[0, 0, 0]}>
        <mesh geometry={budapestNodes.bottomShelfBody.geometry} material={materials.Material} position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]} />
        <mesh ref={cabinetBackPanelBottom} geometry={budapestNodes.bottomShelfBodyRear.geometry} material={materials.white} position={[0, -0.225, -0.1]} />
      </group>
    </group>
  );
};
