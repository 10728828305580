import { useGLTF } from "@react-three/drei";
import React from "react";

const OxfordMirror = () => {
  const { nodes, materials } = useGLTF("/newModels/oxford.glb");
  return (
    <group scale-y={0.99}>
      <mesh geometry={nodes.leftDoor_1.geometry} material={materials.glass} />
      <mesh geometry={nodes.leftDoor_2.geometry} material={materials.mirror} />
      <mesh geometry={nodes.leftDoor_3.geometry} material={materials.mirror} />
      {/* <mesh geometry={nodes.leftDoor_4.geometry} material={materials.aluminum} /> */}
    </group>
  );
};

export default OxfordMirror;
