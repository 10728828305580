import React from "react";
import { Plug } from "../../../../OrtakComponents/Plug";
import { TempSensor } from "../../../../OrtakComponents/TempSensor";
import YataySensor from "../YataySensor";
import DikeySensor from "../DikeySensor";
import AnalogWatch from "../AnalogWatch";
import DigitalWatch from "../DigitalWatch";
import Touch from "../../../../OrtakComponents/Sensor/Touch";
import DoubleSensor from "../DoubleSensor";
import { useSelector } from "react-redux";

const ObjelerMid = (props) => {
  const {
    lightColorIndex,
    makeupMirror,
    analogWatch,
    digitalWatch,
    yataySensor,
    dikeySensor,
    isiSensor,
    makeupMirrorSensorPos,
    makeupMirrorSensor,
    yataySensorPos,
    dikeySensorPos,
    watchPos,
    isiSensorPos,
    ciftSensorPos,
    mirrorHeight,
    mirrorWidth,
  } = useSelector((state) => state.mirror);

  const isiSensorCheck = (isiSensor === 2 || isiSensor === 3) && isiSensorPos == 1;
  const hasMakeupMirror = makeupMirror == 2 && makeupMirrorSensor == 2 && makeupMirrorSensorPos == 4;
  const hasDoubleSensor = lightColorIndex == 4 && ciftSensorPos == 1;
  const hasVerticalSensor = hasDoubleSensor || (yataySensor && yataySensorPos == 1) || (dikeySensor && dikeySensorPos == 1);

  const hasOnlyVerticalSensor = !hasMakeupMirror && !isiSensorCheck && hasVerticalSensor;
  const hasOnlyWatch = !hasVerticalSensor && !hasMakeupMirror && !isiSensorCheck;
  const hasOnlyMakeupMirror = !hasVerticalSensor && !isiSensorCheck && hasMakeupMirror;

  const hasOnlyIsiAndTouchSensor = hasVerticalSensor && isiSensorCheck && !hasMakeupMirror;
  const hasOnlyIsiAndMakeup = hasMakeupMirror && isiSensorCheck && !hasVerticalSensor;

  const hasOnlyTouchAndMakeup = hasVerticalSensor && hasMakeupMirror && !isiSensorCheck;

  const has_Isi_Makeup_Touch = isiSensorCheck && hasMakeupMirror && hasVerticalSensor;

  const watchHorizontalPosition = () => {
    switch (true) {
      case hasOnlyVerticalSensor:
        return 0.04;

      case hasOnlyMakeupMirror:
        return 0.08;

      case hasOnlyIsiAndTouchSensor:
        return 0.02;
      case hasOnlyIsiAndMakeup:
        return 0.04;
      case hasOnlyTouchAndMakeup:
        return 0.06;

      case has_Isi_Makeup_Touch:
        return 0.04;

      default:
        return 0;
    }
  };

  const watchVerticalPosition = hasOnlyWatch ? -0.04 : 0.05;

  return (
    <group {...props} scale={0.75 + mirrorWidth * 0.00075 + mirrorHeight * 0.00075}>
      {/* <mesh>
        <boxGeometry args={[0.3, 0.25, 0.01]} />
        <meshBasicMaterial color={"blue"} />
      </mesh> */}
      {/* OBJELER */}

      {isiSensorCheck && <TempSensor position={[0, -0.05, 0.01]} rotation={[0, 0, 0]} scale={1.2} />}

      {yataySensor && yataySensorPos == 1 && <YataySensor position={[0.04, -0.05, 0.01]} />}

      {dikeySensor && dikeySensorPos == 1 && <DikeySensor position={[0.04, -0.05, 0.01]} />}

      {analogWatch && watchPos == 1 && <AnalogWatch position={[watchHorizontalPosition(), watchVerticalPosition, 0.01]} />}

      {digitalWatch && watchPos == 1 && <DigitalWatch position={[watchHorizontalPosition(), watchVerticalPosition, 0.01]} />}

      {makeupMirror == 2 && makeupMirrorSensor == 2 && makeupMirrorSensorPos == 4 && <Touch position={[0.105, -0.05, 0.01]} scale={1.2} rotation={[Math.PI * 1.5, 0, 0]} />}

      {lightColorIndex == 4 && ciftSensorPos == 1 && <DoubleSensor position={[0.07, -0.26, -0.11]} scale={1.2} />}
    </group>
  );
};

export default ObjelerMid;
