import React, { useState, useEffect } from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeMirrorWidth, resetToInitialState, setCamRafUzunlugu } from "../../../store/mirrors";

const CamRafUzunlugu = () => {
  const { selectedMirror, camRafUzunlugu, mirrorWidth, kareAynaModel, mirrorHeight } = useSelector((state) => state.mirror);
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState(camRafUzunlugu);

  const portau = kareAynaModel === "Full_+_Kalın";

  const MAX_VALUE = portau ? mirrorWidth - 40 : mirrorWidth - 20;
  const MIN_VALUE = 200;

  useEffect(() => {
    setInputValue(MIN_VALUE);
    dispatch(setCamRafUzunlugu(MIN_VALUE));
  }, [selectedMirror]);

  useEffect(() => {
    if (inputValue > MAX_VALUE) {
      setInputValue(MAX_VALUE);
      dispatch(setCamRafUzunlugu(MAX_VALUE));
    }
    if (inputValue < MIN_VALUE) {
      setInputValue(MIN_VALUE);
      dispatch(setCamRafUzunlugu(MIN_VALUE));
    }
  }, [mirrorWidth, mirrorHeight]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Länge der Ablage in mm
      </Typography>

      <input type="hidden" name="id[88]" value="740" />
      <input type="hidden" class="form-control" name="txt_740" value={inputValue} />

      <TextField
        value={inputValue}
        type="number"
        onChange={(e) => {
          const value = Number(e.target.value);
          if (value > MAX_VALUE) {
            setInputValue(value);
            e.target.style.color = "tomato";
          } else if (value < MIN_VALUE) {
            setInputValue(value);
            e.target.style.color = "tomato";
          } else {
            e.target.style.color = "black";
            setInputValue(value);
            dispatch(setCamRafUzunlugu(value));
          }
        }}
        sx={{
          height: "2.2rem",
          width: "100%",
          ".MuiInputBase-input": {
            padding: "8px 8px 8px 18px",
            fontSize: 12,
          },
        }}
      />
    </Stack>
  );
};

export default CamRafUzunlugu;
