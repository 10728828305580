import { Stack, Slider, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeCabinetDepth } from "../../../store/cabinet";
import { useState } from "react";
import { setDerinlikFiyatı } from "../../../store/cabinetFee";

const Derinlik = () => {
  const dispatch = useDispatch();
  const { cabinetDepth } = useSelector((state) => state.cabinet);
  const [sliderValue, setSliderValue] = useState(125);

  const handleSliderChange = (e) => {
    setSliderValue(e.target.value);
  };

  const handleBlur = () => {
    dispatch(changeCabinetDepth(sliderValue));
    const diffPrice = ((Number(sliderValue) - 200) / 5) * 2.3;
    if (sliderValue > 200) {
      dispatch(setDerinlikFiyatı(diffPrice));
    } else {
      dispatch(setDerinlikFiyatı(0));
    }
  };

  const renderInputValue = () => {
    return (sliderValue - 125) / 5 + 151;
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Tiefe inkl. Front
      </Typography>

      <input name="id[17]" value={renderInputValue()} />

      <Slider
        sx={{
          ml: 3,
          ".MuiSlider-thumb": {
            minWidth: "50px",
            borderRadius: "10px",
          },
        }}
        onChange={handleSliderChange}
        onMouseUp={handleBlur}
        onChangeCommitted={handleBlur}
        size="medium"
        defaultValue={cabinetDepth}
        step={5}
        min={140}
        max={600}
        valueLabelDisplay="auto"
        value={sliderValue}
      />
    </Stack>
  );
};

export default Derinlik;
