import { Stack, Typography, Select, MenuItem } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setBackPanel } from "../../../store/cabinet";
import { setBackPanelFiyatı } from "../../../store/cabinetFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const ArkaPanel = () => {
  const dispatch = useDispatch();
  const { backPanel } = useSelector((state) => state.cabinet);

  const handleChange = (e) => {
    dispatch(setBackPanel(e.target.value));
    dispatch(setBackPanelFiyatı(e.target.value === 0 ? 0 : e.target.value === 1 ? 169 : 99));
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="17">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Rückwand
        </Typography>
      </RenderModal>

      <input name="id[175]" value={backPanel == 0 ? 2018 : backPanel == 4 ? 2019 : backPanel == 3 ? 9157 : backPanel == 2 ? 9158 : backPanel == 1 ? 2020 : null} />

      <Select onChange={handleChange} value={backPanel} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>Matt weiße Rückwand W1100 ST9</MenuItem> 2018
        <MenuItem value={4}>Schwarze Rückwand U999 ST2 leicht glänzend + 99,00 €</MenuItem> 2019
        <MenuItem value={3}>Cremebeige Rückwand U222 ST9 + 99,00 € </MenuItem> 9157
        <MenuItem value={2}>Arktisgraue Rückwand U788 ST9 + 99,00 €</MenuItem> 9158
        <MenuItem value={1}>Verspiegelte Rückwand + 169,00 €</MenuItem> 2020
      </Select>
    </Stack>
  );
};

export default ArkaPanel;
