import * as React from "react";

import { Box, Button, Typography, Stack, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Slide, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { EnumCabinets, resetToInitialState, setCamCikintiAlt, setSummaryModalOpen } from "../../store/cabinet";
import $ from "jquery";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SummaryModal() {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(setSummaryModalOpen(false));
  const {
    bodyDecor,
    backPanel,
    doorHandle,
    externalLeds,
    bluetoothSpeaker,
    hasMakeupMirror,
    socketUsb,
    orderAmount,
    summaryModalOpen,
    customerComment,
    cabinetWidth,
    cabinetHeight,
    cabinetDepth,
    cabinetType,
    borderHeight,
    pandoraLight,
    socketUsbDisarida,
    camCikintiAlt,
    camCikintiUst,
    sensor,
  } = useSelector((state) => state.cabinet);

  const { lightColorIndex, selectedMirror } = useSelector((state) => state.mirror);
  const {
    baseFee, //modelden gelen fiyatı
    ahsapZeminFiyatı,
    backPanelFiyatı,
    bluetoothSpeakerFiyatı,
    camRafFiyatı,
    cabinetFiyatı, // dolap tipinden gelen fiyat bu, modelden değil
    doorHandleFiyatı,
    doorFiyatı,
    externalLedFiyatı,
    makeupMirrorFiyatı,
    sensorFiyatı,
    ledIsikFiyatı,
    cabinetName,
    derinlikFee,
    pandoraLightFee,
    socketUsbFee,
    socketUsbDisaridaFee,
  } = useSelector((state) => state.cabinetFee);

  const dimensionFee = cabinetWidth > 1200 && cabinetHeight > 800 ? 49.99 : 0;

  const feeData = [
    {
      name: "Model Fiyatı",
      fee: baseFee,
    },
    {
      name: "Ahşap Zemin",
      fee: ahsapZeminFiyatı,
    },
    {
      name: "Rückwandl",
      fee: backPanelFiyatı,
    },
    {
      name: "Cam Raf",
      fee: camRafFiyatı,
    },
    {
      name: "Montaj Tipi",
      fee: cabinetFiyatı,
    },
    {
      name: "Led Işık",
      fee: ledIsikFiyatı,
    },
    {
      name: "Kapı Kolu",
      fee: doorHandleFiyatı,
    },
    {
      name: "Türen",
      fee: doorFiyatı,
    },
    {
      name: "Waschtisch-/ Decken-Beleuchtung",
      fee: externalLedFiyatı,
    },
    {
      name: "Schminkspiegel",
      fee: makeupMirrorFiyatı,
    },
    {
      name: "Sensor/Anahtar",
      fee: sensorFiyatı,
    },
    {
      name: "Soket USB Inside",
      fee: socketUsbFee,
    },
    {
      name: "Soket USB Outside",
      fee: socketUsbDisaridaFee,
    },
    {
      name: "Derinlik",
      fee: derinlikFee,
    },
    {
      name: "Led Light ( Per Mirror )",
      fee: pandoraLightFee,
    },
    {
      name: "Bluetooth Lautsprecher",
      fee: bluetoothSpeakerFiyatı,
    },
    {
      name: "Summe",
      fee:
        (baseFee +
          ahsapZeminFiyatı +
          backPanelFiyatı +
          bluetoothSpeakerFiyatı +
          camRafFiyatı +
          cabinetFiyatı +
          doorHandleFiyatı +
          ledIsikFiyatı +
          doorFiyatı +
          externalLedFiyatı +
          makeupMirrorFiyatı +
          sensorFiyatı +
          derinlikFee +
          dimensionFee +
          pandoraLightFee) *
        orderAmount,
    },
  ];

  const summaryData = [
    {
      name: "Spiegelschränke Typ",
      value: cabinetName,
    },
    {
      name: "Schrank Art",
      value:
        cabinetType === -0.15
          ? "Unterputz / Einbau"
          : cabinetType === 0
          ? "Aufputz / Hängeschrank"
          : cabinetType === 0.2
          ? "Unterputz mit Einbaurahmen"
          : cabinetType === -0.08
          ? "Halb-Einbau mit Einbaurahmen"
          : "---",
    },
    {
      name: "Spiegelschränke Maße",
      value: cabinetWidth + "mm" + "*" + cabinetHeight + "mm" + "*" + cabinetDepth + " mm",
    },

    {
      name: "Dekor Korpus",
      value:
        bodyDecor === 0
          ? " Tossini Dark Brown Elm"
          : bodyDecor === 1
          ? "Pietra Grigia Black"
          : bodyDecor === 2
          ? "Cefalu concrete light"
          : bodyDecor === 3
          ? "Bavaria Beech"
          : bodyDecor === 4
          ? "Sand Whiteriver Oak"
          : "---",
    },
    {
      name: "Lichtfarbe",
      value: lightColorIndex === 0 ? "Beyaz" : lightColorIndex === 1 ? "Sarı" : lightColorIndex === 2 ? "Kırmızı" : lightColorIndex === 3 ? "Mavi" : lightColorIndex === 4 ? "Pembe" : "Beyaz",
    },
    {
      name: "Rückwandl",
      value:
        backPanel === 0
          ? "matt weiße Rückwand W1100 ST9"
          : backPanel === 1
          ? "verspiegelte Rückwand"
          : backPanel === 2
          ? "arktisgraue Rückwand U788 ST9"
          : backPanel === 3
          ? "cremebeige Rückwand U222 ST9"
          : backPanel === 4
          ? "schwarze Rückwand U999 ST2 leicht glänzend"
          : "---",
    },
    {
      name: "Waschtisch-/ Decken-Beleuchtung",
      value: externalLeds === 0 ? "---" : externalLeds === 1 ? "Gömülü" : "Dışarıda",
    },
    {
      name: "Türgriff Spiegeltür/en",
      value:
        doorHandle === 1
          ? "Verchromt poliert Canto"
          : doorHandle === 99
          ? "Push-to-Open"
          : doorHandle === 3
          ? "Chrom glänzend Fina"
          : doorHandle === 4
          ? "Alu verchromt poliert Presa"
          : doorHandle === 5
          ? "Alu silberfarben eloxiert Mango"
          : doorHandle === 6
          ? "Verchromt poliert Moda"
          : "ohne Öffnungsmechanismus",
    },
    {
      name: "Glasüberstand (oben)",
      value: camCikintiUst + " mm",
    },
    {
      name: "Glasüberstand (unten)",
      value: camCikintiAlt + " mm",
    },
    {
      name: "Bluetooth Lautsprecher",
      value: bluetoothSpeaker ? "Ja" : "---",
    },
    {
      name: "Schminkspiegel",
      value: hasMakeupMirror ? "5-fach Vergrößerung, 152 mm, unbeleuchtet" : "---",
    },
    {
      name: "Schalter/Sensor/Dimmer",
      value: sensor == 0 ? "---" : sensor,
    },
    {
      name: "Steckdose/USB innen",
      value: socketUsb,
    },
    {
      name: "Steckdose/USB außen/im Fach",
      value: socketUsbDisarida,
    },
    {
      name: "Anzahl der produkte",
      value: orderAmount + " Stück",
    },
    {
      name: "LED Light ( Per Mirror )",
      value: pandoraLight,
    },
    {
      name: "Ihr Kommentar zum Produkt",
      value: customerComment.length > 0 ? customerComment : "---",
    },
  ];
  const mirrorImage =
    selectedMirror === EnumCabinets.OXFORD
      ? "/cabinetImages/Oxford.jpg"
      : selectedMirror === EnumCabinets.FRANKFURT
      ? "/cabinetImages/Frankfurt.jpg"
      : selectedMirror === EnumCabinets.DRESDEN
      ? "/cabinetImages/Dresden.jpg" /////
      : selectedMirror === EnumCabinets.MUNCHEN_4
      ? "/cabinetImages/München2.jpg"
      : selectedMirror === EnumCabinets.MUNCHEN_5
      ? "/cabinetImages/München5.jpg"
      : selectedMirror === EnumCabinets.LAS_VEGAS
      ? "/cabinetImages/LasVegas.jpg" /////
      : selectedMirror === EnumCabinets.SUPREME
      ? "/cabinetImages/Supreme.jpg"
      : selectedMirror === EnumCabinets.BUDAPEST
      ? "/cabinetImages/Budapest.jpg"
      : selectedMirror === EnumCabinets.CHICAGO
      ? "/cabinetImages/Chicago.jpg"
      : selectedMirror === EnumCabinets.COLUMBUS
      ? "/cabinetImages/Columbus.jpg"
      : selectedMirror === EnumCabinets.DUBLIN
      ? "/cabinetImages/Dublin.jpg"
      : selectedMirror === EnumCabinets.HAMBURG
      ? "/cabinetImages/Hamburg.jpg"
      : selectedMirror === EnumCabinets.MUNCHEN_6
      ? "/cabinetImages/München6.jpg"
      : "/cabinetImages/München1.jpg";

  const mobileMatches600 = useMediaQuery("(min-width:500px)");

  return (
    <Dialog TransitionComponent={Transition} maxWidth={"xl"} open={summaryModalOpen} onClose={handleClose} sx={{ zIndex: "999999999" }}>
      <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h5" fontWeight="800" color="#0d646f" my={3}>
          Zusammenfassung der Bestellung
        </Typography>
      </DialogTitle>

      <DialogContent spacing={1} sx={{ maxHeight: "75vh" }} dividers={"paper"}>
        <Paper
          elevation={20}
          square
          sx={{
            width: "50%",
            backgroundColor: "#0d646f10",
            marginLeft: "25%",
            mb: "3rem",
            mt: "2rem",
            borderRadius: "0.5rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={`https://www.spiegel21.de/images/product_images/popup_images/${selectedMirror}_0.jpg`} alt={cabinetName} style={{ borderRadius: "0.5rem" }} />
        </Paper>
        {summaryData.map((item) => {
          if (item.name == "Lichtfarbe" && EnumCabinets.OXFORD == selectedMirror) {
            return;
          } else {
            return (
              <Box
                sx={{
                  width: "100%",
                  minHeight: "4vh",
                  lineHeight: "4vh",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: "5px",
                  overflow: "hidden",
                  backgroundColor: "#edf0f0",
                  padding: "0 1rem",
                  color: "#1f1d1d",
                  my: 1,
                }}
              >
                <Box
                  sx={{
                    width: "35%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textAlign: "left",
                  }}
                >
                  <Typography variant={mobileMatches600 ? "body2" : "caption"} fontWeight={900}>
                    {item.name}
                  </Typography>
                  <Typography sx={{ textAlign: "left", fontWeight: "900" }}>:</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "1rem",
                    lineHeight: "100%",
                  }}
                >
                  <Typography variant={mobileMatches600 ? "body2" : "caption"}>{item.value}</Typography>
                </Box>
              </Box>
            );
          }
        })}
        <Box
          sx={{
            width: "100%",
            minHeight: "4vh",
            lineHeight: "4vh",
            textAlign: "center",
            display: "flex",
            flexDirection: mobileMatches600 ? "row" : "column",
            alignItems: "flex-start",
            borderRadius: "5px",
            overflow: "hidden",
            backgroundColor: "#edf0f0",
            padding: mobileMatches600 ? "0 1rem" : "0 0.25rem",
            color: "#1f1d1d",
          }}
        >
          <Box
            sx={{
              minWidth: "35%",
              height: "4vh",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "left",
              pl: mobileMatches600 ? 2 : 0.5,
            }}
          >
            <Typography variant={mobileMatches600 ? "body2" : "caption"} fontWeight={900}>
              Details zur Preisgestaltung
            </Typography>
            {mobileMatches600 && (
              <Typography variant={mobileMatches600 ? "body2" : "caption"} sx={{ textAlign: "left", fontWeight: "900" }}>
                :
              </Typography>
            )}
          </Box>
          <Stack direction="column" sx={{ flexGrow: 1, pl: mobileMatches600 ? 2 : 0.5 }}>
            {feeData.map((item) =>
              item.fee > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    minHeight: "4vh",
                  }}
                >
                  <Typography variant="subtitle2" fontWeight={item.name === "Toplam" ? 900 : 300}>
                    {item.name}
                  </Typography>
                  <Typography variant="subtitle2" fontWeight={item.name === "Toplam" ? 900 : 300}>
                    {item.fee.toFixed(2)} €
                  </Typography>
                </Box>
              ) : null
            )}
          </Stack>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleClose()} variant="contained" color="error" sx={{ fontWeight: "800", height: "2.5rem", mr: 1 }}>
          Weiter shoppen
        </Button>
        <Button
          onClick={() => {
            handleClose();
            // dispatch(resetToInitialState());
            $("#cart_quantity_cabinet").submit();
          }}
          variant="contained"
          sx={{
            fontWeight: "800",
            height: "2.5rem",

            backgroundColor: "#0d646f",
            mr: 1,
          }}
        >
          Zur Kasse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
