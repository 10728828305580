import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { EnumCabinets, changeCabinetType } from "../../../store/cabinet";
import { setCabinetFiyatı } from "../../../store/cabinetFee";

import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const DolapTipi = () => {
  const dispatch = useDispatch();
  const { cabinetType } = useSelector((state) => state.cabinet);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const handleChange = (e) => {
    dispatch(changeCabinetType(e.target.value));
    dispatch(setCabinetFiyatı(e.target.value === -0.2 ? 0 : e.target.value === 0 ? 39 : 79));
  };

  // useEffect(() => {
  //   dispatch(changeCabinetType(0));
  // }, [selectedMirror]);

  const renderInputDataValue = () => {
    switch (cabinetType) {
      case -0.15:
        return 528;
        break;
      case 0:
        return 529;
        break;
      case 0.2:
        return 530;
        break;
      case -0.08:
        return 2134;
        break;
    }
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      
      <RenderModal id="12">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Schrank Art
        </Typography>
      </RenderModal>

      <Stack direction="row" justifyContent="space-evenly" sx={{ flexGrow: "1", fontSize: "0.7rem" }}>
        <input name="id[63]" value={renderInputDataValue()} />
        <Select onChange={handleChange} value={cabinetType} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
          <MenuItem value={-0.15}>Unterputz / Einbau</MenuItem>
          <MenuItem value={0}>Aufputz / Hängeschrank + 39,00 €</MenuItem>
          <MenuItem value={0.2}>Unterputz mit Einbaurahmen + 79,00 €</MenuItem>
          <MenuItem value={-0.08}>Halb-Einbau mit Einbaurahmen + 79,00 €</MenuItem>
        </Select>
      </Stack>
    </Stack>
  );
};

export default DolapTipi;
