import { useGLTF } from "@react-three/drei";
import React from "react";
import { useSelector } from "react-redux";
import * as THREE from "three";

const DublinMirror = ({ type }) => {
  const { nodes, materials } = useGLTF("/newModels/dublin.glb");
  const { lightColor } = useSelector((state) => state.cabinetMirror);

  return (
    <>
      {type === "single" ? (
        <>
          {/* SPACE FILLER */}
          <mesh
            position-y={0}
            scale-y={0.999}
            scale-z={0.99}
            position-z={0.0045}
            position-x={0.25}
            material-side={THREE.DoubleSide}
            material={materials.mirror}
            rotation={[Math.PI * 1, 0, Math.PI * 1]}
            renderOrder={5}
          >
            <boxGeometry args={[0.5, 0.8, 0.00002]} />
          </mesh>
          {/* FIRST */}
          <group position-y={-0.037} rotation={[Math.PI * 1, 0, Math.PI * 1]} position-z={0.005} position-x={0.25} scale-x={0.5}>
            <mesh geometry={nodes.rightDoorLeft_1.geometry} material={materials.mirror} />
            <mesh geometry={nodes.rightDoorLeft_2.geometry} material={materials["ledlightTexture.003"]}>
              <meshBasicMaterial toneMapped={false} color={lightColor} />
            </mesh>
            <mesh geometry={nodes.rightDoorLeft_3.geometry} material={materials.mirror} />
          </group>
          {/* LAST */}
          <group position-y={-0.037} scale-x={0.5} position-x={0.25}>
            <mesh geometry={nodes.rightDoorLeft_1.geometry} material={materials.mirror} />
            <mesh geometry={nodes.rightDoorLeft_2.geometry} material={materials["ledlightTexture.003"]}>
              <meshBasicMaterial toneMapped={false} color={lightColor} />
            </mesh>
            <mesh geometry={nodes.rightDoorLeft_3.geometry} material={materials.mirror} />
          </group>
        </>
      ) : type === "first" ? (
        <group position-z={0.001}>
          <mesh renderOrder={6} scale={[1, 1, 1]} geometry={nodes.leftDoorLeft_1.geometry} material={materials.mirror} />
          <mesh scale={[1, 1, 1]} position={[0, 0, 0.001]} geometry={nodes.leftDoorLeft_2.geometry} material={materials["ledlightTexture.003"]}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
          <mesh scale={[1, 1, 1]} geometry={nodes.leftDoorLeft_3.geometry} material-side={THREE.DoubleSide} material={materials.mirror} />
        </group>
      ) : type === "mid" ? (
        <group position-y={-0.037}>
          <mesh geometry={nodes.midDoorLeft_1.geometry} material={materials.mirror} />
          <mesh geometry={nodes.midDoorLeft_2.geometry} material={materials["ledlightTexture.003"]}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
          <mesh geometry={nodes.midDoorLeft_3.geometry} material={materials.mirror} />
        </group>
      ) : (
        <group position-y={-0.037}>
          <mesh geometry={nodes.rightDoorLeft_1.geometry} material={materials.mirror} />
          <mesh geometry={nodes.rightDoorLeft_2.geometry} material={materials["ledlightTexture.003"]}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
          <mesh geometry={nodes.rightDoorLeft_3.geometry} material={materials.mirror} />
        </group>
      )}
    </>
  );
};

export default DublinMirror;
