import React, { useEffect, useState } from "react";
import { Stack, Typography, Divider, shouldSkipGeneratingVar, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getProductWithMatrixByName, getProductWithMatrixByNameForDolap, readPriceFromApiForKare } from "../../utils/readJson";
import { setBaseFee } from "../../store/cabinetFee";
import $ from "jquery";

import KapıKolu from "./menuItems/KapıKolu";
import MenteseYonu from "./menuItems/MenteseYonu";
import DolapTipi from "./menuItems/DolapTipi";
import LedIsıgı from "./menuItems/LedIsıgı";
import ArkaPanel from "./menuItems/ArkaPanel";
import AynaAdeti from "./menuItems/AynaAdeti";
import Soketler from "./menuItems/Soketler";
import YorumYapın from "./menuItems/YorumYapın";
import SiparisVer from "./menuItems/SiparisVer";
import Genislik from "./menuItems/Genislik";
import Yukseklik from "./menuItems/Yukseklik";
import Derinlik from "./menuItems/Derinlik";
import GovdeDoku from "./menuItems/GovdeDoku";
import FrontGovdeDoku from "./menuItems/FrontGovdeDoku";
import CamRaflar from "./menuItems/CamRaflar";
import AhsapZeminler from "./menuItems/AhsapZeminler";

import CamCikintiUst from "./menuItems/CamCikintiUst";
import CamCikintiAlt from "./menuItems/CamCikintiAlt";
import CmButton from "./menuItems/Buttons/CmButton";
import LightButton from "./menuItems/Buttons/LightButton";
import MenuToggleButton from "./menuItems/Buttons/MenuToggleButton";
import OpenAllDoors from "./menuItems/Buttons/OpenAllDoors";
import { EnumCabinets } from "../../store/cabinet";
import CloseIcon from "@mui/icons-material/Close";
import { changeMenuIsOpen } from "../../store/mirrors";
import MontajCercevesiDerinligi from "./menuItems/MontajCercevesiDerinligi";
import KurulumCercevesi from "./menuItems/kurulumCercevesi";

const DolapMenu = () => {
  /**
   * Ayna Stateler
   * Aynalar: selectedMirror / changeMirror 123
   *
   * Işık Rengi: lightColor / changeLightColor "white", "red", "yellow", "blue"
   *
   * Saat Konumu: watchPosition / changeWatchPosition discorddan attı
   *
   * Dijital Saat : digitalWatch / setDigitalWatch
   *
   */

  const { selectedMirror } = useSelector((state) => state.mirror);
  const { cabinetWidth, cabinetHeight, selectedCabinetExcelIndex, orderAmount, cabinetType } = useSelector((state) => state.cabinet);
  const { isMenuOpen } = useSelector((state) => state.mirror);
  const {
    cabinetName,
    baseFee, //modelden gelen fiyatı
    ahsapZeminFiyatı,
    backPanelFiyatı,
    bluetoothSpeakerFiyatı,
    camRafFiyatı,
    cabinetFiyatı, // dolap tipinden gelen fiyat bu, modelden değil
    doorHandleFiyatı,
    doorFiyatı,
    externalLedFiyatı,
    makeupMirrorFiyatı,
    sensorFiyatı,
    dekorFiyati,
    ledIsikFiyatı,
    pandoraLightFee,
    socketUsbFee,
    socketUsbDisaridaFee,
    derinlikFee,
    kurulumCercevesiFee,
  } = useSelector((state) => state.cabinetFee);

  const { isPrivate } = useSelector((state) => state.mirror);
  const dispatch = useDispatch();
  const desktopMatches1700 = useMediaQuery("(min-width:1700px)");
  const desktopMatches1200 = useMediaQuery("(min-width:1200px)");
  const desktopMatches900 = useMediaQuery("(min-width:900px)");
  const mobileMatches600 = useMediaQuery("(min-width:600px)");
  const menuResponsiveWidth = desktopMatches1700 ? "29vw" : desktopMatches1200 ? "29vw" : desktopMatches900 ? "40vw" : mobileMatches600 ? "50vw" : "100vw";
  const menuTranslateXClosed = desktopMatches1200 ? "0" : desktopMatches900 ? "40vw" : mobileMatches600 ? "50vw" : "100vw";
  const menuToggledPosition = isMenuOpen ? "0" : menuTranslateXClosed;

  // useEffect(() => {
  //   async function fetchData() {
  //     await getProductWithMatrixByName(selectedMirrorExcelIndex, cabinetWidth, cabinetHeight).then((response) => {
  //       dispatch(setBaseFee(response ? response : 0));
  //     });
  //   }
  //   // if (mirrorWidth % 50 == 0 || mirrorHeight % 50 == 0) {
  //   //   fetchData();
  //   // }
  //   fetchData();
  // }, [selectedMirrorExcelIndex, cabinetWidth, cabinetHeight]);

  useEffect(() => {
    async function fetchData() {
      const data = await readPriceFromApiForKare(selectedCabinetExcelIndex, cabinetWidth, cabinetHeight).then((response) => {
        dispatch(setBaseFee(response ? response : 0));
      });
      return data;
    }
    fetchData();
  }, [selectedCabinetExcelIndex, cabinetWidth, cabinetHeight]);

  const dimensionFee = cabinetWidth > 1200 && cabinetHeight > 800 ? 49.99 : 0;

  const total =
    baseFee +
    (ahsapZeminFiyatı +
      dekorFiyati +
      ledIsikFiyatı +
      backPanelFiyatı +
      bluetoothSpeakerFiyatı +
      camRafFiyatı +
      cabinetFiyatı +
      doorHandleFiyatı +
      doorFiyatı +
      externalLedFiyatı +
      makeupMirrorFiyatı +
      sensorFiyatı +
      dimensionFee +
      pandoraLightFee +
      kurulumCercevesiFee +
      socketUsbFee +
      socketUsbDisaridaFee +
      derinlikFee) *
      orderAmount;

  const hasLedOption = [EnumCabinets.BHS007L4, EnumCabinets.BHS012L4, EnumCabinets.BHS008L4, EnumCabinets.BHS013L4, EnumCabinets.BHS009L4].includes(selectedMirror);
  const isCenteredCabinet = [EnumCabinets.BHS003, EnumCabinets.BHS009L4, EnumCabinets.BHS004].includes(selectedMirror);
  const isWoodenDoor = [EnumCabinets.BHS002A, EnumCabinets.BHS003, EnumCabinets.BHS005, EnumCabinets.BHS002B].includes(selectedMirror);

  return (
    <>
      <div id="buttonsContainer2">
        <OpenAllDoors isMenuOpen={false} />
        <CmButton isMenuOpen={false} />
        <LightButton isMenuOpen={false} />
      </div>

      {mobileMatches600 ? <MenuToggleButton isMenuOpen={isMenuOpen} setIsMenuOpen={() => dispatch(changeMenuIsOpen(!isMenuOpen))} /> : null}

      <form id="cart_quantity_cabinet" action={"https://dev.spiegel21.de/product_info.php?products_id=" + selectedCabinetExcelIndex + "&action=add_product"} method="post">
        <input type="hidden" name="products_id" value={selectedMirror} />
        <Stack
          className="mobileMenuHeightEdit"
          spacing={2}
          p={3}
          sx={{
            position: "fixed",
            top: "0",
            right: "0",
            height: "100vh",
            width: menuResponsiveWidth,
            maxWidth: menuResponsiveWidth,
            backgroundColor: "#ffffff",
            overflowY: "auto",
            overflowX: "hidden",
            zIndex: "2",
            transition: "all 0.5s ease-in",
            transform: `translateX(${menuToggledPosition})`,
          }}
        >
          <Stack>
            {!desktopMatches1200 && mobileMatches600 && (
              <Stack
                direction={"row"}
                spacing={0.5}
                sx={{
                  position: "absolute",
                  top: "4px",
                  left: "4px",
                  alignItems: "center",
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#0d646f",
                  border: "2px solid #0d646f",
                  borderRadius: "8px",
                  p: "4px 8px",
                }}
                onClick={() => dispatch(changeMenuIsOpen(!isMenuOpen))}
              >
                <CloseIcon />
              </Stack>
            )}
            <Stack direction="row" justifyContent="space-between" sx={{ ...(!desktopMatches1200 && { mt: "10px" }) }}>
              {isPrivate ? (
                <></>
              ) : (
                <Typography
                  color="primary"
                  onClick={() => {
                    window.location.reload();
                  }}
                  sx={{
                    cursor: "pointer",
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <svg
                    id="icon_ent"
                    style={{
                      transform: "rotate(180deg)",
                      marginRight: "0.5rem",
                      color: "lightblue",
                    }}
                    className="svg-icon"
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                  >
                    <path d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z"></path>
                  </svg>
                  Zurück
                </Typography>
              )}
              <Typography sx={{ fontWeight: "700" }}>
                {cabinetName}
                {""} - {""}
                {parseFloat(total).toFixed(2).toString().replace(".", ",")}€
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <DolapTipi />
          {cabinetType == -0.08 ? (
            <>
              <MontajCercevesiDerinligi />
              <KurulumCercevesi />
            </>
          ) : null}
          {cabinetType == 0.2 ? <KurulumCercevesi /> : null}
          <Genislik />
          <Yukseklik />
          <Derinlik />

          {hasLedOption && <LedIsıgı />}

          <GovdeDoku />
          {isWoodenDoor && <FrontGovdeDoku />}
          <ArkaPanel />
          <MenteseYonu />

          <KapıKolu />
          {/* <CamCikinti /> */}
          {!isWoodenDoor && (
            <>
              <CamCikintiAlt />
              <CamCikintiUst />
            </>
          )}
          <CamRaflar />
          <AhsapZeminler />
          <Soketler />

          {/* <SensorAnahtar /> */}
          <YorumYapın />
          <br />
          <AynaAdeti />
          <center>
            <p className="text-primary relative top-5">Die Mehrwertsteuer wird an der Kasse berechnet.</p>
          </center>
          <SiparisVer />
          <br />
        </Stack>
      </form>
    </>
  );
};

export default DolapMenu;
