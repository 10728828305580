import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setSocketUsb } from "../../../store/cabinet";
import { setSocketUsbFiyatı } from "../../../store/cabinetFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const Soketler = () => {
  const dispatch = useDispatch();
  const { socketUsb } = useSelector((state) => state.cabinet);

  const soketlerIcerdeOptions = [
    {
      label: "---",
      price: 0.0,
      value: "754",
    },

    {
      label: "1x USB left",
      price: 45.0,
      value: "6793",
    },

    {
      label: "1x USB right",
      price: 45.0,
      value: "6794",
    },
    {
      label: "1x USB + 1x socket on the left (2-fold frame)",
      price: 79.0,
      value: "6795",
    },

    {
      label: "1x USB + 1x socket on the right (2-fold frame)",
      price: 79.0,
      value: "6796",
    },
    {
      label: "1x USB + 2x socket on the left (triple frame)",
      price: 119.0,
      value: "6797",
    },

    {
      label: "1x USB + 2x socket on the right (triple frame)",
      price: 119.0,
      value: "6798",
    },
    { value: "2185", label: "1x socket at the bottom left ", price: 39.0 },
    { value: "2187", label: "1x socket in the middle on the left ", price: 39.0 },
    { value: "2189", label: "1x socket at the top left ", price: 39.0 },
    { value: "2186", label: "1x socket on the bottom right ", price: 39.0 },
    { value: "2188", label: "1x socket in the middle on the right ", price: 39.0 },
    { value: "2190", label: "1x socket at the top right ", price: 39.0 },
    { value: "2191", label: "2x socket bottom left (double frame) ", price: 49.0 },
    { value: "2193", label: "2x socket in the middle on the left (double frame) ", price: 49.0 },
    { value: "2195", label: "2x socket top left (double frame) ", price: 49.0 },
    { value: "2192", label: "2x socket bottom right (double frame) ", price: 49.0 },
    { value: "2194", label: "2x socket in the middle on the right (double frame) ", price: 49.0 },
    { value: "2196", label: "2x socket at the top right (double frame) ", price: 49.0 },
  ];

  useEffect(() => {
    const matchedOption = soketlerIcerdeOptions.find((item) => item.label === socketUsb);
    if (matchedOption) {
      dispatch(setSocketUsbFiyatı(matchedOption.price));
    }
  }, [socketUsb]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="24">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Steckdose/USB innen
        </Typography>
      </RenderModal>

      <input name="id[91]" value={soketlerIcerdeOptions.find((item) => item.label === socketUsb)?.value || "754"} />
      <Select
        onChange={(e) => {
          dispatch(setSocketUsb(e.target.value));
        }}
        value={socketUsb == 0 ? "---" : socketUsb}
        sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
      >
        {soketlerIcerdeOptions.map((item) => (
          <MenuItem value={item.label}>
            {item.label} {item.price <= 0 ? "" : "+ €" + item.price}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default Soketler;
