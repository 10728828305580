import React, { useEffect, useRef, useState } from "react";
import { useGLTF, Html } from "@react-three/drei";

import { useDispatch, useSelector } from "react-redux";
import { useDrag } from "@use-gesture/react";

import { extend, useThree } from "@react-three/fiber";
import { TempSensor } from "../../OrtakComponents/TempSensor";
import { changeSelectedExcelItem, changeLightThickness, EnumMirrors } from "../../../store/mirrors";
import { Plug } from "../../OrtakComponents/Plug";
import CentimeterHeightLines from "../../OrtakComponents/CM/CentimeterHeightLines";
import CentimeterWidthLines from "../../OrtakComponents/CM/CentimeterWidthLines";
import Touch from "../../OrtakComponents/Sensor/Touch";

function Daire(props) {
  const { nodes, materials } = useGLTF("/daire.glb");
  const { nodes: budapestNodes, materials: budapestMaterials } = useGLTF("/newModels/budapest.glb");
  const daireAyna = useRef();
  const yuvarlakLED = useRef();
  const dispatch = useDispatch();

  const {
    soket,
    soketPos,
    lightColor,
    lightColorIndex,
    ciftSensorPos,
    mirrorWidth,
    analogWatch,
    digitalWatch,
    watchPos,
    yataySensor,
    yataySensorPos,
    dikeySensor,
    dikeySensorPos,
    isiSensor,
    isiSensorPos,
    widthCmVisible,
    heightCmVisible,
    makeupMirrorPos,
    makeupMirror,
    mirrorTickness,
    makeupMirrorSensor,
    selectedMirror,
  } = useSelector((state) => state.mirror);

  const [mirrorWidthForCm, setMirrorWidthForCm] = useState(mirrorWidth + 900);
  const [yuvarlakLEDScale, setYuvarlakLEDScale] = useState();

  // Positions
  const [positionAnalog, setPositionAnalog] = useState([
    watchPos == 0 ? -0.2 : watchPos == 1 ? -0.04 : watchPos == 2 ? 0.13 : watchPos == 3 ? -0.2 : watchPos == 4 ? -0.02 : 0.14,

    watchPos == 0 || watchPos == 1 || watchPos == 2 ? 0.23 : 0.8,
    0.09,
  ]);
  const [positionDigital, setPositionDigital] = useState([
    watchPos == 0 ? -0.2 : watchPos == 1 ? -0.04 : watchPos == 2 ? 0.13 : watchPos == 3 ? -0.2 : watchPos == 4 ? -0.02 : 0.14,
    watchPos == 0 || watchPos == 1 || watchPos == 2 ? 0.23 : 0.8,
    0.1,
  ]);

  const [positionSensor, setPositionSensor] = useState([0, 0, 0.1]);

  const [positionTemp, setPositionTemp] = useState([0, 0, 0.1]);

  const [ciftSensorPosition, setCiftSensorPosition] = useState([0, 0, 0]);

  const [positionSoket, setPositionSoket] = useState([0, 0, 0.1]);

  const [positionDoubleSoket, setPositionDoubleSoket] = useState([0, 0, 0.1]);

  // ---

  const { size, viewport } = useThree();
  const aspect = size.width / viewport.width;

  // Position conditions
  const doubleSensor = lightColorIndex == 4;
  const soketCheck = soket === 1 || soket === 2;
  const doubleSoketCheck = soket === 3 || soket === 4;
  const isiSensorCheck = isiSensor === 2 || isiSensor === 3;
  const bottomLeftAll =
    (lightColorIndex == 4 && ciftSensorPos == 0) ||
    (yataySensor ? yataySensorPos == 0 : dikeySensor ? dikeySensorPos == 0 : false) ||
    (isiSensorCheck ? isiSensorPos == 0 : false) ||
    (soket ? soketPos == 0 : false);
  const bottomMidAll =
    (lightColorIndex == 4 && ciftSensorPos == 1) ||
    (yataySensor ? yataySensorPos == 1 : dikeySensor ? dikeySensorPos == 1 : false) ||
    (isiSensorCheck ? isiSensorPos == 1 : false) ||
    (soket ? soketPos == 1 : false);
  const bottomRightAll =
    (lightColorIndex == 4 && ciftSensorPos == 2) ||
    (yataySensor ? yataySensorPos == 2 : dikeySensor ? dikeySensorPos == 2 : false) ||
    (isiSensorCheck ? isiSensorPos == 2 : false) ||
    (soket ? soketPos == 2 : false);
  const topLeftAll = (yataySensor ? yataySensorPos == 3 : dikeySensor ? dikeySensorPos == 3 : false) || (isiSensorCheck ? isiSensorPos == 3 : false) || (soket ? soketPos == 3 : false);
  const topMidAll = (yataySensor ? yataySensorPos == 4 : dikeySensor ? dikeySensorPos == 4 : false) || (isiSensorCheck ? isiSensorPos == 4 : false) || (soket ? soketPos == 4 : false);
  const topRightAll = (yataySensor ? yataySensorPos == 5 : dikeySensor ? dikeySensorPos == 5 : false) || (isiSensorCheck ? isiSensorPos == 5 : false) || (soket ? soketPos == 5 : false);
  const midRightAll = (yataySensor ? yataySensorPos == 6 : dikeySensor ? dikeySensorPos == 6 : false) && (isiSensorCheck ? isiSensorPos == 6 : false);
  const midRightAny = (yataySensor ? yataySensorPos == 6 : dikeySensor ? dikeySensorPos == 6 : false) || (isiSensorCheck ? isiSensorPos == 6 : false) || (soket ? soketPos == 6 : false);
  const midLeftAll = (yataySensor ? yataySensorPos == 7 : dikeySensor ? dikeySensorPos == 7 : false) && (isiSensorCheck ? isiSensorPos == 7 : false);
  const midLeftAny = (yataySensor ? yataySensorPos == 7 : dikeySensor ? dikeySensorPos == 7 : false) || (isiSensorCheck ? isiSensorPos == 7 : false) || (soket ? soketPos == 7 : false);
  // ---

  // Object Positions
  useEffect(() => {
    // Watch Pos
    if (watchPos == 0) {
      // Bottom Left && If there is Temp or sensor
      if (bottomLeftAll) {
        setPositionDigital((prev) => [-0.202, 0.235, prev[2]]);
        setPositionAnalog((prev) => [-0.205, 0.255, prev[2]]);
      }
      // Bottom Left Default
      else {
        setPositionDigital((prev) => [-0.2, 0.175, prev[2]]);
        setPositionAnalog((prev) => [-0.205, 0.19, prev[2]]);
      }
    } else if (watchPos == 1) {
      // Bottom Mid && If there is Temp or sensor
      if (bottomMidAll) {
        setPositionDigital((prev) => [-0.03, 0.235, prev[2]]);
        setPositionAnalog((prev) => [-0.03, 0.245, prev[2]]);
      }
      // Bottom Mid Default
      else {
        setPositionDigital((prev) => [-0.03, 0.175, prev[2]]);
        setPositionAnalog((prev) => [-0.03, 0.19, prev[2]]);
      }
    } else if (watchPos == 2) {
      // Bottom Right && If there is Temp or sensor
      if (bottomRightAll) {
        setPositionDigital((prev) => [0.13, 0.235, prev[2]]);
        setPositionAnalog((prev) => [0.13, 0.26, prev[2]]);
      }
      // Bottom Right Default
      else {
        setPositionDigital((prev) => [0.13, 0.175, prev[2]]);
        setPositionAnalog((prev) => [0.13, 0.19, prev[2]]);
      }
    } else if (watchPos == 3) {
      // Top Left && If there is Temp or sensor
      if (topLeftAll) {
        setPositionDigital((prev) => [-0.2, 0.82, prev[2]]);
        setPositionAnalog((prev) => [-0.205, 0.8, prev[2]]);
      }
      // Top Left Default
      else {
        setPositionDigital((prev) => [-0.2, 0.86, prev[2]]);
        setPositionAnalog((prev) => [-0.205, 0.86, prev[2]]);
      }
    } else if (watchPos == 4) {
      // Top Mid If there is Temp or sensor
      if (topMidAll) {
        setPositionDigital((prev) => [-0.03, 0.835, prev[2]]);
        setPositionAnalog((prev) => [-0.03, 0.835, prev[2]]);
      }
      // Top Mid Default
      else {
        setPositionDigital((prev) => [-0.03, 0.875, prev[2]]);
        setPositionAnalog((prev) => [-0.03, 0.875, prev[2]]);
      }
    } else if (watchPos == 5) {
      //  Top Right If there is Temp or sensor
      if (topRightAll) {
        setPositionDigital((prev) => [0.13, 0.815, prev[2]]);
        setPositionAnalog((prev) => [0.135, 0.8, prev[2]]);
      }
      // Top Right Default
      else {
        setPositionDigital((prev) => [0.13, 0.86, prev[2]]);
        setPositionAnalog((prev) => [0.135, 0.85, prev[2]]);
      }
    } else if (watchPos == 6) {
      //  Mid Right If there is Temp and sensor
      if (midRightAll) {
        setPositionDigital((prev) => [0.2, 0.5, prev[2]]);
        setPositionAnalog((prev) => [0.2, 0.5, prev[2]]);
      }
      //  Mid Right If there is Temp or sensor
      else if (midRightAny) {
        setPositionDigital((prev) => [0.22, 0.5, prev[2]]);
        setPositionAnalog((prev) => [0.22, 0.5, prev[2]]);
      }
      // Mid Right Default
      else {
        setPositionDigital((prev) => [0.29, 0.5, prev[2]]);
        setPositionAnalog((prev) => [0.295, 0.5, prev[2]]);
      }
    } else if (watchPos == 7) {
      // Mid Left If there is Temp and sensor
      if (midLeftAll) {
        setPositionDigital((prev) => [-0.265, 0.5, prev[2]]);
        setPositionAnalog((prev) => [-0.27, 0.5, prev[2]]);
      }
      //  Mid Left If there is Temp or sensor
      else if (midLeftAny) {
        setPositionDigital((prev) => [-0.285, 0.5, prev[2]]);
        setPositionAnalog((prev) => [-0.29, 0.5, prev[2]]);
      }
      // Mid Left Default
      else {
        setPositionDigital((prev) => [-0.35, 0.5, prev[2]]);
        setPositionAnalog((prev) => [-0.355, 0.5, prev[2]]);
      }
    }

    // Sensor Pos
    if ((yataySensor && yataySensorPos == 0) || (dikeySensor && dikeySensorPos == 0)) {
      // Bottom Left If there is TempSensor and soket
      if ((isiSensorCheck ? isiSensorPos == 0 : false) && (soketCheck ? soketPos == 0 : false)) {
        setPositionSensor((prev) => [-0.13, 0.175, prev[2]]);
      }
      // Bottom Left If there is TempSensor and Double Soket
      else if ((isiSensorCheck ? isiSensorPos == 0 : false) && (doubleSoketCheck ? soketPos == 0 : false)) {
        setPositionSensor((prev) => [-0.08, 0.175, prev[2]]);
      }
      // Bottom Left If there is TempSensor
      else if (isiSensorCheck ? isiSensorPos == 0 : false) {
        setPositionSensor((prev) => [-0.18, 0.175, prev[2]]);
      }
      // Bottom Left If there is Soket
      else if (soketCheck ? soketPos == 0 : false) {
        setPositionSensor((prev) => [-0.17, 0.175, prev[2]]);
      }
      // Bottom Left If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 0 : false) {
        setPositionSensor((prev) => [-0.12, 0.175, prev[2]]);
      }
      // Bottom Left Default
      else {
        setPositionSensor((prev) => [-0.2, 0.175, prev[2]]);
      }
    } else if ((yataySensor && yataySensorPos === 1) || (dikeySensor && dikeySensorPos == 1)) {
      // Bottom Mid If there is TempSensor and Soket
      if ((isiSensorCheck ? isiSensorPos == 1 : false) && (soketCheck ? soketPos == 1 : false)) {
        setPositionSensor((prev) => [0.03, 0.175, prev[2]]);
      }
      // Bottom Mid If there is TempSensor and Double Soket
      else if ((isiSensorCheck ? isiSensorPos == 1 : false) && (doubleSoketCheck ? soketPos == 1 : false)) {
        setPositionSensor((prev) => [0.04, 0.175, prev[2]]);
      }
      // Bottom Mid If there is TempSensor
      else if (isiSensorCheck ? isiSensorPos == 1 : false) {
        setPositionSensor((prev) => [-0.01, 0.175, prev[2]]);
      }
      // Bottom Mid If there is Soket
      else if (soketCheck ? soketPos == 1 : false) {
        setPositionSensor((prev) => [0, 0.175, prev[2]]);
      }
      // Bottom Mid If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 1 : false) {
        setPositionSensor((prev) => [0.03, 0.175, prev[2]]);
      }
      // Bottom Mid Default
      else {
        setPositionSensor((prev) => [-0.03, 0.175, prev[2]]);
      }
    } else if ((yataySensor && yataySensorPos === 2) || (dikeySensor && dikeySensorPos == 2)) {
      // Bottom Right If there is TempSensor and Soket
      if ((isiSensorCheck ? isiSensorPos == 2 : false) && (soketCheck ? soketPos == 2 : false)) {
        setPositionSensor((prev) => [0.13, 0.175, prev[2]]);
      }
      // Bottom Right If there is TempSensor and Double Soket
      else if ((isiSensorCheck ? isiSensorPos == 2 : false) && (doubleSoketCheck ? soketPos == 2 : false)) {
        setPositionSensor((prev) => [0.1, 0.175, prev[2]]);
      }
      // Bottom Right If there is TempSensor
      else if (isiSensorCheck ? isiSensorPos == 2 : false) {
        setPositionSensor((prev) => [0.15, 0.175, prev[2]]);
      }
      // Bottom Right If there is Soket
      else if (soketCheck ? soketPos == 2 : false) {
        setPositionSensor((prev) => [0.12, 0.175, prev[2]]);
      }
      // Bottom Right If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 2 : false) {
        setPositionSensor((prev) => [0.08, 0.175, prev[2]]);
      }
      // Bottom Right Default
      else {
        setPositionSensor((prev) => [0.13, 0.175, prev[2]]);
      }
    } else if ((yataySensor && yataySensorPos === 3) || (dikeySensor && dikeySensorPos == 3)) {
      // Top Left If there is TempSensor and Soket
      if ((isiSensorCheck ? isiSensorPos == 3 : false) && (soketCheck ? soketPos == 3 : false)) {
        setPositionSensor((prev) => [-0.13, 0.86, prev[2]]);
      }
      // Top Left  If there is TempSensor and Double Soket
      else if ((isiSensorCheck ? isiSensorPos == 3 : false) && (doubleSoketCheck ? soketPos == 3 : false)) {
        setPositionSensor((prev) => [-0.08, 0.86, prev[2]]);
      }
      // Top Left  If there is TempSensor
      else if (isiSensorCheck ? isiSensorPos == 3 : false) {
        setPositionSensor((prev) => [-0.18, 0.86, prev[2]]);
      }
      // Top Left  If there is Soket
      else if (soketCheck ? soketPos == 3 : false) {
        setPositionSensor((prev) => [-0.15, 0.86, prev[2]]);
      }
      // Top Left If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 3 : false) {
        setPositionSensor((prev) => [-0.1, 0.86, prev[2]]);
      }
      // Top Left Default
      else {
        setPositionSensor((prev) => [-0.2, 0.86, prev[2]]);
      }
    } else if ((yataySensor && yataySensorPos === 4) || (dikeySensor && dikeySensorPos == 4)) {
      // Top Mid If there is TempSensor and Soket
      if ((isiSensorCheck ? isiSensorPos == 4 : false) && (soketCheck ? soketPos == 4 : false)) {
        setPositionSensor((prev) => [0.02, 0.875, prev[2]]);
      }
      // Top Mid If there is TempSensor and Double Soket
      else if ((isiSensorCheck ? isiSensorPos == 4 : false) && (doubleSoketCheck ? soketPos == 4 : false)) {
        setPositionSensor((prev) => [0.04, 0.875, prev[2]]);
      }
      // Top Mid  If there is TempSensor
      else if (isiSensorCheck ? isiSensorPos == 4 : false) {
        setPositionSensor((prev) => [-0.01, 0.875, prev[2]]);
      }
      // Top Mid  If there is Soket
      else if (soketCheck ? soketPos == 4 : false) {
        setPositionSensor((prev) => [0.01, 0.875, prev[2]]);
      }
      // Top Mid If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 4 : false) {
        setPositionSensor((prev) => [0.03, 0.875, prev[2]]);
      }
      // Top Mid Default
      else {
        setPositionSensor((prev) => [-0.03, 0.875, prev[2]]);
      }
    } else if ((yataySensor && yataySensorPos === 5) || (dikeySensor && dikeySensorPos == 5)) {
      // Top Right If there is TempSensor and Soket
      if ((isiSensorCheck ? isiSensorPos == 5 : false) && (soketCheck ? soketPos == 5 : false)) {
        setPositionSensor((prev) => [0.11, 0.86, prev[2]]);
      }
      // Top Right If there is TempSensor and Double Soket
      else if ((isiSensorCheck ? isiSensorPos == 5 : false) && (doubleSoketCheck ? soketPos == 5 : false)) {
        setPositionSensor((prev) => [0.06, 0.86, prev[2]]);
      }
      // Top Right If there is TempSensor
      else if (isiSensorCheck ? isiSensorPos == 5 : false) {
        setPositionSensor((prev) => [0.15, 0.86, prev[2]]);
      }
      // Top Right If there is Soket
      else if (soketCheck ? soketPos == 5 : false) {
        setPositionSensor((prev) => [0.09, 0.86, prev[2]]);
      }
      // Top Right If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 5 : false) {
        setPositionSensor((prev) => [0.04, 0.86, prev[2]]);
      }
      // Top Right Default
      else {
        setPositionSensor((prev) => [0.13, 0.86, prev[2]]);
      }
    } else if ((yataySensor && yataySensorPos === 6) || (dikeySensor && dikeySensorPos == 6)) {
      // Mid Right If there is TempSensor and Soket
      if ((isiSensorCheck ? isiSensorPos == 6 : false) && (soketCheck ? soketPos == 6 : false)) {
        setPositionSensor((prev) => [0.29, 0.5, prev[2]]);
      }
      // Mid Right If there is TempSensor and Double Soket
      else if ((isiSensorCheck ? isiSensorPos == 6 : false) && (doubleSoketCheck ? soketPos == 6 : false)) {
        setPositionSensor((prev) => [0.26, 0.5, prev[2]]);
      }
      // Mid Right If there is TempSensor
      else if (isiSensorCheck ? isiSensorPos == 6 : false) {
        setPositionSensor((prev) => [0.31, 0.5, prev[2]]);
      }
      // Mid Right If there is Soket
      else if (soketCheck ? soketPos == 6 : false) {
        setPositionSensor((prev) => [0.27, 0.5, prev[2]]);
      }
      // Mid Right If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 6 : false) {
        setPositionSensor((prev) => [0.24, 0.5, prev[2]]);
      }
      // Mid Right Default
      else {
        setPositionSensor((prev) => [0.29, 0.5, prev[2]]);
      }
    } else if ((yataySensor && yataySensorPos === 7) || (dikeySensor && dikeySensorPos == 7)) {
      // Mid Left If there is TempSensor and Soket
      if ((isiSensorCheck ? isiSensorPos == 7 : false) && (soketCheck ? soketPos == 7 : false)) {
        setPositionSensor((prev) => [-0.31, 0.5, prev[2]]);
      }
      // Mid Left If there is TempSensor and Double Soket
      else if ((isiSensorCheck ? isiSensorPos == 7 : false) && (doubleSoketCheck ? soketPos == 7 : false)) {
        setPositionSensor((prev) => [-0.24, 0.5, prev[2]]);
      }
      // Mid Left If there is TempSensor
      else if (isiSensorCheck ? isiSensorPos == 7 : false) {
        setPositionSensor((prev) => [-0.33, 0.5, prev[2]]);
      }
      // Mid Left If there is Soket
      else if (soketCheck ? soketPos == 7 : false) {
        setPositionSensor((prev) => [-0.33, 0.5, prev[2]]);
      }
      // Mid Left If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 7 : false) {
        setPositionSensor((prev) => [-0.27, 0.5, prev[2]]);
      }
      // Mid Left Default
      else {
        setPositionSensor((prev) => [-0.35, 0.5, prev[2]]);
      }
    }

    // Temp Sensor Pos
    if (isiSensorCheck && isiSensorPos == 0) {
      // Bottom Left If there is Sensor and soket
      if (((lightColorIndex == 4 && ciftSensorPos == 0) || (yataySensor && yataySensorPos == 0) || (dikeySensor && dikeySensorPos == 0)) && (soketCheck ? soketPos == 0 : false)) {
        setPositionTemp((prev) => [-0.17, 0.175, prev[2]]);
      }
      // Bottom Left If there is Sensor and Double Soket
      else if (((lightColorIndex == 4 && ciftSensorPos == 0) || (yataySensor && yataySensorPos == 0) || (dikeySensor && dikeySensorPos == 0)) && (doubleSoketCheck ? soketPos == 0 : false)) {
        setPositionTemp((prev) => [-0.12, 0.175, prev[2]]);
      }
      // Bottom Left If there is Sensor
      else if ((lightColorIndex == 4 && ciftSensorPos == 0) || (yataySensor && yataySensorPos == 0) || (dikeySensor && dikeySensorPos == 0)) {
        setPositionTemp((prev) => [-0.22, 0.175, prev[2]]);
      }
      // Bottom Left If there is Soket
      else if (soketCheck ? soketPos == 0 : false) {
        setPositionTemp((prev) => [-0.17, 0.175, prev[2]]);
      }
      // Bottom Left If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 0 : false) {
        setPositionTemp((prev) => [-0.12, 0.175, prev[2]]);
      }
      // Bottom Left Default
      else {
        setPositionTemp((prev) => [-0.205, 0.175, prev[2]]);
      }
    } else if (isiSensorCheck && isiSensorPos == 1) {
      // Bottom Mid If there is Sensor and soket
      if (((lightColorIndex == 4 && ciftSensorPos == 1) || (yataySensor && yataySensorPos == 1) || (dikeySensor && dikeySensorPos == 1)) && (soketCheck ? soketPos == 1 : false)) {
        setPositionTemp((prev) => [-0.01, 0.175, prev[2]]);
      }
      // Bottom Mid If there is Sensor and Double Soket
      else if (((lightColorIndex == 4 && ciftSensorPos == 1) || (yataySensor && yataySensorPos == 1) || (dikeySensor && dikeySensorPos == 1)) && (doubleSoketCheck ? soketPos == 1 : false)) {
        setPositionTemp((prev) => [0.03, 0.175, prev[2]]);
      }
      // Bottom Mid If there is Sensor
      else if ((lightColorIndex == 4 && ciftSensorPos == 1) || (yataySensor && yataySensorPos == 1) || (dikeySensor && dikeySensorPos == 1)) {
        setPositionTemp((prev) => [0.05, 0.175, prev[2]]);
      }
      // Bottom Mid If there is Soket
      else if (soketCheck ? soketPos == 1 : false) {
        setPositionTemp((prev) => [0, 0.175, prev[2]]);
      }
      // Bottom Left If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 1 : false) {
        setPositionTemp((prev) => [0.03, 0.175, prev[2]]);
      }
      // Bottom Mid Default
      else {
        setPositionTemp((prev) => [-0.03, 0.175, prev[2]]);
      }
    } else if (isiSensorCheck && isiSensorPos == 2) {
      // Bottom Right If there is Sensor and Soket
      if (((yataySensor && yataySensorPos == 2) || (dikeySensor && dikeySensorPos == 2)) && (soketCheck ? soketPos == 2 : false)) {
        setPositionTemp((prev) => [0.09, 0.175, prev[2]]);
      }
      // Bottom Right If there is Sensor and Double Soket
      else if (((yataySensor && yataySensorPos == 2) || (dikeySensor && dikeySensorPos == 2)) && (doubleSoketCheck ? soketPos == 2 : false)) {
        setPositionTemp((prev) => [0.06, 0.175, prev[2]]);
      }
      // Bottom Right If there is Sensor
      else if ((yataySensor && yataySensorPos == 2) || (dikeySensor && dikeySensorPos == 2)) {
        setPositionTemp((prev) => [0.11, 0.175, prev[2]]);
      }
      // Bottom Right If there is Soket
      else if (soketCheck ? soketPos == 2 : false) {
        setPositionTemp((prev) => [0.12, 0.175, prev[2]]);
      }
      // Bottom Left If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 2 : false) {
        setPositionTemp((prev) => [0.08, 0.175, prev[2]]);
      }
      // Bottom Right Default
      else {
        setPositionTemp((prev) => [0.13, 0.175, prev[2]]);
      }
    } else if (isiSensorCheck && isiSensorPos == 3) {
      // Top Left If there is Sensor and Soket
      if (((yataySensor && yataySensorPos == 3) || (dikeySensor && dikeySensorPos == 3)) && (soketCheck ? soketPos == 3 : false)) {
        setPositionTemp((prev) => [-0.17, 0.86, prev[2]]);
      }
      // Top Left If there is Sensor and Double Soket
      else if (((yataySensor && yataySensorPos == 3) || (dikeySensor && dikeySensorPos == 3)) && (doubleSoketCheck ? soketPos == 3 : false)) {
        setPositionTemp((prev) => [-0.12, 0.86, prev[2]]);
      }
      // Top Left If there is Sensor
      else if ((yataySensor && yataySensorPos == 3) || (dikeySensor && dikeySensorPos == 3)) {
        setPositionTemp((prev) => [-0.22, 0.86, prev[2]]);
      }
      // Top Left If there is Soket
      else if (soketCheck ? soketPos == 3 : false) {
        setPositionTemp((prev) => [-0.15, 0.86, prev[2]]);
      }
      // Bottom Left If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 3 : false) {
        setPositionTemp((prev) => [-0.1, 0.86, prev[2]]);
      }
      // Top Left Default
      else {
        setPositionTemp((prev) => [-0.2, 0.86, prev[2]]);
      }
    } else if (isiSensorCheck && isiSensorPos == 4) {
      // Top Mid If there is Sensor and Soket
      if (((yataySensor && yataySensorPos == 4) || (dikeySensor && dikeySensorPos == 4)) && (soketCheck ? soketPos == 4 : false)) {
        setPositionTemp((prev) => [-0.02, 0.875, prev[2]]);
      }
      // Bottom Mid If there is Sensor and Double Soket
      else if (((yataySensor && yataySensorPos == 4) || (dikeySensor && dikeySensorPos == 4)) && (doubleSoketCheck ? soketPos == 4 : false)) {
        setPositionTemp((prev) => [0, 0.875, prev[2]]);
      }
      // Top Mid If there is Sensor
      else if ((yataySensor && yataySensorPos == 4) || (dikeySensor && dikeySensorPos == 4)) {
        setPositionTemp((prev) => [-0.05, 0.875, prev[2]]);
      }
      // Top Mid If there is Soket
      else if (soketCheck ? soketPos == 4 : false) {
        setPositionTemp((prev) => [0.01, 0.875, prev[2]]);
      }
      // Bottom Left If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 4 : false) {
        setPositionTemp((prev) => [0.03, 0.875, prev[2]]);
      }
      // Top Mid Default
      else {
        setPositionTemp((prev) => [-0.03, 0.875, prev[2]]);
      }
    } else if (isiSensorCheck && isiSensorPos == 5) {
      // Top Right If there is Sensor and Soket
      if (((yataySensor && yataySensorPos == 5) || (dikeySensor && dikeySensorPos == 5)) && (soketCheck ? soketPos == 5 : false)) {
        setPositionTemp((prev) => [0.07, 0.86, prev[2]]);
      }
      // Top Right If there is Sensor and Double Soket
      else if (((yataySensor && yataySensorPos == 5) || (dikeySensor && dikeySensorPos == 5)) && (doubleSoketCheck ? soketPos == 5 : false)) {
        setPositionTemp((prev) => [0.01, 0.86, prev[2]]);
      }
      // Top Right If there is Sensor
      else if ((yataySensor && yataySensorPos == 5) || (dikeySensor && dikeySensorPos == 5)) {
        setPositionTemp((prev) => [0.11, 0.86, prev[2]]);
      }
      // Top Right If there is Soket
      else if (soketCheck ? soketPos == 5 : false) {
        setPositionTemp((prev) => [0.09, 0.86, prev[2]]);
      }
      // Top Right If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 5 : false) {
        setPositionTemp((prev) => [0.04, 0.86, prev[2]]);
      }
      // Top Right Default
      else {
        setPositionTemp((prev) => [0.13, 0.86, prev[2]]);
      }
    } else if (isiSensorCheck && isiSensorPos == 6) {
      // Mid Right If there is Sensor and Soket
      if (((yataySensor && yataySensorPos == 6) || (dikeySensor && dikeySensorPos == 6)) && (soketCheck ? soketPos == 6 : false)) {
        setPositionTemp((prev) => [0.25, 0.5, prev[2]]);
      }
      // Mid Right If there is Sensor and Double Soket
      else if (((yataySensor && yataySensorPos == 6) || (dikeySensor && dikeySensorPos == 6)) && (doubleSoketCheck ? soketPos == 6 : false)) {
        setPositionTemp((prev) => [0.21, 0.5, prev[2]]);
      }
      // Mid Right If there is Sensor
      else if ((yataySensor && yataySensorPos == 6) || (dikeySensor && dikeySensorPos == 6)) {
        setPositionTemp((prev) => [0.27, 0.5, prev[2]]);
      }
      // Mid Right If there is Soket
      else if (soketCheck ? soketPos == 6 : false) {
        setPositionTemp((prev) => [0.27, 0.5, prev[2]]);
      }
      // Mid Right If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 6 : false) {
        setPositionTemp((prev) => [0.24, 0.5, prev[2]]);
      }
      // Mid Right Default
      else {
        setPositionTemp((prev) => [0.29, 0.5, prev[2]]);
      }
    } else if (isiSensorCheck && isiSensorPos == 7) {
      // Mid Left If there is Sensor and Soket
      if (((yataySensor && yataySensorPos == 7) || (dikeySensor && dikeySensorPos == 7)) && (soketCheck ? soketPos == 7 : false)) {
        setPositionTemp((prev) => [-0.35, 0.5, prev[2]]);
      }
      // Mid Left If there is Sensor and Double Soket
      else if (((yataySensor && yataySensorPos == 7) || (dikeySensor && dikeySensorPos == 7)) && (doubleSoketCheck ? soketPos == 7 : false)) {
        setPositionTemp((prev) => [-0.29, 0.5, prev[2]]);
      }
      // Mid Left If there is Sensor
      else if ((yataySensor && yataySensorPos == 7) || (dikeySensor && dikeySensorPos == 7)) {
        setPositionTemp((prev) => [-0.37, 0.5, prev[2]]);
      }
      // Mid Left If there is Soket
      else if (soketCheck ? soketPos == 7 : false) {
        setPositionTemp((prev) => [-0.33, 0.5, prev[2]]);
      }
      // Mid Left If there is Double Soket
      else if (doubleSoketCheck ? soketPos == 7 : false) {
        setPositionTemp((prev) => [-0.27, 0.5, prev[2]]);
      }
      // Mid Left Default
      else {
        setPositionTemp((prev) => [-0.35, 0.5, prev[2]]);
      }
    }

    // Soket pos
    if (soketPos == 0) {
      // Bottom Left If there is sensor and temp
      if (((yataySensor && yataySensorPos == 0) || (dikeySensor && dikeySensorPos == 0)) && (isiSensorCheck ? isiSensorPos == 0 : false)) {
        setPositionSoket((prev) => [-0.225, 0.187, prev[2]]);
      }
      // Bottom Left If there is sensor or temp
      else if ((yataySensor && yataySensorPos == 0) || (dikeySensor && dikeySensorPos == 0) || (isiSensorCheck && isiSensorPos == 0)) {
        setPositionSoket((prev) => [-0.225, 0.187, prev[2]]);
      }
      // Bottom Left Default
      else {
        setPositionSoket((prev) => [-0.2, 0.175, prev[2]]);
      }
    } else if (soketPos == 1) {
      // Bottom Mid  If there is sensor and temp
      if (((yataySensor && yataySensorPos == 1) || (dikeySensor && dikeySensorPos == 1)) && (isiSensorCheck ? isiSensorPos == 1 : false)) {
        setPositionSoket((prev) => [-0.07, 0.187, prev[2]]);
      }
      // Bottom Mid  If there is sensor or temp
      else if ((yataySensor && yataySensorPos == 1) || (dikeySensor && dikeySensorPos == 1) || (isiSensorCheck && isiSensorPos == 1)) {
        setPositionSoket((prev) => [-0.055, 0.187, prev[2]]);
      }
      // Bottom Mid Default
      else {
        setPositionSoket((prev) => [-0.025, 0.175, prev[2]]);
      }
    } else if (soketPos == 2) {
      // Bottom Right If there is Sensor and Temp
      if (((yataySensor && yataySensorPos == 2) || (dikeySensor && dikeySensorPos == 2)) && (isiSensorCheck ? isiSensorPos == 2 : false)) {
        setPositionSoket((prev) => [0.185, 0.187, prev[2]]);
      }
      // Bottom Right If there is Sensor or Temp
      else if ((yataySensor && yataySensorPos == 2) || (dikeySensor && dikeySensorPos == 2) || (isiSensorCheck && isiSensorPos == 2)) {
        setPositionSoket((prev) => [0.17, 0.187, prev[2]]);
      }
      // Bottom Right Default
      else {
        setPositionSoket((prev) => [0.13, 0.175, prev[2]]);
      }
    } else if (soketPos == 3) {
      // Top Left If there is Sensor and Temp
      if (((yataySensor && yataySensorPos == 3) || (dikeySensor && dikeySensorPos == 3)) && (isiSensorCheck ? isiSensorPos == 3 : false)) {
        setPositionSoket((prev) => [-0.23, 0.872, prev[2]]);
      }
      // Top Left If there is Sensor or Temp
      else if ((yataySensor && yataySensorPos == 3) || (dikeySensor && dikeySensorPos == 3) || (isiSensorCheck && isiSensorPos == 3)) {
        setPositionSoket((prev) => [-0.21, 0.872, prev[2]]);
      }
      // Top Left Default
      else {
        setPositionSoket((prev) => [-0.2, 0.86, prev[2]]);
      }
    } else if (soketPos == 4) {
      // Top Mid  If there is Sensor and Temp
      if (((yataySensor && yataySensorPos == 4) || (dikeySensor && dikeySensorPos == 4)) && (isiSensorCheck ? isiSensorPos == 4 : false)) {
        setPositionSoket((prev) => [-0.08, 0.885, prev[2]]);
      }
      // Top Mid  If there is Sensor or Temp
      else if ((yataySensor && yataySensorPos == 4) || (dikeySensor && dikeySensorPos == 4) || (isiSensorCheck && isiSensorPos == 4)) {
        setPositionSoket((prev) => [-0.05, 0.885, prev[2]]);
      }
      // Top Mid Default
      else {
        setPositionSoket((prev) => [-0.025, 0.875, prev[2]]);
      }
    } else if (soketPos == 5) {
      // Top Right  If there is Sensor and Temp
      if (((yataySensor && yataySensorPos == 5) || (dikeySensor && dikeySensorPos == 5)) && (isiSensorCheck ? isiSensorPos == 5 : false)) {
        setPositionSoket((prev) => [0.165, 0.872, prev[2]]);
      }
      // Top Right If there is Sensor or Temp
      else if ((yataySensor && yataySensorPos == 5) || (dikeySensor && dikeySensorPos == 5) || (isiSensorCheck && isiSensorPos == 5)) {
        setPositionSoket((prev) => [0.15, 0.872, prev[2]]);
      }
      // Top Right Default
      else {
        setPositionSoket((prev) => [0.13, 0.86, prev[2]]);
      }
    } else if (soketPos == 6) {
      // Mid Right If there is Sensor and Temp
      if (((yataySensor && yataySensorPos == 6) || (dikeySensor && dikeySensorPos == 6)) && (isiSensorCheck ? isiSensorPos == 6 : false)) {
        setPositionSoket((prev) => [0.345, 0.513, prev[2]]);
      }
      // Mid Right If there is Sensor or Temp
      else if ((yataySensor && yataySensorPos == 6) || (dikeySensor && dikeySensorPos == 6) || (isiSensorCheck && isiSensorPos == 6)) {
        setPositionSoket((prev) => [0.33, 0.513, prev[2]]);
      }
      // Mid Right Default
      else {
        setPositionSoket((prev) => [0.29, 0.5, prev[2]]);
      }
    } else if (soketPos == 7) {
      // Mid Left If there is Sensor and Temp
      if (((yataySensor && yataySensorPos == 7) || (dikeySensor && dikeySensorPos == 7)) && (isiSensorCheck ? isiSensorPos == 7 : false)) {
        setPositionSoket((prev) => [-0.41, 0.512, prev[2]]);
      }
      // Mid Left If there is Sensor or Temp
      else if ((yataySensor && yataySensorPos == 7) || (dikeySensor && dikeySensorPos == 7) || (isiSensorCheck && isiSensorPos == 7)) {
        setPositionSoket((prev) => [-0.39, 0.512, prev[2]]);
      }
      // Mid Left Default
      else {
        setPositionSoket((prev) => [-0.36, 0.5, prev[2]]);
      }
    }

    // Double Soket pos
    if (soketPos == 0) {
      // Bottom Left If there is sensor and temp
      if (((yataySensor && yataySensorPos == 0) || (dikeySensor && dikeySensorPos == 0)) && (isiSensorCheck ? isiSensorPos == 0 : false)) {
        setPositionDoubleSoket((prev) => [-0.225, 0.187, prev[2]]);
      }
      // Bottom Left If there is sensor or temp
      else if ((yataySensor && yataySensorPos == 0) || (dikeySensor && dikeySensorPos == 0) || (isiSensorCheck && isiSensorPos == 0)) {
        setPositionDoubleSoket((prev) => [-0.225, 0.187, prev[2]]);
      }
      // Bottom Left Default
      else {
        setPositionDoubleSoket((prev) => [-0.2, 0.175, prev[2]]);
      }
    } else if (soketPos == 1) {
      // Bottom Mid  If there is sensor and temp
      if (((yataySensor && yataySensorPos == 1) || (dikeySensor && dikeySensorPos == 1)) && (isiSensorCheck ? isiSensorPos == 1 : false)) {
        setPositionDoubleSoket((prev) => [-0.11, 0.187, prev[2]]);
      }
      // Bottom Mid  If there is sensor or temp
      else if ((yataySensor && yataySensorPos == 1) || (dikeySensor && dikeySensorPos == 1) || (isiSensorCheck && isiSensorPos == 1)) {
        setPositionDoubleSoket((prev) => [-0.08, 0.187, prev[2]]);
      }
      // Bottom Mid Default
      else {
        setPositionDoubleSoket((prev) => [-0.045, 0.175, prev[2]]);
      }
    } else if (soketPos == 2) {
      // Bottom Right If there is Sensor and Temp
      if (((yataySensor && yataySensorPos == 2) || (dikeySensor && dikeySensorPos == 2)) && (isiSensorCheck ? isiSensorPos == 2 : false)) {
        setPositionDoubleSoket((prev) => [0.1575, 0.187, prev[2]]);
      }
      // Bottom Right If there is Sensor or Temp
      else if ((yataySensor && yataySensorPos == 2) || (dikeySensor && dikeySensorPos == 2) || (isiSensorCheck && isiSensorPos == 2)) {
        setPositionDoubleSoket((prev) => [0.14, 0.187, prev[2]]);
      }
      // Bottom Right Default
      else {
        setPositionDoubleSoket((prev) => [0.11, 0.175, prev[2]]);
      }
    } else if (soketPos == 3) {
      // Top Left If there is Sensor and Temp
      if (((yataySensor && yataySensorPos == 3) || (dikeySensor && dikeySensorPos == 3)) && (isiSensorCheck ? isiSensorPos == 3 : false)) {
        setPositionDoubleSoket((prev) => [-0.232, 0.872, prev[2]]);
      }
      // Top Left If there is Sensor or Temp
      else if ((yataySensor && yataySensorPos == 3) || (dikeySensor && dikeySensorPos == 3) || (isiSensorCheck && isiSensorPos == 3)) {
        setPositionDoubleSoket((prev) => [-0.21, 0.872, prev[2]]);
      }
      // Top Left Default
      else {
        setPositionDoubleSoket((prev) => [-0.2, 0.86, prev[2]]);
      }
    } else if (soketPos == 4) {
      // Top Mid  If there is Sensor and Temp
      if (((yataySensor && yataySensorPos == 4) || (dikeySensor && dikeySensorPos == 4)) && (isiSensorCheck ? isiSensorPos == 4 : false)) {
        setPositionDoubleSoket((prev) => [-0.11, 0.885, prev[2]]);
      }
      // Top Mid  If there is Sensor or Temp
      else if ((yataySensor && yataySensorPos == 4) || (dikeySensor && dikeySensorPos == 4) || (isiSensorCheck && isiSensorPos == 4)) {
        setPositionDoubleSoket((prev) => [-0.08, 0.885, prev[2]]);
      }
      // Top Mid Default
      else {
        setPositionDoubleSoket((prev) => [-0.045, 0.875, prev[2]]);
      }
    } else if (soketPos == 5) {
      // Top Right  If there is Sensor and Temp
      if (((yataySensor && yataySensorPos == 5) || (dikeySensor && dikeySensorPos == 5)) && (isiSensorCheck ? isiSensorPos == 5 : false)) {
        setPositionDoubleSoket((prev) => [0.12, 0.872, prev[2]]);
      }
      // Top Right If there is Sensor or Temp
      else if ((yataySensor && yataySensorPos == 5) || (dikeySensor && dikeySensorPos == 5) || (isiSensorCheck && isiSensorPos == 5)) {
        setPositionDoubleSoket((prev) => [0.1, 0.872, prev[2]]);
      }
      // Top Right Default
      else {
        setPositionDoubleSoket((prev) => [0.12, 0.86, prev[2]]);
      }
    } else if (soketPos == 6) {
      // Mid Right If there is Sensor and Temp
      if (((yataySensor && yataySensorPos == 6) || (dikeySensor && dikeySensorPos == 6)) && (isiSensorCheck ? isiSensorPos == 6 : false)) {
        setPositionDoubleSoket((prev) => [0.32, 0.513, prev[2]]);
      }
      // Mid Right If there is Sensor or Temp
      else if ((yataySensor && yataySensorPos == 6) || (dikeySensor && dikeySensorPos == 6) || (isiSensorCheck && isiSensorPos == 6)) {
        setPositionDoubleSoket((prev) => [0.3, 0.513, prev[2]]);
      }
      // Mid Right Default
      else {
        setPositionDoubleSoket((prev) => [0.29, 0.5, prev[2]]);
      }
    } else if (soketPos == 7) {
      // Mid Left If there is Sensor and Temp
      if (((yataySensor && yataySensorPos == 7) || (dikeySensor && dikeySensorPos == 7)) && (isiSensorCheck ? isiSensorPos == 7 : false)) {
        setPositionDoubleSoket((prev) => [-0.41, 0.512, prev[2]]);
      }
      // Mid Left If there is Sensor or Temp
      else if ((yataySensor && yataySensorPos == 7) || (dikeySensor && dikeySensorPos == 7) || (isiSensorCheck && isiSensorPos == 7)) {
        setPositionDoubleSoket((prev) => [-0.39, 0.512, prev[2]]);
      }
      // Mid Left Default
      else {
        setPositionDoubleSoket((prev) => [-0.36, 0.5, prev[2]]);
      }
    }

    // ---
  }, [
    watchPos,
    soket,
    soketPos,
    dikeySensorPos,
    yataySensorPos,
    isiSensorPos,
    watchPos,
    soket,
    digitalWatch,
    analogWatch,
    yataySensor,
    dikeySensor,
    isiSensor,
    makeupMirror,
    makeupMirrorPos,
    ciftSensorPos,
    lightColorIndex,
  ]);
  // ----

  // Double Sensor Positions
  useEffect(() => {
    if (ciftSensorPos == 0) {
      if (doubleSoketCheck && soketPos === 0) {
        setCiftSensorPosition([-0.04, 0, 0]);
      } else {
        setCiftSensorPosition([-0.12, 0, 0]);
      }
    } else if (ciftSensorPos == 1) {
      if (doubleSoketCheck && soketPos === 1 && isiSensorCheck && isiSensorPos === 1) {
        setCiftSensorPosition([0.11, 0, 0]);
      } else if (isiSensorCheck && isiSensorPos === 1) {
        setCiftSensorPosition([0.03, 0, 0]);
      } else {
        setCiftSensorPosition([0.005, 0, 0]);
      }
    } else if (ciftSensorPos == 2) {
      if (doubleSoketCheck && soketPos === 2 && isiSensorCheck && isiSensorPos === 2) {
        setCiftSensorPosition([0.07, 0, 0]);
      } else {
        setCiftSensorPosition([0.115, 0, 0]);
      }
    }
  }, [ciftSensorPos, soketPos, doubleSoketCheck]);

  // function changeLighstThickness(value) {
  //   setYuvarlakLEDScale(value);
  //   yuvarlakLED.current.scale.x = value;
  //   yuvarlakLED.current.scale.z = value;
  // }
  const isPluto2 = mirrorTickness === 0.66;

  useEffect(() => {
    if (isPluto2) {
      yuvarlakLED.current.scale.x = mirrorTickness - 0.1;
      yuvarlakLED.current.scale.z = mirrorTickness - 0.1;
    } else {
      yuvarlakLED.current.scale.x = mirrorTickness;
      yuvarlakLED.current.scale.z = mirrorTickness;
    }
  }, [mirrorTickness]);

  return (
    <>
      {heightCmVisible && (
        <CentimeterHeightLines
          textWidth={mirrorWidth > 999 ? "85px" : "75px"}
          textPadding={mirrorWidth > 999 ? "0 12px" : "0 15px"}
          textValue={mirrorWidth == 0 ? "400" : mirrorWidth}
          textTop={110 - mirrorWidth * 0.3}
          textSize={"1.6em"}
          textHeight="40px"
          textPos={[0.6, 0.36, 0.02]}
          scale={[0.3, 0.12, 0.15]}
          lineTop={100 - mirrorWidth * 0.7}
          lineHeight={0 + mirrorWidth * 1.01}
          position={[-0.45 + mirrorWidth * 0.00175, -0.32, 0.18]}
        />
      )}
      {widthCmVisible && (
        <CentimeterWidthLines
          scale={[0.12, 0.3, 0.15]}
          textWidth={mirrorWidth > 999 ? "85px" : "75px"}
          textPadding={mirrorWidth > 999 ? "0 12px" : "0 15px"}
          textValue={mirrorWidth == 0 ? "400" : mirrorWidth}
          textTop={110 - mirrorWidth * 0.3}
          textSize={"1.6em"}
          textHeight="40px"
          lineLeft={-53 - mirrorWidth * 0.545}
          lineWidth={1.9 + mirrorWidth * 1.13}
          position={[0, -0.9 + mirrorWidth * 0.0023, 0.18]}
        />
      )}
      <group
        ref={daireAyna}
        position={[0, -0.45 - mirrorWidth * 0.00075, 0.2]}
        rotation={[0, 0, 0]}
        scale={[((2.3 * mirrorWidth) / 400) * 0.5 + 0.3, ((2.3 * mirrorWidth) / 400) * 0.5 + 0.3, 1.6 * 0.5]}
        {...props}
        dispose={null}
      >
        {makeupMirrorSensor == 2 && <Touch position={[0.07, 0.175, 0.1]} scale={[1, 1, 1]} rotation={[Math.PI * 1.5, 0, 0]} />}
        {digitalWatch && (
          <mesh castShadow receiveShadow geometry={nodes.dijitalSAAT001.geometry} material={materials.ledtexture} position={positionDigital} rotation={[Math.PI / 2, 0, 0]} scale={1.5}></mesh>
        )}

        {yataySensor === true && (
          <mesh castShadow receiveShadow geometry={nodes["SENSÖR-yatay001"].geometry} material={materials.ledtexture} position={positionSensor} rotation={[Math.PI / 2, 0, 0]} scale={1}></mesh>
        )}
        {soket == 1 ? <Plug color="white" position={positionSoket} /> : soket == 2 ? <Plug color="gray" position={positionSoket} /> : null}
        {soket == 3 ? (
          <group position={positionDoubleSoket}>
            <Plug color="white" />
            <Plug position={[0.047, 0, 0]} color="white" />
          </group>
        ) : soket == 4 ? (
          <group position={positionDoubleSoket}>
            <Plug color="gray" />
            <Plug color="gray" position={[0.047, 0, 0]} />
          </group>
        ) : null}

        <mesh position={[-0.03, 0.52, -0.03]} rotation={[Math.PI / 2, 0, 0]} scale={1}>
          <cylinderGeometry args={[0.45, 0.45, 0.05, 64]} />
          <meshBasicMaterial color={"#666666"} />
        </mesh>
        {isiSensorCheck && <TempSensor position={positionTemp} rotation={[0, 0, 0]} scale={1} />}
        {dikeySensor === true && (
          <mesh castShadow receiveShadow geometry={nodes["SENSÖR-kare001"].geometry} material={materials.touchdimmertexture} position={positionSensor} rotation={[Math.PI / 2, 0, 0]} scale={1}>
            <meshBasicMaterial color={[2, 1, 1]} toneMapped={false} />
          </mesh>
        )}

        <mesh geometry={nodes.yuvarlakLED.geometry} material={materials.ledtexture} position={[-0.03, 0.52, -0.03]} rotation={[Math.PI / 2, 0, 0]} scale={[0.95, 0.01, 0.95]}>
          <meshBasicMaterial color={lightColor} toneMapped={false} />
        </mesh>

        <mesh
          name="yuvarlakLED"
          ref={yuvarlakLED}
          castShadow
          receiveShadow
          geometry={nodes.yuvarlakLED.geometry}
          material={materials.ledtexture}
          scale={[1, 0.1, 1]}
          position={[-0.03, 0.52, 0.06]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <meshStandardMaterial color={lightColor} roughness={1} toneMapped={false} />
        </mesh>
        {isPluto2 && (
          <mesh geometry={nodes.yuvarlakLED.geometry} material={materials.ledtexture} scale={[0.94, 0.1, 0.94]} position={[-0.03, 0.52, 0.1]} rotation={[Math.PI / 2, 0, 0]}>
            <meshStandardMaterial color={lightColor} roughness={1} toneMapped={false} />
          </mesh>
        )}

        <group name="yuvarlak_AYNA" position={[-0.03, 0.52, 0.08]} rotation={[Math.PI / 2, 0, 0]} scale={[1.06, 0.21, 1.06]}>
          <mesh name="yuvarlak_AYNA_1" castShadow receiveShadow geometry={nodes.yuvarlak_AYNA_1.geometry} material={materials.ayna} />
          <mesh name="yuvarlak_AYNA_2" castShadow receiveShadow geometry={nodes.yuvarlak_AYNA_2.geometry} material={materials.grimetal} />
        </group>
        <mesh
          name="arkadan_aydınlatma"
          castShadows
          receiveShadow
          geometry={nodes.arkadan_aydınlatma.geometry}
          material={materials.ledtexture}
          position={[-0.03, 0.52, 0.08]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <meshBasicMaterial color={[2, 1, 1]} toneMapped={false} />
        </mesh>
        {analogWatch && (
          <group position={positionAnalog} rotation={[Math.PI / 2, 0, 0]}>
            <mesh castShadow receiveShadow geometry={nodes.AKREP_YELKOVAN_1.geometry} material={materials.siyah} />
            <mesh castShadow receiveShadow geometry={nodes.AKREP_YELKOVAN_2.geometry} material={materials.wire_086086086} />
            <mesh castShadow receiveShadow geometry={nodes.AKREP_YELKOVAN_3.geometry} material={materials.grimetal} />
          </group>
        )}
        {lightColorIndex == 4 && (
          <>
            <group position={ciftSensorPosition}>
              <mesh castShadow receiveShadow geometry={nodes["SENSÖR-kare001"].geometry} material={materials.ledtexture} position={[-0.05, 0.175, 0.1]} rotation={[Math.PI / 2, 0, 0]} scale={1}>
                {/* <meshBasicMaterial color={[2, 1, 1]} toneMapped={false} /> */}
              </mesh>
              <mesh castShadow receiveShadow geometry={nodes["SENSÖR-kare001"].geometry} material={materials.ledtexture} position={[-0.02, 0.175, 0.1]} rotation={[Math.PI / 2, 0, 0]} scale={1}>
                {/* <meshBasicMaterial color={[2, 1, 1]} toneMapped={false} /> */}
              </mesh>
            </group>
          </>
        )}
      </group>
    </>
  );
}

useGLTF.preload("/daire.glb");

export { Daire };
