import React from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMakeupMirrorType } from "../../../store/mirrors";
import { setMakyajTypeFee } from "../../../store/mirrorFee";

const MakyajAynasıTipi = () => {
  const dispatch = useDispatch();
  const { makeupMirrorType } = useSelector((state) => state.mirror);

  const handleChange = (e) => {
    dispatch(setMakeupMirrorType(e.target.value));
    dispatch(setMakyajTypeFee(e.target.value == 1 ? 49.99 : 0));
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Schminkspiegel Typ
      </Typography>
      <input name="id[81]" value={makeupMirrorType == 0 ? 716 : makeupMirrorType == 1 ? 717 : null}></input>
      <Select onChange={handleChange} value={makeupMirrorType} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>Ø 152 mm, auf Glas</MenuItem>
        <MenuItem value={1}>Ø 152 mm, integriert + 49,99 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default MakyajAynasıTipi;
