import React from "react";
import { Plug } from "../../../../OrtakComponents/Plug";
import { TempSensor } from "../../../../OrtakComponents/TempSensor";
import YataySensor from "../YataySensor";
import DikeySensor from "../DikeySensor";
import AnalogWatch from "../AnalogWatch";
import DigitalWatch from "../DigitalWatch";
import Touch from "../../../../OrtakComponents/Sensor/Touch";
import DoubleSensor from "../DoubleSensor";
import { useSelector } from "react-redux";

const ObjelerRight = (props) => {
  const {
    soket,
    lightColorIndex,
    analogWatch,
    digitalWatch,
    yataySensor,
    dikeySensor,
    makeupMirror,
    isiSensor,
    makeupMirrorSensorPos,
    makeupMirrorSensor,
    yataySensorPos,
    dikeySensorPos,
    soketPos,
    watchPos,
    isiSensorPos,
    ciftSensorPos,
    mirrorHeight,
    mirrorWidth,
  } = useSelector((state) => state.mirror);

  const isiSensorCheck = (isiSensor === 2 || isiSensor === 3) && isiSensorPos == 2;
  const soketCheck = ((soket === 1 || soket === 2) && soketPos == 1) || ((soket === 3 || soket === 4) && soketPos == 2);
  const soketLeftRightCheck = (soket === 3 || soket === 4) && soketPos == 2;
  const doubleSoketCheck = (soket === 3 || soket === 4) && soketPos == 1;
  const hasMakeupMirror = makeupMirror == 2 && makeupMirrorSensor == 2 && makeupMirrorSensorPos == 3;
  const hasDoubleSensor = lightColorIndex == 4 && ciftSensorPos == 2;
  const hasVerticalSensor = hasDoubleSensor || (yataySensor && yataySensorPos == 2) || (dikeySensor && dikeySensorPos == 2);

  const hasOnlyVerticalSensor = !hasMakeupMirror && !isiSensorCheck && !soketCheck && hasVerticalSensor && !doubleSoketCheck;
  const hasOnlySoket = !hasMakeupMirror && !hasVerticalSensor && !isiSensorCheck && (soketCheck || doubleSoketCheck);
  const hasOnlyWatch = !hasVerticalSensor && !doubleSoketCheck && !soketCheck && !hasMakeupMirror && !isiSensorCheck;
  const hasOnlyMakeupMirror = !hasVerticalSensor && !doubleSoketCheck && !soketCheck && !isiSensorCheck && hasMakeupMirror;

  const hasOnlyIsiAndSoket = (soketCheck || doubleSoketCheck) && isiSensorCheck && !hasVerticalSensor && !hasMakeupMirror;
  const hasOnlyIsiAndTouchSensor = hasVerticalSensor && isiSensorCheck && !hasMakeupMirror && !soketCheck && !doubleSoketCheck;
  const hasOnlyIsiAndMakeup = hasMakeupMirror && isiSensorCheck && !hasVerticalSensor && !soketCheck && !doubleSoketCheck;

  const hasOnlyTouchAndMakeup = hasVerticalSensor && hasMakeupMirror && !isiSensorCheck && !soketCheck && !doubleSoketCheck;
  const hasOnlyTouchAndSoket = hasVerticalSensor && (soketCheck || doubleSoketCheck) && !isiSensorCheck && !hasMakeupMirror;

  const hasOnlyMakeupAndSoket = hasMakeupMirror && (soketCheck || doubleSoketCheck) && !isiSensorCheck && !hasVerticalSensor;

  const has_Isi_Makeup_Touch = isiSensorCheck && hasMakeupMirror && hasVerticalSensor && !soketCheck && !doubleSoketCheck;
  const has_Isi_Makeup_Soket = isiSensorCheck && hasMakeupMirror && !hasVerticalSensor && (soketCheck || doubleSoketCheck);
  const has_Isi_Touch_Soket = isiSensorCheck && !hasMakeupMirror && hasVerticalSensor && (soketCheck || doubleSoketCheck);

  const has_touch_soket_makeup = !isiSensorCheck && hasMakeupMirror && hasVerticalSensor && (soketCheck || doubleSoketCheck);

  const watchHorizontalPosition = () => {
    switch (true) {
      case hasOnlyVerticalSensor:
        return 0.04;
      case soketLeftRightCheck && isiSensorCheck && hasVerticalSensor:
        return 0.02;
      case soketLeftRightCheck && isiSensorCheck:
        return 0.05;
      case soketLeftRightCheck:
        return 0.09;
      case hasOnlySoket:
        return -0.07;
      case hasOnlyMakeupMirror:
        return 0.08;
      case hasOnlyIsiAndSoket:
        return -0.03;
      case hasOnlyIsiAndTouchSensor:
        return 0.02;
      case hasOnlyIsiAndMakeup:
        return 0.04;
      case hasOnlyTouchAndMakeup:
        return 0.06;
      case hasOnlyTouchAndSoket:
        return -0.01;
      case hasOnlyMakeupAndSoket:
        return 0.01;
      case has_Isi_Makeup_Touch:
        return 0.04;
      case has_Isi_Makeup_Soket:
        return 0.02;
      case has_Isi_Touch_Soket:
        return 0;
      case has_touch_soket_makeup:
        return 0.01;
      default:
        return 0;
    }
  };

  const watchVerticalPosition = hasOnlyWatch ? -0.04 : 0.05;
  const onlyDoubleSoket = hasVerticalSensor && isiSensorCheck;

  return (
    <group {...props} scale={0.75 + mirrorWidth * 0.00075 + mirrorHeight * 0.00075}>
      {/* <mesh>
        <boxGeometry args={[0.3, 0.25, 0.01]} />
        <meshBasicMaterial color={"blue"} />
      </mesh> */}
      {/* OBJELER */}
      {soketCheck && soket == 1 ? (
        <Plug color="white" position={[-0.07, -0.04, 0.025]} />
      ) : soketCheck && soket == 2 ? (
        <Plug color="gray" position={[-0.07, -0.04, 0.025]} />
      ) : soketCheck && soket == 3 ? (
        <Plug color="white" position={[onlyDoubleSoket ? -0.07 : hasVerticalSensor ? -0.04 : 0.08, -0.04, 0.025]} />
      ) : soketCheck && soket == 4 ? (
        <Plug color="gray" position={[onlyDoubleSoket ? -0.07 : hasVerticalSensor ? -0.04 : 0.08, -0.04, 0.025]} />
      ) : null}
      {doubleSoketCheck && soket == 3 ? (
        <group position={[-0.07, -0.04, 0.025]}>
          <Plug color="white" />
          <Plug position={[-0.05, 0, 0]} color="white" />
        </group>
      ) : doubleSoketCheck && soket == 4 ? (
        <group position={[-0.07, -0.04, 0.025]}>
          <Plug color="gray" />
          <Plug color="gray" position={[-0.05, 0, 0]} />
        </group>
      ) : null}
      {isiSensorCheck && <TempSensor position={[0, -0.05, 0.01]} rotation={[0, 0, 0]} scale={1.2} />}
      {yataySensor && yataySensorPos == 2 && <YataySensor position={[0.04, -0.05, 0.01]} />}
      {dikeySensor && dikeySensorPos == 2 && <DikeySensor position={[0.04, -0.05, 0.01]} />}
      {analogWatch && watchPos == 2 && <AnalogWatch position={[watchHorizontalPosition(), watchVerticalPosition, 0.01]} />}
      {digitalWatch && watchPos == 2 && <DigitalWatch position={[watchHorizontalPosition(), watchVerticalPosition, 0.01]} />}
      {makeupMirror == 2 && makeupMirrorSensor == 2 && makeupMirrorSensorPos == 3 && <Touch position={[0.105, -0.05, 0.01]} scale={1.2} rotation={[Math.PI * 1.5, 0, 0]} />}
      {hasDoubleSensor && <DoubleSensor position={[0.07, -0.26, -0.11]} scale={1.2} />}
    </group>
  );
};

export default ObjelerRight;
