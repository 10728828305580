import React, { useEffect, useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";

export function MakyajAynasi({ position }) {
  const { nodes, materials } = useGLTF("/makyajaynasi.glb");

  const { makeupMirror, selectedMirror, mirrorWidth, makeupLightColor, doubleLed } = useSelector((state) => state.mirror);

  return (
    <>
      {makeupMirror != 0 && (
        <group rotation={[0, 0, 0]} scale={[1, 1, 1.5]} dispose={null}>
          <group position={position} rotation={[Math.PI / 2, 0, Math.PI]} scale={[1.2, 0.4, 1.2]}>
            <mesh geometry={nodes.Sphere001.geometry} material={materials.camRaf} />
            <mesh geometry={nodes.Sphere001_2.geometry} material={materials.ayna} />
            <mesh geometry={nodes.Sphere001_3.geometry} scale={[0.95, 1, 0.95]} position-y={0}>
              <meshStandardMaterial color="black" roughness={1} toneMapped={false} />
              {makeupMirror === 2 && (
                <meshBasicMaterial color={doubleLed ? [makeupLightColor[0] / 2, makeupLightColor[1] / 2, makeupLightColor[2] / 2] : makeupLightColor} roughness={1} toneMapped={false} />
              )}
            </mesh>
          </group>
        </group>
      )}
    </>
  );
}

useGLTF.preload("/makyajaynasi.glb");
