import React from "react";
import { Stack, Slider, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeLightColor } from "../../../store/cabinet";
import { setLedIsikFiyatı } from "../../../store/cabinetFee";

const LedIsıgı = () => {
  const dispatch = useDispatch();
  const { lightColorIndex } = useSelector((state) => state.cabinet);

  const handleChange = (e) => {
    dispatch(changeLightColor(e.target.value));
    e.target.value === 2 ||
      3 ||
      4 ||
      (5 && dispatch(setLedIsikFiyatı(e.target.value === 2 ? 79.99 : e.target.value === 3 ? 99.0 : e.target.value === 4 ? 179.99 : e.target.value === 5 ? 159.0 : 59.0)));
  };

  const getValue = () => {
    switch (lightColorIndex) {
      case 0:
        return 9;
      case 1:
        return 10;
      case 2:
        return 94;
      case 3:
        return 11;
    }
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Led Işığı
      </Typography>
      <input name="id[5]" value={getValue() || "9"} />
      <Select onChange={handleChange} value={lightColorIndex} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>LED Nötr Beyaz 4000K</MenuItem>
        <MenuItem value={1}>LED Sıcak Beyaz 3000K</MenuItem>
        <MenuItem value={2}>LED Soğuk Beyaz 6000K</MenuItem>
        <MenuItem value={3}>LED RGB, Uzaktan Kumanda Dahil + 79,99€</MenuItem>
      </Select>
    </Stack>
  );
};

export default LedIsıgı;
