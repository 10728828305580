import React, { useRef } from "react";
import DışAydınlatma from "../../../OrtakComponents/DışAydınlatma";
import { useSelector } from "react-redux";
import { EnumCabinetMirrors } from "../../../../store/cabinetMirror";
import { useGLTF } from "@react-three/drei";

export const DisAydinlatmalarGenel = () => {
  const { materials } = useGLTF("/Frankfurt-test2.glb");
  const { nodes: budapestNodes } = useGLTF("/newModels/budapest.glb");

  const { lightColor, cabinetMirrorWidth, cabinetMirrorHeight, cabinetMirrorType, externalLeds } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.cabinetMirror);

  const rightLed = useRef();
  const leftLed = useRef();
  const topLed = useRef();
  const bottomLed = useRef();
  const bodyInnerLed = useRef();

  const isAllVisible = externalLeds === 5;
  const isBottomAndTopLedVisible = externalLeds === 3;
  const isBottomLedVisible = isBottomAndTopLedVisible || externalLeds === 1;
  const isTopLedVisible = isBottomAndTopLedVisible || externalLeds === 2;
  const isSideLedVisible = isAllVisible || externalLeds === 4;

  const hasBottomShelf = [
    EnumCabinetMirrors.MUNCHEN_6,
    EnumCabinetMirrors.BUDAPEST,
    EnumCabinetMirrors.SANTANDER,
    EnumCabinetMirrors.LISSABON,
    EnumCabinetMirrors.LINZ,
    EnumCabinetMirrors.CORTINA,
    EnumCabinetMirrors.WINDSOR,
    EnumCabinetMirrors.VERONICA_2,
    EnumCabinetMirrors.PANDORA_2,
    EnumCabinetMirrors.KATHERINE_2,
  ].includes(selectedMirror);

  const hasPandora = [
    EnumCabinetMirrors.VERONICA_1,
    EnumCabinetMirrors.PANDORA_1,
    EnumCabinetMirrors.KATHERINE_1,
    EnumCabinetMirrors.VERONICA_2,
    EnumCabinetMirrors.PANDORA_2,
    EnumCabinetMirrors.KATHERINE_2,
  ].includes(selectedMirror);

  const bottomShelfIsAllVisible = isAllVisible && hasBottomShelf;

  const hasInnerLed = ![EnumCabinetMirrors.OXFORD, EnumCabinetMirrors.WESER, EnumCabinetMirrors.ELBE_1, EnumCabinetMirrors.WINDSOR].includes(selectedMirror) && !hasPandora;

  return (
    <>
      {hasInnerLed && (
        <mesh
          ref={bodyInnerLed}
          geometry={budapestNodes.interriorLed.geometry}
          material={materials.ledtexture}
          scale={[0.97 + cabinetMirrorWidth * 0.00008, 0.97 + cabinetMirrorHeight * 0.00003, 1]}
          position={[1.61, 1.47, -0.02 + cabinetMirrorType]}
        >
          <meshBasicMaterial color={[lightColor[0] * 0.25, lightColor[1] * 0.25, lightColor[2] * 0.25]} toneMapped={false} />
        </mesh>
      )}

      {/* DIŞARIDAKİ LEDLER */}
      {isSideLedVisible && (
        <>
          <DışAydınlatma
            ref={leftLed}
            scaleY={bottomShelfIsAllVisible ? 0.95 : isAllVisible ? 0.8 : 0.7}
            position={[0.863, bottomShelfIsAllVisible ? 1.4 : 1.47, 0.06 + cabinetMirrorType]}
            rotation={[0, Math.PI * 1.5, 0]}
          />
          <DışAydınlatma
            ref={rightLed}
            scaleY={bottomShelfIsAllVisible ? 0.95 : isAllVisible ? 0.8 : 0.7}
            position={[2.357 + cabinetMirrorWidth * 0.000001, bottomShelfIsAllVisible ? 1.4 : 1.47, 0.06 + cabinetMirrorType]}
            rotation={[0, Math.PI * 0.5, 0]}
          />
        </>
      )}
      {isBottomLedVisible && (
        <DışAydınlatma
          visible={isBottomLedVisible}
          ref={bottomLed}
          scaleY={1 + cabinetMirrorWidth * 0.00005}
          position={[1.6 + cabinetMirrorWidth * 0.000008, hasBottomShelf ? 0.92 : 1.074, 0.06 + cabinetMirrorType]}
          rotation={[Math.PI * 0.5, 0, Math.PI * 0.5]}
        />
      )}
      {isTopLedVisible && (
        <DışAydınlatma
          visible={isTopLedVisible}
          ref={topLed}
          scaleY={1 + cabinetMirrorWidth * 0.00005}
          position={[1.6 + cabinetMirrorWidth * 0.000008, 1.866, 0.06 + cabinetMirrorType]}
          rotation={[Math.PI * 1.5, 0, Math.PI * 1.5]}
        />
      )}
    </>
  );
};
