import React from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setKaplama } from "../../../store/mirrors";
import { setYanTamKaplamaFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";
const YanTamKaplama = () => {
  const dispatch = useDispatch();
  const { kaplama, selectedMirror } = useSelector((state) => state.mirror);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal title="Informationen zu den Seitenblenden/Vollverblendung">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Seiten-/ Vollverblendung
        </Typography>
      </RenderModal>
      <input name="id[125]" value={kaplama == 0 ? 1037 : kaplama == 1 ? 1038 : kaplama == 2 ? 1299 : null} />
      <Select
        onChange={(e) => {
          dispatch(setKaplama(e.target.value));
          dispatch(setYanTamKaplamaFee(e.target.value == 1 ? 19.99 : e.target.value == 2 ? 49.99 : 0));
        }}
        value={kaplama}
        sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
      >
        <MenuItem value={0}>---</MenuItem>
        {selectedMirror != 6699 ? <MenuItem value={1}>Seitenblenden + 19,99 €</MenuItem> : null}
        <MenuItem value={2}>Vollverblendung + 49,99 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default YanTamKaplama;
