import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";
import * as THREE from "three";
export default function OvalMirrorBottomRight(props) {
  const [scale, setScale] = useState([1.5, 1, 1]);
  const { mirrorWidth, mirrorHeight, lightColor } = useSelector((state) => state.mirror);
  const { nodes } = useGLTF("/newMirrorModels/F610L4K.glb");
  const { materials } = useGLTF("/rectangle-mirror-rev01.glb");

  const bottom = useRef(null);

  return (
    <group
      ref={bottom}
      rotation-z={-Math.PI * 0.5}
      rotation-y={Math.PI}
      scale={[0.99 + mirrorHeight * 0.002265, 1.27, 1.27]}
      position={[1.77 + mirrorWidth * 0.00077, 0.422 - mirrorHeight * 0.00002, -0.0095]}
    >
      <mesh
        name="mirrorLower"
        geometry={nodes.mirrorLower.geometry}
        scale={[1, mirrorHeight > 1000 ? 0.85 : 0.95, 1]}
        material={materials.mirror}
        position={[-0.015, mirrorHeight > 1000 ? 1.064 : 1.035, -0.0145]}
      />
      <mesh name="ledLightLower" geometry={nodes.ledLightLower.geometry} material={materials.led} scale-x={1.0031} position={[-0.0151, 1.008, -0.014]}>
        <meshStandardMaterial toneMapped={false} color={lightColor} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/F610L4K.glb");
