import React, { useEffect } from "react";
import { Stack, Slider, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeLavaboTavanLightSalter } from "../../../../store/cabinetMirror";
import { setLavaboTavanLightSalterFiyatı } from "../../../../store/cabinetMirrorFee";

const LavaboTavanLedSalter = () => {
  const dispatch = useDispatch();
  const { lavaboTavanLightSalter } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);

  const handleChange = (e) => {
    dispatch(changeLavaboTavanLightSalter(e.target.value));
    dispatch(setLavaboTavanLightSalterFiyatı(e.target.value === 1342 ? 0 : e.target.value === 1343 || e.target.value === 1344 ? 19.99 : 99, 99));
  };
  useEffect(() => {
    dispatch(changeLavaboTavanLightSalter(1342));
    dispatch(setLavaboTavanLightSalterFiyatı(0));
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        W/D separater Schalter
      </Typography>

      <input type="hidden" name="id[145]" value={lavaboTavanLightSalter} />

      <Select onChange={handleChange} value={lavaboTavanLightSalter} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={1342}>---</MenuItem>
        <MenuItem value={1343}>Kippschalter außen/unten links + 19,99 €</MenuItem>
        <MenuItem value={1344}>Kippschalter außen/unten rechts + 19,99 €</MenuItem>
        <MenuItem value={2126}>Gestensteuerung unten links + 99,00 €</MenuItem>
        <MenuItem value={2128}>Gestensteuerung unten rechts + 99,00 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default LavaboTavanLedSalter;
