import { createSlice } from "@reduxjs/toolkit";

const initalUtilsState = { selectedHeightLevel: 1 };

const heights = {
  level1: 1,
  level2: 2,
  level3: 3,
  level4: 4,
  level5: 5,
};

const utilsSlice = createSlice({
  name: "utils",
  initialState: initalUtilsState,
  reducers: {
    changeHeightMan(state, action) {
      state.selectedHeightLevel = action.payload;
    },
  },
});

export const { changeHeightMan } = utilsSlice.actions;

export default utilsSlice.reducer;
