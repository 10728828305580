import React, { useState } from "react";
import { Stack, Box, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setDoorHandle } from "../../../../store/cabinetMirror";
import { setDoorHandleFiyatı } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const CompartmentBottomCount = () => {
  const dispatch = useDispatch();
  const [bottomCompartmentCount, setBottomCompartmentCount] = useState(0);

  // const { doorHandle } = useSelector((state) => state.cabinetMirror);
  const handleChange = (e) => {
    setBottomCompartmentCount(e.target.value);
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="29">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Fächer unten Anzahl
        </Typography>
      </RenderModal>

      <input
        type="hidden"
        name="id[79]"
        value={
          bottomCompartmentCount == 0
            ? 702
            : bottomCompartmentCount == 1
            ? 703
            : bottomCompartmentCount == 2
            ? 704
            : bottomCompartmentCount == 3
            ? 705
            : bottomCompartmentCount == 4
            ? 706
            : bottomCompartmentCount == 5
            ? 707
            : null
        }
      />

      <Stack direction="row" justifyContent="space-evenly" sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <Select value={bottomCompartmentCount} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }} onChange={handleChange}>
          <MenuItem value={0}>1 Fach</MenuItem>
          <MenuItem value={1}>2 Fächer</MenuItem>
          <MenuItem value={2}>3 Fächer</MenuItem>
          <MenuItem value={3}>4 Fächer</MenuItem>
          <MenuItem value={4}>5 Fächer</MenuItem>
          <MenuItem value={5}>6 Fächer</MenuItem>
        </Select>
      </Stack>
    </Stack>
  );
};

export default CompartmentBottomCount;
