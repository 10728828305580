import React from "react";
import { DoubleSide } from "three";

const Room = (props) => {
  return (
    <mesh renderOrder={-1} {...props}>
      <boxGeometry attach="geometry" args={[300, 300, 300, 300]} />
      <meshBasicMaterial side={DoubleSide} color="#e3e3e3" />
      <mesh
        // renderOrder={-1}
        rotation={[0, 0, 0]}
        position={[5.663, 6.95, 0]}
      >
        <planeGeometry attach="geometry" args={[50, 20]} />
        <meshBasicMaterial
          // depthTest={false}
          side={DoubleSide}
          color="#c7c7c7"
        />
      </mesh>
    </mesh>
  );
};

export default Room;
