import React from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeMakeUpMirrorSensor } from "../../../store/mirrors";

const MakyajAynasiSensor = () => {
  const dispatch = useDispatch();
  const { makeupMirrorSensor } = useSelector((state) => state.mirror);
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Separater Lichtschalter
      </Typography>

      <input name="id[86]" value={makeupMirrorSensor == 0 ? 730 : makeupMirrorSensor == 1 ? 731 : makeupMirrorSensor == 2 ? 734 : null} />

      <Select
        onChange={(e) => {
          const value = e.target.value;
          dispatch(changeMakeUpMirrorSensor(value));
        }}
        value={makeupMirrorSensor}
        sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
      >
        <MenuItem value={0}>---</MenuItem>
        <MenuItem value={1}>Kippschalter + 19,99 €</MenuItem>
        <MenuItem value={2}>Touch-Sensor + 39,99 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default MakyajAynasiSensor;
