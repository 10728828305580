import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCustomDoorWidthValues, setDraggerVisible } from "../../../../store/cabinetMirror";

const KapiGenisligi = () => {
  const dispatch = useDispatch();
  const { draggerVisible } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const handleChange = (e) => {
    dispatch(setDraggerVisible(e.target.value));
  };

  useEffect(() => {
    dispatch(setDraggerVisible(0));
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Breite Türen in mm
      </Typography>

      <input name="id[235]" value={draggerVisible == 0 ? 2524 : draggerVisible == 1 ? 2525 : null} />

      <Select onChange={handleChange} value={draggerVisible} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>alle Türen gleich breit</MenuItem>
        <MenuItem value={1}>Türen unterschiedlich breit</MenuItem>
      </Select>
    </Stack>
  );
};

export default KapiGenisligi;
