import React from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setRaf } from "../../../store/mirrors";

import { setRafFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const CamRaf = () => {
  const dispatch = useDispatch();
  const { raf } = useSelector((state) => state.mirror);
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal title="Informationen zur Glasablage">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Ablage aus Glas
        </Typography>
      </RenderModal>
      <input name="id[76]" value={raf == 0 ? 691 : raf == 1 ? 692 : raf == 2 ? 693 : null} />
      <Select
        onChange={(e) => {
          dispatch(setRaf(e.target.value));
          dispatch(setRafFee(e.target.value == 1 ? 35 : e.target.value == 2 ? 70 : 0));
        }}
        value={raf}
        sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
      >
        <MenuItem value={0}>---</MenuItem>
        <MenuItem value={1}>Klarglas + 35,00 €</MenuItem>
        <MenuItem value={2}>satiniertes Glas + 70,00 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default CamRaf;
