import { useGLTF } from "@react-three/drei";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as THREE from "three";

const DublinMirror = ({ type }) => {
  const { nodes, materials } = useGLTF("/newModels/dublin.glb");
  const { lightColor } = useSelector((state) => state.cabinetMirror);

  // useEffect(() => {
  //   materials.mirror.side = THREE.DoubleSide;
  // }, []);

  return (
    <group rotation-z={Math.PI} position-y={0.037}>
      {type === "single" ? (
        <>
          {/* SPACE FILLER */}
          <mesh
            position-y={0.037}
            scale-y={0.999}
            scale-z={0.99}
            position-z={0.0045}
            position-x={-0.25}
            material-side={THREE.DoubleSide}
            material={materials.mirror}
            rotation={[Math.PI * 1, 0, Math.PI * 1]}
            renderOrder={5}
          >
            <boxGeometry args={[0.5, 0.8, 0.00002]} />
          </mesh>
          {/* FIRST */}
          <group position-y={0.037} rotation={[Math.PI * 1, 0, Math.PI * 1]} position-z={0.005} position-x={-0.5} scale-x={0.5}>
            <mesh geometry={nodes.rightDoorRight_1.geometry} material={materials.mirror} />
            <mesh geometry={nodes.rightDoorRight_2.geometry} material={materials["ledlightTexture.003"]}>
              <meshBasicMaterial toneMapped={false} color={lightColor} />
            </mesh>
            <mesh geometry={nodes.rightDoorRight_3.geometry} material={materials.mirror} />
          </group>
          {/* LAST */}
          <group position-y={0.037} scale-x={0.5}>
            <mesh geometry={nodes.rightDoorRight_1.geometry} material={materials.mirror} />
            <mesh geometry={nodes.rightDoorRight_2.geometry} material={materials["ledlightTexture.003"]}>
              <meshBasicMaterial toneMapped={false} color={lightColor} />
            </mesh>
            <mesh geometry={nodes.rightDoorRight_3.geometry} material={materials.mirror} />
          </group>
        </>
      ) : type === "first" ? (
        <group position-z={0.001}>
          <mesh scale={[1, 1, 1]} geometry={nodes.LeftDoorRight.geometry} material={materials.mirror} />
          <mesh position={[0, 0, 0.001]} geometry={nodes.LeftDoorRight_1.geometry} material={materials["ledlightTexture.003"]}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
          <mesh geometry={nodes.LeftDoorRight_2.geometry} material={materials.mirror} />
          {/* <mesh geometry={nodes.LeftDoorRight_3.geometry} material={materials["aluminum.001"]} /> */}
        </group>
      ) : type === "mid" ? (
        <>
          <mesh geometry={nodes.midDoorRight_1.geometry} material={materials.mirror} />
          <mesh geometry={nodes.midDoorRight_2.geometry} material={materials["ledlightTexture.003"]}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
          <mesh geometry={nodes.midDoorRight_3.geometry} material={materials.mirror} />
          {/* <mesh geometry={nodes.midDoorRight_4.geometry} material={materials["aluminum.001"]} /> */}
        </>
      ) : (
        <group position-y={0.037}>
          <mesh geometry={nodes.rightDoorRight_1.geometry} material={materials.mirror} />
          <mesh geometry={nodes.rightDoorRight_2.geometry} material={materials["ledlightTexture.003"]}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
          <mesh geometry={nodes.rightDoorRight_3.geometry} material={materials.mirror} />
          {/* <mesh geometry={nodes.rightDoorRight_4.geometry} material={materials["aluminum.001"]} /> */}
        </group>
      )}
    </group>
  );
};

export default DublinMirror;
