import React from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import SelectUnstyled from "@mui/base/SelectUnstyled";

import { useDispatch, useSelector } from "react-redux";
import { setMakeupMirrorPos, EnumMirrors } from "../../../store/mirrors";

const MakyajAynasıPozisyon = () => {
  const dispatch = useDispatch();
  const { selectedMirror, makeupMirrorPos } = useSelector((state) => state.mirror);
  const isDaire = selectedMirror == EnumMirrors.CHARON || selectedMirror == EnumMirrors.DIANA || selectedMirror == EnumMirrors.HELENA;

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Schminkspiegel Position
      </Typography>
      {!isDaire ? (
        <>
          <input name="id[126]" value={makeupMirrorPos == 2 ? 1043 : makeupMirrorPos == 3 ? 1042 : null} />
          <Select onChange={(e) => dispatch(setMakeupMirrorPos(e.target.value))} value={makeupMirrorPos} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
            <MenuItem value={1}> links </MenuItem>
            <MenuItem value={2}> rechts</MenuItem>
          </Select>
        </>
      ) : (
        <>
          <input
            name="id[493]"
            value={
              makeupMirrorPos == 1
                ? 9675
                : makeupMirrorPos == 2
                ? 9676
                : makeupMirrorPos == 3
                ? 9677
                : makeupMirrorPos == 4
                ? 9678
                : makeupMirrorPos == 5
                ? 9679
                : makeupMirrorPos == 6
                ? 9680
                : makeupMirrorPos == 7
                ? 9681
                : makeupMirrorPos == 8
                ? 9682
                : null
            }
          />
          <Select onChange={(e) => dispatch(setMakeupMirrorPos(e.target.value))} value={makeupMirrorPos} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
            <MenuItem value={1}>1:30 Uhr (rechts oben)</MenuItem>
            <MenuItem value={2}>3:00 Uhr (rechts)</MenuItem>
            <MenuItem value={3}>4:30 Uhr (rechts unten)</MenuItem>
            <MenuItem value={4}>6:00 Uhr (unten mittig)</MenuItem>
            <MenuItem value={5}>7:30 Uhr (links unten)</MenuItem>
            <MenuItem value={6}>9:00 Uhr (links)</MenuItem>
            <MenuItem value={7}>10:30 Uhr (links oben)</MenuItem>
            <MenuItem value={8}>12:00 Uhr (oben)</MenuItem>
          </Select>
        </>
      )}
    </Stack>
  );
};

export default MakyajAynasıPozisyon;
