import React, { useRef, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";

const F620TL = () => {
  const { nodes, materials } = useGLTF("/rectangle-mirror-rev01.glb");
  const { mirrorWidth, mirrorHeight } = useSelector((state) => state.mirror);
  const mirrorRef = useRef();
  const blackRef = useRef();

  useEffect(() => {
    if (mirrorRef.current) {
      mirrorRef.current.scale.x = 1.4 + (mirrorWidth - 400) / 410;
      mirrorRef.current.scale.y = 1.275 + (mirrorHeight - 400) / 650;
    }
    if (blackRef.current) {
      blackRef.current.scale.x = 1.4 + (mirrorWidth - 400) / 410;
      blackRef.current.scale.y = 1.275 + (mirrorHeight - 400) / 650;
    }
  }, [mirrorWidth, mirrorHeight]);

  return (
    <group position-z={0.1}>
      <mesh ref={blackRef}>
        <boxGeometry args={[1.06, 1.06, 0.01]} />
        <meshBasicMaterial color={"black"} />
      </mesh>
      <mesh ref={mirrorRef} material={materials.mirror} position-z={0.01} rotation-y={Math.PI * 1}>
        <planeGeometry args={[1, 1]} />
      </mesh>
    </group>
  );
};

export default F620TL;
