import React from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSoket } from "../../../store/mirrors";
import { setSoketFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const Soketler = () => {
  const dispatch = useDispatch();
  const { soket } = useSelector((state) => state.mirror);
  const handleChange = (e) => {
    dispatch(setSoket(e.target.value));
    dispatch(
      setSoketFee(
        e.target.value == 1
          ? 49.99
          : e.target.value == 2
          ? 49.99
          : e.target.value == 3
          ? 98
          : e.target.value == 4
          ? 98
          : 0
      )
    );
  };
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal title="Informationen zur Steckdose">
        <Typography
          variant="caption"
          fontWeight={700}
          sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}
        >
          Steckdose(n) inkl. Bohrung
        </Typography>
      </RenderModal>
      <input
        name={"id[11]"}
        value={
          soket == 0
            ? 772
            : soket == 1
            ? 775
            : soket == 2
            ? 773
            : soket == 3
            ? 776
            : soket == 4
            ? 774
            : null
        }
      />
      <Select
        onChange={handleChange}
        value={soket}
        sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
      >
        <MenuItem value={0}>---</MenuItem> 772
        <MenuItem value={1}>1 x chrom + 49,99 €</MenuItem>
        <MenuItem value={2}>1 x schwarz + 49,99 €</MenuItem>
        <MenuItem value={3}>2 x chrom + 98,00 €</MenuItem>
        <MenuItem value={4}>2 x schwarz + 98,00 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default Soketler;
