import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EnumMirrors, changeMirrorHeight, changeMirrorWidth, resetToInitialState, setSızdırmazlık } from "../../../store/mirrors";
import { setSizdirmazlikFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const Sızdırmazlık = () => {
  const dispatch = useDispatch();
  const { selectedMirror, selectedMirrorExcelIndex, sızdırmazlık } = useSelector((state) => state.mirror);
  const handleChange = (e) => {
    dispatch(setSızdırmazlık(e.target.value));
    dispatch(setSizdirmazlikFee(e.target.value ? 79 : 0));
  };
  const rahmenloss = [EnumMirrors.SILBER_RAHMENLOS_3MM, EnumMirrors.STARK_RAHMENLOS_5MM].includes(selectedMirror);

  useEffect(() => {
    if (rahmenloss) {
      dispatch(resetToInitialState());
      dispatch(changeMirrorHeight(200));
      dispatch(changeMirrorWidth(200));
    }
  }, [selectedMirror, selectedMirrorExcelIndex]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal title="Informationen zur Versiegelung">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Versiegelung
        </Typography>
      </RenderModal>
      <input name={"id[143]"} value={sızdırmazlık == 0 ? 1338 : sızdırmazlık == 1 ? 1339 : null} />
      <Select value={sızdırmazlık} onChange={handleChange} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>---</MenuItem>
        <MenuItem value={1}>Ja (Lieferzeit +1 Tag) + 79,00 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default Sızdırmazlık;
