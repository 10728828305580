import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

const Gesture = (props) => {
  const { nodes, materials } = useGLTF("/daire.glb");
  return (
    <mesh castShadow receiveShadow geometry={nodes["SENSÖR-yatay001"].geometry} material={materials.touchdimmertexture} {...props}>
      {/* {props.bloom && (
        <meshBasicMaterial color={[2, 1, 1]} toneMapped={false} />
      )} */}
    </mesh>
  );
};

export default Gesture;
