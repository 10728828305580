import React, { useState, useEffect } from "react";
import { Stack, Box, Typography, Avatar, ImageListItem, Tooltip, useMediaQuery, MenuItem, Select, TextField } from "@mui/material";

import { EnumCabinetMirrors, setBodyDecor } from "../../../../store/cabinetMirror";
import { useDispatch, useSelector } from "react-redux";

import { renklerKategorili } from "../../../../utils/data/data";
import { setDekorFiyatı } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const GovdeDoku = () => {
  //TODO RENKLER ARRAYDEN GELSIN

  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("252");
  // const [selectedHtml, setSelectedHtml] = useState(renklerKategorili[0].title.split(" "));
  const { bodyDecor } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);

  // const dekors = [];

  // for (let i = 0; i < renklerKategorili.length - renklerKategorili.filter((x) => x.imageIndex > 0).length; i++) {
  //   dekors.push(renklerKategorili[i].imageIndex ? `bodyTextures/bodyText${renklerKategorili[i].imageIndex}.jpeg` : `bodyTextures/bodyText${i}.jpeg`);
  // }

  const isAluminium = [
    EnumCabinetMirrors.HAVEL,
    EnumCabinetMirrors.LECH,
    EnumCabinetMirrors.WESER,
    EnumCabinetMirrors.ELBE_1,
    EnumCabinetMirrors.NECKAR,
    EnumCabinetMirrors.LIPPE,
    EnumCabinetMirrors.WERRA,
  ].includes(selectedMirror);

  const desktopMatches1700 = useMediaQuery("(min-width:1700px)");
  const desktopMatches1200 = useMediaQuery("(min-width:1200px)");
  const desktopMatches900 = useMediaQuery("(min-width:900px)");
  const mobileMatches600 = useMediaQuery("(min-width:600px)");

  const kapiGenisligiWidth = desktopMatches1200 ? "21vw" : desktopMatches900 ? "35vw" : mobileMatches600 ? "35vw" : "70vw";
  const kapiGenisligiMarginRight = desktopMatches1200 ? "2vw" : desktopMatches900 ? "3vw" : "5vw";

  // useEffect(() => {
  //   if (isAluminium) {
  //     dispatch(setBodyDecor(renklerKategorili[88].imageIndex ? renklerKategorili[88].imageIndex : 88));
  //     dispatch(setDekorFiyatı(renklerKategorili[88].price));
  //   }
  // }, [selectedMirror]);

  const tooltipTitle = (i) => {
    const title = renklerKategorili[i].price != 0 ? renklerKategorili[i].title + " +" + renklerKategorili[i].price + "€" : renklerKategorili[i].title;
    return title;
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="16">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Dekor Korpus
        </Typography>
      </RenderModal>

      {/* {!isAluminium && (
        <Typography
          color="primary"
          variant="subtitle2"
          fontWeight={700}
          sx={{
            ml: "1rem",
            borderRadius: "8px",
            bgcolor: "#88888840",
            p: "6px 12px",
            cursor: "pointer",
          }}
          onClick={(event) => {
            event.stopPropagation();
            setOpenColorMenu(true);
          }}
        >
          Renk seçenekleri
        </Typography>
      )} */}
      <input name="id[21]" value={selectedValue} />

      <Select
        // value={selectedHtml}
        defaultValue={"W1100_ST9.jpeg,0,252"}
        sx={{
          width: "100%",
          height: "2.2rem",
          fontSize: 12,
          ".MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
        }}
        onChange={(e) => {
          const splitValue = e.target.value.split(",");
          const imageName = splitValue[0];
          const price = parseFloat(splitValue[1]);
          const value = splitValue[2];
          dispatch(setBodyDecor(imageName));
          dispatch(setDekorFiyatı(price));
          setSelectedValue(value);
          // setSelectedHtml(`${imageName},${price},${value}`);
        }}
      >
        {renklerKategorili.map((item) => {
          const spliteItemTitle = item.title.split(" ");
          const imageName = spliteItemTitle[0] + "_" + spliteItemTitle[1] + ".jpeg";
          const imageLink = "./decors/" + imageName + "";
          const itemTitle = item.title + " €" + item.price;
          return (
            <MenuItem value={`${imageName},${item.price},${item.value}`} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              {itemTitle}
              <Avatar
                alt={item.title}
                src={imageLink}
                sx={{
                  height: "1.5rem",
                  width: "1.5rem",
                  alignSelf: "flex-end",
                }}
              />
            </MenuItem>
            // <MenuItem value={item.} >
            //   {tooltipTitle(i)}
            //
            // </MenuItem>;
          );
        })}
      </Select>
    </Stack>
  );
};

export default GovdeDoku;
