import React, { useState, useEffect, useRef } from "react";
import { Stack, Typography, RadioGroup, FormControlLabel, Radio, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setIsFirstLeftSided, setIsSecondLeftSided, EnumCabinets } from "../../../store/cabinet";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const MenteseYonu = () => {
  const dispatch = useDispatch();
  const { isFirstLeftSided, isSecondLeftSided } = useSelector((state) => state.cabinet);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const [openDoorSideMenu, setOpenDoorSideMenu] = useState(false);
  const menuRef = useRef(null);

  const doorsInfoArray = [
    {
      value: isFirstLeftSided,
      setter: (value) => dispatch(setIsFirstLeftSided(value)),
      entegrationInputValue: isFirstLeftSided ? 2583 : 2584,
      name: "id[245]",
      label: "Anschlagrichtung Tür 1 ",
    },
    {
      value: isSecondLeftSided,
      setter: (value) => dispatch(setIsSecondLeftSided(value)),
      entegrationInputValue: isSecondLeftSided ? 2586 : 2585,
      name: "id[246]",
      label: "Anschlagrichtung Tür 2",
    },
  ];

  const isTwoDoorCabinet = [EnumCabinets.BHS003, EnumCabinets.BHS005, EnumCabinets.BHS006, EnumCabinets.BHS009L4, EnumCabinets.BHS008L4, EnumCabinets.BHS013L4, EnumCabinets.BHS004].includes(
    selectedMirror
  );

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenDoorSideMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const desktopMatches1200 = useMediaQuery("(min-width:1200px)");
  const desktopMatches900 = useMediaQuery("(min-width:900px)");
  const mobileMatches600 = useMediaQuery("(min-width:600px)");

  const kapiGenisligiWidth = desktopMatches1200 ? "21vw" : desktopMatches900 ? "35vw" : mobileMatches600 ? "35vw" : "70vw";
  const kapiGenisligiMarginRight = desktopMatches1200 ? "2vw" : desktopMatches900 ? "3vw" : "5vw";

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="19">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Anschlagrichtung Tür
        </Typography>
      </RenderModal>

      <Typography
        color="primary"
        sx={{ borderRadius: "8px", bgcolor: "#88888840", p: "6px 12px", cursor: "pointer", fontSize: "14px", fontWeight: "700" }}
        onClick={(event) => {
          event.stopPropagation();
          setOpenDoorSideMenu(true);
        }}
      >
        Optionen
      </Typography>
      <Stack
        ref={menuRef}
        justifyContent="space-evenly"
        spacing={0.5}
        id="doorSideMenu"
        sx={{
          flexGrow: "1",
          fontSize: "0.5rem",
          width: kapiGenisligiWidth,
          display: openDoorSideMenu ? "flex" : "none",
          position: "absolute",
          right: "1vw",
          alignItems: "flex-start",
          justifyContent: "center",
          zIndex: 11,
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          border: "1px solid #aabbaa30",
          p: "8px 8px",
          boxShadow: "2px 2px 12px gray",
          transition: "all 1s ease-in-out forwards",
          marginRight: kapiGenisligiMarginRight,
        }}
      >
        {isTwoDoorCabinet ? (
          doorsInfoArray.map((item, index) => <DoorSidePicker entegrationInputName={item.name} entegrationInputValue={item.entegrationInputValue} door={item} />)
        ) : (
          <DoorSidePicker entegrationInputName={doorsInfoArray[0].name} entegrationInputValue={doorsInfoArray[0].entegrationInputValue} door={doorsInfoArray[0]} />
        )}
        <Typography
          color="primary"
          variant="subtitle2"
          fontWeight={700}
          sx={{ borderRadius: "8px", p: "6px 12px", cursor: "pointer", alignSelf: "center" }}
          onClick={(event) => {
            event.stopPropagation();
            setOpenDoorSideMenu(false);
          }}
        >
          Vollendet
        </Typography>
      </Stack>
    </Stack>
  );
};

export default MenteseYonu;

const DoorSidePicker = ({ door, entegrationInputName, entegrationInputValue }) => {
  const { value, label, setter } = door;
  const handleChange = (event, newValue) => {
    let boolVal;
    boolVal = JSON.parse(newValue);
    setter(boolVal);
    event.stopPropagation();
  };
  return (
    <Stack sx={{ alignItems: "flex-start", justifyContent: "flex-start", height: "5rem", width: "100%", fontSize: "12px !important" }}>
      <Typography variant="caption">{label}</Typography>
      <input name={entegrationInputName} value={entegrationInputValue} />
      <RadioGroup row value={value} onChange={handleChange} sx={{ fontSize: "12px" }}>
        <FormControlLabel value={true} control={<Radio />} label="Links" size="small" />
        <FormControlLabel value={false} control={<Radio />} label="Rechts" size="small" />
      </RadioGroup>
    </Stack>
  );
};
