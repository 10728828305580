import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EnumCabinetMirrors, changeCabinetMirrorWidth, setDoorNumber } from "../../../../store/cabinetMirror";
import { setDoorFiyatı } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";
const Kapılar = () => {
  const dispatch = useDispatch();
  const { selectedMirror } = useSelector((state) => state.mirror);
  const { doorNumber, cabinetMirrorWidth } = useSelector((state) => state.cabinetMirror);

  const handleChange = (e) => {
    dispatch(setDoorNumber(e.target.value));
    dispatch(setDoorFiyatı(e.target.value === 2 ? 69.99 : e.target.value === 3 ? 119.99 : e.target.value === 4 ? 159.99 : e.target.value === 5 ? 189.99 : e.target.value === 6 ? 209.99 : 0));
  };

  const isSidedCabinet =
    selectedMirror === EnumCabinetMirrors.MUNCHEN_4 ||
    selectedMirror === EnumCabinetMirrors.MALAGA ||
    selectedMirror === EnumCabinetMirrors.SARAGOSSA ||
    selectedMirror === EnumCabinetMirrors.KONSTANZ ||
    selectedMirror === EnumCabinetMirrors.DERBY ||
    selectedMirror === EnumCabinetMirrors.GENT ||
    selectedMirror === EnumCabinetMirrors.VENEZIA ||
    selectedMirror === EnumCabinetMirrors.ALMERIA ||
    selectedMirror === EnumCabinetMirrors.GRADO ||
    selectedMirror === EnumCabinetMirrors.VILLACH ||
    selectedMirror === EnumCabinetMirrors.VERONICA_3 ||
    selectedMirror === EnumCabinetMirrors.VERONICA_4 ||
    selectedMirror === EnumCabinetMirrors.PANDORA_3 ||
    selectedMirror === EnumCabinetMirrors.PANDORA_4 ||
    selectedMirror === EnumCabinetMirrors.KATHERINE_3 ||
    selectedMirror === EnumCabinetMirrors.KATHERINE_4;

  const isCenteredCabinet = selectedMirror === EnumCabinetMirrors.MUNCHEN_5 || selectedMirror === EnumCabinetMirrors.LAS_VEGAS;

  const notFixedDoors = !isSidedCabinet && !isCenteredCabinet;

  useEffect(() => {
    if (cabinetMirrorWidth > 2201) {
      dispatch(setDoorNumber(6));
      dispatch(setDoorFiyatı(209.99));
    } else if (cabinetMirrorWidth > 1601) {
      dispatch(setDoorNumber(5));
      dispatch(setDoorFiyatı(189.99));
    } else if (cabinetMirrorWidth > 1001) {
      dispatch(setDoorNumber(4));
      dispatch(setDoorFiyatı(159.99));
    } else if (cabinetMirrorWidth > 900) {
      dispatch(setDoorNumber(3));
      dispatch(setDoorFiyatı(119.99));
    } else if (cabinetMirrorWidth > 650) {
      dispatch(setDoorNumber(2));
      dispatch(setDoorFiyatı(69.99));
    } else {
      dispatch(setDoorNumber(1));
      dispatch(setDoorFiyatı(0));
    }
  }, [cabinetMirrorWidth]);

  useEffect(() => {
    dispatch(setDoorNumber(1));
    dispatch(setDoorFiyatı(0));
  }, [selectedMirror]);

  const renderInputDataValue = () => {
    switch (doorNumber) {
      case 1:
        return 262;
      case 2:
        return 263;
      case 3:
        return 264;
      case 4:
        return 265;
      case 5:
        return 266;
      case 6:
        return 267;
    }
  };

  useEffect(() => {
    if (isCenteredCabinet) {
      dispatch(setDoorNumber(2));
      dispatch(setDoorFiyatı(0));
    } else {
      dispatch(setDoorNumber(1));
      dispatch(setDoorFiyatı(0));
    }
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="18">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Türen
        </Typography>
      </RenderModal>

      <input name="id[22]" value={renderInputDataValue()} />

      <Select onChange={handleChange} value={doorNumber} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem disabled={cabinetMirrorWidth > 650} value={1}>
          1 Tür (bei Breite 300mm-650mm)
        </MenuItem>

        <MenuItem disabled={cabinetMirrorWidth < 600 || cabinetMirrorWidth > 1300} value={2}>
          2 Türen (bei Breite 600mm-1300mm) + 69,99 €
        </MenuItem>
        <MenuItem disabled={cabinetMirrorWidth < 900 || cabinetMirrorWidth > 1950} value={3}>
          3 Türen (bei Breite 900mm-1950mm) + 119,99 €
        </MenuItem>
        <MenuItem disabled={cabinetMirrorWidth < 1200} value={4}>
          4 Türen (bei Breite 1200mm-2500mm) + 159,99 €
        </MenuItem>
        <MenuItem disabled={cabinetMirrorWidth < 1500} value={5}>
          5 Türen (bei Breite 1500mm-2500mm) + 189,99 €
        </MenuItem>
        <MenuItem disabled={cabinetMirrorWidth < 1800} value={6}>
          6 Türen (bei Breite 1800mm-2500mm) + 209,99 €
        </MenuItem>
      </Select>
    </Stack>
  );
};

export default Kapılar;
