import { Canvas } from "@react-three/fiber";
import { Provider, useSelector } from "react-redux";
import store from "../store/index";
import { useMediaQuery } from "@mui/material";
// Room

import { Perf } from "r3f-perf";
import SummaryModal from "./DolapComponents/SummaryModal";
import Test from "./DolapComponents/TestCabinet/Test";
import DolapMenu from "./DolapComponents/DolapMenu";
import Panel from "./DolapComponents/TestCabinet/Panel";

const DolapCanvas = () => {
  const { isPrivate, isMenuOpen } = useSelector((state) => state.mirror);

  const desktopMatches1700 = useMediaQuery("(min-width:1700px)");
  const desktopMatches1200 = useMediaQuery("(min-width:1200px)");
  const mobileMatches600 = useMediaQuery("(min-width:600px)");
  const canvasResponsiveWidth = desktopMatches1700
    ? "71vw"
    : desktopMatches1200
    ? "75vw"
    : mobileMatches600 && isMenuOpen
    ? "50vw"
    : "100vw";

  return (
    <>
      <Canvas
        className="canvasHeightEdit"
        style={{
          width: canvasResponsiveWidth,
          height: `100vh`,
          float: "right",
        }}
        dpr={[1, 1.5]}
        shadows
        camera={{ position: [-9, 0, -1.3], fov: 60 }}
        antialias={true}
      >
        <Provider store={store}>
          <Test />
        </Provider>
      </Canvas>
      <Panel />
      <DolapMenu />
      <SummaryModal />
    </>
  );
};

export default DolapCanvas;
