import React, { useEffect, useState } from "react";
import BudapestMirror from "./BudapestMirror";
import ChicagoMirror from "./ChicagoMirror";
import ColumbusMirror from "./ColumbusMirror";
import DresdenMirror from "./DresdenMirror";
import DublinMirror from "./DublinMirror";
import HamburgMirror from "./HamburgMirror";
import LasVegasMirror from "./LasVegasMirror";
import Munih1Mirror from "./Munih1Mirror";
import Munih2Mirror from "./Munih2Mirror";
import Munih5Mirror from "./Munih5Mirror";
import OxfordMirror from "./OxfordMirror";

import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";
import { EnumCabinetMirrors } from "../../../../store/cabinetMirror";

const CabinetLeftDoor = ({ type }) => {
  const { selectedMirror } = useSelector((state) => state.mirror);
  const { nodes, materials } = useGLTF("/Frankfurt-test2.glb");

  const isOnlyMirror =
    selectedMirror === EnumCabinetMirrors.OXFORD ||
    selectedMirror === EnumCabinetMirrors.BRISTOL ||
    selectedMirror === EnumCabinetMirrors.FRANKFURT ||
    selectedMirror === EnumCabinetMirrors.VENEZIA ||
    selectedMirror === EnumCabinetMirrors.GENT ||
    selectedMirror === EnumCabinetMirrors.DERBY ||
    selectedMirror === EnumCabinetMirrors.GRADO ||
    selectedMirror === EnumCabinetMirrors.WESER ||
    selectedMirror === EnumCabinetMirrors.ELBE_1 ||
    selectedMirror === EnumCabinetMirrors.CORTINA ||
    selectedMirror === EnumCabinetMirrors.WINDSOR ||
    selectedMirror === EnumCabinetMirrors.VERONICA_1 ||
    selectedMirror === EnumCabinetMirrors.VERONICA_2 ||
    selectedMirror === EnumCabinetMirrors.VERONICA_3 ||
    selectedMirror === EnumCabinetMirrors.VERONICA_4 ||
    selectedMirror === EnumCabinetMirrors.PANDORA_1 ||
    selectedMirror === EnumCabinetMirrors.PANDORA_2 ||
    selectedMirror === EnumCabinetMirrors.PANDORA_3 ||
    selectedMirror === EnumCabinetMirrors.PANDORA_4 ||
    selectedMirror === EnumCabinetMirrors.KATHERINE_1 ||
    selectedMirror === EnumCabinetMirrors.KATHERINE_2 ||
    selectedMirror === EnumCabinetMirrors.KATHERINE_3 ||
    selectedMirror === EnumCabinetMirrors.KATHERINE_4;

  const isDresdenPattern =
    selectedMirror === EnumCabinetMirrors.DRESDEN || selectedMirror === EnumCabinetMirrors.LECH || selectedMirror === EnumCabinetMirrors.VILLACH || selectedMirror === EnumCabinetMirrors.LINZ;

  const isColombusPattern =
    selectedMirror === EnumCabinetMirrors.COLUMBUS ||
    selectedMirror === EnumCabinetMirrors.KONSTANZ ||
    selectedMirror === EnumCabinetMirrors.EL_PASO ||
    selectedMirror === EnumCabinetMirrors.SANTANDER ||
    selectedMirror === EnumCabinetMirrors.SARAGOSSA ||
    selectedMirror === EnumCabinetMirrors.ALMERIA ||
    selectedMirror === EnumCabinetMirrors.NECKAR ||
    selectedMirror === EnumCabinetMirrors.WERRA ||
    selectedMirror === EnumCabinetMirrors.HAVEL ||
    selectedMirror === EnumCabinetMirrors.LISSABON;

  return isOnlyMirror ? (
    <OxfordMirror />
  ) : selectedMirror === EnumCabinetMirrors.MUNCHEN_4 ? (
    <Munih2Mirror />
  ) : selectedMirror === EnumCabinetMirrors.MUNCHEN_5 ? (
    <Munih5Mirror />
  ) : selectedMirror === EnumCabinetMirrors.MUNCHEN_6 ? (
    <Munih1Mirror />
  ) : selectedMirror === EnumCabinetMirrors.LAS_VEGAS ? (
    <DublinMirror type={type} />
  ) : selectedMirror === EnumCabinetMirrors.SUPREME ? null : selectedMirror === EnumCabinetMirrors.BUDAPEST ? ( ////Supreme
    <DublinMirror type={type} />
  ) : selectedMirror === EnumCabinetMirrors.CHICAGO ? (
    <ChicagoMirror type={type} />
  ) : selectedMirror === EnumCabinetMirrors.DUBLIN ? (
    <DublinMirror type={type} />
  ) : selectedMirror === EnumCabinetMirrors.HAMBURG ? (
    <HamburgMirror />
  ) : selectedMirror === EnumCabinetMirrors.MUNCHEN_1 ? (
    <Munih1Mirror />
  ) : selectedMirror === EnumCabinetMirrors.MALAGA ? (
    <DublinMirror type={type} />
  ) : selectedMirror === EnumCabinetMirrors.LIPPE ? (
    <DublinMirror type={type} />
  ) : isDresdenPattern ? (
    <DresdenMirror type={type} />
  ) : isColombusPattern ? (
    <ColumbusMirror type={type} />
  ) : null;
};

export default CabinetLeftDoor;
