import React, { useEffect } from "react";
import { Stack, Slider, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAhsapZemin, setShelfCount } from "../../../../store/cabinetMirror";
import { setAhsapZeminFiyatı, setCamRafFiyatı } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const CamRaflar = () => {
  const dispatch = useDispatch();
  const { shelfCount } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const handleChange = (e) => {
    dispatch(setShelfCount(e.target.value));
    dispatch(
      setCamRafFiyatı(e.target.value === 1 ? 25 : e.target.value === 2 ? 40 : e.target.value === 3 ? 50 : e.target.value === 4 ? 60 : e.target.value === 5 ? 70 : e.target.value === 6 ? 80 : 0)
    );
    dispatch(setAhsapZemin(0));
    dispatch(setAhsapZeminFiyatı(0));
  };

  useEffect(() => {
    dispatch(setAhsapZemin(0));
    dispatch(setAhsapZeminFiyatı(0));
    dispatch(setShelfCount(0));
    dispatch(setCamRafFiyatı(0));
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="22">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Glasböden
        </Typography>
      </RenderModal>

      <input
        name="id[18]"
        value={shelfCount == 0 ? 241 : shelfCount == 1 ? 242 : shelfCount == 2 ? 243 : shelfCount == 3 ? 244 : shelfCount == 4 ? 245 : shelfCount == 5 ? 561 : shelfCount == 6 ? 562 : null}
      />

      <Select onChange={handleChange} value={shelfCount} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>ohne Glasböden</MenuItem>
        <MenuItem value={1}>1 x Glasboden + 25,00 €</MenuItem>
        <MenuItem value={2}>2 x Glasboden + 40,00 €</MenuItem>
        <MenuItem value={3}>3 x Glasboden + 50,00 €</MenuItem>
        <MenuItem value={4}>4 x Glasboden + 60,00 €</MenuItem>
        <MenuItem value={5}>5 x Glasboden + 70,00 €</MenuItem>
        <MenuItem value={6}>6 x Glasboden + 80,00 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default CamRaflar;
