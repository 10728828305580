import React, { useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";

export function F623TL(props) {
  const { nodes, materials } = useGLTF("/newMirrorModels/F623TL.glb");
  const { materials: mirrorMaterials } = useGLTF("/rectangle-mirror-rev01.glb");
  const mirrorRef = useRef();
  const { mirrorHeight, mirrorWidth } = useSelector((state) => state.mirror);

  useEffect(() => {
    if (mirrorRef.current) {
      mirrorRef.current.scale.x = 0.7 + (mirrorWidth - 400) / 800;
      mirrorRef.current.scale.y = 0.5 + (mirrorHeight - 400) / 1600;
    }
  }, [mirrorWidth, mirrorHeight]);
  return (
    <group ref={mirrorRef} {...props} rotation-y={Math.PI * 1} position-z={0.1} dispose={null}>
      <mesh name="painted" geometry={nodes.painted.geometry} material={materials["Material.001"]} position={[0, 0, 0.015]} />
      <mesh name="mirror" geometry={nodes.mirror.geometry} material={mirrorMaterials.mirror} position={[-0.78, -1.04, 0]} />
    </group>
  );
}

useGLTF.preload("/F623TL.glb");
