import { useGLTF } from "@react-three/drei";
import React from "react";

const OxfordMirror = () => {
  const { nodes, materials } = useGLTF("/newModels/oxford.glb");
  return (
    <group scale-y={0.99}>
      <mesh geometry={nodes.rightDoor_1.geometry} material={materials.glass} />
      <mesh geometry={nodes.rightDoor_2.geometry} material={materials.mirror} />
      <mesh geometry={nodes.rightDoor_3.geometry} material={materials.mirror} />
      {/* <mesh geometry={nodes.rightDoor_4.geometry} material={materials.aluminum} /> */}
    </group>
  );
};

export default OxfordMirror;
