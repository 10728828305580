import React, { useEffect, useState } from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setIsiSensor, setIsiSensorPos, EnumMirrors } from "../../../store/mirrors";
import { setIsiSensorFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const IsiSensor = () => {
  const dispatch = useDispatch();
  const { isiSensor, isiSensorPos, mirrorWidth, selectedMirror } = useSelector((state) => state.mirror);
  const [showError, setShowError] = useState(false);
  const [value, setValue] = useState(0);
  const isDaire = selectedMirror == EnumMirrors.CHARON || selectedMirror == EnumMirrors.DIANA || selectedMirror == EnumMirrors.HELENA;

  const handleChange = (e) => {
    const targetValue = e.target.value;
    if (isDaire) {
      setValue(targetValue);
      if (mirrorWidth >= 750) {
        setShowError(false);
        dispatch(setIsiSensor(targetValue));
        dispatch(setIsiSensorFee(targetValue == 1 ? 79 : targetValue == 2 ? 49.99 : targetValue == 3 ? 69.99 : 0));
      } else if (targetValue != 0) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    } else {
      setValue(targetValue);
      dispatch(setIsiSensor(targetValue == 7 || targetValue == 8 || targetValue == 9 || targetValue == 0 ? 0 : 2));
      dispatch(setIsiSensorPos(targetValue == 1 || targetValue == 4 ? 0 : targetValue == 2 || targetValue == 5 ? 1 : targetValue == 3 || targetValue == 6 ? 2 : 0));
      dispatch(
        setIsiSensorFee(
          targetValue == 1 || targetValue == 2 || targetValue == 3
            ? 49.99
            : targetValue == 4 || targetValue == 5 || targetValue == 6
            ? 69.99
            : targetValue == 7 || targetValue == 8 || targetValue == 9
            ? 79
            : 0
        )
      );
    }
  };

  useEffect(() => {
    if (isDaire) {
      if (mirrorWidth <= 749 && value != 0) {
        setShowError(true);
        dispatch(setIsiSensor(0));
      } else if (mirrorWidth >= 750 && value != 0) {
        setShowError(false);
        dispatch(setIsiSensor(value));
      }
    }
  });

  return (
    <>
      {!isDaire ? (
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          <RenderModal title="Informationen zur Spiegelheizung">
            <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
              Spiegelheizung
            </Typography>
          </RenderModal>
          <input
            name="id[1]"
            value={
              isiSensor == 0
                ? 1
                : isiSensor == 1
                ? 712
                : isiSensor == 2
                ? 711
                : isiSensor == 3
                ? 710
                : isiSensor == 4
                ? 715
                : isiSensor == 5
                ? 714
                : isiSensor == 6
                ? 713
                : isiSensor == 7
                ? 9928
                : isiSensor == 8
                ? 9929
                : isiSensor == 9
                ? 9930
                : null
            }
          />
          <Select onChange={handleChange} value={value} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
            <MenuItem value={0}>---</MenuItem>
            <MenuItem value={1}>mit Kippschalter unten links + 49,99 €</MenuItem>
            <MenuItem value={2}>mit Kippschalter unten mittig + 49,99 €</MenuItem>
            <MenuItem value={3}>mit Kippschalter unten rechts + 49,99 €</MenuItem>
            <MenuItem value={4}>mit Touch-Sensor unten links + 69,99 €</MenuItem>
            <MenuItem value={5}>mit Touch-Sensor unten mittig + 69,99 €</MenuItem>
            <MenuItem value={6}>mit Touch-Sensor unten rechts + 69,99 €</MenuItem>
            <MenuItem value={7}>mit ECO friendly Switch Sensor unten links + 79,00 €</MenuItem>
            <MenuItem value={8}>mit ECO friendly Switch Sensor unten mittig + 79,00 €</MenuItem>
            <MenuItem value={9}>mit ECO friendly Switch Sensor unten rechts + 79,00 €</MenuItem>
          </Select>
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          <RenderModal title="Informationen zur Spiegelheizung">
            <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
              Spiegelheizung
            </Typography>
          </RenderModal>
          <input name="id[1]" value={isiSensor == 0 ? 1 : isiSensor == 1 ? 10109 : isiSensor == 2 ? 9672 : isiSensor == 3 ? 9673 : null} />
          <Select onChange={handleChange} value={value} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
            <MenuItem value={0}>---</MenuItem>
            <MenuItem value={1}>mit ECO friendly Switch Sensor + 79,00 €</MenuItem>
            <MenuItem value={2}>mit Kippschalter + 49,99 €</MenuItem>
            <MenuItem value={3}>mit Touch-Sensor + 69,99 €</MenuItem>
          </Select>
        </Stack>
      )}

      {isDaire && showError && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            position: "relative",
            top: -10,
          }}
        >
          <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem", mt: 0 }}></Typography>
          <small
            style={{
              color: "tomato",
            }}
          >
            Eine Spiegelheizung ist aus Platzgründen erst ab einem Durchmesser von mind. 750 mm möglich. Bitte wählen Sie einen größeren Durchmesser oder entfernen Sie die Option "Spiegelheizung".
          </small>
        </Stack>
      )}
    </>
  );
};

export default IsiSensor;
