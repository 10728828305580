import { useSelector } from "react-redux";
import { Suspense, useState } from "react";
import { Man2 } from "../../OrtakComponents/Man2";
import SingleDoorCabinet from "./SingleDoorCabinet";
import TwoDoorCenterlessCabinet from "./TwoDoorCenterlessCabinet";
import CenterShelfCabinet from "./CenterShelfCabinet";
import { Environment, OrbitControls } from "@react-three/drei";
import TestRoom from "./TestRoom";
import { Bloom, EffectComposer } from "@react-three/postprocessing";
import { UnrealBloomPass } from "three-stdlib";

import { extend } from "@react-three/fiber";
import { EnumCabinets } from "../../../store/cabinet";

extend({ UnrealBloomPass });

const Test = () => {
  const { cabinetMirrorWidth, lightIsOpen } = useSelector((state) => state.cabinetMirror);
  const manHeight = useSelector((state) => state.utils.selectedHeightLevel);

  const { selectedMirror } = useSelector((state) => state.mirror);

  const isCenteredCabinet = [EnumCabinets.BHS003, EnumCabinets.BHS009L4, EnumCabinets.BHS004].includes(selectedMirror);
  const isSingleDoorCabinet = [EnumCabinets.BHS002A, EnumCabinets.BHS011, EnumCabinets.BHS001, EnumCabinets.BHS002B, EnumCabinets.BHS007L4, EnumCabinets.BHS012L4].includes(selectedMirror);
  const isTwoDoorButCenterless = [EnumCabinets.BHS005, EnumCabinets.BHS010, EnumCabinets.BHS006, EnumCabinets.BHS008L4, EnumCabinets.BHS013L4].includes(selectedMirror);

  return (
    <>
      <Environment
        near={1}
        far={1000}
        resolution={256}
        files={"./hdr/test.hdr"}
        // preset="night"
        background
      />
      <group rotation={[0, -1.7, 0]}>
        {lightIsOpen && (
          <EffectComposer multisampling={4} disableNormalPass>
            <Bloom luminanceThreshold={1} intensity={0.015} levels={2} />
            <Bloom luminanceThreshold={1} intensity={0.0053} levels={5} mipmapBlur />
            <Bloom luminanceThreshold={1} intensity={0.0019} levels={6} mipmapBlur />
          </EffectComposer>
        )}
        <Suspense fallback={null}>
          {isCenteredCabinet ? <CenterShelfCabinet /> : isSingleDoorCabinet ? <SingleDoorCabinet /> : isTwoDoorButCenterless ? <TwoDoorCenterlessCabinet /> : null}

          <Man2 position={[cabinetMirrorWidth > 2200 ? -6.4 : -3 - cabinetMirrorWidth * 0.001, -3.7, -0.3]} scale={25 + manHeight} manHeight={manHeight} />
        </Suspense>
        <TestRoom />

        <OrbitControls
          enablePan={false}
          minPolarAngle={Math.PI / 3.1}
          maxPolarAngle={Math.PI / 1.6}
          minAzimuthAngle={Math.PI * 1.1}
          maxAzimuthAngle={Math.PI * 1.8}
          zoomSpeed={0.25}
          enableZoom={true}
          makeDefault
          maxDistance={10}
          minDistance={3}
        />
      </group>
    </>
  );
};

export default Test;
