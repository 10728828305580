import React from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setFaceta } from "../../../store/mirrors";
import { setFacetaFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";
const Faceta = () => {
  const dispatch = useDispatch();
  const { faceta } = useSelector((state) => state.mirror);
  const handleChange = (e) => {
    dispatch(setFaceta(e.target.value));
    dispatch(
      setFacetaFee(
        e.target.value == 1 ? 37.49 : e.target.value == 2 ? 49.99 : e.target.value == 3 ? 62.49 : e.target.value == 4 ? 74.99 : e.target.value == 5 ? 87.49 : e.target.value == 6 ? 99.99 : 0
      )
    );
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal title="Informationen zum Facettenschliff">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Facette
        </Typography>
      </RenderModal>

      <input name="id[4]" value={faceta == 0 ? 7 : faceta == 1 ? 9940 : faceta == 2 ? 8 : faceta == 3 ? 9941 : faceta == 4 ? 9943 : faceta == 5 ? 9944 : faceta == 6 ? 9945 : null} />

      <Select onChange={handleChange} value={faceta} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>---</MenuItem>
        <MenuItem value={1}>10mm (Lieferzeit +3 Tage) + 37,49 €</MenuItem>
        <MenuItem value={2}>20mm (Lieferzeit +3 Tage) + 49,99 €</MenuItem>
        <MenuItem value={3}>30mm (Lieferzeit +3 Tage) + 62,49 €</MenuItem>
        <MenuItem value={4}>40mm (Lieferzeit +3 Tage) + 74,99 €</MenuItem>
        <MenuItem value={5}>50mm (Lieferzeit +3 Tage) + 87,49 €</MenuItem>
        <MenuItem value={6}>60mm (Lieferzeit +3 Tage) + 99,99 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default Faceta;
