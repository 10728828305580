import { useRef, useState, useEffect } from "react";

import gsap from "gsap";

import { useGLTF } from "@react-three/drei";

import { useFrame } from "@react-three/fiber";
import { useDrag } from "react-use-gesture";
import { useDispatch, useSelector } from "react-redux";

import DışAydınlatma from "../../OrtakComponents/DışAydınlatma";

import { EnumCabinetMirrors, setCustomDoorWidthValues } from "../../../store/cabinetMirror";
import { Pandora } from "../../OrtakComponents/Pandora";
import CentimeterHeightLines from "../../OrtakComponents/CM/CentimeterHeightLines";
import CentimeterWidthLines from "../../OrtakComponents/CM/CentimeterWidthLines";
import CentimeterDepthLines from "../../OrtakComponents/CM/CentimeterDepthLines";

import { EnumSocketUSBBase } from "../cabinetBodyParts/smallParts/SocketUSB";
import { KapakliBolme } from "./bolmeler/KapakliBolme";
import { KapaksizBolme } from "./bolmeler/KapaksizBolme";

export default function CabinetSided(props) {
  //MODELS
  const dispatch = useDispatch();
  //**AYNALI DOLAPLAR */
  const { nodes, materials } = useGLTF("/Frankfurt-test2.glb");
  const { nodes: budapestNodes } = useGLTF("/newModels/budapest.glb");

  // STATES
  const {
    lightColor,
    cabinetMirrorWidth,
    cabinetMirrorHeight,
    cabinetMirrorDepth,
    cabinetMirrorType,
    externalLeds,
    draggerVisible,
    isFirstLeftSided,
    isSecondLeftSided,
    isThirdLeftSided,
    isFourthLeftSided,
    isFifthLeftSided,
    isSixthLeftSided,
    widthCmVisible,
    heightCmVisible,
    depthCmVisible,
    doorNumber,
    bottomCompartmentHeight,
    leftCompartmentWidth,
    rightCompartmentWidth,
  } = useSelector((state) => state.cabinetMirror);

  //------REFS------\\

  const frankfurt = useRef();

  //----------First Cabinet
  const firstCabinet = useRef();

  //----------Second Cabinet
  const secondCabinet = useRef();

  //----------Third Cabinet
  const thirdCabinet = useRef();

  //----------Fourth Cabinet
  const fourthCabinet = useRef();

  //----------Fifth Cabinet
  const fifthCabinet = useRef();

  //----------Sixth Cabinet
  const sixthCabinet = useRef();

  //---------- OpenLeft
  const openLeftCabinet = useRef();

  //---------- OpenLeft
  const openRightCabinet = useRef();

  //----------LEDS
  const outerLedRef = useRef();
  const innerLedRef = useRef();

  //--STATES--\\

  const { selectedMirror } = useSelector((state) => state.mirror);

  //-----Dragger States-----\\

  const iAOpenLeft = (cabinetMirrorWidth / doorNumber / 400) * 2 * -0.25;
  const iA12 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 0.25;
  const iA23 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 0.75;
  const iA34 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 1.25;
  const iA45 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 1.75;
  const iA56 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 2.25;

  const returnIAOpenRight = () => {
    switch (doorNumber) {
      case 1:
        return iA12;
      case 2:
        return iA23;
      case 3:
        return iA34;
      case 4:
        return iA45;
      case 5:
        return iA56;
      case 6:
        return (cabinetMirrorWidth / doorNumber / 400) * 2 * 2.75;
      default:
        return iA12;
    }
  };

  const [draggerOpenRightPosition, setDraggerOpenRightPosition] = useState([returnIAOpenRight(), 0, 0.115 + cabinetMirrorType]);
  const [draggerOpenLeftPosition, setDraggerOpenLeftPosition] = useState([iAOpenLeft, 0, 0.115 + cabinetMirrorType]);
  const [dragger12Position, setDragger12Position] = useState([iA12, 0, 0.115 + cabinetMirrorType]);
  const [dragger23Position, setDragger23Position] = useState([iA23, 0, 0.115 + cabinetMirrorType]);
  const [dragger34Position, setDragger34Position] = useState([iA34, 0, 0.115 + cabinetMirrorType]);
  const [dragger45Position, setDragger45Position] = useState([iA45, 0, 0.115 + cabinetMirrorType]);
  const [dragger56Position, setDragger56Position] = useState([iA56, 0, 0.115 + cabinetMirrorType]);

  const [draggerOpenRightPositionDiff, setDraggerOpenRightPositionDiff] = useState(0);
  const [draggerOpenLeftPositionDiff, setDraggerOpenLeftPositionDiff] = useState(0);
  const [dragger12PositionDiff, setDragger12PositionDiff] = useState(0);
  const [dragger23PositionDiff, setDragger23PositionDiff] = useState(0);
  const [dragger34PositionDiff, setDragger34PositionDiff] = useState(0);
  const [dragger45PositionDiff, setDragger45PositionDiff] = useState(0);
  const [dragger56PositionDiff, setDragger56PositionDiff] = useState(0);

  const [scaleDiffOpenRight, setScaleDiffOpenRight] = useState(0);
  const [scaleDiffOpenLeft, setScaleDiffOpenLeft] = useState(0);
  const [scaleDiff1, setScaleDiff1] = useState(0);
  const [scaleDiff2, setScaleDiff2] = useState(0);
  const [scaleDiff3, setScaleDiff3] = useState(0);
  const [scaleDiff4, setScaleDiff4] = useState(0);
  const [scaleDiff5, setScaleDiff5] = useState(0);
  const [scaleDiff6, setScaleDiff6] = useState(0);

  //----TestMenuMesh visibility states

  //----Dragger Functions

  const bindDraggerOpenRight = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = draggerOpenRightPosition;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= firstCabinet.current.scale.x) {
        setDraggerOpenRightPosition([returnIAOpenRight() + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );

  const bindDraggerOpenLeft = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = draggerOpenLeftPosition;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= firstCabinet.current.scale.x) {
        setDraggerOpenLeftPosition([iAOpenLeft + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );

  const bindDragger12 = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = dragger12Position;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= firstCabinet.current.scale.x) {
        setDragger12Position([iA12 + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );

  const bindDragger23 = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = dragger23Position;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= secondCabinet.current.scale.x) {
        setDragger23Position([iA23 + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );

  const bindDragger34 = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = dragger34Position;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= thirdCabinet.current.scale.x) {
        setDragger34Position([iA34 + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );
  const bindDragger45 = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = dragger45Position;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= fourthCabinet.current.scale.x) {
        setDragger45Position([iA45 + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );
  const bindDragger56 = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = dragger56Position;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= fifthCabinet.current.scale.x) {
        setDragger56Position([iA56 + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );

  useEffect(() => {
    const iAOpenLeft = (cabinetMirrorWidth / doorNumber / 400) * 2 * -0.25;
    const iA12 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 0.25;
    const iA23 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 0.75;
    const iA34 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 1.25;
    const iA45 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 1.75;
    const iA56 = (cabinetMirrorWidth / doorNumber / 400) * 2 * 2.25;

    const returnIAOpenRight = () => {
      switch (doorNumber) {
        case 1:
          return iA12;
        case 2:
          return iA23;
        case 3:
          return iA34;
        case 4:
          return iA45;
        case 5:
          return iA56;
        case 6:
          return (cabinetMirrorWidth / doorNumber / 400) * 2 * 2.75;
      }
    };

    //------------------------------------------

    setDraggerOpenRightPosition((prev) => [returnIAOpenRight(), 0, 0.115 + cabinetMirrorType]);
    setDraggerOpenLeftPosition((prev) => [iAOpenLeft, 0, 0.115 + cabinetMirrorType]);
    setDragger12Position((prev) => [iA12, 0, 0.115 + cabinetMirrorType]);
    setDragger23Position((prev) => [iA23, 0, 0.115 + cabinetMirrorType]);
    setDragger34Position((prev) => [iA34, 0, 0.115 + cabinetMirrorType]);
    setDragger45Position((prev) => [iA45, 0, 0.115 + cabinetMirrorType]);
    setDragger56Position((prev) => [iA56, 0, 0.115 + cabinetMirrorType]);
  }, [cabinetMirrorType, doorNumber, cabinetMirrorWidth]);

  useFrame((state) => {
    // -----CABINET SCALES----\\
    const initialScaleX = (cabinetMirrorWidth / doorNumber / 400) * 2;

    setScaleDiffOpenRight((openRightCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiffOpenLeft((openLeftCabinet.current.scale.x - initialScaleX) / 2);

    setScaleDiff1((firstCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff2((secondCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff3((thirdCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff4((fourthCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff5((fifthCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff6((sixthCabinet.current.scale.x - initialScaleX) / 2);

    setDraggerOpenRightPositionDiff(draggerOpenRightPosition[0] - returnIAOpenRight());
    setDraggerOpenLeftPositionDiff(draggerOpenLeftPosition[0] - iAOpenLeft);
    setDragger12PositionDiff(dragger12Position[0] - iA12);
    setDragger23PositionDiff(dragger23Position[0] - iA23);
    setDragger34PositionDiff(dragger34Position[0] - iA34);
    setDragger45PositionDiff(dragger45Position[0] - iA45);
    setDragger56PositionDiff(dragger56Position[0] - iA56);

    gsap.to(openRightCabinet.current.scale, { x: initialScaleX / 2 + draggerOpenRightPositionDiff + ((rightCompartmentWidth - 150) / 300) * (initialScaleX / 2 + draggerOpenRightPositionDiff) });
    gsap.to(openLeftCabinet.current.scale, { x: initialScaleX / 2 + draggerOpenLeftPositionDiff + ((leftCompartmentWidth - 150) / 300) * (initialScaleX / 2 + draggerOpenRightPositionDiff) });

    gsap.to(firstCabinet.current.scale, { x: initialScaleX + dragger12PositionDiff - draggerOpenLeftPositionDiff });
    gsap.to(secondCabinet.current.scale, {
      x: initialScaleX + dragger23PositionDiff - dragger12PositionDiff,
    });
    gsap.to(thirdCabinet.current.scale, {
      x: initialScaleX + dragger34PositionDiff - dragger23PositionDiff,
    });
    gsap.to(fourthCabinet.current.scale, {
      x: initialScaleX + dragger45PositionDiff - dragger34PositionDiff,
    });
    gsap.to(fifthCabinet.current.scale, {
      x: initialScaleX - dragger45PositionDiff + dragger56PositionDiff,
    });
    gsap.to(sixthCabinet.current.scale, { x: initialScaleX - dragger56PositionDiff });

    //** */
    gsap.to(openLeftCabinet.current.position, { x: -initialScaleX * 0.1 + scaleDiffOpenLeft - (initialScaleX * 0.5 * (leftCompartmentWidth - 150)) / 350 });

    const openRightMultiplier = () => {
      switch (doorNumber) {
        case 1:
          return 0.6;
        case 2:
          return 1.1;
        case 3:
          return 1.6;
        case 4:
          return 2.1;
        case 5:
          return 2.6;
        case 6:
          return 3.1;
        default:
          return 0.6;
      }
    };
    gsap.to(openRightCabinet.current.position, { x: initialScaleX * openRightMultiplier() + scaleDiffOpenRight - (rightCompartmentWidth - 150) / (500 * doorNumber) });

    gsap.to(firstCabinet.current.position, { x: initialScaleX * 0 + scaleDiff1 * 0.5 + draggerOpenLeftPositionDiff * 0.5 });
    gsap.to(secondCabinet.current.position, { x: initialScaleX * 0.5 + scaleDiff2 * 0.5 + dragger12PositionDiff * 0.5 });
    gsap.to(thirdCabinet.current.position, { x: initialScaleX * 1 + scaleDiff3 * 0.5 + dragger23PositionDiff * 0.5 });
    gsap.to(fourthCabinet.current.position, { x: initialScaleX * 1.5 + scaleDiff4 * 0.5 + dragger34PositionDiff * 0.5 });
    gsap.to(fifthCabinet.current.position, { x: initialScaleX * 2 + scaleDiff5 * 0.5 + dragger45PositionDiff * 0.5 });
    gsap.to(sixthCabinet.current.position, { x: initialScaleX * 2.5 + scaleDiff6 * 0.5 + dragger56PositionDiff * 0.5 });
  });

  useEffect(() => {
    frankfurt.current.scale.y = 1.5 + (cabinetMirrorHeight - 400) / 400;
    frankfurt.current.position.y = 0;
    frankfurt.current.position.x = -1;

    frankfurt.current.position.z = 0 + 0.065 * cabinetMirrorDepth * 0.00125;
    frankfurt.current.scale.z = 2 + cabinetMirrorDepth * 0.00225;
  }, [cabinetMirrorWidth, cabinetMirrorHeight, cabinetMirrorDepth, cabinetMirrorType]);

  const isBottomAndTopLedVisible = externalLeds === 3;
  const isBottomLedVisible = isBottomAndTopLedVisible || externalLeds === 1;
  const isTopLedVisible = isBottomAndTopLedVisible || externalLeds === 2;

  const hasBottomShelf = [
    EnumCabinetMirrors.MUNCHEN_6,
    EnumCabinetMirrors.BUDAPEST,
    EnumCabinetMirrors.SANTANDER,
    EnumCabinetMirrors.LISSABON,
    EnumCabinetMirrors.LINZ,
    EnumCabinetMirrors.CORTINA,
    EnumCabinetMirrors.WINDSOR,
    EnumCabinetMirrors.VERONICA_2,
    EnumCabinetMirrors.PANDORA_2,
    EnumCabinetMirrors.KATHERINE_2,
  ].includes(selectedMirror);

  const hasPandora = [
    EnumCabinetMirrors.VERONICA_3,
    EnumCabinetMirrors.PANDORA_3,
    EnumCabinetMirrors.KATHERINE_3,
    EnumCabinetMirrors.VERONICA_4,
    EnumCabinetMirrors.PANDORA_4,
    EnumCabinetMirrors.KATHERINE_4,
  ].includes(selectedMirror);

  const hasInnerLed = ![
    EnumCabinetMirrors.MALAGA,
    EnumCabinetMirrors.SARAGOSSA,
    EnumCabinetMirrors.KONSTANZ,
    EnumCabinetMirrors.DERBY,
    EnumCabinetMirrors.GENT,
    EnumCabinetMirrors.VENEZIA,
    EnumCabinetMirrors.ALMERIA,
    EnumCabinetMirrors.GRADO,
    EnumCabinetMirrors.VILLACH,
    EnumCabinetMirrors.VERONICA_3,
    EnumCabinetMirrors.PANDORA_3,
    EnumCabinetMirrors.KATHERINE_3,
    EnumCabinetMirrors.VERONICA_4,
    EnumCabinetMirrors.PANDORA_4,
    EnumCabinetMirrors.KATHERINE_4,
  ].includes(selectedMirror);

  const hasLedFrame = [EnumCabinetMirrors.MUNCHEN_4].includes(selectedMirror);

  // CM Adjustment for added cabinets

  // const widthCm = cabinet
  const [widthOfWidthCM, setWidthOfWidthCM] = useState(220 + cabinetMirrorWidth * 0.682);
  const [leftOfWidthCM, setLeftOfWidthCM] = useState(-235 - cabinetMirrorWidth * 0.2);
  const [textPosWidthCM, setTextPosWidthCM] = useState(-1.2 + cabinetMirrorWidth * 0.00032);
  const [positionOfDepthCM, setPositionOfDepthCM] = useState([-0.7 - cabinetMirrorWidth * 0.001, -0.53 - cabinetMirrorHeight * 0.0004, -0.18 - cabinetMirrorDepth * 0.0001]);
  const [lineLeftDepth, setLineLeftDepth] = useState(-15 + cabinetMirrorDepth * 0.05);
  const [textPosDepth, setTextPosDepth] = useState(0.28 - cabinetMirrorDepth * 0.0001);
  const [outerLedPositionX, setOuterLedPositionX] = useState(0);
  const [outerLedScaleX, setOuterLedScaleX] = useState(2);

  useEffect(() => {
    if (doorNumber > 5) {
      setWidthOfWidthCM(
        -35 + cabinetMirrorWidth * 0.975 + cabinetMirrorWidth * 0.015 * ((rightCompartmentWidth - 150) / 150) * 2.4 + cabinetMirrorWidth * 0.0025 * ((leftCompartmentWidth - 150) / 150) * 17
      );
      setLeftOfWidthCM(-370 - cabinetMirrorWidth * 0.135 - cabinetMirrorWidth * 0.002 * ((leftCompartmentWidth - 150) / 150) * 22);
      setTextPosWidthCM(-0.55 + cabinetMirrorWidth * 0.0009);
      setPositionOfDepthCM((prev) => [-1.15 - cabinetMirrorWidth * 0.0004 - ((leftCompartmentWidth - 150) / 150) * 0.325, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);

      setOuterLedPositionX(
        0 + cabinetMirrorWidth * 0.00104 - cabinetMirrorWidth * 0.0017 * ((leftCompartmentWidth - 150) / 150) * 0.042 + cabinetMirrorWidth * 17 * ((rightCompartmentWidth - 150) / 150) * 0.000004
      );
      setOuterLedScaleX(
        0.03 + cabinetMirrorWidth * 0.0019 + cabinetMirrorWidth * 0.0013 * ((leftCompartmentWidth - 150) / 150) * 0.08 + cabinetMirrorWidth * 90 * ((rightCompartmentWidth - 150) / 150) * 0.0000012
      );
    } else if (doorNumber > 4) {
      setWidthOfWidthCM(
        -20 + cabinetMirrorWidth * 0.99 + cabinetMirrorWidth * 0.015 * ((rightCompartmentWidth - 150) / 150) * 3 + cabinetMirrorWidth * 0.0025 * ((leftCompartmentWidth - 150) / 150) * 18
      );
      setLeftOfWidthCM(-370 - cabinetMirrorWidth * 0.155 - cabinetMirrorWidth * 0.002 * ((leftCompartmentWidth - 150) / 150) * 25);
      setTextPosWidthCM(-0.7 + cabinetMirrorWidth * 0.0009);
      setPositionOfDepthCM((prev) => [-1.15 - cabinetMirrorWidth * 0.0005 - ((leftCompartmentWidth - 150) / 150) * 0.325, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);

      setOuterLedPositionX(
        0 + cabinetMirrorWidth * 0.001 - cabinetMirrorWidth * 0.002 * ((leftCompartmentWidth - 150) / 150) * 0.042 + cabinetMirrorWidth * 17 * ((rightCompartmentWidth - 150) / 150) * 0.000005
      );
      setOuterLedScaleX(
        0.03 + cabinetMirrorWidth * 0.00195 + cabinetMirrorWidth * 0.0013 * ((leftCompartmentWidth - 150) / 150) * 0.08 + cabinetMirrorWidth * 90 * ((rightCompartmentWidth - 150) / 150) * 0.0000012
      );
    } else if (doorNumber > 3) {
      setWidthOfWidthCM(
        10 + cabinetMirrorWidth * 1.01 + cabinetMirrorWidth * 0.015 * ((rightCompartmentWidth - 150) / 150) * 4.251 + cabinetMirrorWidth * 0.0025 * ((leftCompartmentWidth - 150) / 150) * 25
      );
      setLeftOfWidthCM(-370 - cabinetMirrorWidth * 0.2 - cabinetMirrorWidth * 0.002 * ((leftCompartmentWidth - 150) / 150) * 30);
      setTextPosWidthCM(-0.6 + cabinetMirrorWidth * 0.0007);
      setPositionOfDepthCM((prev) => [-1.15 - cabinetMirrorWidth * 0.0006 - cabinetMirrorWidth * 0.0004 * ((leftCompartmentWidth - 150) / 150) * 0.5, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);

      setOuterLedPositionX(
        0 + cabinetMirrorWidth * 0.00093 - cabinetMirrorWidth * 0.002 * ((leftCompartmentWidth - 150) / 150) * 0.042 + cabinetMirrorWidth * 17 * ((rightCompartmentWidth - 150) / 150) * 0.0000065
      );
      setOuterLedScaleX(
        0.03 + cabinetMirrorWidth * 0.00206 + cabinetMirrorWidth * 0.0013 * ((leftCompartmentWidth - 150) / 150) * 0.08 + cabinetMirrorWidth * 90 * ((rightCompartmentWidth - 150) / 150) * 0.0000012
      );
    } else if (doorNumber > 2) {
      setWidthOfWidthCM(
        -20 + cabinetMirrorWidth * 1.1 + cabinetMirrorWidth * 0.004 * ((rightCompartmentWidth - 150) / 150) * 17 + cabinetMirrorWidth * 0.0013 * ((leftCompartmentWidth - 150) / 150) * 50
      );
      setLeftOfWidthCM(-370 - cabinetMirrorWidth * 0.27 - cabinetMirrorWidth * 0.0015 * ((leftCompartmentWidth - 150) / 150) * 50);
      setTextPosWidthCM(-0.85 + cabinetMirrorWidth * 0.0007);
      setPositionOfDepthCM((prev) => [-1.17 - cabinetMirrorWidth * 0.0008 - cabinetMirrorWidth * 0.0004 * ((leftCompartmentWidth - 150) / 150) * 0.6, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);

      setOuterLedPositionX(
        0 + cabinetMirrorWidth * 0.000845 - cabinetMirrorWidth * 0.0013 * ((leftCompartmentWidth - 150) / 150) * 0.1 + cabinetMirrorWidth * 15 * ((rightCompartmentWidth - 150) / 150) * 0.000008
      );
      setOuterLedScaleX(
        0.03 + cabinetMirrorWidth * 0.00215 + cabinetMirrorWidth * 0.0013 * ((leftCompartmentWidth - 150) / 150) * 0.16 + cabinetMirrorWidth * 70 * ((rightCompartmentWidth - 150) / 150) * 0.0000025
      );
    } else if (doorNumber > 1) {
      setWidthOfWidthCM(
        0 + cabinetMirrorWidth * 1.21 + cabinetMirrorWidth * 0.0032 * ((rightCompartmentWidth - 150) / 150) * 30 + cabinetMirrorWidth * 0.0013 * ((leftCompartmentWidth - 150) / 150) * 80
      );
      setLeftOfWidthCM(-375 - cabinetMirrorWidth * 0.4 - cabinetMirrorWidth * 0.0013 * ((leftCompartmentWidth - 150) / 150) * 80);
      setTextPosWidthCM(-0.925 + cabinetMirrorWidth * 0.00055 - ((leftCompartmentWidth - 150) / 150) * 0.1);
      setPositionOfDepthCM((prev) => [-1.17 - cabinetMirrorWidth * 0.0012 - cabinetMirrorWidth * 0.0004 * ((leftCompartmentWidth - 150) / 150) * 0.95, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);

      setOuterLedPositionX(
        0 + cabinetMirrorWidth * 0.00062 - cabinetMirrorWidth * 0.0013 * ((leftCompartmentWidth - 150) / 150) * 0.14 + cabinetMirrorWidth * 15 * ((rightCompartmentWidth - 150) / 150) * 0.00001
      );
      setOuterLedScaleX(
        0.03 + cabinetMirrorWidth * 0.0024 + cabinetMirrorWidth * 0.0013 * ((leftCompartmentWidth - 150) / 150) * 0.2 + cabinetMirrorWidth * 50 * ((rightCompartmentWidth - 150) / 150) * 0.000004
      );
    } else {
      setWidthOfWidthCM(230 + cabinetMirrorWidth * 1.21);
      setLeftOfWidthCM(-635 - cabinetMirrorWidth * 0.4);
      setTextPosWidthCM(-1.21 + cabinetMirrorWidth * 0.0003);
      setPositionOfDepthCM((prev) => [-1.15 - cabinetMirrorWidth * 0.00245, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);

      setOuterLedPositionX(0 - ((leftCompartmentWidth - 150) / 150) * 0.23 + ((rightCompartmentWidth - 150) / 150) * 0.15);
      setOuterLedScaleX(2.05 + ((leftCompartmentWidth - 150) / 150) * 0.38 + ((rightCompartmentWidth - 150) / 150) * 0.21);
    }
  }, [cabinetMirrorWidth, widthCmVisible, cabinetMirrorHeight, cabinetMirrorDepth, doorNumber, leftCompartmentWidth, rightCompartmentWidth]);
  useEffect(() => {});

  const [cmPositionOfZ, setCmPositionOfZ] = useState(0);
  useEffect(() => {
    if (cabinetMirrorType === 0) {
      setCmPositionOfZ(0.3);
      setPositionOfDepthCM((prev) => [prev[0], prev[1], -0.18 - cabinetMirrorDepth * 0.0001]);
    } else if (cabinetMirrorType === 0.2) {
      setLineLeftDepth(-40 + cabinetMirrorDepth * 0.13);
      setCmPositionOfZ(0.7);
      setTextPosDepth(0.15 + cabinetMirrorDepth * 0.0006);
      setPositionOfDepthCM((prev) => [prev[0], prev[1], 0.27 - cabinetMirrorDepth * 0.0001]);
    } else if (cabinetMirrorType === -0.08) {
      setLineLeftDepth(-10 - cabinetMirrorDepth * 0.08);
      setCmPositionOfZ(0.1);
      setTextPosDepth(0.28 - cabinetMirrorDepth * 0.0001);
      setPositionOfDepthCM((prev) => [prev[0], prev[1], -0.37 - cabinetMirrorDepth * 0.0001]);
    } else {
      setLineLeftDepth(5 - cabinetMirrorDepth * 0.12);
      setCmPositionOfZ(-0.1);
      setTextPosDepth(0.28 - cabinetMirrorDepth * 0.0001);
      setPositionOfDepthCM((prev) => [prev[0], prev[1], -0.57 - cabinetMirrorDepth * 0.0001]);
    }
  }, [cabinetMirrorType, cabinetMirrorDepth]);

  useEffect(() => {
    if (draggerVisible && props.isDragging) {
      const lengthArray = [];
      if (firstCabinet?.current?.visible) {
        const value = ((firstCabinet?.current?.scale.x * cabinetMirrorWidth) / doorNumber).toFixed(0);
        lengthArray.push(value);
      }
      if (secondCabinet?.current?.visible) {
        const value = ((secondCabinet?.current?.scale.x * cabinetMirrorWidth) / doorNumber).toFixed(0);
        lengthArray.push(value);
      }
      if (thirdCabinet?.current?.visible) {
        const value = ((thirdCabinet?.current?.scale.x * cabinetMirrorWidth) / doorNumber).toFixed(0);
        lengthArray.push(value);
      }
      if (fourthCabinet?.current?.visible) {
        const value = ((fourthCabinet?.current?.scale.x * cabinetMirrorWidth) / doorNumber).toFixed(0);
        lengthArray.push(value);
      }
      if (fifthCabinet?.current?.visible) {
        const value = ((fifthCabinet?.current?.scale.x * cabinetMirrorWidth) / doorNumber).toFixed(0);
        lengthArray.push(value);
      }
      if (sixthCabinet?.current?.visible) {
        const value = ((sixthCabinet?.current?.scale.x * cabinetMirrorWidth) / doorNumber).toFixed(0);
        lengthArray.push(value);
      }
      dispatch(setCustomDoorWidthValues(lengthArray));
    } else if (!draggerVisible) {
      setDraggerOpenLeftPosition([iAOpenLeft, 0, 0.115 + cabinetMirrorType]);
      setDraggerOpenRightPosition([returnIAOpenRight(), 0, 0.115 + cabinetMirrorType]);
      setDragger12Position([iA12, 0, 0.115 + cabinetMirrorType]);
      setDragger23Position([iA23, 0, 0.115 + cabinetMirrorType]);
      setDragger34Position([iA34, 0, 0.115 + cabinetMirrorType]);
      setDragger45Position([iA45, 0, 0.115 + cabinetMirrorType]);
      setDragger56Position([iA56, 0, 0.115 + cabinetMirrorType]);
    }
  }, [props.isDragging, draggerVisible, doorNumber]);

  const multiplierBottomTopLed =
    cabinetMirrorWidth < 651 ? 30 : cabinetMirrorWidth < 901 ? 4 : cabinetMirrorWidth < 1002 ? 2.9 : cabinetMirrorWidth < 1600 ? 2.6 : cabinetMirrorWidth < 2000 ? 2.5 : 2.4;
  const bottomTopLedPosX = (cabinetMirrorWidth / doorNumber / 400) * (doorNumber / multiplierBottomTopLed) - 0.035;

  useEffect(() => {
    if (outerLedRef.current && innerLedRef.current) {
      if (hasBottomShelf) {
        const bottomCompScaleDiffRatio = (bottomCompartmentHeight - 100) / 200;
        const cabinetHeightRatio = (cabinetMirrorHeight - 600) / 600;
        const initialScaleYOuterLed = 1 + cabinetMirrorHeight * 0.000005;
        const initialScaleYInnerLed = 0.97 + cabinetMirrorHeight * 0.00003;
        if (bottomCompScaleDiffRatio <= 2) {
          //--OUTER-LED
          outerLedRef.current.scale.y = initialScaleYOuterLed + initialScaleYOuterLed * (-bottomCompScaleDiffRatio * 0.21 + cabinetHeightRatio * 0.07);
          outerLedRef.current.position.y = 0 + bottomCompScaleDiffRatio * 0.075 - cabinetHeightRatio * 0.0238;
          //--INNER-LED
          innerLedRef.current.scale.y = initialScaleYInnerLed + initialScaleYInnerLed * (-bottomCompScaleDiffRatio * 0.25 + cabinetHeightRatio * 0.075) * 0.8;
          innerLedRef.current.position.y = 0 + bottomCompScaleDiffRatio * 0.075 - cabinetHeightRatio * 0.0238;
        }
      } else {
        outerLedRef.current.position.y = 0;
        innerLedRef.current.position.y = 0;
      }
    }
  }, [cabinetMirrorHeight, bottomCompartmentHeight]);

  return (
    <>
      {widthCmVisible && (
        <CentimeterWidthLines
          textWidth={cabinetMirrorWidth > 999 ? "85px" : "75px"}
          textPadding={cabinetMirrorWidth > 999 ? "0 12px" : "0 15px"}
          textValue={cabinetMirrorWidth == 0 ? "400" : cabinetMirrorWidth}
          textSize={"1.6em"}
          textHeight="40px"
          textPos={[textPosWidthCM, 0.9, 0.03]}
          lineLeft={leftOfWidthCM}
          lineWidth={widthOfWidthCM}
          scale={[0.12, doorNumber > 5 ? 0.4 : 0.3, 0.12]}
          position={[0, -0.35 + cabinetMirrorHeight * 0.001, cmPositionOfZ]}
        />
      )}
      {heightCmVisible && (
        <CentimeterHeightLines
          textWidth={cabinetMirrorHeight > 999 ? "85px" : "75px"}
          textPadding={cabinetMirrorHeight > 999 ? "0 12px" : "0 15px"}
          textValue={cabinetMirrorHeight == 0 ? "400" : cabinetMirrorHeight}
          textSize={"1.6em"}
          textHeight="40px"
          textPos={[cabinetMirrorHeight > 999 ? 0.59 : 0.61, 0.36, 0.02]}
          textTop={hasBottomShelf ? 5 + cabinetMirrorHeight / 200 : -35 + cabinetMirrorHeight / 200}
          lineTop={hasBottomShelf ? -50 - cabinetMirrorHeight * 0.34 : -60 - cabinetMirrorHeight * 0.34}
          lineHeight={hasBottomShelf ? 135 + cabinetMirrorHeight * 0.8 : 122 + cabinetMirrorHeight * 0.678}
          scale={[cabinetMirrorHeight > 1500 ? 0.3 : 0.2, 0.12, 0.12]}
          position={[doorNumber * 0.05 + -1.3 + cabinetMirrorWidth * 0.0024, -0.4, cmPositionOfZ]}
        />
      )}
      {depthCmVisible && (
        <CentimeterDepthLines
          textWidth={"75px"}
          textPadding={"0 14px"}
          textValue={cabinetMirrorDepth == 0 ? "125" : cabinetMirrorDepth}
          textSize={"1.6em"}
          textHeight="40px"
          textPos={[0.13, 0.9, textPosDepth]}
          // textPos={[0.13, 0.9, 0.24 + cabinetMirrorDepth * 0.00022]}
          // lineLeft={4}
          lineLeft={lineLeftDepth}
          lineWidth={170 + cabinetMirrorDepth * 0.15}
          scale={[0.12, doorNumber > 5 ? 0.4 : 0.3, 0.15]}
          position={positionOfDepthCM}
        />
      )}
      <group renderOrder={1} ref={frankfurt} {...props}>
        {/* LED ÇERÇEVE */}
        <group
          ref={outerLedRef}
          scale={[
            // 0.6668 +
            //   0.6668 * ((cabinetMirrorWidth - 400) / 400) +
            //   cabinetMirrorWidth / doorNumber / 650 +
            //   (6 / doorNumber) * ((leftCompartmentWidth - 150) / 700 + (rightCompartmentWidth - 150) / 700),
            outerLedScaleX,
            1 + cabinetMirrorHeight * 0.000005,
            1,
          ]}
          position={[
            outerLedPositionX,
            // ((cabinetMirrorWidth / doorNumber / 400) * (doorNumber - 1)) / 1.98 - 0.17 * ((leftCompartmentWidth - 150) / 150 + (rightCompartmentWidth - 150) / 75),
            0,
            0.116 + cabinetMirrorType,
          ]}
        >
          <mesh visible={hasLedFrame ? true : false} geometry={nodes.led_1.geometry} material={materials.aluminum} />
          <mesh visible={hasLedFrame ? true : false} geometry={nodes.led_2.geometry} material={materials.ledtexture}>
            <meshStandardMaterial color={lightColor} toneMapped={false} />
          </mesh>
        </group>

        {/* İÇ LED */}
        {hasInnerLed && (
          <mesh
            ref={innerLedRef}
            geometry={budapestNodes.interriorLed.geometry}
            material={materials.ledtexture}
            scale={[0.59 + 0.63 * ((cabinetMirrorWidth - 400) / 370), 0.97 + cabinetMirrorHeight * 0.00003, 1]}
            position={[((cabinetMirrorWidth / doorNumber / 400) * (doorNumber - 1)) / 2, 0, -0.02 + cabinetMirrorType]}
          >
            <meshBasicMaterial color={[lightColor[0] * 0.25, lightColor[1] * 0.25, lightColor[2] * 0.25]} toneMapped={false} />
          </mesh>
        )}

        {/* DIŞARIDAKİ LEDLER */}

        {isBottomLedVisible && (
          <DışAydınlatma
            visible={isBottomLedVisible}
            scaleY={cabinetMirrorWidth * 0.002}
            position={[bottomTopLedPosX, hasBottomShelf ? -0.56 : -0.406, 0.06 + cabinetMirrorType]}
            rotation={[Math.PI * 0.5, 0, Math.PI * 0.5]}
          />
        )}
        {isTopLedVisible && (
          <DışAydınlatma visible={isTopLedVisible} scaleY={cabinetMirrorWidth * 0.002} position={[bottomTopLedPosX, 0.405, 0.06 + cabinetMirrorType]} rotation={[Math.PI * 1.5, 0, Math.PI * 1.5]} />
        )}

        {/* OPEN LEFT CABINET */}
        <KapaksizBolme cabinetRef={openLeftCabinet} doorIndex={-1} doorType={"first"} />
        <mesh rotation-y={Math.PI * 0.5} position={draggerOpenLeftPosition} visible={false} {...bindDraggerOpenLeft()}>
          <boxGeometry args={[0.025, 0.2, 0.05]} />
          <meshBasicMaterial color={"#575757"} />
        </mesh>
        {/* OPEN RIGHT CABINET */}
        <KapaksizBolme cabinetRef={openRightCabinet} doorIndex={-1} doorType={"last"} />
        <mesh rotation-y={Math.PI * 0.5} position={draggerOpenRightPosition} visible={false}>
          <boxGeometry args={[0.025, 0.2, 0.05]} />
          <meshBasicMaterial color={"#575757"} />
        </mesh>

        {/* FIRST CABINET */}
        <KapakliBolme isSided={true} cabinetRef={firstCabinet} doorIndex={0} isLeftSided={isFirstLeftSided} socketUsbBase={EnumSocketUSBBase.LEFT} doorType={doorNumber === 1 ? "single" : "first"} />
        {draggerVisible !== 0 && doorNumber > 1 && (
          <mesh rotation-y={Math.PI * 0.5} position={dragger12Position} visible={draggerVisible === 0 ? false : true} {...bindDragger12()}>
            <boxGeometry args={[0.025, 0.2, 0.05]} />
            <meshBasicMaterial color={"#575757"} />
          </mesh>
        )}
        {/* SECOND CABINET */}
        <KapakliBolme
          isSided={true}
          cabinetRef={secondCabinet}
          doorIndex={1}
          isLeftSided={isSecondLeftSided}
          socketUsbBase={doorNumber > 2 && doorNumber < 5 ? EnumSocketUSBBase.MID : doorNumber === 2 ? EnumSocketUSBBase.RIGHT : ""}
          doorType={doorNumber > 2 ? "mid" : "last"}
        />
        {draggerVisible !== 0 && doorNumber > 2 && (
          <mesh rotation-y={Math.PI * 0.5} position={dragger23Position} {...bindDragger23()} visible={draggerVisible === 0 ? false : true}>
            <boxGeometry args={[0.025, 0.2, 0.05]} />
            <meshBasicMaterial color={"#575757"} />
          </mesh>
        )}
        {/* THIRD CABINET */}
        <KapakliBolme
          isSided={true}
          cabinetRef={thirdCabinet}
          doorIndex={2}
          isLeftSided={isThirdLeftSided}
          socketUsbBase={doorNumber === 3 ? EnumSocketUSBBase.RIGHT : doorNumber > 3 && doorNumber < 6 ? EnumSocketUSBBase.MID : ""}
          doorType={doorNumber > 3 ? "mid" : "last"}
        />
        {draggerVisible !== 0 && doorNumber > 3 && (
          <mesh rotation-y={Math.PI * 0.5} position={dragger34Position} {...bindDragger34()} visible={draggerVisible === 0 ? false : true}>
            <boxGeometry args={[0.025, 0.2, 0.05]} />
            <meshBasicMaterial color={"#575757"} />
          </mesh>
        )}
        {/* FOURTH CABINET */}

        <KapakliBolme
          isSided={true}
          cabinetRef={fourthCabinet}
          doorIndex={3}
          isLeftSided={isFourthLeftSided}
          socketUsbBase={doorNumber < 5 ? EnumSocketUSBBase.RIGHT : doorNumber === 6 ? EnumSocketUSBBase.MID : ""}
          doorType={doorNumber > 4 ? "mid" : "last"}
        />
        {draggerVisible !== 0 && doorNumber > 4 && (
          <mesh rotation-y={Math.PI * 0.5} position={dragger45Position} {...bindDragger45()} visible={draggerVisible === 0 ? false : true}>
            <boxGeometry args={[0.025, 0.2, 0.05]} />
            <meshBasicMaterial color={"#575757"} />
          </mesh>
        )}
        {/* FIFTH CABINET */}

        <KapakliBolme
          isSided={true}
          cabinetRef={fifthCabinet}
          doorIndex={4}
          isLeftSided={isFifthLeftSided}
          socketUsbBase={doorNumber > 5 ? "" : EnumSocketUSBBase.RIGHT}
          doorType={doorNumber > 5 ? "mid" : "last"}
        />
        {draggerVisible !== 0 && doorNumber > 5 && (
          <mesh rotation-y={Math.PI * 0.5} position={dragger56Position} {...bindDragger56()} visible={draggerVisible === 0 ? false : true}>
            <boxGeometry args={[0.025, 0.2, 0.05]} />
            <meshBasicMaterial color={"#575757"} />
          </mesh>
        )}

        {/* SIXTH CABINET */}

        <KapakliBolme isSided={true} cabinetRef={sixthCabinet} doorIndex={5} isLeftSided={isSixthLeftSided} socketUsbBase={EnumSocketUSBBase.RIGHT} doorType={"last"} />

        {hasPandora && <Pandora isTestCabinet={true} position={[((cabinetMirrorWidth / doorNumber / 400) * (doorNumber - 1)) / 2 + 0.04, -0.34, 0.185]} />}
      </group>
    </>
  );
}

// useGLTF.preload("/Frankfurt-test2.glb");
