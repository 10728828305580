import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React from "react";
import { useSelector } from "react-redux";

const Munih1Mirror = () => {
  const { nodes, materials } = useGLTF("/newModels/munih1.glb");
  const mirror = React.useRef();
  const mirror1 = React.useRef();
  const { camCikintiUst, camCikintiAlt } = useSelector((state) => state.cabinetMirror);
  useFrame(() => {
    if (mirror.current && mirror1.current) {
      mirror.current.scale.y = 1.02 - camCikintiUst * 0.0013 - camCikintiAlt * 0.0013;
      mirror1.current.scale.y = 1.02 - camCikintiUst * 0.0013 - camCikintiAlt * 0.0013;
      mirror.current.position.y = 0 - camCikintiUst * 0.0005 + camCikintiAlt * 0.0005;
      mirror1.current.position.y = 0 - camCikintiUst * 0.0005 + camCikintiAlt * 0.0005;
    }
  });
  return (
    <group scale-x={1.038} position-x={-0.0025}>
      <mesh geometry={nodes.leftDoorLeft_2.geometry} material={materials["glass.001"]} scale-y={1.06} position-x={-0.017} />
      <mesh ref={mirror} geometry={nodes.leftDoorLeft_2.geometry} material={materials["mirror.001"]} position-x={-0.017} />
      <mesh ref={mirror1} geometry={nodes.leftDoorLeft_3.geometry} material={materials["mirror.001"]} position-x={-0.017} />
    </group>
  );
};

export default Munih1Mirror;
