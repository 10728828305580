import React from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setShelfCount, setAhsapZemin } from "../../../store/cabinet";
import { setCamRafFiyatı, setAhsapZeminFiyatı } from "../../../store/cabinetFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const CamRaflar = () => {
  const dispatch = useDispatch();
  const { shelfCount } = useSelector((state) => state.cabinet);

  const handleChange = (e) => {
    dispatch(setShelfCount(e.target.value));
    dispatch(
      setCamRafFiyatı(e.target.value === 1 ? 15 : e.target.value === 2 ? 30 : e.target.value === 3 ? 45 : e.target.value === 4 ? 60 : e.target.value === 5 ? 70 : e.target.value === 6 ? 80 : 0)
    );
    dispatch(setAhsapZemin(0));
    dispatch(setAhsapZeminFiyatı(0));
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="22">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Glasböden
        </Typography>
      </RenderModal>

      <input
        name="id[18]"
        value={shelfCount == 0 ? 241 : shelfCount == 1 ? 2438 : shelfCount == 2 ? 2439 : shelfCount == 3 ? 2440 : shelfCount == 4 ? 245 : shelfCount == 5 ? 561 : shelfCount == 6 ? 562 : null}
      />

      <Select onChange={handleChange} value={shelfCount} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>Ohne Glasböden</MenuItem>
        <MenuItem value={1}>1 x Glasboden + 15,00 €</MenuItem>
        <MenuItem value={2}>2 x Glasboden + 30,00 €</MenuItem>
        <MenuItem value={3}>3 x Glasboden + 45,00 €</MenuItem>
        <MenuItem value={4}>4 x Glasboden + 60,00 €</MenuItem>
        <MenuItem value={5}>5 x Glasboden + 70,00 €</MenuItem>
        <MenuItem value={6}>6 x Glasboden + 80,00 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default CamRaflar;
