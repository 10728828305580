import React, { useEffect, useState } from "react";
import { Stack, Slider, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EnumCabinetMirrors, setPandoraLight, setPandoraLightScaleX } from "../../../../store/cabinetMirror";
import { setPandoraLightFee } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const LedIsigiPandora = () => {
  const dispatch = useDispatch();
  const { pandoraLight } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const [idValue, setIdValue] = useState(0);
  const handleChange = (e) => {
    dispatch(setPandoraLight(e.target.value));
  };

  const isPandora = [EnumCabinetMirrors.PANDORA_1, EnumCabinetMirrors.PANDORA_2, EnumCabinetMirrors.PANDORA_3, EnumCabinetMirrors.PANDORA_4].includes(selectedMirror);

  const isVeronica = [EnumCabinetMirrors.VERONICA_1, EnumCabinetMirrors.VERONICA_2, EnumCabinetMirrors.VERONICA_3, EnumCabinetMirrors.VERONICA_4].includes(selectedMirror);

  const isKatherine = [EnumCabinetMirrors.KATHERINE_1, EnumCabinetMirrors.KATHERINE_2, EnumCabinetMirrors.KATHERINE_3, EnumCabinetMirrors.KATHERINE_4].includes(selectedMirror);

  const isPandorish = isPandora || isVeronica || isKatherine;

  const menuOptionsPandora = [
    { value: 'Pandora Chrome 12" 8W Pack of 1', price: 0, idValue: "794", scaleX: 1 },
    { value: "Pandora chrome, 30.8 cm, 8W, 2 pieces", price: 25, idValue: "809", scaleX: 1 },
    { value: "Pandora chrome, 45.8 cm, 10W, 1 piece", price: 15, idValue: "1027", scaleX: 1.5 },
    { value: "Pandora chrome, 45.8 cm, 10W, 2 pieces", price: 55, idValue: "1028", scaleX: 1.5 },
    { value: "Pandora black, 45.8 cm, 10W, 1 piece", price: 30, idValue: "2023", scaleX: 1.5 },
    { value: "Pandora black, 45.8 cm, 10W, 2 pieces", price: 60, idValue: "2024", scaleX: 1.5 },
    { value: "Pandora chrome, 60.8 cm, 12W, 1 piece", price: 25, idValue: "1029", scaleX: 2 },
    { value: "Pandora chrome, 60.8 cm, 12W, 2 pieces", price: 75, idValue: "1030", scaleX: 2 },
    { value: "Pandora chrome, 80 cm, 15W, 1 piece", price: 45, idValue: "1031", scaleX: 8 / 3 },
    { value: "Pandora chrome, 80 cm, 15W, 2 pieces", price: 115, idValue: "1032", scaleX: 8 / 3 },
  ];
  const menuOptionsVeronica = [
    { value: "Veronica chrome, 30 cm, 8W, 1 piece", price: 0, idValue: "796", scaleX: 1 },
    { value: "Veronica chrome, 30 cm, 8W, 2 pieces", price: 30, idValue: "811", scaleX: 1 },
    { value: "Veronica black, 30 cm, 8W, 1 piece", price: 15, idValue: "2025", scaleX: 1 },
    { value: "Veronica black, 30 cm, 8W, 2 pieces", price: 60, idValue: "2026", scaleX: 1 },
  ];
  const menuOptionsKatherine = [
    { value: "Katherine I, 30 cm, 6W, 1 piece", price: 0, idValue: "791", scaleX: 1 },
    { value: "Katherine I, 30 cm, 6W, 2 pieces", price: 55, idValue: "806", scaleX: 1 },
    { value: "Katherine II, 50 cm, 6W, 1 piece", price: 15, idValue: "792", scaleX: 5 / 3 },
    { value: "Katherine II, 50 cm, 6W, 2 pieces", price: 85, idValue: "807", scaleX: 5 / 3 },
  ];

  const allPandorishOptions = [...menuOptionsPandora, ...menuOptionsVeronica, ...menuOptionsKatherine];

  const returnMenuOptions = () => {
    const menuOptions = isPandora ? menuOptionsPandora : isKatherine ? menuOptionsKatherine : menuOptionsVeronica;
    return menuOptions;
  };

  useEffect(() => {
    if (isPandora) {
      dispatch(setPandoraLight(menuOptionsPandora[0].value));
      setIdValue(menuOptionsPandora[0].idValue);
      dispatch(setPandoraLightFee(0));
    }
    if (isVeronica) {
      dispatch(setPandoraLight(menuOptionsVeronica[0].value));
      setIdValue(menuOptionsVeronica[0].idValue);
      dispatch(setPandoraLightFee(0));
    }
    if (isKatherine) {
      dispatch(setPandoraLight(menuOptionsKatherine[0].value));
      setIdValue(menuOptionsKatherine[0].idValue);
      dispatch(setPandoraLightFee(0));
    }
    dispatch(setPandoraLightFee(0));
  }, [selectedMirror]);

  useEffect(() => {
    const matchedOption = allPandorishOptions.find((item) => item.value === pandoraLight);
    if (isPandorish && matchedOption) {
      dispatch(setPandoraLightFee(matchedOption.price));
      dispatch(setPandoraLightScaleX(matchedOption.scaleX));
    }
  }, [pandoraLight]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="28">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          LED Leuchte (pro Spiegel)
        </Typography>
      </RenderModal>
      <Select onChange={handleChange} value={pandoraLight} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        {returnMenuOptions().map((item) => (
          <MenuItem
            value={item.value}
            onClick={() => {
              setIdValue(item.idValue);
              dispatch(setPandoraLightScaleX(item.scaleX));
            }}
          >
            {item.value} {item.price > 0 && `+ €${item.price}`}
          </MenuItem>
        ))}
      </Select>
      <input name="id[96]" type="hidden" value={idValue} />
    </Stack>
  );
};

export default LedIsigiPandora;
