import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setSocketUsbDisarida } from "../../../../store/cabinetMirror";
import { setSocketUsbDisaridaFiyatı } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const SoketlerDisarida = () => {
  const dispatch = useDispatch();
  const { socketUsbDisarida } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const soketlerDisardaOptions = [
    {
      label: "---",
      price: 0.0,
      value: "761",
    },
    {
      label: "1x Steckdose unten links",
      price: 39.0,
      value: "763",
    },
    {
      label: "1x Steckdose unten rechts",
      price: 39.0,
      value: "762",
    },
    {
      label: "2x Steckdose unten links (Doppelframe)",
      price: 49.0,
      value: "765",
    },
    {
      label: "2x Steckdose unten rechts (Doppelframe)",
      price: 49.0,
      value: "764",
    },
    {
      label: "1x Steckdose unten rechts + 1x Steckdose unten links",
      price: 78.0,
      value: "766",
    },
    {
      label: "2x Steckdose unten rechts + 2x Steckdose unten links",
      price: 98.0,
      value: "767",
    },
    {
      label: "1x USB links",
      price: 45.0,
      value: "6799",
    },
    {
      label: "1x USB rechts",
      price: 45.0,
      value: "6800",
    },
    {
      label: "1x USB links + 1x USB rechts",
      price: 85.0,
      value: "8822",
    },
    {
      label: "1x USB + 1x Steckdose links (2-fach Rahmen)",
      price: 79.0,
      value: "6801",
    },
    {
      label: "1x USB + 1x Steckdose rechts (2-fach Rahmen)",
      price: 79.0,
      value: "6802",
    },
    {
      label: "1x USB + 2x Steckdose links (3-fach Rahmen)",
      price: 119.0,
      value: "6803",
    },
    {
      label: "1x USB + 2x Steckdose rechts (3-fach Rahmen)",
      price: 119.0,
      value: "6804",
    },
  ];

  useEffect(() => {
    const matchedOption = soketlerDisardaOptions.find((item) => item.label === socketUsbDisarida);
    if (matchedOption) {
      dispatch(setSocketUsbDisaridaFiyatı(matchedOption.price));
    }
  }, [socketUsbDisarida]);

  useEffect(() => {
    dispatch(setSocketUsbDisaridaFiyatı(0));
    dispatch(setSocketUsbDisarida(0));
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="24">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Steckdose/USB außen/im Fach
        </Typography>
      </RenderModal>

      <input name="id[92]" value={soketlerDisardaOptions.find((item) => item.label === socketUsbDisarida)?.value || 761} />

      <Select
        onChange={(e) => {
          dispatch(setSocketUsbDisarida(e.target.value));
        }}
        value={socketUsbDisarida == 0 ? "---" : socketUsbDisarida}
        sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
      >
        {soketlerDisardaOptions.map((item) => (
          <MenuItem value={item.label}>
            {item.label} {item.price > 0 ? "+ €" + item.price : ""}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default SoketlerDisarida;
