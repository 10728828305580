import React from "react";
import { Stack, Box, Typography, Select } from "@mui/material";
import { EnumMirrors, changeKareAynaModel, changeMirror, resetToInitialState, setSquareMirrorSelectedItem } from "../../../store/mirrors";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedExcelItem } from "../../../store/mirrors";

const AynaTipi = () => {
  const dispatch = useDispatch();

  const { isPrivate } = useSelector((state) => state.mirror);

  return (
    <>
      {!isPrivate ? (
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          {/* <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
            Spiegeltyp
          </Typography> */}
          <Stack value={0} direction="row" sx={{ flexGrow: "1", fontSize: "0.7rem", gap: "30px" }}>
            <Stack
              alignItems="center"
              justifyContent="center"
              className="cursor-pointer"
              onClick={() => {
                dispatch(resetToInitialState());
                dispatch(changeMirror(EnumMirrors.CHARON));
                dispatch(
                  changeSelectedExcelItem({
                    name: "Charon",
                    id: EnumMirrors.CHARON,
                  })
                );
              }}
              sx={{
                border: "2px solid rgb(140,140,140,0.5)",
                borderRadius: 1,
                height: 88,
                py: 2,
                px: 1,
              }}
            >
              <img src={"./ikonlar/ikon_yuvarlakayna_geniş_ışıklı.png"} style={{ maxHeight: "60px" }} />
              <Typography fontWeight={900} sx={{ textAlign: "center", fontSize: "0.7rem", mt: 0.5 }}>
                Runde Spiegel
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              className="cursor-pointer"
              onClick={() => {
                dispatch(resetToInitialState());
                dispatch(changeMirror(122));
                dispatch(setSquareMirrorSelectedItem("Arka"));
                dispatch(
                  changeSelectedExcelItem({
                    name: "New Jersey",
                    id: EnumMirrors.NEW_JERSEY,
                  })
                );
                // dispatch(changeMirror(122));
                // dispatch(changeMirror(EnumMirrors.NEW_JERSEY));
                // dispatch(changeKareAynaModel("newjersey"));
                // dispatch(
                //   changeSelectedExcelItem({
                //     name: "New Jersey",
                //     id: EnumMirrors.NEW_JERSEY,
                //   })
                // );
              }}
              sx={{
                border: "2px solid rgb(140,140,140,0.5)",
                borderRadius: 1,
                height: 88,
                py: 2,
                px: 1,
              }}
            >
              <img
                src={"/ikonlar/ikon_keskinkose.png"}
                style={{
                  width: 50,
                  height: 50,
                }}
              />
              <Typography fontWeight={900} sx={{ textAlign: "center", fontSize: "0.7rem", mt: 0.5 }}>
                Rechteckige Spiegel
              </Typography>
            </Stack>
            {/* <Stack
        alignItems="center"
        justifyContent="center"
        className="cursor-pointer"
        onClick={() => {
          dispatch(changeMirror(3));
          dispatch(
            changeSelectedExcelItem({
              name: "Koseli",
              id: 122,
            })
          );
        }}
        sx={{
          border: "2px solid rgb(140,140,140,0.5)",
          borderRadius: 1,
          height: 88,
          py: 2,
          px: 1,
        }}
      >
        <img
          src={"/ikonlar/ikon_ovalköşe.png"}
          style={{ maxHeight: "60px" }}
        />
        <Typography
          fontWeight={900}
          sx={{ textAlign: "center", fontSize: "0.7rem", mt: 0.5 }}
        >
          Köşeli Ayna
        </Typography>
      </Stack> */}
          </Stack>
        </Stack>
      ) : null}
    </>
  );
};

export default AynaTipi;
