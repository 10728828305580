import { useGLTF } from "@react-three/drei";
import React from "react";

export const MakyajAynasi = ({ isLeftDoor }) => {
  const { materials } = useGLTF("/makyajaynasi.glb");

  return isLeftDoor ? (
    <group visible={true} name="makeup-mirror" position={[0.28, -0.12, -0.01]}>
      <mesh material={materials.ayna} rotation-y={Math.PI}>
        <circleGeometry args={[0.094, 48]} />
      </mesh>
      <mesh position-z={0.005} rotation-y={Math.PI}>
        <circleGeometry args={[0.1, 48]} />
        <meshStandardMaterial color={"black"} />
      </mesh>
    </group>
  ) : (
    <group visible={true} name="makeup-mirror" rotation-y={Math.PI} position={[0.28, 0.12, -0.01]}>
      <mesh material={materials.ayna} rotation-y={Math.PI}>
        <circleGeometry args={[0.093, 48]} />
      </mesh>
      <mesh position-z={-0.005}>
        <circleGeometry args={[0.095, 48]} />
        <meshStandardMaterial color={[0, 0, 0]} />
      </mesh>
    </group>
  );
};
