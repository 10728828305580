import React, { useEffect } from "react";
import { Stack, Typography, Divider, useMediaQuery } from "@mui/material";
import Genislik from "./menuItems/Genislik";
import YorumYapın from "./menuItems/YorumYapın";
import SiparisVer from "./menuItems/SiparisVer";
import { useDispatch, useSelector } from "react-redux";
import Yukseklik from "./menuItems/Yukseklik";
import AynaTipi from "./menuItems/AynaTipi";
import { data } from "jquery";
import AynaAdedi from "./menuItems/AynaAdedi";
import { getProductWithMatrixByName, getProductWithMatrixByNameForDaire, readPriceFromApiForKare } from "../../utils/readJson";
import { setBaseFee } from "../../store/mirrorFee";
import { changeHeightCmVisible, changeWidthCmVisible, EnumMirrors, changeMenuIsOpen } from "../../store/mirrors";
import MenuToggleButton from "../AynaliDolapComponents/menuItems/Buttons/MenuToggleButton";
import StraightenIcon from "@mui/icons-material/Straighten";
import SummaryModal from "./SummaryModal";
import CloseIcon from "@mui/icons-material/Close";
import Kanten from "./menuItems/Kanten";
import Versiegelung from "./menuItems/Versiegelung";
import Aufhängebleche from "./menuItems/Aufhängebleche";
import Rahmen from "./menuItems/Rahmen";

import MakyajAynası from "./menuItems/MakyajAynası";
import MakyajAynasıTipi from "./menuItems/MakyajAynasıTipi";
import MakyajAynasıPozisyon from "./menuItems/MakyajAynasıPozisyon";
import MakyajAynaUzaklık from "./menuItems/MakyajAynaUzaklık";

const PaintedMirrorMenu = () => {
  /**
   * Ayna Stateler
   * Aynalar: selectedMirror / changeMirror 123
   *
   * Işık Rengi: lightColor / changeLightColor "white", "red", "yellow", "blue"
   *
   * Saat Konumu: watchPosition / changeWatchPosition discorddan attı
   *
   * Dijital Saat : digitalWatch / setDigitalWatch
   *
   */

  const {
    yataySensor,
    dikeySensor,
    raf,
    analogWatch,
    digitalWatch,
    makeupMirror,
    selectedMirror,
    makeupMirrorPos,
    makeupMirrorSensor,
    mirrorWidth,
    mirrorHeight,
    aynaIsıtma,
    lightColorIndex,
    soket,
    isiSensor,
    selectedMirrorName,
    selectedMirrorExcelIndex,
    orderAmount,
    isPrivate,
    isMenuOpen,
    sensors,
    makeupMirrorType,
  } = useSelector((state) => state.mirror);

  const {
    baseFee,
    yanTamKaplamaFee,
    facetaFee,
    ledIsigiFee,
    aynaIsitmaFee,
    rafFee,
    makyajIsikFee,
    makyajTypeFee,
    sizdirmazlikFee,
    bluetoothFee,
    makyajFee,
    soketFee,
    saatFee,
    isiSensorFee,
    kareSensorFee,
    gecisAnahtariSensorFee,
    yataySensorFee,
    ciftLedFee,
    suspansiyonFee,
    hangingPlatesFee,
  } = useSelector((state) => state.mirrorFee);

  const dispatch = useDispatch();
  const desktopMatches1700 = useMediaQuery("(min-width:1700px)");
  const desktopMatches1200 = useMediaQuery("(min-width:1200px)");
  const desktopMatches900 = useMediaQuery("(min-width:900px)");
  const mobileMatches600 = useMediaQuery("(min-width:600px)");
  const menuResponsiveWidth = desktopMatches1700 ? "29vw" : desktopMatches1200 ? "29vw" : desktopMatches900 ? "40vw" : mobileMatches600 ? "50vw" : "100vw";
  const menuTranslateXClosed = desktopMatches1200 ? "0" : desktopMatches900 ? "50vw" : mobileMatches600 ? "60vw" : "150vw";
  const menuToggledPosition = isMenuOpen ? "0" : menuTranslateXClosed;

  const isDaire = selectedMirror == EnumMirrors.CHARON || selectedMirror == EnumMirrors.DIANA || selectedMirror == EnumMirrors.HELENA;

  const dimensionRule = isDaire ? mirrorWidth > 1200 : mirrorWidth > 1200 && mirrorHeight > 800;

  const dimensionFee = dimensionRule ? 49.99 : 0;

  const total = Number(
    parseFloat(
      parseFloat(baseFee.toFixed(2)) +
        (yanTamKaplamaFee +
          facetaFee +
          rafFee +
          makyajIsikFee +
          makyajTypeFee +
          ledIsigiFee +
          sizdirmazlikFee +
          bluetoothFee +
          makyajFee +
          soketFee +
          saatFee +
          isiSensorFee +
          kareSensorFee +
          yataySensorFee +
          ciftLedFee +
          suspansiyonFee +
          aynaIsitmaFee +
          gecisAnahtariSensorFee +
          hangingPlatesFee +
          dimensionFee) *
          orderAmount
    ).toFixed(2)
  );

  useEffect(() => {
    async function fetchData() {
      await readPriceFromApiForKare(selectedMirrorExcelIndex, mirrorWidth, mirrorHeight).then((response) => {
        dispatch(setBaseFee(response ? response : 0));
      });
    }

    fetchData();
  }, [selectedMirrorExcelIndex, mirrorWidth, mirrorHeight]);

  const classnameForPosition = desktopMatches1200 ? "right-[30vw]" : "right-2";

  const classNameForZIndex = !desktopMatches1200 && isMenuOpen ? "z-0" : "z-50";

  const isWunschdekor = [EnumMirrors.WUNSCHDEKOR].includes(selectedMirror);

  return (
    <>
      <div id="buttonsContainer5">
        <button
          className={` bg-white flex items-center justify-center w-10 h-10 rounded-full bg-cover overflow-hidden outline-none cursor-pointer border-2 border-[#edf0f0] control-btn absolute ${classnameForPosition} top-[63px] ${classNameForZIndex}`}
          data-counter="0"
          id="cmButton"
          onClick={() => {
            dispatch(changeHeightCmVisible(true));
            dispatch(changeWidthCmVisible(true));
          }}
        >
          <StraightenIcon />
        </button>
      </div>
      {/* <MenuToggleButton
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={() => dispatch(changeMenuIsOpen(!isMenuOpen))}
      /> */}

      <Stack
        className="mobileMenuHeightEdit"
        spacing={2}
        p={3}
        sx={{
          position: "fixed",
          top: "0",
          right: "0",
          height: "100vh",
          width: menuResponsiveWidth,
          backgroundColor: "#ffffff",
          overflowY: "auto",
          overflowX: "hidden",
          zIndex: "2",
          transition: "all 0.5s ease-in",
          transform: `translateX(${menuToggledPosition})`,
        }}
      >
        {!desktopMatches1200 && mobileMatches600 && (
          <Stack
            direction={"row"}
            spacing={0.5}
            sx={{
              position: "absolute",
              top: "4px",
              left: "4px",
              alignItems: "center",
              fontWeight: "700",
              fontSize: "16px",
              color: "#0d646f",
              border: "2px solid #0d646f",
              borderRadius: "8px",
              p: "4px 8px",
              zIndex: "9991",
            }}
            onClick={() => dispatch(changeMenuIsOpen(!isMenuOpen))}
          >
            <CloseIcon />
          </Stack>
        )}
        <div
          style={{
            position: "sticky",
            top: -30,
            backgroundColor: "#fff",
            width: "100%",
            height: 70,
            zIndex: 999999,
            ...(!desktopMatches1200 && { mt: "10px" }),
          }}
        >
          <Typography
            color="primary"
            sx={{
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: 70,
              backgroundColor: "#fff",
            }}
          >
            {!isPrivate ? (
              <div
                onClick={() => {
                  window.location.reload();
                }}
                style={{
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                <svg
                  style={{
                    transform: "rotate(180deg)",
                    marginRight: "0.5rem",
                    color: "lightblue",
                  }}
                  className="svg-icon"
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                >
                  <path d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z"></path>
                </svg>
                Zurück
              </div>
            ) : (
              <></>
            )}
            <div>
              <span
                style={{
                  color: "black",
                }}
              >
                {selectedMirrorName} - {total}€
              </span>
            </div>
          </Typography>

          <Divider />
        </div>

        <form id="cart_quantity_mirror" action={"https://dev.spiegel21.de/product_info.php?products_id=" + selectedMirrorExcelIndex + "&action=add_product"} method="post">
          <input type="hidden" name="products_id" value={selectedMirrorExcelIndex} />
          <AynaTipi />
          <Genislik />
          <Yukseklik />
          {isWunschdekor && <Rahmen />}
          <Aufhängebleche />
          {isWunschdekor && <MakyajAynası />}
          {isWunschdekor && makeupMirror === 1 && (
            <>
              <MakyajAynasıTipi />
              {makeupMirrorType == 1 && (
                <>
                  <MakyajAynasıPozisyon />
                  <MakyajAynaUzaklık />
                </>
              )}
            </>
          )}

          <Versiegelung />
          {!isWunschdekor && <Kanten />}
          <YorumYapın />
          <br />
          <AynaAdedi />
          <SummaryModal />
        </form>
        <center>
          <p className="text-primary relative top-5">Die Mehrwertsteuer wird an der Kasse berechnet.</p>
        </center>
        <SiparisVer />
        <br />
      </Stack>
    </>
  );
};

export default PaintedMirrorMenu;
