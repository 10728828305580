import React, { useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";

export function F621TL(props) {
  const { nodes, materials } = useGLTF("/newMirrorModels/F621TL.glb");
  const { materials: mirrorMaterials } = useGLTF("/rectangle-mirror-rev01.glb");
  const mirrorRef = useRef();
  const { mirrorHeight, mirrorWidth } = useSelector((state) => state.mirror);

  useEffect(() => {
    if (mirrorRef.current) {
      mirrorRef.current.scale.x = 1.4 + (mirrorWidth - 400) / 400;
      mirrorRef.current.scale.y = 0.65 + (mirrorHeight - 400) / 1350;
    }
  }, [mirrorWidth, mirrorHeight]);
  return (
    <group ref={mirrorRef} dispose={null} rotation-y={Math.PI * 1} position-z={0.1}>
      <mesh name="mirror" geometry={nodes.mirror.geometry} material={mirrorMaterials.mirror} />
      <mesh name="painted" geometry={nodes.painted.geometry} material={materials["Material.001"]} position={[0, 0, 0.011]} />
    </group>
  );
}

useGLTF.preload("/F621TL.glb");
