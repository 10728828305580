import React, { useEffect } from "react";
import { Stack, Typography, Divider, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { readPriceFromApiForKare } from "../../utils/readJson";
import { setBaseFee } from "../../store/cabinetMirrorFee";

import KapıKolu from "./menuItems/menuOptions/KapıKolu";
import MenteseYonu from "./menuItems/menuOptions/MenteseYonu";
import DolapTipi from "./menuItems/menuOptions/DolapTipi";
import LavaboTavan from "./menuItems/menuOptions/LavaboTavan";
import LedIsıgı from "./menuItems/menuOptions/LedIsıgı";
import ArkaPanel from "./menuItems/menuOptions/ArkaPanel";
import SensorAnahtar from "./menuItems/menuOptions/SensorAnahtar";
import Soketler from "./menuItems/menuOptions/Soketler";
import MakyajAynası from "./menuItems/menuOptions/MakyajAynası";
import Bluetooth from "./menuItems/menuOptions/Bluetooth";
import YorumYapın from "./menuItems/menuOptions/YorumYapın";
import SiparisVer from "./menuItems/menuOptions/SiparisVer";
import Genislik from "./menuItems/menuOptions/Genislik";
import Yukseklik from "./menuItems/menuOptions/Yukseklik";
import Derinlik from "./menuItems/menuOptions/Derinlik";
import GovdeDoku from "./menuItems/menuOptions/GovdeDoku";
import CamRaflar from "./menuItems/menuOptions/CamRaflar";
import AhsapZeminler from "./menuItems/menuOptions/AhsapZeminler";
import Kapılar from "./menuItems/menuOptions/Kapılar";
import CenteredDoors from "./menuItems/menuOptions/CenteredDoors";
import KapiGenisligi from "./menuItems/menuOptions/KapiGenisligi";
import AynaAdedi from "./menuItems/menuOptions/AynaAdeti";

import CamCikintiUst from "./menuItems/menuOptions/CamCikintiUst";
import CamCikintiAlt from "./menuItems/menuOptions/CamCikintiAlt";
// import MenteseYonuLasvegas from "./menuItems/menuOptions/MenteseYonuLasVegas";
import CmButton from "./menuItems/Buttons/CmButton";
import LightButton from "./menuItems/Buttons/LightButton";
import MenuToggleButton from "./menuItems/Buttons/MenuToggleButton";
import OpenAllDoors from "./menuItems/Buttons/OpenAllDoors";
import { EnumCabinetMirrors } from "../../store/cabinetMirror";
import CompartmentBottomCount from "./menuItems/menuOptions/CompartmentBottomCount";
import CompartmentBottom from "./menuItems/menuOptions/CompartmentBottom";
import CompartmentCenter from "./menuItems/menuOptions/CompartmentCenter";
import CompartmentLeft from "./menuItems/menuOptions/CompartmentLeft";
import CompartmentRight from "./menuItems/menuOptions/CompartmentRight";
import SoketlerDisarida from "./menuItems/menuOptions/SoketlerDisarida";
import LedIsigiPandora from "./menuItems/menuOptions/LedIsigiPandora";

import { changeMenuIsOpen } from "../../store/mirrors";
import LavaboTavanLedIsıgı from "./menuItems/menuOptions/LavaboTavanLedIsıgı";
import LavaboTavanLedSalter from "./menuItems/menuOptions/LavaboTavanLedSalter";
import MontajCercevesiDerinligi from "./menuItems/menuOptions/MontajCercevesiDerinligi";
import KurulumCercevesi from "./menuItems/menuOptions/kurulumCercevesi";

const AynaliDolapMenu = () => {
  /**
   * Ayna Stateler
   * Aynalar: selectedMirror / changeMirror 123
   *
   * Işık Rengi: lightColor / changeLightColor "white", "red", "yellow", "blue"
   *
   * Saat Konumu: watchPosition / changeWatchPosition discorddan attı
   *
   * Dijital Saat : digitalWatch / setDigitalWatch
   *
   */

  const {
    cabinetMirrorWidth,
    cabinetMirrorHeight,
    selectedMirrorExcelIndex,
    orderAmount,
    cabinetMirrorType,
    montajCevresiDerinligi,
    lavaboTavanLightColorIndex,
    lavaboTavanLightSalter,
    externalLeds,
  } = useSelector((state) => state.cabinetMirror);
  const {
    cabinetMirrorName,
    baseFee, //modelden gelen fiyatı
    ahsapZeminFiyatı,
    backPanelFiyatı,
    bluetoothSpeakerFiyatı,
    camRafFiyatı,
    cabinetMirrorTypeFiyatı, // dolap tipinden gelen fiyat bu, modelden değil
    doorHandleFiyatı,
    doorFiyatı,
    externalLedFiyatı,
    makeupMirrorFiyatı,
    sensorFiyatı,
    dekorFiyati,
    ledIsikFiyatı,
    pandoraLightFee,
    kurulumCercevesiFee,
    lavaboTavanLightSalterFiyatı,
    socketUsbFee,
    socketUsbDisaridaFee,
    derinlikFee,
  } = useSelector((state) => state.cabinetMirrorFee);
  const { isPrivate, isMenuOpen, selectedMirror } = useSelector((state) => state.mirror);

  const dispatch = useDispatch();
  const desktopMatches1700 = useMediaQuery("(min-width:1700px)");
  const desktopMatches1200 = useMediaQuery("(min-width:1200px)");
  const desktopMatches900 = useMediaQuery("(min-width:900px)");
  const mobileMatches600 = useMediaQuery("(min-width:600px)");
  const menuResponsiveWidth = desktopMatches1700 ? "29vw" : desktopMatches1200 ? "29vw" : desktopMatches900 ? "40vw" : mobileMatches600 ? "50vw" : "100vw";
  const menuTranslateXClosed = desktopMatches1200 ? "0" : desktopMatches900 ? "40vw" : mobileMatches600 ? "50vw" : "100vw";
  const menuToggledPosition = isMenuOpen ? "0" : menuTranslateXClosed;
  const oxford = EnumCabinetMirrors.OXFORD == selectedMirror;

  useEffect(() => {
    async function fetchData() {
      // await getProductWithMatrixByName(
      //   selectedMirrorExcelIndex,
      //   cabinetMirrorWidth,
      //   cabinetMirrorHeight
      // ).then((response) => {
      //   dispatch(setBaseFee(response ? response : 0));
      // });
      await readPriceFromApiForKare(selectedMirrorExcelIndex, cabinetMirrorWidth, cabinetMirrorHeight).then((response) => {
        dispatch(setBaseFee(response ? response : 0));
      });
    }
    // if (mirrorWidth % 50 == 0 || mirrorHeight % 50 == 0) {
    //   fetchData();
    // }
    fetchData();
  }, [selectedMirrorExcelIndex, cabinetMirrorWidth, cabinetMirrorHeight]);

  const dimensionFee = cabinetMirrorWidth > 1200 && cabinetMirrorHeight > 800 ? 49.99 : 0;

  const total = parseFloat(
    (baseFee +
      ahsapZeminFiyatı +
      dekorFiyati +
      ledIsikFiyatı +
      backPanelFiyatı +
      bluetoothSpeakerFiyatı +
      camRafFiyatı +
      cabinetMirrorTypeFiyatı +
      doorHandleFiyatı +
      doorFiyatı +
      externalLedFiyatı +
      makeupMirrorFiyatı +
      kurulumCercevesiFee +
      sensorFiyatı +
      dimensionFee +
      pandoraLightFee +
      socketUsbFee +
      lavaboTavanLightSalterFiyatı +
      socketUsbDisaridaFee +
      derinlikFee) *
      orderAmount
  ).toFixed(2);

  const fixedDoors =
    selectedMirror == EnumCabinetMirrors.MUNCHEN_4 || selectedMirror == EnumCabinetMirrors.MUNCHEN_5 || selectedMirror == EnumCabinetMirrors.LAS_VEGAS || selectedMirror == EnumCabinetMirrors.SUPREME;

  const hasLedOption = ![EnumCabinetMirrors.OXFORD, EnumCabinetMirrors.GENT, EnumCabinetMirrors.DERBY, EnumCabinetMirrors.WESER, EnumCabinetMirrors.WINDSOR].includes(selectedMirror);
  const hasCamCikinti = ![EnumCabinetMirrors.FRANKFURT, EnumCabinetMirrors.HAMBURG].includes(selectedMirror);
  const isPandorish = [
    EnumCabinetMirrors.PANDORA_1,
    EnumCabinetMirrors.PANDORA_2,
    EnumCabinetMirrors.PANDORA_3,
    EnumCabinetMirrors.PANDORA_4,

    EnumCabinetMirrors.VERONICA_1,
    EnumCabinetMirrors.VERONICA_2,
    EnumCabinetMirrors.VERONICA_3,
    EnumCabinetMirrors.VERONICA_4,

    EnumCabinetMirrors.KATHERINE_1,
    EnumCabinetMirrors.KATHERINE_2,
    EnumCabinetMirrors.KATHERINE_3,
    EnumCabinetMirrors.KATHERINE_4,
  ].includes(selectedMirror);

  const isAluminium = [
    EnumCabinetMirrors.HAVEL,
    EnumCabinetMirrors.LECH,
    EnumCabinetMirrors.WESER,
    EnumCabinetMirrors.ELBE_1,
    EnumCabinetMirrors.NECKAR,
    EnumCabinetMirrors.LIPPE,
    EnumCabinetMirrors.WERRA,
  ].includes(selectedMirror);

  const isSidedCabinet = [
    EnumCabinetMirrors.MUNCHEN_4,
    EnumCabinetMirrors.MUNCHEN_6,
    EnumCabinetMirrors.MALAGA,
    EnumCabinetMirrors.SARAGOSSA,
    EnumCabinetMirrors.KONSTANZ,
    EnumCabinetMirrors.DERBY,
    EnumCabinetMirrors.GENT,
    EnumCabinetMirrors.VENEZIA,
    EnumCabinetMirrors.ALMERIA,
    EnumCabinetMirrors.GRADO,
    EnumCabinetMirrors.VILLACH,
    EnumCabinetMirrors.VERONICA_3,
    EnumCabinetMirrors.VERONICA_4,
    EnumCabinetMirrors.PANDORA_3,
    EnumCabinetMirrors.PANDORA_4,
    EnumCabinetMirrors.KATHERINE_3,
    EnumCabinetMirrors.KATHERINE_4,
  ].includes(selectedMirror);

  const isCenteredCabinet = [EnumCabinetMirrors.MUNCHEN_5, EnumCabinetMirrors.LAS_VEGAS].includes(selectedMirror);

  const isWithBottom = [
    EnumCabinetMirrors.BUDAPEST,
    EnumCabinetMirrors.MUNCHEN_6,
    EnumCabinetMirrors.SANTANDER,
    EnumCabinetMirrors.GENT,
    EnumCabinetMirrors.VENEZIA,
    EnumCabinetMirrors.LISSABON,
    EnumCabinetMirrors.LINZ,
    EnumCabinetMirrors.CORTINA,
    EnumCabinetMirrors.WINDSOR,
    EnumCabinetMirrors.VERONICA_2,
    EnumCabinetMirrors.VERONICA_4,
    EnumCabinetMirrors.PANDORA_2,
    EnumCabinetMirrors.PANDORA_4,
    EnumCabinetMirrors.KATHERINE_2,
    EnumCabinetMirrors.KATHERINE_4,
  ].includes(selectedMirror);

  return (
    <>
      <div id="buttonsContainer3">
        <OpenAllDoors isMenuOpen={false} />
        <CmButton isMenuOpen={false} />
        <LightButton isMenuOpen={false} />
      </div>

      {mobileMatches600 ? (
        <MenuToggleButton
          isMenuOpen={false}
          setIsMenuOpen={() => {
            dispatch(changeMenuIsOpen(!isMenuOpen));
          }}
        />
      ) : null}

      <form id="cart_quantity_cabinet" action={"https://dev.spiegel21.de/product_info.php?products_id=" + selectedMirrorExcelIndex + "&action=add_product"} method="post">
        <input type="hidden" name="products_id" value={selectedMirrorExcelIndex} />
        <Stack
          className="mobileMenuHeightEdit"
          spacing={2}
          p={3}
          sx={{
            position: "absolute",
            top: "0",
            right: "0",
            height: "100vh",
            width: menuResponsiveWidth,
            maxWidth: menuResponsiveWidth,
            backgroundColor: "#ffffff",
            overflowY: "auto",
            overflowX: "hidden",
            zIndex: "2",
            transition: "all 0.5s ease-in",
            transform: `translateX(${menuToggledPosition})`,
          }}
        >
          <Stack>
            {/* {!desktopMatches1200 && (
              <Stack
                direction={"row"}
                spacing={0.5}
                sx={{
                  position: "absolute",
                  top: "4px",
                  left: "4px",
                  alignItems: "center",
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#0d646f",
                  border: "2px solid #0d646f",
                  borderRadius: "8px",
                  p: "4px 8px",
                }}
                onClick={() => {
                  dispatch(changeMenuIsOpen(!isMenuOpen));
                }}
              >
                <CloseIcon />
              </Stack>
            )} */}
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                position: "relative",
                ...(!desktopMatches1200 && { mt: "10px" }),
              }}
            >
              {isPrivate ? (
                <></>
              ) : (
                <Typography
                  color="primary"
                  onClick={() => {
                    window.location.reload();
                  }}
                  sx={{
                    cursor: "pointer",
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <svg
                    id="icon_ent"
                    style={{
                      transform: "rotate(180deg)",
                      marginRight: "0.5rem",
                      color: "lightblue",
                    }}
                    className="svg-icon"
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                  >
                    <path d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z"></path>
                  </svg>
                  Zurück
                </Typography>
              )}
              <Typography sx={{ fontWeight: "700" }}>
                {cabinetMirrorName}
                {""} - {""}
                {total}€
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <DolapTipi />
          {cabinetMirrorType == -0.08 ? (
            <>
              <MontajCercevesiDerinligi />
              <KurulumCercevesi />
            </>
          ) : null}
          {cabinetMirrorType == 0.2 ? <KurulumCercevesi /> : null}
          <Genislik />
          <Yukseklik />
          <Derinlik />
          {isWithBottom && (
            <>
              <CompartmentBottomCount />
              <CompartmentBottom />
            </>
          )}
          {isCenteredCabinet && <CompartmentCenter />}
          {isSidedCabinet && (
            <>
              <CompartmentLeft />
              <CompartmentRight />
            </>
          )}
          {hasLedOption && !isPandorish && <LedIsıgı />}
          {isPandorish && <LedIsigiPandora />}
          <GovdeDoku />
          {!isAluminium && <ArkaPanel />}
          {isCenteredCabinet ? <CenteredDoors /> : <Kapılar />}
          {/* {isCenteredCabinet ? <MenteseYonuLasvegas /> : <MenteseYonu />} */}
          <MenteseYonu />
          <KapiGenisligi />
          <KapıKolu />
          {/* <CamCikinti /> */}
          {hasCamCikinti && (
            <>
              <CamCikintiAlt />
              <CamCikintiUst />
            </>
          )}
          <CamRaflar />
          <AhsapZeminler />
          {!oxford && <SensorAnahtar />}
          {/* <SensorAnahtar /> */}
          <Soketler />
          {isSidedCabinet && <SoketlerDisarida />}
          <LavaboTavan />
          {externalLeds !== 0 && <LavaboTavanLedIsıgı />}
          {externalLeds !== 0 && lavaboTavanLightColorIndex !== 0 && <LavaboTavanLedSalter />}
          <MakyajAynası />
          <Bluetooth />
          {/* <Led /> */}

          {/* <SensorAnahtar /> */}
          <YorumYapın />
          <br />
          <AynaAdedi />
          <center>
            <p className="relative text-primary top-5">Die Mehrwertsteuer wird an der Kasse berechnet.</p>
          </center>
          <SiparisVer />
          <br />
        </Stack>
      </form>
    </>
  );
};

export default AynaliDolapMenu;
