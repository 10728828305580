import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Man2 } from "../OrtakComponents/Man2";
import { Koseli } from "./Mirrors/Koseli";
import { MakyajAynasi } from "./Mirrors/MakyajAynasi";

const KoseliAyna = () => {
  const { mirrorWidth, mirrorHeight, makeupMirrorPos } = useSelector((state) => state.mirror);
  const manHeight = useSelector((state) => state.utils.selectedHeightLevel);
  const [koseliAynaPos, setKoseliAynaPos] = useState([0.7, 0.08]);

  useEffect(() => {
    if (makeupMirrorPos == 0) {
      setKoseliAynaPos([1.9, 0.1]);
    } else if (makeupMirrorPos == 1) {
      setKoseliAynaPos([3.1, 0.1]);
    } else if (makeupMirrorPos == 2) {
      setKoseliAynaPos([1.9, 0.1]);
    } else if (makeupMirrorPos == 3) {
      setKoseliAynaPos([0.8, 0.1]);
    } else if (makeupMirrorPos == 4) {
      setKoseliAynaPos([0.8, 0.8]);
    } else if (makeupMirrorPos == 5) {
      setKoseliAynaPos([0.8, 1.5]);
    } else if (makeupMirrorPos == 6) {
      setKoseliAynaPos([1.9, 1.5]);
    } else if (makeupMirrorPos == 7) {
      setKoseliAynaPos([3.1, 1.6]);
    } else if (makeupMirrorPos == 8) {
      setKoseliAynaPos([3.1, 0.8]);
    }
  }, [makeupMirrorPos]);

  return (
    <>
      <Koseli />
      <MakyajAynasi position={[6.1, koseliAynaPos[0] - mirrorHeight * 0.00014, koseliAynaPos[1] - mirrorWidth * 0.00045]} />
      <Man2 position={[(mirrorWidth > 2000 ? -4.2 : mirrorWidth > 1600 ? -3.65 : -3.12) - mirrorWidth * 0.0008, -3.5, -0.2]} scale={25 + manHeight} manHeight={manHeight} />
    </>
  );
};

export default KoseliAyna;
