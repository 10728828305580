import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setAhsapZemin, setShelfCount } from "../../../../store/cabinetMirror";
import { setAhsapZeminFiyatı, setCamRafFiyatı } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const AhsapZeminler = () => {
  const dispatch = useDispatch();
  const { ahsapZemin } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);

  const handleChange = (e) => {
    dispatch(setAhsapZemin(e.target.value));
    dispatch(
      setAhsapZeminFiyatı(e.target.value === 1 ? 35 : e.target.value === 2 ? 50 : e.target.value === 3 ? 60 : e.target.value === 4 ? 70 : e.target.value === 5 ? 80 : e.target.value === 6 ? 90 : 0)
    );
    dispatch(setShelfCount(0));
    dispatch(setCamRafFiyatı(0));
  };

  useEffect(() => {
    dispatch(setAhsapZemin(0));
    dispatch(setAhsapZeminFiyatı(0));
    dispatch(setShelfCount(0));
    dispatch(setCamRafFiyatı(0));
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="23">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Holzböden (Korpus-Dekor)
        </Typography>
      </RenderModal>

      <input
        name="id[211]"
        value={
          ahsapZemin == 0 ? 2178 : ahsapZemin == 1 ? 10063 : ahsapZemin == 2 ? 10064 : ahsapZemin == 3 ? 10065 : ahsapZemin == 4 ? 10066 : ahsapZemin == 5 ? 10067 : ahsapZemin == 6 ? 10068 : null
        }
      />

      <Select onChange={handleChange} value={ahsapZemin} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>Ohne Hölzböden</MenuItem>
        <MenuItem value={1}>1x Holzboden + 35,00 €</MenuItem>
        <MenuItem value={2}>2x Holzboden + 50,00 €</MenuItem>
        <MenuItem value={3}>3x Holzboden + 60,00 €</MenuItem>
        <MenuItem value={4}>4x Holzboden + 70,00 €</MenuItem>
        <MenuItem value={5}>5x Holzboden + 80,00 €</MenuItem>
        <MenuItem value={6}>6x Holzboden + 90,00 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default AhsapZeminler;
