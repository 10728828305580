import React, { useRef, useEffect, useState } from "react";
import { useGLTF, Html } from "@react-three/drei";

import { useSelector } from "react-redux";
import { useDrag } from "@use-gesture/react";

import { extend, useThree } from "@react-three/fiber";
import { Effects } from "@react-three/drei";
import { UnrealBloomPass } from "three-stdlib";

extend({ UnrealBloomPass });

export function Pandora(props) {
  const lightColor = useSelector((state) => state.mirror.lightColor);
  const { pandoraLightScaleX, cabinetMirrorWidth } = useSelector((state) => state.cabinetMirror);
  const { nodes, materials } = useGLTF("/Pandora.glb");
  const scaleX = props.isTestCabinet
    ? pandoraLightScaleX * 1.8 - 0.2
    : !props.isTestCabinet && cabinetMirrorWidth > 650
    ? pandoraLightScaleX * 1.8 - pandoraLightScaleX * (650 / cabinetMirrorWidth) * 1.8
    : pandoraLightScaleX * 1.8;
  return (
    <>
      <group {...props} dispose={null} scale-x={scaleX}>
        <mesh geometry={nodes.ledlight.geometry} material={materials.ledlightTexture} position={[-0.02, 0.743, -0.005]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.77}>
          <meshBasicMaterial color={lightColor} toneMapped={false} />
        </mesh>
        <mesh geometry={nodes["ledlight-case"].geometry} material={materials.alumtexture} position={[-0.02, 0.75, -0.03]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.77}>
          {/* <meshBasicMaterial color={lightColor} toneMapped={false} /> */}
        </mesh>
      </group>
    </>
  );
}

useGLTF.preload("/Pandora.glb");
