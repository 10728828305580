import React from "react";
import { Stack, Typography, Link } from "@mui/material";

const Zubehör = () => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Zubehör optional
      </Typography>

      <Typography variant="caption">
        Glas-/Spiegel-<Link>Klebeband</Link> und <Link>Kleber</Link>
      </Typography>
    </Stack>
  );
};

export default Zubehör;
