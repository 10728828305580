import React from "react";
import { useSelector } from "react-redux";
import { useGLTF } from "@react-three/drei";

export const DoorHandleLeft = () => {
  const canto = useGLTF("/Kulplar/Canto.glb");
  const fina = useGLTF("/Kulplar/Fina.glb");
  const knopf = useGLTF("/Kulplar/Knopf.glb");

  const mango = useGLTF("/Kulplar/Mango.glb");
  const moda = useGLTF("/Kulplar/Moda.glb");
  const presa = useGLTF("/Kulplar/Presa.glb");

  const { doorHandle } = useSelector((state) => state.cabinetMirror);
  return (
    <>
      {doorHandle === 1 ? (
        <group rotation={[0, Math.PI * 0.5, Math.PI]} scale={0.002} position={[0.43, -0.35, 0.03]}>
          <mesh geometry={canto.nodes.Canto.geometry} material={canto.materials["Material #2143627554"]} rotation={[0, 1.57, 0]} />
        </group>
      ) : doorHandle === 2 ? (
        <group rotation={[0, Math.PI * 0.5, 0]} scale={0.07} position={[0.45, -0.35, 0.058]}>
          <mesh geometry={knopf.nodes.Griff_Alu.geometry} material={knopf.materials.wire_214228153} />
        </group>
      ) : doorHandle === 3 ? (
        <group rotation-x={Math.PI * 0.5} scale={0.04} position={[0.45, -0.35, 0.001]}>
          <mesh geometry={fina.nodes.Mesh001.geometry} material={fina.materials["15 - Default"]} />
          <mesh geometry={fina.nodes.Mesh001_1.geometry} material={fina.materials["15 - Defaultff"]} />
        </group>
      ) : doorHandle === 4 ? (
        <mesh scale={0.01} rotation-z={Math.PI} position={[0.43, -0.35, 0.01]} geometry={presa.nodes.Presa.geometry} material={presa.materials["21___Default.001"]} />
      ) : doorHandle === 5 ? (
        <mesh geometry={mango.nodes.Box001.geometry} material={mango.nodes.Box001.material} scale={0.02} position={[0.46, -0.35, 0.02]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
      ) : doorHandle === 6 ? (
        <group scale={0.01} position={[0.43, -0.35, 0.005]}>
          <mesh geometry={moda.nodes.Moda.geometry} material={moda.materials["21___Default"]} />
        </group>
      ) : (
        <></>
      )}
    </>
  );
};
