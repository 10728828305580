import OxfordMirror from "./OxfordMirror";
import WoodenDoor from "./WoodenDoor";

import { useSelector } from "react-redux";
import { EnumCabinets } from "../../../store/cabinet";

const CabinetLeftDoor = ({ type }) => {
  const { selectedMirror } = useSelector((state) => state.mirror);

  const isNotMirror = [EnumCabinets.BHS002A, EnumCabinets.BHS003, EnumCabinets.BHS005, EnumCabinets.BHS002B].includes(selectedMirror);

  return !isNotMirror ? <OxfordMirror /> : <WoodenDoor />;
};

export default CabinetLeftDoor;
