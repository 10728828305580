import { Stack, Slider, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EnumCabinetMirrors, changeCabinetMirrorDepth } from "../../../../store/cabinetMirror";
import { useEffect, useState } from "react";
import { setDerinlikFiyatı } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const Derinlik = () => {
  const dispatch = useDispatch();
  const { cabinetMirrorDepth } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const [sliderValue, setSliderValue] = useState(125);

  const handleSliderChange = (e) => {
    setSliderValue(e.target.value);
  };
  const handleBlur = () => {
    dispatch(changeCabinetMirrorDepth(sliderValue));
    const diffPrice = ((Number(sliderValue) - 125) / 5) * 2;
    dispatch(setDerinlikFiyatı(diffPrice));
  };

  const is140 = selectedMirror === EnumCabinetMirrors.FRANKFURT;
  const is169 = selectedMirror === EnumCabinetMirrors.ELBE_1; // elbe aluminum aynı zamanda
  const isAluminium = [EnumCabinetMirrors.HAVEL, EnumCabinetMirrors.LECH, EnumCabinetMirrors.WESER, EnumCabinetMirrors.NECKAR, EnumCabinetMirrors.LIPPE, EnumCabinetMirrors.WERRA].includes(
    selectedMirror
  );
  const minValue = is169 ? 169 : isAluminium ? 153 : is140 ? 140 : 125;

  useEffect(() => {
    dispatch(changeCabinetMirrorDepth(minValue));
    setSliderValue(minValue);
    dispatch(setDerinlikFiyatı(0));
  }, [selectedMirror]);

  const isAluminum = [
    EnumCabinetMirrors.HAVEL,
    EnumCabinetMirrors.LECH,
    EnumCabinetMirrors.WESER,
    EnumCabinetMirrors.ELBE_1,
    EnumCabinetMirrors.NECKAR,
    EnumCabinetMirrors.LIPPE,
    EnumCabinetMirrors.WERRA,
  ].includes(selectedMirror);

  const renderInputValue = () => {
    if (isAluminium) {
      return 10070;
    } else {
      return (sliderValue - 125) / 5 + 151;
    }
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="15">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Tiefe
        </Typography>
      </RenderModal>

      <input name="id[17]" value={renderInputValue()} />

      {isAluminium ? (
        <Typography variant="caption" sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          153mm
        </Typography>
      ) : (
        <>
          <Slider
            sx={{
              ml: 3,
              ".MuiSlider-thumb": {
                minWidth: "50px",
                borderRadius: "10px",
              },
            }}
            onChange={handleSliderChange}
            onMouseUp={handleBlur}
            onChangeCommitted={handleBlur}
            size="medium"
            defaultValue={cabinetMirrorDepth}
            step={5}
            min={minValue}
            max={400}
            valueLabelDisplay="auto"
            value={sliderValue}
          />
        </>
      )}
    </Stack>
  );
};

export default Derinlik;
