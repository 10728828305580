export const wunschdekorOptions = [
  {
    value: "1918",
    title: "H3840 ST9 Ahorn",
    price: "0",
  },
  {
    value: "1919",
    title: "H1137 ST12 Eiche Schwarzbraun",
    price: "0",
  },
  {
    value: "1920",
    title: "H1400 ST36 Wenge Hell", // wenge hell
    price: "0",
  },
  {
    value: "1921",
    title: "H3325 ST28 Wenge Dunkel", // wenge dunkel
    price: "0",
  },
  {
    value: "1922",
    title: "H3450 ST22 Weiß Gekalkt",
    price: "0",
  },
  {
    value: "1923",
    title: "U708 ST9 Hellgrau",
    price: "0",
  },
  {
    value: "1924",
    title: "F187 ST9 Dunkelgrau",
    price: "0",
  },
  {
    value: "1925",
    title: "H1313 ST10 Anthrazit", // Anthrazit
    price: "0",
  },

  {
    value: "1926",
    title: "H3450 ST22 Silber Glanzend",
    price: "0",
  },
  {
    value: "1927",
    title: "H3450 ST22 Silber Matt",
    price: "0",
  },
  {
    value: "1928",
    title: "U201 ST9 Kieselgrau Matt",
    price: "0",
  },
  {
    value: "1929",
    title: "U113 ST9 Olivgrau Matt",
    price: "0",
  },
  {
    value: "1930",
    title: "F463 ST20 Platin Matt",
    price: "0",
  },
  {
    value: "1931",
    title: "U999 ST2 Schwarz Matt",
    price: "0",
  },
];
