import React, { useEffect, useState } from "react";
import { Stack, Slider, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeMirrorWidth, resetToInitialState, EnumMirrors, setMakeupMirror } from "../../../store/mirrors";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";
import { min } from "lodash";

const Genislik = () => {
  const { selectedMirror, mirrorWidth, kareAynaModel, mirrorHeight, selectedMirrorExcelIndex } = useSelector((state) => state.mirror);
  const dispatch = useDispatch();
  const isDaire = selectedMirror == EnumMirrors.CHARON || selectedMirror == EnumMirrors.DIANA || selectedMirror == EnumMirrors.HELENA;
  const isF597L4K = selectedMirror == EnumMirrors.F597L4K;
  const isF610L4K = selectedMirror == EnumMirrors.F610L4K;
  const isKristall = [EnumMirrors.STARK_RAHMENLOS_5MM, EnumMirrors.SILBER_RAHMENLOS_3MM, EnumMirrors.RAHMENLOSER_6_MM].includes(selectedMirror);
  const [sliderValue, setSliderValue] = useState(mirrorWidth);

  useEffect(() => {
    if (isKristall) {
      dispatch(setMakeupMirror(0));
      setInput(200);
      dispatch(changeMirrorWidth(200));
    } else if (isF597L4K) {
      setInput(500);
      dispatch(changeMirrorWidth(500));
    } else {
      setInput(400);
      dispatch(changeMirrorWidth(400));
    }
  }, [selectedMirror, selectedMirrorExcelIndex]);

  useEffect(() => {
    if (isF597L4K && mirrorHeight >= mirrorWidth - 100) {
      // dispatch(changeMirrorWidth(mirrorHeight + 100));
      setInput(mirrorHeight + 100);
    }
  }, [mirrorHeight, mirrorWidth]);

  const handleChange = (e) => {
    setSliderValue(e.target.value);
    setInput(e.target.value);
  };

  const daireAynaData = [
    {
      data: "415",
      value: 400,
    },
    {
      data: "416",
      value: 450,
    },
    {
      data: "417",
      value: 500,
    },
    {
      data: "418",
      value: 550,
    },
    {
      data: "419",
      value: 600,
    },
    {
      data: "420",
      value: 650,
    },
    {
      data: "421",
      value: 700,
    },
    {
      data: "422",
      value: 750,
    },
    {
      data: "423",
      value: 800,
    },
    {
      data: "424",
      value: 850,
    },
    {
      data: "425",
      value: 900,
    },
    {
      data: "426",
      value: 950,
    },
    {
      data: "427",
      value: 1000,
    },
    {
      data: "781",
      value: 1050,
    },
    {
      data: "782",
      value: 1100,
    },
    {
      data: "783",
      value: 1150,
    },
    {
      data: "784",
      value: 1200,
    },
    {
      data: "2096",
      value: 1250,
    },
    {
      data: "2097",
      value: 1300,
    },
    {
      data: "2101",
      value: 1400,
    },
    {
      data: "2103",
      value: 1500,
    },
    {
      data: "9554",
      value: 1600,
    },
    {
      data: "9556",
      value: 1700,
    },
    {
      data: "9558",
      value: 1800,
    },
    {
      data: "9560",
      value: 1900,
    },
    {
      data: "9562",
      value: 2000,
    },
  ];

  const handleCommit = () => {
    dispatch(changeMirrorWidth(isKristall ? 200 : sliderValue));
  };

  const MAX_VALUE = isF610L4K ? 1200 : 2500;
  const MIN_VALUE = isKristall ? 200 : isF597L4K ? 500 : 400;
  const DEFAULT_VALUE = sliderValue;
  const [input, setInput] = useState(DEFAULT_VALUE);

  const [alertMessage, setAlertMessage] = useState(null);

  const kareGenislik = [
    {
      value: "1803",
      inputValue: 200,
    },
    {
      value: "1804",
      inputValue: 250,
    },
    {
      value: "1805",
      inputValue: 300,
    },
    {
      value: "1806",
      inputValue: 350,
    },
    {
      value: "20",
      inputValue: 400,
    },
    {
      value: "21",
      inputValue: 450,
    },
    {
      value: "22",
      inputValue: 500,
    },
    {
      value: "23",
      inputValue: 550,
    },
    {
      value: "24",
      inputValue: 600,
    },
    {
      value: "25",
      inputValue: 650,
    },
    {
      value: "26",
      inputValue: 700,
    },
    {
      value: "27",
      inputValue: 750,
    },
    {
      value: "28",
      inputValue: 800,
    },
    {
      value: "29",
      inputValue: 850,
    },
    {
      value: "30",
      inputValue: 900,
    },
    {
      value: "31",
      inputValue: 950,
    },
    {
      value: "32",
      inputValue: 1000,
    },
    {
      value: "33",
      inputValue: 1050,
    },
    {
      value: "34",
      inputValue: 1100,
    },
    {
      value: "35",
      inputValue: 1150,
    },
    {
      value: "36",
      inputValue: 1200,
    },
    {
      value: "37",
      inputValue: 1250,
    },
    {
      value: "38",
      inputValue: 1300,
    },
    {
      value: "39",
      inputValue: 1350,
    },
    {
      value: "40",
      inputValue: 1400,
    },
    {
      value: "41",
      inputValue: 1450,
    },
    {
      value: "42",
      inputValue: 1500,
    },
    {
      value: "43",
      inputValue: 1550,
    },
    {
      value: "44",
      inputValue: 1600,
    },
    {
      value: "45",
      inputValue: 1650,
    },
    {
      value: "46",
      inputValue: 1700,
    },
    {
      value: "47",
      inputValue: 1750,
    },
    {
      value: "48",
      inputValue: 1800,
    },
    {
      value: "207",
      inputValue: 1850,
    },
    {
      value: "208",
      inputValue: 1900,
    },
    {
      value: "209",
      inputValue: 1950,
    },
    {
      value: "210",
      inputValue: 2000,
    },
    {
      value: "211",
      inputValue: 2050,
    },
    {
      value: "212",
      inputValue: 2100,
    },
    {
      value: "213",
      inputValue: 2150,
    },
    {
      value: "214",
      inputValue: 2200,
    },
    {
      value: "215",
      inputValue: 2250,
    },
    {
      value: "216",
      inputValue: 2300,
    },
    {
      value: "217",
      inputValue: 2350,
    },
    {
      value: "218",
      inputValue: 2400,
    },
    {
      value: "219",
      inputValue: 2450,
    },
    {
      value: "220",
      inputValue: 2500,
    },
  ];

  const ovalGenislik = [
    { value: "1818", inputValue: 400 },
    { value: "1819", inputValue: 450 },
    { value: "1820", inputValue: 500 },
    { value: "1821", inputValue: 550 },
    { value: "1822", inputValue: 600 },
    { value: "1823", inputValue: 650 },
    { value: "1824", inputValue: 700 },
    { value: "1825", inputValue: 750 },
    { value: "1826", inputValue: 800 },
    { value: "1827", inputValue: 850 },
    { value: "1828", inputValue: 900 },
    { value: "1829", inputValue: 950 },
    { value: "1830", inputValue: 1000 },
    { value: "1831", inputValue: 1050 },
    { value: "1832", inputValue: 1100 },
    { value: "1833", inputValue: 1150 },
    { value: "1834", inputValue: 1200 },
    { value: "1835", inputValue: 1250 },
    { value: "1836", inputValue: 1300 },
    { value: "1837", inputValue: 1350 },
    { value: "1838", inputValue: 1400 },
    { value: "1839", inputValue: 1450 },
    { value: "1840", inputValue: 1500 },
    { value: "1841", inputValue: 1550 },
    { value: "1842", inputValue: 1600 },
    { value: "1843", inputValue: 1650 },
    { value: "1844", inputValue: 1700 },
    { value: "1845", inputValue: 1750 },
    { value: "1846", inputValue: 1800 },
    { value: "1847", inputValue: 1850 },
    { value: "1848", inputValue: 1900 },
    { value: "1849", inputValue: 1950 },
    { value: "1850", inputValue: 2000 },
    { value: "1851", inputValue: 2050 },
    { value: "1852", inputValue: 2100 },
    { value: "1853", inputValue: 2150 },
    { value: "1854", inputValue: 2200 },
    { value: "1855", inputValue: 2250 },
    { value: "1856", inputValue: 2300 },
    { value: "1857", inputValue: 2350 },
    { value: "1858", inputValue: 2400 },
    { value: "1859", inputValue: 2450 },
    { value: "1860", inputValue: 2500 },
  ];

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        {isDaire ? (
          <>
            <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
              Durchmesser (mm)
            </Typography>
            <input name="id[34]" value={daireAynaData.find((x) => x.value == sliderValue)?.data} />
            <Slider
              sx={{
                ml: 3,
                ".MuiSlider-thumb": {
                  minWidth: "50px",
                  borderRadius: "10px",
                },
              }}
              onChange={handleChange}
              onChangeCommitted={handleCommit}
              size="medium"
              value={sliderValue}
              min={400}
              max={2000}
              step={sliderValue >= 1300 ? 100 : 50}
              valueLabelDisplay="auto"
            />
          </>
        ) : (
          <>
            <RenderModal title="Informationen zur Wunschbreite, Wunschhöhe">
              <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
                Breite in mm
              </Typography>
            </RenderModal>

            <TextField
              value={input}
              type="number"
              onFocus={(e) => {
                if (e.target.value.length == 0) {
                  setInput(sliderValue);
                  e.target.style.color = "#000";
                  setAlertMessage(false);
                }
              }}
              onChange={(e) => {
                var value = parseInt(e.target.value);
                setInput(value);
                if (!value) {
                  dispatch(changeMirrorWidth(MIN_VALUE));
                } else {
                  if (value > MAX_VALUE || value < MIN_VALUE) {
                    dispatch(changeMirrorWidth(value > MAX_VALUE ? MAX_VALUE : MIN_VALUE));
                    e.target.style.color = "tomato";
                    setAlertMessage(true);
                  } else {
                    dispatch(changeMirrorWidth(value));
                    e.target.style.color = "#000";
                    setAlertMessage(false);
                  }
                }
              }}
              sx={{
                height: "2.2rem",
                width: "100%",
                ".MuiInputBase-input": {
                  padding: "8px 8px 8px 18px",
                  fontSize: 12,
                },
              }}
            />

            {isF597L4K || isF610L4K ? (
              <input type="hidden" name="id[164]" value={ovalGenislik.find((k) => parseInt(k.inputValue) === input)?.value} />
            ) : (
              <>
                <input type="hidden" name="id[7]" value={kareGenislik.find((k) => parseInt(k.inputValue) === input)?.value} />

                <input type="hidden" name="id[61]" value="526" />
                <input type="hidden" class="form-control" id="txt61" name="txt_526" value={sliderValue} />
              </>
            )}
          </>
        )}
      </Stack>
      {alertMessage ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            position: "relative",
            top: -10,
          }}
        >
          <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem", mt: 0 }}></Typography>
          <small
            style={{
              color: "red",
            }}
          >
            Bitte geben Sie den Wert für die Breite (nur Ziffern) in Millimetern ein. Dieser muss zwischen {MIN_VALUE} und {MAX_VALUE} (mm) betragen
          </small>
        </Stack>
      ) : null}
    </>
  );
};

export default Genislik;
