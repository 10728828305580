import React from "react";
import { Stack, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSummaryModalOpen } from "../../../store/cabinet";
import $ from "jquery";

const SiparisVer = () => {
  const dispatch = useDispatch();

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-evenly">
      <Button onClick={() => $("#cart_quantity_cabinet").submit()} sx={{ width: "100%", mt: 5, fontWeight: "700", letterSpacing: "0.15rem" }} variant="contained">
        In den Warenkorb
      </Button>
    </Stack>
  );
};

export default SiparisVer;
