import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

const Touch = (props) => {
  const { nodes, materials } = useGLTF("/daire.glb");
  return (
    <mesh castShadow receiveShadow geometry={nodes["SENSÖR-kare001"].geometry} material={materials.touchdimmertexture} rotation={[Math.PI / 2, 0, 0]} {...props}>
      <meshBasicMaterial color={[1, 1, 1]} toneMapped={true} />
    </mesh>
  );
};

export default Touch;
