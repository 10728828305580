import { useRef, useState, useEffect } from "react";

import * as THREE from "three";
import gsap from "gsap";
import { useGLTF, useTexture } from "@react-three/drei";

import { useFrame } from "@react-three/fiber";
import { useSelector } from "react-redux";

import Cm from "./Cm";
import TestMenuMesh from "./TestMenuMesh";
import DoorHandleLeft from "./DoorHandleLeft";
import DoorHandleRight from "./DoorHandleRight";
import CabinetLeftDoor from "../cabinetLeftDoors/CabinetLeftDoor";
import CabinetRightDoor from "../cabinetRightDoors/CabinetRightDoor";
import CabinetShelves from "../cabinetBodyParts/CabinetShelves";
import LeftHinge from "../cabinetBodyParts/LeftHinge";
import RightHinge from "../cabinetBodyParts/RightHinge";
import { EnumCabinets } from "../../../store/cabinet";
import CentimeterDepthLines from "../../OrtakComponents/CM/CentimeterDepthLines";
import CentimeterHeightLines from "../../OrtakComponents/CM/CentimeterHeightLines";
import CentimeterWidthLines from "../../OrtakComponents/CM/CentimeterWidthLines";

export default function TwoDoorCenterlessCabinet(props) {
  //MODELS

  //**AYNALI DOLAPLAR */
  const { nodes, materials } = useGLTF("/Frankfurt-test2.glb");
  const { nodes: budapestNodes, materials: budapestMaterials } = useGLTF("/newModels/budapest.glb");

  // STATES
  const {
    lightColor,
    cabinetWidth,
    cabinetHeight,
    cabinetDepth,
    cabinetType,
    backPanel,
    bodyDecor,
    socketUsb,
    camCikintiUst,
    camCikintiAlt,
    sensor,
    doorOpeningSide,
    isFirstLeftSided,
    isSecondLeftSided,
    openAllDoors,
    widthCmVisible,
    heightCmVisible,
    depthCmVisible,
  } = useSelector((state) => state.cabinet);

  //------REFS------\\

  const frankfurt = useRef();
  const cabinetBody = useRef();
  const bodyLed = useRef();
  const soketRef = useRef();

  //----------First Cabinet
  const firstCabinet = useRef();
  const firstLeftDoor = useRef();
  const firstRightDoor = useRef();
  const firstCabinetBackPanel = useRef();

  //----------Second Door
  const secondLeftDoor = useRef();
  const secondRightDoor = useRef();

  //--STATES--\\

  const { selectedMirror } = useSelector((state) => state.mirror);
  let externalLed = 1;

  //-----Door States-----\\
  const [firstLeftOpen, setFirstLeftOpen] = useState(false);
  const [firstRightOpen, setFirstRightOpen] = useState(false);
  const [secondLeftOpen, setSecondLeftOpen] = useState(false);
  const [secondRightOpen, setSecondRightOpen] = useState(false);

  //----Dragger Functions

  const bodyTexture = useTexture(`./decors/${bodyDecor == 88 ? "U763_ST9.jpeg" : bodyDecor}`);
  bodyTexture.rotation = Math.PI * 0.5;
  bodyTexture.repeat.set(0.4, 5);
  bodyTexture.wrapS = THREE.RepeatWrapping;
  bodyTexture.wrapT = THREE.RepeatWrapping;

  useFrame((state) => {
    //--Body Texture
    cabinetBody.current.material.map = bodyTexture;

    //------DOOR ROTATIONS------\\

    //--FirstCabinet
    if (firstLeftOpen) {
      gsap.to(firstLeftDoor.current.rotation, { y: -Math.PI * 0.5 });
    } else {
      gsap.to(firstLeftDoor.current.rotation, { y: 0 });
    }
    if (firstRightOpen) {
      gsap.to(firstRightDoor.current.rotation, { y: Math.PI * 0.5 });
    } else {
      gsap.to(firstRightDoor.current.rotation, { y: 0 });
    }

    //--SecondCabinet
    if (secondLeftOpen) {
      gsap.to(secondLeftDoor.current.rotation, { y: -Math.PI * 0.5 });
    } else {
      gsap.to(secondLeftDoor.current.rotation, { y: 0 });
    }
    if (secondRightOpen) {
      gsap.to(secondRightDoor.current.rotation, { y: Math.PI * 0.5 });
    } else {
      gsap.to(secondRightDoor.current.rotation, { y: 0 });
    }

    //--BackPanel
    if (backPanel === 0) {
      // WHITE
      firstCabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#ffffff",
      });
    } else if (backPanel === 1) {
      // MIRROR
      firstCabinetBackPanel.current.material = materials.mirror;
    } else if (backPanel === 2) {
      // GRAY

      firstCabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#919191",
      });
    } else if (backPanel === 3) {
      // KREM
      firstCabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#FCFF94",
      });
    } else if (backPanel === 4) {
      // BLACK
      firstCabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#333333",
      });
    }
  });

  // Border heights
  useEffect(() => {
    firstLeftDoor.current.scale.y = 0.5 + camCikintiAlt * 0.0015; // alttaki
    firstLeftDoor.current.position.y = 1.275 - camCikintiAlt * 0.00036;
    firstRightDoor.current.scale.y = 0.5 + camCikintiAlt * 0.0015;
    firstRightDoor.current.position.y = 1.275 - camCikintiAlt * 0.00036;
    secondLeftDoor.current.scale.y = 0.5 + camCikintiUst * 0.0015; // üstteki
    secondLeftDoor.current.position.y = 1.668 + camCikintiUst * 0.00036;
    secondRightDoor.current.scale.y = 0.5 + camCikintiUst * 0.0015;
    secondRightDoor.current.position.y = 1.668 + camCikintiUst * 0.00036;
  }, [camCikintiAlt, camCikintiUst]);

  useEffect(() => {
    frankfurt.current.scale.x = 1 + (cabinetWidth - 200) / 400;
    frankfurt.current.scale.y = 1.5 + (cabinetHeight - 400) / 300;
    frankfurt.current.position.y = -4.8 - (cabinetHeight - 400) / 280;
    frankfurt.current.position.z = 0 + 0.065 * cabinetDepth * 0.00125;
    frankfurt.current.scale.z = 2 + cabinetDepth * 0.00225;
  }, [cabinetWidth, cabinetHeight, cabinetDepth, cabinetType]);

  useFrame(() => {
    frankfurt.current.position.x = -0.67 - cabinetWidth * 0.00275;
    bodyLed.current.position.x = 1.11;

    if (firstLeftOpen) {
      gsap.to(firstLeftDoor.current.scale, {
        x: 0.6 + cabinetWidth * 0.00024 - cabinetDepth * 0.00035,
      });
    } else {
      gsap.to(firstLeftDoor.current.scale, { x: 1 });
    }
    if (firstRightOpen) {
      gsap.to(firstRightDoor.current.scale, {
        x: 0.6 + cabinetWidth * 0.00024 - cabinetDepth * 0.00035,
      });
    } else {
      gsap.to(firstRightDoor.current.scale, { x: 1 });
    }
    if (secondLeftOpen) {
      gsap.to(secondLeftDoor.current.scale, {
        x: 0.6 + cabinetWidth * 0.00024 - cabinetDepth * 0.00035,
      });
    } else {
      gsap.to(secondLeftDoor.current.scale, { x: 1 });
    }
    if (secondRightOpen) {
      gsap.to(secondRightDoor.current.scale, {
        x: 0.6 + cabinetWidth * 0.00024 - cabinetDepth * 0.00035,
      });
    } else {
      gsap.to(secondRightDoor.current.scale, { x: 1 });
    }

    // };

    // return resize();
  });

  // Door opening directions
  useEffect(() => {
    if (isFirstLeftSided) {
      firstLeftDoor.current.visible = true;
      firstRightDoor.current.visible = false;
    } else {
      firstLeftDoor.current.visible = false;
      firstRightDoor.current.visible = true;
    }
    if (isSecondLeftSided) {
      secondLeftDoor.current.visible = true;
      secondRightDoor.current.visible = false;
    } else {
      secondLeftDoor.current.visible = false;
      secondRightDoor.current.visible = true;
    }
  }, [isFirstLeftSided, isSecondLeftSided, doorOpeningSide]);

  useEffect(() => {
    bodyLed.current.position.z = 0.135 + cabinetType;
  });

  // Opening all doors

  useEffect(() => {
    if (openAllDoors) {
      if (isFirstLeftSided) {
        setFirstLeftOpen(true);
        setFirstRightOpen(false);
      } else {
        setFirstLeftOpen(false);
        setFirstRightOpen(true);
      }
      if (isSecondLeftSided) {
        setSecondLeftOpen(true);
        setSecondRightOpen(false);
      } else {
        setSecondLeftOpen(false);
        setSecondRightOpen(true);
      }
    } else {
      setFirstLeftOpen(false);
      setFirstRightOpen(false);
      setSecondLeftOpen(false);
      setSecondRightOpen(false);
    }
  }, [openAllDoors]);

  const hasLedFrame = [EnumCabinets.BHS008L4, EnumCabinets.BHS013L4].includes(selectedMirror);

  return (
    <>
      {widthCmVisible && (
        <CentimeterWidthLines
          textWidth={cabinetWidth > 999 ? "85px" : "75px"}
          textPadding={cabinetWidth > 999 ? "0 12px" : "0 15px"}
          textValue={cabinetWidth == 0 ? "400" : cabinetWidth}
          textSize={"1.6em"}
          textHeight="40px"
          textPos={[-0.12 + cabinetWidth * 0.00006, 0.9, 0.04]}
          lineLeft={-122 - cabinetWidth * 0.2}
          lineWidth={75 + cabinetWidth * 0.4}
          scale={[0.12, 0.3, 0.12]}
          position={[0, -3.9 + cabinetHeight * 0.00265, -0.07]}
        />
      )}
      {heightCmVisible && (
        <CentimeterHeightLines
          textWidth={cabinetHeight > 999 ? "105px" : "93px"}
          textPadding={cabinetHeight > 999 ? "0 14px" : "0 16px"}
          textValue={cabinetHeight == 0 ? "400" : cabinetHeight}
          textSize={"2em"}
          textHeight="50px"
          textPos={[cabinetHeight > 999 ? 0.56 : 0.59, 0.36, 0.02]}
          textTop={160 - cabinetHeight * 0.5}
          lineTop={173 - cabinetHeight * 0.89}
          lineHeight={25 + cabinetHeight * 0.89}
          scale={[0.3, 0.12, 0.12]}
          position={[-0.4 + cabinetWidth * 0.0008, -3.19, -0.07]}
        />
      )}
      {depthCmVisible && (
        <CentimeterDepthLines
          textWidth={"75px"}
          textPadding={"0 14px"}
          textValue={cabinetDepth == 0 ? "140" : cabinetDepth}
          textSize={"1.6em"}
          textHeight="40px"
          textPos={[0.13, 0.9, 0.24 + cabinetDepth * 0.00022]}
          lineLeft={9}
          lineWidth={130 + cabinetDepth * 0.15}
          scale={[0.12, 0.3, 0.15]}
          position={[-0.4 - cabinetWidth * 0.0006, -4.2 + cabinetHeight * 0.0012, -0.2 - cabinetDepth * 0.0001]}
        />
      )}
      <group renderOrder={1} ref={frankfurt} {...props}>
        {/* LED ÇERÇEVE */}
        <group ref={bodyLed} scale={[0.33 + cabinetWidth * 0.00003, 0.942 + cabinetHeight * 0.00003, 1]} position={[1.11, 1.47, 0.135 + cabinetType]}>
          <Cm />
          <mesh visible={hasLedFrame ? true : false} geometry={nodes.led_1.geometry} material={materials.aluminum} />
          <mesh visible={hasLedFrame ? true : false} geometry={nodes.led_2.geometry} material={materials.ledtexture}>
            <meshStandardMaterial color={[lightColor[0] * 0.25, lightColor[1] * 0.25, lightColor[2] * 0.25]} toneMapped={false} />
          </mesh>
        </group>

        {/* FIRST CABINET */}
        <group position-z={cabinetType} ref={firstCabinet}>
          <group name="bodyParts">
            {/* ÜST BÖLME */}
            <mesh ref={cabinetBody} castShadow receiveShadow geometry={nodes.body.geometry} material={materials.Material} position={[1.11, 1.47, 0.02]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh ref={firstCabinetBackPanel} castShadow receiveShadow geometry={nodes.bodyRear.geometry} material={materials.white} position={[1.11, 1.47, -0.07]} />
            {socketUsb && (
              <group
                ref={soketRef}
                position={[0.98, 1.16, -0.05]}
                rotation={[0, -1.57, 0]}
                scale={[2.4, cabinetWidth == 400 ? 1 : 2.3 - cabinetHeight * 0.0006, cabinetWidth == 400 ? 3 : 2.4 + cabinetWidth * 0.0001]}
              >
                <mesh geometry={budapestNodes.plug_1.geometry} material={budapestMaterials.wire_214228153} />
                <mesh geometry={budapestNodes.plug_2.geometry} material={budapestMaterials.wire_204204204} />
                <mesh geometry={budapestNodes.plug_3.geometry} material={budapestMaterials.Colour_Void} />
              </group>
            )}

            <CabinetShelves />

            {firstRightOpen && <RightHinge />}
            {firstLeftOpen && <LeftHinge />}
          </group>
          <group ref={firstLeftDoor} position={[0.86, 0.7336, 0.12]}>
            <DoorHandleLeft />
            <CabinetLeftDoor type="first" />
          </group>
          {/**ALTTAKI KAPAK */}
          <group ref={firstRightDoor} position={[1.36, 0.7336, 0.12]} rotation={[0, 0, Math.PI]}>
            <TestMenuMesh centerlessTwoDown={true} openLeft={setFirstLeftOpen} openRight={setFirstRightOpen} rightDoor={firstRightOpen} leftDoor={firstLeftOpen} isLeftSided={isFirstLeftSided} />
            {firstLeftDoor.current && firstLeftDoor.current.visible === false && <DoorHandleRight />}
            <CabinetRightDoor type="first" />
          </group>
          {/**USTTEKI KAPAK */}
          <group ref={secondLeftDoor} position={[0.86, 2.2012, 0.12]}>
            <TestMenuMesh centerlessTwoUp={true} openLeft={setSecondLeftOpen} openRight={setSecondRightOpen} rightDoor={secondRightOpen} leftDoor={secondLeftOpen} isLeftSided={isSecondLeftSided} />
            <DoorHandleLeft />
            <CabinetLeftDoor type="mid" />
          </group>
          <group ref={secondRightDoor} position={[1.36, 2.2012, 0.12]} rotation={[0, 0, Math.PI]}>
            {secondRightDoor.current && secondRightDoor.current.visible === false && <DoorHandleRight />}
            <CabinetRightDoor type="mid" />
          </group>
        </group>
      </group>
    </>
  );
}

useGLTF.preload("/Frankfurt-test2.glb");
