import React, { useEffect } from "react";
import { Stack, Box, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setDoorHandle } from "../../../store/cabinet";
import { setDoorHandleFiyatı } from "../../../store/cabinetFee";

const KapıKolu = () => {
  const dispatch = useDispatch();
  const { doorHandle } = useSelector((state) => state.cabinet);

  const handleChange = (e) => {
    const matchedMenuItem = cabinetDoorHandleOptions.find((item) => item.value === e.target.value);

    dispatch(setDoorHandle(e.target.value));
    dispatch(setDoorHandleFiyatı(matchedMenuItem?.price || 0));
  };

  const cabinetDoorHandleOptions = [
    {
      value: "4764",
      label: "ohne Öffnungsmechanismus",
      price: "0",
    },
    {
      value: "4760",
      label: "Push-to-Open (grifflos)",
      price: "28,56",
    },
    {
      value: "4836",
      label: "Griffleiste Rio, Alu silberfarbig eloxiert, matt",
      price: "28,56",
    },
    {
      value: "4837",
      label: "Griffleiste Rio, Alu schwarz eloxiert, matt",
      price: "28,56",
    },
    {
      value: "4838",
      label: "Griffleiste Belo, Alu silberfarbig eloxiert, matt",
      price: "28,56",
    },
    {
      value: "4839",
      label: "Griffleiste Belo, Alu schwarz eloxiert, matt",
      price: "28,56",
    },
    {
      value: "4841",
      label: "Griffleiste Mano, Alu silberfarbig eloxiert, matt",
      price: "28,56",
    },
    {
      value: "4840",
      label: "Griffleiste Mano, Alu schwarz eloxiert, matt",
      price: "28,56",
    },
    {
      value: "4761",
      label: "010 - Bügelgriff, verzinnt antik, B: 169 mm, T: 29 mm",
      price: "9,52",
    },
    {
      value: "4762",
      label: "011 - Bügelgriff, schwarz matt lackiert, RAL 9017, B: 169 mm, T: 29 mm",
      price: "8,33",
    },
    {
      value: "4763",
      label: "012 - Bügelgriff, vernickelt gebürstet, B: 169 mm, T: 29 mm",
      price: "9,52",
    },
    {
      value: "4766",
      label: "020 - Bügelgriff, verchromt matt, B: 110 mm, T: 28 mm",
      price: "8,33",
    },
    {
      value: "4767",
      label: "021 - Bügelgriff, verchromt matt, B: 142 mm, T: 28 mm",
      price: "8,33",
    },
    {
      value: "4768",
      label: "022 - Bügelgriff, verchromt matt, B: 174 mm, T: 28 mm",
      price: "8,33",
    },
    {
      value: "4769",
      label: "023 - Bügelgriff, vernickelt gebürstet, B: 110 mm, T: 28 mm",
      price: "8,33",
    },
    {
      value: "4770",
      label: "024 - Bügelgriff, vernickelt gebürstet, B: 142 mm, T: 28 mm",
      price: "8,33",
    },
    {
      value: "4771",
      label: "025 - Bügelgriff, vernickelt gebürstet, B: 174 mm, T: 28 mm",
      price: "8,33",
    },
    {
      value: "4772",
      label: "026 - Bügelgriff, verzinnt antik, B: 110 mm, T: 28 mm",
      price: "8,33",
    },
    {
      value: "4773",
      label: "027 - Bügelgriff, verzinnt antik, B: 142 mm, T: 28 mm",
      price: "8,33",
    },
    {
      value: "4774",
      label: "028 - Bügelgriff, verzinnt antik, B: 174 mm, T: 28 mm",
      price: "8,33",
    },
    {
      value: "4775",
      label: "030 - Bügelgriff, verchromt poliert, B: 169 mm, T: 22 mm",
      price: "7,14",
    },
    {
      value: "4776",
      label: "031 - Bügelgriff, vernickelt gebürstet, B: 169 mm, T: 22 mm",
      price: "7,14",
    },
    {
      value: "4777",
      label: "032 - Bügelgriff, vernickelt schwarz gebürstet, B: 169 mm, T: 22 mm",
      price: "7,14",
    },
    {
      value: "4778",
      label: "033 - Bügelgriff, schwarz matt lackiert, RAL 9017, B: 169 mm, T: 22 mm",
      price: "7,14",
    },
    {
      value: "4789",
      label: "040 - Bügelgriff aus Alu, schwarz eloxiert, B: 142 mm, T: 34 mm",
      price: "9,52",
    },
    {
      value: "4790",
      label: "041 - Bügelgriff aus Alu, schwarz eloxiert, B: 174 mm, T: 34 mm",
      price: "9,52",
    },
    {
      value: "4791",
      label: "042 - Bügelgriff aus Alu, schwarz eloxiert, B: 206 mm, T: 34 mm",
      price: "10,71",
    },
    {
      value: "4792",
      label: "043 - Bügelgriff aus Alu, vernickelt gebürstet, B: 142 mm, T: 34 mm",
      price: "9,52",
    },
    {
      value: "4793",
      label: "044 - Bügelgriff aus Alu, vernickelt gebürstet, B: 174 mm, T: 34 mm",
      price: "9,52",
    },
    {
      value: "4794",
      label: "045 - Bügelgriff aus Alu, vernickelt gebürstet, B: 206 mm, T: 34 mm",
      price: "10,71",
    },
    {
      value: "4807",
      label: "050 - Muschelgriff, verchromt poliert, B: 70 mm, T: 28 mm",
      price: "8,33",
    },
    {
      value: "4808",
      label: "051 - Muschelgriff, schwarz, B: 70 mm, T: 28 mm",
      price: "8,33",
    },
    {
      value: "4809",
      label: "052 - Muschelgriff, goldfarben, B: 70 mm, T: 28 mm",
      price: "8,33",
    },
    {
      value: "4810",
      label: "060 - Muschelgriff, verchromt poliert, B: 158 mm, T: 24 mm",
      price: "9,52",
    },
    {
      value: "4811",
      label: "061 - Muschelgriff, schwarz, B: 158 mm, T: 24 mm",
      price: "9,52",
    },
    {
      value: "4812",
      label: "062 - Muschelgriff, goldfarben, B: 158 mm, T: 24 mm",
      price: "9,52",
    },
    {
      value: "4813",
      label: "070 - Muschelgriff, verchromt poliert, B: 45 mm, T: 24 mm",
      price: "7,14",
    },
    {
      value: "4814",
      label: "071 - Muschelgriff, verchromt poliert, B: 92 mm, T: 24 mm",
      price: "7,14",
    },
    {
      value: "4815",
      label: "072 - Muschelgriff, schwarz, B: 45 mm, T: 24 mm",
      price: "7,14",
    },
    {
      value: "4816",
      label: "073 - Muschelgriff, schwarz, B: 92 mm, T: 24 mm",
      price: "7,14",
    },
    {
      value: "4817",
      label: "074 - Muschelgriff, goldfarben, B: 45 mm, T: 24 mm",
      price: "7,14",
    },
    {
      value: "4818",
      label: "075 - Muschelgriff, goldfarben, B: 92 mm, T: 24 mm",
      price: "8,33",
    },
    {
      value: "4795",
      label: "080 - Sockelgriff, verchromt poliert, B: 92 mm, T: 23 mm",
      price: "7,14",
    },
    {
      value: "4796",
      label: "081 - Sockelgriff, verchromt poliert, B: 128 mm, T: 23 mm",
      price: "7,14",
    },
    {
      value: "4797",
      label: "082 - Sockelgriff, schwarz, B: 92 mm, T: 23 mm",
      price: "7,14",
    },
    {
      value: "4798",
      label: "083 - Sockelgriff, schwarz, B: 128 mm, T: 23 mm",
      price: "7,14",
    },
    {
      value: "4799",
      label: "084 - Sockelgriff, goldfarben, B: 92 mm, T: 23 mm",
      price: "7,14",
    },
    {
      value: "4800",
      label: "085 - Sockelgriff, goldfarben, B: 128 mm, T: 23 mm",
      price: "7,14",
    },
    {
      value: "4801",
      label: "(z. Zt. nicht lieferbar) 090 - Sockelgriff, verchromt poliert, B: 138 mm, T: 29 mm",
      price: "8,33",
    },
    {
      value: "4802",
      label: "091 - Sockelgriff, schwarz, B: 138 mm, T: 29 mm",
      price: "8,33",
    },
    {
      value: "4803",
      label: "092 - Sockelgriff, goldfarben, B: 138 mm, T: 29 mm",
      price: "8,33",
    },
    {
      value: "4804",
      label: "100 - Sockelgriff, verchromt poliert, B: 111 mm, T: 26 mm",
      price: "7,14",
    },
    {
      value: "4805",
      label: "101 - Sockelgriff, schwarz, B: 111 mm, T: 26 mm",
      price: "7,14",
    },
    {
      value: "4806",
      label: "102 - Sockelgriff, goldfarben, B: 111 mm, T: 26 mm",
      price: "8,33",
    },
    {
      value: "4785",
      label: "110 - Sockelgriff, champagnerfarben, matt, B: 190 mm, T: 19 mm",
      price: "9,52",
    },
    {
      value: "4786",
      label: "111 - Sockelgriff, vermessingt poliert, B: 190 mm, T: 19 mm",
      price: "10,71",
    },
    {
      value: "4787",
      label: "112 - Sockelgriff, vermessingt antik, B: 190 mm, T: 19 mm",
      price: "9,52",
    },
    {
      value: "4788",
      label: "113 - Sockelgriff, schwarz matt lackiert, RAL 9017, B: 190 mm, T: 19 mm",
      price: "8,33",
    },
    {
      value: "4779",
      label: "120 - Sockelgriff, vernickelt gebürstet, B: 52 mm, T: 23 mm",
      price: "4,76",
    },
    {
      value: "4780",
      label: "121 - Sockelgriff, vernickelt gebürstet, B: 200 mm, T: 23 mm",
      price: "8,33",
    },
    {
      value: "4781",
      label: "122 - Sockelgriff, vernickelt schwarz gebürstet, B: 52 mm, T: 23 mm",
      price: "4,76",
    },
    {
      value: "4782",
      label: "123 - Sockelgriff, vernickelt schwarz gebürstet, B: 200 mm, T: 23 mm",
      price: "8,33",
    },
    {
      value: "4783",
      label: "124 - Sockelgriff, schwarz matt, B: 52 mm, T: 23 mm",
      price: "4,76",
    },
    {
      value: "4784",
      label: "125 - Sockelgriff, schwarz matt, B: 200 mm, T: 23 mm",
      price: "8,33",
    },
    {
      value: "4819",
      label: "130 - Sockelgriff aus Stahl, B: 132 mm, T: 49 mm",
      price: "24,99",
    },
    {
      value: "4820",
      label: "140 - Steggriff aus Leder braun, Sockel Stahl verchromt poliert, B: 132 mm, T: 29 mm",
      price: "33,32",
    },
    {
      value: "4821",
      label: "141 - Steggriff aus Leder naturbraun, Sockel Stahl verchromt poliert, B: 132 mm, T: 29 mm",
      price: "33,32",
    },
    {
      value: "4822",
      label: "142 - Steggriff aus Leder anthrazit, Sockel Stahl verchromt poliert, B: 132 mm, T: 29 mm",
      price: "33,32",
    },
    {
      value: "4823",
      label: "150 - Möbelknopf aus Stahl, B: 31 mm T: 49 mm",
      price: "15,47",
    },
    {
      value: "4824",
      label: "160 - Möbelknopf aus Alu, edelstahlfarben, B: 60 mm, T: 33 mm",
      price: "7,14",
    },
    {
      value: "4825",
      label: "161 - Möbelknopf aus Alu, schwarz gebürstet, B: 60 mm, T: 33 mm",
      price: "7,14",
    },
    {
      value: "4826",
      label: "162 - Möbelknopf aus Alu, messingfarben gebürstet, B: 60 mm, T: 33 mm",
      price: "7,14",
    },
    {
      value: "4827",
      label: "170 - Möbelknopf aus Alu, edelstahlfarben, B: 33 mm, T: 10 mm",
      price: "4,76",
    },
    {
      value: "4828",
      label: "171 - Möbelknopf aus Alu, schwarz gebürstet, B: 33 mm, T: 10 mm",
      price: "4,76",
    },
    {
      value: "4829",
      label: "172 - Möbelknopf aus Alu, messingfarben gebürstet, B: 33 mm, T: 10 mm",
      price: "4,76",
    },
    {
      value: "4832",
      label: "190 - Möbelknopf aus Leder, braun, B: 40 mm, T: 22 mm",
      price: "9,52",
    },
    {
      value: "4833",
      label: "200 - Möbelknopf aus Leder braun, Sockel Stahl verchromt poliert, Ø 22 mm , T: 29 mm",
      price: "16,66",
    },
    {
      value: "4834",
      label: "201 - Möbelknopf aus Leder naturbraun, Sockel Stahl verchromt poliert, Ø 22 mm, T: 29 mm",
      price: "16,66",
    },
    {
      value: "4835",
      label: "202 - Möbelknopf aus Leder anthrazit, Sockel Stahl verchromt poliert, Ø 22 mm , T: 29 mm",
      price: "16,66",
    },
  ];

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Griffe / Griffleisten
      </Typography>
      <Stack direction="row" justifyContent="space-evenly" sx={{ flexGrow: "1", fontSize: "0.7rem" }}>
        <input name="id[324]" value={doorHandle || "4764"} />
        <Select value={doorHandle || "4764"} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }} onChange={handleChange}>
          {cabinetDoorHandleOptions.map((item) => (
            <MenuItem value={item.value}>
              {item.label} {item.price === 0 ? "" : "+ €" + item.price}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  );
};

export default KapıKolu;
