import React from "react";
import { useGLTF } from "@react-three/drei";

const DigitalWatch = ({ position }) => {
  const { nodes, materials } = useGLTF("/rectangle-mirror-rev01.glb");
  return (
    <mesh geometry={nodes.Digital_Clock.geometry} material={materials.ledtexture} scale={[1, 1, 1]} position={position} rotation={[Math.PI / 2, 0, 0]}>
      {/* <meshBasicMaterial color={lightColor} toneMapped={false} /> */}
    </mesh>
  );
};

export default DigitalWatch;
