import React from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAnalogWatch, setDigitalWatch } from "../../../store/mirrors";
import { setSaatFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";
const AnalogDigitalSaat = () => {
  const dispatch = useDispatch();
  const { digitalWatch, analogWatch } = useSelector((state) => state.mirror);
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal title="Informationen zur Uhr">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Analog-/ Digitaluhr
        </Typography>
      </RenderModal>
      <input name={"id[69]"} value={digitalWatch ? 9552 : analogWatch ? 598 : 597} />
      <Select
        value={digitalWatch ? 1 : analogWatch ? 2 : 0}
        onChange={(e) => {
          if (e.target.value == 1) {
            dispatch(setDigitalWatch(true));
            dispatch(setAnalogWatch(false));
          } else if (e.target.value == 2) {
            dispatch(setAnalogWatch(true));
            dispatch(setDigitalWatch(false));
          } else {
            dispatch(setAnalogWatch(false));
            dispatch(setDigitalWatch(false));
          }

          dispatch(setSaatFee(e.target.value == 0 ? 0 : 49.99));
        }}
        sx={{
          height: "2.2rem",
          flexGrow: "1",
          fontSize: "0.7rem",
        }}
      >
        <MenuItem value={0}>---</MenuItem>
        <MenuItem value={1}>Digitaluhr mit APP Synchronisierung + 49,99 €</MenuItem>
        <MenuItem value={2}>Analoguhr + 49,99 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default AnalogDigitalSaat;
