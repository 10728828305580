import { Html } from "@react-three/drei";

import { useSelector } from "react-redux";

const CentimeterHeightLines = ({ position, lineHeight, lineTop, textTop, textValue, textPadding, textWidth, textSize = "1.4em", textHeight = "35px", scale = 0.12, textPos = [0.61, 0.36, 0.02] }) => {
  return (
    <mesh position={position}>
      <Html transform position={[0.7, 0.4, 0]} scale={scale}>
        <div>
          <div
            className="bg-black"
            style={{
              width: 1,
              position: "absolute",
              top: lineTop,
              height: lineHeight,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <div className="w-4 h-0.5 bg-black" />
            <div className="w-4 h-0.5 bg-black" />
          </div>
        </div>
      </Html>
      <Html scale={[0.1, 0.1, 0.1]} transform position={textPos}>
        <div
          style={{
            width: textWidth,
            height: textHeight,
            backgroundColor: "rgba(0, 0, 0, 1)",
            color: "#fff",
            position: "absolute",
            top: textTop,

            borderRadius: 100,
            display: "flex",
            padding: textPadding,
          }}
        >
          <span
            style={{
              fontSize: textSize,
            }}
          >
            {textValue}
          </span>
        </div>
      </Html>
    </mesh>
  );
};

export default CentimeterHeightLines;
