import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EnumCabinetMirrors, setExternalLeds } from "../../../../store/cabinetMirror";
import { setExternalLedFiyatı } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const LavaboTavan = () => {
  const dispatch = useDispatch();
  const { externalLeds } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);

  const isPandora = [
    EnumCabinetMirrors.PANDORA_1,
    EnumCabinetMirrors.KATHERINE_1,
    EnumCabinetMirrors.VERONICA_1,
    EnumCabinetMirrors.PANDORA_2,
    EnumCabinetMirrors.KATHERINE_2,
    EnumCabinetMirrors.VERONICA_2,
    EnumCabinetMirrors.PANDORA_3,
    EnumCabinetMirrors.KATHERINE_3,
    EnumCabinetMirrors.VERONICA_3,
    EnumCabinetMirrors.PANDORA_4,
    EnumCabinetMirrors.KATHERINE_4,
    EnumCabinetMirrors.VERONICA_4,
  ].includes(selectedMirror);

  const isAluminium = [
    EnumCabinetMirrors.HAVEL,
    EnumCabinetMirrors.LECH,
    EnumCabinetMirrors.WESER,
    EnumCabinetMirrors.ELBE_1,
    EnumCabinetMirrors.NECKAR,
    EnumCabinetMirrors.LIPPE,
    EnumCabinetMirrors.WERRA,
  ].includes(selectedMirror);

  useEffect(() => {
    dispatch(setExternalLeds(0));
    dispatch(setExternalLedFiyatı(0));
  }, [selectedMirror]);

  const handleChange = (e) => {
    dispatch(setExternalLeds(e.target.value));
    dispatch(setExternalLedFiyatı(e.target.value === 1 ? 89 : e.target.value === 2 ? 89 : e.target.value === 3 ? 149 : e.target.value === 4 ? 149 : e.target.value === 5 ? 298 : 0));
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="25">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Waschtisch-/ Decken-Beleuchtung
        </Typography>
      </RenderModal>

      <input name="id[65]" value={externalLeds == 0 ? 583 : externalLeds == 1 ? 584 : externalLeds == 2 ? 585 : externalLeds == 3 ? 586 : externalLeds == 4 ? 9614 : externalLeds == 5 ? 9615 : null} />

      <Select onChange={handleChange} value={externalLeds} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>---</MenuItem>
        <MenuItem value={1}>Waschbeckenbeleuchtung + 89,00 €</MenuItem>
        {!isPandora && <MenuItem value={2}>Deckenbeleuchtung + 89,00 €</MenuItem>}
        {!isPandora && <MenuItem value={3}>Waschbecken-/Deckenbeleuchtung + 149,00 €</MenuItem>}
        {!isAluminium && <MenuItem value={4}>Außenbeleuchtung rechts + links + 149,00 €</MenuItem>}
        {!isAluminium && <MenuItem value={5}>Außenbeleuchtung rundherum + 298,00 €</MenuItem>}
      </Select>
    </Stack>
  );
};

export default LavaboTavan;
