import React from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeMakeUpLightColor } from "../../../store/mirrors";
import { setMakyajIsikFee } from "../../../store/mirrorFee";

const MakyajAynasıIsik = () => {
  const dispatch = useDispatch();
  const { makeupLightColorIndex } = useSelector((state) => state.mirror);
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Schminkspiegel Lichtfarbe
      </Typography>

      <input
        name="id[90]"
        value={makeupLightColorIndex == -1 ? 749 : makeupLightColorIndex == 0 ? 751 : makeupLightColorIndex == 1 ? 750 : makeupLightColorIndex == 2 ? 752 : makeupLightColorIndex === 7 ? 1045 : null}
      />

      <Select
        onChange={(e) => {
          const value = e.target.value;
          dispatch(changeMakeUpLightColor(value));
          // dispatch(setMakyajIsikFee(value == 3 ? 79.99 : value == 4 ? 99 : value == 5 ? 179.99 : value == 6 ? 159 : 0)); fiyat eklenmiyor
        }}
        value={makeupLightColorIndex}
        sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
      >
        <MenuItem value={-1}>---</MenuItem>
        <MenuItem value={0}>Neutralweiß</MenuItem>
        <MenuItem value={1}>Warmweiß </MenuItem>
        <MenuItem value={2}>Kaltweiß</MenuItem>
        {/* LED Soğuk Beyaz 6000K
        <MenuItem value={3}>LED RGB, Uzaktan Kumanda Dahil + 79,99€</MenuItem>
        <MenuItem value={4}>
          Çift Dokunmatik Sensörlü Sıcak ve Soğuk Beyaz LED + 99,00€
        </MenuItem>
        <MenuItem value={5}>
          Kısılabilir LED 2700K - 6300K, Hareket Kontrolü Dahil + 179,99€
        </MenuItem>
        <MenuItem value={6}>
          LED Kısılabilir 2700K - 6300K, Uzaktan Kumanda İle + 159,00€
        </MenuItem> */}
        <MenuItem value={7}>3 Lichtfarben (Warm-/Kalt-/Neutralweiß) mit Touch-Sensor + 39,99 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default MakyajAynasıIsik;
