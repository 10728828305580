import React, { useEffect } from "react";
import { Stack, Typography, Divider, useMediaQuery } from "@mui/material";
import AynaTipi from "./menuItems/AynaTipi";
import Genislik from "./menuItems/Genislik";
import Suspansiyon from "./menuItems/Suspansiyon";
import LedIsıgı from "./menuItems/LedIsıgı";
import LedCiftSerit from "./menuItems/LedCiftSerit";
import YanTamKaplama from "./menuItems/YanTamKaplama";
import MakyajAynasıPozisyon from "./menuItems/MakyajAynasıPozisyon";
import CamRaf from "./menuItems/CamRaf";
import CamRafUzunlugu from "./menuItems/CamRafUzunlugu";
import AnalogDigitalSaat from "./menuItems/AnalogDigitalSaat";
import SaatKonumu from "./menuItems/SaatKonumu";
import Soketler from "./menuItems/Soketler";
import SoketPozisyon from "./menuItems/SoketPozisyon";
import Faceta from "./menuItems/Faceta";
import MakyajAynası from "./menuItems/MakyajAynası";
import MakyajAynasıTipi from "./menuItems/MakyajAynasıTipi";
import Bluetooth from "./menuItems/Bluetooth";
import Sızdırmazlık from "./menuItems/Sızdırmazlık";
import YorumYapın from "./menuItems/YorumYapın";
import SiparisVer from "./menuItems/SiparisVer";
import { useDispatch, useSelector } from "react-redux";
import Yukseklik from "./menuItems/Yukseklik";
import MakyajAynaUzaklık from "./menuItems/MakyajAynaUzaklık";
import { data } from "jquery";
import DikeySensorPos from "./menuItems/DikeySensorPos";
import YataySensorPos from "./menuItems/YataySensorPos";
import IsiSensor from "./menuItems/IsiSensor";
import IsiSensorPos from "./menuItems/IsiSensorPos";
import AynaAdedi from "./menuItems/AynaAdedi";
import MakyajAynasıIsik from "./menuItems/MakyajAynasıIsik";
import MakyajAynasiSensor from "./menuItems/MakyajAynasiSensor";
import { TempSensor } from "../OrtakComponents/TempSensor";
import { getProductWithMatrixByName, getProductWithMatrixByNameForDaire, readPriceFromApiForDaire, readPriceFromApiForKare, readPriceFromApiForOval } from "../../utils/readJson";
import { setBaseFee } from "../../store/mirrorFee";
import { changeHeightCmVisible, changeWidthCmVisible, EnumMirrors, changeMenuIsOpen } from "../../store/mirrors";
import MakyajAynasiSensorPos from "./menuItems/MakyajAynasiSensorPos";
import Sensorler from "./menuItems/Sensorler";
import LightButton from "./menuItems/Buttons/LightButton";
import MenuToggleButton from "../AynaliDolapComponents/menuItems/Buttons/MenuToggleButton";
import StraightenIcon from "@mui/icons-material/Straighten";
import SummaryModal from "./SummaryModal";
import CiftSensorPos from "./menuItems/CiftSensorPos";
import CloseIcon from "@mui/icons-material/Close";
import Kanten from "./menuItems/Kanten";
import Bohrungen from "./menuItems/Bohrungen";

const AynaMenu2 = () => {
  /**
   * Ayna Stateler
   * Aynalar: selectedMirror / changeMirror 123
   *
   * Işık Rengi: lightColor / changeLightColor "white", "red", "yellow", "blue"
   *
   * Saat Konumu: watchPosition / changeWatchPosition discorddan attı
   *
   * Dijital Saat : digitalWatch / setDigitalWatch
   *
   */

  const {
    yataySensor,
    dikeySensor,
    raf,
    analogWatch,
    digitalWatch,
    makeupMirror,
    selectedMirror,
    makeupMirrorPos,
    makeupMirrorSensor,
    mirrorWidth,
    mirrorHeight,
    aynaIsıtma,
    lightColorIndex,
    soket,
    isiSensor,
    selectedMirrorName,
    selectedMirrorExcelIndex,
    orderAmount,
    isPrivate,
    isMenuOpen,
    sensors,
    makeupMirrorType,
  } = useSelector((state) => state.mirror);

  const {
    baseFee,
    yanTamKaplamaFee,
    facetaFee,
    ledIsigiFee,
    aynaIsitmaFee,
    rafFee,
    makyajIsikFee,
    makyajTypeFee,
    sizdirmazlikFee,
    bluetoothFee,
    makyajFee,
    soketFee,
    saatFee,
    isiSensorFee,
    kareSensorFee,
    gecisAnahtariSensorFee,
    yataySensorFee,
    ciftLedFee,
    suspansiyonFee,
  } = useSelector((state) => state.mirrorFee);

  const dispatch = useDispatch();
  const desktopMatches1700 = useMediaQuery("(min-width:1700px)");
  const desktopMatches1200 = useMediaQuery("(min-width:1200px)");
  const desktopMatches900 = useMediaQuery("(min-width:900px)");
  const mobileMatches600 = useMediaQuery("(min-width:600px)");
  const menuResponsiveWidth = desktopMatches1700 ? "29vw" : desktopMatches1200 ? "29vw" : desktopMatches900 ? "40vw" : mobileMatches600 ? "50vw" : "100vw";
  const menuTranslateXClosed = desktopMatches1200 ? "0" : desktopMatches900 ? "50vw" : mobileMatches600 ? "60vw" : "0";
  const menuToggledPosition = isMenuOpen ? "0" : menuTranslateXClosed;

  const isDaire = selectedMirror == EnumMirrors.CHARON || selectedMirror == EnumMirrors.DIANA || selectedMirror == EnumMirrors.HELENA;

  const dimensionRule = isDaire ? mirrorWidth > 1200 : mirrorWidth > 1200 && mirrorHeight > 800;

  const dimensionFee = dimensionRule ? 49.99 : 0;

  const total =
    baseFee +
    (yanTamKaplamaFee +
      facetaFee +
      rafFee +
      makyajIsikFee +
      makyajTypeFee +
      ledIsigiFee +
      sizdirmazlikFee +
      bluetoothFee +
      makyajFee +
      soketFee +
      saatFee +
      isiSensorFee +
      kareSensorFee +
      yataySensorFee +
      ciftLedFee +
      suspansiyonFee +
      aynaIsitmaFee +
      gecisAnahtariSensorFee +
      dimensionFee) *
      orderAmount;

  const isOvalMirror = [EnumMirrors.F597L4K, EnumMirrors.F610L4K].includes(selectedMirror);

  useEffect(() => {
    async function fetchData() {
      if (isOvalMirror) {
        if (selectedMirror == EnumMirrors.F597L4K) {
          await readPriceFromApiForOval(EnumMirrors.F597L4K, mirrorWidth == 400 ? 500 : mirrorWidth, mirrorHeight).then((response) => {
            dispatch(setBaseFee(response ? response : 0));
          });
        } else {
          await readPriceFromApiForOval(EnumMirrors.F610L4K, mirrorWidth, mirrorHeight == 400 ? 500 : mirrorHeight).then((response) => {
            dispatch(setBaseFee(response ? response : 0));
          });
        }
      } else if (!isDaire) {
        const isKristall = [EnumMirrors.STARK_RAHMENLOS_5MM, EnumMirrors.SILBER_RAHMENLOS_3MM, EnumMirrors.RAHMENLOSER_6_MM].includes(selectedMirror);
        if (isKristall) {
          await readPriceFromApiForKare(selectedMirror, mirrorWidth == 400 ? 200 : mirrorWidth, mirrorHeight == 400 ? 200 : mirrorHeight).then((response) => {
            dispatch(setBaseFee(response ? response : 0));
          });
        } else {
          await readPriceFromApiForKare(selectedMirrorExcelIndex, mirrorWidth, mirrorHeight).then((response) => {
            dispatch(setBaseFee(response ? response : 0));
          });
        }
      } else {
        await readPriceFromApiForDaire(selectedMirrorExcelIndex, mirrorWidth).then((response) => {
          dispatch(setBaseFee(response ? response : 0));
        });
      }
    }
    fetchData();
  }, [selectedMirrorExcelIndex, mirrorWidth, mirrorHeight]);

  const classnameForPosition = desktopMatches1200 ? "right-[30vw]" : "right-2";

  const classNameForZIndex = !desktopMatches1200 && isMenuOpen ? "z-50" : "z-50";

  const Kristallspiegel6mm = [EnumMirrors.RAHMENLOSER_6_MM].includes(selectedMirror);

  const notInclude = Kristallspiegel6mm;

  const facetaIsVisible = [EnumMirrors.NEW_YORK, EnumMirrors.SANTA_CRUZ, EnumMirrors.MIAMI, EnumMirrors.F597L4K, EnumMirrors.F610L4K].includes(selectedMirror);

  return (
    <>
      <div id="buttonsContainer1">
        <button
          className={` bg-white flex items-center justify-center w-10 h-10 rounded-full bg-cover overflow-hidden outline-none cursor-pointer border-2 border-[#edf0f0] control-btn absolute ${classnameForPosition} top-[63px] ${classNameForZIndex}`}
          data-counter="0"
          id="cmButton"
          onClick={() => {
            dispatch(changeHeightCmVisible(true));
            dispatch(changeWidthCmVisible(true));
          }}
        >
          <StraightenIcon />
        </button>
        <LightButton isMenuOpen={false} />
      </div>

      {mobileMatches600 ? <MenuToggleButton isMenuOpen={isMenuOpen} setIsMenuOpen={() => dispatch(changeMenuIsOpen(!isMenuOpen))} /> : null}

      <Stack
        className="mobileMenuHeightEdit"
        spacing={2}
        p={3}
        sx={{
          position: "fixed",
          top: "0",
          right: "0",
          height: "100vh",
          width: menuResponsiveWidth,
          backgroundColor: "#ffffff",
          overflowY: "auto",
          overflowX: "hidden",
          zIndex: "2",
          transition: "all 0.5s ease-in",
          transform: `translateX(${menuToggledPosition})`,
        }}
      >
        {!desktopMatches1200 && mobileMatches600 && (
          <Stack
            direction={"row"}
            spacing={0.5}
            sx={{
              position: "absolute",
              top: "4px",
              left: "4px",
              alignItems: "center",
              fontWeight: "700",
              fontSize: "16px",
              color: "#0d646f",
              border: "2px solid #0d646f",
              borderRadius: "8px",
              p: "4px 8px",
            }}
            onClick={() => dispatch(changeMenuIsOpen(!isMenuOpen))}
          >
            <CloseIcon />
          </Stack>
        )}
        <div
          style={{
            position: "sticky",
            top: -30,
            backgroundColor: "#fff",
            width: "100%",
            height: 70,
            zIndex: 999999,
            ...(!desktopMatches1200 && { mt: "10px" }),
          }}
        >
          <Typography
            color="primary"
            sx={{
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: 70,
              backgroundColor: "#fff",
            }}
          >
            {!isPrivate ? (
              <div
                onClick={() => {
                  window.location.reload();
                }}
                style={{
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                <svg
                  style={{
                    transform: "rotate(180deg)",
                    marginRight: "0.5rem",
                    color: "lightblue",
                  }}
                  className="svg-icon"
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                >
                  <path d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z"></path>
                </svg>
                Zurück
              </div>
            ) : (
              <></>
            )}
            <div>
              <span
                style={{
                  color: "black",
                }}
              >
                {selectedMirrorName} - {parseFloat(total).toFixed(2).toString().replace(".", ",")}€
              </span>
            </div>
          </Typography>
          <Divider />
        </div>

        <form id="cart_quantity_mirror" action={"https://dev.spiegel21.de/product_info.php?products_id=" + selectedMirrorExcelIndex + "&action=add_product"} method="post">
          <input type="hidden" name="products_id" value={selectedMirrorExcelIndex} />
          <AynaTipi />

          <Genislik />
          {isDaire ? "" : <Yukseklik />}
          <Suspansiyon />
          {!notInclude && <LedIsıgı />}
          {lightColorIndex == 4 && <CiftSensorPos />}
          {/* {(lightColorIndex == 0 || lightColorIndex == 1 || lightColorIndex == 2) && <LedCiftSerit />} */}
          {!notInclude && <LedCiftSerit />}
          {!notInclude && <Sensorler />}
          {dikeySensor === true && <DikeySensorPos />}
          {yataySensor === true && <YataySensorPos />}
          {sensors == 3 && <YataySensorPos />}
          {/* Isı Sensor */}
          {isDaire ? (
            " "
          ) : (
            <>
              <YanTamKaplama />
              <CamRaf />
              {raf === 0 ? "" : <CamRafUzunlugu />}
            </>
          )}
          <AnalogDigitalSaat />
          {analogWatch || digitalWatch ? <SaatKonumu /> : ""}
          <IsiSensor />
          {isiSensor !== 0 && isDaire && (
            <>
              {/* <IsiSensorType /> */}
              <IsiSensorPos />
            </>
          )}
          {/* <TempSensor /> */}
          {/* <AynaIsıtma />
          {aynaIsıtma > 0 && <AynaIsıtmaPos />} */}
          <Soketler />
          {soket === 0 ? "" : <SoketPozisyon />}
          {isDaire ? " " : !facetaIsVisible ? <Faceta /> : null}
          {/* {
          selectedMirror == 2 || selectedMirror == 3 ? (mirrorWidth > 499 && mirrorHeight > 499 ? <MakyajAynası /> : (
            <>
              <div style={{ display: 'flex' }} >
                <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}> Makyaj Aynası</Typography> <span style={{ color: "tomato" }} >Ayna genişliğinin ve yüksekliğinin 500'den büyük olması gerekir</span>
              </div>
            </>
          )) : <MakyajAynası />
        } */}
          <MakyajAynası />
          {makeupMirror === 0 ? (
            ""
          ) : (
            <>
              <MakyajAynasıTipi />
              {makeupMirror == 2 ? <MakyajAynasıPozisyon /> : makeupMirrorType == 1 && <MakyajAynasıPozisyon />}
            </>
          )}
          {makeupMirror == 2 && (
            <>
              <MakyajAynasıIsik />
              <MakyajAynasiSensor />
              {!isDaire && makeupMirrorSensor !== 0 && <MakyajAynasiSensorPos />}
            </>
          )}
          {/* {(makeupMirrorPos >= 1) & !isDaire & (makeupMirror == 1) & (makeupMirrorType != 0) ? (
            <>
              <MakyajAynaUzaklık />
            </>
          ) : (
            ""
          )} */}

          {}

          {(makeupMirrorPos >= 1) & !isDaire ? (
            <>
              {/* {makeupMirror}
              {makeupMirrorType}
              {makeupMirror == 1 && makeupMirrorType == 0 ? "true" : "false"} */}
              <MakyajAynaUzaklık hide={(makeupMirror == 1 && makeupMirrorType == 0) || (makeupMirror == 0 && makeupMirrorType == 0) ? true : false} />
            </>
          ) : null}

          <Bluetooth />
          {Kristallspiegel6mm && <Bohrungen />}
          <Sızdırmazlık />
          {Kristallspiegel6mm && <Kanten />}
          <YorumYapın />
          <br />
          <AynaAdedi />
          <SummaryModal />
        </form>
        <center>
          <p className="text-primary relative top-5">Die Mehrwertsteuer wird an der Kasse berechnet.</p>
        </center>
        <SiparisVer />
        <br />
      </Stack>
    </>
  );
};

export default AynaMenu2;
