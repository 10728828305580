import React, { useEffect } from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeDoubleLed } from "../../../store/mirrors";
import { setCiftLedFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";
const LedCiftSerit = () => {
  const dispatch = useDispatch();
  const { doubleLed, lightColorIndex } = useSelector((state) => state.mirror);

  useEffect(() => {
    if (lightColorIndex !== 0 && lightColorIndex !== 1 && lightColorIndex !== 2) {
      dispatch(changeDoubleLed(false));
      dispatch(setCiftLedFee(0));
    }
  }, [lightColorIndex]);

  return (
    (lightColorIndex == 0 || lightColorIndex == 1 || lightColorIndex == 2) && (
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <RenderModal title="Informationen zum LED-Band doppelt">
          <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
            LED-Band doppelt
          </Typography>
        </RenderModal>
        <input name="id[80]" value={!doubleLed ? "708" : doubleLed ? "709" : null} />
        <Select
          onChange={(e) => {
            dispatch(changeDoubleLed(e.target.value));
            dispatch(setCiftLedFee(e.target.value == true ? 59.99 : 0));
          }}
          value={doubleLed}
          sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
        >
          <MenuItem value={false}>---</MenuItem>
          <MenuItem value={true}>Ja + 59,99€</MenuItem>
        </Select>
      </Stack>
    )
  );
};

export default LedCiftSerit;
