import React from "react";
import {
  Stack,
  Slider,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setBluetooth } from "../../../store/mirrors";
import { setBluetoothFee } from "../../../store/mirrorFee";
import { blue } from "@mui/material/colors";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const Bluetooth = () => {
  const dispatch = useDispatch();
  const { bluetooth } = useSelector((state) => state.mirror);
  const handleChange = (e) => {
    dispatch(setBluetooth(e.target.value));
    dispatch(setBluetoothFee(e.target.value ? 179 : 0));
  };
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal
        title="Informationen zum Bluetooth Lautsprecher"
      >
        <Typography
          variant="caption"
          fontWeight={700}
          sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}
        >
          Bluetooth Lautsprecher
        </Typography>
      </RenderModal>
      <input
        name="id[77]"
        value={bluetooth == 0 ? 694 : bluetooth == 1 ? 1914 : null}
      />
      <Select
        value={bluetooth}
        onChange={handleChange}
        sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
      >
        <MenuItem value={0}>---</MenuItem>
        <MenuItem value={1}>WHD High-End Sound-System + 179,00 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default Bluetooth;
