import { useRef, useEffect } from "react";

import * as THREE from "three";

import { useGLTF, useTexture } from "@react-three/drei";

import { useSelector } from "react-redux";

import DışAydınlatma from "../../../OrtakComponents/DışAydınlatma";

import { EnumCabinetMirrors } from "../../../../store/cabinetMirror";

import { CabinetShelves, DimmerSocketUSB, EnumSocketUSBBase, IndividualWidth, SocketUSB } from "../../cabinetBodyParts/smallParts";

export const KapaksizBolme = ({ cabinetRef, doorIndex, doorType }) => {
  const { nodes, materials } = useGLTF("/Frankfurt-test2.glb");

  const { selectedMirror } = useSelector((state) => state.mirror);
  const {
    cabinetMirrorType,
    doorNumber,
    externalLeds,
    draggerVisible,
    backPanel,
    bodyDecor,
    cabinetMirrorWidth,
    cabinetMirrorHeight,
    leftCompartmentWidth,
    rightCompartmentWidth,
    centerCompartmentWidth,
  } = useSelector((state) => state.cabinetMirror);

  const isCentered = [EnumCabinetMirrors.LAS_VEGAS, EnumCabinetMirrors.MUNCHEN_5].includes(selectedMirror);

  const mainBodyRef = useRef();

  const cabinetVisible = true;

  const cabinetBody = useRef();
  const cabinetBackPanel = useRef();

  const bodyTexture = useTexture(`./decors/${bodyDecor == 88 ? "U763_ST9.jpeg" : bodyDecor}`);
  bodyTexture.rotation = Math.PI * 0.5;
  bodyTexture.repeat.set(0.4, 5);
  bodyTexture.wrapS = THREE.RepeatWrapping;
  bodyTexture.wrapT = THREE.RepeatWrapping;

  const isAllVisible = externalLeds === 5;

  const hasBottomShelf = [
    EnumCabinetMirrors.MUNCHEN_6,
    EnumCabinetMirrors.BUDAPEST,
    EnumCabinetMirrors.SANTANDER,
    EnumCabinetMirrors.LISSABON,
    EnumCabinetMirrors.LINZ,
    EnumCabinetMirrors.CORTINA,
    EnumCabinetMirrors.WINDSOR,
    EnumCabinetMirrors.VERONICA_2,
    EnumCabinetMirrors.PANDORA_2,
    EnumCabinetMirrors.KATHERINE_2,
    EnumCabinetMirrors.GENT,
    EnumCabinetMirrors.VENEZIA,
    EnumCabinetMirrors.VERONICA_4,
    EnumCabinetMirrors.PANDORA_4,
    EnumCabinetMirrors.KATHERINE_4,
  ].includes(selectedMirror);

  useEffect(() => {
    cabinetBody.current.material.map = bodyTexture;

    if (backPanel === 0) {
      //
      cabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#ffffff",
      });
    } else if (backPanel === 1) {
      cabinetBackPanel.current.material = materials.mirror;
    } else if (backPanel === 2) {
      cabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#919191",
      });
    } else if (backPanel === 3) {
      cabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#FCFF94",
      });
    } else if (backPanel === 4) {
      cabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#333333",
      });
    }
  }, [backPanel, bodyDecor]);

  useEffect(() => {
    if (cabinetRef.current) {
      //SCALE
      const minWidthDoor = 400;
      const currentWidthPerDoor = cabinetMirrorWidth / doorNumber;
      const scaleRatio = currentWidthPerDoor / minWidthDoor;
      cabinetRef.current.scale.x = scaleRatio * 2;
      // cabinetRef.current.position.y = doorIndex % 2 === 0 ? 0.5 : -0.5;

      //POSITION
      cabinetRef.current.position.x = scaleRatio * doorIndex;
    }
  }, [cabinetMirrorWidth, doorNumber]);

  const isSideLedVisible = isAllVisible || externalLeds === 4;
  const bottomShelfIsAllVisible = isAllVisible && hasBottomShelf;

  useEffect(() => {
    if (mainBodyRef.current) {
      if (hasBottomShelf) {
        mainBodyRef.current.position.y = -0.075;
        mainBodyRef.current.scale.y = 1.187;
      } else {
        mainBodyRef.current.position.y = 0;
        mainBodyRef.current.scale.y = 1;
      }
    }
  }, [selectedMirror]);

  const socketUsbBase = EnumSocketUSBBase.MID;

  return (
    <group visible={cabinetVisible} position-z={cabinetMirrorType} scale={[1, 1, 1]} ref={cabinetRef}>
      <group name="bodyParts" ref={mainBodyRef} position={[0, 0, 0]}>
        {isCentered && (
          <>
            <SocketUSB base={socketUsbBase} />
            <DimmerSocketUSB base={socketUsbBase} />
          </>
        )}
        {isAllVisible && <DışAydınlatma scaleY={0.5} position={[0, 0.4, 0.065]} rotation={[Math.PI * 1.5, 0, Math.PI * 1.5]} />}
        {isAllVisible && <DışAydınlatma scaleY={0.5} position={[0, hasBottomShelf ? -0.55 : -0.4, 0.065]} rotation={[Math.PI * 0.5, 0, Math.PI * 0.5]} />}
        {isSideLedVisible && doorType === "first" && (
          <DışAydınlatma scaleY={bottomShelfIsAllVisible ? 0.95 : isAllVisible ? 0.8 : 0.7} position={[-0.25, bottomShelfIsAllVisible ? -0.07 : 0, 0.065]} rotation={[0, Math.PI * 1.5, 0]} />
        )}
        {isSideLedVisible && doorType === "last" && (
          <DışAydınlatma scaleY={bottomShelfIsAllVisible ? 0.95 : isAllVisible ? 0.8 : 0.7} position={[0.25, bottomShelfIsAllVisible ? -0.07 : 0, 0.065]} rotation={[0, Math.PI * 0.5, 0]} />
        )}
        <group position-y={hasBottomShelf ? -0.05 : 0}>
          {cabinetVisible && cabinetRef.current && draggerVisible && (
            <IndividualWidth
              testCabinet={true}
              width={cabinetRef.current.scale.x}
              kapaksizValue={doorType === "first" ? leftCompartmentWidth : doorType === "center" ? centerCompartmentWidth : rightCompartmentWidth}
            />
          )}
        </group>

        {/* ÜST BÖLME */}
        <mesh ref={cabinetBody} castShadow receiveShadow geometry={nodes.body.geometry} material={materials.Material} position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]} />
        <mesh ref={cabinetBackPanel} castShadow receiveShadow geometry={nodes.bodyRear.geometry} material={materials.white} position={[0, 0, -0.1]} />

        <CabinetShelves />
      </group>
    </group>
  );
};
