import React, { useEffect, useState } from "react";
import { Stack, Typography, TextField } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setRightCompartmentWidth } from "../../../../store/cabinetMirror";

const CompartmentRight = () => {
  const [rightValue, setRightValue] = useState(150);
  const dispatch = useDispatch();

  const { selectedMirror } = useSelector((state) => state.mirror);

  useEffect(() => {
    if (rightValue < 150) {
      setRightValue(150);
      dispatch(setRightCompartmentWidth(150));
    } else if (rightValue > 250) {
      setRightValue(250);
      dispatch(setRightCompartmentWidth(250));
    } else {
      dispatch(setRightCompartmentWidth(rightValue));
    }
  }, [rightValue]);

  useEffect(() => {
    dispatch(setRightCompartmentWidth(150));
    setRightValue(150);
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Stack>
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Fach rechts: lichte Breite (mm)
        </Typography>
      </Stack>

      <input type="hidden" name="id[253]" value="2883" />
      <input type="hidden" class="form-control" name="2883" value={rightValue} />

      <TextField
        type="number"
        onChange={(e) => setRightValue(e.target.value)}
        value={rightValue}
        sx={{
          width: "100%",
          height: "2.2rem",

          ".MuiInputBase-input": {
            paddingTop: 0.7,
            paddingBottom: 0.7,
            paddingLeft: 1.6,
            paddingRight: 0.7,
            width: "100%",
            fontSize: 12,
          },
        }}
      />
    </Stack>
  );
};

export default CompartmentRight;
