import React, { useEffect } from "react";
import { Stack, Box, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setDoorHandle } from "../../../../store/cabinetMirror";
import { setDoorHandleFiyatı } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const KapıKolu = () => {
  const dispatch = useDispatch();
  const { doorHandle } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const handleChange = (e) => {
    const value = e.target.value;
    dispatch(setDoorHandle(value));
    dispatch(setDoorHandleFiyatı(value === 2 ? 39.99 : value === 5 ? 14.95 : value === 6 ? 29.95 : value === 1 ? 42.45 : value === 4 ? 64.95 : value === 3 ? 19.99 : value == 99 ? 39.99 : 0));
  };

  const renderInputDataValue = () => {
    switch (doorHandle) {
      case 0:
        return 239;
      case 99:
        return 577;
      case 5:
        return 8841;
      case 6:
        return 8839;
      case 1:
        return 8840;
      case 4:
        return 8843;
      case 3:
        return 2136;
    }
  };

  useEffect(() => {
    dispatch(setDoorHandle(0));
    dispatch(setDoorHandleFiyatı(0));
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="20">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Türgriff Spiegeltür/en
        </Typography>
      </RenderModal>
      <Stack direction="row" justifyContent="space-evenly" sx={{ flexGrow: "1", fontSize: "0.7rem" }}>
        <input name="id[19]" value={renderInputDataValue()} />
        <Select value={doorHandle} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }} onChange={handleChange}>
          <MenuItem value={0}>ohne Öffnungsmechanismus</MenuItem>
          <MenuItem value={99}>Push-to-Open + 39,99 €</MenuItem>
          <MenuItem value={5}>Alu silberfarben eloxiert Mango + 14,95 €</MenuItem>
          <MenuItem value={3}>Chrom glänzend Fina + 19,99 €</MenuItem>
          <MenuItem value={6}>Verchromt poliert Moda + 29,95 €</MenuItem>
          <MenuItem value={1}>Verchromt poliert Canto + 42,45 €</MenuItem>
          <MenuItem value={4}>Alu verchromt poliert Presa + 64,95 €</MenuItem>
        </Select>
      </Stack>
    </Stack>
  );
};

export default KapıKolu;
