import React from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setHangingPlates } from "../../../store/mirrors";
import { setHangingPlatesFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const Aufhängebleche = () => {
  const dispatch = useDispatch();
  const { hangingPlates } = useSelector((state) => state.mirror);
  const handleChange = (e) => {
    dispatch(setHangingPlates(e.target.value));
    dispatch(setHangingPlatesFee(e.target.value == 1 ? 6 : e.target.value == 2 ? 19.99 : 0));
  };
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="1">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Aufhängebleche
        </Typography>
      </RenderModal>
      <input name={"id[28]"} value={hangingPlates == 1 ? 283 : hangingPlates == 2 ? 616 : 282} />
      <Select onChange={handleChange} value={hangingPlates} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>Standard (lose beiliegend)</MenuItem>
        <MenuItem value={1}>Standard (vormontiert - fertig zum Aufhängen) + 6,00 €</MenuItem>
        <MenuItem value={2}>ProfiPlus-Set (lose beiliegend) + 19,99 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default Aufhängebleche;
