import React, { useRef, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";
import OvalMirrorTopLeft from "./components/OvalMirrorTopLeft";
import OvalMirrorBottomRight from "./components/OvalMirrorBottomRight";

const F597L4K = () => {
  const { lightColor, mirrorHeight, mirrorWidth } = useSelector((state) => state.mirror);
  const { nodes, materials } = useGLTF("/rectangle-mirror-rev01.glb");
  const ovalMerkez = useRef(null);
  const scaleMultiplier = 0.6;

  useEffect(() => {
    if (ovalMerkez.current) {
      ovalMerkez.current.scale.x = 0.11 + mirrorWidth * 0.0012;
      ovalMerkez.current.scale.z = 1.4 + mirrorHeight * 0.00322;
    }
  }, [mirrorHeight, mirrorWidth]);

  return (
    <>
      <group>
        <OvalMirrorTopLeft />
        <OvalMirrorBottomRight />
      </group>

      <mesh
        ref={ovalMerkez}
        geometry={nodes.Miami.geometry}
        material={materials.ledlightTexture}
        position={[0.015, 0.428, 0.0085]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1, 1 * scaleMultiplier, 1.02]}
      >
        <mesh geometry={nodes.New_Jersey_2.geometry} position-y={-0.0054} position-z={-0.0051} scale-x={1} scale-z={0.937} scale-y={1.9} renderOrder={-1} material={materials.mirror} />
        <meshBasicMaterial color={lightColor} toneMapped={false} />
      </mesh>
    </>
  );
};

export default F597L4K;
