import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { EnumCabinetMirrors, setSensor, setSocketUsb } from "../../../../store/cabinetMirror";
import { setSensorFiyatı, setSocketUsbFiyatı } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const SensorAnahtar = () => {
  const dispatch = useDispatch();
  const { sensor } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const handleChange = (e) => {
    dispatch(setSensor(e.target.value));
    dispatch(setSocketUsb("754"));
    dispatch(setSocketUsbFiyatı(0));
  };

  const isSidedCabinet = [
    EnumCabinetMirrors.MUNCHEN_4,
    EnumCabinetMirrors.MALAGA,
    EnumCabinetMirrors.SARAGOSSA,
    EnumCabinetMirrors.KONSTANZ,
    EnumCabinetMirrors.DERBY,
    EnumCabinetMirrors.GENT,
    EnumCabinetMirrors.VENEZIA,
    EnumCabinetMirrors.ALMERIA,
    EnumCabinetMirrors.GRADO,
    EnumCabinetMirrors.VILLACH,
    EnumCabinetMirrors.VERONICA_3,
    EnumCabinetMirrors.VERONICA_4,
    EnumCabinetMirrors.PANDORA_3,
    EnumCabinetMirrors.PANDORA_4,
    EnumCabinetMirrors.KATHERINE_3,
    EnumCabinetMirrors.KATHERINE_4,
  ].includes(selectedMirror);

  const sidedSensorSwitchOptions = [
    { value: "250", label: "---", price: 0 },
    { value: "6789", label: "Switch on the left", price: 39 },
    { value: "6790", label: "Switch right", price: 39 },
    { value: "251", label: "Switch + 1x socket on the left (double frame)", price: 49 },
    { value: "578", label: "Switch + 1x socket on the right (double frame)", price: 49 },
    { value: "6785", label: "Switch + 2x socket on the left (triple frame)", price: 89 },
    { value: "6786", label: "Switch + 2x socket on the right (triple frame)", price: 89 },
    { value: "6787", label: "Switch + 1x socket + 1x USB left (triple frame)", price: 119 },
    { value: "6788", label: "Switch + 1x socket + 1x USB right frame)", price: 119 },
  ];

  const sensorSwitchOptions = [
    {
      value: "250",
      label: "---",
      price: 0,
    },
    {
      value: "6789",
      label: "Schalter links",
      price: 39,
    },
    {
      value: "6790",
      label: "Schalter rechts",
      price: 39,
    },
    {
      value: "6791",
      label: "Drehdimmer links",
      price: 49,
    },
    {
      value: "6792",
      label: "Drehdimmer rechts",
      price: 49,
    },
    {
      value: "2319",
      label: "Gestensteuerung im Schrank links",
      price: 99.99,
    },
    {
      value: "2320",
      label: "Gestensteuerung im Schrank rechts",
      price: 99.99,
    },
    {
      value: "251",
      label: "Schalter + 1x Steckdose links (Doppelrahmen)",
      price: 49,
    },
    {
      value: "578",
      label: "Schalter + 1x Steckdose rechts (Doppelrahmen)",
      price: 49,
    },
    {
      value: "6785",
      label: "Schalter + 2x Steckdose links (Dreifachrahmen)",
      price: 89,
    },
    {
      value: "6786",
      label: "Schalter + 2x Steckdose rechts (Dreifachrahmen)",
      price: 89,
    },
    {
      value: "6787",
      label: "Schalter + 1x Steckdose + 1x USB links (Dreifachrahmen)",
      price: 119,
    },
    {
      value: "6788",
      label: "Schalter + 1x Steckdose + 1x USB rechts (Dreifachrahmen)",
      price: 119,
    },
    {
      value: "990",
      label: "Gestensteuerung außen/unten links",
      price: 99.99,
    },
    {
      value: "992",
      label: "Gestensteuerung außen/unten rechts",
      price: 99.99,
    },
    {
      value: "6805",
      label: "Drehdimmer + 1x Steckdose links (Doppelrahmen)",
      price: 85,
    },
    {
      value: "6806",
      label: "Drehdimmer + 1x Steckdose rechts (Doppelrahmen)",
      price: 85,
    },
    {
      value: "6807",
      label: "Drehdimmer + 2x Steckdose links (Dreifachrahmen)",
      price: 99,
    },
    {
      value: "6808",
      label: "Drehdimmer + 2x Steckdose rechts (Dreifachrahmen)",
      price: 99,
    },
    {
      value: "6809",
      label: "Drehdimmer + 1x Steckdose + 1x USB links (Dreifachrahmen)",
      price: 129,
    },
    {
      value: "6810",
      label: "Drehdimmer + 1x Steckdose + 1x USB rechts (Dreifachrahmen)",
      price: 129,
    },
  ];

  const renderOptions = () => {
    if (isSidedCabinet) {
      return sidedSensorSwitchOptions;
    } else {
      return sensorSwitchOptions;
    }
  };

  useEffect(() => {
    const matchedOption = renderOptions().find((item) => item.value === sensor);
    if (matchedOption) {
      dispatch(setSensorFiyatı(matchedOption.price));
      dispatch(setSensor(matchedOption.value));
    }
  }, [sensor]);

  useEffect(() => {
    dispatch(setSensorFiyatı(0));
    dispatch(setSensor("250"));
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="24">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Schalter/Sensor/
          <br />
          Dimmer
        </Typography>
      </RenderModal>

      <input name="id[20]" value={renderOptions().find((item) => item.value === sensor)?.value || "250"} />
      <Select onChange={handleChange} value={sensor} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        {renderOptions().map((item) => (
          <MenuItem value={item.value}>
            {item.label} {item.price > 0 ? "+ €" + item.price : ""}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default SensorAnahtar;
