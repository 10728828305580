import React from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setAhsapZemin, setShelfCount } from "../../../store/cabinet";
import { setAhsapZeminFiyatı, setCamRafFiyatı } from "../../../store/cabinetFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const AhsapZeminler = () => {
  const dispatch = useDispatch();
  const { ahsapZemin } = useSelector((state) => state.cabinet);

  const handleChange = (e) => {
    dispatch(setAhsapZemin(e.target.value));
    dispatch(
      setAhsapZeminFiyatı(e.target.value === 1 ? 15 : e.target.value === 2 ? 30 : e.target.value === 3 ? 45 : e.target.value === 4 ? 60 : e.target.value === 5 ? 70 : e.target.value === 6 ? 80 : 0)
    );
    dispatch(setShelfCount(0));
    dispatch(setCamRafFiyatı(0));
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Holzböden
      </Typography>

      <input
        name="id[211]"
        value={ahsapZemin == 0 ? 2178 : ahsapZemin == 1 ? 2179 : ahsapZemin == 2 ? 2180 : ahsapZemin == 3 ? 2181 : ahsapZemin == 4 ? 2182 : ahsapZemin == 5 ? 2183 : ahsapZemin == 6 ? 2184 : null}
      />

      <Select onChange={handleChange} value={ahsapZemin} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>Ohne Hölzböden</MenuItem>
        <MenuItem value={1}>1 x Holzboden + 15,00 €</MenuItem>
        <MenuItem value={2}>2 x Holzboden + 30,00 €</MenuItem>
        <MenuItem value={3}>3 x Holzboden + 45,00 €</MenuItem>
        <MenuItem value={4}>4 x Holzboden + 60,00 €</MenuItem>
        <MenuItem value={5}>5 x Holzboden + 70,00 €</MenuItem>
        <MenuItem value={6}>6 x Holzboden + 80,00 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default AhsapZeminler;
