import React from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";
import { EnumCabinetMirrors } from "../../../../store/cabinetMirror";

export const EnumSocketUSBBase = {
  LEFT: "left",
  MID: "mid",
  RIGHT: "right",
};

export function SocketUSB({ base }) {
  const { nodes: oneSocketNodes, materials: oneSocketMaterials } = useGLTF("/socketUSB/oneSocket.glb");
  const { nodes: twoSocketNodes, materials: twoSocketMaterials } = useGLTF("/socketUSB/twoSocket.glb");
  const { nodes: oneSocketOneUSBNodes, materials: oneSocketOneUSBMaterials } = useGLTF("/socketUSB/oneSocketOneUSB.glb");
  const { nodes: oneUSBNodes, materials: oneUSBMaterials } = useGLTF("/socketUSB/oneUSB.glb");
  const { nodes: twoSocketOneUSBNodes, materials: twoSocketOneUSBMaterials } = useGLTF("/socketUSB/twoSocketOneUSB.glb");

  const { socketUsb, cabinetMirrorWidth, cabinetMirrorHeight, centerCompartmentWidth } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);

  const isCentered = [EnumCabinetMirrors.LAS_VEGAS, EnumCabinetMirrors.MUNCHEN_5].includes(selectedMirror);
  const widthAdjuster = isCentered && centerCompartmentWidth < 220 ? 0.2 : 0.1;
  const oneSocket = () => (
    <group scale={[0.05, 0.1 - ((cabinetMirrorHeight - 400) / 250) * 0.015, widthAdjuster - ((cabinetMirrorWidth - 400) / 400) * 0.005]} rotation-y={-Math.PI * 0.5} position={[0, -0.3, -0.07]}>
      <mesh castShadow receiveShadow geometry={oneSocketNodes.oneSocket_1.geometry} material={oneSocketMaterials["midnight chrome.004"]} />
      <mesh castShadow receiveShadow geometry={oneSocketNodes.oneSocket_2.geometry} material={oneSocketMaterials["plastik_weiss.004"]} />
      <mesh castShadow receiveShadow geometry={oneSocketNodes.oneSocket_3.geometry} material={oneSocketMaterials["plastik_weiss.003"]} />
    </group>
  );
  const twoSocket = () => (
    <group scale={[0.05, 0.1 - ((cabinetMirrorHeight - 400) / 250) * 0.015, widthAdjuster - ((cabinetMirrorWidth - 400) / 400) * 0.005]} rotation-y={-Math.PI * 0.5} position={[0, -0.3, -0.07]}>
      <mesh castShadow receiveShadow geometry={twoSocketNodes.twoSocket_1.geometry} material={twoSocketMaterials["midnight chrome.009"]} />
      <mesh castShadow receiveShadow geometry={twoSocketNodes.twoSocket_2.geometry} material={twoSocketMaterials["plastik_weiss.009"]} />
      <mesh castShadow receiveShadow geometry={twoSocketNodes.twoSocket_3.geometry} material={twoSocketMaterials["plastik_weiss.010"]} />
      <mesh castShadow receiveShadow geometry={twoSocketNodes.twoSocket_4.geometry} material={twoSocketMaterials["midnight chrome.011"]} />
      <mesh castShadow receiveShadow geometry={twoSocketNodes.twoSocket_5.geometry} material={twoSocketMaterials["plastik_weiss.011"]} />
      <mesh castShadow receiveShadow geometry={twoSocketNodes.twoSocket_6.geometry} material={twoSocketMaterials["plastik_weiss.012"]} />
    </group>
  );
  const oneSocketOneUSB = () => (
    <group scale={[0.05, 0.1 - ((cabinetMirrorHeight - 400) / 250) * 0.015, widthAdjuster - ((cabinetMirrorWidth - 400) / 400) * 0.005]} rotation-y={-Math.PI * 0.5} position={[0, -0.3, -0.07]}>
      <mesh castShadow receiveShadow geometry={oneSocketOneUSBNodes.oneSocketOneUSB_1.geometry} material={oneSocketOneUSBMaterials["midnight chrome"]} />
      <mesh castShadow receiveShadow geometry={oneSocketOneUSBNodes.oneSocketOneUSB_2.geometry} material={oneSocketOneUSBMaterials["Material #2114879240"]} />
      <mesh castShadow receiveShadow geometry={oneSocketOneUSBNodes.oneSocketOneUSB_3.geometry} material={oneSocketOneUSBMaterials["15 - Default"]} />
      <mesh castShadow receiveShadow geometry={oneSocketOneUSBNodes.oneSocketOneUSB_4.geometry} material={oneSocketOneUSBMaterials.plastik_weiss} />
    </group>
  );
  const oneUSB = () => (
    <group scale={[0.05, 0.1 - ((cabinetMirrorHeight - 400) / 250) * 0.015, widthAdjuster - ((cabinetMirrorWidth - 400) / 400) * 0.005]} rotation-y={-Math.PI * 0.5} position={[0, -0.3, -0.07]}>
      <mesh castShadow receiveShadow geometry={oneUSBNodes.oneUSB_1.geometry} material={oneUSBMaterials["Material #2114879240.002"]} />
      <mesh castShadow receiveShadow geometry={oneUSBNodes.oneUSB_2.geometry} material={oneUSBMaterials["15 - Default.004"]} />
      <mesh castShadow receiveShadow geometry={oneUSBNodes.oneUSB_3.geometry} material={oneUSBMaterials["plastik_weiss.002"]} />
      <mesh castShadow receiveShadow geometry={oneUSBNodes.oneUSB_4.geometry} material={oneUSBMaterials["plastik_weiss.001"]} />
    </group>
  );
  const twoSocketOneUSB = () => (
    <group scale={[0.05, 0.1 - ((cabinetMirrorHeight - 400) / 250) * 0.015, widthAdjuster - ((cabinetMirrorWidth - 400) / 400) * 0.005]} rotation-y={-Math.PI * 0.5} position={[0, -0.3, -0.07]}>
      <mesh castShadow receiveShadow geometry={twoSocketOneUSBNodes.twoSocketOneUSB_1.geometry} material={twoSocketOneUSBMaterials["Material #2114879240.019"]} />
      <mesh castShadow receiveShadow geometry={twoSocketOneUSBNodes.twoSocketOneUSB_2.geometry} material={twoSocketOneUSBMaterials["15 - Default.038"]} />
      <mesh castShadow receiveShadow geometry={twoSocketOneUSBNodes.twoSocketOneUSB_3.geometry} material={twoSocketOneUSBMaterials["plastik_weiss.019"]} />
      <mesh castShadow receiveShadow geometry={twoSocketOneUSBNodes.twoSocketOneUSB_4.geometry} material={twoSocketOneUSBMaterials["plastik_weiss.020"]} />
      <mesh castShadow receiveShadow geometry={twoSocketOneUSBNodes.twoSocketOneUSB_5.geometry} material={twoSocketOneUSBMaterials["midnight chrome.013"]} />
      <mesh castShadow receiveShadow geometry={twoSocketOneUSBNodes.twoSocketOneUSB_6.geometry} material={twoSocketOneUSBMaterials["plastik_weiss.013"]} />
      <mesh castShadow receiveShadow geometry={twoSocketOneUSBNodes.twoSocketOneUSB_7.geometry} material={twoSocketOneUSBMaterials["plastik_weiss.014"]} />
      <mesh castShadow receiveShadow geometry={twoSocketOneUSBNodes.twoSocketOneUSB_8.geometry} material={twoSocketOneUSBMaterials["midnight chrome.015"]} />
      <mesh castShadow receiveShadow geometry={twoSocketOneUSBNodes.twoSocketOneUSB_9.geometry} material={twoSocketOneUSBMaterials["plastik_weiss.015"]} />
      <mesh castShadow receiveShadow geometry={twoSocketOneUSBNodes.twoSocketOneUSB_10.geometry} material={twoSocketOneUSBMaterials["plastik_weiss.016"]} />
    </group>
  );

  const isMID = base === EnumSocketUSBBase.MID;
  const isLEFT = base === EnumSocketUSBBase.LEFT;
  const isRIGHT = base === EnumSocketUSBBase.RIGHT;

  // eslint-disable-next-line default-case
  switch (true) {
    case socketUsb === "754":
      return null;
    case isLEFT && socketUsb === "755":
      return oneSocket();
    case isRIGHT && socketUsb === "756":
      return oneSocket();
    case isMID && socketUsb === "9666":
      return oneSocket();
    case isLEFT && socketUsb === "758":
      return twoSocket();
    case isMID && socketUsb === "9667":
      return twoSocket();
    case isRIGHT && socketUsb === "757":
      return twoSocket();
    case (isLEFT || isRIGHT) && socketUsb === "759":
      return oneSocket();
    case (isLEFT || isRIGHT) && socketUsb === "760":
      return twoSocket();
    case isLEFT && socketUsb === "6793":
      return oneUSB();
    case isMID && socketUsb === "9669":
      return oneUSB();
    case isRIGHT && socketUsb === "6794":
      return oneUSB();
    case isLEFT && socketUsb === "6795":
      return oneSocketOneUSB();
    case isMID && socketUsb === "9670":
      return oneSocketOneUSB();
    case isRIGHT && socketUsb === "6796":
      return oneSocketOneUSB();
    case isLEFT && socketUsb === "6797":
      return twoSocketOneUSB();
    case isMID && socketUsb === "9671":
      return twoSocketOneUSB();
    case isRIGHT && socketUsb === "6798":
      return twoSocketOneUSB();
    case socketUsb === "9665":
      return isLEFT ? twoSocket() : isRIGHT ? twoSocket() : isMID ? oneUSB() : null;
    case (isLEFT || isRIGHT || isMID) && socketUsb === "9668":
      return twoSocket();
    default:
      return null;
  }
}
