import React, { useEffect, useState } from "react";
import { Stack, Slider, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EnumMirrors, changeMirrorHeight, setMakeupMirror } from "../../../store/mirrors";

const Yukseklik = () => {
  const { selectedMirror, mirrorHeight, mirrorWidth, selectedMirrorExcelIndex } = useSelector((state) => state.mirror);
  const dispatch = useDispatch();
  const [sliderValue, setSliderValue] = useState(mirrorHeight);
  const isKristall = [EnumMirrors.STARK_RAHMENLOS_5MM, EnumMirrors.SILBER_RAHMENLOS_3MM, EnumMirrors.RAHMENLOSER_6_MM].includes(selectedMirror);
  const isPaintedMirror = [EnumMirrors.F620TL, EnumMirrors.F621TL, EnumMirrors.F622TL, EnumMirrors.F623TL].includes(selectedMirror);
  const isF597L4K = selectedMirror == EnumMirrors.F597L4K;
  const isF610L4K = selectedMirror == EnumMirrors.F610L4K;
  const isWunschdekor = [EnumMirrors.WUNSCHDEKOR].includes(selectedMirror);

  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    if (isKristall) {
      dispatch(setMakeupMirror(0));
      setInput(200);
      dispatch(changeMirrorHeight(200));
    } else if (isF610L4K) {
      setInput(500);
      dispatch(changeMirrorHeight(500));
    } else {
      setInput(400);
      dispatch(changeMirrorHeight(400));
    }
  }, [selectedMirror, selectedMirrorExcelIndex]);

  useEffect(() => {
    if (isF610L4K && mirrorWidth >= mirrorHeight - 100) {
      dispatch(changeMirrorHeight(mirrorWidth + 100));
      setInput(mirrorWidth + 100);
    }
  }, [mirrorHeight, mirrorWidth]);

  useEffect(() => {
    setSliderValue(mirrorHeight);
  }, [selectedMirror, mirrorHeight]);

  const MAX_VALUE = isWunschdekor || isF610L4K || isPaintedMirror || isKristall ? 2500 : isF597L4K ? 2200 : 1500;
  const MIN_VALUE = isKristall ? 200 : isF610L4K ? 500 : 400;
  const DEFAULT_VALUE = sliderValue;
  const [input, setInput] = useState(DEFAULT_VALUE);

  const kareYukseklik = [
    {
      value: "1807",
      inputValue: "200",
    },
    {
      value: "1052",
      inputValue: "250",
    },
    {
      value: "1053",
      inputValue: "300",
    },
    {
      value: "1054",
      inputValue: "350",
    },
    {
      value: "53",
      inputValue: "400",
    },
    {
      value: "54",
      inputValue: "450",
    },
    {
      value: "55",
      inputValue: "500",
    },
    {
      value: "56",
      inputValue: "550",
    },
    {
      value: "57",
      inputValue: "600",
    },
    {
      value: "58",
      inputValue: "650",
    },
    {
      value: "59",
      inputValue: "700",
    },
    {
      value: "60",
      inputValue: "750",
    },
    {
      value: "61",
      inputValue: "800",
    },
    {
      value: "62",
      inputValue: "850",
    },
    {
      value: "63",
      inputValue: "900",
    },
    {
      value: "64",
      inputValue: "950",
    },
    {
      value: "65",
      inputValue: "1000",
    },
    {
      value: "66",
      inputValue: "1050",
    },
    {
      value: "67",
      inputValue: "1100",
    },
    {
      value: "68",
      inputValue: "1150",
    },
    {
      value: "69",
      inputValue: "1200",
    },
    {
      value: "70",
      inputValue: "1250",
    },
    {
      value: "71",
      inputValue: "1300",
    },
    {
      value: "72",
      inputValue: "1350",
    },
    {
      value: "73",
      inputValue: "1400",
    },
    {
      value: "74",
      inputValue: "1450",
    },
    {
      value: "75",
      inputValue: "1500",
    },
    {
      value: "76",
      inputValue: "1550",
    },
    {
      value: "77",
      inputValue: "1600",
    },
    {
      value: "78",
      inputValue: "1650",
    },
    {
      value: "79",
      inputValue: "1700",
    },
    {
      value: "80",
      inputValue: "1750",
    },
    {
      value: "81",
      inputValue: "1800",
    },
    {
      value: "221",
      inputValue: "1850",
    },
    {
      value: "222",
      inputValue: "1900",
    },
    {
      value: "223",
      inputValue: "1950",
    },
    {
      value: "224",
      inputValue: "2000",
    },
    {
      value: "225",
      inputValue: "2050",
    },
    {
      value: "226",
      inputValue: "2100",
    },
    {
      value: "227",
      inputValue: "2150",
    },
    {
      value: "228",
      inputValue: "2200",
    },
    {
      value: "229",
      inputValue: "2250",
    },
    {
      value: "230",
      inputValue: "2300",
    },
    {
      value: "231",
      inputValue: "2350",
    },
    {
      value: "232",
      inputValue: "2400",
    },
    {
      value: "233",
      inputValue: "2450",
    },
    {
      value: "234",
      inputValue: "2500",
    },
  ];

  const ovalYukseklik = [
    { value: "1865", inputValue: "400" },
    { value: "1866", inputValue: "450" },
    { value: "1867", inputValue: "500" },
    { value: "1868", inputValue: "550" },
    { value: "1869", inputValue: "600" },
    { value: "1870", inputValue: "650" },
    { value: "1871", inputValue: "700" },
    { value: "1872", inputValue: "750" },
    { value: "1873", inputValue: "800" },
    { value: "1874", inputValue: "850" },
    { value: "1875", inputValue: "900" },
    { value: "1876", inputValue: "950" },
    { value: "1877", inputValue: "1000" },
    { value: "1878", inputValue: "1050" },
    { value: "1879", inputValue: "1100" },
    { value: "1880", inputValue: "1150" },
    { value: "1881", inputValue: "1200" },
    { value: "1882", inputValue: "1250" },
    { value: "1883", inputValue: "1300" },
    { value: "1884", inputValue: "1350" },
    { value: "1885", inputValue: "1400" },
    { value: "1886", inputValue: "1450" },
    { value: "1887", inputValue: "1500" },
    { value: "1888", inputValue: "1550" },
    { value: "1889", inputValue: "1600" },
    { value: "1890", inputValue: "1650" },
    { value: "1891", inputValue: "1700" },
    { value: "1892", inputValue: "1750" },
    { value: "1893", inputValue: "1800" },
    { value: "1894", inputValue: "1850" },
    { value: "1895", inputValue: "1900" },
    { value: "1896", inputValue: "1950" },
    { value: "1897", inputValue: "2000" },
    { value: "1898", inputValue: "2050" },
    { value: "1899", inputValue: "2100" },
    { value: "1900", inputValue: "2150" },
    { value: "1901", inputValue: "2200" },
    { value: "1902", inputValue: "2250" },
    { value: "1903", inputValue: "2300" },
    { value: "1904", inputValue: "2350" },
    { value: "1905", inputValue: "2400" },
    { value: "1906", inputValue: "2450" },
    { value: "1907", inputValue: "2500" },
  ];
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Höhe in mm
        </Typography>

        {isF597L4K || isF610L4K ? (
          <>
            <input type="hidden" name="id[165]" value={ovalYukseklik.find((k) => parseInt(k.inputValue) === input)?.value} />
          </>
        ) : (
          <>
            <input type="hidden" name="id[62]" value="527" />
            <input type="hidden" class="form-control" id="txt62" name="txt_527" value={sliderValue} />
            <input type="hidden" name="id[8]" value={kareYukseklik.find((k) => parseInt(k.inputValue) === input)?.value} />
          </>
        )}

        <TextField
          value={input}
          type="number"
          onFocus={(e) => {
            if (e.target.value.length == 0) {
              setInput(sliderValue);
              e.target.style.color = "#000";
              setAlertMessage(false);
            }
          }}
          onChange={(e) => {
            var value = parseInt(e.target.value);
            setInput(value);
            if (!value) {
              dispatch(changeMirrorHeight(MIN_VALUE));
            } else {
              if (value > MAX_VALUE || value < MIN_VALUE) {
                dispatch(changeMirrorHeight(value > MAX_VALUE ? MAX_VALUE : MIN_VALUE));
                e.target.style.color = "tomato";
                setAlertMessage(true);
              } else {
                dispatch(changeMirrorHeight(value));
                e.target.style.color = "#000";
                setAlertMessage(false);
              }
            }
          }}
          sx={{
            height: "2.2rem",
            width: "100%",
            ".MuiInputBase-input": {
              padding: "8px 8px 8px 18px",
              fontSize: 12,
            },
          }}
        />
      </Stack>
      {alertMessage ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            position: "relative",
            top: -10,
          }}
        >
          <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem", mt: 0 }}></Typography>
          <small
            style={{
              color: "red",
            }}
          >
            Bitte geben Sie den Wert für die Höhe (nur Ziffern) in Millimetern ein. Dieser muss zwischen {MIN_VALUE} und {MAX_VALUE} (mm) betragen.
          </small>
        </Stack>
      ) : null}
    </>
  );
};

export default Yukseklik;
