import React from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCiftSensorPos } from "../../../store/mirrors";

const CiftSensorPos = () => {
  const dispatch = useDispatch();
  const { ciftSensorPos } = useSelector((state) => state.mirror);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Position Sensor
      </Typography>
      {/* <input name="id[141]" value={dikeySensorPos == 0 ? 1321 : dikeySensorPos == 1 ? 1322 : dikeySensorPos == 2 ? 1323 : null} /> */}
      <Select value={ciftSensorPos} onChange={(e) => dispatch(setCiftSensorPos(e.target.value))} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>Unten Links</MenuItem>
        <MenuItem value={1}>Unten Mittig</MenuItem>
        <MenuItem value={2}>Unten Rechts</MenuItem>
      </Select>
    </Stack>
    // }
  );
};

export default CiftSensorPos;
