import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EnumCabinetMirrors, changeCabinetMirrorWidth, setDoorNumber } from "../../../../store/cabinetMirror";
import { setDoorFiyatı } from "../../../../store/cabinetMirrorFee";
const CenteredDoors = () => {
  const dispatch = useDispatch();
  const { selectedMirror } = useSelector((state) => state.mirror);
  const { doorNumber, cabinetMirrorWidth } = useSelector((state) => state.cabinetMirror);
  const handleChange = (e) => {
    dispatch(setDoorNumber(e.target.value));
    dispatch(setDoorFiyatı(e.target.value === 4 ? 90.0 : e.target.value === 6 ? 140.0 : 0));
  };

  const isSidedCabinet =
    selectedMirror === EnumCabinetMirrors.MUNCHEN_4 ||
    selectedMirror === EnumCabinetMirrors.MALAGA ||
    selectedMirror === EnumCabinetMirrors.SARAGOSSA ||
    selectedMirror === EnumCabinetMirrors.KONSTANZ ||
    selectedMirror === EnumCabinetMirrors.DERBY ||
    selectedMirror === EnumCabinetMirrors.GENT ||
    selectedMirror === EnumCabinetMirrors.VENEZIA ||
    selectedMirror === EnumCabinetMirrors.ALMERIA ||
    selectedMirror === EnumCabinetMirrors.GRADO ||
    selectedMirror === EnumCabinetMirrors.VILLACH ||
    selectedMirror === EnumCabinetMirrors.VERONICA_3 ||
    selectedMirror === EnumCabinetMirrors.VERONICA_4 ||
    selectedMirror === EnumCabinetMirrors.PANDORA_3 ||
    selectedMirror === EnumCabinetMirrors.PANDORA_4 ||
    selectedMirror === EnumCabinetMirrors.KATHERINE_3 ||
    selectedMirror === EnumCabinetMirrors.KATHERINE_4;

  const isCenteredCabinet = selectedMirror === EnumCabinetMirrors.MUNCHEN_5 || selectedMirror === EnumCabinetMirrors.LAS_VEGAS;

  const notFixedDoors = !isSidedCabinet && !isCenteredCabinet;

  useEffect(() => {
    if (!notFixedDoors) {
      dispatch(setDoorNumber(2));
      dispatch(setDoorFiyatı(0));
    } else {
      dispatch(setDoorNumber(1));
      dispatch(setDoorFiyatı(0));
    }
  }, [selectedMirror]);

  const renderInputDataValue = () => {
    switch (doorNumber) {
      case 2:
        return 2580;
      case 4:
        return 2581;
      case 6:
        return 2582;
    }
  };

  useEffect(() => {
    if (cabinetMirrorWidth > 1300) {
      dispatch(setDoorNumber(4));
      dispatch(setDoorFiyatı(90));
    } else {
      dispatch(setDoorNumber(2));
      dispatch(setDoorFiyatı(0));
    }
  }, [cabinetMirrorWidth]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Türen
      </Typography>

      <input name="id[22]" value={renderInputDataValue()} />

      <Select onChange={handleChange} value={doorNumber} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem disabled={cabinetMirrorWidth < 600 || cabinetMirrorWidth > 1300} value={2}>
          2 Türen (bei Breite 600mm-1300mm)
        </MenuItem>
        <MenuItem disabled={cabinetMirrorWidth < 1200} value={4}>
          4 Türen (bei Breite 1200mm-2500mm) + 90,00 €
        </MenuItem>
        <MenuItem disabled={cabinetMirrorWidth < 1800} value={6}>
          6 Türen (bei Breite 1800mm-2500mm) + 140,00 €
        </MenuItem>
      </Select>
    </Stack>
  );
};

export default CenteredDoors;
