import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React from "react";
import { useSelector } from "react-redux";

const Munih2Mirror = () => {
  const { nodes, materials } = useGLTF("/newModels/munih2.glb");
  const mirror = React.useRef();
  const mirror1 = React.useRef();
  const { camCikintiUst, camCikintiAlt } = useSelector((state) => state.cabinetMirror);
  useFrame(() => {
    if (mirror.current && mirror1.current) {
      mirror.current.scale.y = 1.03 - camCikintiUst * 0.0013 - camCikintiAlt * 0.0013;
      mirror1.current.scale.y = 1.03 - camCikintiUst * 0.0013 - camCikintiAlt * 0.0013;
      mirror.current.position.y = 0 - camCikintiUst * 0.0005 + camCikintiAlt * 0.0005;
      mirror1.current.position.y = 0 - camCikintiUst * 0.0005 + camCikintiAlt * 0.0005;
    }
  });
  return (
    <>
      <mesh geometry={nodes.doorLeft_2.geometry} material={materials["glass.003"]} scale-y={1.07} />
      <mesh ref={mirror} geometry={nodes.doorLeft_2.geometry} material={materials["mirror.004"]} />
      <mesh ref={mirror1} geometry={nodes.doorLeft_3.geometry} material={materials["mirror.004"]} />
    </>
  );
};

export default Munih2Mirror;
