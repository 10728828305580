import React, { useEffect } from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeKurulumCercevesi } from "../../../store/cabinet";
import { setKurulumCercevesiFee } from "../../../store/cabinetFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";
const KurulumCercevesi = () => {
  const dispatch = useDispatch();
  const { kurulumCercevesi } = useSelector((state) => state.cabinet);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal title="32">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Einbaurahmen Vorab
        </Typography>
      </RenderModal>
      <input name="id[117]" value={!kurulumCercevesi ? "993" : kurulumCercevesi ? "994" : null} />
      <Select
        onChange={(e) => {
          dispatch(changeKurulumCercevesi(e.target.value));
          dispatch(setKurulumCercevesiFee(e.target.value == true ? 49.99 : 0));
        }}
        value={kurulumCercevesi}
        sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
      >
        <MenuItem value={false}>---</MenuItem>
        <MenuItem value={true}>Ja + 49,99€</MenuItem>
      </Select>
    </Stack>
  );
};

export default KurulumCercevesi;
