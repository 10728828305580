import React, { useState } from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setCustomerComment } from "../../../store/mirrors";

const YorumYapın = () => {
  const [comment, setComment] = useState();
  const dispatch = useDispatch();

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Stack>
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Ihre Bemerkung
        </Typography>
      </Stack>

      <input type="hidden" name="id[139]" value="1312" />
      <input type="hidden" class="form-control" name="txt_1312" value={comment} />

      <TextField
        label="Ihre Bemerkung"
        onChange={(e) => {
          setComment(e.target.value);
          dispatch(setCustomerComment(e.target.value));
        }}
        sx={{ flexGrow: "1", fontSize: "0.7rem !important" }}
      />
    </Stack>
  );
};

export default YorumYapın;
