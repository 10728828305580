import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useDispatch, useSelector } from "react-redux";
import { changeKareAynaModel, EnumMirrors } from "../../../store/mirrors";
import CentimeterHeightLines from "../../OrtakComponents/CM/CentimeterHeightLines";
import CentimeterWidthLines from "../../OrtakComponents/CM/CentimeterWidthLines";
import CamRaf from "./KareAynaComponents/CamRaf";
import { MakyajAynasi2 } from "../Mirrors/MakyajAynasi2";
import Wunschdekor from "./KareAynaComponents/Wunschdekor";
import ObjelerLeft from "./KareAynaComponents/objeler/ObjelerLeft";
import ObjelerMid from "./KareAynaComponents/objeler/ObjelerMid";
import ObjelerRight from "./KareAynaComponents/objeler/ObjelerRight";
import ObjelerTop from "./KareAynaComponents/objeler/ObjelerTop";

export function Kare(props) {
  const { nodes, materials } = useGLTF("/rectangle-mirror-rev01.glb");
  const rectangle = useRef();
  const buzluCam = useRef();
  const dispatch = useDispatch();

  const {
    lightColor,
    lightIsOpen,
    mirrorWidth,
    mirrorHeight,
    selectedDisableMirrors,
    analogWatch,
    digitalWatch,
    heightCmVisible,
    widthCmVisible,
    squareMirrorSelectedItem,
    makeupMirrorPos,
    kareAynaModel,
    makyajAynasiYandanUzaklik,
    makyajAynasiAlttanUzaklik,
    raf,
    selectedMirror,
    watchPos,
    soket,
    isiSensor,
    makeupMirror,
    makeupMirrorSensor,
    lightColorIndex,
    ciftSensorPos,
    dikeySensor,
    yataySensor,
  } = useSelector((state) => state.mirror);

  const isWunschdekor = [EnumMirrors.WUNSCHDEKOR].includes(selectedMirror);
  const [backLightShow, setBacklightShow] = useState(true);

  const visibleSelectedItems = [
    "siyah_çerçeve-kare",
    "dijitalSAAT-kare",
    "AKREP_YELKOVAN-kare",
    "sensor-yatay",
    "sensor-kare",
    "pleksi_ışık001-kare",
    "PLESİ_IŞIK_METAL-kare",
    "Alt_+_Üst",
    "Sağ_+_Sol",
    "Sağ_+_Üst_+_Sol",
    "Üst",
    "Sağ_+_Sol_+_Uzun",
    "Sağ_+_Sol_+_Üst_+_Dış",
    "Full_+_Dış",
    "Full_+_İnce",
    "Full_+_Kalın",
    "Full_+_İnce_+_İç",
    "Arka",
    "Kristallspiegel",
  ];

  useEffect(() => {
    visibleSelectedItems.map((item) => {
      rectangle.current.children.map((child) => {
        if (child.name === item) {
          child.visible = false;
        }
      });
    });
  }, []);

  useEffect(() => {
    if (selectedDisableMirrors.length > 0) {
      selectedDisableMirrors.map((mesh) => {
        rectangle.current.children.map((child) => {
          if (child.name === mesh.name) {
            child.visible = mesh.isVisible;
          }
        });
      });
    }
  }, [selectedDisableMirrors]);

  useEffect(() => {
    if (lightIsOpen) {
      props.pandoraLightHandler(true);
    } else {
      props.pandoraLightHandler(false);
    }
  }, [lightIsOpen]);

  function disableItem(name, element) {
    const items = [
      "Pandora",
      "Üst",
      "Sağ_+_Sol_+_Uzun",
      "Alt_+_Üst",
      "Sağ_+_Sol",
      "Sağ_+_Sol_+_Üst_+_Dış",
      "Sağ_+_Üst_+_Sol",
      "Full_+_Dış",
      "Full_+_İnce",
      "Full_+_Kalın",
      "Full_+_İnce_+_İç",
      "Arka",
      "Kristallspiegel",
    ];

    if (name === "Pandora") {
      props.showPandoraHandler(true);
    } else {
      props.showPandoraHandler(false);
    }

    if (name === "Arka") {
      setBacklightShow(true);
    } else {
      setBacklightShow(false);
    }

    rectangle.current.children.forEach((child) => {
      if (items.includes(child.name)) {
        child.visible = false;
      }
      if (child.name === name) {
        child.visible = true;
        dispatch(changeKareAynaModel(name));
      }
    });
  }

  useEffect(() => {
    disableItem(squareMirrorSelectedItem);
  }, [squareMirrorSelectedItem]);

  useEffect(() => {
    if (buzluCam.current) {
      buzluCam.current.material.roughness = 0.25;
    }
  });

  const scaleMultiplier = 0.6; // Kare aynadaki scale hatasını düzeltmek için

  // Positions
  const rafExists = raf === 1 || raf === 2;
  const [widthOfWidth, setWidthOfWidth] = useState(-47 - mirrorWidth * 0.565);
  const [leftOfWidth, setLeftOfWidth] = useState(-9 + mirrorWidth * 1.16);
  const [heightOfHeight, setHeightOfHeight] = useState(0 + mirrorHeight * 1.01);
  const [topOfHeight, setTopOfHeight] = useState(110 - mirrorHeight * 0.3);
  const [positionOfWidth, setPositionOfWidth] = useState(-0.87 + mirrorHeight * 0.0021);
  const [positionOfHeight, setPositionOfHeight] = useState(-0.45 + mirrorWidth * 0.00175);

  const watchTopCheck =
    (analogWatch && watchPos == 3) ||
    (analogWatch && watchPos == 4) ||
    (analogWatch && watchPos == 5) ||
    (digitalWatch && watchPos == 3) ||
    (digitalWatch && watchPos == 4) ||
    (digitalWatch && watchPos == 5);

  const watchBottomCheck = !watchTopCheck && (analogWatch || digitalWatch);

  const anyObject =
    isiSensor === 2 ||
    isiSensor === 3 ||
    soket === 1 ||
    soket === 2 ||
    soket === 3 ||
    soket === 4 ||
    (makeupMirror == 2 && makeupMirrorSensor == 2) ||
    (lightColorIndex == 4 && ciftSensorPos == 0) ||
    yataySensor ||
    dikeySensor ||
    watchBottomCheck;

  const anyObjectExceptWatch =
    isiSensor === 2 ||
    isiSensor === 3 ||
    soket === 1 ||
    soket === 2 ||
    soket === 3 ||
    soket === 4 ||
    (makeupMirror == 2 && makeupMirrorSensor == 2) ||
    (lightColorIndex == 4 && ciftSensorPos == 0) ||
    yataySensor ||
    dikeySensor;

  const anyAndWatch = anyObjectExceptWatch && watchBottomCheck;
  const anyAndRaf = anyObject && rafExists;
  const anyAndWatchAndRaf = anyObjectExceptWatch && watchBottomCheck && rafExists;

  useEffect(() => {
    const newyork = kareAynaModel == "Full_+_Dış";
    const bolnuevo = kareAynaModel == "Full_+_İnce";
    const santaCruz = kareAynaModel == "Sağ_+_Sol_+_Üst_+_Dış";
    if (newyork) {
      setWidthOfWidth(-10 + mirrorWidth * 1.235);
      setLeftOfWidth(-45 - mirrorWidth * 0.602);
      setHeightOfHeight(-5 + mirrorHeight * 1.1375);
      setTopOfHeight(102 - mirrorHeight * 0.76);
      setPositionOfWidth(-0.87 + mirrorHeight * 0.0023);
      setPositionOfHeight(-0.45 + mirrorWidth * 0.0019);
    } else if (bolnuevo) {
      setWidthOfWidth(-10 + mirrorWidth * 1.18);
      setLeftOfWidth(-45 - mirrorWidth * 0.575);
      setHeightOfHeight(-5 + mirrorHeight * 1.0595);
      setTopOfHeight(102 - mirrorHeight * 0.721);
      setPositionOfWidth(-0.87 + mirrorHeight * 0.00215);
      setPositionOfHeight(-0.45 + mirrorWidth * 0.00185);
    } else if (santaCruz) {
      setWidthOfWidth(-5 + mirrorWidth * 1.22);
      setLeftOfWidth(-50 - mirrorWidth * 0.595);
      setHeightOfHeight(4 + mirrorHeight * 1.064);
      setTopOfHeight(90 - mirrorHeight * 0.7425);
      setPositionOfWidth(-0.87 + mirrorHeight * 0.00225);
      setPositionOfHeight(-0.45 + mirrorWidth * 0.0019);
    } else {
      setWidthOfWidth(-9 + mirrorWidth * 1.16);
      setLeftOfWidth(-45 - mirrorWidth * 0.565);
      setHeightOfHeight(-4 + mirrorHeight * 1.019);
      setTopOfHeight(102 - mirrorHeight * 0.702);
      setPositionOfWidth(-0.87 + mirrorHeight * 0.0021);
      setPositionOfHeight(-0.45 + mirrorWidth * 0.0018);
    }
  }, [kareAynaModel, mirrorHeight, mirrorWidth]);

  const leftLimit = useRef(null);
  const rightLimit = useRef(null);
  const topLimit = useRef(null);
  const bottomLimit = useRef(null);

  const [makyajPosition, setMakyajPosition] = useState([0, 0, 0.09]);

  useEffect(() => {
    const objectsAreHere = leftLimit.current && rightLimit.current && topLimit.current && bottomLimit.current;

    if (objectsAreHere) {
      const limitLeft = leftLimit.current.position.x;
      const limitRight = rightLimit.current.position.x;
      const limitTop = topLimit.current.position.y;
      const limitBottom = bottomLimit.current.position.y;

      let makX;
      let makY;
      let makZ = 0.09;

      const degisimX = (makyajAynasiYandanUzaklik - 180) / (mirrorWidth - 360);
      const degisimY = (makyajAynasiAlttanUzaklik - 180) / (mirrorHeight - 360);
      if (makeupMirrorPos == 1) {
        makX = limitLeft + degisimX * (limitRight - limitLeft);
        if (makX > limitRight) {
          makX = limitRight;
        }
        makY = limitBottom + degisimY * (limitTop - limitBottom);
        if (makY > limitTop) {
          makY = limitTop;
        }
      } else {
        makX = limitRight - degisimX * (limitRight - limitLeft);
        if (makX < limitLeft) {
          makX = limitLeft;
        }
        makY = limitBottom + degisimY * (limitTop - limitBottom);
        if (makY > limitTop) {
          makY = limitTop;
        }
      }
      setMakyajPosition([makX - 0.1, makY + 0.155, makZ]);
    }
  }, [
    mirrorWidth,
    mirrorHeight,
    makyajAynasiYandanUzaklik,
    makyajAynasiAlttanUzaklik,
    makeupMirrorPos,
    analogWatch,
    digitalWatch,
    raf,
    watchPos,
    soket,
    isiSensor,
    makeupMirror,
    makeupMirrorSensor,
    lightColorIndex,
    ciftSensorPos,
    dikeySensor,
    yataySensor,
  ]);

  return (
    <>
      {widthCmVisible && (
        <CentimeterWidthLines
          scale={[0.118, 0.3, 0.15]}
          textWidth={mirrorWidth > 999 ? "75px" : "70px"}
          textPadding={mirrorHeight > 999 ? "0 12px" : "0 14px"}
          textValue={mirrorWidth == 0 ? "400" : mirrorWidth}
          textSize={"1.4em"}
          textHeight="35px"
          lineLeft={leftOfWidth}
          lineWidth={widthOfWidth}
          position={[0, positionOfWidth, 0.1]}
        />
      )}
      {heightCmVisible && (
        <CentimeterHeightLines
          scale={[0.3, 0.12, 0.15]}
          textWidth={mirrorHeight > 999 ? "75px" : "70px"}
          textPadding={mirrorHeight > 999 ? "0 12px" : "0 14px"}
          textValue={mirrorHeight == 0 ? "400" : mirrorHeight}
          textSize={"1.4em"}
          textHeight="35px"
          textTop={110 - mirrorHeight * 0.3}
          lineTop={topOfHeight}
          lineHeight={heightOfHeight}
          position={[positionOfHeight, -0.3, 0.1]}
        />
      )}
      {/* MAKYAJ AYNASI LIMITLERI */}
      <group>
        {/* LEFT */}
        <mesh ref={leftLimit} position={[-0.09 - ((mirrorWidth - 400) / 400) * 0.5725, 0, 0.1]} scale={2}>
          <meshBasicMaterial transparent={true} opacity={0} color={"red"} />
          <boxGeometry args={[0.01, 0.3, 0.01]} />
        </mesh>
        {/* RIGHT */}
        <mesh ref={rightLimit} position={[0.35 + ((mirrorWidth - 400) / 400) * 0.59, 0, 0.1]} scale={2}>
          <meshBasicMaterial transparent={true} opacity={0} color={"red"} />
          <boxGeometry args={[0.01, 0.3, 0.01]} />
        </mesh>
        {/* TOP */}
        <mesh ref={topLimit} position={[0, (watchTopCheck ? -0.1 : -0.02) + ((mirrorHeight - 400) / 400 / 0.48) * 0.33, 0.1]} scale={2}>
          <meshBasicMaterial transparent={true} opacity={0} color={"red"} />
          <boxGeometry args={[0.7, 0.01, 0.01]} />
        </mesh>
        {/* BOTTOM */}
        {/* <mesh ref={bottomLimit} position={[0, (raf ? 0.17 : -0.05) - ((mirrorHeight - 400) / 400) * 0.28, 0.1]} scale={2}> */}
        <mesh
          ref={bottomLimit}
          position={[
            0,

            anyAndWatchAndRaf
              ? -0.05 - ((mirrorHeight - 400) / 400) * 0.12 + ((mirrorWidth - 400) / 400) * 0.05
              : anyAndWatch
              ? -0.15 - ((mirrorHeight - 400) / 400) * 0.12 + ((mirrorWidth - 400) / 400) * 0.05
              : anyAndRaf
              ? -0.18 - ((mirrorHeight - 400) / 400) * 0.15 + ((mirrorWidth - 400) / 400) * 0.03
              : anyObject
              ? -0.22 - ((mirrorHeight - 400) / 400) * 0.17 + ((mirrorWidth - 400) / 400) * 0.03
              : rafExists
              ? -0.22 - ((mirrorHeight - 400) / 400) * 0.17
              : -0.22 - ((mirrorHeight - 400) / 400) * 0.24,
            0.1,
          ]}
          scale={2}
        >
          <meshBasicMaterial transparent={true} opacity={0} color={"red"} />
          <boxGeometry args={[0.7, 0.01, 0.01]} />
        </mesh>
        <MakyajAynasi2 positionA={makyajPosition} />
      </group>

      <ObjelerLeft
        position={[0.13 - mirrorWidth * 0.0013 + mirrorHeight * 0.0001, raf ? -0.135 - mirrorHeight * 0.000395 + mirrorWidth * 0.00006 : -0.2 - mirrorHeight * 0.000395 + mirrorWidth * 0.00006, 0.1]}
      />
      <ObjelerMid position={[0, raf ? -0.135 - mirrorHeight * 0.000395 + mirrorWidth * 0.00006 : -0.2 - mirrorHeight * 0.000395 + mirrorWidth * 0.00006, 0.1]} />
      <ObjelerRight
        position={[-0.13 + mirrorWidth * 0.0013 - mirrorHeight * 0.0001, raf ? -0.135 - mirrorHeight * 0.000395 + mirrorWidth * 0.00006 : -0.2 - mirrorHeight * 0.000395 + mirrorWidth * 0.00006, 0.1]}
      />
      <ObjelerTop />

      {/* ----- */}
      {isWunschdekor && <Wunschdekor />}
      <group
        ref={rectangle}
        {...props}
        position={[0, -0.2 - mirrorHeight * 0.0012, 0.1]}
        rotation={[0, 0, 0]}
        scale={[((3.5 * mirrorWidth) / 400) * 0.5, ((3.3 * mirrorHeight) / 400) * 0.5, 1.6 * 0.5]}
        dispose={null}
      >
        {/* Cam raf */}
        <CamRaf buzluCam={buzluCam} />
        {/* AYNA TIPLERI */}
        <>
          <group name="newjersey" position={[0.01, 0.43, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[1 * scaleMultiplier, 1, 1]}>
            <mesh geometry={nodes.New_Jersey_1.geometry} material={materials.glass}></mesh>
            <mesh geometry={nodes.New_Jersey_2.geometry} renderOrder={-1} material={materials.mirror} />
            <mesh geometry={nodes.New_Jersey_3.geometry} material={materials.glaze} />
          </group>
          <group name="Kristallspiegel" position={[0.01, 0.43, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[1 * scaleMultiplier, 1, 1]}>
            <mesh geometry={nodes.New_Jersey_1.geometry} material={materials.glass}></mesh>
            <mesh geometry={nodes.New_Jersey_2.geometry} renderOrder={-1} material={materials.mirror} />

            <mesh geometry={nodes.New_Jersey_3.geometry} material={materials.glaze} />
          </group>
          <mesh name="Üst" geometry={nodes.Namus.geometry} material={materials.ledlightTexture} position={[0.01, 0.74, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[1 * scaleMultiplier, 30, 1]}>
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>

          {backLightShow && (
            <mesh geometry={nodes.Namus.geometry} material={materials.ledlightTexture} position={[0.01, 0.43, -0.01]} rotation={[Math.PI / 2, 0, 0]} scale={[1.17 * scaleMultiplier, 30, 16.6]}>
              <meshBasicMaterial color={lightColor} toneMapped={false} />
            </mesh>
          )}

          <mesh position={[0.01, 0.43, -0.03]} rotation={[Math.PI / 2, 0, 0]} scale={[1 * scaleMultiplier, 1, 1]}>
            <boxGeometry args={[1.27, 0.05, 0.73]} />
            <meshBasicMaterial color={"#666666"} />
          </mesh>
          <mesh
            name="Sağ_+_Sol_+_Uzun"
            geometry={nodes.M18L2V.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.43, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1 * scaleMultiplier, 30.01, 1]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Alt_+_Üst"
            geometry={nodes.Miami.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.425, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1 * scaleMultiplier, 29.91, 1]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Sağ_+_Sol"
            geometry={nodes.M01L2V.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.43, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1 * scaleMultiplier, 30.14, 1]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Sağ_+_Sol_+_Üst_+_Dış"
            geometry={nodes.Santa_Cruz.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.44, 0]}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
            scale={[1.67 * scaleMultiplier, 0.1, 0.6]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Sağ_+_Üst_+_Sol"
            geometry={nodes.Green_Bay.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.42, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1 * scaleMultiplier, 29.87, 1]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Full_+_Dış"
            geometry={nodes.Newyork.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.43, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1.08 * scaleMultiplier, 1.08, 1.08]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Full_+_İnce"
            geometry={nodes.Bolnuevo.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.43, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1 * scaleMultiplier, 3.61, 1]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Full_+_Kalın"
            geometry={nodes.Portau.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.43, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.84 * scaleMultiplier, 25.14, 0.84]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Full_+_İnce_+_İç"
            geometry={nodes.Aurora.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.43, 0.01]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1 * scaleMultiplier, 1.21, 1]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Arka"
            geometry={nodes.New_Jersey_backlight.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.43, -0.02]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.98 * scaleMultiplier, 1, 0.98]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
        </>
      </group>
    </>
  );
}

useGLTF.preload("/rectangle-mirror-rev01.glb");
