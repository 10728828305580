import React, { useEffect, useState } from "react";
import { EnumMirrors } from "../../../store/mirrors";
import { useSelector } from "react-redux";
import F620TL from "./PartialPaintedMirrors/F620TL";
import { F621TL } from "./PartialPaintedMirrors/F621TL";
import { F622TL } from "./PartialPaintedMirrors/F622TL";
import { F623TL } from "./PartialPaintedMirrors/F623TL";
import CentimeterHeightLines from "../../OrtakComponents/CM/CentimeterHeightLines";
import CentimeterWidthLines from "../../OrtakComponents/CM/CentimeterWidthLines";

const PaintedMirrors = () => {
  const { selectedMirror } = useSelector((state) => state.mirror);
  const isF620 = EnumMirrors.F620TL === selectedMirror;
  const isF621 = EnumMirrors.F621TL === selectedMirror;
  const isF622 = EnumMirrors.F622TL === selectedMirror;
  const isF623 = EnumMirrors.F623TL === selectedMirror;

  const {
    mirrorWidth,
    mirrorHeight,

    heightCmVisible,
    widthCmVisible,
  } = useSelector((state) => state.mirror);

  const [widthOfWidth, setWidthOfWidth] = useState(-47 - mirrorWidth * 0.565);
  const [leftOfWidth, setLeftOfWidth] = useState(-9 + mirrorWidth * 1.16);
  const [heightOfHeight, setHeightOfHeight] = useState(0 + mirrorHeight * 1.01);
  const [topOfHeight, setTopOfHeight] = useState(110 - mirrorHeight * 0.3);
  const [positionOfWidth, setPositionOfWidth] = useState(-0.87 + mirrorHeight * 0.0021);
  const [positionOfHeight, setPositionOfHeight] = useState(-0.45 + mirrorWidth * 0.00175);
  const [positionMakeUpSensor, setPositionMakeUpSensor] = useState([0, 1, 0.01]);

  useEffect(() => {
    setWidthOfWidth(125 + mirrorWidth * 0.82);
    setLeftOfWidth(-105 - mirrorWidth * 0.41);
    setHeightOfHeight(200 + mirrorHeight * 0.51);
    setTopOfHeight(-80 - mirrorHeight * 0.252);
    setPositionOfWidth(-0.4 + mirrorHeight * 0.001);
    setPositionOfHeight(-0.1 + mirrorWidth * 0.0014);
  }, [mirrorHeight, mirrorWidth]);

  const returnPaintedMirror = () => {
    switch (true) {
      case isF620:
        return <F620TL />;
      case isF621:
        return <F621TL />;
      case isF622:
        return <F622TL />;
      case isF623:
        return <F623TL />;
      default:
        return null;
    }
  };
  return (
    <mesh>
      {widthCmVisible && (
        <CentimeterWidthLines
          scale={[0.1275, 0.3, 0.15]}
          textWidth={mirrorWidth > 999 ? "75px" : "70px"}
          textPadding={mirrorHeight > 999 ? "0 12px" : "0 14px"}
          textValue={mirrorWidth == 0 ? "400" : mirrorWidth}
          textSize={"1.4em"}
          textHeight="35px"
          lineLeft={leftOfWidth}
          lineWidth={widthOfWidth}
          position={[-0.01, positionOfWidth, 0.1]}
        />
      )}
      {heightCmVisible && (
        <CentimeterHeightLines
          scale={[0.3, 0.1275, 0.15]}
          textWidth={mirrorHeight > 999 ? "75px" : "70px"}
          textPadding={mirrorHeight > 999 ? "0 12px" : "0 14px"}
          textValue={mirrorHeight == 0 ? "400" : mirrorHeight}
          textSize={"1.4em"}
          textHeight="35px"
          textTop={0 - mirrorHeight * 0.05}
          lineTop={topOfHeight}
          lineHeight={heightOfHeight}
          position={[positionOfHeight, -0.325, 0.1]}
        />
      )}
      {returnPaintedMirror()}
    </mesh>
  );
};

export default PaintedMirrors;
