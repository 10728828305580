import { useRef, useState, useEffect } from "react";

import gsap from "gsap";

import { useGLTF } from "@react-three/drei";

import { useFrame } from "@react-three/fiber";
import { useDrag } from "react-use-gesture";
import { useDispatch, useSelector } from "react-redux";

import DışAydınlatma from "../../OrtakComponents/DışAydınlatma";

import { EnumCabinetMirrors, setCustomDoorWidthValues } from "../../../store/cabinetMirror";

import CentimeterHeightLines from "../../OrtakComponents/CM/CentimeterHeightLines";
import CentimeterWidthLines from "../../OrtakComponents/CM/CentimeterWidthLines";
import CentimeterDepthLines from "../../OrtakComponents/CM/CentimeterDepthLines";

import { EnumSocketUSBBase } from "../cabinetBodyParts/smallParts/SocketUSB";
import { KapakliBolmeCenter } from "./bolmeler/KapakliBolmeCenter";
import { KapaksizBolme } from "./bolmeler/KapaksizBolme";

export default function CabinetCenter(props) {
  //MODELS
  const dispatch = useDispatch();
  //**AYNALI DOLAPLAR */
  const { nodes, materials } = useGLTF("/Frankfurt-test2.glb");
  const { nodes: budapestNodes } = useGLTF("/newModels/budapest.glb");

  // STATES
  const {
    lightColor,
    cabinetMirrorWidth,
    cabinetMirrorHeight,
    cabinetMirrorDepth,
    cabinetMirrorType,
    externalLeds,
    draggerVisible,
    isFirstLeftSided,
    isSecondLeftSided,
    isThirdLeftSided,
    isFourthLeftSided,
    isFifthLeftSided,
    isSixthLeftSided,
    centerCompartmentWidth,
    widthCmVisible,
    heightCmVisible,
    depthCmVisible,
    doorNumber,
    bottomCompartmentHeight,
  } = useSelector((state) => state.cabinetMirror);

  //------REFS------\\

  const frankfurt = useRef();

  //----------First Cabinet
  const firstCabinet = useRef();

  //----------Second Cabinet
  const secondCabinet = useRef();

  //----------Third Cabinet
  const thirdCabinet = useRef();

  //----------Fourth Cabinet
  const fourthCabinet = useRef();

  //----------Fifth Cabinet
  const fifthCabinet = useRef();

  //----------Sixth Cabinet
  const sixthCabinet = useRef();

  //----------Seventh Cabinet
  const seventhCabinet = useRef();

  //----------LEDS
  const outerLedRef = useRef();
  const innerLedRef = useRef();

  //--STATES--\\

  const { selectedMirror } = useSelector((state) => state.mirror);

  //-----Dragger States-----\\
  const initialScaleX = (cabinetMirrorWidth / (doorNumber + 1) / 400) * 2;
  const centerScaleFactor = (initialScaleX * (centerCompartmentWidth - 150)) / 300;
  const centerPositionFactor = centerScaleFactor / 1.75;

  const iA12 = (cabinetMirrorWidth / (doorNumber + 1) / 400) * 2 * -1.25;
  const iA23 = (cabinetMirrorWidth / (doorNumber + 1) / 400) * 2 * -0.75;
  const iA34 = (cabinetMirrorWidth / (doorNumber + 1) / 400) * 2 * -0.25;
  const iA45 = (cabinetMirrorWidth / (doorNumber + 1) / 400) * 2 * 0.25;
  const iA56 = (cabinetMirrorWidth / (doorNumber + 1) / 400) * 2 * 0.5;
  const iA67 = (cabinetMirrorWidth / (doorNumber + 1) / 400) * 2 * 1;

  const [dragger12Position, setDragger12Position] = useState([iA12, 0, 0.115 + cabinetMirrorType]);
  const [dragger23Position, setDragger23Position] = useState([iA23, 0, 0.115 + cabinetMirrorType]);
  const [dragger34Position, setDragger34Position] = useState([iA34, 0, 0.115 + cabinetMirrorType]);
  const [dragger45Position, setDragger45Position] = useState([iA45, 0, 0.115 + cabinetMirrorType]);
  const [dragger56Position, setDragger56Position] = useState([iA56, 0, 0.115 + cabinetMirrorType]);
  const [dragger67Position, setDragger67Position] = useState([iA56, 0, 0.115 + cabinetMirrorType]);

  const [dragger12PositionDiff, setDragger12PositionDiff] = useState(0);
  const [dragger23PositionDiff, setDragger23PositionDiff] = useState(0);
  const [dragger34PositionDiff, setDragger34PositionDiff] = useState(0);
  const [dragger45PositionDiff, setDragger45PositionDiff] = useState(0);
  const [dragger56PositionDiff, setDragger56PositionDiff] = useState(0);
  const [dragger67PositionDiff, setDragger67PositionDiff] = useState(0);

  const [scaleDiff1, setScaleDiff1] = useState(0);
  const [scaleDiff2, setScaleDiff2] = useState(0);
  const [scaleDiff3, setScaleDiff3] = useState(0);
  const [scaleDiff4, setScaleDiff4] = useState(0);
  const [scaleDiff5, setScaleDiff5] = useState(0);
  const [scaleDiff6, setScaleDiff6] = useState(0);
  const [scaleDiff7, setScaleDiff7] = useState(0);

  //----TestMenuMesh visibility states

  //----Dragger Functions

  const bindDragger12 = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = dragger12Position;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= firstCabinet.current.scale.x) {
        setDragger12Position([iA12 + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );

  const bindDragger23 = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = dragger23Position;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= secondCabinet.current.scale.x) {
        setDragger23Position([iA23 + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );

  const bindDragger56 = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = dragger56Position;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= fifthCabinet.current.scale.x) {
        setDragger56Position([iA56 + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );
  const bindDragger67 = useDrag(
    ({ offset: [x], dragging }) => {
      const [, y, z] = dragger67Position;
      if (draggerVisible && x < 250 && x > -250 && 0.4 <= sixthCabinet.current.scale.x) {
        setDragger67Position([iA67 + x / 1000, y, z]);
      }
      dragging ? props.setIsDragging(true) : props.setIsDragging(false);
    },
    { pointerEvents: true, damping: 0.02 }
  );

  useEffect(() => {
    setDragger12Position((prev) => [iA12, 0, 0.115 + cabinetMirrorType]);
    setDragger23Position((prev) => [iA23, 0, 0.115 + cabinetMirrorType]);
    setDragger34Position((prev) => [iA34, 0, 0.115 + cabinetMirrorType]);
    setDragger45Position((prev) => [iA45, 0, 0.115 + cabinetMirrorType]);
    setDragger56Position((prev) => [iA56, 0, 0.115 + cabinetMirrorType]);
    setDragger67Position((prev) => [iA67, 0, 0.115 + cabinetMirrorType]);
  }, [cabinetMirrorType, doorNumber, cabinetMirrorWidth]);

  useFrame((state) => {
    // -----CABINET SCALES----\\
    const initialScaleX = (cabinetMirrorWidth / (doorNumber + 1) / 400) * 2;

    setScaleDiff1((firstCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff2((secondCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff3((thirdCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff4((fourthCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff5((fifthCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff6((sixthCabinet.current.scale.x - initialScaleX) / 2);
    setScaleDiff7((seventhCabinet.current.scale.x - initialScaleX) / 2);

    setDragger12PositionDiff(dragger12Position[0] - iA12);
    setDragger23PositionDiff(dragger23Position[0] - iA23);
    setDragger34PositionDiff(dragger34Position[0] - iA34);
    setDragger45PositionDiff(dragger45Position[0] - iA45);
    setDragger56PositionDiff(dragger56Position[0] - iA56);
    setDragger67PositionDiff(dragger67Position[0] - iA67);

    gsap.to(firstCabinet.current.scale, { x: initialScaleX + dragger12PositionDiff });
    gsap.to(secondCabinet.current.scale, {
      x: initialScaleX + dragger23PositionDiff - dragger12PositionDiff,
    });
    gsap.to(thirdCabinet.current.scale, {
      x: initialScaleX + dragger34PositionDiff - dragger23PositionDiff - centerScaleFactor / 1.75,
    });
    gsap.to(fourthCabinet.current.scale, {
      x: initialScaleX / 2 - dragger34PositionDiff + dragger45PositionDiff + centerScaleFactor,
    });
    gsap.to(fifthCabinet.current.scale, {
      x: initialScaleX - dragger45PositionDiff + dragger56PositionDiff - centerScaleFactor / 1.75,
    });
    gsap.to(sixthCabinet.current.scale, { x: initialScaleX - dragger56PositionDiff + dragger67PositionDiff });
    gsap.to(seventhCabinet.current.scale, { x: initialScaleX - dragger67PositionDiff });

    //** */
    gsap.to(firstCabinet.current.position, { x: initialScaleX * -1.5 + scaleDiff1 * 0.5 });
    gsap.to(secondCabinet.current.position, { x: initialScaleX * -1 + scaleDiff2 * 0.5 + dragger12PositionDiff * 0.5 });
    gsap.to(thirdCabinet.current.position, { x: initialScaleX * -0.5 + scaleDiff3 * 0.5 + dragger23PositionDiff * 0.5 });
    gsap.to(fourthCabinet.current.position, { x: initialScaleX * 0 + scaleDiff4 * 0.5 + dragger34PositionDiff * 0.5 - centerPositionFactor / 2 }); /////CENTER POSITION FACTOR
    gsap.to(fifthCabinet.current.position, { x: initialScaleX * 0.5 + scaleDiff5 * 0.5 + dragger45PositionDiff * 0.5 + centerPositionFactor / 2.5 - initialScaleX / 3.75 });
    gsap.to(sixthCabinet.current.position, { x: initialScaleX * 1 + scaleDiff6 * 0.5 + dragger56PositionDiff * 0.5 - initialScaleX / 3.25 });
    gsap.to(seventhCabinet.current.position, { x: initialScaleX * 1.5 + scaleDiff7 * 0.5 + dragger67PositionDiff * 0.5 - initialScaleX / 3.25 });

    //---------------- LED FRAME SCALE AND POS
    gsap.to(outerLedRef.current.scale, { x: (doorNumber * initialScaleX + fourthCabinet.current.scale.x - (initialScaleX * (centerCompartmentWidth - 150)) / 275) * 0.327 });
    gsap.to(outerLedRef.current.position, { x: fourthCabinet.current.position.x - 0.07 + 6 / doorNumber / 50 });

    //----------------  INNER LED SCALE AND POS
    gsap.to(innerLedRef.current.scale, { x: (doorNumber * initialScaleX * 1.011 + fourthCabinet.current.scale.x * 0.9) * 0.327 - (initialScaleX * (centerCompartmentWidth - 150)) / 875 });
    gsap.to(innerLedRef.current.position, {
      x: fourthCabinet.current.position.x - 0.01 * cabinetMirrorWidth * 0.003 + doorNumber * 0.002 + centerPositionFactor - (initialScaleX * (centerCompartmentWidth - 150)) / 550,
    });
  });

  useEffect(() => {
    frankfurt.current.scale.y = 1.5 + (cabinetMirrorHeight - 400) / 400;
    frankfurt.current.position.y = 0;
    frankfurt.current.position.x = -1;

    frankfurt.current.position.z = 0 + 0.065 * cabinetMirrorDepth * 0.00125;
    frankfurt.current.scale.z = 2 + cabinetMirrorDepth * 0.00225;
  }, [cabinetMirrorWidth, cabinetMirrorHeight, cabinetMirrorDepth, cabinetMirrorType]);

  const isBottomAndTopLedVisible = externalLeds === 3;
  const isBottomLedVisible = isBottomAndTopLedVisible || externalLeds === 1;
  const isTopLedVisible = isBottomAndTopLedVisible || externalLeds === 2;

  const hasInnerLed = [EnumCabinetMirrors.MUNCHEN_5, EnumCabinetMirrors.LAS_VEGAS].includes(selectedMirror);
  const hasLedFrame = [EnumCabinetMirrors.MUNCHEN_5].includes(selectedMirror);

  // CM Adjustment for added cabinets

  // const widthCm = cabinet
  const [widthOfWidthCM, setWidthOfWidthCM] = useState(220 + cabinetMirrorWidth * 0.682);
  const [leftOfWidthCM, setLeftOfWidthCM] = useState(-235 - cabinetMirrorWidth * 0.2);
  const [textPosWidthCM, setTextPosWidthCM] = useState(-1.2 + cabinetMirrorWidth * 0.00032);
  const [positionOfDepthCM, setPositionOfDepthCM] = useState([-0.7 - cabinetMirrorWidth * 0.001, -0.53 - cabinetMirrorHeight * 0.0004, -0.18 - cabinetMirrorDepth * 0.0001]);
  const [lineLeftDepth, setLineLeftDepth] = useState(-15 + cabinetMirrorDepth * 0.05);
  const [textPosDepth, setTextPosDepth] = useState(0.28 - cabinetMirrorDepth * 0.0001);

  useEffect(() => {
    if (doorNumber > 5) {
      setWidthOfWidthCM(35 + cabinetMirrorWidth * 0.735);
      setLeftOfWidthCM(-210 - cabinetMirrorWidth * 0.41);
      setTextPosWidthCM(-0.42 - cabinetMirrorWidth * 0.00012);
      setPositionOfDepthCM((prev) => [-1.16 - cabinetMirrorWidth * 0.00125, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);
    } else if (doorNumber > 4) {
      setWidthOfWidthCM(0 + cabinetMirrorWidth * 0.83);
      setLeftOfWidthCM(-365 - cabinetMirrorWidth * 0.092);
      setTextPosWidthCM(-0.6 + cabinetMirrorWidth * 0.00075);
      setPositionOfDepthCM((prev) => [-1.15 - cabinetMirrorWidth * 0.00025, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);
    } else if (doorNumber > 3) {
      setWidthOfWidthCM(0 + cabinetMirrorWidth * 0.735);
      setLeftOfWidthCM(-210 - cabinetMirrorWidth * 0.41);
      setTextPosWidthCM(-0.48 - cabinetMirrorWidth * 0.00012);
      setPositionOfDepthCM((prev) => [-1.15 - cabinetMirrorWidth * 0.00125, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);
    } else if (doorNumber > 2) {
      setWidthOfWidthCM(0 + cabinetMirrorWidth * 0.83);
      setLeftOfWidthCM(-385 - cabinetMirrorWidth * 0.15);
      setTextPosWidthCM(-0.7 + cabinetMirrorWidth * 0.00055);
      setPositionOfDepthCM((prev) => [-1.17 - cabinetMirrorWidth * 0.0004, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);
    } else if (doorNumber > 1) {
      setWidthOfWidthCM(-10 + cabinetMirrorWidth * 0.695);
      setLeftOfWidthCM(-205 - cabinetMirrorWidth * 0.41);
      setTextPosWidthCM(-0.52 - cabinetMirrorWidth * 0.00013);
      setPositionOfDepthCM((prev) => [-1.15 - cabinetMirrorWidth * 0.00125, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);
    } else {
      setWidthOfWidthCM(38 + cabinetMirrorWidth * 0.75);
      setLeftOfWidthCM(-390 - cabinetMirrorWidth * 0.4);
      setTextPosWidthCM(-1.1 + cabinetMirrorWidth * 0.0003);
      setPositionOfDepthCM((prev) => [-1.15 - cabinetMirrorWidth * 0.00125, -0.85 - cabinetMirrorHeight * 0.00008, prev[2]]);
    }
  }, [cabinetMirrorWidth, widthCmVisible, cabinetMirrorHeight, cabinetMirrorDepth, doorNumber]);

  const [cmPositionOfZ, setCmPositionOfZ] = useState(0);
  useEffect(() => {
    if (cabinetMirrorType === 0) {
      setCmPositionOfZ(0.3);
      setPositionOfDepthCM((prev) => [prev[0], prev[1], -0.18 - cabinetMirrorDepth * 0.0001]);
    } else if (cabinetMirrorType === 0.2) {
      setLineLeftDepth(-40 + cabinetMirrorDepth * 0.13);
      setCmPositionOfZ(0.7);
      setTextPosDepth(0.28 + cabinetMirrorDepth * 0.0004);
      setPositionOfDepthCM((prev) => [prev[0], prev[1], 0.27 - cabinetMirrorDepth * 0.0001]);
    } else if (cabinetMirrorType === -0.08) {
      setLineLeftDepth(-10 - cabinetMirrorDepth * 0.08);
      setCmPositionOfZ(0.1);
      setTextPosDepth(0.28 - cabinetMirrorDepth * 0.0001);
      setPositionOfDepthCM((prev) => [prev[0], prev[1], -0.37 - cabinetMirrorDepth * 0.0001]);
    } else {
      setLineLeftDepth(5 - cabinetMirrorDepth * 0.12);
      setCmPositionOfZ(-0.1);
      setTextPosDepth(0.28 - cabinetMirrorDepth * 0.0001);
      setPositionOfDepthCM((prev) => [prev[0], prev[1], -0.57 - cabinetMirrorDepth * 0.0001]);
    }
  }, [cabinetMirrorType, cabinetMirrorDepth]);

  useEffect(() => {
    if (draggerVisible && props.isDragging) {
      const lengthArray = [];
      if (firstCabinet?.current?.visible) {
        const value = ((firstCabinet?.current?.scale.x * cabinetMirrorWidth) / (doorNumber + 1)).toFixed(0);
        lengthArray.push(value);
      }
      if (secondCabinet?.current?.visible) {
        const value = ((secondCabinet?.current?.scale.x * cabinetMirrorWidth) / (doorNumber + 1)).toFixed(0);
        lengthArray.push(value);
      }
      if (thirdCabinet?.current?.visible) {
        const value = ((thirdCabinet?.current?.scale.x * cabinetMirrorWidth) / (doorNumber + 1)).toFixed(0);
        lengthArray.push(value);
      }
      if (fourthCabinet?.current?.visible) {
        const value = ((fourthCabinet?.current?.scale.x * cabinetMirrorWidth) / (doorNumber + 1)).toFixed(0);
        lengthArray.push(value);
      }
      if (fifthCabinet?.current?.visible) {
        const value = ((fifthCabinet?.current?.scale.x * cabinetMirrorWidth) / (doorNumber + 1)).toFixed(0);
        lengthArray.push(value);
      }
      if (sixthCabinet?.current?.visible) {
        const value = ((sixthCabinet?.current?.scale.x * cabinetMirrorWidth) / (doorNumber + 1)).toFixed(0);
        lengthArray.push(value);
      }
      if (seventhCabinet?.current?.visible) {
        const value = ((seventhCabinet?.current?.scale.x * cabinetMirrorWidth) / (doorNumber + 1)).toFixed(0);
        lengthArray.push(value);
      }
      dispatch(setCustomDoorWidthValues(lengthArray));
    } else if (!draggerVisible) {
      setDragger12Position([iA12, 0, 0.115 + cabinetMirrorType]);
      setDragger23Position([iA23, 0, 0.115 + cabinetMirrorType]);
      setDragger34Position([iA34, 0, 0.115 + cabinetMirrorType]);
      setDragger45Position([iA45, 0, 0.115 + cabinetMirrorType]);
      setDragger56Position([iA56, 0, 0.115 + cabinetMirrorType]);
      setDragger67Position([iA67, 0, 0.115 + cabinetMirrorType]);
    }
  }, [props.isDragging, draggerVisible, doorNumber]);

  const multiplierBottomTopLed =
    cabinetMirrorWidth < 651 ? 30 : cabinetMirrorWidth < 901 ? 4 : cabinetMirrorWidth < 1002 ? 2.9 : cabinetMirrorWidth < 1600 ? 2.6 : cabinetMirrorWidth < 2000 ? 2.5 : 2.4;
  const bottomTopLedPosX = (cabinetMirrorWidth / (doorNumber + 1) / 400) * ((doorNumber + 1) / multiplierBottomTopLed) - 0.035;

  useEffect(() => {
    if (outerLedRef.current && innerLedRef.current) {
      outerLedRef.current.position.y = 0;
      innerLedRef.current.position.y = 0;
    }
  }, [cabinetMirrorHeight, bottomCompartmentHeight]);

  const dragger16Visible = doorNumber === 6;
  const dragger25Visible = doorNumber >= 4;

  const doorTypeChecker = (index) => {
    switch (index) {
      case 0:
        return "first";
      case 1:
        if (doorNumber > 4) {
          return "mid";
        } else {
          return "first";
        }

      case 2:
        if (doorNumber > 2) {
          return "last";
        } else {
          return "single";
        }
      case 4:
        if (doorNumber > 2) {
          return "first";
        } else {
          return "single";
        }
      case 5:
        if (doorNumber > 4) {
          return "mid";
        } else {
          return "last";
        }
      case 6:
        return "last";
      default:
        return "first";
    }
  };

  return (
    <>
      {widthCmVisible && (
        <CentimeterWidthLines
          textWidth={cabinetMirrorWidth > 999 ? "85px" : "75px"}
          textPadding={cabinetMirrorWidth > 999 ? "0 12px" : "0 15px"}
          textValue={cabinetMirrorWidth == 0 ? "400" : cabinetMirrorWidth}
          textSize={"1.6em"}
          textHeight="40px"
          textPos={[textPosWidthCM, 0.9, 0.03]}
          lineLeft={leftOfWidthCM}
          lineWidth={widthOfWidthCM}
          scale={[0.12, doorNumber > 5 ? 0.3 : 0.2, 0.12]}
          position={[-0.55, -0.35 + cabinetMirrorHeight * 0.001, cmPositionOfZ]}
        />
      )}
      {heightCmVisible && (
        <CentimeterHeightLines
          textWidth={cabinetMirrorHeight > 999 ? "85px" : "75px"}
          textPadding={cabinetMirrorHeight > 999 ? "0 12px" : "0 15px"}
          textValue={cabinetMirrorHeight == 0 ? "400" : cabinetMirrorHeight}
          textSize={"1.6em"}
          textHeight="40px"
          textPos={[cabinetMirrorHeight > 999 ? 0.59 : 0.61, 0.36, 0.03]}
          textTop={-35 + cabinetMirrorHeight / 200}
          lineTop={-50 - cabinetMirrorHeight * 0.345}
          lineHeight={122 + cabinetMirrorHeight * 0.685}
          scale={[cabinetMirrorHeight > 1500 ? 0.3 : 0.2, 0.12, 0.12]}
          position={[doorNumber * 0.06 + -1.6 + cabinetMirrorWidth * 0.001, -0.38, cmPositionOfZ]}
        />
      )}
      {depthCmVisible && (
        <CentimeterDepthLines
          textWidth={"75px"}
          textPadding={"0 14px"}
          textValue={cabinetMirrorDepth == 0 ? "125" : cabinetMirrorDepth}
          textSize={"1.6em"}
          textHeight="40px"
          textPos={[0.13, 0.9, textPosDepth]}
          lineLeft={lineLeftDepth}
          lineWidth={170 + cabinetMirrorDepth * 0.15}
          scale={[0.12, doorNumber > 5 ? 0.4 : 0.3, 0.15]}
          position={positionOfDepthCM}
        />
      )}
      <group renderOrder={1} ref={frankfurt} {...props}>
        {/* LED ÇERÇEVE */}
        <group ref={outerLedRef} scale={[1.03, 1 + cabinetMirrorHeight * 0.000005, 1]} position={[0, 0, 0.116 + cabinetMirrorType]}>
          <mesh visible={hasLedFrame ? true : false} geometry={nodes.led_1.geometry} material={materials.aluminum} />
          <mesh visible={hasLedFrame ? true : false} geometry={nodes.led_2.geometry} material={materials.ledtexture}>
            <meshStandardMaterial color={lightColor} toneMapped={false} />
          </mesh>
        </group>

        {/* İÇ LED */}
        {hasInnerLed && (
          <mesh
            ref={innerLedRef}
            geometry={budapestNodes.interriorLed.geometry}
            material={materials.ledtexture}
            scale={[0.6 + 0.63 * ((cabinetMirrorWidth - 370) / 370), 0.97 + cabinetMirrorHeight * 0.00003, 1]}
            position={[0, 0, -0.02 + cabinetMirrorType]}
          >
            <meshBasicMaterial color={[lightColor[0] * 0.25, lightColor[1] * 0.25, lightColor[2] * 0.25]} toneMapped={false} />
          </mesh>
        )}

        {/* DIŞARIDAKİ LEDLER */}

        {isBottomLedVisible && (
          <DışAydınlatma
            visible={isBottomLedVisible}
            scaleY={cabinetMirrorWidth * 0.002}
            position={[bottomTopLedPosX, -0.406, 0.06 + cabinetMirrorType]}
            rotation={[Math.PI * 0.5, 0, Math.PI * 0.5]}
          />
        )}
        {isTopLedVisible && (
          <DışAydınlatma visible={isTopLedVisible} scaleY={cabinetMirrorWidth * 0.002} position={[bottomTopLedPosX, 0.405, 0.06 + cabinetMirrorType]} rotation={[Math.PI * 1.5, 0, Math.PI * 1.5]} />
        )}
        {/* FIRST CABINET */}
        <KapakliBolmeCenter cabinetRef={firstCabinet} doorIndex={0} isLeftSided={isFirstLeftSided} socketUsbBase={EnumSocketUSBBase.LEFT} doorType={doorTypeChecker(0)} />
        {draggerVisible !== 0 && dragger16Visible && (
          <mesh rotation-y={Math.PI * 0.5} position={dragger12Position} visible={draggerVisible === 0 ? false : true} {...bindDragger12()}>
            <boxGeometry args={[0.025, 0.2, 0.05]} />
            <meshBasicMaterial color={"#575757"} />
          </mesh>
        )}
        {/* SECOND CABINET */}
        <KapakliBolmeCenter cabinetRef={secondCabinet} doorIndex={1} isLeftSided={isSecondLeftSided} socketUsbBase={doorNumber === 4 ? EnumSocketUSBBase.LEFT : ""} doorType={doorTypeChecker(1)} />
        {draggerVisible !== 0 && dragger25Visible && (
          <mesh rotation-y={Math.PI * 0.5} position={dragger23Position} {...bindDragger23()} visible={draggerVisible === 0 ? false : true}>
            <boxGeometry args={[0.025, 0.2, 0.05]} />
            <meshBasicMaterial color={"#575757"} />
          </mesh>
        )}
        {/* THIRD CABINET */}
        <KapakliBolmeCenter cabinetRef={thirdCabinet} doorIndex={2} isLeftSided={isThirdLeftSided} socketUsbBase={doorNumber === 2 ? EnumSocketUSBBase.LEFT : ""} doorType={doorTypeChecker(2)} />

        {/* FOURTH CABINET */}

        <KapaksizBolme cabinetRef={fourthCabinet} doorIndex={3} socketUsbBase={doorNumber < 5 ? EnumSocketUSBBase.RIGHT : doorNumber === 6 ? EnumSocketUSBBase.MID : ""} doorType={"center"} />

        {/* FIFTH CABINET */}

        <KapakliBolmeCenter cabinetRef={fifthCabinet} doorIndex={4} isLeftSided={isFourthLeftSided} socketUsbBase={doorNumber === 2 ? EnumSocketUSBBase.RIGHT : ""} doorType={doorTypeChecker(4)} />

        {draggerVisible !== 0 && dragger25Visible && (
          <mesh rotation-y={Math.PI * 0.5} position={dragger56Position} {...bindDragger56()} visible={draggerVisible === 0 ? false : true}>
            <boxGeometry args={[0.025, 0.2, 0.05]} />
            <meshBasicMaterial color={"#575757"} />
          </mesh>
        )}

        {/* SIXTH CABINET */}

        <KapakliBolmeCenter cabinetRef={sixthCabinet} doorIndex={5} isLeftSided={isFifthLeftSided} socketUsbBase={doorNumber === 4 ? EnumSocketUSBBase.RIGHT : ""} doorType={doorTypeChecker(5)} />

        {draggerVisible !== 0 && dragger16Visible && (
          <mesh rotation-y={Math.PI * 0.5} position={dragger67Position} {...bindDragger67()} visible={draggerVisible === 0 ? false : true}>
            <boxGeometry args={[0.025, 0.2, 0.05]} />
            <meshBasicMaterial color={"#575757"} />
          </mesh>
        )}

        {/* SEVENTH CABINET */}

        <KapakliBolmeCenter cabinetRef={seventhCabinet} doorIndex={6} isLeftSided={isSixthLeftSided} socketUsbBase={EnumSocketUSBBase.RIGHT} doorType={doorTypeChecker(6)} />
      </group>
    </>
  );
}

// useGLTF.preload("/Frankfurt-test2.glb");
