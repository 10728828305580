import React from "react";
import { useGLTF } from "@react-three/drei";

const DikeySensor = (props) => {
  const { nodes, materials } = useGLTF("/rectangle-mirror-rev01.glb");

  return (
    <mesh geometry={nodes["touch-led"].geometry} material={materials.ledtexture} {...props} rotation={[Math.PI / 2, 0, 0]} scale={1.2}>
      {/* <meshBasicMaterial color={lightColor} toneMapped={false} /> */}
    </mesh>
  );
};

export default DikeySensor;
