import { Stack, Slider, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EnumCabinetMirrors, changeCabinetMirrorWidth, setCustomDoorWidthValues, setDoorNumber } from "../../../../store/cabinetMirror";
import { useState, useEffect } from "react";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const Genislik = () => {
  const dispatch = useDispatch();
  const { cabinetMirrorWidth, doorNumber } = useSelector((state) => state.cabinetMirror);

  const { selectedMirror } = useSelector((state) => state.mirror);

  const [alertMessage, setAlertMessage] = useState(false);

  const is500 = [EnumCabinetMirrors.EL_PASO].includes(selectedMirror);
  const is600 = [EnumCabinetMirrors.BUDAPEST, EnumCabinetMirrors.SANTANDER, EnumCabinetMirrors.LISSABON, EnumCabinetMirrors.LINZ, EnumCabinetMirrors.CORTINA].includes(selectedMirror);
  const is650 = [
    EnumCabinetMirrors.MUNCHEN_4,
    EnumCabinetMirrors.GENT,
    EnumCabinetMirrors.DERBY,
    EnumCabinetMirrors.VERONICA_3,
    EnumCabinetMirrors.VERONICA_4,
    EnumCabinetMirrors.PANDORA_3,
    EnumCabinetMirrors.PANDORA_4,
    EnumCabinetMirrors.KATHERINE_3,
    EnumCabinetMirrors.KATHERINE_4,
  ].includes(selectedMirror);

  const is700 = [EnumCabinetMirrors.VENEZIA].includes(selectedMirror);
  const is750 = [EnumCabinetMirrors.MUNCHEN_5, EnumCabinetMirrors.LAS_VEGAS, EnumCabinetMirrors.MALAGA, EnumCabinetMirrors.GRADO].includes(selectedMirror);
  const is900 = [EnumCabinetMirrors.VILLACH].includes(selectedMirror);
  const is1000 = [EnumCabinetMirrors.SARAGOSSA, EnumCabinetMirrors.KONSTANZ, EnumCabinetMirrors.ALMERIA].includes(selectedMirror);

  const minValue = is1000 ? 1000 : is900 ? 900 : is750 ? 750 : is700 ? 700 : is650 ? 650 : is600 ? 600 : is500 ? 500 : 400;

  useEffect(() => {
    dispatch(changeCabinetMirrorWidth(minValue));
    setInput(minValue);
    const initialValue = (minValue / doorNumber).toFixed(0);
    dispatch(setCustomDoorWidthValues([initialValue, initialValue, initialValue]));
  }, [selectedMirror]);

  const MAX_VALUE = 2500;
  const MIN_VALUE = minValue;
  const DEFAULT_VALUE = cabinetMirrorWidth;

  const [input, setInput] = useState(DEFAULT_VALUE);

  const handleCabinetMirrorWidthChange = (e) => {
    const value = parseInt(e.target.value);
    setInput(value);

    if (!value) {
      dispatch(changeCabinetMirrorWidth(MIN_VALUE));
    } else {
      if (value < MIN_VALUE || value > MAX_VALUE) {
        dispatch(changeCabinetMirrorWidth(value < MIN_VALUE ? MIN_VALUE : MAX_VALUE));
        e.target.style.color = "tomato";
        setAlertMessage(true);
      } else {
        dispatch(changeCabinetMirrorWidth(value));
        e.target.style.color = "#000";
        setAlertMessage(false);
      }
    }
  };

  const kareGenislik = [
    {
      value: "1803",
      inputValue: "200",
    },
    {
      value: "1804",
      inputValue: "250",
    },
    {
      value: "1805",
      inputValue: "300",
    },
    {
      value: "1806",
      inputValue: "350",
    },
    {
      value: "20",
      inputValue: "400",
    },
    {
      value: "21",
      inputValue: "450",
    },
    {
      value: "22",
      inputValue: "500",
    },
    {
      value: "23",
      inputValue: "550",
    },
    {
      value: "24",
      inputValue: "600",
    },
    {
      value: "25",
      inputValue: "650",
    },
    {
      value: "26",
      inputValue: "700",
    },
    {
      value: "27",
      inputValue: "750",
    },
    {
      value: "28",
      inputValue: "800",
    },
    {
      value: "29",
      inputValue: "850",
    },
    {
      value: "30",
      inputValue: "900",
    },
    {
      value: "31",
      inputValue: "950",
    },
    {
      value: "32",
      inputValue: "1000",
    },
    {
      value: "33",
      inputValue: "1050",
    },
    {
      value: "34",
      inputValue: "1100",
    },
    {
      value: "35",
      inputValue: "1150",
    },
    {
      value: "36",
      inputValue: "1200",
    },
    {
      value: "37",
      inputValue: "1250",
    },
    {
      value: "38",
      inputValue: "1300",
    },
    {
      value: "39",
      inputValue: "1350",
    },
    {
      value: "40",
      inputValue: "1400",
    },
    {
      value: "41",
      inputValue: "1450",
    },
    {
      value: "42",
      inputValue: "1500",
    },
    {
      value: "43",
      inputValue: "1550",
    },
    {
      value: "44",
      inputValue: "1600",
    },
    {
      value: "45",
      inputValue: "1650",
    },
    {
      value: "46",
      inputValue: "1700",
    },
    {
      value: "47",
      inputValue: "1750",
    },
    {
      value: "48",
      inputValue: "1800",
    },
    {
      value: "207",
      inputValue: "1850",
    },
    {
      value: "208",
      inputValue: "1900",
    },
    {
      value: "209",
      inputValue: "1950",
    },
    {
      value: "210",
      inputValue: "2000",
    },
    {
      value: "211",
      inputValue: "2050",
    },
    {
      value: "212",
      inputValue: "2100",
    },
    {
      value: "213",
      inputValue: "2150",
    },
    {
      value: "214",
      inputValue: "2200",
    },
    {
      value: "215",
      inputValue: "2250",
    },
    {
      value: "216",
      inputValue: "2300",
    },
    {
      value: "217",
      inputValue: "2350",
    },
    {
      value: "218",
      inputValue: "2400",
    },
    {
      value: "219",
      inputValue: "2450",
    },
    {
      value: "220",
      inputValue: "2500",
    },
  ];

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <RenderModal id="14">
          <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
            Breite in mm
          </Typography>
        </RenderModal>

        <input type="hidden" name="id[61]" value="526" />
        <input type="hidden" class="form-control" id="txt61" name="txt_526" value={cabinetMirrorWidth} />
        <input type="hidden" name="id[7]" value={kareGenislik.find((k) => parseInt(k.inputValue) === input)?.value} />

        <TextField
          value={input}
          type="number"
          onChange={handleCabinetMirrorWidthChange}
          sx={{
            height: "2.2rem",
            width: "100%",
            ".MuiInputBase-input": {
              padding: "8px 8px 8px 18px",
              fontSize: 12,
            },
          }}
        />
      </Stack>
      {alertMessage ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            position: "relative",
            top: -10,
          }}
        >
          <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem", mt: 0 }}></Typography>
          <small
            style={{
              color: "red",
            }}
          >
            Bitte geben Sie den Wert für die Breite (nur Ziffern) in Millimetern ein. Dieser muss zwischen {MIN_VALUE} und {MAX_VALUE} (mm) betragen
          </small>
        </Stack>
      ) : null}
    </>
  );
};

export default Genislik;
