import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSummaryModalOpen, resetToInitialState } from "../../../store/mirrors";
import $ from "jquery";

const SiparisVer = () => {
  const dispatch = useDispatch();

  return (
    <Stack direction="row" alignItems="center">
      <Stack
        spacing={3}
        direction="row"
        sx={{
          flexGrow: "1",
          fontSize: "0.7rem !important",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Button onClick={() => $("#cart_quantity_mirror").submit()} sx={{ width: "100%", mt: 5, fontWeight: "700", letterSpacing: "0.15rem" }} variant="contained">
          In den Warenkorb
        </Button>
        {/* <Button
          onClick={() => dispatch(resetToInitialState(true))}
          sx={{
            width: "25%",
            backgroundColor: "red",
            "&:hover": {
              backgroundColor: "#7b080f",
            },
          }}
          variant="contained"
        >
          Sıfırla
        </Button> */}
      </Stack>
    </Stack>
  );
};

export default SiparisVer;
