import { Stack, Slider, Typography, Input, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EnumCabinets, changeCabinetWidth } from "../../../store/cabinet";
import { useState, useEffect } from "react";

const Genislik = () => {
  const dispatch = useDispatch();
  const { cabinetWidth } = useSelector((state) => state.cabinet);

  const { selectedMirror } = useSelector((state) => state.mirror);

  const [sliderValue, setSliderValue] = useState(400);

  const handleBlur = () => {
    dispatch(changeCabinetWidth(sliderValue));
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue); // Update the local state directly as the slider is moved.
  };

  const handleSliderChangeCommitted = (event, newValue) => {
    setInput(newValue);
    dispatch(changeCabinetWidth(newValue)); // Update Redux state when the user stops dragging the slider.
  };

  useEffect(() => {
    setSliderValue(cabinetWidth);
  }, [cabinetWidth]);

  useEffect(() => {
    dispatch(changeCabinetWidth(400));
    setSliderValue(400);
  }, [selectedMirror]);

  const MAX_VALUE = 650;
  const MIN_VALUE = 200;
  const DEFAULT_VALUE = sliderValue;
  const [input, setInput] = useState(DEFAULT_VALUE);

  const [alertMessage, setAlertMessage] = useState(null);

  const kareGenislik = [
    {
      value: "1803",
      inputValue: "200",
    },
    {
      value: "1804",
      inputValue: "250",
    },
    {
      value: "1805",
      inputValue: "300",
    },
    {
      value: "1806",
      inputValue: "350",
    },
    {
      value: "20",
      inputValue: "400",
    },
    {
      value: "21",
      inputValue: "450",
    },
    {
      value: "22",
      inputValue: "500",
    },
    {
      value: "23",
      inputValue: "550",
    },
    {
      value: "24",
      inputValue: "600",
    },
    {
      value: "25",
      inputValue: "650",
    },
    {
      value: "26",
      inputValue: "700",
    },
    {
      value: "27",
      inputValue: "750",
    },
    {
      value: "28",
      inputValue: "800",
    },
    {
      value: "29",
      inputValue: "850",
    },
    {
      value: "30",
      inputValue: "900",
    },
    {
      value: "31",
      inputValue: "950",
    },
    {
      value: "32",
      inputValue: "1000",
    },
    {
      value: "33",
      inputValue: "1050",
    },
    {
      value: "34",
      inputValue: "1100",
    },
    {
      value: "35",
      inputValue: "1150",
    },
    {
      value: "36",
      inputValue: "1200",
    },
    {
      value: "37",
      inputValue: "1250",
    },
    {
      value: "38",
      inputValue: "1300",
    },
    {
      value: "39",
      inputValue: "1350",
    },
    {
      value: "40",
      inputValue: "1400",
    },
    {
      value: "41",
      inputValue: "1450",
    },
    {
      value: "42",
      inputValue: "1500",
    },
    {
      value: "43",
      inputValue: "1550",
    },
    {
      value: "44",
      inputValue: "1600",
    },
    {
      value: "45",
      inputValue: "1650",
    },
    {
      value: "46",
      inputValue: "1700",
    },
    {
      value: "47",
      inputValue: "1750",
    },
    {
      value: "48",
      inputValue: "1800",
    },
    {
      value: "207",
      inputValue: "1850",
    },
    {
      value: "208",
      inputValue: "1900",
    },
    {
      value: "209",
      inputValue: "1950",
    },
    {
      value: "210",
      inputValue: "2000",
    },
    {
      value: "211",
      inputValue: "2050",
    },
    {
      value: "212",
      inputValue: "2100",
    },
    {
      value: "213",
      inputValue: "2150",
    },
    {
      value: "214",
      inputValue: "2200",
    },
    {
      value: "215",
      inputValue: "2250",
    },
    {
      value: "216",
      inputValue: "2300",
    },
    {
      value: "217",
      inputValue: "2350",
    },
    {
      value: "218",
      inputValue: "2400",
    },
    {
      value: "219",
      inputValue: "2450",
    },
    {
      value: "220",
      inputValue: "2500",
    },
  ];

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Breite in mm
        </Typography>

        <input type="hidden" name="id[61]" value="526" />
        <input type="hidden" class="form-control" id="txt61" name="txt_526" value={cabinetWidth} />
        <input type="hidden" name="id[7]" value={kareGenislik.find((k) => parseInt(k.inputValue) === input)?.value} />

        <TextField
          value={input}
          type="number"
          onFocus={(e) => {
            if (e.target.value.length == 0) {
              setInput(sliderValue);
              e.target.style.color = "#000";
            }
          }}
          onChange={(e) => {
            var value = parseInt(e.target.value);
            setInput(value);
            let point = 0;
            if (!value) {
              dispatch(changeCabinetWidth(MIN_VALUE));
            } else {
              if (value > MAX_VALUE) {
                dispatch(changeCabinetWidth(MAX_VALUE));
                e.target.style.color = "tomato";
                setAlertMessage(true);

                point++;
              } else {
                point = point != 0 ? point-- : 0;
                setAlertMessage(false);
              }

              if (value < MIN_VALUE) {
                dispatch(changeCabinetWidth(MIN_VALUE));
                e.target.style.color = "tomato";
                setAlertMessage(true);

                point++;
              } else {
                point = point != 0 ? point-- : 0;
                setAlertMessage(false);
              }

              if (point == 0) {
                dispatch(changeCabinetWidth(value));
                e.target.style.color = "#000";
                setAlertMessage(false);
              } else {
                setAlertMessage(true);
              }
            }
          }}
          sx={{
            height: "2.2rem",
            width: "100%",
            ".MuiInputBase-input": {
              padding: "8px 8px 8px 18px",

              fontSize: 12,
            },
          }}
        />

        {/* <Slider
        sx={{
          ml: 3,
          ".MuiSlider-thumb": {
            minWidth: "50px",
            borderRadius: "10px",
          },
        }}
        onChange={handleSliderChange}
        onMouseUp={handleBlur}
        onBlur={handleBlur}
        onChangeCommitted={handleSliderChangeCommitted}
        size="medium"
        defaultValue={DEFAULT_VALUE}
        min={MIN_VALUE}
        max={MAX_VALUE}
        valueLabelDisplay="auto"
        value={sliderValue}
      /> */}
      </Stack>
      {alertMessage ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            position: "relative",
            top: -10,
          }}
        >
          <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem", mt: 0 }}></Typography>
          <small
            style={{
              color: "red",
            }}
          >
            Bitte geben Sie den Wert für die Breite (nur Ziffern) in Millimetern ein. Dieser muss zwischen {MIN_VALUE} und {MAX_VALUE} (mm) betragen
          </small>
        </Stack>
      ) : null}
    </>
  );
};

export default Genislik;
