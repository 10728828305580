import { useGLTF } from "@react-three/drei";
import React from "react";
import { useSelector } from "react-redux";

const ChicagoMirror = ({ type }) => {
  const { nodes, materials } = useGLTF("/newModels/chicago.glb");
  const { nodes: chicagoNodes, materials: chicagoMaterials } = useGLTF("/chicago.glb");
  const { lightColor } = useSelector((state) => state.cabinetMirror);

  return (
    <>
      {type === "single" ? (
        <>
          {/* FIRST */}
          <group scale-x={0.5}>
            <mesh geometry={nodes.leftDoorLeft_1.geometry} material={materials["ledlightTexture.002"]}>
              <meshBasicMaterial toneMapped={false} color={lightColor} />
            </mesh>
            <mesh geometry={nodes.leftDoorLeft_2.geometry} material={materials["mirror.003"]} />
            <mesh geometry={nodes.leftDoorLeft_3.geometry} material={materials["mirror.003"]} />
          </group>

          {/* LAST */}
          <group scale-x={0.5} rotation-z={Math.PI} position-x={0.25}>
            <mesh geometry={nodes.leftDoorRight_1.geometry} material={materials["ledlightTexture.002"]}>
              <meshBasicMaterial toneMapped={false} color={lightColor} />
            </mesh>
            <mesh geometry={nodes.leftDoorRight_2.geometry} material={materials["mirror.003"]} />
            <mesh geometry={nodes.leftDoorRight_3.geometry} material={materials["mirror.003"]} />
          </group>
        </>
      ) : type === "first" ? (
        <>
          <mesh geometry={nodes.leftDoorLeft_1.geometry} material={materials["ledlightTexture.002"]}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
          <mesh geometry={nodes.leftDoorLeft_2.geometry} material={materials["mirror.003"]} />
          <mesh geometry={nodes.leftDoorLeft_3.geometry} material={materials["mirror.003"]} />
        </>
      ) : type === "mid" ? (
        <group scale={[0.815, 0.38, 1]}>
          <mesh geometry={chicagoNodes["2001"].geometry} material={chicagoMaterials.ledlightTexture}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
          <mesh geometry={chicagoNodes["2001_1"].geometry} material={chicagoMaterials.mirror} />
          <mesh geometry={chicagoNodes["2001_2"].geometry} material={chicagoMaterials.mirror} />
        </group>
      ) : type === "last" ? (
        <group rotation-z={Math.PI}>
          <mesh geometry={nodes.leftDoorRight_1.geometry} material={materials["ledlightTexture.002"]}>
            <meshBasicMaterial toneMapped={false} color={lightColor} />
          </mesh>
          <mesh geometry={nodes.leftDoorRight_2.geometry} material={materials["mirror.003"]} />
          <mesh geometry={nodes.leftDoorRight_3.geometry} material={materials["mirror.003"]} />
        </group>
      ) : null}
    </>
  );
};

export default ChicagoMirror;
