import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { EnumCabinetMirrors, changeCabinetMirrorType } from "../../../../store/cabinetMirror";
import { setCabinetMirrorTypeFiyatı } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const DolapTipi = () => {
  const dispatch = useDispatch();
  const { cabinetMirrorType } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const isPandora = [
    EnumCabinetMirrors.PANDORA_1,
    EnumCabinetMirrors.KATHERINE_1,
    EnumCabinetMirrors.VERONICA_1,
    EnumCabinetMirrors.PANDORA_2,
    EnumCabinetMirrors.KATHERINE_2,
    EnumCabinetMirrors.VERONICA_2,
    EnumCabinetMirrors.PANDORA_3,
    EnumCabinetMirrors.KATHERINE_3,
    EnumCabinetMirrors.VERONICA_3,
    EnumCabinetMirrors.PANDORA_4,
    EnumCabinetMirrors.KATHERINE_4,
    EnumCabinetMirrors.VERONICA_4,
  ].includes(selectedMirror);

  const isAluminum = [
    EnumCabinetMirrors.HAVEL,
    EnumCabinetMirrors.LECH,
    EnumCabinetMirrors.WESER,
    EnumCabinetMirrors.ELBE_1,
    EnumCabinetMirrors.NECKAR,
    EnumCabinetMirrors.LIPPE,
    EnumCabinetMirrors.WERRA,
  ].includes(selectedMirror);

  const renderInputDataValue = () => {
    // cabinetMirrorType == -0.15 ? 528 : cabinetMirrorType == 0 ? 529 : cabinetMirrorType == 0.2 ? 530 : cabinetMirrorType == -0.08 ? 2134 : null
    switch (cabinetMirrorType) {
      case -0.15:
        return 528;
        break;
      case 0:
        if (isPandora) {
          return 6416;
        } else {
          return 529;
        }
        break;
      case 0.2:
        return 530;
        break;
      case -0.08:
        return 2134;
        break;
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    dispatch(changeCabinetMirrorType(e.target.value));
    dispatch(setCabinetMirrorTypeFiyatı(value == 0 && !isPandora ? 39 : value == 0.2 ? 79 : value == -0.08 ? 79 : 0));
  };

  useEffect(() => {
    if (isPandora) {
      dispatch(changeCabinetMirrorType(0));
      dispatch(setCabinetMirrorTypeFiyatı(0));
    } else {
      dispatch(changeCabinetMirrorType(-0.15));
      dispatch(setCabinetMirrorTypeFiyatı(0));
    }
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal title="Informationen zur Schrank Art">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Schrank Art
        </Typography>
      </RenderModal>
      <Stack direction="row" justifyContent="space-evenly" sx={{ flexGrow: "1", fontSize: "0.7rem" }}>
        <input name="id[63]" value={renderInputDataValue()} />
        <Select onChange={handleChange} value={cabinetMirrorType} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
          {!isPandora && <MenuItem value={-0.15}>Unterputz / Einbau</MenuItem>}
          <MenuItem value={0}>Aufputz / Hängeschrank {!isPandora && "+ 39,00 €"}</MenuItem>
          {!isPandora && !isAluminum && <MenuItem value={0.2}>Unterputz mit Einbaurahmen + 79,00 €</MenuItem>}
          {!isPandora && !isAluminum && <MenuItem value={-0.08}>Halb-Einbau mit Einbaurahmen + 79,00 €</MenuItem>}
        </Select>
      </Stack>
    </Stack>
  );
};

export default DolapTipi;
