import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGLTF, Html } from "@react-three/drei";

const CamRaf = ({ buzluCam }) => {
  const { raf, kareAynaModel, camRafUzunlugu, mirrorWidth, mirrorHeight } = useSelector((state) => state.mirror);
  const { nodes: budapestNodes, materials: budapestMaterials } = useGLTF("/newModels/budapest.glb");
  const portauAndAurora = kareAynaModel == "Full_+_Kalın" || kareAynaModel == "Full_+_İnce_+_İç" ? true : false;
  const miami = kareAynaModel == "Alt_+_Üst";
  const [scale, setScale] = useState([(1.5 * camRafUzunlugu) / mirrorWidth + 0.16, 1.3, 1]);

  useEffect(() => {
    if (mirrorWidth < 600) {
      setScale([(1.25 * camRafUzunlugu) / mirrorWidth + 0, 1.3, 1]);
    } else {
      setScale([(1.2 * camRafUzunlugu) / mirrorWidth + 0.16, 1.3, 1]);
    }
  }, [mirrorWidth, mirrorHeight, camRafUzunlugu]);

  return raf === 1 ? (
    <mesh renderOrder={5} geometry={budapestNodes.bottomShelfBodyRear.geometry} scale={scale} material={budapestMaterials.glass} position={[0.01, 0.17, 0.4]} rotation={[Math.PI / 2, 0, 0]} />
  ) : raf === 2 ? (
    <mesh
      renderOrder={5}
      ref={buzluCam}
      geometry={budapestNodes.bottomShelfBodyRear.geometry}
      scale={scale}
      material={budapestMaterials["mirror.001"]}
      position={[0.01, 0.17, 0.4]}
      rotation={[Math.PI / 2, 0, 0]}
    />
  ) : null;
};

export default CamRaf;
