import React, { useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";
import Touch from "../../OrtakComponents/Sensor/Touch";
import Gesture from "../../OrtakComponents/Sensor/Gesture";
import { EnumMirrors } from "../../../store/mirrors";

export function MakyajAynasi(props) {
  const { nodes, materials } = useGLTF("/makyajaynasi.glb");
  const lightIsOpen = useSelector((state) => state.mirror.lightIsOpen);

  const {
    makeupMirror,
    selectedMirror,
    mirrorWidth,
    mirrorHeight,
    makeupLightColor,
    makeupMirrorPos,
    makeupMirrorSensor,
    makyajAynasiYandanUzaklik,
    makyajAynasiAlttanUzaklik,
    raf,
    analogWatch,
    digitalWatch,
    isiSensor,
    isiSensorPos,
    yataySensor,
    yataySensorPos,
    dikeySensor,
    dikeySensorPos,
    watchPos,
    kareAynaModel,
    soket,
    soketPos,
    doubleLed,
  } = useSelector((state) => state.mirror);
  const makyajayna = useRef();
  const isDaire = selectedMirror == EnumMirrors.CHARON || selectedMirror == EnumMirrors.DIANA || selectedMirror == EnumMirrors.HELENA;

  // // Position conditions
  const isiSensorCheck = isiSensor == 2 || isiSensor == 3;
  const rafExists = raf === 1 || raf === 2;
  const soketCheck = soket === 1 || soket === 2;
  const doubleSoketCheck = soket === 3 || soket === 4;

  const botConditions = (objectPos) => {
    if (
      (analogWatch || digitalWatch ? watchPos == objectPos : false) ||
      (yataySensor ? yataySensorPos == objectPos : dikeySensor ? dikeySensorPos == objectPos : false) ||
      (isiSensorCheck ? isiSensorPos == objectPos : false)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const botConditionsAll = (objectPos) => {
    if (
      (analogWatch || digitalWatch ? watchPos == objectPos : false) &&
      ((yataySensor ? yataySensorPos == objectPos : dikeySensor ? dikeySensorPos == objectPos : false) || (isiSensorCheck ? isiSensorPos == objectPos : false))
    ) {
      return true;
    } else {
      return false;
    }
  };
  // const objectsAll = (analogWatch || digitalWatch) && (yataySensor || dikeySensor || isiSensorCheck);
  // const topMid = (digitalWatch || analogWatch ? watchPos == 4 : false) && makyajAynasiAlttanUzaklik > 375 && makyajAynasiYandanUzaklik > 245 && makyajAynasiYandanUzaklik < 360;

  // Any Conditions
  const botRightRight = botConditions(2) && makyajAynasiYandanUzaklik < 205;
  const botRightLeft = botConditions(2) && makyajAynasiYandanUzaklik > 300;
  const botLeftRight = botConditions(0) && makyajAynasiYandanUzaklik > 300;
  const botLeftLeft = botConditions(0) && makyajAynasiYandanUzaklik < 205;
  const botMidRight = botConditions(1) && makyajAynasiYandanUzaklik > 225 && makyajAynasiYandanUzaklik < 285;
  const botMidLeft = botConditions(1) && makyajAynasiYandanUzaklik > 225 && makyajAynasiYandanUzaklik < 285;

  // All Conditions
  const botRightRightAll = botConditionsAll(2) && makyajAynasiYandanUzaklik < 205;
  const botRightLeftAll = botConditionsAll(2) && makyajAynasiYandanUzaklik > 320;
  const botMidRightAll = botConditionsAll(1) && makyajAynasiYandanUzaklik > 220 && makyajAynasiYandanUzaklik < 310;
  const botMidLeftAll = botConditionsAll(1) && makyajAynasiYandanUzaklik > 220 && makyajAynasiYandanUzaklik < 305;
  const botLeftRightAll = botConditionsAll(0) && makyajAynasiYandanUzaklik > 320;
  const botLeftLeftAll = botConditionsAll(0) && makyajAynasiYandanUzaklik < 205;

  // Watch Top Conditions
  const topLeftLeftConditionsWatch750 = mirrorHeight > 750 && mirrorHeight < 1425 && makyajAynasiYandanUzaklik < 225 && (analogWatch || digitalWatch) ? watchPos == 3 : false;
  const topLeftLeftConditionsWatch1425 = mirrorHeight > 1425 && makyajAynasiYandanUzaklik < 225 && (analogWatch || digitalWatch) ? watchPos == 3 : false;
  const topLeftRightConditionsWatch750 = mirrorHeight > 750 && mirrorHeight < 1425 && makyajAynasiYandanUzaklik > 350 && (analogWatch || digitalWatch) ? watchPos == 3 : false;
  const topLeftRightConditionsWatch1425 = mirrorHeight > 1425 && makyajAynasiYandanUzaklik > 350 && (analogWatch || digitalWatch) ? watchPos == 3 : false;
  const topRightLeftConditionsWatch750 = mirrorHeight > 750 && mirrorHeight < 1425 && makyajAynasiYandanUzaklik > 350 && (analogWatch || digitalWatch) ? watchPos == 5 : false;
  const topRightLeftConditionsWatch1425 = mirrorHeight > 1425 && makyajAynasiYandanUzaklik > 350 && (analogWatch || digitalWatch) ? watchPos == 5 : false;
  const topRightRightConditionsWatch750 = mirrorHeight > 750 && mirrorHeight < 1425 && makyajAynasiYandanUzaklik < 225 && (analogWatch || digitalWatch) ? watchPos == 5 : false;
  const topRightRightConditionsWatch1425 = mirrorHeight > 1425 && makyajAynasiYandanUzaklik < 225 && (analogWatch || digitalWatch) ? watchPos == 5 : false;
  const topMidConditionsWatch750 =
    mirrorHeight > 750 && mirrorHeight < 1425 && makyajAynasiYandanUzaklik > 225 && makyajAynasiYandanUzaklik < 355 && (analogWatch || digitalWatch) ? watchPos == 4 : false;
  const topMidConditionsWatch1425 = mirrorHeight > 1425 && makyajAynasiYandanUzaklik > 225 && makyajAynasiYandanUzaklik < 355 && (analogWatch || digitalWatch) ? watchPos == 4 : false;

  // Packed Conditions
  const botRightConditionsAll = botRightRightAll || botMidRightAll || botLeftRightAll;
  const botRightConditionsAny = botRightRight || botLeftRight || botMidRight;
  const botLeftConditionsAll = botRightLeftAll || botMidLeftAll || botLeftLeftAll;
  const botLeftConditionsAny = botRightLeft || botLeftLeft || botMidLeft;

  const botCornerMidWindow = kareAynaModel == "Full_+_Kalın" || kareAynaModel == "Full_+_İnce_+_İç";
  const botCornerWindow = kareAynaModel == "Sağ_+_Üst_+_Sol" || kareAynaModel == "Sağ_+_Sol_+_Uzun" || kareAynaModel == "Sağ_+_Sol";
  const botWindow = kareAynaModel == "Alt_+_Üst";
  // ---

  const calculatePosition = () => {
    const initialStateKareSag = [
      0.25 + mirrorWidth * 0.0000375 - (((makyajAynasiYandanUzaklik - 180) / (mirrorWidth - 360)) * 0.5 + 0.025),
      (botCornerMidWindow && rafExists && botRightConditionsAll
        ? 0.09
        : (botCornerMidWindow || botWindow) && botRightConditionsAll
        ? 0.0466
        : rafExists && botRightConditionsAll
        ? 0.1
        : rafExists && botRightConditionsAny
        ? 0.08
        : rafExists
        ? 0.02
        : (botCornerMidWindow || botWindow) && botRightConditionsAny
        ? -0.02
        : botRightConditionsAll
        ? 0.01
        : botRightConditionsAny
        ? -0.05
        : topLeftRightConditionsWatch750
        ? -0.12
        : topLeftRightConditionsWatch1425
        ? -0.17
        : topMidConditionsWatch750
        ? -0.14
        : topMidConditionsWatch1425
        ? -0.17
        : topRightRightConditionsWatch750
        ? -0.12
        : topRightRightConditionsWatch1425
        ? -0.17
        : -0.12) +
        mirrorHeight * 0.00012 +
        makyajAynasiAlttanUzaklik / (mirrorHeight < 850 ? mirrorHeight * 1.2 : mirrorHeight < 1200 ? mirrorHeight * 1.3 : mirrorHeight * 1.4),
      0.05,
    ];
    const initialStateKareSol = [
      -0.24 - mirrorWidth * 0.0000375 + (((makyajAynasiYandanUzaklik - 180) / (mirrorWidth - 360)) * 0.5 + 0.025),
      (botCornerMidWindow && rafExists && botLeftConditionsAll
        ? 0.09
        : botCornerMidWindow && rafExists && botLeftConditionsAny
        ? 0.06
        : botCornerMidWindow && rafExists
        ? 0.03
        : botWindow && botLeftConditionsAll && rafExists
        ? 0.09
        : rafExists && botLeftConditionsAll
        ? 0.08
        : rafExists && botLeftConditionsAny
        ? 0.06
        : rafExists
        ? 0.02
        : botCornerMidWindow && botLeftConditionsAll
        ? 0.04
        : botCornerMidWindow && botLeftConditionsAny
        ? -0.02
        : botWindow && botLeftConditionsAll
        ? 0.01
        : botWindow && botLeftConditionsAny
        ? -0.03
        : botLeftConditionsAll
        ? -0.04
        : botLeftConditionsAny && soketCheck
        ? 0
        : botLeftConditionsAny
        ? -0.03
        : topLeftLeftConditionsWatch750
        ? -0.12
        : topLeftLeftConditionsWatch1425
        ? -0.17
        : topMidConditionsWatch750
        ? -0.14
        : topMidConditionsWatch1425
        ? -0.17
        : topRightLeftConditionsWatch750
        ? -0.12
        : topRightLeftConditionsWatch1425
        ? -0.17
        : -0.12) +
        mirrorHeight * 0.00012 +
        makyajAynasiAlttanUzaklik / (mirrorHeight < 850 ? mirrorHeight * 1.2 : mirrorHeight < 1200 ? mirrorHeight * 1.3 : mirrorHeight * 1.4),
      0.05,
    ];
    const kareAynaPos = makeupMirrorPos == 1 ? initialStateKareSol : initialStateKareSag;
    return !isDaire
      ? kareAynaPos
      : isDaire
      ? [((makeupMirrorPos === 4 ? -0.03 : 0.15) * (makeupMirrorPos === 5 || makeupMirrorPos === 6 || makeupMirrorPos === 7 ? -mirrorWidth : mirrorWidth)) / 500, -0.3, 0]
      : [(0.15 * mirrorWidth) / 2000, -0.29, 0];
  };
  useEffect(() => {
    calculatePosition();
  }, [selectedMirror, makyajAynasiYandanUzaklik, makyajAynasiAlttanUzaklik]);

  // useEffect(() => {
  //   if (bottomLeftAny) {
  //     makyajayna.current.position.y = makyajayna.current.position.y + 0.1;
  //   }
  // }, [watchPos, dikeySensorPos, yataySensorPos, isiSensorPos, watchPos, soket, digitalWatch, analogWatch, yataySensor, dikeySensor, isiSensor, makeupMirror, makeupMirrorPos]);

  return (
    <>
      {makeupMirror === 0 ? null : (
        <group ref={makyajayna} rotation={[0, 0, 0]} scale={[1, 1, 1]} {...props} dispose={null}>
          <group
            position={calculatePosition()}
            rotation={[Math.PI / 2, 0, Math.PI]}
            // scale={
            //   selectedMirror == 2 || selectedMirror == 3
            //     ? mirrorWidth > 1500
            //       ? mirrorWidth / 1800
            //       : mirrorWidth < 1000
            //         ? mirrorWidth / 700
            //         : mirrorWidth / 1200
            //     : mirrorWidth > 1500
            //       ? mirrorWidth / 1000
            //       : mirrorWidth < 1000
            //         ? mirrorWidth / 700
            //         : mirrorWidth / 1200
            // }

            scale={!isDaire ? [(1 / mirrorWidth) * 400 * 0.5, 1, (1 / mirrorHeight) * 400 * 0.5] : [((1 * mirrorWidth) / 400) * 0.5, 0.3, ((1 * mirrorWidth) / 400) * 0.5]}
          >
            {makeupMirror == 2 && (
              <>
                {makeupMirrorSensor == 1
                  ? null
                  : // <Gesture
                  //   position={[0, 0, 0.3]}
                  //   scale={[3, 1, 3]}
                  //   rotation={[0, 0, 0]}
                  //   bloom={true}
                  // />
                  makeupMirrorSensor == 2
                  ? null
                  : // <Touch
                    //   // position={[
                    //   //   makeupMirrorSensorPos == 1 || makeupMirrorSensorPos == 2 || makeupMirrorSensorPos == 3
                    //   //     ? -0.1
                    //   //     : makeupMirrorSensorPos == 5 || makeupMirrorSensorPos == 6 || makeupMirrorSensorPos == 7
                    //   //     ? 0.1
                    //   //     : 0,
                    //   //   0,
                    //   //   makeupMirrorSensorPos == 1 || makeupMirrorSensorPos == 7 || makeupMirrorSensorPos == 8 ? -0.12 : makeupMirrorSensorPos == 2 || makeupMirrorSensorPos == 6 ? 0 : 0.12,
                    //   // ]}
                    //   position={[-0.03, 0.175, 0.3]}
                    //   scale={[3, 1, 3]}
                    //   rotation={[0, 0, 0]}
                    // />
                    null}
              </>
            )}
            <mesh geometry={nodes.Sphere001.geometry} material={materials.camRaf} />
            <mesh geometry={nodes.Sphere001_2.geometry} material={materials.ayna} />
            <mesh geometry={nodes.Sphere001_3.geometry} scale={[0.95, 1, 0.95]} position-y={0}>
              <meshStandardMaterial color="black" roughness={1} toneMapped={false} />
              {makeupMirror === 2 ? (
                <meshBasicMaterial color={doubleLed ? [makeupLightColor[0] / 2, makeupLightColor[1] / 2, makeupLightColor[2] / 2] : makeupLightColor} roughness={1} toneMapped={false} />
              ) : null}
            </mesh>
          </group>
        </group>
      )}
    </>
  );
}

useGLTF.preload("/makyajaynasi.glb");
