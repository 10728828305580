import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Html } from "@react-three/drei";
import CloseIcon from "@mui/icons-material/Close";
import { changeMirror } from "../../../store/mirrors";
import { setCabinetName } from "../../../store/cabinetFee";
import { EnumCabinets, changeSelectedCabinetExcelItem } from "../../../store/cabinet";
import { Avatar, Button, Divider, Drawer, Stack, Tooltip, tooltipClasses, useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import styled from "@emotion/styled";
const Panel = () => {
  const [lightTypes, setLightTypes] = useState();
  const { cabinetHeight } = useSelector((state) => state.cabinet);
  const { isPrivate, selectedMirror } = useSelector((state) => state.mirror);
  const dispatch = useDispatch();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isSingleDoorSubMenuOpen, setIsSingleDoorSubMenuOpen] = useState(true);
  const [activetab, setActiveTab] = useState(0); //0 >> Two Door, 1 >> One Door
  const [isTwoDoorSubMenuOpen, setIsTwoDoorSubMenuOpen] = useState(false);

  const twoDoorCabinets = [
    {
      name: "BHS005",
      value: EnumCabinets.BHS005,
      excelId: EnumCabinets.BHS005,
      excelName: "Bad Hochschrank Holz mit 2 Türen BHS005",
    },
    {
      name: "BHS006",
      value: EnumCabinets.BHS006,
      excelId: EnumCabinets.BHS006,
      excelName: "Hochschrank Bad Spiegelschrank BHS006",
    },

    {
      name: "BHS008L4",
      value: EnumCabinets.BHS008L4,
      excelId: EnumCabinets.BHS008L4,
      excelName: "Bad Wandschrank LED Hochschrank mit Spiegel BHS008L4",
    },
    {
      name: "BHS013L4",
      value: EnumCabinets.BHS013L4,
      excelId: EnumCabinets.BHS013L4,
      excelName: "LED Hochschrank Maßanfertigung mit Spiegel BHS013L4",
    },
    {
      name: "BHS009L4",
      value: EnumCabinets.BHS009L4,
      excelId: EnumCabinets.BHS009L4,
      excelName: "Hochschrank Bad mit Spiegel und Licht BHS009L4",
    },
    {
      name: "BHS004",
      value: EnumCabinets.BHS004,
      excelId: EnumCabinets.BHS004,
      excelName: "Wandschrank Bad, Spiegelschrank mit Regal BHS004",
    },
    {
      name: "BHS003",
      value: EnumCabinets.BHS003,
      excelId: EnumCabinets.BHS003,
      excelName: "Bad Hochschrank Holz mit 2 Türen und Regal BHS003",
    },
  ];
  const singleDoorCabinets = [
    {
      name: "BHS002A",
      value: EnumCabinets.BHS002A,
      excelId: EnumCabinets.BHS002A,
      excelName: "Hochschrank nach Maß 1 Tür Holz BHS002A",
    },
    {
      name: "BHS011",
      value: EnumCabinets.BHS011,
      excelId: EnumCabinets.BHS011,
      excelName: "",
    },
    {
      name: "BHS001",
      value: EnumCabinets.BHS001,
      excelId: EnumCabinets.BHS001,
      excelName: "Bad Hochschrank mit Spiegel BHS001",
    },
    {
      name: "BHS002B",
      value: EnumCabinets.BHS002B,
      excelId: EnumCabinets.BHS002B,
      excelName: "Seitenschrank Holz nach Maß BHS002B",
    },
    {
      name: "BHS007L4",
      value: EnumCabinets.BHS007L4,
      excelId: EnumCabinets.BHS007L4,
      excelName: "Hochschrank mit LED Beleuchtung Spiegelschrank BHS007L4",
    },
    {
      name: "BHS012L4",
      value: EnumCabinets.BHS012L4,
      excelId: EnumCabinets.BHS012L4,
      excelName: "Bad Hochschrank auf Maß mit Beleuchtung verspiegelt BHS012L4",
    },
  ];

  const handleDoorSelection = ({ name, value, excelName, excelId }) => {
    setIsMenuOpen(false);
    dispatch(changeMirror(value));
    // dispatch(setBaseFee(140.98));
    dispatch(setCabinetName(name));
    dispatch(
      changeSelectedCabinetExcelItem({
        name: excelName || "Bad Spiegelschrank - nur INNEN beleuchtet - BRISTOL",
        id: excelId || 2479,
      })
    );
  };

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
    const isTwoDoors = twoDoorCabinets.find((cabinet) => cabinet.value == selectedMirror);
    if (isTwoDoors) {
      handleSubTwoMenuClick();
    } else {
      handleSubSingleMenuClick();
    }
  };

  const handleSubSingleMenuClick = () => {
    setIsSingleDoorSubMenuOpen(true);
    setIsTwoDoorSubMenuOpen(false);
    setActiveTab(1);
  };

  const handleSubTwoMenuClick = () => {
    setIsSingleDoorSubMenuOpen(false);
    setIsTwoDoorSubMenuOpen(true);
    setActiveTab(0);
  };

  const [openState, setOpenState] = useState(true);
  const [initialClose, setInitialClose] = useState(false);

  const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#0d646f",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#0d646f",
      padding: "10px",
    },
  }));

  return (
    <Stack
      sx={{
        position: "fixed",
        zIndex: "1",
        top: "3vh",
        left: "3vw",
        display: "flex",
        alignItems: "flex-start",
      }}
      spacing={2.5}
    >
      <CustomTooltip
        PopperProps={{ style: { zIndex: 1 } }}
        open={openState}
        title={
          <div
            id="tooltipTextContainer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              id="tooltipTitleText"
              style={{
                fontSize: 14,
              }}
            >
              {initialClose ? "Modelle" : " Hier finden Sie alle Modelle"}
            </p>
            {!initialClose && (
              <CloseIcon
                cursor={"pointer"}
                onClick={() => {
                  setOpenState(false);
                  setInitialClose(true);
                }}
                sx={{
                  marginLeft: "10px",
                }}
              />
            )}
          </div>
        }
        arrow
        placement="right"
      >
        <EditIcon
          onClick={() => {
            handleMenuClick();
            setInitialClose(true);
            setOpenState(false);
          }}
          onMouseEnter={() => setOpenState(true)}
          onMouseLeave={() => setOpenState(false)}
          sx={{
            width: "40px",
            height: "40px",
            minHeight: "40px",
            fontWeight: "700",
            bgcolor: isMenuOpen ? "#eeeeee" : "#0d646f",
            color: isMenuOpen ? "#0d646f" : "#eeeeee",
            borderRadius: "100%",
            p: "8px",
            "&:hover": {
              bgcolor: "#0d646f",
              color: "#eeeeee",
            },
            cursor: "pointer",
          }}
        />
      </CustomTooltip>

      <Drawer
        anchor="left"
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: `${mobileMatches ? "400px" : " 100vw"}`,
          },
        }}
      >
        <Stack
          spacing={3.5}
          sx={{
            width: `${mobileMatches ? "400px" : " 100vw"}`,
            maxWidth: `${mobileMatches ? "400px" : " 100vw"}`,
            height: "100vh",
            padding: "32px 16px",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          divider={<Divider light sx={{ width: 1 }} />}
        >
          <Stack
            spacing={1}
            direction={"row"}
            sx={{
              flexWrap: "wrap",
              width: "100%",
              maxWidth: "100%",
              justifyContent: "space-evenly",
            }}
          >
            <Tooltip title={"Einzeltür"}>
              <Avatar
                src={"/ikonlar/ikon_dolapdolap.png"}
                onClick={handleSubSingleMenuClick}
                sx={{
                  backgroundColor: `${activetab == 1 ? "#0d646f" : "#fff"}`,
                  color: `${activetab == 1 ? "#fff" : "#0d646f"}`,
                  "&:hover": {
                    bgcolor: "#0d646f",
                    color: "#fff",
                  },
                  cursor: "pointer",
                  borderRadius: "8px",
                  padding: "8px",
                  width: "58px",
                  height: "58px",
                }}
              />
            </Tooltip>
            <Tooltip title={"Zweitürig"}>
              <Avatar
                src={"/ikonlar/ikon_dolapdolap.png"}
                onClick={handleSubTwoMenuClick}
                sx={{
                  backgroundColor: `${activetab == 0 ? "#0d646f" : "#fff"}`,
                  color: `${activetab == 0 ? "#fff" : "#0d646f"}`,
                  "&:hover": {
                    bgcolor: "#0d646f",
                    color: "#fff",
                  },
                  cursor: "pointer",
                  borderRadius: "8px",
                  padding: "8px",
                  width: "58px",
                  height: "58px",
                }}
              />
            </Tooltip>
          </Stack>

          <Stack
            spacing={4}
            sx={{
              width: "100%",
              flexGrow: 1,
              alignItems: "center",
              padding: "16px",
              overflowY: "auto",
            }}
          >
            {isSingleDoorSubMenuOpen && singleDoorCabinets.map((cabinet, index) => <ClosetButton cabinet={cabinet} handleDoorSelection={handleDoorSelection} index={index} />)}

            {isTwoDoorSubMenuOpen && twoDoorCabinets.map((cabinet, index) => <ClosetButton cabinet={cabinet} handleDoorSelection={handleDoorSelection} index={index} />)}
          </Stack>
        </Stack>
      </Drawer>
    </Stack>
  );
};

export default Panel;

const ClosetButton = ({ cabinet, handleDoorSelection, index }) => {
  const { selectedMirror } = useSelector((state) => state.mirror);
  const animationDelay = index * 50;
  const isSelected = selectedMirror === cabinet.value;

  return (
    <Button
      key={cabinet.value}
      onClick={() => handleDoorSelection({ name: cabinet.name, value: cabinet.value, excelId: cabinet.excelId, excelName: cabinet.excelName })}
      sx={{
        fontWeight: "700",
        bgcolor: isSelected ? "#0d646f" : "#eeeeee",
        color: isSelected ? "#ffffff" : "#0d646f",
        "&:hover": {
          color: "#eeeeee",
          bgcolor: "#0d646f",
          boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 6px",
        },
        opacity: "0",
        transform: "translateY(-200%)",
        "@keyframes dropDown": {
          "0%": {
            opacity: 0,
            transform: "translateY(-200%)",
          },
          "80%": {
            opacity: 0,
            transform: "translateY(-50%)",
          },
          "100%": {
            opacity: 1,
            transform: "translateY(0)",
          },
        },
        animation: `0.25s ease-in ${animationDelay}ms dropDown forwards`,
        width: 0.75,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "64px",
        p: 0,
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
    >
      <Avatar
        src={`https://www.spiegel21.de/images/product_images/info_images/${cabinet.value}_0.jpg`}
        sx={{ mr: 3, height: "64px", width: "64px", borderRadius: "0px", borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px" }}
      />

      {cabinet.name}
    </Button>
  );
};
