import React from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setIsiSensorPos } from "../../../store/mirrors";

const IsiSensor = () => {
  const dispatch = useDispatch();
  const { isiSensorPos } = useSelector((state) => state.mirror);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Position Schalter/Sensor
      </Typography>
      <input name="id[493]" value={isiSensorPos == 0 ? 9679 : isiSensorPos == 1 ? 9678 : isiSensorPos == 2 ? 9677 : null} />
      <Select onChange={(e) => dispatch(setIsiSensorPos(e.target.value))} value={isiSensorPos} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={5}>1:30 Uhr (rechts oben)</MenuItem>
        <MenuItem value={6}>3:00 Uhr (rechts)</MenuItem>
        <MenuItem value={2}>4:30 Uhr (rechts unten)</MenuItem>
        <MenuItem value={1}>6:00 Uhr (unten mittig)</MenuItem>
        <MenuItem value={0}>7:30 Uhr (links unten)</MenuItem>
        <MenuItem value={7}>9:00 Uhr (links)</MenuItem>
        <MenuItem value={3}>10:30 Uhr (links oben)</MenuItem>
        <MenuItem value={4}>12:00 Uhr (oben)</MenuItem>
      </Select>
    </Stack>
    // }
  );
};

export default IsiSensor;
