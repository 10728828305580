import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";
import { EnumMirrors } from "../../../store/mirrors";
import CentimeterHeightLines from "../../OrtakComponents/CM/CentimeterHeightLines";
import CentimeterWidthLines from "../../OrtakComponents/CM/CentimeterWidthLines";
import F610L4K from "./ovalMirrors/F610L4K";
import F597L4K from "./ovalMirrors/F597L4K";
import CamRaf from "./ovalMirrors/objects/CamRaf";
import { MakyajAynasi } from "./ovalMirrors/objects/MakyajAynasi";
import ObjelerLeft from "./KareAynaComponents/objeler/ObjelerLeft";
import ObjelerMid from "./KareAynaComponents/objeler/ObjelerMid";
import ObjelerRight from "./KareAynaComponents/objeler/ObjelerRight";
import * as THREE from "three";

export function Oval(props) {
  const rectangle = useRef();
  const buzluCam = useRef();
  const ovalMerkez = useRef();
  const { mirrorWidth, mirrorHeight, analogWatch, digitalWatch, heightCmVisible, widthCmVisible, makeupMirrorPos, makyajAynasiYandanUzaklik, makyajAynasiAlttanUzaklik, raf, selectedMirror } =
    useSelector((state) => state.mirror);
  useEffect(() => {
    if (buzluCam.current) {
      buzluCam.current.material.roughness = 0.25;
    }
  });

  const [widthOfWidth, setWidthOfWidth] = useState(-47 - mirrorWidth * 0.565);
  const [leftOfWidth, setLeftOfWidth] = useState(-9 + mirrorWidth * 1.16);
  const [heightOfHeight, setHeightOfHeight] = useState(0 + mirrorHeight * 1.01);
  const [topOfHeight, setTopOfHeight] = useState(110 - mirrorHeight * 0.3);
  const [positionOfWidth, setPositionOfWidth] = useState(-0.87 + mirrorHeight * 0.0021);
  const [positionOfHeight, setPositionOfHeight] = useState(-0.45 + mirrorWidth * 0.00175);

  const leftLimit = useRef(null);
  const rightLimit = useRef(null);
  const topLimit = useRef(null);
  const bottomLimit = useRef(null);

  const rafExists = raf === 1 || raf === 2;
  const [makyajPosition, setMakyajPosition] = useState([0, 0, 0.025]);

  const isF610L4K = EnumMirrors.F610L4K === selectedMirror;
  const isF597L4K = EnumMirrors.F597L4K === selectedMirror;

  useEffect(() => {
    if (isF597L4K) {
      setWidthOfWidth(455 + mirrorWidth * 0.52);
      setLeftOfWidth(-265 - mirrorWidth * 0.265);
      setHeightOfHeight(350 + mirrorHeight * 0.775);
      setTopOfHeight(55 - mirrorHeight * 0.5);
      setPositionOfWidth(-0.65 + mirrorHeight * 0.0015);
      setPositionOfHeight(0.325 + mirrorWidth * 0.0008);
    } else if (isF610L4K) {
      setWidthOfWidth(290 + mirrorWidth * 0.86);
      setLeftOfWidth(-185 - mirrorWidth * 0.44);
      setHeightOfHeight(460 + mirrorHeight * 0.67);
      setTopOfHeight(-10 - mirrorHeight * 0.425);
      setPositionOfWidth(-0.55 + mirrorHeight * 0.0013);
      setPositionOfHeight(0.1 + mirrorWidth * 0.0013);
    }

    if (ovalMerkez.current) {
      ovalMerkez.current.scale.z = 1.4 + mirrorWidth * 0.0032;
      ovalMerkez.current.scale.x = 0.1 + mirrorHeight * 0.00152;
    }
  }, [mirrorHeight, mirrorWidth]);

  useEffect(() => {
    const objectsAreHere = leftLimit.current && rightLimit.current && topLimit.current && bottomLimit.current;

    if (objectsAreHere) {
      const limitLeft = leftLimit.current.position.x;
      const limitRight = rightLimit.current.position.x;
      const limitTop = topLimit.current.position.y;
      const limitBottom = bottomLimit.current.position.y;

      let makX;
      let makY;
      let makZ = 0.14;
      const degisimX = (0.85 * (makyajAynasiYandanUzaklik - 180)) / (mirrorWidth - 360);
      const degisimY = (0.85 * (makyajAynasiAlttanUzaklik - 180)) / (mirrorHeight - 360);
      if (makeupMirrorPos == 1) {
        makX = limitLeft + degisimX * (limitRight - limitLeft);
        if (makX > limitRight) {
          makX = limitRight;
        }
        makY = limitBottom + degisimY * (limitTop - limitBottom);
        if (makY > limitTop) {
          makY = limitTop;
        }
      } else {
        makX = limitRight - degisimX * (limitRight - limitLeft);
        if (makX < limitLeft) {
          makX = limitLeft;
        }
        makY = limitBottom + degisimY * (limitTop - limitBottom);
        if (makY > limitTop) {
          makY = limitTop;
        }
      }
      setMakyajPosition([makX - 0.1, makY + 0.155, makZ]);
    }
  }, [mirrorWidth, mirrorHeight, makyajAynasiYandanUzaklik, makyajAynasiAlttanUzaklik, makeupMirrorPos, analogWatch, digitalWatch, raf]);

  return (
    <>
      {widthCmVisible && (
        <CentimeterWidthLines
          scale={[0.118, 0.3, 0.15]}
          textWidth={mirrorWidth > 999 ? "75px" : "70px"}
          textPadding={mirrorHeight > 999 ? "0 12px" : "0 14px"}
          textValue={mirrorWidth == 0 ? "400" : mirrorWidth}
          textSize={"1.4em"}
          textHeight="35px"
          lineLeft={leftOfWidth}
          lineWidth={widthOfWidth}
          position={[0, positionOfWidth, 0.1]}
        />
      )}
      {heightCmVisible && (
        <CentimeterHeightLines
          scale={[0.3, 0.12, 0.15]}
          textWidth={mirrorHeight > 999 ? "75px" : "70px"}
          textPadding={mirrorHeight > 999 ? "0 12px" : "0 14px"}
          textValue={mirrorHeight == 0 ? "400" : mirrorHeight}
          textSize={"1.4em"}
          textHeight="35px"
          textTop={275 - mirrorHeight * 0.225}
          lineTop={topOfHeight}
          lineHeight={heightOfHeight}
          position={[positionOfHeight, -0.3, 0.1]}
        />
      )}

      {/* MAKYAJ AYNASI LIMITLERI */}
      <group position-z={-0.001}>
        {/* LEFT */}
        <mesh ref={leftLimit} position={[-0.4 - ((mirrorWidth - 400) / 400) * (isF597L4K ? 0.27 : 0.4), 0, 0]} scale={2}>
          <meshBasicMaterial side={THREE.DoubleSide} transparent={true} opacity={0} color={"red"} />
          <boxGeometry args={[0.01, 0.3, 0.01]} />
        </mesh>
        {/* RIGHT */}
        <mesh ref={rightLimit} position={[0.65 + ((mirrorWidth - 400) / 400) * (isF597L4K ? 0.27 : 0.4), 0, 0]} scale={2}>
          <meshBasicMaterial side={THREE.DoubleSide} transparent={true} opacity={0} color={"red"} />
          <boxGeometry args={[0.01, 0.3, 0.01]} />
        </mesh>
        {/* TOP */}
        <mesh ref={topLimit} position={[0, 0.1 + ((mirrorHeight - 400) / 400 / 0.6) * 0.3, 0]} scale={2}>
          <meshBasicMaterial side={THREE.DoubleSide} transparent={true} opacity={0} color={"red"} />
          <boxGeometry args={[0.7, 0.01, 0.01]} />
        </mesh>
        {/* BOTTOM */}
        <mesh ref={bottomLimit} position={[0, (analogWatch || digitalWatch ? -0.8 : -1) - ((mirrorHeight - 400) / 400) * 0.85 * 0.18, 0]} scale={2}>
          <meshBasicMaterial side={THREE.DoubleSide} transparent={true} opacity={0} color={"red"} />
          <boxGeometry args={[0.7, 0.01, 0.01]} />
        </mesh>
      </group>
      <MakyajAynasi position={makyajPosition} />

      <ObjelerLeft position={[-0.2 - mirrorWidth * 0.0005, -0.74 - mirrorHeight * 0.00072, 0.11]} />
      <ObjelerMid position={[0, -0.84 - mirrorHeight * 0.00072, 0.11]} />
      <ObjelerRight position={[0.17 + mirrorWidth * 0.0005, -0.74 - mirrorHeight * 0.00072, 0.11]} />

      <group ref={rectangle} {...props} position={[0, -1 + mirrorHeight * 0.0003, 0.1]} rotation={[0, 0, 0]} scale={[1, 1, 1]} dispose={null}>
        {/* Cam raf */}
        <CamRaf buzluCam={buzluCam} />
        {/* AYNA TIPLERI */}

        {isF610L4K && <F610L4K />}
        {isF597L4K && <F597L4K />}
      </group>
    </>
  );
}

useGLTF.preload("/rectangle-mirror-rev01.glb");
