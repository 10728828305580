import React, { useEffect } from "react";
import { Stack, Slider, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeLavaboTavanLightColor } from "../../../../store/cabinetMirror";
import { setLedIsikFiyatı } from "../../../../store/cabinetMirrorFee";

const LavaboTavanLedIsıgı = () => {
  const dispatch = useDispatch();
  const { lavaboTavanLightColorIndex } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const handleChange = (e) => {
    dispatch(changeLavaboTavanLightColor(e.target.value));
  };
  useEffect(() => {
    dispatch(changeLavaboTavanLightColor(0));
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        W/D Lichtfarbe
      </Typography>
      <input
        type="hidden"
        name="id[94]"
        value={
          lavaboTavanLightColorIndex == 0
            ? 777
            : lavaboTavanLightColorIndex == 1
            ? 778
            : lavaboTavanLightColorIndex == 2
            ? 779
            : lavaboTavanLightColorIndex == 3
            ? 780
            : lavaboTavanLightColorIndex == 4
            ? 2270
            : null
        }
      />
      <Select onChange={handleChange} value={lavaboTavanLightColorIndex} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>---</MenuItem>
        <MenuItem value={1}>Neutralweiß 4000K</MenuItem>
        <MenuItem value={2}>Warmweiß 3000K</MenuItem>
        <MenuItem value={3}>Kaltweiß 6000K</MenuItem>
        <MenuItem value={4}>bei Gestensteuerung: Lichtfarbe wie oben</MenuItem>
      </Select>
    </Stack>
  );
};

export default LavaboTavanLedIsıgı;
