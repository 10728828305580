import React, { useState, useEffect } from "react";
import { Stack, Box, Typography, Avatar, ImageListItem, Tooltip, useMediaQuery, MenuItem, Select, TextField } from "@mui/material";

import { useDispatch } from "react-redux";

import RenderModal from "../../OrtakComponents/Modal/RenderModal";
import { wunschdekorOptions } from "../Mirrors/KareAynaComponents/wunschdekorOptions";
import { setBodyDecor } from "../../../store/mirrors";

const Rahmen = () => {
  //TODO RENKLER ARRAYDEN GELSIN

  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("1918");

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="16">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Rahmen
        </Typography>
      </RenderModal>

      <input name="id[99]" value={selectedValue} />

      <Select
        // value={selectedHtml}
        defaultValue={"H3840_ST9.jpeg,0,1918"}
        sx={{
          width: "100%",
          height: "2.2rem",
          fontSize: 12,
          ".MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
        }}
        onChange={(e) => {
          const splitValue = e.target.value.split(",");
          const imageName = splitValue[0];
          const value = splitValue[2];

          dispatch(setBodyDecor(imageName));
          setSelectedValue(value);
        }}
      >
        {wunschdekorOptions.map((item) => {
          const spliteItemTitle = item.title.split(" ");
          const imageName = spliteItemTitle[0] + "_" + spliteItemTitle[1] + ".jpeg";
          const imageLink = "./decors/" + imageName + "";
          const itemTitle = [];
          spliteItemTitle.forEach((word, i) => {
            if (i !== 0 && i !== 1) itemTitle.push(word);
          });

          return (
            <MenuItem value={`${imageName},${item.price},${item.value}`} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              {itemTitle.join(" ")}
              <Avatar
                alt={item.title}
                src={imageLink}
                sx={{
                  height: "1.5rem",
                  width: "1.5rem",
                  alignSelf: "flex-end",
                }}
              />
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
};

export default Rahmen;
