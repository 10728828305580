import React, { useEffect, useState } from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setYataySensor, setDikeySensor, setSensors } from "../../../store/mirrors";
import { setYataySensorFee, setKareSensorFee, setGecisAnahtariSensorFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";
const Sensorler = () => {
  const dispatch = useDispatch();
  const { yataySensor, dikeySensor, lightColorIndex } = useSelector((state) => state.mirror);
  const [showMessage, setShowMessage] = useState(0);
  const [selectedSensor, setSelecetedSensor] = useState(0);
  const lightSensor = lightColorIndex == 0 || lightColorIndex == 1 || lightColorIndex == 2;

  const updateSensorSettings = (value) => {
    const sensorSettings = {
      0: { yatay: false, dikey: false, yatayFee: 0, kareFee: 0, gecisAnahtariFee: 0 },
      1: { yatay: true, dikey: false, yatayFee: 59.99, kareFee: 0, gecisAnahtariFee: 0 },
      2: { yatay: false, dikey: true, yatayFee: 0, kareFee: 49.99, gecisAnahtariFee: 0 },
      3: { yatay: false, dikey: false, yatayFee: 0, kareFee: 0, gecisAnahtariFee: 10 },
    };

    const settings = sensorSettings[value] || sensorSettings[0]; // Default to 0 if value is not found
    dispatch(setYataySensor(settings.yatay));
    dispatch(setDikeySensor(settings.dikey));
    dispatch(setYataySensorFee(settings.yatayFee));
    dispatch(setKareSensorFee(settings.kareFee));
    dispatch(setGecisAnahtariSensorFee(settings.gecisAnahtariFee));
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSelecetedSensor(value);
    setShowMessage(false);
    dispatch(setSensors(value));

    if (lightSensor) {
      updateSensorSettings(value);
    } else {
      setShowMessage(value != 0 || !(lightColorIndex == 5 && value == 1));
    }
  };

  useEffect(() => {
    setShowMessage(!lightSensor && selectedSensor != 0 && !(lightColorIndex == 5 && selectedSensor == 1));
    if (lightSensor) {
      updateSensorSettings(selectedSensor);
    }
  }, [lightSensor, selectedSensor]);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <RenderModal title="Informationen zu Sensor/Schalter">
          <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
            Sensor/Schalter
          </Typography>
        </RenderModal>
        <input name="id[140]" value={selectedSensor == 0 ? 1313 : selectedSensor == 1 ? 1316 : selectedSensor == 2 ? 1315 : selectedSensor == 3 ? 1317 : null} />
        <Select onChange={handleChange} value={selectedSensor} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
          <MenuItem selected value={0}>
            ---
          </MenuItem>
          <MenuItem value={3}>Kippschalter + 10,00 €</MenuItem>
          <MenuItem value={2}>Touch-Sensor + 49,99 €</MenuItem>
          <MenuItem value={1}>Gestensteuerung dimmbar + 59,99 €</MenuItem>
        </Select>
      </Stack>
      {showMessage && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            position: "relative",
            top: -10,
          }}
        >
          <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem", mt: 0 }}></Typography>
          <small
            style={{
              color: "tomato",
            }}
          >
            Die Kombination „einschließlich Warm- und Kaltweiß…“ und „Sensor/Schalter“ ist nicht möglich. Bitte entfernen Sie eine der Optionen.
          </small>
        </Stack>
      )}
    </>
  );
};

export default Sensorler;
