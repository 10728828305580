const Footer = (props) => {
  return (
    <div
      style={{
        height: "10%",
        padding: 15,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop:"1px solid lightgray"
      }}
    >
      {props.children}
    </div>
  );
};
export default Footer;
