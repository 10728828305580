import * as React from "react";

import { Stack, Box, Slide, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setMakeupMirrorType, setSummaryModalOpen } from "../../store/mirrors";
import { SpokeTwoTone } from "@mui/icons-material";
import $ from "jquery";
import { EnumMirrors } from "../../store/mirrors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SummaryModal() {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(setSummaryModalOpen(false));
  const {
    lightColorIndex,
    digitalWatch,
    analogWatch,
    isiSensor,
    soket,
    sealing,
    hangingPlates,
    dikeySensor,
    yataySensor,
    makeupMirror,
    makeupMirrorType,
    bluetooth,
    suspansiyon,
    customerComment,
    summaryModalOpen,
    selectedMirror,
    mirrorWidth,
    mirrorHeight,
    lightIsOpen,
    sızdırmazlık,
    orderAmount,
    watchPos,
    sensors,
  } = useSelector((state) => state.mirror);
  const { baseFee, suspansiyonFee, ledIsigiFee, ciftLedFee, yataySensorFee, kareSensorFee, isiSensorFee, saatFee, soketFee, sealingFee, hangingPlatesFee, makyajFee, bluetoothFee, sizdirmazlikFee } =
    useSelector((state) => state.mirrorFee);

  const isDaire = selectedMirror == EnumMirrors.CHARON || selectedMirror == EnumMirrors.DIANA || selectedMirror == EnumMirrors.HELENA;

  const dimensionRule = isDaire ? mirrorWidth > 1200 : mirrorWidth > 1200 && mirrorHeight > 800;

  const dimensionFee = dimensionRule ? 49.99 : 0;

  const feeData = [
    {
      name: "Modelpreis",
      fee: baseFee || 888,
    },
    {
      name: "Aufhängebleche",
      fee: suspansiyonFee,
    },
    {
      name: "Lichtfarbe",
      fee: ledIsigiFee,
    },
    {
      name: "LED-Band doppelt",
      fee: ciftLedFee,
    },
    {
      name: "Sensor/Schalter",
      fee: yataySensorFee,
    },
    {
      name: "Gestensteuerung dimmbar",
      fee: yataySensorFee,
    },
    {
      name: "Touch-Sensor",
      fee: kareSensorFee,
    },
    {
      name: "Versiegelung",
      fee: sealingFee,
    },
    {
      name: "Aufhängebleche",
      fee: hangingPlatesFee,
    },
    {
      name: analogWatch ? "Analoguhr" : "Digitaluhr mit APP Synchronisierung",
      fee: saatFee,
    },
    {
      name: "Spiegelheizung",
      fee: isiSensorFee,
    },
    {
      name: "Steckdose(n) inkl. Bohrung",
      fee: soketFee,
    },
    {
      name: "Schminkspiegel",
      fee: makyajFee,
    },
    {
      name: "Bluetooth Lautsprecher",
      fee: bluetoothFee,
    },
    {
      name: "Versiegelung",
      fee: sizdirmazlikFee,
    },
    {
      name: "Summe",
      fee:
        (baseFee +
          suspansiyonFee +
          ledIsigiFee +
          ciftLedFee +
          yataySensorFee +
          kareSensorFee +
          isiSensorFee +
          saatFee +
          soketFee +
          sealingFee +
          makyajFee +
          bluetoothFee +
          sizdirmazlikFee +
          hangingPlatesFee +
          dimensionFee) *
        orderAmount,
    },
  ];
  const isPaintedMirror = [EnumMirrors.F620TL, EnumMirrors.F621TL, EnumMirrors.F622TL, EnumMirrors.F623TL].includes(selectedMirror);

  const isKristall = [EnumMirrors.STARK_RAHMENLOS_5MM, EnumMirrors.SILBER_RAHMENLOS_3MM, EnumMirrors.RAHMENLOSER_6_MM].includes(selectedMirror);

  const summaryData = [
    {
      name: "Spiegeltyp", // emircan buraya state çakacak
      value: isDaire ? "Runde Spiegel" : !isDaire ? "Rechteckigspiegel" : "", // burdan bahsediyorsak çakmışım gören biri var ise teyit edip kaldırabilir
    },
    {
      name: isDaire ? "Durchmesser" : "Breite",
      value: mirrorWidth,
    },
    {
      name: "Aufhängebleche",
      value: suspansiyon == 1 ? "Standard (lose beiliegend)" : suspansiyon == 2 ? "Standard (vormontiert - fertig zum Aufhängen)" : "ProfiPlus-Set (lose beiliegend)",
    },
    {
      name: "Lichtfarbe",
      value:
        lightColorIndex == 0
          ? "LED Neutralweiß 4000K"
          : lightColorIndex == 1
          ? "LED Warmweiß 3000K"
          : lightColorIndex == 2
          ? "LED Kaltweiß 6000K"
          : lightColorIndex == 3
          ? "LED RGB inkl. Fernbedienung"
          : lightColorIndex == 4
          ? "LED Warm- und Kaltweiß mit Doppel-Touch-Sensor"
          : lightColorIndex == 5
          ? "LED Dimmbar 2700K bis 6300K inkl. Gestensteuerung"
          : "LED Dimmbar 2700K bis 6300K mit Fernbedienung",
    },
    {
      name: "LED-Band doppelt", //Davuthan ile konuşulacak
      value: lightIsOpen ? "Ja" : "-",
    },
    {
      name: "Sensor/Schalter",
      value: sensors == 1 ? "Touch-Sensor" : sensors == 2 ? "Gestensteuerung dimmbar" : sensors == 3 ? "Kippschalter" : "-",
    },
    {
      name: "Analog-/ Digitaluhr",
      value: analogWatch ? "Analoguhr" : digitalWatch ? "Digitaluhr mit APP Synchronisierung" : "-",
    },
    {
      name: "Position Uhr",
      value:
        isDaire && watchPos == 0
          ? "7:30 Uhr (links unten)"
          : isDaire && watchPos == 1
          ? "6:00 Uhr (unten mittig)"
          : isDaire && watchPos == 2
          ? "4:30 Uhr (rechts unten)"
          : isDaire && watchPos == 3
          ? "10:30 Uhr (links oben)"
          : isDaire && watchPos == 4
          ? "12:00 Uhr (oben)"
          : isDaire && watchPos == 5
          ? "1:30 Uhr (rechts oben)"
          : isDaire && watchPos == 6
          ? "3:00 Uhr (rechts)"
          : isDaire && watchPos == 7
          ? "9:00 Uhr (links)"
          : watchPos == 0
          ? "unten links"
          : watchPos == 1
          ? "unten mitte"
          : watchPos == 2
          ? "unten rechts"
          : watchPos == 3
          ? "oben links"
          : watchPos == 4
          ? "oben mitte"
          : watchPos == 5
          ? "oben rechts"
          : "",
    },
    {
      name: "Spiegelheizung",
      value: isiSensor == 1 ? "mit ECO friendly Switch Sensor" : isiSensor == 2 ? "mit Kippschalter" : isiSensor == 3 ? "mit Touch-Sensor" : "-",
    },
    {
      name: "Steckdose(n) inkl. Bohrung",
      value: soket == 1 ? "1 x chrom" : soket == 2 ? "1 x schwarz" : soket == 3 ? "2 x chrom" : soket == 4 ? "2 x schwarz" : "-",
    },
    {
      name: "Aufhängebleche",
      value: hangingPlates == 1 ? "Standard (vormontiert - fertig zum Aufhängen)" : hangingPlates == 2 ? "ProfiPlus-Set (lose beiliegend)" : "Standard (lose beiliegend)",
    },
    {
      name: "Versiegelung",
      value: sealing == 1 ? "Ja (Lieferzeit +1 Tag)" : "-",
    },
    {
      name: "Kanten",
      value: "Hochglanz Poliert",
    },

    {
      name: "Schminkspiegel",
      value: makeupMirror == 1 ? "5-fach Vergrößerung, unbeleuchtet" : makeupMirror == 2 ? "5-fach Vergrößerung, beleuchtet" : "-",
    },
    {
      name: "Schminkspiegel Typ",
      value: makeupMirrorType == 0 ? "Ø 152 mm, auf Glas" : makeupMirrorType == 1 ? "Ø 152 mm, integriert" : "-",
    },
    {
      name: "Bluetooth Lautsprecher",
      value: bluetooth == true ? "WHD High-End Sound-System" : "-", //Yeni state ekle
    },
    {
      name: "Versiegelung", //Sızdırmazlık
      value: sızdırmazlık == true ? "Ja" : "-",
    },
    {
      name: "Ihre Bemerkung",
      value: customerComment ? customerComment : "-",
    },
    {
      name: "Menge",
      value: orderAmount, //buna da yeni state
    },
  ];

  const mobileMatches600 = useMediaQuery("(min-width:500px)");

  return (
    <Dialog TransitionComponent={Transition} maxWidth="xl" open={summaryModalOpen} onClose={handleClose} sx={{ zIndex: "999999999" }}>
      <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h5" fontWeight="800" color="#0d646f" my={3}>
          Zusammenfassung der Bestellung
        </Typography>
      </DialogTitle>
      <DialogContent spacing={1} sx={{ maxHeight: "75vh" }} dividers={"paper"}>
        {summaryData.map((item) => {
          if (item.name == "Schminkspiegel Typ" && makeupMirror == 0) {
            return;
          }
          if (item.name === "Kanten" && !isPaintedMirror && !isKristall) {
            return null;
          }
          if (item.name === "Aufhängebleche" && !isPaintedMirror) {
            return null;
          }
          if (item.name == "Position Uhr" && analogWatch == 0 && digitalWatch == 0) {
            return;
          } else {
            return (
              <Box
                sx={{
                  width: "100%",
                  minHeight: "4vh",
                  lineHeight: "4vh",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: "5px",
                  overflow: "hidden",
                  backgroundColor: "#edf0f0",
                  padding: "0 1rem",
                  color: "#1f1d1d",
                  my: 1,
                }}
              >
                <Box
                  sx={{
                    width: "35%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textAlign: "left",
                  }}
                >
                  <Typography variant={mobileMatches600 ? "body2" : "caption"} fontWeight={900}>
                    {item.name}
                  </Typography>
                  <Typography sx={{ textAlign: "left", fontWeight: "900" }}>:</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "1rem",
                    lineHeight: "100%",
                  }}
                >
                  <Typography variant={mobileMatches600 ? "body2" : "caption"}>{item.value}</Typography>
                </Box>
              </Box>
            );
          }
        })}
        <Box
          sx={{
            width: "55vw",
            minHeight: "4vh",
            lineHeight: "4vh",
            textAlign: "center",
            display: "flex",
            flexDirection: mobileMatches600 ? "row" : "column",
            alignItems: "flex-start",
            borderRadius: "5px",
            overflow: "hidden",
            backgroundColor: "#edf0f0",
            padding: mobileMatches600 ? "0 1rem" : "0 0.25rem",
            color: "#1f1d1d",
          }}
        >
          <Box
            sx={{
              minWidth: "35%",
              height: "4vh",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "left",
              pl: mobileMatches600 ? 2 : 0.5,
            }}
          >
            <Typography variant={mobileMatches600 ? "body2" : "caption"} fontWeight={900}>
              Details zur Preisgestaltung
            </Typography>
            <Typography variant={mobileMatches600 ? "body2" : "caption"} sx={{ textAlign: "left", fontWeight: "900" }}>
              :
            </Typography>
          </Box>
          <Stack direction="column" sx={{ flexGrow: 1, pl: 2 }}>
            {feeData.map((item) =>
              item.fee > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    minHeight: "4vh",
                  }}
                >
                  <Typography variant="subtitle2" fontWeight={item.name === "Summe" ? 900 : 300}>
                    {item.name}
                  </Typography>
                  <Typography variant="subtitle2" fontWeight={item.name === "Summe" ? 900 : 300}>
                    {item.fee} €
                  </Typography>
                </Box>
              ) : null
            )}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} variant="contained" color="error" sx={{ fontWeight: "800", height: "2.5rem", mr: 1 }}>
          Weiter shoppen
        </Button>
        <Button
          onClick={() => {
            handleClose();
            $("#cart_quantity_mirror").submit();
          }}
          variant="contained"
          sx={{
            fontWeight: "800",
            height: "3rem",

            backgroundColor: "#0d646f",
          }}
        >
          Zur Kasse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
