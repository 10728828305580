import React, { useEffect, useState } from "react";
import { Stack, Slider, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeLightColor } from "../../../../store/cabinetMirror";
import { setLedIsikFiyatı } from "../../../../store/cabinetMirrorFee";

const LedIsıgı = () => {
  const dispatch = useDispatch();
  const { lightColorIndex } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const [idValue, setIdValue] = useState();
  const handleChange = (e) => {
    dispatch(changeLightColor(e.target.value));
    dispatch(setLedIsikFiyatı(e.target.value === 3 ? 79.99 : e.target.value === 4 ? 179.99 : e.target.value === 5 ? 159.0 : 0));
    setIdValue(e.target.value == 0 ? 9 : e.target.value == 1 ? 10 : e.target.value == 2 ? 11 : e.target.value == 3 ? 94 : e.target.value == 4 ? 2303 : e.target.value === 5 ? 8863 : null);
  };

  useEffect(() => {
    dispatch(changeLightColor(0));
    dispatch(setLedIsikFiyatı(0));
    setIdValue(9);
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Lichtfarbe
      </Typography>
      <input name="id[5]" type="hidden" value={idValue} />
      <Select onChange={handleChange} value={lightColorIndex} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>LED Neutralweiß 4000K</MenuItem>
        <MenuItem value={1}>LED Warmweiß 3000K</MenuItem>
        <MenuItem value={2}>LED Kaltweiß 6000K</MenuItem>
        <MenuItem value={3}>LED RGB inkl. Fernbedienung + 79,99 €</MenuItem>
        <MenuItem value={4}>LED Dimmbar 2700K bis 6300K inkl. Gestensteuerung + 179,99 €</MenuItem>
        <MenuItem value={5}>LED Dimmbar 2700K bis 6300K mit Fernbedienung + 159,00 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default LedIsıgı;
