import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Html } from "@react-three/drei";
import CloseIcon from "@mui/icons-material/Close";
import { EnumMirrors, changeLightThickness, changeMirror, changeMirrorWidth, changeSelectedExcelItem, setSquareMirrorSelectedItem } from "../../../store/mirrors";
import { setCabinetName } from "../../../store/cabinetFee";
import { EnumCabinets, changeSelectedCabinetExcelItem } from "../../../store/cabinet";
import { Avatar, Button, Divider, Drawer, Stack, Tooltip, tooltipClasses, useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { getKeyByValue } from "../../../utils/helper";
import styled from "@emotion/styled";
const Panel = () => {
  const [lightTypes, setLightTypes] = useState();
  const { cabinetHeight } = useSelector((state) => state.cabinet);
  const { isPrivate, selectedMirror } = useSelector((state) => state.mirror);
  const dispatch = useDispatch();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isSingleDoorSubMenuOpen, setIsSingleDoorSubMenuOpen] = useState(true);
  const [activetab, setActiveTab] = useState(3); //0 >> Tek Işıklı, 1 >> İki Işıklı, 2 >> Üç Işıklı, 3 >> Dört Işıklı
  const [isTwoDoorSubMenuOpen, setIsTwoDoorSubMenuOpen] = useState(false);

  const [categories, setCategories] = useState([]);

  const [products, setProducts] = useState([]);

  const handleDoorSelection = ({ name, value, excelName, excelId }) => {
    setIsMenuOpen(false);
    switch (value) {
      case EnumMirrors.PLUTO_2:
        dispatch(changeMirror(EnumMirrors.CHARON));
        dispatch(changeLightThickness(0.66));
        dispatch(
          changeSelectedExcelItem({
            name: "Pluto 2",
            id: EnumMirrors.PLUTO_2,
          })
        );
        break;
      case EnumMirrors.CHARON:
        dispatch(changeMirror(EnumMirrors.CHARON));
        dispatch(changeLightThickness(1.05));
        dispatch(
          changeSelectedExcelItem({
            name: "Charon",
            id: EnumMirrors.CHARON,
          })
        );
        break;
      case EnumMirrors.HELENA:
        dispatch(changeMirror(EnumMirrors.CHARON));
        dispatch(changeLightThickness(1.03));
        dispatch(
          changeSelectedExcelItem({
            name: "Helena",
            id: EnumMirrors.HELENA,
          })
        );
        break;
      case EnumMirrors.DIANA:
        dispatch(changeMirror(EnumMirrors.CHARON));
        dispatch(changeLightThickness(1.01));
        dispatch(
          changeSelectedExcelItem({
            name: "Diana",
            id: EnumMirrors.DIANA,
          })
        );
        break;
      case EnumMirrors.RAHMENLOSER_6_MM:
        dispatch(changeMirror(EnumMirrors.RAHMENLOSER_6_MM));
        dispatch(setSquareMirrorSelectedItem("Kristallspiegel"));
        dispatch(
          changeSelectedExcelItem({
            name: "RAHMENLOSER 6 MM",
            id: EnumMirrors.RAHMENLOSER_6_MM,
          })
        );
        break;
      case EnumMirrors.STARK_RAHMENLOS_5MM:
        dispatch(changeMirror(EnumMirrors.STARK_RAHMENLOS_5MM));
        dispatch(setSquareMirrorSelectedItem("Kristallspiegel"));
        dispatch(
          changeSelectedExcelItem({
            name: "STARK RAHMENLOS 5MM",
            id: EnumMirrors.STARK_RAHMENLOS_5MM,
          })
        );
        break;
      case EnumMirrors.SILBER_RAHMENLOS_3MM:
        dispatch(changeMirror(EnumMirrors.SILBER_RAHMENLOS_3MM));
        dispatch(setSquareMirrorSelectedItem("Kristallspiegel"));
        dispatch(
          changeSelectedExcelItem({
            name: "SILBER RAHMENLOS 3MM",
            id: EnumMirrors.SILBER_RAHMENLOS_3MM,
          })
        );
        break;
      case EnumMirrors.NEW_JERSEY:
        dispatch(changeMirror(122));
        dispatch(setSquareMirrorSelectedItem("Arka"));
        dispatch(
          changeSelectedExcelItem({
            name: "New Jersey",
            id: EnumMirrors.NEW_JERSEY,
          })
        );
        break;
      case EnumMirrors.NEW_YORK:
        dispatch(changeMirror(122));
        dispatch(setSquareMirrorSelectedItem("Full_+_Dış"));
        dispatch(
          changeSelectedExcelItem({
            name: "New York",
            id: EnumMirrors.NEW_YORK,
          })
        );
        break;
      case EnumMirrors.BOLNUEVO:
        dispatch(changeMirror(122));
        dispatch(setSquareMirrorSelectedItem("Full_+_İnce"));
        dispatch(
          changeSelectedExcelItem({
            name: "Bolnuevo",
            id: EnumMirrors.BOLNUEVO,
          })
        );
        break;
      case EnumMirrors.PORTAU:
        dispatch(changeMirror(122));
        dispatch(setSquareMirrorSelectedItem("Full_+_Kalın"));
        dispatch(
          changeSelectedExcelItem({
            name: "Portau",
            id: EnumMirrors.PORTAU,
          })
        );
        break;
      case EnumMirrors.NAMUS:
        dispatch(changeMirror(122));
        dispatch(setSquareMirrorSelectedItem("Üst"));
        dispatch(
          changeSelectedExcelItem({
            name: "Namus",
            id: EnumMirrors.NAMUS,
          })
        );
        break;
      case EnumMirrors.M18L2V:
        dispatch(changeMirror(122));
        dispatch(setSquareMirrorSelectedItem("Sağ_+_Sol_+_Uzun"));
        dispatch(
          changeSelectedExcelItem({
            name: "M18L2V",
            id: EnumMirrors.M18L2V,
          })
        );
        break;
      case EnumMirrors.MIAMI:
        dispatch(changeMirror(122));
        dispatch(setSquareMirrorSelectedItem("Alt_+_Üst"));
        dispatch(
          changeSelectedExcelItem({
            name: "Miami",
            id: EnumMirrors.MIAMI,
          })
        );
        break;
      case EnumMirrors.M01L2V:
        dispatch(changeMirror(122));
        dispatch(setSquareMirrorSelectedItem("Sağ_+_Sol"));
        dispatch(
          changeSelectedExcelItem({
            name: "M01L2V",
            id: EnumMirrors.M01L2V,
          })
        );
        break;
      case EnumMirrors.SANTA_CRUZ:
        dispatch(changeMirror(122));
        dispatch(setSquareMirrorSelectedItem("Sağ_+_Sol_+_Üst_+_Dış"));
        dispatch(
          changeSelectedExcelItem({
            name: "Santa Cruz",
            id: EnumMirrors.SANTA_CRUZ,
          })
        );
        break;
      case EnumMirrors.GREEN_BAY:
        dispatch(changeMirror(122));
        dispatch(setSquareMirrorSelectedItem("Sağ_+_Üst_+_Sol"));
        dispatch(
          changeSelectedExcelItem({
            name: "Green Bay",
            id: EnumMirrors.GREEN_BAY,
          })
        );
        break;
      case EnumMirrors.AUROA:
        dispatch(changeMirror(122));
        dispatch(setSquareMirrorSelectedItem("Full_+_İnce_+_İç"));
        dispatch(
          changeSelectedExcelItem({
            name: "Aurora",
            id: EnumMirrors.AUROA,
          })
        );
        break;
      case EnumMirrors.F620TL:
        dispatch(changeMirror(EnumMirrors.F620TL));
        dispatch(
          changeSelectedExcelItem({
            name: "F620TL",
            id: EnumMirrors.F620TL,
          })
        );
        break;
      case EnumMirrors.F621TL:
        dispatch(changeMirror(EnumMirrors.F621TL));
        dispatch(
          changeSelectedExcelItem({
            name: "F621TL",
            id: EnumMirrors.F621TL,
          })
        );
        break;
      case EnumMirrors.F622TL:
        dispatch(changeMirror(EnumMirrors.F622TL));
        dispatch(
          changeSelectedExcelItem({
            name: "F622TL",
            id: EnumMirrors.F622TL,
          })
        );
        break;
      case EnumMirrors.F623TL:
        dispatch(changeMirror(EnumMirrors.F623TL));
        dispatch(
          changeSelectedExcelItem({
            name: "F623TL",
            id: EnumMirrors.F623TL,
          })
        );
        break;
      case EnumMirrors.F610L4K:
        dispatch(changeMirror(EnumMirrors.F610L4K));
        dispatch(
          changeSelectedExcelItem({
            name: "F610L4K",
            id: EnumMirrors.F610L4K,
          })
        );
        break;
      case EnumMirrors.F597L4K:
        dispatch(changeMirror(EnumMirrors.F597L4K));
        dispatch(
          changeSelectedExcelItem({
            name: "F597L4K",
            id: EnumMirrors.F597L4K,
          })
        );
        break;
      case EnumMirrors.WUNSCHDEKOR:
        dispatch(changeMirror(EnumMirrors.WUNSCHDEKOR));
        dispatch(
          changeSelectedExcelItem({
            name: "WUNSCHDEKOR",
            id: EnumMirrors.WUNSCHDEKOR,
          })
        );
        break;
    }
  };
  const isPaintedMirror = [EnumMirrors.F620TL, EnumMirrors.F621TL, EnumMirrors.F622TL, EnumMirrors.F623TL].includes(selectedMirror);
  const isKristall = [EnumMirrors.STARK_RAHMENLOS_5MM, EnumMirrors.SILBER_RAHMENLOS_3MM, EnumMirrors.RAHMENLOSER_6_MM].includes(selectedMirror);
  const isOvalMirror = [EnumMirrors.F597L4K, EnumMirrors.F610L4K].includes(selectedMirror);
  const isWunschdekor = [EnumMirrors.WUNSCHDEKOR].includes(selectedMirror);
  useEffect(() => {
    if (isOvalMirror || (selectedMirror != 122 && !isPaintedMirror && !isKristall && !isWunschdekor)) {
      setProducts([
        {
          value: EnumMirrors.CHARON,
          categoryId: 3,
        },
        {
          value: EnumMirrors.HELENA,
          categoryId: 3,
        },
        {
          value: EnumMirrors.DIANA,
          categoryId: 3,
        },
        {
          value: EnumMirrors.PLUTO_2,
          categoryId: 3,
        },
        {
          value: EnumMirrors.F597L4K,
          categoryId: 6,
        },
        {
          value: EnumMirrors.F610L4K,
          categoryId: 6,
        },
      ]);
      setCategories([
        {
          id: 3,
          categoryName: "Kreisspiegel",
          icon: "/ikonlar/ikon_yuvarlakayna_ışıksız.png",
        },
        {
          id: 6,
          categoryName: "Ovale Spiegel",
          icon: "/ikonlar/ikon_ovalkose.png",
        },
      ]);
    } else {
      setCategories([
        {
          id: 3,
          categoryName: "Vier Lichter",
          icon: "/ikonlar/ikon_aynamodeli_dört_taraf_ışıklı.png",
        },
        {
          id: 2,
          categoryName: "Vier Lichter",
          icon: "/ikonlar/ikon_aynamodeli_üç_taraf_ışıklı.png",
        },
        {
          id: 1,
          categoryName: "Zwei Lichter",
          icon: "/ikonlar/ikon_aynamodeli_çift_ışıklı.png",
        },
        {
          id: 0,
          categoryName: "Einzelnes Licht",
          icon: "/ikonlar/ikon_aynamodeli_tek_ışıklı.png",
        },
        {
          id: 4,
          categoryName: "Teilweise Bemalter Spiegel",
          icon: "/ikonlar/ikon_aynamodeli_tek_ışıklı.png",
        },
        {
          id: 5,
          categoryName: "Kristall Spiegel",
          icon: "/ikonlar/ikon_aynamodeli_tek_ışıklı.png",
        },
        {
          id: 6,
          categoryName: "Spiegel mit Rahmen",
          icon: "/ikonlar/ikon_aynamodeli_tek_ışıklı.png",
        },
      ]);
      setProducts([
        {
          value: EnumMirrors.NAMUS,
          categoryId: 0,
        },

        {
          value: EnumMirrors.M01L2V,
          categoryId: 1,
        },
        {
          value: EnumMirrors.MIAMI,
          categoryId: 1,
        },
        {
          value: EnumMirrors.M18L2V,
          categoryId: 1,
        },
        {
          value: EnumMirrors.GREEN_BAY,
          categoryId: 2,
        },
        {
          value: EnumMirrors.SANTA_CRUZ,
          categoryId: 2,
        },
        {
          value: EnumMirrors.NEW_JERSEY,
          categoryId: 3,
        },
        {
          value: EnumMirrors.AUROA,
          categoryId: 3,
        },
        {
          value: EnumMirrors.PORTAU,
          categoryId: 3,
        },
        {
          value: EnumMirrors.BOLNUEVO,
          categoryId: 3,
        },
        {
          value: EnumMirrors.NEW_YORK,
          categoryId: 3,
        },
        {
          value: EnumMirrors.F620TL,
          categoryId: 4,
        },
        {
          value: EnumMirrors.F621TL,
          categoryId: 4,
        },
        {
          value: EnumMirrors.F622TL,
          categoryId: 4,
        },
        {
          value: EnumMirrors.F623TL,
          categoryId: 4,
        },
        {
          value: EnumMirrors.RAHMENLOSER_6_MM,
          categoryId: 5,
        },
        {
          value: EnumMirrors.STARK_RAHMENLOS_5MM,
          categoryId: 5,
        },
        {
          value: EnumMirrors.SILBER_RAHMENLOS_3MM,
          categoryId: 5,
        },
        {
          value: EnumMirrors.WUNSCHDEKOR,
          categoryId: 6,
        },
      ]);
    }
  }, [selectedMirror]);

  const { selectedMirrorName } = useSelector((state) => state.mirror);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
    const activeId = products.find((p) => getKeyByValue(p.value, EnumMirrors).replace("_", " ").toLowerCase() == selectedMirrorName.toLowerCase()).categoryId;
    setActiveTab(activeId);
  };

  const handleCategoriesButton = (activeCategory) => {
    setActiveTab(activeCategory);
  };

  const [openState, setOpenState] = useState(true);
  const [initialClose, setInitialClose] = useState(false);

  const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#0d646f",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#0d646f",
      padding: "10px",
    },
  }));

  return (
    <Stack
      sx={{
        position: "fixed",
        zIndex: "1",
        top: "3vh",
        left: "3vw",
        display: "flex",
        alignItems: "flex-start",
      }}
      spacing={2.5}
    >
      <CustomTooltip
        PopperProps={{ style: { zIndex: 1 } }}
        open={openState}
        title={
          <div
            id="tooltipTextContainer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              id="tooltipTitleText"
              style={{
                fontSize: 14,
              }}
            >
              {initialClose ? "Modelle" : " Hier finden Sie alle Modelle"}
            </p>
            {!initialClose && (
              <CloseIcon
                cursor={"pointer"}
                onClick={() => {
                  setOpenState(false);
                  setInitialClose(true);
                }}
                sx={{
                  marginLeft: "10px",
                }}
              />
            )}
          </div>
        }
        arrow
        placement="right"
      >
        <EditIcon
          onClick={() => {
            handleMenuClick();
            setInitialClose(true);
            setOpenState(false);
          }}
          onMouseEnter={() => setOpenState(true)}
          onMouseLeave={() => setOpenState(false)}
          sx={{
            width: "40px",
            height: "40px",
            minHeight: "40px",
            fontWeight: "700",
            bgcolor: isMenuOpen ? "#eeeeee" : "#0d646f",
            color: isMenuOpen ? "#0d646f" : "#eeeeee",
            borderRadius: "100%",
            p: "8px",
            "&:hover": {
              bgcolor: "#0d646f",
              color: "#eeeeee",
            },
            cursor: "pointer",
          }}
        />
      </CustomTooltip>
      <Drawer
        anchor="left"
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: `${mobileMatches ? "400px" : " 100vw"}`,
          },
        }}
      >
        <Stack
          spacing={3.5}
          sx={{
            width: `${mobileMatches ? "400px" : " 100vw"}`,
            maxWidth: `${mobileMatches ? "400px" : " 100vw"}`,
            height: "100vh",
            padding: "32px 16px",
            justifyContent: "center",
            alignItems: "center",
          }}
          divider={<Divider light sx={{ width: 1 }} />}
        >
          <Stack
            spacing={1}
            direction={"row"}
            sx={{
              flexWrap: "wrap",
              width: "60%",
              maxWidth: "100%",
              gap: 1,
              justifyContent: "space-between",
            }}
          >
            {categories.map((category, index) => (
              <Tooltip title={category.categoryName}>
                <Avatar
                  src={category.icon}
                  onClick={() => handleCategoriesButton(category.id)}
                  sx={{
                    backgroundColor: `${activetab == category.id ? "#0d646f" : "#fff"}`,
                    color: `${activetab == category.id ? "#fff" : "#0d646f"}`,
                    "&:hover": {
                      bgcolor: "#0d646f",
                      color: "#fff",
                    },
                    cursor: "pointer",
                    borderRadius: "8px",
                    padding: "8px",
                    width: "56px",
                    height: "56px",
                    ml: 1,
                  }}
                />
              </Tooltip>
            ))}
          </Stack>

          <Stack
            spacing={4}
            sx={{
              width: "100%",
              flexGrow: 1,
              alignItems: "center",
              padding: "16px",
              overflowY: "auto",
            }}
          >
            {/* Render Products */}

            {products
              .filter((p) => p.categoryId == activetab)
              .map((cabinet, index) => (
                <ClosetButton cabinet={cabinet} handleDoorSelection={handleDoorSelection} index={index} />
              ))}
          </Stack>
        </Stack>
      </Drawer>
    </Stack>
  );
};

export default Panel;

const ClosetButton = ({ cabinet, handleDoorSelection, index }) => {
  const { selectedMirrorName } = useSelector((state) => state.mirror);
  const animationDelay = index * 50;
  const name = getKeyByValue(cabinet.value, EnumMirrors).replace("_", " ");
  const isSelected = selectedMirrorName.toLowerCase() == name.toString().toLowerCase();
  return (
    <Button
      key={cabinet.value}
      onClick={() =>
        handleDoorSelection({
          name: name,
          value: cabinet.value,
          excelId: cabinet.excelId,
          excelName: cabinet.excelName,
        })
      }
      sx={{
        fontWeight: "700",
        bgcolor: isSelected ? "#0d646f" : "#eeeeee",
        color: isSelected ? "#ffffff" : "#0d646f",
        "&:hover": {
          color: "#eeeeee",
          bgcolor: "#0d646f",
          boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 6px",
        },
        opacity: "0",
        transform: "translateY(-200%)",
        "@keyframes dropDown": {
          "0%": {
            opacity: 0,
            transform: "translateY(-200%)",
          },
          "80%": {
            opacity: 0,
            transform: "translateY(-50%)",
          },
          "100%": {
            opacity: 1,
            transform: "translateY(0)",
          },
        },
        animation: `0.25s ease-in ${animationDelay}ms dropDown forwards`,
        width: 0.75,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "64px",
        p: 0,
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
    >
      <Avatar
        src={`https://www.spiegel21.de/images/product_images/info_images/${cabinet.value}_0.jpg`}
        sx={{
          mr: 3,
          height: "64px",
          width: "64px",
          borderRadius: "0px",
          borderTopLeftRadius: "4px",
          borderBottomLeftRadius: "4px",
        }}
      />

      {name}
    </Button>
  );
};
