import React, { createRef, useEffect, useRef, useState } from "react";
import { useGLTF, Html } from "@react-three/drei";

import { useSelector } from "react-redux";
import { useDrag } from "@use-gesture/react";

import { extend, useThree } from "@react-three/fiber";
import { Effects } from "@react-three/drei";
import { UnrealBloomPass } from "three-stdlib";

const Cm = () => {
  const { cabinetMirrorWidth, cabinetMirrorHeight, cabinetMirrorDepth, camCikintiUst } = useSelector((state) => state.cabinetMirror);

  return (
    <mesh>
      <Html transform={true} position={[0, 0.5 + camCikintiUst * 0.007, 0]} scale={[0.1, 0.07 + cabinetMirrorWidth * 0.00003, 0.1]} occlude rotation={[0, 0, 0]}>
        <div
          // style={{
          //   display: "none"
          // }}

          hidden
          id="cabinetWidth"
        >
          <div
            // onClick={(e) => {
            //   alert("Click");
            // }}
            style={{
              width: 500,
              position: "relative",
              left: 0,
              height: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div
              style={{
                position: "relative",
                top: "-2px",
                right: "3px",
              }}
            >
              |
            </div>
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                color: "#fff",
                borderRadius: 100,
                padding: "0 8px",
              }}
            >
              <span
                style={{
                  fontSize: "1.1em",
                }}
                // id="cabinetWidth"
              >
                {cabinetMirrorWidth}
              </span>
            </div>
            <div
              style={{
                position: "relative",
                top: "-2px",
                left: "3px",
              }}
            >
              |
            </div>
          </div>
        </div>
      </Html>
      <Html transform={true} position={[1, 0, 0]} scale={[0.12 - cabinetMirrorWidth * 0.00002, 0.1, 0.1]} occlude rotation={[0, 0, 0]}>
        <div
          // style={{
          //   display: "none"
          // }}

          hidden
          id="cabinetHeight"
        >
          <div
            // onClick={(e) => {
            //   alert("Click");
            // }}
            style={{
              minWidth: 300,
              position: "relative",
              left: 0,
              height: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              rotate: "90deg",
            }}
          >
            <div
              style={{
                position: "relative",
                top: "-2px",
                right: "3px",
              }}
            >
              |
            </div>
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                color: "#fff",
                borderRadius: 100,
                padding: "0 8px",
                rotate: "-90deg",
              }}
            >
              <span
                style={{
                  fontSize: "1.1em",
                }}
                id="cabinetHeight"
              >
                {cabinetMirrorHeight}
              </span>
            </div>
            <div
              style={{
                position: "relative",
                top: "-2px",
                left: "3px",
              }}
            >
              |
            </div>
          </div>
        </div>
      </Html>
      <Html transform={true} position={[-0.77, 0, -0.11]} scale={[0.1, 0.06, 1]} occlude rotation={[Math.PI * -1.5, Math.PI * 1.5, 0]}>
        <div
          // style={{
          //   display: "none"
          // }}

          hidden
          id="cabinetDepth"
        >
          <div
            // onClick={(e) => {
            //   alert("Click");
            // }}
            style={{
              minWidth: 125,
              position: "relative",
              left: 0,
              height: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              rotate: "90deg",
            }}
          >
            <div
              style={{
                position: "relative",
                top: "-2px",
                right: "3px",
              }}
            >
              |
            </div>
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                color: "#fff",
                borderRadius: 100,
                padding: "0 8px",
                rotate: "180deg",
              }}
            >
              <span
                style={{
                  fontSize: "1.1em",
                }}
                id="cabinetDepth"
              >
                {cabinetMirrorDepth}
              </span>
            </div>
            <div
              style={{
                position: "relative",
                top: "-2px",
                left: "3px",
              }}
            >
              |
            </div>
          </div>
        </div>
      </Html>
    </mesh>
  );
};

export default Cm;
