import React, { useEffect, useRef } from "react";
import { DoubleSide } from "three";
import { Man2 } from "../../OrtakComponents/Man2";
import { useSelector } from "react-redux";
import { EnumCabines } from "../../../store/cabinet";

const TestRoom = (props) => {
  const rightWall = useRef();
  const leftWall = useRef();
  const topWall = useRef();
  const botWall = useRef();

  const { cabinetDepth, cabinetWidth, cabinetHeight, cabinetType } = useSelector((state) => state.cabinet);

  const { selectedMirror } = useSelector((state) => state.mirror);

  useEffect(() => {
    if (cabinetType === 0 || cabinetType === 0.2) {
      leftWall.current.position.x = 5.7;
      leftWall.current.position.z = 0;
      leftWall.current.scale.x = 2;
    } else {
      leftWall.current.position.z = -13.22 - cabinetWidth * 0.00061;
      rightWall.current.position.z = 13.15 + cabinetWidth * 0.00051;
      topWall.current.position.y = 6.5 + cabinetHeight * 0.00273;
      leftWall.current.position.x = 5.43;
      leftWall.current.scale.x = 1;
    }
  }, [cabinetType, selectedMirror, cabinetHeight, cabinetWidth]);

  return (
    <mesh {...props} rotation-y={Math.PI * 0.4945} position-z={5.42}>
      <boxGeometry attach="geometry" args={[300, 300, 300, 300]} />
      <meshBasicMaterial side={DoubleSide} color="#e3e3e3" />

      <mesh ref={topWall} rotation={[0, -Math.PI / 2.025, 0]} position={[5.67, 11.4, 0]}>
        <planeGeometry attach="geometry" args={[15, 20]} />
        <meshBasicMaterial side={DoubleSide} color="#c7c7c7" />
      </mesh>
      <mesh ref={rightWall} rotation={[0, -Math.PI / 2.0218, 0]} position={[5.9, 6.6, 14.73 + cabinetWidth * 0.0006]}>
        <planeGeometry attach="geometry" args={[26.43, 20]} />
        <meshBasicMaterial side={DoubleSide} color="#c7c7c7" />
      </mesh>
      <mesh ref={leftWall} rotation={[0, -Math.PI / 2.0218, 0]} position={[5.4, 6.6, -14.9 - cabinetWidth * 0.00001]}>
        <planeGeometry attach="geometry" args={[25.9, 20]} />
        <meshBasicMaterial side={DoubleSide} color="#c7c7c7" />
      </mesh>
    </mesh>
  );
};

export default TestRoom;
