import React, { useState, useEffect } from "react";
import { Stack, Box, Typography, Avatar, MenuItem, Select } from "@mui/material";

import { setBodyDecor } from "../../../store/cabinet";
import { useDispatch, useSelector } from "react-redux";

import { renklerKategorili } from "../../../utils/data/data";
import { setDekorFiyatı } from "../../../store/cabinetFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const GovdeDoku = () => {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("257");

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="16">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Dekor Korpus
        </Typography>
      </RenderModal>

      <input name="id[21]" value={selectedValue} />

      <Select
        defaultValue={"H1176_ST37.jpeg,9.99,257"}
        sx={{
          width: "100%",
          height: "2.2rem",
          fontSize: 12,
          ".MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
        }}
        onChange={(e) => {
          const splitValue = e.target.value.split(",");
          const imageName = splitValue[0];
          const price = parseFloat(splitValue[1]);
          const value = splitValue[2];

          dispatch(setBodyDecor(imageName));
          dispatch(setDekorFiyatı(price));
          setSelectedValue(value);
        }}
      >
        {renklerKategorili.map((item) => {
          const spliteItemTitle = item.title.split(" ");
          const imageName = spliteItemTitle[0] + "_" + spliteItemTitle[1] + ".jpeg";
          const imageLink = "./decors/" + imageName + "";
          const itemTitle = item.title + " €" + item.price;
          return (
            <MenuItem value={`${imageName},${item.price},${item.value}`} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              {itemTitle}
              <Avatar
                alt={item.title}
                src={imageLink}
                sx={{
                  height: "1.5rem",
                  width: "1.5rem",
                  alignSelf: "flex-end",
                }}
              />
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
};

export default GovdeDoku;
