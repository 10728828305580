import { createSlice, current } from "@reduxjs/toolkit";

export const EnumMirrors = {
  //TODO Enum Mirrors Spiegel diye belirleyelim
  CHARON: 1677,
  HELENA: 1680,
  DIANA: 637,
  NEW_JERSEY: 122,
  NEW_YORK: 615,
  BOLNUEVO: 377,
  PORTAU: 258,
  AUROA: 265,
  SANTA_CRUZ: 244,
  GREEN_BAY: 279,
  M18L2V: 623,
  MIAMI: 345,
  M01L2V: 634,
  NAMUS: 193,
  //YENİ EKLENENLER
  F610L4K: 6699,
  F597L4K: 2822,
  RAHMENLOSER_6_MM: 304,
  STARK_RAHMENLOS_5MM: 2691,
  SILBER_RAHMENLOS_3MM: 2689,
  WUNSCHDEKOR: 3092,
  PLUTO_2: 3967,
  F620TL: 7775,
  F621TL: 7778,
  F622TL: 7781,
  F623TL: 7784,
};

let initalMirrorState = {
  bodyDecor: "H3840_ST9.jpeg",
  selectedMirror: EnumMirrors.CHARON,
  isPrivate: false,
  squareMirrorSelectedItem: "",
  selectedMirrorName: "Charon",
  selectedMirrorExcelIndex: 1677,
  selectedDisableMirrors: [],
  lightIsOpen: false,
  doubleLed: false,
  mirrorWidth: 400,
  mirrorHeight: 400,
  lightColor: [100, 100, 100],
  makeupLightColor: [100, 100, 100],
  mirrorTickness: 1,
  //---Modal için stateler
  lightColorIndex: 0,
  ciftSensorPos: 0,
  makeupLightColorIndex: -1,
  digitalWatch: false,
  analogWatch: false,
  watchPos: 0,
  horizontalSensor: false,
  squareSensor: false,
  makeupMirror: 0,
  makeupMirrorType: 0,
  makeupMirrorPos: 1,
  makeupMirrorSensor: 0,
  makeupMirrorSensorPos: 5,
  customerComment: "",
  summaryModalOpen: false,
  watchPosition: [0.15, 0.18, 0.09],
  rectangeWatchPosition: [0.5, 0.15, 0.03],
  curvedWatchPositon: [0.35, 0.15, 0.01],
  yataySensor: false,
  yataySensorPos: 0,
  isiSensor: 0,
  isiSensorType: 0,
  isiSensorPos: 0,
  dikeySensor: false,
  dikeySensorPos: 0,
  kaplama: 0,
  raf: 0,
  faceta: 0,
  aynaIsıtma: 0,
  aynaIsıtmaPos: 0,
  backLight: false,
  suspansiyon: 1,
  soket: 0,
  hangingPlates: 0,
  sealing: 0,
  soketPos: 0,
  bluetooth: 0,
  sızdırmazlık: 0,
  orderAmount: 1,
  widthCmVisible: false,
  heightCmVisible: false,
  makyajAynasiYandanUzaklik: 180,
  makyajAynasiAlttanUzaklik: 180,
  kareAynaModel: "newjersey",
  camRafUzunlugu: 380,
  sensors: 0,
  isMenuOpen: false,
  bohrungen: false,
};

const mirrosSlice = createSlice({
  name: "Mirror",
  initialState: initalMirrorState,
  reducers: {
    setBodyDecor(state, action) {
      state.bodyDecor = action.payload;
    },
    changeMenuIsOpen(state, action) {
      state.isMenuOpen = action.payload;
    },
    changeMirror(state, action) {
      state.selectedMirror = action.payload;
    },
    changePrivateStatus(state, action) {
      state.isPrivate = action.payload;
    },
    setSquareMirrorSelectedItem(state, action) {
      state.squareMirrorSelectedItem = action.payload;
    },
    setCamRafUzunlugu(state, action) {
      state.camRafUzunlugu = action.payload;
    },
    changeLightThickness(state, action) {
      state.mirrorTickness = action.payload;
    },
    setDisableSensors(state) {
      state.isiSensor = 0;
      state.yataySensor = 0;
      state.dikeySensor = 0;
    },
    setMakyajAynasiYandanUzaklik(state, action) {
      state.makyajAynasiYandanUzaklik = action.payload;
    },
    setMakyajAynasiAlttanUzaklik(state, action) {
      state.makyajAynasiAlttanUzaklik = action.payload;
    },
    changeWidthCmVisible(state) {
      state.widthCmVisible = !state.widthCmVisible;
    },
    changeHeightCmVisible(state) {
      state.heightCmVisible = !state.heightCmVisible;
    },
    changeSelectedExcelItem(state, action) {
      const { id, name } = action.payload;
      state.selectedMirrorName = name;
      state.selectedMirrorExcelIndex = id;
    },
    changeWatchPosition(state, action) {
      state.watchPosition = action.payload;
    },
    changeRectangleWatchPosition(state, action) {
      state.rectangeWatchPosition = action.payload;
    },
    changeCurvedWatchPosition(state, action) {
      state.curvedWatchPositon = action.payload;
    },
    setSoket(state, action) {
      state.soket = action.payload;
    },
    changeLightStatus(state, action) {
      if (action.payload == false) {
        state.lightIsOpen = false;
      } else {
        state.lightIsOpen = !state.lightIsOpen;
      }
    },
    changeMirrorWidth(state, action) {
      state.mirrorWidth = action.payload;
    },
    changeMirrorHeight(state, action) {
      state.mirrorHeight = action.payload;
    },
    changeLightColor(state, action) {
      state.lightColorIndex = action.payload;
      if (action.payload === 0) {
        // WHITE
        state.lightColor = [100, 100, 100];
      } else if (action.payload === 1) {
        // SICAK BEYAZ
        // state.makeupLightColor = [100, 100, 0.2];
        // Alternative
        state.lightColor = [100, 60, 1];
      } else if (action.payload === 2) {
        // BLUE
        // state.lightColor = [-100, 100, 100];
        // Alternative
        state.lightColor = [20, 50, 100];
      } else {
        state.lightColor = [100, 100, 100];
      }
    },
    changeDoubleLed(state, action) {
      state.doubleLed = action.payload;
    },
    changeMakeUpLightColor(state, action) {
      if (action.payload === 0) {
        // WHITE
        state.makeupLightColor = [100, 100, 100];
        state.makeupLightColorIndex = 0;
      } else if (action.payload === 1) {
        // SICAK BEYAZ
        // state.makeupLightColor = [100, 100, 0.2];
        // Alternative
        state.makeupLightColor = [100, 60, 1];
        state.makeupLightColorIndex = 1;
      } else if (action.payload === 2) {
        // BLUE
        // state.makeupLightColor = [-100, 100, 100];
        // Alternative
        state.makeupLightColor = [20, 50, 100];
        state.makeupLightColorIndex = 2;
      } else if (action.payload === 3) {
        // RED
        // state.lightColor = [1000, 0, -20];
        state.makeupLightColorIndex = 3;
      } else if (action.payload === 4) {
        // YELLOW
        // state.lightColor = [2, 100, -100];
        state.makeupLightColorIndex = 4;
      } else if (action.payload === 5) {
        // PINK
        // state.lightColor = [100, 0.1, 1];
        state.makeupLightColorIndex = 5;
      } else if (action.payload === 7) {
        // Multiple Color Hot Cold Notr
        // state.lightColor = [100, 0.1, 1];
        state.makeupLightColorIndex = 7;
      } else if (action.payload == -1) {
        //Boş geçmek için ---
        state.makeupLightColorIndex = -1;
      }
    },
    setCiftSensorPos: (state, action) => {
      state.ciftSensorPos = action.payload;
    },
    setSoket: (state, action) => {
      state.soket = action.payload;
    },
    changeMakeUpMirrorSensor(state, action) {
      state.makeupMirrorSensor = action.payload;
    },
    changeMakeUpMirrorSensorPos(state, action) {
      state.makeupMirrorSensorPos = action.payload;
    },
    ///---Modal için stateler
    setLightColorIndex: (state, action) => {
      state.lightColorIndex = action.payload;
    },
    setDigitalWatch: (state, action) => {
      state.digitalWatch = action.payload;
    },
    setBluetooth: (state, action) => {
      state.bluetooth = action.payload;
    },
    setAnalogWatch: (state, action) => {
      state.analogWatch = action.payload;
    },
    setWatchPos: (state, action) => {
      state.watchPos = action.payload;
    },
    setHorizontalSensor: (state, action) => {
      state.horizontalSensor = action.payload;
    },
    setSquareSensor: (state, action) => {
      state.squareSensor = action.payload;
    },
    setMakeupMirror: (state, action) => {
      state.makeupMirror = action.payload;
    },
    setMakeupMirrorType: (state, action) => {
      state.makeupMirrorType = action.payload;
    },
    setMakeupMirrorPos: (state, action) => {
      state.makeupMirrorPos = action.payload;
    },
    setCustomerComment: (state, action) => {
      state.customerComment = action.payload;
    },
    setSummaryModalOpen: (state, action) => {
      state.summaryModalOpen = action.payload;
    },
    setYataySensor: (state, action) => {
      state.yataySensor = action.payload;
    },
    setYataySensorPos: (state, action) => {
      state.yataySensorPos = action.payload;
    },
    setIsiSensor: (state, action) => {
      state.isiSensor = action.payload;
    },
    setIsiSensorType: (state, action) => {
      state.isiSensorType = action.payload;
    },
    setIsiSensorPos: (state, action) => {
      state.isiSensorPos = action.payload;
    },
    setFaceta: (state, action) => {
      state.faceta = action.payload;
    },
    setDikeySensor: (state, action) => {
      state.dikeySensor = action.payload;
    },
    setDikeySensorPos: (state, action) => {
      state.dikeySensorPos = action.payload;
    },
    setKaplama: (state, action) => {
      state.kaplama = action.payload;
    },
    setRaf: (state, action) => {
      state.raf = action.payload;
    },
    setAynaIsıtma: (state, action) => {
      state.aynaIsıtma = action.payload;
    },
    setAynaIsıtmaPos: (state, action) => {
      state.aynaIsıtmaPos = action.payload;
    },
    setBackLight: (state, action) => {
      state.backLight = action.payload;
    },
    setSuspansiyon: (state, action) => {
      state.suspansiyon = action.payload;
    },
    setHangingPlates: (state, action) => {
      state.hangingPlates = action.payload;
    },
    setSealing: (state, action) => {
      state.sealing = action.payload;
    },
    setSoketPos: (state, action) => {
      state.soketPos = action.payload;
    },
    setSızdırmazlık: (state, action) => {
      state.sızdırmazlık = action.payload;
    },
    setOrderAmount: (state, action) => {
      state.orderAmount = action.payload;
    },
    setSensors: (state, action) => {
      state.sensors = action.payload;
    },
    changeKareAynaModel: (state, action) => {
      state.kareAynaModel = action.payload;
    },
    changeBohrungen: (state, action) => {
      state.bohrungen = action.payload;
    },
    resetToInitialState: (state) => {
      state.selectedDisableMirrors = [];
      state.lightIsOpen = false;
      state.mirrorWidth = 400;
      state.mirrorHeight = 400;
      state.lightColor = [100, 100, 100];
      state.lightColorIndex = 0;
      state.digitalWatch = false;
      state.analogWatch = false;
      state.horizontalSensor = false;
      state.squareSensor = false;
      state.makeupMirror = 0;
      state.makeupMirrorType = 0;
      state.makeupMirrorPos = 2;
      state.makeupMirrorSensor = 0;
      state.makeupMirrorSensorPos = 0;
      state.customerComment = "";
      state.summaryModalOpen = false;
      state.watchPosition = [0.15, 0.18, 0.09];
      state.rectangeWatchPosition = [0.5, 0.15, 0.03];
      state.curvedWatchPositon = [0.35, 0.15, 0.01];
      state.yataySensor = false;
      state.dikeySensor = false;
      state.dikeySensorPos = 0;
      state.yataySensorPos = 0;
      state.kaplama = 0;
      state.raf = 0;
      state.faceta = 0;
      state.aynaIsıtma = 0;
      state.backLight = false;
      state.suspansiyon = 1;
      state.soket = 0;
      state.soketPos = 0;
      state.isiSensor = 0;
      state.isiSensorPos = 0;
      state.aynaIsıtmaPos = 0;
      state.bluetooth = 0;
      state.sızdırmazlık = 0;
      state.orderAmount = 1;
      state.widthCmVisible = false;
      state.heightCmVisible = false;
      state.makyajAynasiAlttanUzaklik = 180;
      state.makyajAynasiYandanUzaklik = 180;
      state.camRafUzunlugu = 380;
      state.doubleLed = false;
      state.sensors = 0;
    },
  },
});

export let {
  setBodyDecor,
  changeMirror,
  setDisableSensors,
  disableMirror,
  changeLightStatus,
  clearSelectedDisableMirrors,
  changeMirrorWidth,
  changeMirrorHeight,
  changeLightColor,
  changeMakeUpLightColor,
  setLightColorIndex,
  changeMakeUpMirrorSensor,
  changeMakeUpMirrorSensorPos,
  setSızdırmazlık,
  setDigitalWatch,
  setBluetooth,
  setAnalogWatch,
  setWatchPos,
  setHorizontalSensor,
  setSquareSensor,
  setMakeupMirror,
  setMakeupMirrorType,
  changeLightThickness,
  setMakeupMirrorPos,
  setCustomerComment,
  setSummaryModalOpen,
  changeWatchPosition,
  changeRectangleWatchPosition,
  changeSelectedExcelItem,
  changeCurvedWatchPosition,
  setDikeySensor,
  setDikeySensorPos,
  setYataySensor,
  setYataySensorPos,
  setOrderAmount,
  setIsiSensor,
  changePrivateStatus,
  setIsiSensorType,
  setSquareMirrorSelectedItem,
  setMakyajAynasiAlttanUzaklik,
  setCamRafUzunlugu,
  setMakyajAynasiYandanUzaklik,
  setIsiSensorPos,
  setKaplama,
  setRaf,
  setAynaIsıtma,
  changeDoubleLed,
  setAynaIsıtmaPos,
  setFaceta,
  setSoket,
  setHangingPlates,
  setSealing,
  setSoketPos,
  setBackLight,
  setSuspansiyon,
  resetToInitialState,
  changeHeightCmVisible,
  changeWidthCmVisible,
  changeKareAynaModel,
  setCiftSensorPos,
  changeMenuIsOpen,
  setSensors,
  changeBohrungen,
} = mirrosSlice.actions;

export default mirrosSlice.reducer;
