import React from "react";
import {
  Stack,
  Slider,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Modal,
  Box,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setSuspansiyon } from "../../../store/mirrors";
import { setSuspansiyonFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const Suspansiyon = () => {
  const dispatch = useDispatch();
  const { suspansiyon } = useSelector((state) => state.mirror);

  const handleChange = (e) => {
    dispatch(setSuspansiyon(e.target.value));
    dispatch(
      setSuspansiyonFee(
        e.target.value == 2 ? 6 : e.target.value == 3 ? 19.99 : 0
      )
    );
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal title={"Informationen zu den Aufhängeblechen"}>
        <Typography
          variant="caption"
          fontWeight={700}
          sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}
        >
          Aufhängebleche
        </Typography>
      </RenderModal>

      <input
        name="id[28]"
        value={
          suspansiyon == 1
            ? 282
            : suspansiyon == 2
            ? 283
            : suspansiyon == 3
            ? 616
            : null
        }
      />

      <Select
        onChange={handleChange}
        value={suspansiyon}
        sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
      >
        <MenuItem value={1}>Standard (lose beiliegend)</MenuItem>
        <MenuItem value={2}>
          Standard (vormontiert - fertig zum Aufhängen) + 6,00 €
        </MenuItem>
        <MenuItem value={3}>ProfiPlus-Set (lose beiliegend) + 19,99 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default Suspansiyon;
