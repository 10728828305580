import React from "react";
import { Stack, Typography, Link } from "@mui/material";

const Kanten = () => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Kanten
      </Typography>

      <Typography variant="caption">Hochglanz Poliert</Typography>
    </Stack>
  );
};

export default Kanten;
