import React from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";
function CabinetShelves({ isCenterCabinet }) {
  const { nodes, materials } = useGLTF("/newModels/budapest.glb");
  const { nodes: nodesForShelf } = useGLTF("/untitled.glb");

  const { shelfCount } = useSelector((state) => state.cabinet);

  const renderOne = () => (
    <mesh
      renderOrder={-1}
      castShadow
      receiveShadow
      geometry={nodesForShelf.shelf001.geometry}
      material={materials.glass}
      position={[1.11, 1.6, 0.02]}
      rotation={[Math.PI / 2, 0, 0]}
      scale={[0.98, 1, 1]}
    />
  );
  const renderTwo = () => (
    <mesh renderOrder={-1} castShadow receiveShadow geometry={nodes.shelf.geometry} material={materials.glass} position={[1.11, 1.47, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
  );
  const renderThree = () => (
    <group renderOrder={-1}>
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.78, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.6, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.42, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
    </group>
  );
  const renderFour = () => (
    <group renderOrder={-1}>
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.84, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.68, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.52, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.36, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
    </group>
  );
  const renderFive = () => (
    <group renderOrder={-1}>
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.87, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.735, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.6, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.465, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.33, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
    </group>
  );
  const renderSix = () => (
    <group renderOrder={-1}>
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.88, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.77, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.66, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.54, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.43, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
      <mesh castShadow receiveShadow geometry={nodesForShelf.shelf001.geometry} material={materials.glass} position={[1.11, 1.32, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
    </group>
  );

  if (isCenterCabinet) {
    return renderOne();
  }

  return shelfCount === 1
    ? renderOne()
    : shelfCount === 2
    ? renderTwo()
    : shelfCount === 3
    ? renderThree()
    : shelfCount === 4
    ? renderFour()
    : shelfCount === 5
    ? renderFive()
    : shelfCount === 6
    ? renderSix()
    : null;
}

export default CabinetShelves;
