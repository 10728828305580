import { Html } from "@react-three/drei";
import React from "react";
import { useSelector } from "react-redux";
import { EnumCabinetMirrors } from "../../../../store/cabinetMirror";

export const IndividualWidth = ({ width, testCabinet, kapaksizValue }) => {
  const { cabinetMirrorWidth, cabinetMirrorHeight, doorNumber, leftCompartmentWidth, rightCompartmentWidth } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);

  const totalValueAfterSideCompartments = [
    EnumCabinetMirrors.MUNCHEN_4,
    EnumCabinetMirrors.MALAGA,
    EnumCabinetMirrors.SARAGOSSA,
    EnumCabinetMirrors.KONSTANZ,
    EnumCabinetMirrors.DERBY,
    EnumCabinetMirrors.GENT,
    EnumCabinetMirrors.VENEZIA,
    EnumCabinetMirrors.ALMERIA,
    EnumCabinetMirrors.GRADO,
    EnumCabinetMirrors.VILLACH,
    EnumCabinetMirrors.VERONICA_3,
    EnumCabinetMirrors.VERONICA_4,
    EnumCabinetMirrors.PANDORA_3,
    EnumCabinetMirrors.PANDORA_4,
    EnumCabinetMirrors.KATHERINE_3,
    EnumCabinetMirrors.KATHERINE_4,
  ].includes(selectedMirror)
    ? cabinetMirrorWidth - leftCompartmentWidth - rightCompartmentWidth
    : cabinetMirrorWidth;

  const newWidth = ((width / (totalValueAfterSideCompartments / doorNumber / 200)) * (totalValueAfterSideCompartments / doorNumber)).toFixed(0);
  const oldWidth = ((width * cabinetMirrorWidth) / doorNumber).toFixed(0);
  const widthValue = testCabinet ? newWidth : oldWidth;

  const placementXfForNewVersion = kapaksizValue ? -0.2 : -0.1;

  const htmlPosX = testCabinet ? placementXfForNewVersion : 0.85 + ((width * cabinetMirrorWidth) / doorNumber) * 0.0003;

  return (
    <Html distanceFactor={4} position={[htmlPosX, 1 - cabinetMirrorHeight * 0.00029, 0]}>
      <div
        style={{
          backgroundColor: "white",
          fontSize: cabinetMirrorWidth < 700 ? "12px" : "16px",
          padding: "4px 8px",
          borderRadius: "8px",
          width: "auto",
          whiteSpace: "nowrap",
        }}
      >
        <p>{kapaksizValue ?? widthValue} mm</p>
      </div>
    </Html>
  );
};
