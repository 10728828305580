import React, { useState } from "react";
import {
  Stack,
  Slider,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeBohrungen } from "../../../store/mirrors";
// import { setBluetoothFee } from "../../../store/mirrorFee";
import { blue } from "@mui/material/colors";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const Bohrungen = () => {
  const dispatch = useDispatch();
  const { bohrungen } = useSelector((state) => state.mirror);
  const [bohurgen1, setBohurgen1] = useState(10203);
  const [vonRec1, setVonRec1] = useState(null);
  const [vonUnten1, setVonUnten1] = useState(null);

  const [bohurgen2, setBohurgen2] = useState(10289);
  const [vonRec2, setVonRec2] = useState(null);
  const [vonUnten2, setVonUnten2] = useState(null);

  const handleChange = (e) => {
    // dispatch(setBluetooth(e.target.value));
    dispatch(changeBohrungen(e.target.value));
  };
  return (
    <Stack direction="column" gap="25px">
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <RenderModal id="31">
          <Typography
            variant="caption"
            fontWeight={700}
            sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}
          >
            Bohrungen (+4 Tage)
          </Typography>
        </RenderModal>
        <input
          name="id[510]"
          value={
            bohrungen == 0
              ? 10196
              : bohrungen == 1
              ? 10197
              : bohrungen == 2
              ? 10198
              : null
          }
        />
        <Select
          value={bohrungen}
          onChange={handleChange}
          sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
        >
          <MenuItem value={0}>---</MenuItem>
          <MenuItem value={1}>1 Bohrung</MenuItem>
          <MenuItem value={2}>2 Bohrungen</MenuItem>
        </Select>
      </Stack>
      {(bohrungen == 1 || bohrungen == 2) && (
        <>
          {/* bohurgen1 */}
          <input name="id[511]" value={bohurgen1} />

          {/* von rechts 1 */}
          <input type="hidden" name="id[512]" value="10238" />
          <input type="hidden" id="txt512" name="txt_10238" value={vonRec1} />

          {/* von unten 1 */}
          <input type="hidden" name="id[513]" value="10239" />
          <input type="hidden" id="txt513" name="txt_10239" value={vonUnten1} />

          <Stack direction="row" gap="15px">
            <Stack
              direction="column"
              gap="10px"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography fontSize={"0.8rem"}>Durchmesser</Typography>
              <Select
                value={bohurgen1}
                onChange={(e) => {
                  setBohurgen1(e.target.value);
                }}
                sx={{
                  width: "8vw",
                  height: "2rem",
                  flexGrow: "1",
                  fontSize: "0.7rem",
                }}
              >
                <MenuItem
                  value="10203"
                  selected="selected"
                  data-value="0"
                  data-sortorder="0"
                >
                  ---
                </MenuItem>
                <MenuItem value="10205" data-value="6" data-sortorder="0">
                  6 mm + 6,90 €
                </MenuItem>
                <MenuItem value="10206" data-value="8" data-sortorder="0">
                  8 mm + 6,90 €
                </MenuItem>
                <MenuItem value="10207" data-value="10" data-sortorder="0">
                  10 mm + 6,90 €
                </MenuItem>
                <MenuItem value="10208" data-value="12" data-sortorder="0">
                  12 mm + 6,90 €
                </MenuItem>
                <MenuItem value="10209" data-value="14" data-sortorder="0">
                  14 mm + 6,90 €
                </MenuItem>
                <MenuItem value="10210" data-value="16" data-sortorder="0">
                  16 mm + 6,90 €
                </MenuItem>
                <MenuItem value="10211" data-value="18" data-sortorder="0">
                  18 mm + 6,90 €
                </MenuItem>
                <MenuItem value="10212" data-value="20" data-sortorder="0">
                  20 mm + 6,90 €
                </MenuItem>
                <MenuItem value="10213" data-value="22" data-sortorder="0">
                  22 mm + 11,70 €
                </MenuItem>
                <MenuItem value="10214" data-value="24" data-sortorder="0">
                  24 mm + 11,70 €
                </MenuItem>
                <MenuItem value="10215" data-value="26" data-sortorder="0">
                  26 mm + 11,70 €
                </MenuItem>
                <MenuItem value="10216" data-value="28" data-sortorder="0">
                  28 mm + 11,70 €
                </MenuItem>
                <MenuItem value="10217" data-value="30" data-sortorder="0">
                  30 mm + 11,70 €
                </MenuItem>
                <MenuItem value="10218" data-value="32" data-sortorder="0">
                  32 mm + 11,70 €
                </MenuItem>
                <MenuItem value="10219" data-value="34" data-sortorder="0">
                  34 mm + 11,70 €
                </MenuItem>
                <MenuItem value="10220" data-value="36" data-sortorder="0">
                  36 mm + 11,70 €
                </MenuItem>
                <MenuItem value="10221" data-value="38" data-sortorder="0">
                  38 mm + 11,70 €
                </MenuItem>
                <MenuItem value="10222" data-value="40" data-sortorder="0">
                  40 mm + 11,70 €
                </MenuItem>
                <MenuItem value="10225" data-value="45" data-sortorder="0">
                  45 mm + 11,70 €
                </MenuItem>
                <MenuItem value="10226" data-value="48" data-sortorder="0">
                  48 mm + 11,70 €
                </MenuItem>
                <MenuItem value="10227" data-value="50" data-sortorder="0">
                  50 mm + 11,70 €
                </MenuItem>
                <MenuItem value="10230" data-value="55" data-sortorder="0">
                  55 mm + 18,60 €
                </MenuItem>
                <MenuItem value="10232" data-value="60" data-sortorder="0">
                  60 mm + 18,60 €
                </MenuItem>
                <MenuItem value="10235" data-value="65" data-sortorder="0">
                  65 mm + 18,60 €
                </MenuItem>
                <MenuItem value="10237" data-value="70" data-sortorder="0">
                  70 mm + 18,60 €
                </MenuItem>
              </Select>
              {bohrungen == 2 && (
                <>
                  {/* bohrugen2 */}
                  <input name="id[514]" value={bohurgen2} />

                  {/* von rechts 2 */}
                  <input type="hidden" name="id[515]" value="10240" />
                  <input
                    type="hidden"
                    id="txt515"
                    name="txt_10240"
                    value={vonRec2}
                  />

                  {/* von unten 2 */}
                  <input type="hidden" name="id[516]" value="10241" />
                  <input
                    type="hidden"
                    id="txt516"
                    name="txt_10241"
                    value={vonUnten2}
                  />

                  <Select
                    value={bohurgen2}
                    onChange={(e) => {
                      setBohurgen2(e.target.value);
                    }}
                    sx={{
                      width: "8vw",
                      height: "2.2rem",
                      flexGrow: "1",
                      fontSize: "0.7rem",
                    }}
                  >
                    <MenuItem
                      value="10289"
                      selected="selected"
                      data-value="0"
                      data-sortorder="0"
                    >
                      ---
                    </MenuItem>
                    <MenuItem value="10290" data-value="6" data-sortorder="0">
                      6 mm + 6,90 €
                    </MenuItem>
                    <MenuItem value="10291" data-value="8" data-sortorder="0">
                      8 mm + 6,90 €
                    </MenuItem>
                    <MenuItem value="10292" data-value="10" data-sortorder="0">
                      10 mm + 6,90 €
                    </MenuItem>
                    <MenuItem value="10293" data-value="12" data-sortorder="0">
                      12 mm + 6,90 €
                    </MenuItem>
                    <MenuItem value="10294" data-value="14" data-sortorder="0">
                      14 mm + 6,90 €
                    </MenuItem>
                    <MenuItem value="10295" data-value="16" data-sortorder="0">
                      16 mm + 6,90 €
                    </MenuItem>
                    <MenuItem value="10296" data-value="18" data-sortorder="0">
                      18 mm + 6,90 €
                    </MenuItem>
                    <MenuItem value="10297" data-value="20" data-sortorder="0">
                      20 mm + 6,90 €
                    </MenuItem>
                    <MenuItem value="10298" data-value="22" data-sortorder="0">
                      22 mm + 11,70 €
                    </MenuItem>
                    <MenuItem value="10300" data-value="26" data-sortorder="0">
                      26 mm + 11,70 €
                    </MenuItem>
                    <MenuItem value="10301" data-value="28" data-sortorder="0">
                      28 mm + 11,70 €
                    </MenuItem>
                    <MenuItem value="10302" data-value="30" data-sortorder="0">
                      30 mm + 11,70 €
                    </MenuItem>
                    <MenuItem value="10303" data-value="32" data-sortorder="0">
                      32 mm + 11,70 €
                    </MenuItem>
                    <MenuItem value="10304" data-value="34" data-sortorder="0">
                      34 mm + 11,70 €
                    </MenuItem>
                    <MenuItem value="10305" data-value="36" data-sortorder="0">
                      36 mm + 11,70 €
                    </MenuItem>
                    <MenuItem value="10306" data-value="38" data-sortorder="0">
                      38 mm + 11,70 €
                    </MenuItem>
                    <MenuItem value="10307" data-value="40" data-sortorder="0">
                      40 mm + 11,70 €
                    </MenuItem>
                    <MenuItem value="10308" data-value="45" data-sortorder="0">
                      45 mm + 11,70 €
                    </MenuItem>
                    <MenuItem value="10309" data-value="48" data-sortorder="0">
                      48 mm + 11,70 €
                    </MenuItem>
                    <MenuItem value="10310" data-value="50" data-sortorder="0">
                      50 mm + 11,70 €
                    </MenuItem>
                    <MenuItem value="10311" data-value="55" data-sortorder="0">
                      55 mm + 18,60 €
                    </MenuItem>
                    <MenuItem value="10312" data-value="60" data-sortorder="0">
                      60 mm + 18,60 €
                    </MenuItem>
                    <MenuItem value="10313" data-value="65" data-sortorder="0">
                      65 mm + 18,60 €
                    </MenuItem>
                    <MenuItem value="10314" data-value="70" data-sortorder="0">
                      70 mm + 18,60 €
                    </MenuItem>
                    <MenuItem value="10715" data-value="24" data-sortorder="0">
                      24 mm + 41,65 €
                    </MenuItem>
                  </Select>
                </>
              )}
            </Stack>
            <Stack
              direction="column"
              gap="10px"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography fontSize={"0.8rem"}>von rechts</Typography>
              <input
                class="appearance-none border border-black border-opacity-30 rounded w-full py-2 px-3 leading-tight focus:outline-[#0d646f] focus:shadow-outline"
                type="text"
              />
              {bohrungen == 2 && (
                <input
                  class=" appearance-none border border-black border-opacity-30 rounded w-full py-2 px-3 leading-tight focus:outline-[#0d646f] focus:shadow-outline"
                  type="text"
                />
              )}
            </Stack>
            <Stack
              direction="column"
              gap="10px"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography fontSize={"0.8rem"}>von unten</Typography>
              <input
                class="appearance-none border border-black border-opacity-30 rounded w-full py-2 px-3 leading-tight focus:outline-[#0d646f] focus:shadow-outline"
                type="text"
              />
              {bohrungen == 2 && (
                <input
                  class=" appearance-none border border-black border-opacity-30 rounded w-full py-2 px-3 leading-tight focus:outline-[#0d646f] focus:shadow-outline"
                  type="text"
                />
              )}
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default Bohrungen;
