import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { useSelector } from "react-redux";
import { Environment, OrbitControls } from "@react-three/drei";
import { Provider } from "react-redux";
import store from "../store/index";
import Room from "./OrtakComponents/Room";
import { DaireAyna, KareAyna, KoseliAyna } from "./AynaComponents";
import AynaMenu2 from "./AynaComponents/AynaMenu2";
import { useMediaQuery } from "@mui/material";
import { Bloom, EffectComposer, SMAA } from "@react-three/postprocessing";
import { UnrealBloomPass } from "three-stdlib";
import { extend } from "@react-three/fiber";
import { EnumMirrors } from "../store/mirrors";
import Panel from "./AynaComponents/Mirrors/Panel";
import KristallspiegelMenu from "./AynaComponents/KristallspiegelMenu";
import PaintedMirrorMenu from "./AynaComponents/PaintedMirrorMenu";
extend({ UnrealBloomPass });

const AynaCanvas = () => {
  const { selectedMirror, lightIsOpen, doubleLed, isPrivate, isMenuOpen } = useSelector((state) => state.mirror);
  const desktopMatches1700 = useMediaQuery("(min-width:1700px)");
  const desktopMatches1200 = useMediaQuery("(min-width:1200px)");
  const mobileMatches600 = useMediaQuery("(min-width:600px)");

  const canvasResponsiveWidth = desktopMatches1700 ? "71vw" : desktopMatches1200 ? "75vw" : mobileMatches600 && isMenuOpen ? "50vw" : "100vw";
  const isPaintedMirror = [EnumMirrors.F620TL, EnumMirrors.F621TL, EnumMirrors.F622TL, EnumMirrors.F623TL].includes(selectedMirror);
  const Kristallspiegel3_5mm = [EnumMirrors.STARK_RAHMENLOS_5MM, EnumMirrors.SILBER_RAHMENLOS_3MM].includes(selectedMirror);
  const isKristall = [EnumMirrors.STARK_RAHMENLOS_5MM, EnumMirrors.SILBER_RAHMENLOS_3MM, EnumMirrors.RAHMENLOSER_6_MM].includes(selectedMirror);
  const isOval = [EnumMirrors.F597L4K, EnumMirrors.F610L4K].includes(selectedMirror);
  const isWunschdekor = [EnumMirrors.WUNSCHDEKOR].includes(selectedMirror);

  return (
    <>
      <Canvas
        className="canvasHeightEdit"
        colorManagement
        shadowMap
        style={{
          width: canvasResponsiveWidth,
          height: `100vh`,
          float: "right",
          backgroundColor: "#e3e3e3",
          position: "relative",
        }}
        dpr={[1, 1.5]}
        shadows
        camera={{ position: [0, 0, 7], fov: 60, zoom: 0.9 }}
      >
        {lightIsOpen && (
          <EffectComposer multisampling={4} disableNormalPass>
            <Bloom luminanceThreshold={1} intensity={doubleLed ? 0.03 : 0.015} levels={2} />
            <Bloom luminanceThreshold={1} intensity={doubleLed ? 0.007 : 0.0053} levels={5} mipmapBlur />
            <Bloom luminanceThreshold={1} intensity={doubleLed ? 0.003 : 0.0019} levels={6} mipmapBlur />
          </EffectComposer>
        )}
        <Provider store={store}>
          <Room />
          <Environment near={1} far={1000} resolution={256} files={"./hdr/bathroom_360.hdr"} background />
          <OrbitControls
            minPolarAngle={Math.PI / 3.1}
            maxPolarAngle={Math.PI / 1.8}
            minAzimuthAngle={Math.PI * 1.75}
            maxAzimuthAngle={Math.PI * 2.3}
            zoomSpeed={0.25}
            enableZoom={true}
            makeDefault
            maxDistance={9}
            minDistance={2}
            enablePan={false}
          />
          <Suspense fallback={null}>
            {selectedMirror === EnumMirrors.CHARON || isOval ? (
              <DaireAyna />
            ) : selectedMirror === EnumMirrors.NEW_JERSEY || isPaintedMirror || isKristall || isWunschdekor ? (
              <KareAyna />
            ) : selectedMirror === 3 ? (
              <KoseliAyna />
            ) : null}
          </Suspense>
        </Provider>
      </Canvas>
      {Kristallspiegel3_5mm ? <KristallspiegelMenu /> : isPaintedMirror || isWunschdekor ? <PaintedMirrorMenu /> : <AynaMenu2 />}
      <Panel />
    </>
  );
};

export default AynaCanvas;
