import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useSelector } from "react-redux";
import * as THREE from "three";
export default function OvalMirrorTop(props) {
  const [scale, setScale] = useState([1.5, 1, 1]);
  const { mirrorWidth, mirrorHeight, lightColor } = useSelector((state) => state.mirror);
  const { nodes } = useGLTF("/newMirrorModels/F610L4K.glb");
  const { materials } = useGLTF("/rectangle-mirror-rev01.glb");

  const top = useRef(null);

  return (
    <group
      ref={top}
      rotation-z={Math.PI}
      rotation-y={Math.PI}
      scale={[0.991 + mirrorWidth * 0.00226, 1.27, 1.27]}
      // position={[0.027, 2.42, -0.007]}
      position={[0.025 + mirrorWidth * 0.00002, 2.17 + mirrorHeight * 0.00099, -0.007]}
    >
      <mesh
        name="mirrorLower"
        geometry={nodes.mirrorLower.geometry}
        material={materials.mirror}
        scale={[1, mirrorWidth > 1000 ? 0.85 : 0.95, 1]}
        position={[-0.0145, mirrorWidth > 1000 ? 1.064 : 1.035, -0.013]}
      />
      <mesh name="ledLightLower" geometry={nodes.ledLightLower.geometry} material={materials.led} scale-x={1.0015} position={[-0.016, 1.008, -0.0122]}>
        <meshStandardMaterial toneMapped={false} color={lightColor} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/F610L4K.glb");
