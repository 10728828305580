import { useGLTF } from "@react-three/drei";
import React from "react";

const BudapestMirror = () => {
  const { nodes, materials } = useGLTF("/newModels/budapest.glb");
  return (
    <group scale-y={0.915}>
      <mesh geometry={nodes.midDoorLeft_1.geometry} material={materials["mirror.001"]} />
      <mesh geometry={nodes.midDoorLeft_2.geometry} material={materials.ledlightTexture} />
      <mesh geometry={nodes.midDoorLeft_3.geometry} material={materials["mirror.001"]} />
      {/* <mesh geometry={nodes.midDoorLeft_4.geometry} material={materials.aluminum} /> */}
    </group>
  );
};

export default BudapestMirror;
