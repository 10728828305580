import React, { useEffect, useRef } from "react";
import { DoubleSide } from "three";
import { useSelector } from "react-redux";
import { EnumCabinetMirrors } from "../../../store/cabinetMirror";

const CabinetMirrorRoom = (props) => {
  const rightWall = useRef();
  const leftWall = useRef();
  const topWall = useRef();
  const botWall = useRef();

  const { cabinetMirrorWidth, cabinetMirrorHeight, cabinetMirrorType, doorNumber, leftCompartmentWidth, rightCompartmentWidth, centerCompartmentWidth } = useSelector((state) => state.cabinetMirror);

  const { selectedMirror } = useSelector((state) => state.mirror);

  const isSidedCabinet = [
    EnumCabinetMirrors.MUNCHEN_4,
    EnumCabinetMirrors.MALAGA,
    EnumCabinetMirrors.SARAGOSSA,
    EnumCabinetMirrors.KONSTANZ,
    EnumCabinetMirrors.DERBY,
    EnumCabinetMirrors.GENT,
    EnumCabinetMirrors.VENEZIA,
    EnumCabinetMirrors.ALMERIA,
    EnumCabinetMirrors.GRADO,
    EnumCabinetMirrors.VILLACH,
    EnumCabinetMirrors.VERONICA_3,
    EnumCabinetMirrors.VERONICA_4,
    EnumCabinetMirrors.PANDORA_3,
    EnumCabinetMirrors.PANDORA_4,
    EnumCabinetMirrors.KATHERINE_3,
    EnumCabinetMirrors.KATHERINE_4,
  ].includes(selectedMirror);

  const isBottomShelfCabinet = [
    EnumCabinetMirrors.BUDAPEST,
    EnumCabinetMirrors.MUNCHEN_6,
    EnumCabinetMirrors.SANTANDER,
    EnumCabinetMirrors.GENT,
    EnumCabinetMirrors.VENEZIA,
    EnumCabinetMirrors.LISSABON,
    EnumCabinetMirrors.LINZ,
    EnumCabinetMirrors.CORTINA,
    EnumCabinetMirrors.WINDSOR,
    EnumCabinetMirrors.VERONICA_2,
    EnumCabinetMirrors.VERONICA_4,
    EnumCabinetMirrors.PANDORA_2,
    EnumCabinetMirrors.PANDORA_4,
    EnumCabinetMirrors.KATHERINE_2,
    EnumCabinetMirrors.KATHERINE_4,
  ].includes(selectedMirror);

  const isCenteredCabinet = [EnumCabinetMirrors.MUNCHEN_5, EnumCabinetMirrors.LAS_VEGAS].includes(selectedMirror);

  useEffect(() => {
    const initialScaleX = (cabinetMirrorWidth / (doorNumber + 1) / 400) * 2;
    const centerScale = (centerCompartmentWidth - 150 / centerCompartmentWidth) * initialScaleX;
    const totalScale = doorNumber * initialScaleX + centerScale;

    if (cabinetMirrorType === 0 || cabinetMirrorType === 0.2) {
      leftWall.current.position.x = 5.7;
      leftWall.current.position.z = 0;
      leftWall.current.scale.x = 2;
    } else {
      if (isSidedCabinet) {
        if (doorNumber == 1) {
          leftWall.current.position.z = -15.6 - ((leftCompartmentWidth - 150) / 150) * 0.5;
          rightWall.current.position.z = 12.09 + cabinetMirrorWidth * 0.00245 + cabinetMirrorWidth * 0.0016 * ((rightCompartmentWidth - 150) / 150) * 0.2;
        } else if (doorNumber == 2) {
          leftWall.current.position.z = -14.02 - cabinetMirrorWidth * 0.0012 - cabinetMirrorWidth * 0.0013 * ((leftCompartmentWidth - 150) / 150) * 0.3;
          rightWall.current.position.z = 12.09 + cabinetMirrorWidth * 0.00245 + cabinetMirrorWidth * 0.0016 * ((rightCompartmentWidth - 150) / 150) * 0.2;
        } else if (doorNumber == 3) {
          leftWall.current.position.z = -14.04 - cabinetMirrorWidth * 0.0008 - cabinetMirrorWidth * 0.0013 * ((leftCompartmentWidth - 150) / 150) * 0.2;
          rightWall.current.position.z = 12.15 + cabinetMirrorWidth * 0.00243 + cabinetMirrorWidth * 0.0016 * ((rightCompartmentWidth - 150) / 150) * 0.15;
        } else if (doorNumber == 4) {
          leftWall.current.position.z = -14.03 - cabinetMirrorWidth * 0.0006 - cabinetMirrorWidth * 0.001 * ((leftCompartmentWidth - 150) / 150) * 0.2;
          rightWall.current.position.z = 12.15 + cabinetMirrorWidth * 0.00245 + cabinetMirrorWidth * 0.0023 * ((rightCompartmentWidth - 150) / 150) * 0.08;
        } else if (doorNumber == 5) {
          leftWall.current.position.z = -14.02 - cabinetMirrorWidth * 0.0005 - cabinetMirrorWidth * 0.001 * ((leftCompartmentWidth - 150) / 150) * 0.14;
          rightWall.current.position.z = 12.15 + cabinetMirrorWidth * 0.00246 + cabinetMirrorWidth * 0.00242 * ((rightCompartmentWidth - 150) / 150) * 0.06;
        } else if (doorNumber == 6) {
          leftWall.current.position.z = -14.03 - cabinetMirrorWidth * 0.0004 - cabinetMirrorWidth * 0.0009 * ((leftCompartmentWidth - 150) / 150) * 0.14;
          rightWall.current.position.z = 12.15 + cabinetMirrorWidth * 0.00246 + cabinetMirrorWidth * 0.00242 * ((rightCompartmentWidth - 150) / 150) * 0.054;
        }
        if (isBottomShelfCabinet) {
          botWall.current.position.y = -1.81 - cabinetMirrorHeight * 0.000705;
          botWall.current.scale.y = 1.06 - cabinetMirrorHeight * 0.00047;
        } else {
          botWall.current.position.y = -1.795 - cabinetMirrorHeight * 0.000519;
          botWall.current.scale.y = 1.095 - cabinetMirrorHeight * 0.000355;
        }
        topWall.current.position.y = 10.2 + cabinetMirrorHeight * 0.00095;
      } else if (isBottomShelfCabinet) {
        if (doorNumber == 1) {
          leftWall.current.position.z = -14.1 - cabinetMirrorWidth * 0.0011;
          rightWall.current.position.z = 12.085 + cabinetMirrorWidth * 0.0013;
        } else if (doorNumber == 2) {
          leftWall.current.position.z = -14.1 - cabinetMirrorWidth * 0.00056;
          rightWall.current.position.z = 12.15 + cabinetMirrorWidth * 0.0018;
        } else if (doorNumber == 3) {
          leftWall.current.position.z = -14.03 - cabinetMirrorWidth * 0.0004;
          rightWall.current.position.z = 12.145 + cabinetMirrorWidth * 0.00205;
        } else if (doorNumber == 4) {
          leftWall.current.position.z = -14.05 - cabinetMirrorWidth * 0.0003;
          rightWall.current.position.z = 12.15 + cabinetMirrorWidth * 0.00215;
        } else if (doorNumber == 5) {
          leftWall.current.position.z = -14.06 - cabinetMirrorWidth * 0.000225;
          rightWall.current.position.z = 12.1 + cabinetMirrorWidth * 0.00225;
        } else if (doorNumber == 6) {
          leftWall.current.position.z = -14 - cabinetMirrorWidth * 0.000225;
          rightWall.current.position.z = 12.19 + cabinetMirrorWidth * 0.00225;
        }
        topWall.current.position.y = 10.19 + cabinetMirrorHeight * 0.001;
        botWall.current.position.y = -1.81 - cabinetMirrorHeight * 0.000705;
        botWall.current.scale.y = 1.06 - cabinetMirrorHeight * 0.00047;
      } else if (isCenteredCabinet) {
        if (doorNumber === 6) {
          rightWall.current.position.z = 12.27 + cabinetMirrorWidth * 0.00096;
          leftWall.current.position.z = -14.07 - cabinetMirrorWidth * 0.00123;
        } else if (doorNumber === 4) {
          rightWall.current.position.z = 12.15 + cabinetMirrorWidth * 0.00092;
          leftWall.current.position.z = -14.07 - cabinetMirrorWidth * 0.001225;
        } else {
          rightWall.current.position.z = 12.12 + cabinetMirrorWidth * 0.0008 - centerCompartmentWidth * 0.00015;
          leftWall.current.position.z = -14.07 - cabinetMirrorWidth * 0.001225 + centerCompartmentWidth * 0.00005;
        }
        topWall.current.position.y = 10.2 + cabinetMirrorHeight * 0.001;
        botWall.current.position.y = -1.85 - cabinetMirrorHeight * 0.00046;
        botWall.current.scale.y = 1.095 - cabinetMirrorHeight * 0.000355;
      } else {
        if (doorNumber == 1) {
          leftWall.current.position.z = -14.1 - cabinetMirrorWidth * 0.0011;
          rightWall.current.position.z = 12.085 + cabinetMirrorWidth * 0.0013;
        } else if (doorNumber == 2) {
          leftWall.current.position.z = -14.1 - cabinetMirrorWidth * 0.00056;
          rightWall.current.position.z = 12.15 + cabinetMirrorWidth * 0.0018;
        } else if (doorNumber == 3) {
          leftWall.current.position.z = -14.03 - cabinetMirrorWidth * 0.0004;
          rightWall.current.position.z = 12.145 + cabinetMirrorWidth * 0.00205;
        } else if (doorNumber == 4) {
          leftWall.current.position.z = -14.05 - cabinetMirrorWidth * 0.0003;
          rightWall.current.position.z = 12.15 + cabinetMirrorWidth * 0.00215;
        } else if (doorNumber == 5) {
          leftWall.current.position.z = -14.06 - cabinetMirrorWidth * 0.000225;
          rightWall.current.position.z = 12.1 + cabinetMirrorWidth * 0.00228;
        } else if (doorNumber == 6) {
          leftWall.current.position.z = -14 - cabinetMirrorWidth * 0.000225;
          rightWall.current.position.z = 12.19 + cabinetMirrorWidth * 0.00228;
        }
        topWall.current.position.y = 10.2 + cabinetMirrorHeight * 0.00095;
        botWall.current.position.y = -1.795 - cabinetMirrorHeight * 0.000519;
        botWall.current.scale.y = 1.095 - cabinetMirrorHeight * 0.000355;
      }
      leftWall.current.position.x = 5.43;
      leftWall.current.scale.x = 1;
    }
  }, [cabinetMirrorType, selectedMirror, cabinetMirrorHeight, cabinetMirrorWidth, doorNumber, leftCompartmentWidth, rightCompartmentWidth, centerCompartmentWidth]);

  return (
    <mesh {...props} rotation-y={Math.PI * 0.4945} position-z={5.42}>
      <boxGeometry attach="geometry" args={[300, 300, 300, 300]} />
      <meshBasicMaterial side={DoubleSide} color="#e3e3e3" />
      <mesh ref={botWall} rotation={[0, -Math.PI / 2.0218, 0]} position={[5.67, -1.84, 0]}>
        <planeGeometry attach="geometry" args={[15, 3]} />
        <meshBasicMaterial side={DoubleSide} color="#c7c7c7" />
      </mesh>
      <mesh ref={topWall} rotation={[0, -Math.PI / 2.025, 0]} position={[5.67, 11.4, 0]}>
        <planeGeometry attach="geometry" args={[15, 20]} />
        <meshBasicMaterial side={DoubleSide} color="#c7c7c7" />
      </mesh>
      <mesh ref={rightWall} rotation={[0, -Math.PI / 2.0218, 0]} position={[5.9, 6.6, 14.73 + cabinetMirrorWidth * 0.0006]}>
        <planeGeometry attach="geometry" args={[26.43, 20]} />
        <meshBasicMaterial side={DoubleSide} color="#c7c7c7" />
      </mesh>
      <mesh ref={leftWall} rotation={[0, -Math.PI / 2.0218, 0]} position={[5.4, 6.6, -14.9 - cabinetMirrorWidth * 0.00001]}>
        <planeGeometry attach="geometry" args={[25.9, 20]} />
        <meshBasicMaterial side={DoubleSide} color="#c7c7c7" />
      </mesh>
    </mesh>
  );
};

export default CabinetMirrorRoom;
