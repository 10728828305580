const data = [
  {
    id: 1,
    title: "Informationen zu den Aufhängeblechen",
    html: `<div class=""><meta name="robots" content="noindex">
        <h1></h1>
        <h3>Aufhängebleche vormontiert</h3>
        
        <p>Bei Auswahl der Option <strong>"Aufhängebleche: vormontiert"</strong> erhalten Sie den Spiegel aufhängefertig. Die Bleche sind dann bereits auf der Rückseite des Badspiegels verklebt. Bei den Bohrungen in der Wand müssen Sie sich folglich nach deren Position richten.</p>
        
        <h3>Aufhängebleche lose</h3>
        
        <p>Falls Sie <strong>größere Flexibilität </strong>wünschen, z. B. um die <strong>Bohrungen sauber in die Fugen</strong> zu setzen, wählen Sie bitte die Option <strong>"Aufhängebleche: lose"</strong>. Wir liefern die Bleche dann lose mit und Sie können entscheiden, wo Sie sie auf der Rückseite des Badspiegels anbringen. Ein&nbsp;Vorteil der Option "Aufhängebleche: lose" ist auch, dass&nbsp;<strong>bestehende Bohrungen&nbsp;</strong>weiter genutzt werden können.</p>
        
        <p>Bitte beachten Sie: Zum Anbringen entfernen Sie den Schutzstreifen und kleben die Aufhängebleche an die gewünschte Stelle. Im Anschluss müssen Sie den <strong>Spiegel 24 Stunden ruhen lassen</strong>, bevor Sie ihn aufhängen, damit der Kleber richtig abbindet.</p>
        
        <div>
        <h3>ProfiPlus-Set</h3>
        
        <p>Als Alternative zu konventionellen Spiegelhalterungen bieten wir für Spiegel bis 2 Quadratmeter Spiegelfläche das ProfiPlus-Set an (Bild unten). Es besteht aus zwei gekanteten Blechen, Exzenterscheiben, Druckknöpfen, Schrauben und Dübeln. Diese Art der Befestigung bietet gleich mehrere Vorteile:</p>
        
        <ul>
            <li>Dank der Exzenterscheiben können Sie <strong>Höhenunterschiede von bis zu 8 mm</strong> bei Bohrungen oder Halterungen auch später noch <strong>ausgleichen</strong>.</li>
            <li>Das <strong>Aufhängen</strong> des Spiegels geht dank der gekanteten Bleche <strong>leichter</strong>.</li>
            <li>Dank der beiligenden Druckknöpfe hängt der Spiegel nicht nur, sondern wird auch unten an der Wand gehalten. Das ist sinnvoll bei <strong>Spiegeln mit Steckdose</strong>, da durch das Herausziehen von Steckern stets Zug auf den Spiegel ausgeübt wird.</li>
        </ul>
        
        <p>Das ProfiPlus-Set wird lose mitgeliefert. Die Halterungen werden ganz einfach an die gewünschte Position geklebt. Eine ausführliche Montage-Anleitung liegt dem Set bei.</p>
        
        <div class="row">
        <div class="col-md-9"><img alt="ProfiPlus Set Spiegelhalterungen Aufhängebleche" class="img-fluid lazy" src="/images/ProfiPlusSetKomplett_Text_800px_web.jpg" style="height: 762px; width: 800px;"></div>
        </div>
          <p>Abbildung exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind.</p>
        
        <h3><strong>Wichtiger Hinweis zu Befestigungsmaterial und Montage</strong></h3>
        
        <p>Es liegt in der Verantwortung des Kunden, abhängig von der Beschaffenheit der Wände das geeignete Befestigungsmaterial auszuwählen und zu beschaffen. Falls Sie zum Beispiel <strong>Rigips-Wände</strong> haben, verwenden Sie bitte nicht die beiliegenden Dübel aus dem ProfiPlus-Set, sondern Hohlkammerdübel.</p>
        </div></div>`,
  },
  {
    id: 2,
    title: "Informationen zur Lichtfarbe",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Bei der LED-Beleuchtung haben Sie die Wahl zwischen</p>
    
    <ol>
        <li><strong>Warmweißer</strong> (ca. 3000 K), <strong>neutralweißer</strong> (ca. 4000 K) oder <strong>kaltweißer</strong> (ca. 6000 K) Lichtfarbe. Zusätzliche Kosten entstehen für diese Option nicht. Die Beleuchtung ist <strong>nur in Kombination mit einer Gestensteuerung&nbsp;dimmbar</strong>;<br>
        &nbsp;</li>
        <li><b>Warm- <u>und</u> Kaltweiß inkl. Doppel-Touch-Sensor</b>: Auf der Spiegeloberfläche befinden sich zwei Touch-Sensoren, einer für warmweißes und einer für kaltweißes Licht. Werden beide zugleich eingeschaltet, erhalten Sie eine Art Tageslichtweiß (bei höherem Stromverbrauch). Wählen Sie diese Option, so benötigen Sie <u>keinen</u> anderen Schalter oder Sensor (Kipp-/Wipp-/Frontschalter, IR- oder regulärer Touch-Sensor) für die Beleuchtung. Die LED sind <strong>nicht dimmbar</strong>. Die Beleuchtung hat keine Memory-Funktion für den Fall, dass die Stromzufuhr unterbrochen wird.<br>
        &nbsp;</li>
        <li><strong>CCT LED-Bänder - <u>stufenlos dimmbar</u> von 2700K (Warmweiß) bis 6300K (Kaltweiß) inkl.&nbsp;Gestensteuerung:</strong> Das Ein- und Ausschalten der Beleuchtung erfolgt völlig berührungslos. Im Unterschied zum Touch-Sensor haben Sie also keine Fingerspuren auf der Spiegeloberfläche. Bewegen Sie die Hand vor dem Sensor kurz auf und ab, und das Licht geht an/aus. Halten Sie die Hand länger vor den Sensor, um die Beleuchtung auf- oder abzudimmen. Diese Steuerung verfügt über eine Memory-Funktion, d.h. der letzt Wert wird gespeichert. Diese Option kann nicht mit einem anderen Schalter, Touch- oder Infrarot-Sensor kombiniert werden.</li>
    </ol>
    
    <h4>Wichtige Hinweise</h4>
    Der Touch-Sensor ist dauerhaft beleuchtet, d.h. auch in ausgeschaltetem Zustand. Ebenfalls im ausgeschalteten Zustand sichtbar ist der kleine Lichtpunkt einer ggf. ausgewählten Gestensteuerung.<br>
    <br>
    Wenn die Gestensteuerung das Signal speichert, flackern die LED einmal kurz zur Bestätigung. Das ist kein Fehler bzw. Mangel.<br>
    <br>
    Beim <strong>TV Spiegel</strong> befindet sich der Sensor für die Gestensteuerung <u>nicht</u> vorn auf dem Spiegel, sondern <strong>unten hinter der Spiegelfläche.</strong> Die Gestensteuerung funktioniert <u>nicht</u> mit Smart Home von <strong>digitalSTROM</strong>.
    
    <ol>
        <li>
        <div class="video" style="margin:16px 0;"><iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" frameborder="0" height="315" src="https://www.youtube-nocookie.com/embed/7pwmtNmHlGk" width="560"></iframe></div>
        </li>
        <li><strong>RGB-Leuchtdioden: </strong>Über eine Funk-Fernbedienung können einzelnen Farben oder Farbverlaufsprogramme gewählt werden. Die RGB-LED sind <strong>mittels Fernbedienung auch dimmbar</strong>, nicht jedoch über einen möglicherweise vorhandenen Dimmer-/Raum-Lichtschalter.</li>
    </ol>
    
    <p><strong>Bitte beachten Sie:</strong> Die Leuchtdioden werden nicht direkt hinter den satinierten Flächen montiert, sondern etwas zurückgesetzt. Damit ist sichergestellt, dass das Licht gleichmäßig durch das Milchglas fällt und Sie die einzelnen LED-Punkte nicht sehen. Darüber hinaus wird die Wand indirekt beleuchtet (<strong>Hintergrundbeleuchtung</strong>).</p>
    
    <h4>Allgemeines zur Lichtfarbe</h4>
    
    <p>Die meisten Kunden sind mit <strong>neutralweißen LED</strong>, deren Licht dem Tageslicht sehr ähnlich ist, gut bedient. <strong>Warmweißes Licht </strong>verfügt im Vergleich dazu über einen höheren Gelbanteil, <strong>kaltweißes Licht</strong> über einen höheren Blauanteil. Für welche Lichtfarbe Sie sich entscheiden, hängt nicht nur von der Gestaltung Ihres&nbsp;Badezimmers&nbsp;(helle vs. dunkle Fliesen, Fenster für Tageslicht etc.) und den Badmöbeln, sondern auch von Ihrem persönlichen Geschmack und Empfinden ab.</p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Lichtfarbe LED Badspiegel" class="img-fluid lazy" height="500" src="/images/content/optionen/lichtfarbe.jpg" title="Lichtfarbe LED Badspiegel" width="500"></div>
    
    <div class="col-md-6">&nbsp;</div>
    </div>
    
    <p>Abbildung exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 3,
    title: "Informationen zum LED-Band doppelt",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Bei der Option "LED-Band doppelt" wird der Spiegel mit der doppelten Anzahl an LED ausgestattet. Die Bänder mit den Leuchtdioden werden parallel zueinander verlegt.&nbsp;Sie profitieren von größerer Leuchtkraft und Helligkeit. An den Lichtauslässen bzw. der Satinierung ändert sich nichts.</p>
    
    <p>Die Option ist zu empfehlen, wenn Sie zum Beispiel</p>
    
    <ul>
      <li>ein kleines Bad bzw. Gäste WC ausschließlich mit Hilfe des Lichts am Spiegel ausleuchten wollen oder</li>
      <li>eine stärkere Beleuchtung für ein großes Badezimmer wünschen.</li>
    </ul>
    
    <p><strong>Bitte beachten Sie</strong>&nbsp;</p>
    
    <p>Der Energieverbrauch ist aufgrund der doppelten Anzahl an LED entsprechend größer.</p>
    
    <p>Wenn Sie eine <strong>möglichst hohe Lichtausbeute </strong>wünschen, dann wählen Sie bitte ein Spiegel-Dekor mit breiter (4 cm) Satinierung. Wir empfehlen jedoch in der Regel eine zusätzliche (Decken-) Beleuchtung vorzusehen.</p>
    
    <p>Falls Sie einen Spiegel suchen, bei dem Sie <strong>zwischen warm- und kaltweißer Beleuchtung wechseln</strong> können, so wählen Sie im Konfigurator unter "Lichtfarbe" bitte die Option "Warm- und Kaltweiß mit Doppel-Touch-Sensor" (nicht mit "LED-Band doppelt" kombinierbar).</p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Spiegel mit LED einfach" class="img-fluid lazy" height="500" src="/images/content/optionen/led-band-einfach.jpg" title="Spiegel mit LED einfach" width="500"></div>
    
    <div class="col-md-6"><img alt="Spiegel mit LED doppelt" class="img-fluid lazy" height="560" src="/images/content/optionen/led-band-doppelt.jpg" title="Spiegel mit LED doppelt" width="560"></div>
    </div>
    <p>Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 4,
    title: "Informationen zu Sensor/Schalter",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <h3>Gestensteuerung</h3>
    
    <p>Gestensteuerung bieten wir in <strong>Kombination mit <u>stufenlos dimmbaren</u>&nbsp;CCT LED-Bändern</strong> – von 2700K (Warmweiß) bis 6300K (Kaltweiß) – an.<strong> </strong>Das Ein- und Ausschalten der Beleuchtung erfolgt völlig berührungslos. Im Unterschied zum Touch-Sensor haben Sie also keine Fingerspuren auf der Spiegeloberfläche. Bewegen Sie die Hand vor dem Sensor kurz auf und ab, und das Licht geht an/aus. Halten Sie die Hand länger vor den Sensor, um die Beleuchtung auf- oder abzudimmen. Diese Steuerung verfügt über eine Memory-Funktion, d.h. der letzt Wert wird gespeichert. Der kleine Lichtpunkt der Gestensteuerung ist auch in ausgeschaltetem Zustand sichtbar.</p>
    
    <h4>Wichtige Hinweise</h4>
    
    <p>Wenn die Gestensteuerung das Signal speichert, flackern die LED einmal kurz zur Bestätigung. Das ist kein Fehler bzw. Mangel. Die Gestensteuerung funktioniert nicht mit Smart Home von <strong>digitalSTROM</strong>.&nbsp;Der Abstand des Sensors vom Rand des Spiegels ist von der Breite der Satinierung abhängig.</p>
    
    <p><strong>TV Spiegel:</strong> Der Sensor für die Gestensteuerung befindet sich <u>nicht</u> vorne auf dem Spiegel, sondern <strong>unten hinter der&nbsp;Spiegelfläche.</strong></p>
    
    <p><strong>Raumteiler: </strong>Der Sensor&nbsp;befindet sich nur auf einer Seite, nicht auf beiden Spiegeln.</p>
    
    <div class="row">
    <div class="col-md-6">
    <div class="video"><iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" class="lazyloaded" frameborder="0" height="315" src="https://www.youtube-nocookie.com/embed/7pwmtNmHlGk" width="560"></iframe></div>
    </div>
    
    <div class="col-md-6"><img alt="Spiegel mit Gestensteuerung" class="img-fluid lazy" height="500" src="/images/content/optionen/gestensteuerung.jpg" style="margin-bottom:18px" width="500"></div>
    </div>
    
    <h3>Touch-Sensor</h3>
    
    <div class="row">
    <div class="col-md-6">
    <p>Der Touch-Sensor bzw. Sensor-Leuchttaster <strong>(Bild rechts bzw. unten)</strong> befindet sich auf der Vorderseite des Spiegels und misst 17 x 17 mm&nbsp;(Breite x Höhe); die Form ist quadratisch mit abgerundeten Ecken. Der Sensor leuchtet (auch in ausgeschaltetem Zustand) wie folgt:</p>
    
    <ul>
      <li>EIN = ROT</li>
      <li>AUS = KALTWEISS</li>
    </ul>
    
    <p>Die Position des Sensors (unten links/mitte/rechts) können Sie wählen. Die Beleuchtung hat keine Memory-Funktion für den Fall, dass die Stromzufuhr unterbrochen wird.</p>
    
    <p><strong>Raumteiler: </strong>Der Sensor&nbsp;befindet sich nur auf einer Seite, nicht auf beiden Spiegeln.</p>
    </div>
    
    <div class="col-md-6"><img alt="Spiegel mit Touch-Sensor" class="img-fluid lazy" height="500" src="/images/content/optionen/Sensor_BS_01.jpg" style="margin-bottom:18px" width="500"></div>
    </div>
    
    <h3>Infrarot (IR) -Sensor</h3>
    
    <div class="row">
    <div class="col-md-6">
    <p>Der Infrarot-Sensor wird unten am Spiegel montiert. Sie können den Badspiegel dann durch eine Handbewegung, z. B. von rechts nach links, ein- und auch ausschalten, ohne ihn berühren zu müssen.</p>
    
    <p><strong>Bitte beachten Sie</strong>, dass beim Infrarot-Sensor ein <strong>Abstand von mindestens 15 cm </strong>bis zum nächsten Gegenstand (z. B. Waschtisch oder Armatur) erforderlich ist. Der Sensor ist&nbsp;<u>nicht</u>&nbsp;für Spiegel mit Beleuchtung unten (Rundumbeleuchtung bzw. oben/unten) erhältlich.</p>
    </div>
    
    <div class="col-md-6"><img alt="Spiegel mit Infrarot-Sensor" class="img-fluid lazy" height="500" src="/images/content/optionen/Sensor_01.jpg" style="margin-bottom:18px" width="500"></div>
    </div>
    
    <h3>Kippschalter</h3>
    
    <p>Der&nbsp;Kippschalter wird in der Regel&nbsp;im unteren Bereich hinter dem Spiegel angebracht, sodass er nicht sichtbar ist.&nbsp;Ist die Spiegelfläche entlang der Kanten satiniert bzw. hinterleuchtet, so wird der Schalter so montiert, dass die Beleuchtung nicht beeinträchtigt wird. Bitte beachten Sie: Falls der Schalter auf Ihren speziellen Wunsch hin an den Seiten eingebaut wird, so ist er möglicherweise sichtbar.</p>
    
    <p>Vom Kippschalter gibt es zwei verschiedene Varianten. Welche wie verbauen, hängt vom gewählten Spiegel-Modell bzw. dessen Bauweise ab. Hier besteht&nbsp;keine Wahlmöglichkeit.&nbsp;Je nach Modell bzw. Breite der Satinierung müssen Sie ggfs. etwas tiefer hinter den Spiegel fassen, um an den Schalter zu gelangen.</p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Spiegel mit Kippschalter" class="img-fluid lazy" height="500" src="/images/content/optionen/Schalter_02.jpg" style="margin-bottom:18px" width="500"></div>
    
    <div class="col-md-6"><img alt="Spiegel mit Kippschalter" class="img-fluid lazy" height="500" src="/images/content/optionen/Schalter_01.jpg" style="margin-bottom: 18px;" width="500"></div>
    </div>
    
    <h3>Position bei runden Spiegeln</h3>
    
    <div class="row">
    <div class="col-md-12"><img alt="Position bei runden Spiegeln" class="img-fluid lazy" height="285" src="/images/content/optionen/position.PNG" style="margin-bottom: 18px;" widht="867"></div>
    </div>
    
    <p>Alle Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p>
    <!--
    <h3>Wichtiger Hinweis</h3>
    
    <p><strong>Falls im Raum ein Wandschalter für den Spiegel vorhanden ist</strong>, raten wir von einem zusätzlichen Sensor am Spiegel ab. <strong>Bitte beachten Sie:</strong>&nbsp;Eine eventuell vorhandene Digitaluhr ist dann nur bei eingeschalteter Spiegel-Beleuchtung sichtbar. Und auch die Bluetooth-Lautsprecher funktionieren nur bei eingeschalteter Beleuchtung.</p> --></div>`,
  },
  {
    id: 5,
    title: "Informationen zur Uhr",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>In den Spiegel kann auf Wunsch eine <strong>Uhr</strong> – digital oder analog – integriert werden.</p>
    
    <h3>Digitaluhr</h3>
    
    <p>Bei der Digitaluhr handelt es sich um eine&nbsp;<strong>Digitaluhr mit APP-Synchronisierung</strong>&nbsp;mit 24-Stunden-Anzeige und weißen Ziffern. Die Einstellung der Uhrzeit&nbsp;erfolgt über eine Bluetooth-Schnittstelle mit Hilfe der App "MirrorDigitalUhr" (Drittanbeiter, bei älteren Modellen "LIANE TimeSync"), die Sie im Apple AppStore oder im Google Play Store herunterladen können. Die Maße betragen <strong>4,0 x 1,5 cm (Breite x Höhe)</strong>.</p>
    
    <p>Android: <a href="https://play.google.com/store/apps/details?id=de.ares_gmbh.bluetoothapp1">https://play.google.com/store/apps/details?id=de.ares_gmbh.bluetoothapp1</a><br>
    oder: <a href="https://play.google.com/store/apps/details?id=com.mirrordigitalwatch&amp;gl=DE" target="_blank">https://play.google.com/store/apps/details?id=com.mirrordigitalwatch&amp;gl=DE</a><br>
    oder:&nbsp;<a href="https://play.google.com/store/apps/details?id=com.bniss.mirror&amp;gl=DE">https://play.google.com/store/apps/details?id=com.bniss.mirror&amp;gl=DE</a></p>
    
    <p>iOS/Apple: <a href="https://apps.apple.com/de/app/mirrordigitaluhr/id1559428268" target="_blank">https://apps.apple.com/de/app/mirrordigitaluhr/id1559428268</a></p>
    
    <p><strong>Sie benötigt in jedem Fall einen Stromanschluss.</strong>&nbsp;Bei LED Spiegeln ist die Digitaluhr&nbsp;in der Regel an die Beleuchtung gekoppelt. Bei <strong><u>un</u>beleuchteten</strong> Spiegeln muss ein Stromanschluss für die Uhr vorhanden sein.</p>
    
    <p>Ist die Anzeige an die Beleuchtung des Spiegels gekoppelt, ist die Digitaluhr im ausgeschalteten Zustand nicht sichtbar. Dank integrierter Memory Funktion (Akku) läuft sie aber weiter.</p>
    
    <p><strong>Soll die Anzeige der Digitaluhr&nbsp;</strong>– unabhängig von der Beleuchtung –<strong>&nbsp;</strong><strong>dauerhaft sichtbar sein (optional)</strong>, stellen Sie bitte sicher, dass die Uhr permanent mit Strom versorgt wird und die&nbsp; Stromzufuhr des Spiegels nicht, z. B. über einen Raumschalter, unterbrochen wird. In diesem Fall würde die Anzeige erlöschen, die Uhr dank Memory Funktion aber weiterlaufen.</p>
    
    <h3>Analoguhr</h3>
    
    <p>Der Durchmesser der <strong>Analoguhr</strong>&nbsp;mit dem dünnen Metallrahmen und Abdeckung aus Kunststoff beträgt <strong>6,5 cm</strong>. Sie sitzt in einer Bohrung im Spiegel und steht nach vorn 1,5 cm heraus. Die 3-Zeiger Uhr mit weißem Zifferblatt kann daher ganz leicht rausgenommen und eingestellt werden. Die Uhr ist batteriebetrieben; der Batterie-Tausch ist ohne Abnehmen des Spiegels möglich.</p>
    
    <div class="row">
    <div class="col-md-6"><strong>Digitaluhr mit Bluetooth-/App-Steuerung</strong><br>
    <img alt="Spiegel Analoguhr" class="img-fluid lazy" height="500" src="/images/content/optionen/digitaluhr.jpg" style="margin-bottom: 18px;" width="500"></div>
    
    <div class="col-md-6"><strong>Analoguhr</strong><br>
    <img alt="Spiegel Analoguhr" class="img-fluid lazy" height="500" src="/images/content/optionen/analoguhr.jpg" style="margin-bottom:18px" width="500"></div>
    </div>
    
    <h3>Position bei runden Spiegeln</h3>
    
    <div class="row">
    <div class="col-md-12"><img alt="Position bei runden Spiegeln" class="img-fluid lazy" height="285" src="/images/content/optionen/position.PNG" style="margin-bottom: 18px;" widht="867"></div>
    </div>
    
    <h3>Raumteiler</h3>
    
    <p>Bei <strong>Raumteilern </strong>wird nur eine Uhr verbaut. Sie ist also nur von einer Seite sichtbar.</p>
    
    <p>Abbildung exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 6,
    title: "Informationen zur Spiegelheizung",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Eine Spiegelheizung verhindert bzw. beseitigt das Beschlagen des Spiegels. Dabei handelt es sich um eine Heizfolie, die auf der&nbsp;Rückseite des Spiegels verbaut wird und diesen erwärmt.&nbsp;Beheizbare Spiegel werden mit einem<strong> Extraschalter </strong>geliefert, damit Sie die Spiegelheizung nach Bedarf ein- und ausschalten können und nicht unnötig&nbsp;Strom verbrauchen.</p>
    
    <p>Der Schalter (Kippschalter oder Touch-Sensor) wird in der Regel unten montiert. Falls Sie eine andere Position wünschen, erkundigen Sie sich bitte kurz telefonisch oder per E-Mail, ob das bei dem von Ihnen ausgewählten Modell möglich ist. Wenn ja, vermerken Sie die gewünschte Position bitte bei der Bestellung im Kommentarfeld.</p>
    
    <p>Der Touch-Sensor leuchtet wie folgt:</p>
    
    <ul>
      <li>Spiegelheizung EIN = ROT</li>
      <li>Spiegelheizung AUS = KALTWEISS</li>
    </ul>
    
    <p>Der optisch mit dem Touch-Sensor identische<strong>&nbsp;ECO friendlich Switch Sensor</strong> verfügt über eine Abschaltautomatik. Die Spiegelheizung wird nach 45 Minuten automatisch ausgeschaltet.</p>
    
    <p><strong>Bitte beachten Sie</strong>, dass&nbsp;<strong>nicht&nbsp;</strong>die komplette Fläche des Spiegels beheizt wird, da die Spiegelheizung nicht auf den satinierten, hinterleuchteten Flächen montiert und auch nicht im Bereich eines TV Bildschirms montiert werden kann. Darüber hinaus müssen die Aufhängebleche, Beleuchtung und der Trafo auf der Rückseite Platz finden. Welche Spiegelheizung verbaut wird, hängt von der Größe des Spiegels und der gewünschten Ausstattung ab.</p>
    
    <h3>Spiegelheizung bei RUNDEN SPIEGELN</h3>
    
    <p>Bitte beachten Sie, dass eine Spiegelheizung aus Platzgründen erst bei Spiegeln ab einem <strong>Durchmesser von mindestens 750 mm</strong> verbaut werden kann.</p>
    
    <h4>Position des Schalters/Sensors bei runden Spiegeln</h4>
    
    <div class="row">
    <div class="col-md-12"><img alt="Position bei runden Spiegeln" class="img-fluid lazy" height="285" src="/images/content/optionen/position.PNG" style="margin-bottom: 18px;" widht="867"></div>
    </div>
    
    <h3>Spiegelheizung bei TV SPIEGELN</h3>
    
    <p>Bitte beachten Sie, dass die Spiegelheizung bzw. Heizfolie <strong>nicht im Bereich des TV Bildschirms</strong> verbaut werden kann. Je nach Position des TV Geräts wird sie&nbsp;neben, unter- oder oberhalb platziert. Die Antibeschlag-Funktion wirkt nur im unmittelbaren Umfeld der Heizfolie.</p>
    
    <h3>Spiegelheizung bei RAUMTEILERN</h3>
    
    <p>Bei Raumteilern muss die <strong>Breite mindestens 600 mm</strong> betragen, damit eine Spiegelheizung verbaut werden kann. Bei geringeren Breiten ist dies mangels Platz auf der Spiegelrückseite leider nicht möglich. Die Spiegelheizung wird nur <strong>hinter <u>einem</u> Spiegel verbaut</strong>. Falls beide Seiten mit einer Spiegelheizung versehen werden sollen, nehmen Sie bitte vor der Bestellung Kontakt mit uns auf.</p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Badspiegel mit Spiegelheizung" class="img-fluid lazy" height="500" src="/images/content/optionen/heizung.jpg" title="Badspiegel Spiegelheizung" width="500"></div>
    
    <div class="col-md-6"><img alt="Spiegelheizung Touch-Sensor" class="img-fluid lazy" height="500" src="/images/content/optionen/spiegel-heizung-touch-sensor.jpg?v=2" title="Spiegelheizung Touch-Sensor" width="500"></div>
    </div>
    
    <p>Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 7,
    title: "Informationen zur Steckdose",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Bei der Steckdose können&nbsp;Sie zwischen den Ausführungen <strong>"Schwarz matt"</strong> (Bild links bzw. oben) und<strong>&nbsp;"Chrom" </strong>(Chrom-Optik, glänzend, Bild rechts bzw. unten) wählen und die Position (rechts oder links unten) bestimmen.</p>
    
    <p>Sollte die <strong>Steckdose <u>nicht</u> rechts oder links unten</strong>, sondern an anderer Stelle angebracht werden, oder sollten Sie <strong>mehr als eine Steckdose</strong> benötigen, können Sie dies gerne während der Bestellung im Feld <strong>Kundenkommentar</strong> vermerken. Wir buchen zusätzliche Steckdosen dann manuell hinzu. Der im Konfigurator angegebene Preis wird pro Steckdose berechnet; der Rechnungsbetrag erhöht sich entsprechend.</p>
    
    <p>Bei <strong>Raumteilern</strong> wird die Steckdose nur auf einer Seite verbaut.</p>
    
    <p>Bei <strong>Kunden aus der Schweiz</strong> werden&nbsp;– abweichend von den Bildern&nbsp;– ausschließlich CH-Steckdosen verbaut.</p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Spiegel Steckdose und Schalter schwarz" class="img-fluid lazy" height="500" src="/images/content/optionen/Steckdose_schwarz.jpg?v=23" style="margin-bottom:18px" width="500"></div>
    
    <div class="col-md-6">
    <p><img alt="Spiegel Steckdose und Schalter in Chrom-Optik" class="img-fluid lazy" height="500" src="/images/content/optionen/Steckdose_chrom.jpg?v=23" style="margin-bottom:18px" width="500"></p>
    </div>
    
    <div class="col-md-6"><img alt="Spiegel Steckdose und Schalter schwarz" class="img-fluid lazy" height="500" src="/images/content/optionen/steckdose_schwarz_CH.jpg?v=23" style="margin-bottom:18px" width="500"></div>
    
    <div class="col-md-6">
    <p><img alt="Spiegel Steckdose und Schalter in Chrom-Optik" class="img-fluid lazy" height="500" src="/images/content/optionen/steckdose_chrom_CH.jpg?v=23" style="margin-bottom:18px" width="500"></p>
    </div>
    </div>
    
    <h3>Position bei runden Spiegeln</h3>
    
    <div class="row">
    <div class="col-md-12"><img alt="Position bei runden Spiegeln" class="img-fluid lazy" height="285" src="/images/content/optionen/position.PNG" style="margin-bottom: 18px;" widht="867"></div>
    </div>
    
    <p>Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 8,
    title: "Informationen zum Bluetooth Lautsprecher",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Mit Bluetooth-Receiver und -Lautsprecher erhalten Sie die Möglichkeit, die Tonausgabe von Ihrem Bluetooth-kompatiblen Gerät per Funk auf den Spiegel zu übertragen. Spielen Sie Ihre Musik, Ihren Radiosender oder Ihren Musik-Streaming-Dienst von Ihrem Smartphone (Handy, iPhone), iPod, iPad, Tablet, Netbook oder einem MP3-Player&nbsp;– um nur einige zu nennen.</p>
    
    <p>Die Lautsprecher werden&nbsp;– nicht sichtbar&nbsp;– hinter dem Spiegel verbaut. Die Position ist variabel und hängt davon, welche Optionen (Schminkspiegel, Spiegelheizung etc.) sie darüber hinaus wählen.</p>
    
    <h3>WHD Sound-System</h3>
    
    <p>Beim WHD Sound-System handelt es sich nicht um Standard-Lautsprecher, sondern um <strong>Körperschallwandler</strong>, die den gesamten Spiegel in einen Lautsprecher mit <strong>erstaunlicher Klangqualität</strong> verwandeln. Sie werden unsichtbar hinter dem Spiegel verbaut und erlauben kabelloses Streamen per Bluetooth vom Smartphone, Computer, Laptop oder Tablet.</p>
    
    <p><strong>Bitte beachten Sie</strong></p>
    
    <ul>
      <li>Bluetooth-Lautsprecher sind nicht kompatibel mit Smart Home Assistenten, wie z.B. Alexa, Google Home.<br>
      &nbsp;</li>
      <li>Wird die Stromzufuhr des Spiegels (für Beleuchtung, Bluetooth etc.) über einen Raumschalter geregelt, dann lassen sich die Lautsprecher nur nutzen, wenn zeitgleich das Licht eingeschaltet ist.<br>
      &nbsp;</li>
      <li>Sollen Beleuchtung und Bluetooth-Receiver/-Lautsprecher unabhängig voneinander funktionieren, sorgen Sie bitte für Dauerstrom und schalten Sie die Beleuchtung des Spiegels über einen optional erhältlichen Schalter oder Sensor.</li>
    </ul>
    
    <p><strong>Hinweis für TV Spiegel</strong></p>
    
    <ul>
      <li>Die Tonausgabe erfolgt ausschließlich über das TV-Gerät. Die Bluetooth-Lautsprecher sind getrennt davon zu betrachten. Sie dienen der Tonausgabe vom Smartphone oder anderen Bluetooth-fähigen Geräten.</li>
    </ul></div>`,
  },
  {
    id: 9,
    title: "Informationen zur Versiegelung",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Die TÜV-geprüfte langlebige BriteGuard® Versiegelung schützt die Oberfläche des Glases gegen Verschmutzung durch Seife, Shampoo, Kalk etc. Egal, ob es sich um eine Duschwand, Tischplatte, Küchenrückwand, Spiegel etc. aus Klarglas, satiniertem Glas oder mit Laser-Motiv handelt, sie bleibt länger sauber und ist sehr leicht zu reinigen.&nbsp;BriteGuard® kommt auch bei Fassaden, Wintergärten und Balustraden zum Einsatz. <a href="https://bohle.com/mediando/Online/PDF/BriteGuard_Sealer_deutsch.pdf" target="_blank">Nähere Informationen finden Sie hier</a>.&nbsp;</p>
    
    <p><strong>Es wird nur <u>eine</u> Seite versiegelt. Die versiegelte Seite wird durch einen Aufkleber gekennzeichnet. </strong>Außer bei Spiegel<strong>&nbsp;</strong>versiegeln wir Ihnen gerne beide Seiten, wenn möglich, gerne gegen Aufpreis. Bitte nehmen Sie in dem Fall Kontakt mit uns auf.</p>
    
    <h3>Wichtige Hinweise zur Pflege von versiegelten Glasflächen</h3>
    
    <p>Bitte verwenden Sie keine scharfen oder abrasiv wirkenden Reiniger oder Hilfsmittel.</p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Sticker BriteGuard Surface Protection" class="img-fluid lazy" src="/images/content/Sticker-BriteGuard-Surface-Protection.jpg" style="margin-bottom:18px"></div>
    
    <div class="col-md-6">&nbsp;</div>
    </div></div>`,
  },
  {
    id: 10,
    title: "Informationen zur Glasablage",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Sollten Sie eine Ablagefläche für Badaccessoires benötigen, so können Sie eine praktische Ablage aus Glas zu Ihrem Badspiegel hinzu buchen. Diese ist&nbsp;<strong>10 cm tief&nbsp;</strong>(andere Tiefen auf Anfrage), <strong>6 mm dick</strong> und in jeder gewünschten Breite erhätlich.&nbsp;Zwischen der Glasablage und dem Spiegel befindet sich ein wenige Millimeter breiter Spalt.</p>
    
    <p><strong>Bitte beachten Sie:</strong> Die Glasablage<strong>&nbsp;</strong>wird mit Halterungen befestigt und kann daher <u>nicht bündig</u> an der unteren Spiegelkante platziert werden. Bei unten beleuchteten Spiegeln befindet Sie sich oberhalb der Satinierung bzw. des dahinter montierten Profils mit der LED-Beleuchtung.</p>
    
    <p>Die<strong> Breite der Ablage</strong> können Sie im Konfigurator frei wählen. Bitte berücksichtigen Sie dabei, dass die Glasablage aus ästhetischen Gründen nicht über den satinierten Leuchtflächen bzw. -streifen angebracht und daher etwa 10 bis 20 cm kürzer gewählt werden sollte.</p>
    
    <p>Die Ablage ist in zwei verschiendenen Ausführungen erhältlich:</p>
    
    <ol>
      <li><strong>Klarglas</strong> (Bild links bzw. oben) oder</li>
      <li><strong>satiniert,</strong>&nbsp;also in Milchglas-Optik (Bild rechts bzw. unten). Die obere Fläche ist in dem Fall glatt, die Fläche unten angeraut.</li>
    </ol>
    
    <p>Natürlich ist es auch möglich, die <strong>Glasablage an der oberen Kante</strong>&nbsp;des Spiegels anzubringen, falls Sie dort eine Ablagefläche wünschen. Bitte vermerken Sie die genaue Position in dem Fall bei der Bestellung am Ende des Bestellprozesses unter "Anmerkungen zu dieser Bestellung".</p>
    
    <h3>Hinweis für Klappspiegel</h3>
    
    <p>Wenn Sie die Flügel des Klappspiegels einklappen und dabei versehentlich die Glasablage touchieren, besteht die Gefahr, dass Glasablage und/oder Klapp-Elemente beschädigt werden. Wir raten daher von einer Glasablage bei Klappspiegeln ab.</p>
    
    <h3>Hinweis für Raumteiler Spiegel</h3>
    
    <p>Eine Ablage wird nur auf einer Seite des Raumteilers angebracht. Wünschen Sie auf beiden Seiten eine Glasablage, vermerken Sie es bitte im Kommentarfeld am Ende des Bestellprozesses. Wir berechnen den Preis einer zweiten Ablage dann nach.</p>
    
    <div class="row">
    <div class="col-md-6"><img align="middle" alt="Spiegel Ablage Klarglas" class="img-fluid lazy" height="500" src="/images/content/optionen/Glasablage_klar_Badspiegel.jpg" style="margin-bottom:18px" width="500"></div>
    
    <div class="col-md-6"><img alt="Spiegel Ablage aus satiniertem Glas" class="img-fluid lazy" height="500" src="/images/content/optionen/Glasablage_satiniert_Badspiegel.jpg" style="margin-bottom:18px" width="500"></div>
    </div>
    <p>Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 11,
    title: "Informationen zum Facettenschliff",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p><strong>Alle unsere Spiegel sind bereits sauber geschliffen und poliert</strong>, sodass keine Verletzungsgefahr besteht.<strong> </strong>Der Facettenschliff ist eine zusätzliche Option, bei der der Spiegel rundherum über eine Breite von wahlweise 10 mm, 20 mm, 30 mm, 40 mm, 50 mm oder 60 mm schräg angeschliffen wird.</p>
    
    <p>Ein <strong>Spiegel mit Facette</strong> (Bild links bzw. oben) wirkt aufgrund der Lichteffekte, die durch die schräg geschliffenen Kanten entstehen, besonders edel.&nbsp;Bei der Ansicht von der Seite ruft sie einen regenbogenähnlichen Effekt hervor.</p>
    
    <p><strong>Bitte beachten Sie</strong>, dass eine Facette nicht oder nur bedingt sinnvoll ist, wenn die satinierten, hinterleuchteten Flächen entlang der Kanten des Spiegels verlaufen. Dort, wo die Satinierung verläuft, ist der Facettenschliff – im beleuchteten wie im unbeleuchteten Zustand – nicht zu sehen ist.</p>
    
    <p><strong>Die Lieferzeit erhöht sich bei einem Facettenschliff um 3 Tage.</strong></p>
    
    <p>Das Bild rechts bzw. unten zeigt eine geschliffene und polierte Kante, die bei allen unseren Spiegeln Standard ist.</p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Facettenschliff Spiegel mit Facette" class="img-fluid lazy" height="500" src="/images/content/optionen/spiegel-facette-facettenschliff.jpg" style="margin-bottom:18px" title="Facettenschliff Spiegel mit Facette" width="500"></div>
    
    <div class="col-md-6"><img alt="Rahmenloser Spiegel mit geschliffenen und polierten Kanten" class="img-fluid lazy" height="500" src="/images/content/optionen/spiegel-rahmenlos-kante-geschliffen-poliert.jpg" style="margin-bottom:18px" title="Rahmenloser Spiegel mit geschliffenen und polierten Kanten" width="500"></div>
    </div>
    
    <p>Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 12,
    title: "Informationen zur Schrank Art",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <h3>Aufputz-Schränke</h3>
    
    <p>Es handelt sich um einen ganz normalen Hängeschrank fürs Bad, an dessen Rückseite eine Schiene zum Aufhängen angebracht ist.&nbsp;<strong>Bitte beachten Sie</strong>, dass die Schränke fertigungsbedingt eine<strong> Maßtoleranz von +/- 2-3 mm bei der Breite bzw. Höhe</strong> aufweisen.</p>
    
    <h3>Unterputz-/Einbau-Schränke</h3>
    
    <p>Unterputz bzw. Einbau bedeutet, dass der Bad-Schrank <strong>in die Wand eingelassen</strong> wird. Die Scharniere sind so beschaffen, dass die Türen in den Raum hinein aufschwingen, sodass Sie keine Probleme bekommen, wenn der Schrank bündig mit der Wand abschließt. Falls Sie eine Waschbecken- oder Deckenbeleuchtung, die nach unten bzw. oben hin strahlt, wünschen, dann muss der Holzkorpus des Schranks mindestens 3 cm aus der Wand ragen.</p>
    
    <p>Bitte beachten Sie, dass Unterputz-Schränke <strong>ohne Aufhängeschiene</strong> geliefert werden. Zur korrekten Ermittlung der Tiefe des Einbau-Schranks, lesen Sie bitte auch unter dem Punkt "Tiefe" nach.&nbsp;</p>
    
    <h3>Halbeinbau</h3>
    Halbeinbau bedeutet, dass der Unterputz-Schrank <strong>nur teilweise in die Wand eingelassen</strong> wird. Dies ist z. B. der Fall, wenn der Schrank mit einer Waschtisch- oder Deckenbeleuchtung ausgestattet sein soll. Wählen Sie einen <strong>Halbeinbau-Schrank mit Einbau-Rahmen</strong>, können Sie die Tiefe des Einbau-Rahmens individuell angeben. <strong>Bitte beachten Sie: Damit Schrank und Rahmen sicher befestigt werden können, beträgt die Mindest-Tiefe 70 mm.</strong> Ansonsten ist der Einbau-Rahmen immer so tief wie der Schrank.&nbsp;
    
    <h3>Einbaurahmen für Einbau-Schränke</h3>
    
    <p>Ein Einbaurahmen ist zu empfehlen, wenn die Nische in der Wand unebene Oberflächen aufweist und Sie daher möglicherweise Probleme bekommen, den Schrank zu befestigen. Der Einbaurahmen ist 20&nbsp;mm stark und umgibt den Schrank vollständig. Der Einbaurahmen ist weiß; nur die vordere Kante&nbsp;bzw. Aufsicht entspricht dem Dekor des Korpus.&nbsp;Die Tiefe des Einbaurahmens ist stets 30 mm geringer als die des Schrank-Korpusses, damit der Schrank problemlos eingefliest werden kann.</p>
    
    <p>Wenn Sie einen Bad Schrank mit Einbaurahmen bestellen, dann versteht sich die von Ihnen gewählte&nbsp;<strong>Breite und Höhe <u>ohne</u> Einbaurahmen</strong>. Wenn Sie also eine Breite von 1000 mm und eine Höhe von 800 mm wählen, dann betragen die Außenmaße des Einbaurahmens 1040 mm (1000 + 2 * 20) in der Breite&nbsp;und 840 mm (800 + 2 x 20) in der Höhe.</p>
    
    <p>Falls Sie den Einbaurahmen vorab benötigen, können wir Ihnen diesen – unabhängig vom Schrank – binnen 2 bis 3 Wochen liefern.</p>
    
    <p><strong>Tipp: </strong>Falls der Einbaurahmen nicht eingemauert oder mit Fliesen abgedeckt wird, ist dieser sichtbar. Wir empfehlen Ihnen, in diesen Fällen, oben und unten einen <strong>Glasüberstand</strong>&nbsp;zu wählen, sodass der Rahmen bei geschlossenen Türen von diesen verdeckt ist.</p></div>`,
  },
  {
    id: 13,
    title: "Informationen zur Wunschbreite, Wunschhöhe",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Hier haben Sie die Möglichkeit,&nbsp;die genauen Abmessungen Ihres Spiegels einzugeben: mindestens 400 mm, höchstens 2500 mm. <strong>Bitte geben Sie die Abmessungen in Millimetern an</strong> (nicht Zentimeter). <strong>Die Maße verstehen sich inklusive der satinierten&nbsp;Flächen, bei Rahmenspiegeln auch inklusive Rahmen.</strong> Bitte beachten Sie, dass die Maße&nbsp;– trotz allergrößter Sorgfalt in der Fertigung – um ± 2-3 mm (Toleranz) abweichen können.</p>
    
    <p><strong>Hinweis für Standspiegel mit TV:</strong> Bitte geben Sie hier die Höhe des Spiegels ein. Für die Gesamthöhe rechnen Sie bitte die verstellbaren Füße (20-50 mm) hinzu.</p>
    
    <h4>Tipp</h4>
    
    <p>Sie möchten schauen, wie die Größe des Spiegels sich zum Raum verhält, ob der Schminkspiegel an der richtigen Stelle sitzt usw.? Dann nehmen Sie doch einfach Alufolie oder ein Stück Pappe, schneiden es in der entsprechenden Größe zu und befestigen Sie es provisorisch an der Wand. Die Position des Schminkspiegels kann mit Hilfe einer Untertasse eingezeichnet werden. Für die Leuchtstreifen können Sie Krepp-Klebeband verwenden.</p>
    
    <h4>Montage von Spiegeln in Nischen</h4>
    
    <p>Falls Sie den Spiegel in einer Nische platzieren wollen, denken Sie bitte daran, dass bei der Montage oben etwa 4 cm "Luft" benötigt werden, um den Spiegel ordnungsgemäß mit Hilfe der Aufhängebleche und L-Haken an der Wand befestigen zu können. Falls die Nische also zum Beispiel 100 cm hoch ist, darf die Höhe des Spiegels maximal 96 cm betragen. In der Breite gibt es keine Einschränkungen.</p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Breite LED Badspiegel" class="img-fluid lazy" height="500" src="/images/content/optionen/breite.jpg" style="margin-bottom:18px" title="Breite LED Badspiegel" width="500"></div>
    
    <div class="col-md-6"><img alt="Höhe LED Badspiegel" class="img-fluid lazy" height="500" src="/images/content/optionen/h%C3%B6he.jpg" style="margin-bottom:18px" title="Höhe LED Badspiegel" width="500"></div>
    </div>
    
    <p>Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 14,
    title: "Informationen zur Wunschbreite, Wunschhöhe",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Höhe und Breite des Badezimmerspiegelschranks können Sie bis auf den Millimeter genau angeben.&nbsp;<strong>Bitte beachten Sie&nbsp;</strong>jedoch, dass die Spiegelschränke fertigungsbedingt eine <strong>Maßtoleranz von +/- 2-3 mm</strong> bei Breite und Höhe aufweisen.</p>
    
    <p>Die maximale Breite des Spiegelschranks beträgt 250 cm, die maximale Höhe 160 cm.&nbsp;Wir empfehlen,<strong> pro Tür</strong>&nbsp;eine Breite von <strong>mindestens 30 cm </strong>zu veranschlagen.</p>
    
    <p>Die Spiegelfläche ist je nach gewähltem Modell etwas kleiner als der Schrank, da die hinterleuchteten (satinierten) Flächen nicht verspiegelt sind.</p>
    
    <h3>Bad Spiegelschränke mit offenen Fächern bzw. Regalen</h3>
    
    <p>Breite und Höhe schließen die offenen Fächer mit ein. Sie geben also die Maße inklusive der Fächer ein. Für <strong>offene Fächer seitlich oder mittig</strong>&nbsp;empfehlen wir eine Breite von <strong>mindestens 17 cm</strong> (15 cm lichtes Maß plus 2 cm für die Seitenwand), da die Fächer ansonsten einfach zu klein werden. Ebenso sollte für <strong>Regale oben bzw. unten</strong> eine Höhe von mindestens 17 cm eingeplant werden.</p>
    
    <p>Die Rückwand der Fächer besteht – ebenso wie die Rückwand im Schrank – aus dem gewählten Material:&nbsp;weiße oder schwarze, 8 mm starke, kunststoffbeschichtete&nbsp;Hartfaser oder aber verspiegelte Rückwand.</p>
    
    <p>Falls Sie <strong>Fächer an nur einer Seite</strong> haben wollen, teilen Sie uns dies, sowie die gewünschte lichte Breite bitte unter 'Ihre Bemerkungen' mit.</p>
    
    <h3>Spiegelschränke mit Leucht-Kammern</h3>
    
    <p>Einige Modelle enthalten&nbsp;<strong>Kammern für die Beleuchtung</strong>. Diese sind mindestens 60 mm breit (lichtes Maß). Der nutzbare Innenraum des Schranks fällt entsprechend kleiner aus.</p></div>`,
  },
  {
    id: 15,
    title: "Informationen zur Schranktiefe",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <h3>Spiegelschränke mit Holz-Korpus</h3>
    
    <p>Die Tiefe bezeichnet die <strong>Tiefe des Holzkorpus</strong>. Um die Gesamt-Tiefe des Schranks zu ermitteln, rechnen Sie bitte <strong>6 mm für die Spiegeltüren</strong> hinzu sowie ca. 1-3 mm Abstand zwischen Türen und Korpus, da die Türen in der Regel nicht 100-prozentig aufliegen.</p>
    
    <p><strong>Wichtiger Hinweis zur Nutztiefe bei Holz-Korpus-Schränke</strong></p>
    
    <p>Um den für Sie nutzbaren Raum <strong id="dekor">im</strong>&nbsp;Bad Spiegelschrank zu ermitteln, ziehen Sie von der Tiefe des Schranks bitte 3 cm ab. Diese werden für die Rückwand und den Raum hinter dem Schrank benötigt, wo der Trafo für die Beleuchtung, die Aufhängeschiene etc. verbaut wird.</p>
    
    <p><strong>Rechenbeispiel für&nbsp;Holz-Korpus-Schränke</strong></p>
    
    <p>Bei einem Spiegelschrank mit einer Tiefe von 12,5 cm, ist der nutzbare Schrank-Innenraum nur 9,5 cm tief.</p>
    
    <h3>Spiegelschränke mit Aluminium-Korpus</h3>
    
    <p>Bitte beachten Sie&nbsp;die Information zur Tiefe im Konfigurator.</p>
    
    <p><strong>Wichtiger Hinweis zur Nutztiefe bei Alu-Schränken</strong></p>
    
    <p>Um den für Sie nutzbaren Raum <strong id="dekor">im</strong>&nbsp;Bad Spiegelschrank zu ermitteln, ziehen Sie von der Tiefe des Schranks bitte 2,8&nbsp;cm ab. Diese werden für die Rückwand und den Raum hinter dem Schrank benötigt, wo der Trafo für die Beleuchtung, die Aufhängeschiene etc. verbaut wird.</p>
    
    <p><strong>Rechenbeispiel für Alu Schränke</strong></p>
    
    <p>Bei einem Spiegelschrank mit einer Tiefe von 15,3&nbsp;cm, ist der nutzbare Schrank-Innenraum 12,5&nbsp;cm tief.</p></div>`,
  },
  {
    id: 16,
    title: "Informationen zu den Dekoren",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1>Möbel Dekore</h1>
    <p>Wählen Sie aus über hundert möglichen Dekoren.&nbsp;Wenn Sie darauf klicken, öffnet sich <strong>in einem neuen Fenster</strong> die Webseite der Firma Egger. Dort finden Sie weitere Bilder sowie Empfehlungen, welche sich ideal miteinander kombinieren lassen.&nbsp;<a class="js-scroll-trigger" href="#mehr" title="mehr">Mehr...</a></p>
    
    <p><a class="js-scroll-trigger" href="#badmoebel-weiss" title="Badmöbel weiß"><span class="btn btn-primary custom-btn" style="margin:5px">Weiss</span></a> <a class="js-scroll-trigger" href="#badmoebel-grau" title="Badmöbel grau"><span class="btn btn-primary custom-btn" style="margin:5px">Grau</span></a> <a class="js-scroll-trigger" href="#badmoebel-beige" title="Badmöbel beige"><span class="btn btn-primary custom-btn" style="margin:5px">Beige</span></a> <a class="js-scroll-trigger" href="#badmoebel-schwarz" title="Badmöbel schwarz"><span class="btn btn-primary custom-btn" style="margin:5px">Schwarz</span></a> <a class="js-scroll-trigger" href="#badmoebel-blau-gruen-rot-gelb" title="Badmöbel Uni-Farben"><span class="btn btn-primary custom-btn" style="margin:5px">andere Farben</span></a> <a class="js-scroll-trigger" href="#badmoebel-holz" title="Badmöbel Holz Dekore"><span class="btn btn-primary custom-btn" style="margin:5px">Holz-Dekore</span></a> <a class="js-scroll-trigger" href="#badmoebel-eiche" title="Badmöbel Eiche"><span class="btn btn-primary custom-btn" style="margin:5px">Eiche</span></a> <a class="js-scroll-trigger" href="#badmoebel-betonoptik" title="Badmöbel Betonoptik"><span class="btn btn-primary custom-btn" style="margin:5px">Betonoptik</span></a> <a class="js-scroll-trigger" href="#premium" title="Premium Dekore"><span class="btn btn-primary custom-btn" style="margin:5px">Premium Dekore</span></a> <a class="js-scroll-trigger" href="#badmoebel-dekore" title="Badmöbel Dekore"><span class="btn btn-primary custom-btn" style="margin:5px">andere Materialien</span></a> <a class="js-scroll-trigger" href="#struktur" title="Oberlächenstrukturen"><span class="btn btn-primary custom-btn" style="margin:5px">Oberflächenstrukturen</span></a> <a href="/Moebel/Holz-Farbdekor-Muster::2781.html" title="Dekor-Muster bestellen"><span class="btn btn-primary custom-btn" style="margin:5px">Dekor-Muster bestellen</span></a></p>
    
    <p>&nbsp;</p>
    
    <h2 id="badmoebel-weiss">Weiß</h2>
    
    <div class="row imgborder">
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/W1100_9" rel="noopener" target="_dekor"><img alt="W1100 ST9 Alpinweiss" class="img-fluid lazy" height="500" src="/images/content/dekore/W1100-ST30-Alpinweiss-leicht-glaenzend-glatt.jpg" width="500"></a>
    
    <p class="text-center">W1100 ST9 Alpinweiss<br>
    leicht strukturiert (Standard)</p>
    </div>
    
    <div class="col-md-3"><img alt="W1100 ST30 Alpinweiss" class="img-fluid lazy" height="500" src="/images/content/dekore/W1100-ST30-Alpinweiss-leicht-glaenzend-glatt.jpg" width="500">
    <p class="text-center">W1100 ST30 Alpinweiss<br>
    glänzend, glatt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/W980_2" rel="noopener" target="_dekor"><img alt="W980 ST2 Platinweiss" class="img-fluid lazy" height="500" src="/images/content/dekore/W980-ST2-Platinweiss-Creemeweiss-matt-strukturiert.jpg" width="500"></a>
    
    <p class="text-center">W980 ST2 Platinweiss<br>
    leicht strukturiert</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U104_9" rel="noopener" target="_dekor"><img alt="U104 ST9 Alabasterweiß" class="img-fluid lazy" height="500" src="/images/content/dekore/U104-ST9-Alabasterweiss-matt.jpg" width="500"></a>
    
    <p class="text-center">U104 ST9 Alabasterweiß<br>
    Oberfläche matt</p>
    </div>
    </div>
    
    <h2 id="badmoebel-holz">Holz-Dekore</h2>
    
    <h3 id="badmoebel-eiche">Eiche</h3>
    
    <div class="row imgborder">
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1180_37" rel="noopener" target="_dekor"><img alt="H1180 ST37 Halifax Eiche natur " class="img-fluid lazy" height="500" src="/images/content/dekore/H1180-ST37-Halifax-Eiche-natur.jpg" width="500"></a>
    
    <p class="text-center">H1180 ST37 Halifax Eiche natur</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3326_28" rel="noopener" target="_dekor"><img alt="H3326 ST28 Gladstone Eiche greige " class="img-fluid lazy" height="500" src="/images/content/dekore/H3326-ST28-Gladstone-Eiche-greige.jpg" width="500"></a>
    
    <p class="text-center">H3326 ST28 Gladstone Eiche greige</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3309_28" rel="noopener" target="_dekor"><img alt="H3309 ST28 Gladstone Eiche sandbeige " class="img-fluid lazy" height="500" src="/images/content/dekore/H3309-ST28-Gladstone-Eiche-sandbeige.jpg" width="500"></a>
    
    <p class="text-center">H3309 ST28 Gladstone Eiche sandbeige</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1145_10" rel="noopener" target="_dekor"><img alt="H1145 ST10 Bardolino Eiche natur " class="img-fluid lazy" height="500" src="/images/content/dekore/H1145-ST10-Bardolino-Eiche-natur.jpg" width="500"></a>
    
    <p class="text-center">H1145 ST10 Bardolino Eiche natur</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3325_28" rel="noopener" target="_dekor"><img alt="H3325 ST28 Gladstone Eiche tabak " class="img-fluid lazy" height="500" src="/images/content/dekore/H3325-ST28-Gladstone-Eiche-tabak.jpg" width="500"></a>
    
    <p class="text-center">H3325 ST28 Gladstone Eiche tabak</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1181_37" rel="noopener" target="_dekor"><img alt="H1181 ST37 Halifax Eiche tabak " class="img-fluid lazy" height="500" src="/images/content/dekore/H1181-ST37-Halifax-Eiche-tabak.jpg" width="500"></a>
    
    <p class="text-center">H1181 ST37 Halifax Eiche tabak</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3154_36" rel="noopener" target="_dekor"><img alt="H3154 ST36 Charleston Eiche dunkelbraun " class="img-fluid lazy" height="500" src="/images/content/dekore/H3154-ST36-Charleston-Eiche-dunkelbraun.jpg" width="500"></a>
    
    <p class="text-center">H3154 ST36 Charleston Eiche dunkelbraun</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3133_12" rel="noopener" target="_dekor"><img alt="H3133 ST12 Davos Eiche trüffelbraun " class="img-fluid lazy" height="500" src="/images/content/dekore/H3133-ST12-Davos-Eiche-trueffelbraun.jpg" width="500"></a>
    
    <p class="text-center">H3133 ST12 Davos Eiche trüffelbraun</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3156_12" rel="noopener" target="_dekor"><img alt="H3156 ST12 Corbridge Eiche grau " class="img-fluid lazy" height="500" src="/images/content/dekore/H3156-ST12-Corbridge-Eiche-grau.jpg" width="500"></a>
    
    <p class="text-center">H3156 ST12 Corbridge Eiche grau</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1150_10" rel="noopener" target="_dekor"><img alt="H1150 ST10 Arizona Eiche grau " class="img-fluid lazy" height="500" src="/images/content/dekore/H1150-ST10-Arizona-Eiche-grau.jpg" width="500"></a>
    
    <p class="text-center">H1150 ST10 Arizona Eiche grau</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1176_37" rel="noopener" target="_dekor"><img alt="H1176 ST37 Halifax Eiche weiß " class="img-fluid lazy" height="500" src="/images/content/dekore/H1176-ST37-Halifax-Eiche-weiss.jpg" width="500"></a>
    
    <p class="text-center">H1176 ST37 Halifax Eiche weiß</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3303_10" rel="noopener" target="_dekor"><img alt="H3303 ST10 Hamilton Eiche natur " class="img-fluid lazy" height="500" src="/images/content/dekore/H3303-ST-10-Hamilton-Eiche-natur.jpg" width="500"></a>
    
    <p class="text-center">H3303 ST 10 Hamilton Eiche natur</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1334_9" rel="noopener" target="_dekor"><img alt="H1334 ST9 Sorano Eiche naturhell " class="img-fluid lazy" height="500" src="/images/content/dekore/H1334-ST9-Sorano-Eiche-naturhell.jpg" width="500"></a>
    
    <p class="text-center">H1334 ST9 Sorano Eiche naturhell</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3395_12" rel="noopener" target="_dekor"><img alt="H3395 ST12 Corbridge Eiche natur " class="img-fluid lazy" height="500" src="/images/content/dekore/H3395-ST12-Corbridge-Eiche-natur.jpg" width="500"></a>
    
    <p class="text-center">H3395 ST12 Corbridge Eiche natur</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1137_12" rel="noopener" target="_dekor"><img alt="H1137 ST12 Eiche schwarzbraun " class="img-fluid lazy" height="500" src="/images/content/dekore/H1137_ST12_Eiche_schwarzbraun.jpg" width="500"></a>
    
    <p class="text-center">H1137 ST12 Eiche schwarzbraun</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1312_10" rel="noopener" target="_dekor"><img alt="H1312 ST10 Whiteriver Eiche sandbeige" class="img-fluid lazy" height="500" src="/images/content/dekore/H1312_ST10_Whiteriver_Eiche_sandbeige.jpg" width="500"></a>
    
    <p class="text-center">H1312 ST10 Whiteriver Eiche sandbeige</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1313_10" rel="noopener" target="_dekor"><img alt="H1313 ST10 Whiteriver Eiche graubraun" class="img-fluid lazy" height="500" src="/images/content/dekore/H1313_ST10_Whiteriver_Eiche_graubraun.jpg" width="500"></a>
    
    <p class="text-center">H1313 ST10 Whiteriver Eiche graubraun</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1318_10" rel="noopener" target="_dekor"><img alt="H1318 ST10 Wildeiche natur" class="img-fluid lazy" height="500" src="/images/content/dekore/H1318_ST10_Wildeiche_natur.jpg" width="500"></a>
    
    <p class="text-center">H1318 ST10 Wildeiche natur</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1331_10" rel="noopener" target="_dekor"><img alt="H1331 ST10 Santa Fe Eiche grau" class="img-fluid lazy" height="500" src="/images/content/dekore/H1331_ST10_Santa_Fe_Eiche_grau.jpg" width="500"></a>
    
    <p class="text-center">H1331 ST10 Santa Fe Eiche grau</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1336_37" rel="noopener" target="_dekor"><img alt="H1336 ST37 Halifax Eiche lasiert sandgrau" class="img-fluid lazy" height="500" src="/images/content/dekore/H1336_ST37_Halifax_Eiche_lasiert_sandgrau.jpg" width="500"></a>
    
    <p class="text-center">H1336 ST37 Halifax Eiche lasiert sandgrau</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1344_32" rel="noopener" target="_dekor"><img alt="H1344 ST32 Sherman Eiche cognacbraun" class="img-fluid lazy" height="500" src="/images/content/dekore/H1344_ST32_Sherman_Eiche_cognacbraun.jpg" width="500"></a>
    
    <p class="text-center">H1344 ST32 Sherman Eiche cognacbraun</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1345_32" rel="noopener" target="_dekor"><img alt="H1345 ST32 Sherman Eiche grau" class="img-fluid lazy" height="500" src="/images/content/dekore/H1345_ST32_Sherman_Eiche_grau.jpg" width="500"></a>
    
    <p class="text-center">H1345 ST32 Sherman Eiche grau</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1346_32" rel="noopener" target="_dekor"><img alt="H1346 ST32 Sherman Eiche anthrazit" class="img-fluid lazy" height="500" src="/images/content/dekore/H1346_ST32_Sherman_Eiche_anthrazit.jpg" width="500"></a>
    
    <p class="text-center">H1346 ST32 Sherman Eiche anthrazit</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H2033_10" rel="noopener" target="_dekor"><img alt="H2033 ST10 Hunton Eiche dunkel" class="img-fluid lazy" height="500" src="/images/content/dekore/H2033_ST10_Hunton_Eiche_dunkel.jpg" width="500"></a>
    
    <p class="text-center">H2033 ST10 Hunton Eiche dunkel</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3146_19" rel="noopener" target="_dekor"><img alt="H3146 ST19 Lorenzo Eiche beigegrau" class="img-fluid lazy" height="500" src="/images/content/dekore/H3146_ST19_Lorenzo_Eiche_beigegrau.jpg" width="500"></a>
    
    <p class="text-center">H3146 ST19 Lorenzo Eiche beigegrau</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3158_19" rel="noopener" target="_dekor"><img alt="H3158 ST19 Vicenza Eiche grau" class="img-fluid lazy" height="500" src="/images/content/dekore/H3158_ST19_Vicenza_Eiche_grau.jpg" width="500"></a>
    
    <p class="text-center">H3158 ST19 Vicenza Eiche grau</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3176_37" rel="noopener" target="_dekor"><img alt="H3176 ST37 Halifax Eiche Zinn" class="img-fluid lazy" height="500" src="/images/content/dekore/H3176_ST37_Halifax_Eiche_Zinn.jpg" width="500"></a>
    
    <p class="text-center">H3176 ST37 Halifax Eiche Zinn</p>
    </div>
    </div>
    
    <p><a href="/Moebel/Holz-Farbdekor-Muster::2781.html" title="Dekor-Muster bestellen"><span class="btn btn-primary custom-btn" style="margin:5px">Dekor-Muster bestellen</span></a></p>
    
    <h3>Weitere Holzdekore</h3>
    
    <div class="row imgborder">
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3450_22" rel="noopener" target="_dekor"><img alt="H3450 ST22 Fleetwood weiß" class="img-fluid lazy" height="500" src="/images/content/dekore/H3450-ST22-Fleetwood-weiss.jpg" width="500"></a>
    
    <p class="text-center">H3450 ST22 Fleetwood weiß</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1277_9" rel="noopener" target="_dekor"><img alt="H1277 ST9 Lakeland Akazie hell " class="img-fluid lazy" height="500" src="/images/content/dekore/H1277-ST9-Lakeland-Akazie-hell.jpg" width="500"></a>
    
    <p class="text-center">H1277 ST9 Lakeland Akazie hell</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1250_36" rel="noopener" target="_dekor"><img alt="H1250 ST36 Esche Navarra " class="img-fluid lazy" height="500" src="/images/content/dekore/H1250-ST36-Esche-Navarra.jpg" width="500"></a>
    
    <p class="text-center">H1250 ST36 Esche Navarra</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1733_9" rel="noopener" target="_dekor"><img alt="H1733 ST9 Mainau Birke " class="img-fluid lazy" height="500" src="/images/content/dekore/H1733-ST9-Mainau-Birke.jpg" width="500"></a>
    
    <p class="text-center">H1733 ST9 Mainau Birke</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3840_9" rel="noopener" target="_dekor"><img alt="H3840 ST9 Mandal Ahorn natur " class="img-fluid lazy" height="500" src="/images/content/dekore/H3840-ST9-Mandal-Ahorn-natur.jpg" width="500"></a>
    
    <p class="text-center">H3840 ST9 Mandal Ahorn natur</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3470_22" rel="noopener" target="_dekor"><img alt="H3470 ST22 Astfichte natur " class="img-fluid lazy" height="500" src="/images/content/dekore/H3470-ST22-Astfichte-natur.jpg" width="500"></a>
    
    <p class="text-center">H3470 ST22 Astfichte natur</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1582_15" rel="noopener" target="_dekor"><img alt="H1582 ST15 Ellmau Buche " class="img-fluid lazy" height="500" src="/images/content/dekore/H1582-ST15-Ellmau-Buche.jpg" width="500"></a>
    
    <p class="text-center">H1582 ST15 Ellmau Buche</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1511_15" rel="noopener" target="_dekor"><img alt="H15111 St15 Bavaria Buche " class="img-fluid lazy" height="500" src="/images/content/dekore/H15111-St15-Bavaria-Buche.jpg" width="500"></a>
    
    <p class="text-center">H15111 St15 Bavaria Buche</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1615_9" rel="noopener" target="_dekor"><img alt="H1615 ST9 Kirschbaum Verona " class="img-fluid lazy" height="500" src="/images/content/dekore/H1615-ST9-Kirschbaum-Verona.jpg" width="500"></a>
    
    <p class="text-center">H1615 ST9 Kirschbaum Verona</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3404_38" rel="noopener" target="_dekor"><img alt="H3404 ST38 Berglärche braun " class="img-fluid lazy" height="500" src="/images/content/dekore/H3404-ST38-Berglaerche-braun.jpg" width="500"></a>
    
    <p class="text-center">H3404 ST38 Berglärche braun</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1487_22" rel="noopener" target="_dekor"><img alt="H1487 ST22 Bramberg Fichte " class="img-fluid lazy" height="500" src="/images/content/dekore/H1487-ST22-Bramberg-Fichte.jpg" width="500"></a>
    
    <p class="text-center">H1487 ST22 Bramberg Fichte</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3090_22" rel="noopener" target="_dekor"><img alt="H3090 ST22 Shorewood " class="img-fluid lazy" height="500" src="/images/content/dekore/H3090-ST22-Shorewood.jpg" width="500"></a>
    
    <p class="text-center">H3090 ST22 Shorewood</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1212_33" rel="noopener" target="_dekor"><img alt="H1212 ST33 Tossini Ulme braun " class="img-fluid lazy" height="500" src="/images/content/dekore/H1212-ST33-Tossini-Ulme-braun.jpg" width="500"></a>
    
    <p class="text-center">H1212 ST33 Tossini Ulme braun</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3734_9" rel="noopener" target="_dekor"><img alt="H3734 ST9 Dijon Nussbaum natur " class="img-fluid lazy" height="500" src="/images/content/dekore/H3734-ST9-Dijon-Nussbaum-natur.jpg" width="500"></a>
    
    <p class="text-center">H3734 ST9 Dijon Nussbaum natur</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1400_36" rel="noopener" target="_dekor"><img alt="H1400 ST36 Stadlholz " class="img-fluid lazy" height="500" src="/images/content/dekore/H1400-ST36-Stadlholz.jpg" width="500"></a>
    
    <p class="text-center">H1400 ST36 Stadlholz</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1486_36" rel="noopener" target="_dekor"><img alt="H1486 ST36 Pasadena Pinie " class="img-fluid lazy" height="500" src="/images/content/dekore/H1486-ST36-Pasadena-Pinie.jpg" width="500"></a>
    
    <p class="text-center">H1486 ST36 Pasadena Pinie</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3453_22" rel="noopener" target="_dekor"><img alt="H3453 ST22 Fleetwood lavagrau " class="img-fluid lazy" height="500" src="/images/content/dekore/H3453-ST22-Fleetwood-lavagrau.jpg" width="500"></a>
    
    <p class="text-center">H3453 ST22 Fleetwood lavagrau</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3408_38" rel="noopener" target="_dekor"><img alt="H3408 ST38 Berglärche thermobraun" class="img-fluid lazy" height="500" src="/images/content/dekore/H3408_ST38_Berglaerche_thermobraun.jpg" width="500"></a>
    
    <p class="text-center">H3408 ST38 Berglärche thermobraun</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1714_19" rel="noopener" target="_dekor"><img alt="H1714 ST19 Lincoln Nussbaum" class="img-fluid lazy" height="500" src="/images/content/dekore/H1714_ST19_Lincoln_Nussbaum.jpg" width="500"></a>
    
    <p class="text-center">H1714 ST19 Lincoln Nussbaum</p>
    </div>
    
    <div class="col-md-3 z"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1702_33" rel="noopener" target="_dekor"><img alt="H1702 ST33 Tossini Ulme dunkelbraun " class="img-fluid lazy" height="500" src="/images/content/dekore/H1702_ST33_Tossini_Ulme_dunkelbraun.jpg" width="500"></a>
    
    <p class="text-center">H1702 ST33 Tossini Ulme dunkelbraun</p>
    </div>
    </div>
    
    <p><a href="/Moebel/Holz-Farbdekor-Muster::2781.html" title="Dekor-Muster bestellen"><span class="btn btn-primary custom-btn" style="margin:5px">Dekor-Muster bestellen</span></a></p>
    
    <h2 id="badmoebel-betonoptik">Betonoptik</h2>
    
    <div class="row imgborder">
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F187_9" rel="noopener" target="_dekor"><img alt="Betonoptik Chicago Concrete Dunkelgrau F187 ST9" class="img-fluid lazy" height="500" src="/images/content/dekore/chicago-concrete-dunkelgrau-F187-ST9.jpg" width="500"></a>
    
    <p class="text-center">F187 ST9 Chicago Concrete Dunkelgrau</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F186_9" rel="noopener" target="_dekor"><img alt="Betonoptik Chicago Concrete Hellgrau F186 ST9 " class="img-fluid lazy" height="500" src="/images/content/dekore/chicago-concrete-hellgrau-F186-ST9.jpg" width="500"></a>
    
    <p class="text-center">F186 ST9 Chicago Concrete Hellgrau</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F274_9" rel="noopener" target="_dekor"><img alt="F274 ST9 Beton hell" class="img-fluid lazy" height="500" src="/images/content/dekore/F274_ST9_Beton_hell.jpg" width="500"></a>
    
    <p class="text-center">F274 ST9 Beton hell</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F823_10" rel="noopener" target="_dekor"><img alt="F823 ST10 Cefalu Beton hell" class="img-fluid lazy" height="500" src="/images/content/dekore/F823_ST10_Cefalu_Beton_hell.jpg" width="500"></a>
    
    <p class="text-center">F823 ST10 Cefalu Beton hell</p>
    </div>
    </div>
    
    <h2 id="premium">Premium-Dekore</h2>
    
    <p>Wählen Sie aus 24 Premium Dekoren mit matt oder glänzend veredelter Oberfläche. Diese bieten wir <strong>ausschließlich für Unterschränke mit Echtholz-Platten aus Wildeiche oder Nussbaum</strong> an. Die hochwertigen Lackplatten verfügen über eine mehrfach UV-lackierte Oberfläche. Sie zeichnen sich durch&nbsp;hohe Beständigkeit gegenüber Mikrokratzern aus und halten auch hoher Beanspruchung stand.</p>
    
    <h3>Matte Oberfläche</h3>
    
    <p>Premium Dekore mit matter Oberfläche erkennen Sie an dem <strong>Kürzel PM (M = matt)&nbsp;</strong>im Namen.</p>
    
    <div class="row imgborder">
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F120_PM" rel="noopener" target="_dekor"><img alt="F120 PM Metal Rock Hellgrau (matt)" class="img-fluid lazy" height="500" src="/images/content/dekore/f120-pm-metal-rock-hellgrau.jpg" width="500"></a>
    
    <p class="text-center">F120 PM Metal Rock Hellgrau (matt)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F206_PM" rel="noopener" target="_dekor"><img alt="F206 PM Pietra Grigia Schwarz (matt)" class="img-fluid lazy" height="500" src="/images/content/dekore/f206-pm-pietra-grigia-schwarz.jpg" width="500"></a>
    
    <p class="text-center">F206 PM Pietra Grigia Schwarz (matt)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F627_PM" rel="noopener" target="_dekor"><img alt="F627 PM Stahl dunkel (matt)" class="img-fluid lazy" height="500" src="/images/content/dekore/f627-pm-stahl-dunkel.jpg" width="500"></a>
    
    <p class="text-center">F627 PM Stahl dunkel (matt)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F812_PM" rel="noopener" target="_dekor"><img alt="F812 PM Levanto Marmor weiss (matt)" class="img-fluid lazy" height="500" src="/images/content/dekore/f812-pm-levanto-marmor-weiss.jpg" width="500"></a>
    
    <p class="text-center">F812 PM Levanto Marmor weiss (matt)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U599_PM" rel="noopener" target="_dekor"><img alt="U599 PM Indigoblau (matt)" class="img-fluid lazy" height="500" src="/images/content/dekore/u599-pm-indigoblau.jpg" width="500"></a>
    
    <p class="text-center">U599 PM Indigoblau (matt)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U702_PM" rel="noopener" target="_dekor"><img alt="U702 PM Kaschmirgrau (matt)" class="img-fluid lazy" height="500" src="/images/content/dekore/u702-pm-kaschmirgrau.jpg" width="500"></a>
    
    <p class="text-center">U702 PM Kaschmirgrau (matt)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U708_PM" rel="noopener" target="_dekor"><img alt="U708 PM Hellgrau (matt)" class="img-fluid lazy" height="500" src="/images/content/dekore/u708-pm-hellgrau.jpg" width="500"></a>
    
    <p class="text-center">U708 PM Hellgrau (matt)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U727_PM" rel="noopener" target="_dekor"><img alt="U727 PM Steingrau (matt)" class="img-fluid lazy" height="500" src="/images/content/dekore/u727-pm-steingrau.jpg" width="500"></a>
    
    <p class="text-center">U727 PM Steingrau (matt)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U732_PM" rel="noopener" target="_dekor"><img alt="U732 PM Staubgrau (matt)" class="img-fluid lazy" height="500" src="/images/content/dekore/u732-pm-staubgrau.jpg" width="500"></a>
    
    <p class="text-center">U732 PM Staubgrau (matt)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U961_PM" rel="noopener" target="_dekor"><img alt="U961 PM Graphitschwarz (matt)" class="img-fluid lazy" height="500" src="/images/content/dekore/u961-pm-graphitschwarz.jpg" width="500"></a>
    
    <p class="text-center">U961 PM Graphitschwarz (matt)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U999_PM" rel="noopener" target="_dekor"><img alt="U999 PM Schwarz (matt)" class="img-fluid lazy" height="500" src="/images/content/dekore/u999-pm-schwarz.jpg" width="500"></a>
    
    <p class="text-center">U999 PM Schwarz (matt)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/W1000_PM" rel="noopener" target="_dekor"><img alt="W1000 PM Premiumweiss (matt)" class="img-fluid lazy" height="500" src="/images/content/dekore/w1000-pm-premiumweiss.jpg" width="500"></a>
    
    <p class="text-center">W1000 PM Premiumweiss (matt)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/W1100_PM" rel="noopener" target="_dekor"><img alt="W1100 PM Alpinweiss (matt)" class="img-fluid lazy" height="500" src="/images/content/dekore/w1100-pm-alpinweiss.jpg" width="500"></a>
    
    <p class="text-center">W1100 PM Alpinweiss (matt)</p>
    </div>
    </div>
    
    <h3>Glänzende Oberfläche</h3>
    
    <p>Premium Dekore mit glänzender Oberfläche erkennen Sie an dem <strong>Kürzel PG (G = glänzend) </strong>im Namen.</p>
    
    <div class="row imgborder">
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F206_PG" rel="noopener" target="_dekor"><img alt="F206 PG Pietra Grigia Schwarz (glänzend)" class="img-fluid lazy" height="500" src="/images/content/dekore/f206-pm-pietra-grigia-schwarz.jpg" width="500"></a>
    
    <p class="text-center">F206 PG Pietra Grigia Schwarz (glänzend)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F812_PG" rel="noopener" target="_dekor"><img alt="F812 PG Levanto Marmor weiss (glänzend)" class="img-fluid lazy" height="500" src="/images/content/dekore/f812-pm-levanto-marmor-weiss.jpg" width="500"></a>
    
    <p class="text-center">F812 PG Levanto Marmor weiss (glänzend)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U323_PG" rel="noopener" target="_dekor"><img alt="U323 PG Signalrot (glänzend)" class="img-fluid lazy" height="500" src="/images/content/dekore/u323-pg-signalrot.JPG" width="500"></a>
    
    <p class="text-center">U323 PG Signalrot (glänzend)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U702_PG" rel="noopener" target="_dekor"><img alt="U702 PG Kaschmirgrau (glänzend)" class="img-fluid lazy" height="500" src="/images/content/dekore/u702-pm-kaschmirgrau.jpg" width="500"></a>
    
    <p class="text-center">U702 PG Kaschmirgrau (glänzend)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U708_PG" rel="noopener" target="_dekor"><img alt="U708 PG Hellgrau (glänzend)" class="img-fluid lazy" height="500" src="/images/content/dekore/u708-pm-hellgrau.jpg" width="500"></a>
    
    <p class="text-center">U708 PG Hellgrau (glänzend)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U727_PG" rel="noopener" target="_dekor"><img alt="U727 PG Steingrau (glänzend)" class="img-fluid lazy" height="500" src="/images/content/dekore/u727-pm-steingrau.jpg" width="500"></a>
    
    <p class="text-center">U727 PG Steingrau (glänzend)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U732_PG" rel="noopener" target="_dekor"><img alt="U732 PG Staubgrau (glänzend)" class="img-fluid lazy" height="500" src="/images/content/dekore/u732-pm-staubgrau.jpg" width="500"></a>
    
    <p class="text-center">U732 PG Staubgrau (glänzend)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U961_PG" rel="noopener" target="_dekor"><img alt="U961 PG Graphitschwarz (glänzend)" class="img-fluid lazy" height="500" src="/images/content/dekore/u961-pm-graphitschwarz.jpg" width="500"></a>
    
    <p class="text-center">U961 PG Graphitschwarz (glänzend)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U999_PG" rel="noopener" target="_dekor"><img alt="U999 PG Schwarz (glänzend)" class="img-fluid lazy" height="500" src="/images/content/dekore/u999-pm-schwarz.jpg" width="500"></a>
    
    <p class="text-center">U999 PG Schwarz (glänzend)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/W1000_PG" rel="noopener" target="_dekor"><img alt="W1000 PG Premiumweiss (glänzend)" class="img-fluid lazy" height="500" src="/images/content/dekore/w1000-pm-premiumweiss.jpg" width="500"></a>
    
    <p class="text-center">W1000 PG Premiumweiss (glänzend)</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/W1100_PG" rel="noopener" target="_dekor"><img alt="W1100 PG Alpinweiss (glänzend)" class="img-fluid lazy" height="500" src="/images/content/dekore/w1100-pm-alpinweiss.jpg" width="500"></a>
    
    <p class="text-center">W1100 PG Alpinweiss (glänzend)</p>
    </div>
    </div>
    
    <h2 id="badmoebel-dekore">Andere Materialien</h2>
    
    <div class="row imgborder">
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F121_87" rel="noopener" target="_dekor"><img alt="F121 ST87 Metal Rock anthrazit" class="img-fluid lazy" height="500" src="/images/content/dekore/F121_ST87_Metal_Rock_anthrazit.jpg" width="500"></a>
    
    <p class="text-center">F121 ST87 Metal Rock anthrazit</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F242_10" rel="noopener" target="_dekor"><img alt="F242 ST10 Jura Schiefer anthrazit" class="img-fluid lazy" height="500" src="/images/content/dekore/F242_ST10_Jura_Schiefer_anthrazit.jpg" width="500"></a>
    
    <p class="text-center">F242 ST10 Jura Schiefer anthrazit</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F302_87" rel="noopener" target="_dekor"><img alt="F302 ST87 Ferro bronze" class="img-fluid lazy" height="500" src="/images/content/dekore/F302_ST87_Ferro_bronze.jpg" width="500"></a>
    
    <p class="text-center">F302 ST87 Ferro bronze</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F416_10" rel="noopener" target="_dekor"><img alt="F416 ST10 Textil beige" class="img-fluid lazy" height="500" src="/images/content/dekore/F416_ST10_Textil_beige.jpg" width="500"></a>
    
    <p class="text-center">F416 ST10 Textil beige</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F417_10" rel="noopener" target="_dekor"><img alt="F417 ST10 Textil grau" class="img-fluid lazy" height="500" src="/images/content/dekore/F417_ST10_Textil_grau.jpg" width="500"></a>
    
    <p class="text-center">F417 ST10 Textil grau</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F434_20" rel="noopener" target="_dekor"><img alt="F434 ST20 Steelbrush cubanit
    e" class="img-fluid lazy" height="500" src="/images/content/dekore/F434_ST20_Steelbrush_cubanit.jpg" width="500"></a>
    
    <p class="text-center">F434 ST20 Steelbrush cubanit</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F462_20" rel="noopener" target="_dekor"><img alt="F462 ST20 Metallic Indigoblau
    " class="img-fluid lazy" height="500" src="/images/content/dekore/F462_ST20_Metallic_Indigoblau.jpg" width="500"></a>
    
    <p class="text-center">F462 ST20 Metallic Indigoblau</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F463_20" rel="noopener" target="_dekor"><img alt="F463 ST20 Metallic Platingrau" class="img-fluid lazy" height="500" src="/images/content/dekore/F463_ST20_Metallic_Platingrau.jpg" width="500"></a>
    
    <p class="text-center">F463 ST20 Metallic Platingrau</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F500_20" rel="noopener" target="_dekor"><img alt="F500 ST20 Inox metallic" class="img-fluid lazy" height="500" src="/images/content/dekore/F500_ST20_Inox_metallic.jpg" width="500"></a>
    
    <p class="text-center">F500 ST20 Inox metallic</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F509_2" rel="noopener" target="_dekor"><img alt="F509 ST2 Aluminium" class="img-fluid lazy" height="500" src="/images/content/dekore/F509_ST2_Aluminium.jpg" width="500"></a>
    
    <p class="text-center">F509 ST2 Aluminium</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/F784_20" rel="noopener" target="_dekor"><img alt="F784 ST20 Samtgold gebürstet" class="img-fluid lazy" height="500" src="/images/content/dekore/F784_ST20_Samtgold_gebürstet.jpg" width="500"></a>
    
    <p class="text-center">F784 ST20 Samtgold gebürstet</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3192_19" rel="noopener" target="_dekor"><img alt="H3192 ST19 Fineline Metallic braun" class="img-fluid lazy" height="500" src="/images/content/dekore/H3192_ST19_Fineline_Metallic_braun.jpg" width="500"></a>
    
    <p class="text-center">H3192 ST19 Fineline Metallic braun</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H3190_19" rel="noopener" target="_dekor"><img alt="H3190 ST19 Fineline Metallic anthrazit
    " class="img-fluid lazy" height="500" src="/images/content/dekore/H3190_ST19_Fineline_Metallic_anthrazit.jpg" width="500"></a>
    
    <p class="text-center">H3190 ST19 Fineline Metallic anthrazit</p>
    </div>
    </div>
    
    <h2 id="badmoebel-uni">Einfarbige Oberflächen</h2>
    
    <h3 id="badmoebel-grau">Grau</h3>
    
    <div class="row imgborder">
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U775_9" rel="noopener" target="_dekor"><img alt="U775 ST9 Weißgrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U775-ST9-Weissgrau-matt.jpg" width="500"></a>
    
    <p class="text-center">U775 ST9 Weissgrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U707_9" rel="noopener" target="_dekor"><img alt="U707 ST9 Seidengrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U707-ST9-Seidengrau-matt.jpg" width="500"></a>
    
    <p class="text-center">U707 ST9 Seidengrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U708_9" rel="noopener" target="_dekor"><img alt="U708 ST9 Hellgrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U708-ST9-Hellgrau-matt.jpg" width="500"></a>
    
    <p class="text-center">U708 ST9 Hellgrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U763_9" rel="noopener" target="_dekor"><img alt="U763 ST9 Perlgrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U763-ST9-Perlgrau-matt.jpg" width="500"></a>
    
    <p class="text-center">U763 ST9 Perlgrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U732_9" rel="noopener" target="_dekor"><img alt="U732 ST9 Staubgrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U732-ST9-Staubgrau-matt.jpg" width="500"></a>
    
    <p class="text-center">U732 ST9 Staubgrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U960_9" rel="noopener" target="_dekor"><img alt="U960 ST9 Onyxgrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U960-ST9-Onyxgrau-matt.jpg" width="500"></a>
    
    <p class="text-center">U960 ST9 Onyxgrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U963_9" rel="noopener" target="_dekor"><img alt="U963 ST9 Diamantgrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U963-ST9-Diamantgrau-matt.jpg" width="500"></a>
    
    <p class="text-center">U963 ST9 Diamantgrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U968_9" rel="noopener" target="_dekor"><img alt="U968 ST9 Karbongrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U968_ST9_Karbongrau.jpg" width="500"></a>
    
    <p class="text-center">U968 ST9 Karbongrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U780_9" rel="noopener" target="_dekor"><img alt="U780 ST9 Monumentgrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U780_ST9_Monumentgrau.jpg" width="500"></a>
    
    <p class="text-center">U780 ST9 Monumentgrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U788_9" rel="noopener" target="_dekor"><img alt="U788 ST9 Arktisgrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U788_ST9_Arktisgrau.jpg" width="500"></a>
    
    <p class="text-center">U788 ST9 Arktisgrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U741_9" rel="noopener" target="_dekor"><img alt="U741 ST9 Lavagrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U741-ST9-Lavagrau-matt.jpg" width="500"></a>
    
    <p class="text-center">U741 ST9 Lavagrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U767_9" rel="noopener" target="_dekor"><img alt="U767 ST9 Cubanitgrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U767-ST9-Cubanitgrau-matt.jpg" width="500"></a>
    
    <p class="text-center">U767 ST9 Cubanitgrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U727_9" rel="noopener" target="_dekor"><img alt="U727 ST9 Steingrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U727-ST9-Steingrau-matt.jpg" width="500"></a>
    
    <p class="text-center">U727 ST9 Steingrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U201_9" rel="noopener" target="_dekor"><img alt="U201 ST9 Kieselgrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U201-ST9-Kieselgrau-matt.jpg" width="500"></a>
    
    <p class="text-center">U201 ST9 Kieselgrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U750_9" rel="noopener" target="_dekor"><img alt="U750 ST9 Lichtgrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U750-ST9-Lichtgrau-matt.jpg" width="500"></a>
    
    <p class="text-center">U750 ST9 Lichtgrau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U702_9" rel="noopener" target="_dekor"><img alt="U702 ST9 Kaschmirgrau" class="img-fluid lazy" height="500" src="/images/content/dekore/U702-ST9-Kaschmirgrau-matt.jpg" width="500"></a>
    
    <p class="text-center">U702 ST9 Kaschmirgrau<br>
    Oberfläche matt</p>
    </div>
    </div>
    
    <p><a href="/Moebel/Holz-Farbdekor-Muster::2781.html" title="Dekor-Muster bestellen"><span class="btn btn-primary custom-btn" style="margin:5px">Dekor-Muster bestellen</span></a></p>
    
    <h3 id="badmoebel-schwarz">Schwarz</h3>
    
    <div class="row imgborder">
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U899_9" rel="noopener" target="_dekor"><img alt="U899 ST9 Softschwarz" class="img-fluid lazy" height="500" src="/images/content/dekore/U899-ST9-Kosmosgrau-matt.jpg" width="500"></a>
    
    <p class="text-center">U899 ST9 Softschwarz<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U961_2" rel="noopener" target="_dekor"><img alt="U961 ST2 Graphitschwarz" class="img-fluid lazy" height="500" src="/images/content/dekore/U961-ST2-Graphitschwarz-leicht-glaenzend-und-strukturiert.jpg" width="500"></a>
    
    <p class="text-center">U961 Graphitschwarz<br>
    leicht glänzend, strukturiert</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U999_2" rel="noopener" target="_dekor"><img alt="U999 ST2 Schwarz" class="img-fluid lazy" height="500" src="/images/content/dekore/U999-ST2-Schwarz-leicht-glaenzend-und-strukturiert.jpg" width="500"></a>
    
    <p class="text-center">U999 ST2 Schwarz<br>
    Oberfläche leicht glänzend. strukturiert</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/H1137_12" rel="noopener" target="_dekor"><img alt="H1137 ST12 Eiche schwarzbraun " class="img-fluid lazy" height="500" src="/images/content/dekore/H1137_ST12_Eiche_schwarzbraun.jpg" width="500"></a>
    
    <p class="text-center">H1137 ST12 Eiche schwarzbraun</p>
    </div>
    </div>
    
    <h3 id="badmoebel-beige">Beige</h3>
    
    <div class="row imgborder">
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U216_9" rel="noopener" target="_dekor"><img alt="U216 ST9 Camebeige" class="img-fluid lazy" height="500" src="/images/content/dekore/U216-ST9-Camebeige-matt.jpg" width="500"></a>
    
    <p class="text-center">U216 ST9 Camebeige<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U222_9" rel="noopener" target="_dekor"><img alt="U222 ST9 Creambeige" class="img-fluid lazy" height="500" src="/images/content/dekore/U222-ST9-Creambeige-matt.jpg" width="500"></a>
    
    <p class="text-center">U222 ST9 Cremabeige<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U113_9" rel="noopener" target="_dekor"><img alt="U113 ST9 Cottonbeige" class="img-fluid lazy" height="500" src="/images/content/dekore/U113-ST9-Cottonbeige-matt.jpg" width="500"></a>
    
    <p class="text-center">U113 ST9 Cottonbeige<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U156_9" rel="noopener" target="_dekor"><img alt="U156 ST9 Sandbeige" class="img-fluid lazy" height="500" src="/images/content/dekore/U156-ST9-Sandbeige-matt.jpg" width="500"></a>
    
    <p class="text-center">U156 ST9 Sandbeige<br>
    Oberfläche matt</p>
    </div>
    </div>
    
    <h3 id="badmoebel-blau-gruen-rot-gelb">Andere Farben</h3>
    
    <div class="row imgborder">
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U740_9" rel="noopener" target="_dekor"><img alt="U740 ST9 Taupe dunkel" class="img-fluid lazy" height="500" src="/images/content/dekore/U740_ST9_Taupe_dunkel.jpg" width="500"></a>
    
    <p class="text-center">U740 ST9 Taupe dunkel<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U748_9" rel="noopener" target="_dekor"><img alt="U748 ST9 Trüffelbraun" class="img-fluid lazy" height="500" src="/images/content/dekore/U748-ST9-Trueffelbraun-matt.jpg" width="500"></a>
    
    <p class="text-center">U748 ST9 Trüffelbraun<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U399_9" rel="noopener" target="_dekor"><img alt="U399 ST9 Granatrot" class="img-fluid lazy" height="500" src="/images/content/dekore/U399_ST9_Granatrot.jpg" width="500"></a>
    
    <p class="text-center">U399 ST9 Granatrot<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U311_9" rel="noopener" target="_dekor"><img alt="U311 ST9 Burgundrot" class="img-fluid lazy" height="500" src="/images/content/dekore/U311-ST9-Burgundrot-matt.jpg" width="500"></a>
    
    <p class="text-center">U311 ST9 Burgundrot<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U323_9" rel="noopener" target="_dekor"><img alt="U323 ST9 Signalrot" class="img-fluid lazy" height="500" src="/images/content/dekore/U323-ST9-Signalrot-matt.jpg" width="500"></a>
    
    <p class="text-center">U323 ST9 Signalrot<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U321_9" rel="noopener" target="_dekor"><img alt="U321 ST9 Chinarot" class="img-fluid lazy" height="500" src="/images/content/dekore/U321-ST9-Chinarot-matt.jpg" width="500"></a>
    
    <p class="text-center">U321 ST9 Chinarot<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U390_9" rel="noopener" target="_dekor"><img alt="U390 ST9 Indianrot" class="img-fluid lazy" height="500" src="/images/content/dekore/U390_ST9_Indianrot.jpg" width="500"></a>
    
    <p class="text-center">U390 ST9 Indianrot<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U332_9" rel="noopener" target="_dekor"><img alt="U332 ST9 Orange" class="img-fluid lazy" height="500" src="/images/content/dekore/U332-ST9-Orange-matt.jpg" width="500"></a>
    
    <p class="text-center">U332 ST9 Orange<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U114_9" rel="noopener" target="_dekor"><img alt="U114 ST9 Brillantgelb" class="img-fluid lazy" height="500" src="/images/content/dekore/U114-ST9-Brillantgelb.jpg" width="500"></a>
    
    <p class="text-center">U114 ST9 Brillantgelb<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U131_9" rel="noopener" target="_dekor"><img alt="U131 ST9 Zitrusgelb" class="img-fluid lazy" height="500" src="/images/content/dekore/U131-ST9-Zitrusgelb-matt.jpg" width="500"></a>
    
    <p class="text-center">U131 ST9 Zitrusgelb<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U630_9" rel="noopener" target="_dekor"><img alt="U630 ST9 Limonengrün" class="img-fluid lazy" height="500" src="/images/content/dekore/U630-ST9-Limonengruen-matt.jpg" width="500"></a>
    
    <p class="text-center">U630 ST9 Limonengrün<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U600_9" rel="noopener" target="_dekor"><img alt="U600 ST9 Maigrün" class="img-fluid lazy" height="500" src="/images/content/dekore/U600_ST9_Maigruen.jpg" width="500"></a>
    
    <p class="text-center">U600 ST9 Maigrün<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U633_9" rel="noopener" target="_dekor"><img alt="U633 ST9 Türkisgrün" class="img-fluid lazy" height="500" src="/images/content/dekore/U633_ST9_Tuerkisgruen.jpg" width="500"></a>
    
    <p class="text-center">U633 ST9 Türkisgrün<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U504_9" rel="noopener" target="_dekor"><img alt="U504 ST9 Alpenseeblau" class="img-fluid lazy" height="500" src="/images/content/dekore/U504-ST9-Alpenseeblau-matt.jpg" width="500"></a>
    
    <p class="text-center">U504 ST9 Alpenseeblau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U560_9" rel="noopener" target="_dekor"><img alt="U560 ST9 Tiefseeblau" class="img-fluid lazy" height="500" src="/images/content/dekore/U560-ST9-Tiefseeblau-matt.jpg" width="500"></a>
    
    <p class="text-center">U560 ST9 Tiefseeblau<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U337_9" rel="noopener" target="_dekor"><img alt="U337 ST9 Fuchsiarot" class="img-fluid lazy" height="500" src="/images/content/dekore/U337-ST9-Fuchsiarot-matt.jpg" width="500"></a>
    
    <p class="text-center">U337 ST9 Fuchsiarot<br>
    Oberfläche matt</p>
    </div>
    
    <div class="col-md-3"><a href="https://www.egger.com/shop/de_DE/interior/decor-detail/U325_9" rel="noopener" target="_dekor"><img alt="U325 ST9 Antikrosa" class="img-fluid lazy" height="500" src="/images/content/dekore/U325_ST9_Antikrosa.jpg" width="500"></a>
    
    <p class="text-center">U325 ST9 Antikrosa<br>
    Oberfläche matt</p>
    </div>
    </div>
    
    <p><a href="/Moebel/Holz-Farbdekor-Muster::2781.html" title="Dekor-Muster bestellen"><span class="btn btn-primary custom-btn" style="margin:5px">Dekor-Muster bestellen</span></a></p>
    
    <h2 id="struktur">Oberflächenstrukturen</h2>
    
    <p>Die nachstehenden Bilder sollen Ihnen einen Eindruck von der Oberflächenstruktur der Holzdekorplatten geben.</p>
    
    <div class="row imgborder">
    <div class="col-md-3"><img alt="ST2 Smoothtouch Pearl" class="img-fluid lazy" height="500" src="/images/content/dekore/ST2-340x340px.jpg" width="500">
    <p class="text-center"><strong>ST2</strong></p>
    </div>
    
    <div class="col-md-3"><img alt="ST9 Smoothtouch Matt" class="img-fluid lazy" height="500" src="/images/content/dekore/ST9-340x340px.jpg" width="500">
    <p class="text-center"><strong>ST9</strong></p>
    </div>
    
    <div class="col-md-3"><img alt="ST10 Deepskin Rough" class="img-fluid lazy" height="500" src="/images/content/dekore/ST10-340x340px.jpg" width="500">
    <p class="text-center"><strong>ST10</strong></p>
    </div>
    
    <div class="col-md-3"><img alt="ST12 Omnipore Matt" class="img-fluid lazy" height="500" src="/images/content/dekore/U961-ST12-perspective-structure-10DE-fur-340x340.jpg" width="500">
    <p class="text-center"><strong>ST12</strong></p>
    </div>
    
    <div class="col-md-3"><img alt="ST15 Smoothtouch Velvet" class="img-fluid lazy" height="500" src="/images/content/dekore/ST15-340x340px.jpg" width="500">
    <p class="text-center"><strong>ST15</strong></p>
    </div>
    
    <div class="col-md-3"><img alt="ST22 Deepskin linear" class="img-fluid lazy" height="500" src="/images/content/dekore/ST22-340x340px.jpg" width="500">
    <p class="text-center"><strong>ST22</strong></p>
    </div>
    
    <div class="col-md-3"><img alt="ST28 Feelwood Nature" class="img-fluid lazy" height="500" src="/images/content/dekore/U961-ST28-perspective-structure-10DE-fur-340x340.jpg" width="500">
    <p class="text-center"><strong>ST28</strong></p>
    </div>
    
    <div class="col-md-3"><img alt="ST33 Feelwood Crafted" class="img-fluid lazy" height="500" src="/images/content/dekore/U961-ST33-perspective-structure-10DE-fur-340x340.jpg" width="500">
    <p class="text-center"><strong>ST33</strong></p>
    </div>
    
    <div class="col-md-3"><img alt="ST36 Feelwood Brushed" class="img-fluid lazy" height="500" src="/images/content/dekore/U961-ST36-perspective-structure-10DE-fur-340x340.jpg" width="500">
    <p class="text-center"><strong>ST36</strong></p>
    </div>
    
    <div class="col-md-3"><img alt="ST37 Feelwood Rift" class="img-fluid lazy" height="500" src="/images/content/dekore/ST37-340x340px.jpg" width="500">
    <p class="text-center"><strong>ST37</strong></p>
    </div>
    
    <div class="col-md-3"><img alt="ST38 Feelwood Pinegrain" class="img-fluid lazy" height="500" src="/images/content/dekore/U961-ST38-perspective-structure-10DE-fur-340x340.jpg" width="500">
    <p class="text-center"><strong>ST38</strong></p>
    </div>
    </div>
    
    <p id="mehr">Neben <strong>Holz</strong>-Oberflächen&nbsp;stehen Ihnen auch zahlreiche <strong>einfarbige Oberlächen</strong> und Material-Nachbildungen, wie z. B. <strong>Betonoptik,</strong>&nbsp;zur Verfügung. Anhand des Dekor-Namens (ST9, ST22 etc.) erkennen Sie auch die&nbsp;<a class="js-scroll-trigger" href="#struktur" title="Oberlächenstrukturen">Oberflächenstruktur</a>.</p>
    
    <p>Wir verwenden für die von uns gefertigten Badmöbel ausschließlich Eurodekor Platten der Firma EGGER. Das 1961 gegründete Familienunternehmen fertigt unter anderem hochwertige Holzwerkstoffe für die Möbelindustrie und verfügt über sechs Standorte in Deutschland.</p>
    
    <p><strong>Sie benötigen Muster?</strong>&nbsp;Wir senden Ihnen gerne&nbsp;<a href="/Moebel/Holz-Farbdekor-Muster::2781.html" title="Dekor-Muster">Holz-/Farbdekor-Muster</a>&nbsp;zu.<br>
    <br>
    <strong>Bilder: </strong> ©&nbsp;<a href="https://www.egger.com/shop/de_DE/" rel="”noopener”" target="_blank">EGGER</a></p>
    
    <p>Abbildungen exemplarisch. Bitte beachten Sie, dass es insbesondere durch die Verwendung unterschiedlicher Displaytechnologien und aufgrund Ihrer individuellen Displayeinstellungen zu Verfälschungen bei der Farbdarstellung kommen kann. Die auf Ihrem Display dargestellten Farben können deswegen von der tatsächlichen Farbe der auf unseren Produktfotos dargestellten Produkte abweichen. Im Zweifel empfehlen wir Ihnen Testmuster anzufordern.</p></div>`,
  },
  {
    id: 17,
    title: "Informationen zu Rückwänden",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Bei unseren Spiegelschränken ist eine <strong>matt weiße Rückwand Standard</strong> (siehe erstes Bild). Optional kann auch eine graue, beige oder verspiegelte Rückwand verbaut werden. Speziell für schwarze Spiegelschränke bieten wir diese auch in matt scharzer Ausführung an. Gerne senden wir Ihnen ein <a href="https://www.spiegel21.de/Moebel/Holz-Farbdekor-Muster::2781.html" title="Holz-/Farbdekor-Muster bestellen">Holz-/Farbdekor-Muster</a>.</p>
    
    <h4>Bitte beachten Sie</h4>
    
    <p>Mit "Rückwand" ist <u>nicht</u> die Rückseite der Spiegeltüren gemeint, sondern die Wand hinter den Einlegeböden. Die Spiegeltüren unserer Schränke sind auch in der Standard-Ausführung immer außen und innen (doppelt) verspiegelt, sodass Sie sich von allen Seiten betrachten können.</p>
    
    <div class="row">
    <div class="col-md-6">
    <h3>Spiegelschrank mit matt weißer Rückwand</h3>
    <img alt="Spiegelschrank mit weißer Rückwand" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-rueckwand-weiss.jpg" style="margin-bottom: 18px;" width="560"></div>
    
    <div class="col-md-6">
    <h3>Spiegelschrank mit verspiegelter Rückwand</h3>
    <img alt="Spiegelschrank mit verspiegelter Rückwand" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-rueckwand-verspiegelt1.jpg" width="560"></div>
    
    <div class="col-md-6">
    <h3>Spiegelschrank mit grauer Rückwand</h3>
    <img alt="Spiegelschrank mit grauer Rückwand" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-rueckwand-arktisgrau.jpg" style="margin-bottom: 18px;" width="560"></div>
    
    <div class="col-md-6">
    <h3>Spiegelschrank mit beiger Rückwand</h3>
    <img alt="Spiegelschrank mit beiger Rückwand" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-rueckwand-cremabeige.jpg" style="margin-bottom: 18px;" width="560"></div>
    
    <div class="col-md-6">
    <h3>Spiegelschrank mit schwarzer Rückwand, leicht glänzend</h3>
    <img alt="Spiegelschrank mit schwarzer Rückwand" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-rueckwand-schwarz.jpg" style="margin-bottom: 18px;" width="560"></div>
    </div>
    
    <p>Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 18,
    title: "Informationen zu den Türen",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Die Türen des Badezimmer-Spiegelschranks bestehen aus hochwertigem Kristallspiegel und sind stets <strong>beidseitig verspiegelt</strong> und 6 mm dick. Da sie aus zwei je 3 mm starken Glasplatten bestehen, weisen die Türen konstruktionsbedingt eine schmale Fuge auf. Je nach Breite des Spiegelschranks können Sie zwischen 1 bis 6 Türen wählen. Die <strong>Mindestbreite&nbsp;pro Tür</strong>&nbsp;beträgt<strong>&nbsp;30 cm</strong>.</p>
    
    <ul>
      <li>1 Tür bei einer Breite von 300 - 650 mm</li>
      <li>2 Türen bei 600 - 1300 mm</li>
      <li>3 Türen bei 900 - 1950&nbsp;mm</li>
      <li>4 Türen bei 1200 - 2500 mm</li>
      <li>5 Türen bei 1500 - 2500 mm</li>
      <li>6 Türen bei 1800 - 2500 mm</li>
    </ul>
    
    <p>Wir empfehlen, die Anzahl der Türen so zu wählen, dass die Breite einer einzelnen Tür die Höhe&nbsp;<u>nicht</u>&nbsp;überschreitet, die einzelne Tür also immer höher ist als breit, da Türen im Querformat sich – besonders,wenn sie sehr groß sind –&nbsp;ungünstig auf die Scharniere auswirken..</p>
    
    <h3>Mittelseiten / Zwischenwände im Schrank</h3>
    
    <p><strong>Bitte beachten Sie</strong>, dass, um die Stabilität zu gewährleisten, bei bestimmten Maßen<strong>&nbsp;Mittelseiten</strong> eingebaut werden müssen. Diese trennen&nbsp;den&nbsp;Spiegelschrank in mehrere Zonen. Das ist zum Beispiel der Fall&nbsp;bei 2-türigen Schränken (Anschlagrichtung links/rechts)&nbsp;mit einer Zonen-Breite &gt; 800 mm.</p>
    
    <p>Es kommt jedoch immer auf die individuelle Konfiguration basierend auf der&nbsp;Anzahl der Türen, der&nbsp;gewählten Anschlagrichtung und die davon abhängige Größe der Zonen&nbsp;an.</p></div>`,
  },
  {
    id: 19,
    title: "Informationen zur Anschlagrichtung",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Die Anschlagrichtung der Spiegeltüren können Sie&nbsp;wählen. Stellen Sie sich vor, dass Sie vor dem Schrank stehen und auf den Schrank schauen. Beginnen Sie mit der Tür ganz links.</p>
    
    <p>L = Tür öffnet nach links<br>
    R = Tür öffnet nach rechts</p>
    
    <p><strong>Hinweis für Spiegelschränke für Dachschrägen:</strong> Die abgeschrägte Tür öffnet stets in Richtung der langen Seite.</p>
    
    <p><img alt="Badezimmer Spiegelschrank Anschlagrichtung Türen" class="img-fluid" src="/images/content/optionen/spiegelschrank-anschlagrichtung-tuer.JPG" style="height: 226px; width: 750px;" title="Badezimmer Spiegelschrank Anschlagrichtung Türen"></p></div>`,
  },
  {
    id: 20,
    title: "Info Spiegelschrank Türgriffe / Push-to-Open / Glasüberstand",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Es stehen drei Möglichkeiten zur Auswahl, um die Türen des Spiegelschranks zu öffnen:</p>
    
    <ol>
      <li>Türgriffe</li>
      <li>Push-to-Open / Drucköffner</li>
      <li>Glasüberstand unten</li>
    </ol>
    
    <h3>1. Türgriffe</h3>
    
    <p>Für Ihren Badspiegelschrank stehen folgende Türgriffe zur Auswahl:</p>
    
    <div class="row">
    <div class="col-md-6">
    <h4>FINA</h4>
    Flacher, etwa 72mm breiter Griff,<br>
    Chrom glänzend<img alt="Spiegelschrank Türgriff Fina" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/Spiegelschrank-Griff3-FINA-web.jpg" style="margin-bottom: 18px;" title="Spiegelschrank Griff Fina" width="560"></div>
    
    <div class="col-md-6">
    <h4>CANTO</h4>
    Kantiger Griff aus Zink, verchromt poliert,<br>
    Breite: 51mm, Höhe: 7mm, Tiefe: 21mm<img alt="Spiegelschrank Griff Canto" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-griff-canto.jpg" style="margin-bottom: 18px;" title="Spiegelschrank Griff Canto" width="560"></div>
    
    <div class="col-md-6">
    <h4>MANGO</h4>
    Kantiger Möbelknopf aus Aluminium mit Griffmulde,<br>
    silberfarben, Höhe: 15mm, Tiefe: 22mm<img alt="Spiegelschrank Griff Mango" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-griff-mango.jpg" style="margin-bottom: 18px;" title="Spiegelschrank Griff Mango" width="560"></div>
    
    <div class="col-md-6">
    <h4>MODA</h4>
    Steggriff aus Zink, verchromt poliert,<br>
    Breite: 52mm, Höhe: 13mm, Tiefe: 25mm<img alt="Spiegelschrank Griff Moda" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-griff-moda.jpg" style="margin-bottom: 18px;" title="Spiegelschrank Griff Moda" width="560"></div>
    
    <div class="col-md-6">
    <h4>PRESA</h4>
    Kantiger Steggriff aus Aluminium, verchromt poliert,<br>
    Breite: 80mm, Höhe: 18mm, Tiefe: 24mm<img alt="Spiegelschrank Griff Presa" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-griff-presa.jpg" style="margin-bottom: 18px;" title="Spiegelschrank Griff Presa" width="560"></div>
    </div>
    
    <p>Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p>
    
    <h3>2. Push-to-Open / Drucköffner</h3>
    
    <p>Beim Push-to-Open System handelt es sich um Drucköffner für grifflose Türen. Durch leichtes Andrücken der Türen öffnen und schließen Sie dieselben.</p>
    
    <h3>3. Glasüberstand unten</h3>
    
    <p>Hierbei greifen Sie mit der Hand hinter die Tür und ziehen sie auf. Die meisten Kunden wählen in diesem Fall einen Glasüberstand von 1,5 bis 2,0 cm. Der Überstand wird vom Korpus aus gemessen.</p>
    
    <p>Falls Sie den <strong>Glasüberstand mit einer Gestensteuerung außen </strong>kombinieren, ist die zum Korpus gewandte Spiegelfläche satiniert; sie ist folglich nur bei geöffneten Schranktüren sichtbar (siehe Bild unten). Bei den Modellen München 1, 4 und 5 sowie Supreme ist der Glasüberstand unabhängig von einer Gestensteuerung immer satiniert.</p>
    
    <p><strong>Wichtiger Hinweis&nbsp;– Spiegelschränke mit offenen Fächern</strong></p>
    
    <p>Ein Glasüberstand ist bei Spiegelschränken mit offenen Fächern nicht immer ratsam. Hat der Schrank <strong>unten Fächer</strong> so ragen die Türen etwas in die Fächer hinein. Hat der Schrank seitlich oder mittig Fächer, so ragen die Türen unten über den Korpus hinaus; von vorn gesehen befindet sich also eine oder mehrere Stufe(n) zwischen den Spiegeltüren und den Regalen seitlich bzw. mittig. <strong>Wir empfehlen, in diesem Fall Türknöpfe oder Push-to-Open zum Öffnen der Türen zu wählen.</strong></p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Spiegelschrank Tür mit Glasüberstand" class="img-fluid lazy" height="500" src="/images/content/optionen/Spiegelschrank-Glasueberstand-unten.jpg" style="margin-bottom:18px" title="Spiegelschrank Tür mit Glasüberstand" width="500"></div>
    
    <div class="col-md-6"><img alt="Spiegelschranktür Glasüberstand innen satiniert" class="img-fluid lazy" height="500" src="/images/content/optionen/spiegeltuer-innen-satiniert.jpg" style="margin-bottom: 18px;" title="Spiegelschrank Tür mit Glasüberstand" width="500"></div>
    </div>
    
    <p>Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 21,
    title: "Informationen zum Glasüberstand (unten)",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Es stehen drei Möglichkeiten zur Auswahl, um die Türen des Spiegelschranks zu öffnen:</p>
    
    <ol>
      <li>Türgriffe</li>
      <li>Push-to-Open / Drucköffner</li>
      <li>Glasüberstand unten</li>
    </ol>
    
    <h3>1. Türgriffe</h3>
    
    <p>Für Ihren Badspiegelschrank stehen folgende Türgriffe zur Auswahl:</p>
    
    <div class="row">
    <div class="col-md-6">
    <h4>FINA</h4>
    Flacher, etwa 72mm breiter Griff,<br>
    Chrom glänzend<img alt="Spiegelschrank Türgriff Fina" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/Spiegelschrank-Griff3-FINA-web.jpg" style="margin-bottom: 18px;" title="Spiegelschrank Griff Fina" width="560"></div>
    
    <div class="col-md-6">
    <h4>CANTO</h4>
    Kantiger Griff aus Zink, verchromt poliert,<br>
    Breite: 51mm, Höhe: 7mm, Tiefe: 21mm<img alt="Spiegelschrank Griff Canto" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-griff-canto.jpg" style="margin-bottom: 18px;" title="Spiegelschrank Griff Canto" width="560"></div>
    
    <div class="col-md-6">
    <h4>MANGO</h4>
    Kantiger Möbelknopf aus Aluminium mit Griffmulde,<br>
    silberfarben, Höhe: 15mm, Tiefe: 22mm<img alt="Spiegelschrank Griff Mango" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-griff-mango.jpg" style="margin-bottom: 18px;" title="Spiegelschrank Griff Mango" width="560"></div>
    
    <div class="col-md-6">
    <h4>MODA</h4>
    Steggriff aus Zink, verchromt poliert,<br>
    Breite: 52mm, Höhe: 13mm, Tiefe: 25mm<img alt="Spiegelschrank Griff Moda" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-griff-moda.jpg" style="margin-bottom: 18px;" title="Spiegelschrank Griff Moda" width="560"></div>
    
    <div class="col-md-6">
    <h4>PRESA</h4>
    Kantiger Steggriff aus Aluminium, verchromt poliert,<br>
    Breite: 80mm, Höhe: 18mm, Tiefe: 24mm<img alt="Spiegelschrank Griff Presa" class="img-fluid lazy" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-griff-presa.jpg" style="margin-bottom: 18px;" title="Spiegelschrank Griff Presa" width="560"></div>
    </div>
    
    <p>Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p>
    
    <h3>2. Push-to-Open / Drucköffner</h3>
    
    <p>Beim Push-to-Open System handelt es sich um Drucköffner für grifflose Türen. Durch leichtes Andrücken der Türen öffnen und schließen Sie dieselben.</p>
    
    <h3>3. Glasüberstand unten</h3>
    
    <p>Hierbei greifen Sie mit der Hand hinter die Tür und ziehen sie auf. Die meisten Kunden wählen in diesem Fall einen Glasüberstand von 1,5 bis 2,0 cm. Der Überstand wird vom Korpus aus gemessen.</p>
    
    <p>Falls Sie den <strong>Glasüberstand mit einer Gestensteuerung außen </strong>kombinieren, ist die zum Korpus gewandte Spiegelfläche satiniert; sie ist folglich nur bei geöffneten Schranktüren sichtbar (siehe Bild unten). Bei den Modellen München 1, 4 und 5 sowie Supreme ist der Glasüberstand unabhängig von einer Gestensteuerung immer satiniert.</p>
    
    <p><strong>Wichtiger Hinweis&nbsp;– Spiegelschränke mit offenen Fächern</strong></p>
    
    <p>Ein Glasüberstand ist bei Spiegelschränken mit offenen Fächern nicht immer ratsam. Hat der Schrank <strong>unten Fächer</strong> so ragen die Türen etwas in die Fächer hinein. Hat der Schrank seitlich oder mittig Fächer, so ragen die Türen unten über den Korpus hinaus; von vorn gesehen befindet sich also eine oder mehrere Stufe(n) zwischen den Spiegeltüren und den Regalen seitlich bzw. mittig. <strong>Wir empfehlen, in diesem Fall Türknöpfe oder Push-to-Open zum Öffnen der Türen zu wählen.</strong></p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Spiegelschrank Tür mit Glasüberstand" class="img-fluid lazy" height="500" src="/images/content/optionen/Spiegelschrank-Glasueberstand-unten.jpg" style="margin-bottom:18px" title="Spiegelschrank Tür mit Glasüberstand" width="500"></div>
    
    <div class="col-md-6"><img alt="Spiegelschranktür Glasüberstand innen satiniert" class="img-fluid lazy" height="500" src="/images/content/optionen/spiegeltuer-innen-satiniert.jpg" style="margin-bottom: 18px;" title="Spiegelschrank Tür mit Glasüberstand" width="500"></div>
    </div>
    
      <p>Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 22,
    title: "Informationen zu Glasböden",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Je nach Höhe des Schranks können Sie zwischen 1 und 6 Glasböden wählen. <strong>Wenn Sie keine Glasböden auswählen, wird der Schrank ohne Böden geliefert.</strong></p>
    
    <h4>Konfigurationsbeispiele</h4>
    
    <div class="row">
    <div class="col-md-6">Hier wurden "4x Glasböden" ausgewählt. Geliefert werden 8 Böden, also jeweils 4 pro Kompartiment.<img alt="Spiegelschrank Glasböden" class="img-fluid" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-glasboeden-1.png" title="Glasböden im Schrank" width="560"></div>
    
    <div class="col-md-6">Hier wurden "3x Glasböden" ausgewählt. Geliefert werden 9&nbsp;Böden, also jeweils 3&nbsp;pro Kompartiment.<img alt="Spiegelschrank Glasböden" class="img-fluid" height="560" src="/images/content/optionen/Spiegelschrank/spiegelschrank-glasboeden-2.png" title="Glasböden im Schrank" width="560"></div>
    </div>
    
    <p>Die Glasböden sind <strong>in der Höhe verstellbar</strong>. Ausreichend Bohrungen für die Halterungen sind im Schrank vorhanden. Glasböden sind in verschiedenen Ausführungen erhältlich:</p>
    
    <div class="row">
    <div class="col-md-6">
    <h4>Floatglas: Klarglas mit charakteristischem Grünstich</h4>
    <img alt="Spiegelschrank Glasböden Floatglas" class="img-fluid" height="560" src="/images/content/optionen/glasboden-floatglas.jpg" title="Spiegelschrank Glasböden Floatglas" width="560"></div>
    
    <div class="col-md-6">
    <h4>Weißglas ohne die typische leicht grünliche Farbe</h4>
    <img alt="Spiegelschrank Glasböden Weißglas" class="img-fluid" height="560" src="/images/content/optionen/glasboden-weissglas.jpg" title="Spiegelschrank Glasböden Weißglas" width="560"></div>
    
    <div class="col-md-6">
    <h4>Weißes Lacobel-Glas (weiß lackiert)</h4>
    <img alt="Spiegelschrank Glasböden weißes Lacobel-Glas (weiß lackiert)" class="img-fluid" height="560" src="/images/content/optionen/glasboden-weiss-lackiert.jpg" title="Spiegelschrank Glasböden weißes Lacobel-Glas (weiß lackiert)" width="560"></div>
    
    <div class="col-md-6">
    <h4>Schwarzes Lacobel-Glas (schwarz lackiert)</h4>
    <img alt="Spiegelschrank Glasböden schwarzes Lacobel-Glas (schwarz lackiert)" class="img-fluid" height="560" src="/images/content/optionen/glasboden-schwarz-lackiert.jpg" title="Spiegelschrank Glasböden schwarzes Lacobel-Glas (schwarz lackiert)" width="560"></div>
    </div>
    
    <p><br>
    <strong>Wichtiger Hinweis für Lacobel-Glas:</strong> Bitte nichts auf die lackierte Fläche stellen. Bitte die Böden daher so einlegen, dass die lackierte Fläche unten ist.</p>
    
    <p>Falls der gewünschte Schrank über <strong>offene Fächer an den Seiten oder mittig </strong>verfügt, dann sind Glasböden dafür ebenfalls im Lieferumfang enthalten. Auch bei diesen können Sie die Höhen wählen.</p>
    
    <p><strong>Wichtiger Hinweis für Aluminium Spiegelschränke</strong>, bei denen links und rechts innen im Schrank Leuchtprofile verbaut sind: Hier befindet sich konstruktionsbedingt ein 8 mm breiter Spalt zwischen Glasböden und Rückwand. Bitte achten Sie auf einen entsprechenden Hinweis in der Artikelbeschreibung.</p>
    
    <p>Abbildung exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 23,
    title: "Informationen zu den Holzböden",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p><strong>Bitte beachten Sie:</strong> Bei Spiegelschränken mit lichtdurchlässiger Satinierung sind Holzböden durch die Satinierung hindurch sichtbar.</p>
    
    <p>Ausgenommen hiervon sind</p>
    
    <ul>
      <li>die <a href="https://www.spiegel21.de/Moebel/Spiegelschraenke:::48_115.html?filter%5B165%5D=1329">Modelle mit Leuchtrahmen</a> München, Hamburg, Frankfurt und Elbe</li>
      <li><a href="https://www.spiegel21.de/Moebel/Spiegelschraenke:::48_115.html?filter%5B141%5D=997">Spiegelschränke mit Beleuchtung oben und/oder unten</a>, sofern die Böden nicht auf Höhe der Satinierungen angebracht sind</li>
      <li>alle <a href="https://www.spiegel21.de/Moebel/Spiegelschraenke-fuer-Dachschraegen:::48_119.html">Spiegelschränke für Dachschrägen</a> (Bauweise wie München).</li>
    </ul></div>`,
  },
  {
    id: 24,
    title: "Informationen zur Steckdose",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Steckdosen/USB bieten wir sowohl für Spiegelschränke als auch für Bad-Hochschränke und Garderobenschränke an.</p>
    
    <p>In <strong>Bad-Spiegelschränke</strong>&nbsp;können <strong>bis zu sechs Steckdosen&nbsp;</strong>für Rasierer, Smartphone, elektrische Zahnbürste, Haartrockner etc. integriert werden. Diese werden in der Regel über den gleichen Anschluss mit Strom versorgt wie die LED Beleuchtung. Sie müssen also nicht die Wand aufstemmen und bohren, um neue Steckdosen zu installieren.</p>
    
    <p>Falls Sie Beleuchtung und Steckdose separat mit Strom versorgen wollen, z. B. weil Sie bereits einen Lichtschalter in der Wand bzw. Raumschalter installiert haben, teilen Sie uns das bitte bei Ihrer Bestellung im Kommentarfeld am Ende des Bestellprozesses mit.</p>
    
    <h4>Falls Sie die Steckdosen und/oder Lichtschalter bzw. Dimmer an einer bestimmten Position wünschen, teilen Sie uns dies bitte im Feld "Ihre Bemerkung" im Konfigurator mit.</h4>
    
    <p>Sofern Sie <strong>Steckdosen innen</strong>&nbsp;wünschen und zusätzlich auch&nbsp;<strong>eine Gestensteuerung</strong>&nbsp;verbaut werden soll, so könnten Sie die Gestensteuerung beim Ein- oder Ausstecken&nbsp;eines Gerätes unbeabsichtigt&nbsp;auslösen. Um dies zu verhindern, sollten Sie Gestensteuerung und Steckdosen auf je unterschiedlichen Seiten positionieren, z.B. Gestensteuerung links, Steckdose(n) rechts.</p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Badezimmer Spiegelschrank mit Steckdose" class="img-fluid lazy" height="560" src="/images/content/optionen/spiegelschrank-steckdose-schalter-de.jpg" style="margin-bottom:18px" title="Badezimmer Spiegelschrank mit Steckdose " width="560"></div>
    
    <div class="col-md-6"><img alt="USB Steckdose" class="img-fluid lazy" height="560" src="/images/content/optionen/spiegelschrank-steckdose-de.jpg" style="margin-bottom:18px" width="560"></div>
    
    <div class="col-md-6"><img alt="Schalter, Steckdose, USB kombiniert" class="img-fluid lazy" height="560" src="/images/content/optionen/spiegelschrank-schalter-steckdose-usb-de.jpg" style="margin-bottom: 18px;" width="560"></div>
    </div>
    
    
    <p>Bei <strong>Kunden aus der Schweiz</strong> werden ausschließlich CH-Steckdosen verbaut.</p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Badezimmer Spiegelschrank mit Steckdose" class="img-fluid lazy" height="560" src="/images/content/optionen/spiegelschrank-steckdose-schalter-ch.jpg" style="margin-bottom:18px" title="Badezimmer Spiegelschrank mit Steckdose " width="560"></div>
    
    <div class="col-md-6"><img alt="USB Steckdose" class="img-fluid lazy" height="560" src="/images/content/optionen/spiegelschrank-steckdose-ch.jpg" style="margin-bottom:18px" width="560"></div>
    
    <div class="col-md-6"><img alt="Schalter, Steckdose, USB kombiniert" class="img-fluid lazy" height="560" src="/images/content/optionen/spiegelschrank-schalter-steckdose-usb-ch.jpg" style="margin-bottom: 18px;" width="560"></div>
    </div>
    
    <p>Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 25,
    title: "Informationen zur Waschtisch-/Deckenbeleuchtung",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Die Waschtisch- und/oder Deckenbeleuchtung können Sie <strong>zusätzlich</strong> zur Haupt-Beleuchtung hinzuwählen.</p>
    
    <p><strong>Hinweis für Spiegelschränke für Dachschrägen:</strong> Auf der schrägen Seite des Korpus wird keine Beleuchtung verbaut.</p>
    
    <h3>Spiegel-/Schränke mit Holz-Korpus</h3>
    
    <p>Bei Schränken mit Holz-Korpus sind die LED-Bänder außen im Korpus eingelassen; sie befinden sich in einer mit Aluminium ausgekleideten Nut und sind mit lichtdurchlässigen Kunststoffprofilen abgedeckt.</p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Spiegelschrank mit zusätzlicher Deckenbeleuchtung" class="img-fluid lazy" height="560" src="/images/content/optionen/deckenbeleuchtung.jpg" style="margin-bottom: 18px;" width="560"></div>
    
    <div class="col-md-6"><img alt="Spiegelschrank mit zusätzlicher Waschbeckenbeleuchtung" class="img-fluid lazy" height="560" src="/images/content/optionen/waschbeckenbeleuchtung_schrank.jpg" style="margin-bottom: 18px;" width="560"></div>
    </div>
    
    <p>Die <strong>Spiegelschränke München, Hamburg und Frankfurt, die</strong>&nbsp;<strong>Spiegelschränke für Dachschrägen sowie die Bad-Hochschränke BHS007L4, BHS008L4 BHS009L4, BHS012L4, BHS013L4 und BHS015L4&nbsp;</strong>verfügen als Haupt- bzw. Standard-Beleuchtung über eine Innen- und Außenbeleuchtung. Die Außenbeleuchtung scheint jedoch eher nach vorn als in Richtung Waschtisch oder Decke (siehe Bilder unten).</p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Spiegelschrank München mit zusätzlicher Deckenbeleuchtung" class="img-fluid lazy" height="560" src="/images/content/optionen/deckenbeleuchtung_muenchen.jpg" style="margin-bottom: 18px;" width="560"></div>
    
    <div class="col-md-6"><img alt="Spiegelschrank München mit zusätzlicher Waschbeckenbeleuchtung" class="img-fluid lazy" height="560" src="/images/content/optionen/waschbeckenbeleuchtung_muenchen.jpg" style="margin-bottom: 18px;" width="560"></div>
    </div>
    
    <h3>Spiegelschränke aus Aluminium</h3>
    
    <p>Bei Alu-Spiegelschränken verlaufen die LED-Bänder in einem Profil, das außen auf dem Korpus befestigt wird.</p>
    
    <div class="row">
    <div class="col-md-6"><img alt="Alu-Spiegelschrank mit zusätzlicher Deckenbeleuchtung" class="img-fluid lazy" height="560" src="/images/content/optionen/deckenbeleuchtung_alu.jpg" style="margin-bottom: 18px;" width="560"></div>
    
    <div class="col-md-6"><img alt="Alu-Spiegelschrank mit zusätzlicher Waschbeckenbeleuchtung" class="img-fluid lazy" height="560" src="/images/content/optionen/waschbeckenbeleuchtung_alu.jpg" style="margin-bottom: 18px;" width="560"></div>
    
    <div class="col-md-6"><img alt="Spiegelschrank München mit zusätzlicher Deckenbeleuchtung" class="img-fluid lazy" height="560" src="/images/content/optionen/deckenbeleuchtung_muenchen_alu.jpg" style="margin-bottom: 18px;" width="560"></div>
    
    <div class="col-md-6"><img alt="Spiegelschrank München mit zusätzlicher Waschbeckenbeleuchtung" class="img-fluid lazy" height="560" src="/images/content/optionen/waschbeckenbeleuchtung_muenchen_alu.jpg" style="margin-bottom: 18px;" width="560"></div>
    </div>
    
    <div class="row">
    <div class="col-md-6"><img alt="Ambiente-Beleuchtung oben" class="img-fluid lazy" height="560" src="/images/content/optionen/ambiente-beleuchtung-oben.jpg" style="margin-bottom: 18px;" width="560"></div>
    
    <div class="col-md-6">&nbsp;</div>
    </div>
    
    <h3>Einbau-/Unterputz-Schränke – wichtiger Hinweis</h3>
    
    <p>Bitte beachten Sie, dass eine Gestensteuerung und/oder Waschtisch-Beleuchtung bei Einbau- bzw. Unterputz Spiegelschränken nur dann möglich ist, wenn der Korpus <strong><span style="color:#FF0000;">mindestens 35 mm</span>&nbsp;</strong>(Spiegelschränke 'Frankfurt', 'Hamburg' und 'München' sowie alle Dachschrägen-Schränke <span style="color:#FF0000;"><strong>mindestens 45 mm</strong></span>) aus der Wand herausragt.</p>
    
    <h3>Nutzung von Waschtisch-/Deckenbeleuchtung als Ambiente-Licht</h3>
    
    <p>Falls Sie <strong>nur die Waschtisch-/Deckenbeleuchtung </strong>einschalten und als Ambiente-Licht verwenden wollen, benötigt Ihr Schrank in jedem Fall zwei Stromkreise oder <strong>zwei Schalter</strong>. Wählen Sie in dem Fall</p>
    
    <ol>
      <li><strong>für die Haupt-Beleuchtung</strong>
    
      <ol type="a">
        <li>separater Stromkreis mit vorhandenem Wandschalter</li>
        <li>den Schalter inkl. Steckdose im Schrank (siehe 'Schalter/Sensor') oder</li>
        <li>die Gestensteuerung (siehe 'Schalter/Sensor') oder</li>
        <li>dimmbares Licht inkl. Gestensteuerung (siehe 'Lichtfarbe') oder</li>
        <li>RGB inkl. Fernbedienung (siehe 'Lichtfarbe') <strong><u>und</u></strong><br>
        &nbsp;</li>
      </ol>
      </li>
      <li><strong>für die Waschtisch-/Deckenbeleuchtung</strong>
      <ol type="a">
        <li>einen separaten Kippschalter oder Gestensteuerung sowie</li>
        <li>die gewünschte Lichtfarbe (Neutral-/Warm-/Kaltweiß).</li>
      </ol>
      </li>
    </ol>
    
    <p>Kippschalter oder Gestensteuerung befindeen&nbsp;sich außen <u>unter</u> dem Schrank. Die Waschtisch-/Deckenbeleuchtung ist in dieser Konfiguration <u>nicht</u> dimmbar, sondern kann nur ein- und ausgeschaltet werden. Eine dimmbare Waschtisch-/Deckenbeleuchtung ist nur möglich, wenn Sie mit der Lichtfarbe 'Dimmbar 2700K bis 6300K...' gekoppelt ist, also ohne separaten Kippschalter.</p>
    
    <p>Bei der <strong>Kombination von Gestensteuerung außen mittig und Waschtisch-Beleuchtung</strong> wird&nbsp;die Beleuchtung mittig konstruktionsbedingt unterbrochen.</p>
    
    <p>Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 26,
    title: "Informationen zum Schminkspiegel",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Bei Spiegelschränken fürs Bad bieten wir ausschließlich <u>un</u>beleuchtete Schminkspiegel mit 5-facher Vergrößerung an. Der Kosmetikspiegel hat einen<strong> Durchmesser von 15,2&nbsp;cm</strong>. Die Einfassung ist silberfarben.</p>
    
    <p>Er&nbsp;wird<strong>&nbsp;lose</strong> mitgeliefert. Die Schminkspiegel verfügen über ein Klebepad auf der Rückseite, sodass Sie ihn in der gewünschten Position ganz einfach selbst anbringen können.&nbsp;</p>
    
    <p><strong>Bitte beachten Sie</strong>, dass der Kosmetikspiegel nicht in die Tür integriert ist, sondern einige wenige Millimeter hervorsteht.</p></div>`,
  },
  {
    id: 27,
    title: "Informationen zum Bluetooth Lautsprecher Spiegelschrank",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Mit Bluetooth-Receiver und -Lautsprecher erhalten Sie die Möglichkeit, die Tonausgabe von Ihrem Bluetooth-kompatiblen Gerät per Funk auf den Spiegelschrank zu übertragen. Spielen Sie Ihre Musik, Ihren Radiosender oder Ihren Musik-Streaming-Dienst von Ihrem Smartphone (Handy, iPhone), iPod, iPad, Tablet, Netbook oder einem MP3-Player&nbsp;– um nur einige zu nennen.</p>
    
    <h3>Standard-Lautsprecher</h3>
    
    <p>Standard Lautsprecher werden oben in der Spiegelschrank-Rückwand verbaut.&nbsp;Wenn Sie die den Schrank öffnen, sind sie sichtbar. Die genaue Position ist variabel und hängt von Ihrer Konfiguration ab.</p>
    
    <h3>WHD Sound-System</h3>
    
    <p>Beim WHD Sound-System handelt es sich nicht um Standard-Lautsprecher, sondern um <strong>Körperschallwandler</strong>, die den gesamten Spiegelschrank in einen Lautsprecher mit <strong>erstaunlicher Klangqualität</strong> verwandeln. Sie werden unsichtbar hinter der Schrank-Rückwand verbaut und erlauben kabelloses Streamen per Bluetooth vom Smartphone, Computer, Laptop oder Tablet.</p>
    
    <p><strong>Bitte beachten Sie</strong></p>
    
    <ul>
      <li>Bluetooth-Lautsprecher sind nicht kompatibel mit Smart Home Assistenten, wie z.B. Alexa, Google Home.<br>
      &nbsp;</li>
      <li>Wird die Stromzufuhr des Spiegelschranks (für Beleuchtung, Bluetooth etc.) über einen Raumschalter geregelt, dann lassen sich die Lautsprecher nur nutzen, wenn zeitgleich das Licht eingeschaltet ist.<br>
      &nbsp;</li>
      <li>Sollen Beleuchtung und Bluetooth-Receiver/-Lautsprecher unabhängig voneinander funktionieren, sorgen Sie bitte für Dauerstrom und schalten Sie die Beleuchtung des Schranks über einen optional erhältlichen Schalter oder Sensor.</li>
    </ul></div>`,
  },
  {
    id: 28,
    title: "Informationen zu den LED Spiegelleuchten",
    html: `<div class=""><meta name="robots" content="noindex">
  <h1>Info Spiegelleuchten | Spiegelschrank-Leuchten</h1>
  <p>Standard ist, dass die Spiegel- bzw. Spiegelschrank-Leuchten direkt an den Strom angeschlossen und über einen vorhandenen Wandschalter ein- und ausgeschaltet werden.</p>
  
  <p>Bestellen Sie die Leuchte zusammen mit dem Spiegel oder Spiegelschrank, so kann auf Wunsch aber auch ein Touch- oder Infrarot-Sensor (bei Spiegeln) oder ein Schalter am Spiegel bzw. im Spiegelschrank für das Ein- und Ausschalten der Beleuchtung eingebaut werden. Der Spiegel bzw. Spiegelschrank wird mit&nbsp;anschlussfertiger Elektrik geliefert.</p>
  
  <p><strong>Bitte beachten Sie</strong>, dass einige Spiegel-Leuchten an der Wand befestigt werden, es sich also nicht um Aufsatzleuchten handelt.</p>
  
  <p><strong>Alle technischen Details, wie z.B. die Lichtfarbe, finden Sie als Tabelle in der Artikelbeschreibung des jeweiligen Spiegelschranks.</strong></p>
  
  <p><a href="https://www.spiegel21.de/Leuchten:::112.html" title="Spiegelleuchte, Spiegelschrank-Leuchte"><span class="btn btn-primary custom-btn" style="margin:5px">Alle Spiegel- / Spiegelschrank-Leuchten im Überblick</span></a></p></div>`,
  },
  {
    id: 29,
    title: "Informationen zur Anzahl der Fächer",
    html: `<div class=""><meta name="robots" content="noindex">
    <h1></h1>
    <p>Fächer unten – lichte Höhe: 15 cm<br>
    Fächer links / rechts / mittig – lichte Breite: 15 cm</p>
    
    <p>Kalkulieren Sie pro Fach links, rechts oder mittig bitte eine Breite von <strong>mindestens 17 cm</strong> (15 cm lichtes Maß plus 2 cm für die Seitenwand), <strong>Falls Sie breitere, schmalere oder höhere Fächer wünschen</strong>, teilen Sie uns dies bitte im Kommentarfeld am Ende des Bestellprozeses mit. Wir empfehlen, die Fächer nicht schmaler als 15 cm zu wählen.&nbsp;Ebenso sollte für <strong>Regale oben bzw. unten</strong> eine Höhe von mindestens 17 cm eingeplant werden.</p>
    
    <p><strong>Fächer unten</strong> verfügen über vertikale Stege aus Holz im gewählten Dekor. Sie können außerdem die Unterteilung bzw. Anzahl der Fächer wählen. Diese bezieht sich jedoch nicht auf die seitlichen Fächer (falls vorhanden). Bei Spiegelschränken mit offenen Regalen unten und <strong>an den Seiten</strong>, sind die langen, schmalen Regale links und rechts stets bis unten durchgezogen. Sie erstrecken sich also über die ganze Höhe.</p>
    
    <p><strong>Fächer an den Seiten und in der Mitte</strong>&nbsp;sind mit Glasböden ausgestattet; die Anzahl ist gleich der Böden im Schrank.</p>
    
    <p class="font-weight-bold">Die <strong>Rückwand der Fächer</strong> besteht&nbsp;– ebenso wie die Rückwand im Schrank&nbsp;– aus <strong>weißer</strong>, 8 mm starker, kunststoffbeschichteter Hartfaser.</p></div>`,
  },
  {
    id: 30,
    title: "Informationen zu den Seitenblenden/Vollverblendung",
    html: `<div class=""><meta name="robots" content="noindex">
  <h1></h1>
  <p>Die Blenden sind 30 mm tief. Die Gesamttiefe beleuchteter Spiegel beträgt somit 36 mm. Bei Kristallspiegeln ohne Beleuchtung sind zur Stärke des Spiegels (3 mm, 5 mm oder 6 mm) diese 30 mm hinzu zu rechnen.</p>
  
  <h3>Aluminium</h3>
  
  <p>Blenden aus Aluminium sind in nachstehenden Ausführungen erhältlich.&nbsp;<strong>Bitte beachten Sie bei beleuchteten Spiegeln</strong>, dass diese Blenden&nbsp;kein Licht durchlassen&nbsp;sind und&nbsp;die Wand somit nicht angestrahlt wird.</p>
  
  <ul>
    <li>Alu eloxiert mit matter Oberfläche (Bild 1)</li>
    <li>Alu Edelstahloptik gebürstet (Bild 2)</li>
    <li>Alu Chromoptik glänzend poliert (Bild 3)</li>
    <li>Alu schwarz pulverbeschichtet (Bild 5)&nbsp;– <strong>Wichtiger Hinweis für Spiegel mit Leucht-Profilen</strong>&nbsp;(Bayreuth, Düsseldorf Hannover, Köln, Marlene, Rheine): Es sind nur die optionalen Profile hinter dem Spiegel schwarz, nicht jedoch die Leucht-Profile vorn auf dem Spiegel.</li>
  </ul>
  
  <p>Diese Option wählen auch viele Kunden, die den LED Spiegel als Ganzkörperspiegel bzw. Standspiegel nutzen und ihn z. B. einfach schräg an die Wand lehnen. Wir empfehlen in dem Fall aber einen <a href="https://www.spiegel21.de/Spiegel/mit-Rahmen:::1_85.html" title="Spiegel mit Rahmen">Spiegel mit Rahmen</a>.</p>
  
  <h3>Lichtdurchlässiger Kunststoff</h3>
  
  <p>Im Gegensatz zu Aluminium-Blenden beeinträchtigen lichtdurchlässige Blenden aus Kunststoff (Bild 4) die indirekte Beleuchtung der hinter dem Spiegel liegenden Wand nicht. Diese dienen lediglich dazu, die LED-Bänder zu verdecken. Sie sind aus ästhetischen Gründen sinnvoll, wenn der beleuchtete Spiegel so hängt, dass man von der Seite leicht dahinter schauen kann.</p>
  
  <h3>Vollverblendung</h3>
  
  <p>Bei der Vollverblendung werden Blenden nicht nur an den Seiten (rechts und links), sondern auch oben und unten angebracht. Bei <strong>Spiegeln mit einer (Aufsatz-)Leuchte oben</strong> ist die Blende oben, wo die Leuchte sitzt, unterbrochen. Gleiches gilt, wenn die <strong>Satinierung bis zum Rand </strong>des Spiegels verläuft.</p>
  
  <h3>Sonderwünsche</h3>
  
  <p>Wenn Sie <strong>Sonderwünsche</strong> haben, also z. B. Alu-Blenden an den Seiten, aber lichtdurchlässige Kunststoff-Blenden oben und unten, dann vermerken Sie dies bitte im Kommentarfeld am Ende des Bestellprozesses.</p>
  
  <h3>Hinweis zu runden und besonders geformten Spiegeln</h3>
  
  <p>Bei<strong> Spiegeln mit runden Ecken </strong>erstrecken die Blenden&nbsp;sich aufgrund der Rundungen nicht über die gesamte Höhe des Spiegels.</p>
  
  <p>Bei<strong> runden</strong> <strong>und</strong> einigen <strong>besonders geformten Spiegeln</strong> ist eine Vollverblendung in Kunststoff lichtdurchlässig möglich. Die Blenden werden in das&nbsp;Profil der LED-Beleuchtung eingeklipst. Sie verlaufen also&nbsp;<u>nicht</u> entlang der Spiegelkanten, sondern sind je nach Modell 4 bis 8 cm nach innen eingerückt.</p>
  
  <div class="row">
  <div class="col-md-6"><img alt="" class="img-fluid lazy" height="500" src="/images/content/optionen/Seitenblende_01.jpg" style="margin-bottom:18px" width="500"></div>
  
  <div class="col-md-6"><img alt="" class="img-fluid lazy" height="500" src="/images/content/optionen/Seitenblende_02.jpg?v=210504" style="margin-bottom:18px" width="500"></div>
  
  <div class="col-md-6"><img alt="" class="img-fluid lazy" height="500" src="/images/content/optionen/seitenblende-schwarz.jpg?v=210504" style="margin-bottom:18px" width="500"></div>
  </div>
  
  <p>Abbildungen exemplarisch. Bitte beachten Sie, dass die Bilder nur als Orientierungshilfe gedacht sind. Position und Farben können von der Darstellung abweichen und hängen von der individuellen Konfiguration ab.</p></div>`,
  },
  {
    id: 31,
    title: "Informationen zu den Bohrungen",
    html: `<div class=""><meta name="robots" content="noindex">
  <h1></h1>
  <p>Wählen Sie zunächst die Anzahl der Bohrungen aus. Sodann können Sie den Durchmesser wählen. <strong>Bei der Position wird jeweils vom Rand bis zur Mitte der Bohrung gemessen. </strong>Je nach Art und Stärke der Glas- bzw. Steinplatte&nbsp;gelten unterschiedliche Mindestabstände vom Rand.</p>
  
  <p>Werden die Bohrungen für <strong>Schraubbefestigungen</strong> genutzt, so sollte der&nbsp;Bohrungsdurchmesser mindestens 4mm größer sein als der Durchmesser der Schraube. Ein Kontakt zwischen Glas/Stein und Schraube ist durch geeignete Hülsen zu verhindern.</p>
  
  <p><strong>Bitte beachten Sie, dass sich die Lieferzeit im Falle von Bohrungen&nbsp;um 4&nbsp;Tage verlängert.</strong></p></div>`,
  },
  {
    id:32,
    title:"Informationen zum Einbaurahmen Vorab",
    html:`<div class=""><meta name="robots" content="noindex">
    <h1>Info Einbaurahmen</h1>
    <p>Tipp: Falls der Einbaurahmen nicht eingemauert oder mit Fliesen abgedeckt wird, ist dieser sichtbar. Wir empfehlen Ihnen, in diesen Fällen, oben und unten einen Glasüberstand&nbsp;zu wählen, sodass der Rahmen bei geschlossenen Türen von diesen verdeckt ist.</p>
    
    <p>Falls Sie den Einbaurahmen vorab benötigen, können wir Ihnen diesen&nbsp;– unabhängig vom Schrank – binnen 2 bis 3 Wochen liefern.</p></div>`
  }
];

export { data };
