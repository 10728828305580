import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useFrame } from "@react-three/fiber";

const WoodenDoor = () => {
  const { frontBodyDecor } = useSelector((state) => state.cabinet);
  const { nodes, materials } = useGLTF("/newModels/oxford.glb");

  const cabinetFrontBody = useRef();
  const cabinetFrontBody2 = useRef();
  const cabinetFrontBody3 = useRef();

  const bodyTexture = useTexture(`./decors/${frontBodyDecor == 88 ? "U763_ST9.jpeg" : frontBodyDecor}`);
  bodyTexture.rotation = Math.PI * 0.5;
  bodyTexture.repeat.set(0.4, 5);
  bodyTexture.wrapS = THREE.RepeatWrapping;
  bodyTexture.wrapT = THREE.RepeatWrapping;

  useFrame(() => {
    if (cabinetFrontBody.current && cabinetFrontBody2.current && cabinetFrontBody3.current) {
      cabinetFrontBody.current.material.map = bodyTexture;
      cabinetFrontBody2.current.material.map = bodyTexture;
      cabinetFrontBody3.current.material.map = bodyTexture;
    }
  });
  return (
    <group scale-y={0.99}>
      <mesh ref={cabinetFrontBody3} geometry={nodes.rightDoor_1.geometry} material={materials.white} />
      <mesh ref={cabinetFrontBody2} geometry={nodes.rightDoor_2.geometry} material={materials.white} />
      <mesh ref={cabinetFrontBody} geometry={nodes.rightDoor_3.geometry} material={materials.white} />
      {/* <mesh geometry={nodes.rightDoor_4.geometry} material={materials.aluminum} /> */}
    </group>
  );
};
export default WoodenDoor;
