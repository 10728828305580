import { useGLTF, useTexture, Effects } from "@react-three/drei";
import React from "react";
import { useSelector } from "react-redux";

const LeftHinge = () => {
  const { nodes, materials } = useGLTF("/Frankfurt-test2.glb");
  const { cabinetWidth, cabinetDepth } = useSelector((state) => state.cabinet);

  return (
    <mesh
      name="hingeBodyLeft"
      castShadow
      receiveShadow
      geometry={nodes.hingeBodyLeft.geometry}
      material={materials.aluminum}
      position={[0.9 - cabinetWidth * 0.000007, 1.47, 0.06 + cabinetDepth * 0.000025]}
      scale={[cabinetWidth == 400 ? 1 : 1 - cabinetWidth * 0.00008, 1, cabinetDepth == 400 ? 1 : 1 - cabinetDepth * 0.0003]}
    />
  );
};

export default LeftHinge;
