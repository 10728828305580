import React, { useEffect, useState } from "react";
import { Stack, Typography, TextField } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setCenterCompartmentWidth } from "../../../../store/cabinetMirror";

const CompartmentCenter = () => {
  const dispatch = useDispatch();

  const { selectedMirror } = useSelector((state) => state.cabinetMirror);

  const [centerValue, setCenterValue] = useState(150);
  const [alert, setAlert] = useState(false);

  const minValue = 150;
  const maxValue = 300;

  const handleChange = (event) => {
    const value = parseInt(event.target.value);
    setCenterValue(value);
    if (!value) {
      dispatch(setCenterCompartmentWidth(minValue));
    } else {
      if (value < minValue || value > maxValue) {
        dispatch(setCenterCompartmentWidth(value < minValue ? minValue : maxValue));
        setAlert(true);
      } else {
        dispatch(setCenterCompartmentWidth(value));
        setAlert(false);
      }
    }
  };

  useEffect(() => {
    dispatch(setCenterCompartmentWidth(centerValue));
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Stack>
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Breite des Mittelfachs
        </Typography>
      </Stack>

      <input type="hidden" name="id[252]" value="2882" />
      <input type="hidden" class="form-control" name="txt_2882" value={centerValue} />

      <TextField
        type="number"
        onChange={handleChange}
        value={centerValue}
        sx={{
          height: "2.2rem",
          width: "100%",
          ".MuiInputBase-input": {
            padding: "8px 8px 8px 18px",
            fontSize: 12,
          },
        }}
      />
    </Stack>
  );
};

export default CompartmentCenter;
