import React, { useEffect } from "react";
import { useGLTF, Html } from "@react-three/drei";
import { useDispatch } from "react-redux";

import { changeHeightMan } from "../../store/utils";

export function Man2(props) {
  const { nodes, materials } = useGLTF("/man2.glb");

  const dispatch = useDispatch();

  useEffect(() => {
    materials.Standard_2.metalness = -5;
  });
  return (
    <group {...props} dispose={null}>
      <mesh receiveShadow castShadow geometry={nodes.man2.geometry} material={materials.Standard_2} position={[0.02, 0.1, 0.01]} rotation={[Math.PI / 2, 0, 0]} />
      <Html
        style={{
          transform: "translate(30px,-50px)",
          width: "100%",
        }}
        occlude
      >
        <div
          onClick={(e) => {
            document.getElementById("pleksiPanel-boy").classList.toggle("hidden");
          }}
        >
          <div
            className="w-8 h-8 sm:w-10 sm:h-10"
            style={{
              backgroundColor: "white",
              borderRadius: "100%",
              borderColor: "#7c7d81",
              borderWidth: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <span
              style={{
                fontSize: "1.1em",
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
              </svg>
            </span>
          </div>
        </div>
        <div
          id="pleksiPanel-boy"
          style={{
            transform: "scale(0.8)",
          }}
          className="relative mt-2 flex items-center gap-2 bg-white h-9 right-[100px] px-2 py-1 w-[375px] rounded-xl hidden text-sm sm:gap-3 sm:text-base sm:px-4 sm:py-4 sm:h-12 sm:w-[500px] sm:right-[200px]"
        >
          <div
            onClick={() => {
              dispatch(changeHeightMan(1));
            }}
            style={{
              width: "100px",
              textAlign: "center",
              color: props.manHeight === 1 ? "#0d646f" : "#484444",
              backgroundColor: props.manHeight === 1 ? "#fff" : "#edf0f0",
              borderWidth: props.manHeight === 1 ? 2 : 0,
              fontWeight: "bold",
            }}
            id="Alt_+_Üst"
            className="cursor-pointer h-5 rounded-md sm:rounded-lg sm:h-7 flex items-center justify-center"
          >
            1.60 cm
          </div>
          <div
            id="Sağ_+_Sol"
            style={{
              width: "100px",
              textAlign: "center",
              color: props.manHeight === 2 ? "#0d646f" : "#484444",
              backgroundColor: props.manHeight === 2 ? "#fff" : "#edf0f0",
              borderWidth: props.manHeight === 2 ? 2 : 0,
              fontWeight: "bold",
            }}
            className="cursor-pointer h-5 rounded-md sm:rounded-lg sm:h-7 flex items-center justify-center"
            onClick={() => {
              dispatch(changeHeightMan(2));
            }}
          >
            1.70 cm
          </div>
          <div
            id="Sağ_+_Üst_+_Sol"
            style={{
              width: "100px",
              textAlign: "center",
              color: props.manHeight === 3 ? "#0d646f" : "#484444",
              backgroundColor: props.manHeight === 3 ? "#fff" : "#edf0f0",
              borderWidth: props.manHeight === 3 ? 2 : 0,
              fontWeight: "bold",
            }}
            className="cursor-pointer h-5 rounded-md sm:rounded-lg sm:h-7 flex items-center justify-center"
            onClick={() => {
              dispatch(changeHeightMan(3));
            }}
          >
            1.80 cm
          </div>
          <div
            id="Sağ_+_Üst_+_Sol"
            style={{
              width: "100px",
              textAlign: "center",
              color: props.manHeight === 4 ? "#0d646f" : "#484444",
              backgroundColor: props.manHeight === 4 ? "#fff" : "#edf0f0",
              borderWidth: props.manHeight === 4 ? 2 : 0,
              fontWeight: "bold",
            }}
            className="cursor-pointer h-5 rounded-md sm:rounded-lg sm:h-7 flex items-center justify-center"
            onClick={() => {
              dispatch(changeHeightMan(4));
            }}
          >
            1.90 cm
          </div>
          <div
            id="Sağ_+_Üst_+_Sol"
            style={{
              width: "100px",
              textAlign: "center",
              color: props.manHeight === 5 ? "#0d646f" : "#484444",
              backgroundColor: props.manHeight === 5 ? "#fff" : "#edf0f0",
              borderWidth: props.manHeight === 5 ? 2 : 0,
              fontWeight: "bold",
            }}
            className="cursor-pointer h-5 rounded-md sm:rounded-lg sm:h-7 flex items-center justify-center"
            onClick={() => {
              dispatch(changeHeightMan(5));
            }}
          >
            2.00 cm
          </div>
        </div>
      </Html>
    </group>
  );
}

useGLTF.preload("/man2.glb");
