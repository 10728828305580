import { useSelector } from "react-redux";
import { Suspense, useEffect, useState } from "react";
import { Man2 } from "../OrtakComponents/Man2";

import CabinetRegular from "./CabinetMirrors/CabinetRegular";
import { Environment, OrbitControls } from "@react-three/drei";

import CabinetCenter from "./CabinetMirrors/CabinetCenter";
import CabinetMirrorRoom from "./CabinetMirrors/CabinetMirrorRoom";

import { gsap } from "gsap";
import { useThree } from "@react-three/fiber";
import { Bloom, EffectComposer } from "@react-three/postprocessing";

import { UnrealBloomPass } from "three-stdlib";

import { extend } from "@react-three/fiber";
import { EnumCabinetMirrors } from "../../store/cabinetMirror";
import CabinetSided from "./CabinetMirrors/CabinetSided";

extend({ UnrealBloomPass });

const CabinetMirrorContainer = () => {
  const { cabinetMirrorWidth, lightIsOpen } = useSelector((state) => state.cabinetMirror);
  const manHeight = useSelector((state) => state.utils.selectedHeightLevel);
  const [isDragging, setIsDragging] = useState(false);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const { camera } = useThree();

  // useEffect(() => {
  //   if (cabinetMirrorWidth > 2200) {
  //     gsap.to(camera.position, { x: -10, ease: "Sine.easeOut" });
  //   } else if (cabinetMirrorWidth < 2200) {
  //     gsap.to(camera.position, { x: -7, ease: "Sine.easeOut" });
  //   }
  // }, [cabinetMirrorWidth]);

  const isSidedCabinet = [
    EnumCabinetMirrors.MUNCHEN_4,
    EnumCabinetMirrors.MALAGA,
    EnumCabinetMirrors.SARAGOSSA,
    EnumCabinetMirrors.KONSTANZ,
    EnumCabinetMirrors.DERBY,
    EnumCabinetMirrors.GENT,
    EnumCabinetMirrors.VENEZIA,
    EnumCabinetMirrors.ALMERIA,
    EnumCabinetMirrors.GRADO,
    EnumCabinetMirrors.VILLACH,
    EnumCabinetMirrors.VERONICA_3,
    EnumCabinetMirrors.VERONICA_4,
    EnumCabinetMirrors.PANDORA_3,
    EnumCabinetMirrors.PANDORA_4,
    EnumCabinetMirrors.KATHERINE_3,
    EnumCabinetMirrors.KATHERINE_4,
  ].includes(selectedMirror);

  const isCenteredCabinet = [EnumCabinetMirrors.MUNCHEN_5, EnumCabinetMirrors.LAS_VEGAS].includes(selectedMirror);

  return (
    <>
      <Environment near={1} far={100} resolution={256} files={"./hdr/bathroom_360.hdr"} background />
      <group rotation={[0, -1.5, 0]}>
        {lightIsOpen && (
          <EffectComposer multisampling={4} disableNormalPass>
            <Bloom luminanceThreshold={1} intensity={0.015} levels={2} />
            <Bloom luminanceThreshold={1} intensity={0.0053} levels={5} mipmapBlur />
            <Bloom luminanceThreshold={1} intensity={0.0019} levels={6} mipmapBlur />
          </EffectComposer>
        )}
        <Suspense fallback={null}>
          {isSidedCabinet ? (
            <CabinetSided setIsDragging={setIsDragging} isDragging={isDragging} />
          ) : isCenteredCabinet ? (
            <CabinetCenter setIsDragging={setIsDragging} isDragging={isDragging} />
          ) : (
            <CabinetRegular setIsDragging={setIsDragging} isDragging={isDragging} />
          )}

          <Man2
            position={[isSidedCabinet ? [-4.8 + cabinetMirrorWidth * 0.0003] : isCenteredCabinet ? [cabinetMirrorWidth > 2200 ? -6.4 : -3 - cabinetMirrorWidth * 0.001] : -3.5, -3.7, -0.3]}
            scale={25 + manHeight}
            manHeight={manHeight}
          />
        </Suspense>
        <CabinetMirrorRoom />
        {!isDragging && (
          <OrbitControls
            enablePan={false}
            minPolarAngle={Math.PI / 3.1}
            maxPolarAngle={Math.PI / 1.6}
            minAzimuthAngle={Math.PI * 1.15}
            maxAzimuthAngle={Math.PI * 1.95}
            zoomSpeed={0.25}
            enableZoom={true}
            makeDefault
            maxDistance={10}
            minDistance={3}
          />
        )}
      </group>
    </>
  );
};

export default CabinetMirrorContainer;
