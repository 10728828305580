import React, { useState, useEffect } from "react";
import { Stack, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMakyajAynasiYandanUzaklik, setMakyajAynasiAlttanUzaklik } from "../../../store/mirrors";

const MakyajAynaUzaklik = ({ hide }) => {
  const dispatch = useDispatch();
  const { mirrorWidth, mirrorHeight } = useSelector((state) => state.mirror);

  const [yandanSliderValue, setYandanSliderValue] = useState(180);

  const [MIN_VALUE_HEIGHT, SET_MIN_VALUE_HEIGHT] = useState(180);

  const MAX_VALUE_WIDTH = mirrorWidth - 180;
  const MIN_VALUE_WIDTH = 180;
  const DEFAULT_VALUE_WIDTH = 180;
  const [inputWidth, setInputWidth] = useState(DEFAULT_VALUE_WIDTH);

  const MAX_VALUE_HEIGHT = mirrorHeight - 180;

  const DEFAULT_VALUE_HEIGHT = 180;
  const [inputHeight, setInputHeight] = useState(DEFAULT_VALUE_HEIGHT);

  useEffect(() => {
    setInputWidth(DEFAULT_VALUE_WIDTH);
    SET_MIN_VALUE_HEIGHT(180);
    setInputHeight(180);
    dispatch(setMakyajAynasiAlttanUzaklik(180));
  }, [mirrorHeight, mirrorWidth]);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertMessage2, setAlertMessage2] = useState(null);

  return (
    <>
      {!hide ? (
        <Stack direction="column" gap={2} alignItems="flex-center">
          <Stack id="icon_ent" direction="row">
            <Typography
              variant="caption"
              fontWeight={700}
              sx={{
                maxWidth: "100px",
                minWidth: "100px",
                mr: "1rem",
              }}
            >
              Abstand von der Seite
            </Typography>

            <input type="hidden" name="id[35]" value="428" />
            <input type="hidden" class="form-control" id="txt_428" name="txt_428" value={yandanSliderValue} />

            <TextField
              value={inputWidth}
              type="number"
              onFocus={(e) => {
                if (e.target.value.length == 0) {
                  setInputWidth(yandanSliderValue);
                  e.target.style.color = "#000";
                  setAlertMessage(false);
                }
              }}
              onChange={(e) => {
                var value = parseInt(e.target.value);
                setInputWidth(value);
                let point = 0;
                if (!value) {
                  dispatch(setMakyajAynasiYandanUzaklik(MIN_VALUE_WIDTH));
                } else {
                  if (value > MAX_VALUE_WIDTH) {
                    dispatch(setMakyajAynasiYandanUzaklik(MAX_VALUE_WIDTH));
                    e.target.style.color = "tomato";
                    setAlertMessage(true);
                    point++;
                  } else {
                    point = point != 0 ? point-- : 0;
                    setAlertMessage(false);
                  }

                  if (value < MIN_VALUE_WIDTH) {
                    dispatch(setMakyajAynasiYandanUzaklik(MIN_VALUE_WIDTH));
                    e.target.style.color = "tomato";
                    setAlertMessage(true);
                    point++;
                  } else {
                    point = point != 0 ? point-- : 0;
                    setAlertMessage(false);
                  }

                  if (point == 0) {
                    dispatch(setMakyajAynasiYandanUzaklik(value));
                    e.target.style.color = "#000";
                    setAlertMessage(false);
                  } else {
                    e.target.style.color = "tomato";
                    setAlertMessage(true);
                  }
                }
              }}
              sx={{
                height: "2.2rem",
                width: "100%",
                ".MuiInputBase-input": {
                  padding: "8px 8px 8px 18px",
                  fontSize: 12,
                },
              }}
            />

            {/* <Slider
          sx={{
            ml: 3,
            ".MuiSlider-thumb": {
              minWidth: "50px",
              borderRadius: "10px",
            },
          }}
          onChange={handleChangeYandan}
          onChangeCommitted={handleCommitYandan}
          size="medium"
          defaultValue={180}
          min={180}
          max={mirrorWidth - 180}
          valueLabelDisplay="auto"
          // value={yandanSliderValue}
        /> */}
          </Stack>

          {alertMessage ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              sx={{
                position: "relative",
                top: -10,
              }}
            >
              <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem", mt: 0 }}></Typography>
              <small
                style={{
                  color: "red",
                }}
              >
                Bitte geben Sie den Wert (nur Ziffern) in Millimetern ein. Er muss mindestens {MIN_VALUE_WIDTH} (mm) von jeder Seite entfernt sein.
              </small>
            </Stack>
          ) : null}

          <Stack id="icon_ent" direction="row">
            <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "200px", minWidth: "100px", mr: "1rem" }}>
              Abstand von unten
            </Typography>

            <input type="hidden" name="id[36]" value="429" />
            <input type="hidden" class="form-control" name="txt_429" value={MIN_VALUE_HEIGHT} />

            <TextField
              value={inputHeight}
              type="number"
              onFocus={(e) => {
                if (e.target.value.length == 0) {
                  setInputHeight(MIN_VALUE_HEIGHT);
                  e.target.style.color = "#000";
                }
              }}
              onChange={(e) => {
                var value = parseInt(e.target.value);
                setInputHeight(value);
                let point = 0;
                if (!value) {
                  dispatch(setMakyajAynasiAlttanUzaklik(MIN_VALUE_HEIGHT));
                } else {
                  if (value > MAX_VALUE_HEIGHT) {
                    dispatch(setMakyajAynasiAlttanUzaklik(MAX_VALUE_HEIGHT));
                    e.target.style.color = "tomato";
                    setAlertMessage2(true);

                    point++;
                  } else {
                    point = point != 0 ? point-- : 0;
                    setAlertMessage2(false);
                  }

                  if (value < MIN_VALUE_HEIGHT) {
                    dispatch(setMakyajAynasiAlttanUzaklik(MIN_VALUE_HEIGHT));
                    e.target.style.color = "tomato";
                    setAlertMessage2(true);

                    point++;
                  } else {
                    point = point != 0 ? point-- : 0;
                    setAlertMessage2(false);
                  }

                  if (point == 0) {
                    dispatch(setMakyajAynasiAlttanUzaklik(value));
                    e.target.style.color = "#000";
                    setAlertMessage2(false);
                  } else {
                    setAlertMessage2(true);
                  }
                }
              }}
              sx={{
                height: "2.2rem",
                width: "100%",
                ".MuiInputBase-input": {
                  padding: "8px 8px 8px 18px",
                  fontSize: 12,
                },
              }}
            />

            {/* <Slider
          sx={{
            ml: 3,
            ".MuiSlider-thumb": {
              minWidth: "50px",
              borderRadius: "10px",
            },
          }}
          // value={alttanSliderValue}
          size="medium"
          defaultValue={180}
          min={180}
          max={mirrorHeight - 180}
          valueLabelDisplay="auto"
          onChange={handleChangeAlttan}
          onChangeCommitted={handleCommitAlttan}
        /> */}
          </Stack>
          {alertMessage2 ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              sx={{
                position: "relative",
                top: -10,
              }}
            >
              <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem", mt: 0 }}></Typography>
              <small
                style={{
                  color: "red",
                }}
              >
                Bitte geben Sie den Wert (nur Ziffern) in Millimetern ein. Er muss mindestens {MIN_VALUE_HEIGHT} (mm) von jeder Seite entfernt sein.
              </small>
            </Stack>
          ) : null}
          <Stack
            id="icon_ent"
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            sx={{
              position: "relative",
              top: -10,
              marginTop: "20px",
            }}
          >
            <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem", mt: 0 }}></Typography>
            <p>
              Geben Sie bitte den gewünschten Abstand vom <b>Rand</b> des Spiegels bis zum <b>Rand des Schminkspiegels</b> an. Der Mindestabstand zu jeder Seite des Spiegels muss mindestens
              {MIN_VALUE_WIDTH}mm bzw. {MIN_VALUE_HEIGHT}mm betragen.
            </p>
          </Stack>
        </Stack>
      ) : null}
    </>
  );
};

export default MakyajAynaUzaklik;
