import React, { useRef, useEffect, useState } from "react";
import { useGLTF, Html } from "@react-three/drei";
import { useSelector } from "react-redux";
import { extend, useThree } from "@react-three/fiber";
import { Effects } from "@react-three/drei";
import { UnrealBloomPass } from "three-stdlib";
import { useDrag } from "@use-gesture/react";
import { TempSensor } from "../../OrtakComponents/TempSensor";

extend({ UnrealBloomPass });
export function Koseli(props) {
  const { nodes, materials } = useGLTF("/koseli.glb");
  const koseliRef = useRef();
  const { analogWatch, watchPos, digitalWatch, isiSensor, isiSensorPos, yataySensor, yataySensorPos, dikeySensor, dikeySensorPos, lightColor, mirrorWidth, mirrorHeight, lightIsOpen } = useSelector(
    (state) => state.mirror
  );

  // Positions
  const [position, setPosition] = useState([0.38, 0.15, 0.01]);
  const [positionDigital, setPositionDigital] = useState([0.35, 0.15, 0.01]);

  const { size, viewport } = useThree();
  const aspect = size.width / viewport.width;

  useEffect(() => {
    setPositionDigital((prev) => [
      watchPos == 0 ? -0.175 : watchPos == 1 ? 0.04 : watchPos == 2 ? 0.25 : watchPos == 3 ? -0.175 : watchPos == 4 ? 0.04 : 0.25,
      watchPos == 0 || watchPos == 1 || watchPos == 2 ? 0.17 : 1.15,
      prev[2],
    ]);

    setPosition((prev) => [
      watchPos == 0 ? -0.175 : watchPos == 1 ? 0.04 : watchPos == 2 ? 0.25 : watchPos == 3 ? -0.175 : watchPos == 4 ? 0.04 : 0.25,

      watchPos == 0 || watchPos == 1 || watchPos == 2 ? 0.17 : 1.15,
      prev[2],
    ]);
  }, [watchPos]);

  return (
    <>
      <group
        ref={koseliRef}
        position={[0, -0.2 - mirrorHeight * 0.0012, 0.1]}
        rotation={[0, 0, 0]}
        scale={[((2.5 * mirrorWidth) / 400) * 0.5, ((2.3 * mirrorHeight) / 400) * 0.4, 1.6 * 0.5]}
        {...props}
        dispose={null}
      >
        <Html transform={true} rotation={[0, 0, 0]} position={[0.64, 1.55, 0]} scale={0.1} occlude>
          <div hidden id="koseliWidthHorizontalContainer">
            <div
              onClick={(e) => {
                alert("Click");
              }}
              style={{
                width: size.width / 7,
                position: "relative",
                left: -size.width / 10.4,
                height: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "-2px",
                  right: "3px",
                }}
              >
                |
              </div>
              <div
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.9)",
                  color: "#fff",
                  borderRadius: 100,
                  padding: "0 8px",
                }}
              >
                <span
                  style={{
                    fontSize: "1.1em",
                  }}
                  id="kareWidthHorizontal"
                >
                  {mirrorWidth == 0 ? "400" : mirrorWidth}
                </span>
              </div>
              <div
                style={{
                  position: "relative",
                  top: "-2px",
                  left: "3px",
                }}
              >
                |
              </div>
            </div>
          </div>
        </Html>
        <Html rotation={[0, 0, 0]} transform={true} position={[0.7, 0.725, 0]} scale={0.1} occlude>
          <div hidden id="koseliWidthVerticalContainer">
            <div
              onClick={(e) => {
                alert("Click");
              }}
              style={{
                width: size.width / 5.2,
                position: "relative",
                height: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                rotate: "90deg",
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "-2px",
                  right: "3px",
                }}
              >
                |
              </div>
              <div
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.9)",
                  color: "#fff",
                  borderRadius: 100,
                  padding: "0 8px",
                  rotate: "-90deg",
                }}
              >
                <span
                  style={{
                    fontSize: "1.1em",
                  }}
                  id="kareWidthVertical"
                >
                  {mirrorHeight == 0 ? "400" : mirrorHeight}
                </span>
              </div>
              <div
                style={{
                  position: "relative",
                  top: "-2px",
                  left: "3px",
                }}
              >
                |
              </div>
            </div>
          </div>
        </Html>
        {digitalWatch && (
          <mesh castShadow receiveShadow geometry={nodes.dijitalSAATbnjkgkj.geometry} material={materials.ledtexture} position={positionDigital} scale={2} rotation={[Math.PI / 2, 0, 0]}>
            <meshBasicMaterial color={[2, 1, 1]} toneMapped={false} />
          </mesh>
        )}
        {yataySensor && (
          <mesh
            castShadow
            receiveShadow
            geometry={nodes["SENSÖR-karehhl"].geometry}
            material={materials.ledtexture}
            position={[yataySensorPos == 0 ? -0.3 : yataySensorPos == 1 ? 0.02 : 0.35, 0.15, 0.01]}
            scale={1.5}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <meshBasicMaterial color={[2, 1, 1]} toneMapped={false} />
          </mesh>
        )}

        <mesh position={[0.015, 0.73, -0.045]} rotation={[Math.PI / 2, 0, 0]} scale={1}>
          <boxGeometry args={[0.92, 0.05, 1.15]} />
          <meshBasicMaterial color={"#666666"} />
        </mesh>
        {dikeySensor && (
          <mesh
            castShadow
            receiveShadow
            geometry={nodes["SENSÖR-yatayhjkhjk"].geometry}
            material={materials.touchdimmertexture}
            position={[dikeySensorPos == 0 ? -0.3 : dikeySensorPos == 1 ? 0.02 : 0.35, 0.15, 0.01]}
            scale={1.5}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <meshBasicMaterial color={[2, 1, 1]} toneMapped={false} />
          </mesh>
        )}

        {isiSensor && <TempSensor position={[isiSensorPos == 0 ? -0.3 : isiSensorPos == 1 ? 0.02 : 0.35, 0.15, 0.01]} rotation={[0, 0, 0]} scale={1.5} />}
        <group>
          <group name="5_model_ayna" position={[0.02, 0.72, 0.01]} scale={[1.02, 1.01, 1.01]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh name="5_model_ayna_1" castShadow receiveShadow geometry={nodes["5_model_ayna_1"].geometry} material={materials.ayna} />
            <mesh name="5_model_ayna_2" castShadow receiveShadow geometry={nodes["5_model_ayna_2"].geometry} material={materials.Material} />
          </group>
          <mesh name="5_model_led" castShadow receiveShadow geometry={nodes["5_model_led"].geometry} material={materials.ledtexture} position={[0.02, 0.72, -0.01]} rotation={[Math.PI / 2, 0, 0]}>
            <meshStandardMaterial color={lightColor} roughness={1} toneMapped={false} />
          </mesh>
          <mesh
            name="arkadan_aydınlatma_led"
            castShadow
            receiveShadow
            geometry={nodes.arkadan_aydınlatma_led.geometry}
            material={materials.ledtexture}
            position={[0.02, 0.72, -0.03]}
            rotation={[Math.PI / 2, 0, 0]}
          />
        </group>

        {analogWatch && (
          <group position={position} rotation={[Math.PI / 2, 0, 0]}>
            <mesh castShadow receiveShadow geometry={nodes.AKREP_YELKOVAN_1.geometry} material={materials.siyah} />
            <mesh castShadow receiveShadow geometry={nodes.AKREP_YELKOVAN_2.geometry} material={materials.wire_086086086} />
            <mesh castShadow receiveShadow geometry={nodes.AKREP_YELKOVAN_3.geometry} material={materials.grimetal} />
          </group>
        )}
      </group>
    </>
  );
}

useGLTF.preload("/koseli.glb");
