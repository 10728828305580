const Content = (props) => {
  return (
    <div
      style={{
        height: "80%",
        overflowY: "auto",
        overflowX: "hidden",
        padding: 15,
      }}
    >
      {props.children}
    </div>
  );
};

export default Content;
