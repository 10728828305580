import React from "react";
import { useGLTF } from "@react-three/drei";

const YataySensor = (props) => {
  const { nodes, materials } = useGLTF("/rectangle-mirror-rev01.glb");
  return (
    <mesh {...props} geometry={nodes["touch-dimmer"].geometry} material={materials.touchdimmertexture} rotation={[Math.PI / 2, 0, 0]} scale={1.2}>
      {/* <meshBasicMaterial color={lightColor} toneMapped={false} /> */}
    </mesh>
  );
};

export default YataySensor;
