import React from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeLightColor, setDikeySensor, setDisableSensors, setYataySensor } from "../../../store/mirrors";
import { setGecisAnahtariSensorFee, setKareSensorFee, setLedIsigiFee, setYataySensorFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const LedIsıgı = () => {
  const dispatch = useDispatch();
  const { lightColorIndex } = useSelector((state) => state.mirror);

  const handleChange = (e) => {
    dispatch(changeLightColor(e.target.value));
    dispatch(setLedIsigiFee(e.target.value == 3 ? 79.99 : e.target.value == 4 ? 99 : e.target.value == 5 ? 179.99 : e.target.value == 6 ? 159.99 : e.target.value == 7 ? 54.99 : 0));
    if (e.target.value == 3 || e.target.value == 4 || e.target.value == 5 || e.target.value == 6 || e.target.value == 7) {
      dispatch(setDisableSensors());
    }
    if (e.target.value == 5) {
      dispatch(setYataySensor(true));
      dispatch(setDikeySensor(false));
      dispatch(setYataySensorFee(59.99));
      dispatch(setKareSensorFee(0));
      dispatch(setGecisAnahtariSensorFee(0));
    }
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal title="Informationen zur Lichtfarbe">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Lichtfarbe
        </Typography>
      </RenderModal>
      <input
        name="id[5]"
        value={
          lightColorIndex == 0
            ? 9
            : lightColorIndex == 1
            ? 10
            : lightColorIndex == 2
            ? 11
            : lightColorIndex == 3
            ? 94
            : lightColorIndex == 4
            ? 718
            : lightColorIndex == 5
            ? 1021
            : lightColorIndex == 6
            ? 8863
            : lightColorIndex == 7
            ? 9588
            : null
        }
      />
      <Select onChange={handleChange} value={lightColorIndex} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={0}>LED Neutralweiß 4000K</MenuItem>
        <MenuItem value={1}>LED Warmweiß 3000K</MenuItem>
        <MenuItem value={2}>LED Kaltweiß 6000K</MenuItem>
        <MenuItem value={3}>LED RGB inkl. Fernbedienung + 79,99 €</MenuItem>
        <MenuItem value={4}>LED Warm- und Kaltweiß mit Doppel-Touch-Sensor + 99,00 €</MenuItem>
        <MenuItem value={5}>LED Dimmbar 2700K bis 6300K inkl. Gestensteuerung + 179,99 €</MenuItem>
        <MenuItem value={6}>LED Dimmbar 2700K bis 6300K mit Fernbedienung + 159,00 €</MenuItem>
        <MenuItem value={7}>Nur Smart Home: CCT LED Dimmbar 2700K bis 6300K + 54,99 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default LedIsıgı;
