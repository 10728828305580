import React, { useEffect } from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setBluetoothSpeaker } from "../../../../store/cabinetMirror";
import { setBluetoothSpeakerFiyatı } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const Bluetooth = () => {
  const dispatch = useDispatch();
  const { bluetoothSpeaker } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const handleChange = (e) => {
    dispatch(setBluetoothSpeaker(e.target.value));
    dispatch(setBluetoothSpeakerFiyatı(e.target.value === false ? 0 : 179));
  };

  useEffect(() => {
    dispatch(setBluetoothSpeaker(false));
    dispatch(setBluetoothSpeakerFiyatı(0));
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="30">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Bluetooth Lautsprecher
        </Typography>
      </RenderModal>
      <input name="id[77]" value={bluetoothSpeaker ? 1914 : 694} />
      <Select onChange={handleChange} value={bluetoothSpeaker} sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}>
        <MenuItem value={false}>---</MenuItem>
        <MenuItem value={true}>WHD High-End Sound-System + 179,00 €</MenuItem>
      </Select>
    </Stack>
  );
};

export default Bluetooth;
