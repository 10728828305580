import React, { useEffect } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { useSelector } from "react-redux";

import * as THREE from "three";

export function CabinetShelves() {
  const { nodes, materials } = useGLTF("/newModels/budapest.glb");
  const { nodes: nodesForShelf, materials: shelfMat2 } = useGLTF("/untitled.glb");

  const { shelfCount, ahsapZemin, bodyDecor } = useSelector((state) => state.cabinetMirror);

  const bodyTexture = useTexture(`./decors/${bodyDecor}`);

  useEffect(() => {
    bodyTexture.rotation = Math.PI * 0.5;
    bodyTexture.repeat.set(0.4, 5);
    bodyTexture.wrapS = THREE.RepeatWrapping;
    bodyTexture.wrapT = THREE.RepeatWrapping;
    shelfMat2.white.map = bodyTexture;
  }, [bodyDecor]);

  const shelfMaterial = shelfCount > 1 ? materials.glass : shelfMat2.white;

  const renderOne = () => (
    <mesh
      renderOrder={-1}
      castShadow
      receiveShadow
      geometry={nodesForShelf.shelf001.geometry}
      material={shelfMaterial}
      position={[0, 1.6 - 1.47, 0.02]}
      rotation={[Math.PI / 2, 0, 0]}
      scale={[0.98, 1, 1]}
    />
  );

  const renderTwo = () => (
    <mesh renderOrder={-1} castShadow receiveShadow geometry={nodes.shelf.geometry} material={shelfMaterial} position={[0, 1.47 - 1.47, 0.02]} rotation={[Math.PI / 2, 0, 0]} scale={[0.98, 1, 1]} />
  );

  const renderThree = () => (
    <>
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.78 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.6 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.42 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
    </>
  );

  const renderFour = () => (
    <>
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.84 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.68 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.52 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.36 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
    </>
  );

  const renderFive = () => (
    <>
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.87 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.735 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.6 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.465 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.33 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
    </>
  );

  const renderSix = () => (
    <>
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.88 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.77 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.66 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.54 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.43 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
      <mesh
        renderOrder={-1}
        castShadow
        receiveShadow
        geometry={nodesForShelf.shelf001.geometry}
        material={shelfMaterial}
        position={[0, 1.32 - 1.47, 0.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.98, 1, 1]}
      />
    </>
  );

  const shouldRenderOne = shelfCount === 1 || ahsapZemin === 1;
  const shouldRenderTwo = shelfCount === 2 || ahsapZemin === 2;
  const shouldRenderThree = shelfCount === 3 || ahsapZemin === 3;
  const shouldRenderFour = shelfCount === 4 || ahsapZemin === 4;
  const shouldRenderFive = shelfCount === 5 || ahsapZemin === 5;
  const shouldRenderSix = shelfCount === 6 || ahsapZemin === 6;

  return shouldRenderOne ? (
    renderOne()
  ) : shouldRenderTwo ? (
    renderTwo()
  ) : shouldRenderThree ? (
    renderThree()
  ) : shouldRenderFour ? (
    renderFour()
  ) : shouldRenderFive ? (
    renderFive()
  ) : shouldRenderSix ? (
    renderSix()
  ) : (
    <></>
  );
}
