import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Html } from "@react-three/drei";
import { changeMirror } from "../../../store/mirrors";
import { setBaseFee, setCabinetMirrorName } from "../../../store/cabinetMirrorFee";
import { EnumCabinetMirrors, changeSelectedExcelItem } from "../../../store/cabinetMirror";
import { Avatar, Button, Divider, Drawer, Stack, Tooltip, tooltipClasses, useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";

const CATEGORY_INDEXES = {
  UPPER_LIGHT: 3,
  WITHOUT: 0,
  SIDES: 2,
  CENTER_BOTTOM_SHELF: 1,
};

const CabinetMirrorSelectionMenu = () => {
  const [lightTypes, setLightTypes] = useState();
  const { cabinetHeight } = useSelector((state) => state.cabinetMirror);
  const { isPrivate, selectedMirror } = useSelector((state) => state.mirror);
  const dispatch = useDispatch();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [activeTab, setActiveTab] = useState(CATEGORY_INDEXES.WITHOUT); //0 >> Two Door, 1 >> One Door

  const cabinetsWithUpperLight = [
    {
      name: "PANDORA 1",
      value: EnumCabinetMirrors.PANDORA_1,
      excelId: EnumCabinetMirrors.PANDORA_1,
      excelName: "Bad Spiegelschrank mit LED Leuchte Pandora 1",
    },
    {
      name: "PANDORA 2",
      value: EnumCabinetMirrors.PANDORA_2,
      excelId: EnumCabinetMirrors.PANDORA_2,
      excelName: "Spiegelschrank mit Leuchte Pandora und Ablage fürs Bad 2",
    },
    {
      name: "PANDORA 3",
      value: EnumCabinetMirrors.PANDORA_3,
      excelId: EnumCabinetMirrors.PANDORA_3,
      excelName: "Badezimmerspiegelschrank mit Leuchte Pandora 3",
    },
    {
      name: "PANDORA 4",
      value: EnumCabinetMirrors.PANDORA_4,
      excelId: EnumCabinetMirrors.PANDORA_4,
      excelName: "Badspiegelschrank mit Fächern und Leuchte Pandora 4",
    },
    {
      name: "KATHERINE 1",
      value: EnumCabinetMirrors.KATHERINE_1,
      excelId: EnumCabinetMirrors.KATHERINE_1,
      excelName: "Bad Spiegelschrank nach Maß mit LED Lampe Katherine 1",
    },
    {
      name: "KATHERINE 2",
      value: EnumCabinetMirrors.KATHERINE_2,
      excelId: EnumCabinetMirrors.KATHERINE_2,
      excelName: "Spiegelschrank Bad mit LED Lampe Katherine und Ablagen 2",
    },
    {
      name: "KATHERINE 3",
      value: EnumCabinetMirrors.KATHERINE_3,
      excelId: EnumCabinetMirrors.KATHERINE_3,
      excelName: "Badezimmerspiegelschrank mit Regalen und Lampe Katherine 3",
    },
    {
      name: "KATHERINE 4",
      value: EnumCabinetMirrors.KATHERINE_4,
      excelId: EnumCabinetMirrors.KATHERINE_4,
      excelName: "Badspiegelschrank mit Lampe (LED) Katherine und Fächern 4",
    },
    {
      name: "VERONICA 1",
      value: EnumCabinetMirrors.VERONICA_1,
      excelId: EnumCabinetMirrors.VERONICA_1,
      excelName: "Bad Spiegelschrank mit Lampe (LED) Veronica 1",
    },
    {
      name: "VERONICA 2",
      value: EnumCabinetMirrors.VERONICA_2,
      excelId: EnumCabinetMirrors.VERONICA_2,
      excelName: "Spiegelschrank mit Lampe Veronica und Ablage fürs Bad 2",
    },
    {
      name: "VERONICA 3",
      value: EnumCabinetMirrors.VERONICA_3,
      excelId: EnumCabinetMirrors.VERONICA_3,
      excelName: "Badezimmerspiegelschrank mit Lampe Veronica und Regalen 3",
    },
    {
      name: "VERONICA 4",
      value: EnumCabinetMirrors.VERONICA_4,
      excelId: EnumCabinetMirrors.VERONICA_4,
      excelName: "Badspiegelschrank mit LED Lampe Veronica und Fächern 4",
    },
  ];
  const cabinetsShelfless = [
    {
      name: "OXFORD",
      value: EnumCabinetMirrors.OXFORD,
      excelId: EnumCabinetMirrors.OXFORD,
      excelName: "Bad Spiegelschrank nach Maß - OXFORD",
    },
    {
      name: "BRISTOL",
      value: EnumCabinetMirrors.BRISTOL,
      excelId: EnumCabinetMirrors.BRISTOL,
      excelName: "Bad Spiegelschrank - nur INNEN beleuchtet - BRISTOL",
    },
    {
      name: "WESER",
      value: EnumCabinetMirrors.WESER,
      excelId: EnumCabinetMirrors.WESER,
      excelName: "Badspiegelschrank aus Aluminium - Weser",
    },
    {
      name: "HAMBURG 1",
      value: EnumCabinetMirrors.HAMBURG,
      excelId: EnumCabinetMirrors.HAMBURG,
      excelName: "BSpiegelschrank mit Leuchtrahmen - Hamburg 1",
    },

    {
      name: "FRANKFURT 1",
      value: EnumCabinetMirrors.FRANKFURT,
      excelId: EnumCabinetMirrors.FRANKFURT,
      excelName: "Spiegelschrank Frankfurt 1",
    },
    {
      name: "ELBE 1",
      value: EnumCabinetMirrors.ELBE_1,
      excelId: EnumCabinetMirrors.ELBE_1,
      excelName: "Bad Spiegelschrank aus Aluminium - Elbe 1",
    },
    {
      name: "MUNCHEN 1",
      value: EnumCabinetMirrors.MUNCHEN_1,
      excelId: EnumCabinetMirrors.MUNCHEN_1,
      excelName: "Spiegelschrank rundum beleuchtet - München 1",
    },
    {
      name: "DRESDEN",
      value: EnumCabinetMirrors.DRESDEN,
      excelId: EnumCabinetMirrors.DRESDEN,
      excelName: "Spiegelschrank Bad mit LED Licht - DRESDEN",
    },
    {
      name: "LIPPE",
      value: EnumCabinetMirrors.LIPPE,
      excelId: EnumCabinetMirrors.LIPPE,
      excelName: "Beleuchteter Bad-Spiegelschrank aus Alu - Lippe",
    },
    {
      name: "LECH",
      value: EnumCabinetMirrors.LECH,
      excelId: EnumCabinetMirrors.LECH,
      excelName: "Alu Spiegelschrank mit Beleuchtung - Lech",
    },
    {
      name: "CHICAGO",
      value: EnumCabinetMirrors.CHICAGO,
      excelId: EnumCabinetMirrors.CHICAGO,
      excelName: "Spiegelschrank fürs Bad mit LED Licht - CHICAGO",
    },
    {
      name: "COLUMBUS",
      value: EnumCabinetMirrors.COLUMBUS,
      excelId: EnumCabinetMirrors.COLUMBUS,
      excelName: "Badspiegelschrank nach Maß mit Beleuchtung - COLUMBUS",
    },
    {
      name: "WERRA",
      value: EnumCabinetMirrors.WERRA,
      excelId: EnumCabinetMirrors.WERRA,
      excelName: "Badspiegelschrank aus Alu beleuchtet - Werra",
    },
    {
      name: "NECKAR",
      value: EnumCabinetMirrors.NECKAR,
      excelId: EnumCabinetMirrors.NECKAR,
      excelName: "Alu Badspiegelschrank mit Beleuchtung - Neckar",
    },
    {
      name: "HAVEL",
      value: EnumCabinetMirrors.HAVEL,
      excelId: EnumCabinetMirrors.HAVEL,
      excelName: "Badschrank aus Aluminium mit Spiegel und LED - Havel",
    },
    {
      name: "EL PASO",
      value: EnumCabinetMirrors.EL_PASO,
      excelId: EnumCabinetMirrors.EL_PASO,
      excelName: "Spiegelschrank für das Bad mit LED - EL PASO",
    },
    {
      name: "DUBLIN",
      value: EnumCabinetMirrors.DUBLIN,
      excelId: EnumCabinetMirrors.DUBLIN,
      excelName: "LED Spiegelschrank fürs Bad, Maßanfertigung - DUBLIN",
    },
  ];
  const cabinetsWithSideShelf = [
    {
      name: "MUNCHEN 4",
      value: EnumCabinetMirrors.MUNCHEN_4,
      excelId: EnumCabinetMirrors.MUNCHEN_4,
      excelName: "Spiegelschrank München 4",
    },
    {
      name: "MALAGA",
      value: EnumCabinetMirrors.MALAGA,
      excelId: EnumCabinetMirrors.MALAGA,
      excelName: "Spiegelschrank - MALAGA",
    },
    {
      name: "SARAGOSSA",
      value: EnumCabinetMirrors.SARAGOSSA,
      excelId: EnumCabinetMirrors.SARAGOSSA,
      excelName: "LED Spiegelschrank - SARAGOSSA",
    },
    {
      name: "KONSTANZ",
      value: EnumCabinetMirrors.KONSTANZ,
      excelId: EnumCabinetMirrors.KONSTANZ,
      excelName: "Spiegelschrank Badezimmer beleuchtet - KONSTANZ",
    },
    {
      name: "GRADO",
      value: EnumCabinetMirrors.GRADO,
      excelId: EnumCabinetMirrors.GRADO,
      excelName: "Bad Spiegelschrank - nur INNEN beleuchtet - GRADO",
    },
    {
      name: "ALMERIA",
      value: EnumCabinetMirrors.ALMERIA,
      excelId: EnumCabinetMirrors.ALMERIA,
      excelName: "Badezimmer-Spiegelschrank - ALMERIA",
    },
    {
      name: "VILLACH",
      value: EnumCabinetMirrors.VILLACH,
      excelId: EnumCabinetMirrors.VILLACH,
      excelName: "Bad Spiegelschrank beleuchtet - VILLACH",
    },
    {
      name: "DERBY",
      value: EnumCabinetMirrors.DERBY,
      excelId: EnumCabinetMirrors.DERBY,
      excelName: "Badezimmerspiegelschrank mit Regalen - DERBY",
    },
    {
      name: "GENT",
      value: EnumCabinetMirrors.GENT,
      excelId: EnumCabinetMirrors.GENT,
      excelName: "Badspiegelschrank mit Fächern - GENT",
    },
    {
      name: "VENEZIA",
      value: EnumCabinetMirrors.VENEZIA,
      excelId: EnumCabinetMirrors.VENEZIA,
      excelName: "LED Spiegelschrank - INNEN beleuchtet - VENEZIA",
    },
  ];
  const cabinetsWithCenterBottomShelf = [
    {
      name: "BUDAPEST",
      value: EnumCabinetMirrors.BUDAPEST,
      excelId: EnumCabinetMirrors.BUDAPEST,
      excelName: "Spiegelschrank - BUDAPEST",
    },
    {
      name: "LISSABON",
      value: EnumCabinetMirrors.LISSABON,
      excelId: EnumCabinetMirrors.LISSABON,
      excelName: "Badspiegelschrank - LISSABON",
    },
    {
      name: "LINZ",
      value: EnumCabinetMirrors.LINZ,
      excelId: EnumCabinetMirrors.LINZ,
      excelName: "Spiegelschrank Badezimmer - LINZ",
    },
    {
      name: "CORTINA",
      value: EnumCabinetMirrors.CORTINA,
      excelId: EnumCabinetMirrors.CORTINA,
      excelName: "Spiegelschrank - nur INNEN beleuchtet - CORTINA",
    },
    {
      name: "WINDSOR",
      value: EnumCabinetMirrors.WINDSOR,
      excelId: EnumCabinetMirrors.WINDSOR,
      excelName: "piegelschrank mit Ablage fürs Bad - WINDSOR",
    },
    {
      name: "MUNCHEN 6",
      value: EnumCabinetMirrors.MUNCHEN_6,
      excelId: EnumCabinetMirrors.MUNCHEN_6,
      excelName: "Spiegelschrank Bad München 6",
    },
    {
      name: "SANTANDER",
      value: EnumCabinetMirrors.SANTANDER,
      excelId: EnumCabinetMirrors.SANTANDER,
      excelName: "Bad Spiegelschrank (Unterputz) - SANTANDER",
    },
    {
      name: "MUNCHEN 5",
      value: EnumCabinetMirrors.MUNCHEN_5,
      excelId: EnumCabinetMirrors.MUNCHEN_5,
      excelName: "LED Bad Spiegelschrank mit Fächern - LAS VEGAS",
    },
    {
      name: "LAS VEGAS",
      value: EnumCabinetMirrors.LAS_VEGAS,
      excelId: EnumCabinetMirrors.LAS_VEGAS,
      excelName: "Bad Spiegelschrank München 5",
    },
  ];

  const isPandora = activeTab === CATEGORY_INDEXES.UPPER_LIGHT;
  const isSidedShelf = activeTab === CATEGORY_INDEXES.SIDES;
  const isShelfless = activeTab === CATEGORY_INDEXES.WITHOUT;
  const isCenterBottom = activeTab === CATEGORY_INDEXES.CENTER_BOTTOM_SHELF;

  const handleDoorSelection = ({ name, value, excelName, excelId }) => {
    setIsMenuOpen(false);
    dispatch(changeMirror(value));
    // dispatch(setBaseFee(140.98));
    dispatch(setCabinetMirrorName(name));
    dispatch(
      changeSelectedExcelItem({
        name: excelName || "Bad Spiegelschrank - nur INNEN beleuchtet - BRISTOL",
        id: excelId || 2479,
      })
    );
  };

  const handleMenuClick = () => {
    setIsMenuOpen(true);
  };

  const handleSidedClick = () => {
    setActiveTab(CATEGORY_INDEXES.SIDES);
  };

  const handleCenterBottomClick = () => {
    setActiveTab(CATEGORY_INDEXES.CENTER_BOTTOM_SHELF);
  };

  const handleShelflessClick = () => {
    setActiveTab(CATEGORY_INDEXES.WITHOUT);
  };

  const handlePandoraClick = () => {
    setActiveTab(CATEGORY_INDEXES.UPPER_LIGHT);
  };

  const categories = [
    {
      name: "SHELFLESS CABINET",
      selectedCondition: isShelfless,
      onClickHandler: handleShelflessClick,
      iconUrl: "/ikonlar/İkon_aynadolap_ışıksız.png",
    },
    {
      name: "SIDE SHELVES",
      selectedCondition: isSidedShelf,
      onClickHandler: handleSidedClick,
      iconUrl: "/ikonlar/İkon_aynadolap_raflı.png",
    },
    {
      name: "BOTTOM AND CENTER SHELVES",
      selectedCondition: isCenterBottom,
      onClickHandler: handleCenterBottomClick,
      iconUrl: "/ikonlar/İkon_aynadolap_raflı.png",
    },
    {
      name: "UPPER LIGHT",
      selectedCondition: isPandora,
      onClickHandler: handlePandoraClick,
      iconUrl: "/ikonlar/İkon_aynadolap_ışıklı.png",
    },
  ];

  const [openState, setOpenState] = useState(true);
  const [initialClose, setInitialClose] = useState(false);

  const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#0d646f",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#0d646f",
      padding: "10px",
    },
  }));

  return (
    <Stack
      sx={{
        position: "fixed",
        zIndex: "1",
        top: "3vh",
        left: "3vw",
        display: "flex",
        alignItems: "flex-start",
      }}
      spacing={2.5}
    >
      <CustomTooltip
        PopperProps={{ style: { zIndex: 1 } }}
        open={openState}
        title={
          <div
            id="tooltipTextContainer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              id="tooltipTitleText"
              style={{
                fontSize: 14,
              }}
            >
              {initialClose ? "Modelle" : " Hier finden Sie alle Modelle"}
            </p>
            {!initialClose && (
              <CloseIcon
                cursor={"pointer"}
                onClick={() => {
                  setOpenState(false);
                  setInitialClose(true);
                }}
                sx={{
                  marginLeft: "10px",
                }}
              />
            )}
          </div>
        }
        arrow
        placement="right"
      >
        <EditIcon
          onClick={() => {
            handleMenuClick();
            setInitialClose(true);
          }}
          onMouseEnter={() => setOpenState(true)}
          onMouseLeave={() => setOpenState(false)}
          sx={{
            width: "40px",
            height: "40px",
            minHeight: "40px",
            fontWeight: "700",
            bgcolor: isMenuOpen ? "#eeeeee" : "#0d646f",
            color: isMenuOpen ? "#0d646f" : "#eeeeee",
            borderRadius: "100%",
            p: "8px",
            "&:hover": {
              bgcolor: "#0d646f",
              color: "#eeeeee",
            },
            cursor: "pointer",
          }}
        />
      </CustomTooltip>

      <Drawer
        anchor="left"
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: `${mobileMatches ? "400px" : " 100vw"}`,
          },
        }}
      >
        <Stack
          spacing={3.5}
          sx={{
            width: `${mobileMatches ? "400px" : " 100vw"}`,
            maxWidth: `${mobileMatches ? "400px" : " 100vw"}`,
            height: "100vh",
            padding: "32px 16px",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          divider={<Divider light sx={{ width: 1 }} />}
        >
          <Stack
            spacing={1}
            direction={"row"}
            sx={{
              width: "100%",
              maxWidth: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            {categories.map((category) => (
              <Tooltip title={category.name}>
                <Avatar
                  src={category.iconUrl}
                  onClick={category.onClickHandler}
                  sx={{
                    backgroundColor: `${category.selectedCondition ? "#0d646f" : "#fff"}`,
                    color: `${category.selectedCondition ? "#fff" : "#0d646f"}`,
                    "&:hover": {
                      bgcolor: "#0d646f",
                      color: "#fff",
                    },
                    cursor: "pointer",
                    borderRadius: "8px",
                    padding: "8px",
                    width: "58px",
                    height: "58px",
                  }}
                />
              </Tooltip>
            ))}
          </Stack>

          <Stack
            spacing={4}
            sx={{
              width: "100%",
              flexGrow: 1,
              alignItems: "center",
              padding: "16px",
              overflowY: "auto",
            }}
          >
            {isPandora && cabinetsWithUpperLight.map((cabinet, index) => <ClosetButton cabinet={cabinet} handleDoorSelection={handleDoorSelection} index={index} />)}
            {isCenterBottom && cabinetsWithCenterBottomShelf.map((cabinet, index) => <ClosetButton cabinet={cabinet} handleDoorSelection={handleDoorSelection} index={index} />)}
            {isShelfless && cabinetsShelfless.map((cabinet, index) => <ClosetButton cabinet={cabinet} handleDoorSelection={handleDoorSelection} index={index} />)}
            {isSidedShelf && cabinetsWithSideShelf.map((cabinet, index) => <ClosetButton cabinet={cabinet} handleDoorSelection={handleDoorSelection} index={index} />)}
          </Stack>
        </Stack>
      </Drawer>
    </Stack>
  );
};

export default CabinetMirrorSelectionMenu;

const ClosetButton = ({ cabinet, handleDoorSelection, index }) => {
  const { selectedMirror } = useSelector((state) => state.mirror);
  const animationDelay = index * 50;
  const isSelected = selectedMirror === cabinet.value;
  if (isSelected) {
  }
  return (
    <Button
      key={cabinet.value}
      onClick={() => handleDoorSelection({ name: cabinet.name, value: cabinet.value, excelId: cabinet.excelId, excelName: cabinet.excelName })}
      sx={{
        fontWeight: "700",
        bgcolor: isSelected ? "#0d646f" : "#eeeeee",
        color: isSelected ? "#ffffff" : "#0d646f",
        "&:hover": {
          color: "#eeeeee",
          bgcolor: "#0d646f",
          boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 6px",
        },
        opacity: "0",
        transform: "translateY(-200%)",
        "@keyframes dropDown": {
          "0%": {
            opacity: 0,
            transform: "translateY(-200%)",
          },
          "80%": {
            opacity: 0,
            transform: "translateY(-50%)",
          },
          "100%": {
            opacity: 1,
            transform: "translateY(0)",
          },
        },
        animation: `0.25s ease-in ${animationDelay}ms dropDown forwards`,
        width: 0.75,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "64px",
        p: 0,
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        cursor: "pointer",
      }}
    >
      <Avatar
        src={`https://www.spiegel21.de/images/product_images/info_images/${cabinet.value}_0.jpg`}
        sx={{
          mr: 3,
          height: "64px",
          width: "64px",
          borderRadius: "0px",
          borderTopLeftRadius: "4px",
          borderBottomLeftRadius: "4px",
        }}
      />

      {cabinet.name}
    </Button>
  );
};
