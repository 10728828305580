import React, { useState, useEffect, useRef } from "react";
import { Stack, Typography, RadioGroup, FormControlLabel, Radio, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setIsFirstLeftSided, setIsSecondLeftSided, setIsThirdLeftSided, setIsFourthLeftSided, setIsFifthLeftSided, setIsSixthLeftSided, EnumCabinetMirrors } from "../../../../store/cabinetMirror";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

const MenteseYonu = () => {
  const dispatch = useDispatch();
  const { isFirstLeftSided, isSecondLeftSided, isThirdLeftSided, isFourthLeftSided, isFifthLeftSided, isSixthLeftSided } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const [openDoorSideMenu, setOpenDoorSideMenu] = useState(false);
  const menuRef = useRef(null);

  const testDoorsInfoArray = [
    //TODO MUNCHEN ICIN FARKLI ARRAYLAR OLUSTURURUZ
    {
      value: isFirstLeftSided,
      setter: (value) => dispatch(setIsFirstLeftSided(value)),
      entegrationInputValue: isFirstLeftSided ? 2583 : 2584,
      name: "id[245]",
      label: "Anschlagrichtung Tür 1",
    },
    {
      value: isSecondLeftSided,
      setter: (value) => dispatch(setIsSecondLeftSided(value)),
      entegrationInputValue: isSecondLeftSided ? 2586 : 2585,
      name: "id[246]",
      label: "Anschlagrichtung Tür 2",
    },
    {
      value: isThirdLeftSided,
      setter: (value) => dispatch(setIsThirdLeftSided(value)),
      entegrationInputValue: isThirdLeftSided ? 2587 : 2588,
      name: "id[247]",
      label: "Anschlagrichtung Tür 3",
    },
    {
      value: isFourthLeftSided,
      setter: (value) => dispatch(setIsFourthLeftSided(value)),
      entegrationInputValue: isFourthLeftSided ? 2590 : 2589,
      name: "id[248]",
      label: "Anschlagrichtung Tür 4",
    },
    {
      value: isFifthLeftSided,
      setter: (value) => dispatch(setIsFifthLeftSided(value)),
      entegrationInputValue: isFifthLeftSided ? 2591 : 2592,
      name: "id[249]",
      label: "Anschlagrichtung Tür 5",
    },
    {
      value: isSixthLeftSided,
      setter: (value) => dispatch(setIsSixthLeftSided(value)),
      name: "id[250]",
      entegrationInputValue: isSixthLeftSided ? 2594 : 2593,
      label: "Anschlagrichtung Tür 6",
    },
  ];
  const cabinetSideDoorsInfoArray = [
    {
      value: isSecondLeftSided,
      setter: (value) => dispatch(setIsSecondLeftSided(value)),
      label: "Second Door",
    },
    {
      value: isThirdLeftSided,
      setter: (value) => dispatch(setIsThirdLeftSided(value)),
      label: "Third Door",
    },
    {
      value: isFourthLeftSided,
      setter: (value) => dispatch(setIsFourthLeftSided(value)),
      label: "Fourth Door",
    },
  ];

  const isSidedCabinet = selectedMirror === EnumCabinetMirrors.LAS_VEGAS || selectedMirror === EnumCabinetMirrors.MUNCHEN_4;

  const doorsInfoArray = isSidedCabinet ? testDoorsInfoArray : testDoorsInfoArray; //Test ettşn sided cabinetlerde de aynı şekilde munchen 4 de test ettim

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenDoorSideMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const desktopMatches1700 = useMediaQuery("(min-width:1700px)");
  const desktopMatches1200 = useMediaQuery("(min-width:1200px)");
  const desktopMatches900 = useMediaQuery("(min-width:900px)");
  const mobileMatches600 = useMediaQuery("(min-width:600px)");

  const kapiGenisligiWidth = desktopMatches1200 ? "21vw" : desktopMatches900 ? "35vw" : mobileMatches600 ? "35vw" : "70vw";
  const kapiGenisligiMarginRight = desktopMatches1200 ? "2vw" : desktopMatches900 ? "3vw" : "5vw";

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="19">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Anschlagrichtung Tür
        </Typography>
      </RenderModal>

      <Typography
        color="primary"
        sx={{ borderRadius: "8px", bgcolor: "#88888840", p: "6px 12px", cursor: "pointer", fontSize: "14px", fontWeight: "700" }}
        onClick={(event) => {
          event.stopPropagation();
          setOpenDoorSideMenu(true);
        }}
      >
        Optionen
      </Typography>
      <Stack
        ref={menuRef}
        justifyContent="space-evenly"
        spacing={0.5}
        id="doorSideMenu"
        sx={{
          flexGrow: "1",
          fontSize: "0.5rem",
          width: kapiGenisligiWidth,
          display: openDoorSideMenu ? "flex" : "none",
          position: "absolute",
          right: "1vw",
          alignItems: "flex-start",
          justifyContent: "center",
          zIndex: 11,
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          border: "1px solid #aabbaa30",
          p: "8px 8px",
          boxShadow: "2px 2px 12px gray",
          transition: "all 1s ease-in-out forwards",
          marginRight: kapiGenisligiMarginRight,
        }}
      >
        {doorsInfoArray.map((item, index) => (
          <>
            <DoorSidePicker entegrationInputName={item.name} entegrationInputValue={item.entegrationInputValue} door={item} index={index} isSidedCabinet={isSidedCabinet} />
          </>
        ))}
        <Typography
          color="primary"
          variant="subtitle2"
          fontWeight={700}
          sx={{ borderRadius: "8px", p: "6px 12px", cursor: "pointer", alignSelf: "center" }}
          onClick={(event) => {
            event.stopPropagation();
            setOpenDoorSideMenu(false);
          }}
        >
          Vollendet
        </Typography>
      </Stack>
    </Stack>
  );
};

export default MenteseYonu;

const DoorSidePicker = ({ door, index, isSidedCabinet, entegrationInputName, entegrationInputValue }) => {
  const { doorNumber } = useSelector((state) => state.cabinetMirror);

  const shouldDisplaySideSelector = isSidedCabinet || doorNumber - 1 >= index;

  const { value, label, setter } = door;
  const handleChange = (event, newValue) => {
    let boolVal;
    boolVal = JSON.parse(newValue);
    setter(boolVal);
    event.stopPropagation();
  };
  return shouldDisplaySideSelector ? (
    <Stack sx={{ alignItems: "flex-start", justifyContent: "flex-start", height: "5rem", width: "100%", fontSize: "12px !important" }}>
      <Typography variant="caption">{label}</Typography>
      <input name={entegrationInputName} value={entegrationInputValue} />
      <RadioGroup row value={value} onChange={handleChange} sx={{ fontSize: "12px" }}>
        <FormControlLabel value={true} control={<Radio />} label="Links" size="small" />
        <FormControlLabel value={false} control={<Radio />} label="Rechts" size="small" />
      </RadioGroup>
    </Stack>
  ) : null;
};
