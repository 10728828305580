import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMakeupMirror, EnumMirrors } from "../../../store/mirrors";
import { setMakyajFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const MakyajAynası = () => {
  const dispatch = useDispatch();
  const { makeupMirror, selectedMirror, mirrorWidth, mirrorHeight } = useSelector((state) => state.mirror);
  const isDaire = selectedMirror == EnumMirrors.CHARON || selectedMirror == EnumMirrors.DIANA || selectedMirror == EnumMirrors.HELENA;

  useEffect(() => {
    if (!isDaire) {
      if (mirrorWidth < 500 || mirrorHeight < 500) {
        dispatch(setMakeupMirror(0));
      }
    }
  }, [mirrorWidth, mirrorHeight]);

  const handleChange = (e) => {
    dispatch(setMakeupMirror(e.target.value));
    dispatch(setMakyajFee(e.target.value == 1 ? 59.99 : e.target.value == 2 ? 79.99 : 0));
  };

  const isWunschdekor = [EnumMirrors.WUNSCHDEKOR].includes(selectedMirror);

  return (
    <>
      {/* {
        selectedMirror == 2 || selectedMirror == 3 ? (mirrorWidth > 499 && mirrorHeight > 499 ? <MakyajAynası /> : (
          <>
            <div style={{ display: 'flex' }} >
              <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}> Makyaj Aynası</Typography> <span style={{ color: "tomato" }} >Ayna genişliğinin ve yüksekliğinin 500'den büyük olması gerekir</span>
            </div>
          </>
        )) : 
      } */}

      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <RenderModal title="Informationen zur Spiegelheizung">
          <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
            Schminkspiegel
          </Typography>
        </RenderModal>

        <input name="id[13]" value={makeupMirror == 0 ? 103 : makeupMirror == 1 ? 612 : makeupMirror == 2 ? 610 : null} />

        <Select
          onChange={handleChange}
          value={makeupMirror}
          sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
          disabled={!isDaire && !isWunschdekor ? (mirrorWidth < 500 || mirrorHeight < 500 ? true : false) : false}
        >
          <MenuItem value={0}>---</MenuItem>
          <MenuItem value={1}>5-fach Vergrößerung, unbeleuchtet + 59,99 €</MenuItem>
          {!isWunschdekor && <MenuItem value={2}>5-fach Vergrößerung, beleuchtet + 79,99 €</MenuItem>}
        </Select>
      </Stack>

      {!isDaire && !isWunschdekor ? (
        mirrorWidth < 500 || mirrorHeight < 500 ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            sx={{
              position: "relative",
              top: -10,
            }}
          >
            <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem", mt: 0 }}></Typography>
            <small
              style={{
                color: "tomato",
              }}
            >
              Der Spiegel muss mind. 500 mm breit/hoch sein, da sonst nicht genug Platz für einen beleuchteten Schminkspiegel ist.
            </small>
          </Stack>
        ) : (
          true
        )
      ) : null}
    </>
  );
};

export default MakyajAynası;
