import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { gsap } from "gsap";
import { useThree } from "@react-three/fiber";

import { Man2 } from "../OrtakComponents/Man2";
import { Daire } from "./Mirrors/Daire";
import { MakyajAynasi } from "./Mirrors/MakyajAynasi";
import { Oval } from "./Mirrors/Oval";
import { EnumMirrors } from "../../store/mirrors";

const DaireAyna = () => {
  const { selectedMirror, mirrorWidth, mirrorHeight, makeupMirrorPos, dikeySensorPos, yataySensorPos, isiSensorPos, watchPos, soket, digitalWatch, analogWatch, yataySensor, dikeySensor, isiSensor } =
    useSelector((state) => state.mirror);
  const manHeight = useSelector((state) => state.utils.selectedHeightLevel);
  const [daireAynaPos, setDaireAynaPos] = useState([0.3, 0.16]);
  const { camera } = useThree();

  // Position conditions
  const isiSensorCheck = isiSensor == 2 || isiSensor == 3;
  const bottomLeftAny =
    (analogWatch || digitalWatch ? watchPos == 0 : false) || (yataySensor ? yataySensorPos == 0 : dikeySensor ? dikeySensorPos == 0 : false) || (isiSensorCheck ? isiSensorPos == 0 : false);
  const bottomRightAny =
    (analogWatch || digitalWatch ? watchPos == 2 : false) || (yataySensor ? yataySensorPos == 2 : dikeySensor ? dikeySensorPos == 2 : false) || (isiSensorCheck ? isiSensorPos == 2 : false);
  const bottomMidAny =
    (analogWatch || digitalWatch ? watchPos == 1 : false) || (yataySensor ? yataySensorPos == 1 : dikeySensor ? dikeySensorPos == 1 : false) || (isiSensorCheck ? isiSensorPos == 1 : false);
  const bottomLeftAll =
    (analogWatch || digitalWatch ? watchPos == 0 : false) && ((yataySensor ? yataySensorPos == 0 : dikeySensor ? dikeySensorPos == 0 : false) || (isiSensorCheck ? isiSensorPos == 0 : false));
  const bottomRightAll =
    (analogWatch || digitalWatch ? watchPos == 2 : false) && ((yataySensor ? yataySensorPos == 2 : dikeySensor ? dikeySensorPos == 2 : false) || (isiSensor ? isiSensorPos == 2 : false));
  const bottomMidAll =
    (analogWatch || digitalWatch ? watchPos == 1 : false) && ((yataySensor ? yataySensorPos == 1 : dikeySensor ? dikeySensorPos == 1 : false) || (isiSensor ? isiSensorPos == 1 : false));
  const topRightAll =
    ((yataySensor ? yataySensorPos == 5 : dikeySensor ? dikeySensorPos == 5 : false) || (isiSensorCheck ? isiSensorPos == 5 : false)) && (analogWatch || digitalWatch ? watchPos == 5 : false);
  const topRightAny =
    (yataySensor ? yataySensorPos == 5 : dikeySensor ? dikeySensorPos == 5 : false) || (isiSensorCheck ? isiSensorPos == 5 : false) || (analogWatch || digitalWatch ? watchPos == 5 : false);
  const topLeftAll =
    ((yataySensor ? yataySensorPos == 3 : dikeySensor ? dikeySensorPos == 3 : false) || (isiSensorCheck ? isiSensorPos == 3 : false)) && (analogWatch || digitalWatch ? watchPos == 3 : false);
  const topLeftAny =
    (yataySensor ? yataySensorPos == 3 : dikeySensor ? dikeySensorPos == 3 : false) || (isiSensorCheck ? isiSensorPos == 3 : false) || (analogWatch || digitalWatch ? watchPos == 3 : false);
  const midRightAll =
    ((yataySensor ? yataySensorPos == 6 : dikeySensor ? dikeySensorPos == 6 : false) || (isiSensorCheck ? isiSensorPos == 6 : false)) && (analogWatch || digitalWatch ? watchPos == 6 : false);
  const midRightAny =
    (yataySensor ? yataySensorPos == 6 : dikeySensor ? dikeySensorPos == 6 : false) || (isiSensorCheck ? isiSensorPos == 6 : false) || (analogWatch || digitalWatch ? watchPos == 6 : false);

  const midLeftAll =
    ((yataySensor ? yataySensorPos == 7 : dikeySensor ? dikeySensorPos == 7 : false) || (isiSensorCheck ? isiSensorPos == 7 : false)) && (analogWatch || digitalWatch ? watchPos == 7 : false);
  const midLeftAny =
    (yataySensor ? yataySensorPos == 7 : dikeySensor ? dikeySensorPos == 7 : false) || (isiSensorCheck ? isiSensorPos == 7 : false) || (analogWatch || digitalWatch ? watchPos == 7 : false);

  // ---

  useEffect(() => {
    if (makeupMirrorPos == 1) {
      // Top Right If there is watch and sensor
      if (topRightAll) {
        setDaireAynaPos([(analogWatch ? -0.09 : -0.02) + mirrorWidth * 0.0015, -0.075 + mirrorWidth * 0.0004]);
      }
      // Top Right If there or watch and sensor
      else if (topRightAny) {
        setDaireAynaPos([(analogWatch ? 0.05 : 0.08) + mirrorWidth * 0.0015, -0.075 + mirrorWidth * 0.0004]);
      }
      // Top Right Default
      else {
        setDaireAynaPos([0.1 + mirrorWidth * 0.0015, -0.075 + mirrorWidth * 0.0004]);
      }
    } else if (makeupMirrorPos == 2) {
      // Mid Right If there is watch and sensor
      if (midRightAll) {
        setDaireAynaPos([-0.1 + mirrorWidth * 0.001, -0.5 + mirrorWidth * 0.00055]);
      }
      // Mid Right If there or watch and sensor
      else if (midRightAny) {
        setDaireAynaPos([-0.1 + mirrorWidth * 0.001, -0.3 + mirrorWidth * 0.00055]);
      }
      // Mid Right Default
      else {
        setDaireAynaPos([-0.1 + mirrorWidth * 0.001, 0.07 + mirrorWidth * 0.00055]);
      }
    } else if (makeupMirrorPos == 3) {
      // Bottom Right && If there are watch + sensor
      if (bottomRightAll) {
        setDaireAynaPos([(digitalWatch ? -0.17 : analogWatch ? -0.11 : -0.145) + mirrorWidth * 0.0006, 0.035 + mirrorWidth * 0.00009]);
      }
      // Bottom Right If there is watch or sensor
      else if (bottomRightAny) {
        setDaireAynaPos([(digitalWatch ? -0.225 : analogWatch ? -0.185 : -0.225) + mirrorWidth * 0.0005, 0.035 + mirrorWidth * 0.00009]);
      }
      // Bottom Right default
      else {
        setDaireAynaPos([-0.3 + mirrorWidth * 0.0005, -0.04 + mirrorWidth * 0.00029]);
      }
    } else if (makeupMirrorPos == 4) {
      // Bottom Mid && If there are watch + sensor
      if (bottomMidAll) {
        setDaireAynaPos([(digitalWatch ? -0.17 : analogWatch ? -0.11 : -0.145) + mirrorWidth * 0.0006, -0.015 - mirrorWidth * 0.00001]);
      }
      // Bottom Mid If there is watch or sensor
      else if (bottomMidAny) {
        setDaireAynaPos([(digitalWatch ? -0.225 : analogWatch ? -0.185 : -0.225) + mirrorWidth * 0.0005, -0.015 - mirrorWidth * 0.00001]);
      }
      // Bottom Mid Default
      else {
        setDaireAynaPos([-0.24 + mirrorWidth * 0.0003, -0.043 + mirrorWidth * 0.00005]);
      }
    } else if (makeupMirrorPos == 5) {
      // Bottom Left && If there are watch + sensor
      if (bottomLeftAll) {
        setDaireAynaPos([(digitalWatch ? -0.17 : analogWatch ? -0.11 : -0.145) + mirrorWidth * 0.0006, -0.06 - mirrorWidth * 0.00029]);
      }
      // Bottom left If there is watch or sensor
      else if (bottomLeftAny) {
        setDaireAynaPos([(digitalWatch ? -0.225 : analogWatch ? -0.185 : -0.225) + mirrorWidth * 0.0005, -0.06 - mirrorWidth * 0.00029]);
      }
      // Bottom left Default
      else {
        setDaireAynaPos([-0.3 + mirrorWidth * 0.0005, -0.035 - mirrorWidth * 0.00035]);
      }
    } else if (makeupMirrorPos == 6) {
      // Mid Left If there is watch and sensor
      if (midLeftAll) {
        setDaireAynaPos([0.18 * mirrorWidth * 0.0045, 0.06 - mirrorWidth * 0.0001]);
      }
      // Mid Left If there or watch and sensor
      else if (midLeftAny) {
        setDaireAynaPos([0.18 * mirrorWidth * 0.0045, -0.12 - mirrorWidth * 0.0002]);
      }
      // Mid Left Default
      else {
        setDaireAynaPos([0.18 * mirrorWidth * 0.0045, -0.2 - mirrorWidth * 0.0002]);
      }
    } else if (makeupMirrorPos == 7) {
      // Top Left If there is watch and sensor
      if (topLeftAll) {
        setDaireAynaPos([(analogWatch ? -0.01 : 0.04) + mirrorWidth * 0.0013, -0.07 - mirrorWidth * 0.00027]);
      }
      // Top Left If there or watch and sensor
      else if (topLeftAny) {
        setDaireAynaPos([(analogWatch ? 0.1 : 0.12) + mirrorWidth * 0.0013, -0.07 - mirrorWidth * 0.00027]);
      }
      // Top Left Default
      else {
        setDaireAynaPos([0.1 + mirrorWidth * 0.0013, -0.07 - mirrorWidth * 0.00027]);
      }
      // // Top Left If there is watch
      // if (analogWatch || digitalWatch ? watchPos == 3 : false) {
      //   setDaireAynaPos([(analogWatch ? 0 : 0.03) + mirrorWidth * 0.0015, -0.025 - mirrorWidth * 0.0004]);
      // }
    } else if (makeupMirrorPos == 8) {
      // Top Mid If there is watch
      if (analogWatch || digitalWatch ? watchPos == 4 : false) {
        setDaireAynaPos([(analogWatch ? -0.02 : 0) + mirrorWidth * 0.0016, 0 - mirrorWidth * 0.0004]);
      }
      // Top Mid Default
      else {
        setDaireAynaPos([0.03 + mirrorWidth * 0.00185, 0 - mirrorWidth * 0.0004]);
      }
    }
  }, [makeupMirrorPos, mirrorWidth, soket, digitalWatch, analogWatch, yataySensor, dikeySensor, isiSensor, isiSensorPos, watchPos, yataySensorPos, dikeySensorPos]);

  useEffect(() => {
    if (mirrorWidth > 1100) {
      gsap.to(camera.position, { z: 11, ease: "Sine.easeOut" });
    } else {
      gsap.to(camera.position, { z: 7, ease: "Sine.easeOut" });
    }
  }, [mirrorWidth]);

  const isOval = [EnumMirrors.F610L4K, EnumMirrors.F597L4K].includes(selectedMirror);
  return (
    <>
      {isOval ? <Oval /> : <Daire />}
      {!isOval && <MakyajAynasi position={[daireAynaPos[1], daireAynaPos[0] - (mirrorWidth / 400 - 1) * 0.1, 0.275]} />}
      <Man2 position={[(mirrorWidth > 1600 ? -4.2 : mirrorWidth > 1200 ? -3.8 : -3.2) - mirrorWidth * 0.0006, -3.5, -0.2]} scale={25 + manHeight} manHeight={manHeight} />
    </>
  );
};

export default DaireAyna;
