import { Stack, Slider, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import cabinet, { EnumCabinets, changeCabinetHeight } from "../../../store/cabinet";
import { useState, useEffect } from "react";

const Yukseklik = () => {
  const dispatch = useDispatch();
  const { cabinetHeight } = useSelector((state) => state.cabinet);

  const { selectedMirror } = useSelector((state) => state.mirror);

  const [sliderValue, setSliderValue] = useState(2000);
  const [alertMessage, setAlertMessage] = useState(false);

  const handleBlur = () => {
    dispatch(changeCabinetHeight(sliderValue));
  };
  const isCenteredCabinet = [EnumCabinets.BHS003, EnumCabinets.BHS009L4, EnumCabinets.BHS004].includes(selectedMirror);

  const minValue = isCenteredCabinet ? 500 : 400;

  const MAX_VALUE = 2500;
  const MIN_VALUE = minValue;
  const DEFAULT_VALUE = 400;
  const [input, setInput] = useState(sliderValue);

  useEffect(() => {
    dispatch(changeCabinetHeight(2000));
    setSliderValue(2000);
  }, [selectedMirror]);

  const handleChange = (e) => {
    setInput(e.target.value);
    setSliderValue(e.target.value);
  };

  useEffect(() => {
    setSliderValue(cabinetHeight);
  }, [cabinetHeight]);

  const kareYukseklik = [
    {
      value: "1807",
      inputValue: "200",
    },
    {
      value: "1052",
      inputValue: "250",
    },
    {
      value: "1053",
      inputValue: "300",
    },
    {
      value: "1054",
      inputValue: "350",
    },
    {
      value: "53",
      inputValue: "400",
    },
    {
      value: "54",
      inputValue: "450",
    },
    {
      value: "55",
      inputValue: "500",
    },
    {
      value: "56",
      inputValue: "550",
    },
    {
      value: "57",
      inputValue: "600",
    },
    {
      value: "58",
      inputValue: "650",
    },
    {
      value: "59",
      inputValue: "700",
    },
    {
      value: "60",
      inputValue: "750",
    },
    {
      value: "61",
      inputValue: "800",
    },
    {
      value: "62",
      inputValue: "850",
    },
    {
      value: "63",
      inputValue: "900",
    },
    {
      value: "64",
      inputValue: "950",
    },
    {
      value: "65",
      inputValue: "1000",
    },
    {
      value: "66",
      inputValue: "1050",
    },
    {
      value: "67",
      inputValue: "1100",
    },
    {
      value: "68",
      inputValue: "1150",
    },
    {
      value: "69",
      inputValue: "1200",
    },
    {
      value: "70",
      inputValue: "1250",
    },
    {
      value: "71",
      inputValue: "1300",
    },
    {
      value: "72",
      inputValue: "1350",
    },
    {
      value: "73",
      inputValue: "1400",
    },
    {
      value: "74",
      inputValue: "1450",
    },
    {
      value: "75",
      inputValue: "1500",
    },
    {
      value: "76",
      inputValue: "1550",
    },
    {
      value: "77",
      inputValue: "1600",
    },
    {
      value: "78",
      inputValue: "1650",
    },
    {
      value: "79",
      inputValue: "1700",
    },
    {
      value: "80",
      inputValue: "1750",
    },
    {
      value: "81",
      inputValue: "1800",
    },
    {
      value: "221",
      inputValue: "1850",
    },
    {
      value: "222",
      inputValue: "1900",
    },
    {
      value: "223",
      inputValue: "1950",
    },
    {
      value: "224",
      inputValue: "2000",
    },
    {
      value: "225",
      inputValue: "2050",
    },
    {
      value: "226",
      inputValue: "2100",
    },
    {
      value: "227",
      inputValue: "2150",
    },
    {
      value: "228",
      inputValue: "2200",
    },
    {
      value: "229",
      inputValue: "2250",
    },
    {
      value: "230",
      inputValue: "2300",
    },
    {
      value: "231",
      inputValue: "2350",
    },
    {
      value: "232",
      inputValue: "2400",
    },
    {
      value: "233",
      inputValue: "2450",
    },
    {
      value: "234",
      inputValue: "2500",
    },
  ];

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Höhe in mm
        </Typography>

        <input type="hidden" name="id[62]" value="527" />
        <input type="hidden" class="form-control" id="txt62" name="txt_527" value={cabinetHeight} />
        <input type="hidden" name="id[8]" value={kareYukseklik.find((k) => parseInt(k.inputValue) === input)?.value} />

        <TextField
          value={input}
          type="number"
          onFocus={(e) => {
            if (e.target.value.length == 0) {
              setInput(sliderValue);
              e.target.style.color = "#000";
            }
          }}
          onChange={(e) => {
            var value = parseInt(e.target.value);
            setInput(value);
            let point = 0;
            if (!value) {
              dispatch(changeCabinetHeight(MIN_VALUE));
            } else {
              if (value > MAX_VALUE) {
                dispatch(changeCabinetHeight(MAX_VALUE));
                e.target.style.color = "tomato";
                setAlertMessage(true);

                point++;
              } else {
                point = point != 0 ? point-- : 0;
                setAlertMessage(false);
              }

              if (value < MIN_VALUE) {
                dispatch(changeCabinetHeight(MIN_VALUE));
                e.target.style.color = "tomato";
                setAlertMessage(true);

                point++;
              } else {
                point = point != 0 ? point-- : 0;
                setAlertMessage(false);
              }

              if (point == 0) {
                dispatch(changeCabinetHeight(value));
                e.target.style.color = "#000";
                setAlertMessage(false);
              } else {
                setAlertMessage(true);
              }
            }
          }}
          sx={{
            height: "2.2rem",
            width: "100%",
            ".MuiInputBase-input": {
              padding: "8px 8px 8px 18px",

              fontSize: 12,
            },
          }}
        />

        {/* <Slider
        sx={{
          ml: 3,
          ".MuiSlider-thumb": {
            minWidth: "50px",
            borderRadius: "10px",
          },
        }}
        onChange={handleChange}
        onMouseUp={handleBlur}
        onChangeCommitted={handleBlur}
        size="medium"
        // defaultValue={2000}
        min={minValue}
        max={2500}
        valueLabelDisplay="auto"
        value={sliderValue}
      /> */}
      </Stack>
      {alertMessage ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            position: "relative",
            top: -10,
          }}
        >
          <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem", mt: 0 }}></Typography>
          <small
            style={{
              color: "red",
            }}
          >
            Bitte geben Sie den Wert für die Höhe (nur Ziffern) in Millimetern ein. Dieser muss zwischen {MIN_VALUE} und {MAX_VALUE} (mm) betragen.
          </small>
        </Stack>
      ) : null}
    </>
  );
};

export default Yukseklik;
