import React, { useEffect, useState } from "react";
import { Stack, Typography, TextField } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setBottomCompartmentHeight } from "../../../../store/cabinetMirror";

const CompartmentBottom = () => {
  const [bottomValue, setBottomValue] = useState(100);
  const { selectedMirror } = useSelector((state) => state.mirror);
  const dispatch = useDispatch();
  // const { customerComment } = useSelector((state) => state.cabinetMirror);

  useEffect(() => {
    dispatch(setBottomCompartmentHeight(bottomValue));
  }, [bottomValue]);

  useEffect(() => {
    dispatch(setBottomCompartmentHeight(100));
    setBottomValue(100);
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Stack>
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Fächer unten: lichte Höhe (mm)
        </Typography>
      </Stack>

      <input type="hidden" name="id[243]" value="2578" />
      <input type="hidden" class="form-control" name="txt_2578" value={bottomValue} />

      <TextField
        type="number"
        onChange={(e) => {
          if (e.target.value >= 100 && e.target.value <= 200) {
            setBottomValue(e.target.value);
          } else {
            setBottomValue(100);
          }
        }}
        value={bottomValue}
        sx={{
          width: "100%",
          height: "2.2rem",
          ".MuiInputBase-input": {
            paddingTop: 0.7,
            paddingBottom: 0.7,
            paddingLeft: 1.6,
            paddingRight: 0.7,
            width: "100%",
            fontSize: 12,
          },
        }}
      />
    </Stack>
  );
};

export default CompartmentBottom;
