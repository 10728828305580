import { useRef, useState, useEffect } from "react";

import * as THREE from "three";
import gsap from "gsap";
import { useGLTF, useTexture } from "@react-three/drei";

import { useSelector } from "react-redux";

import DışAydınlatma from "../../../OrtakComponents/DışAydınlatma";

import CabinetLeftDoor from "../../cabinetBodyParts/cabinetLeftDoors/CabinetLeftDoor";
import CabinetRightDoor from "../../cabinetBodyParts/cabinetRightDoors/CabinetRightDoor";

import { EnumCabinetMirrors } from "../../../../store/cabinetMirror";

import { DoorOpener, DoorHandleLeft, DoorHandleRight, CabinetShelves, LeftHinge, RightHinge, MakyajAynasi, IndividualWidth, SocketUSB, DimmerSocketUSB } from "../../cabinetBodyParts/smallParts";

export const KapakliBolmeCenter = ({ cabinetRef, doorIndex, isLeftSided, socketUsbBase, doorType }) => {
  const { nodes, materials } = useGLTF("/Frankfurt-test2.glb");

  const { selectedMirror } = useSelector((state) => state.mirror);
  const { cabinetMirrorType, doorNumber, externalLeds, draggerVisible, backPanel, hasMakeupMirror, bodyDecor, camCikintiAlt, camCikintiUst, openAllDoors, cabinetMirrorWidth } = useSelector(
    (state) => state.cabinetMirror
  );

  const isFirstForLeftLight = (doorIndex === 0 && doorNumber === 6) || (doorIndex === 1 && doorNumber === 4) || (doorIndex === 2 && doorNumber === 2);
  const isFirstForLastLight = (doorIndex === 6 && doorNumber === 6) || (doorIndex === 5 && doorNumber === 4) || (doorIndex === 4 && doorNumber === 2);

  const [rightOpen, setRightOpen] = useState(false);
  const [leftOpen, setLeftOpen] = useState(false);

  const rightDoorRef = useRef();
  const leftDoorRef = useRef();
  const mainBodyRef = useRef();

  const isPermanentDoors = doorIndex === 2 || doorIndex === 3 || doorIndex === 4;
  const isLastDoors = (doorIndex === 0 || doorIndex === 6) && doorNumber === 6;
  const isMidDoors = (doorIndex === 1 || doorIndex === 5) && doorNumber >= 4;

  const cabinetVisible = isPermanentDoors || isLastDoors || isMidDoors;

  const cabinetBody = useRef();
  const cabinetBackPanel = useRef();

  const bodyTexture = useTexture(`./decors/${bodyDecor == 88 ? "U763_ST9.jpeg" : bodyDecor}`);
  bodyTexture.rotation = Math.PI * 0.5;
  bodyTexture.repeat.set(0.4, 5);
  bodyTexture.wrapS = THREE.RepeatWrapping;
  bodyTexture.wrapT = THREE.RepeatWrapping;

  const isAllVisible = externalLeds === 5;

  useEffect(() => {
    cabinetBody.current.material.map = bodyTexture;

    if (backPanel === 0) {
      //
      cabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#ffffff",
      });
    } else if (backPanel === 1) {
      cabinetBackPanel.current.material = materials.mirror;
    } else if (backPanel === 2) {
      cabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#919191",
      });
    } else if (backPanel === 3) {
      cabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#FCFF94",
      });
    } else if (backPanel === 4) {
      cabinetBackPanel.current.material = new THREE.MeshBasicMaterial({
        color: "#333333",
      });
    }
  }, [backPanel, bodyDecor]);

  useEffect(() => {
    if (leftDoorRef.current) {
      leftDoorRef.current.scale.y = 1 + camCikintiAlt * 0.0015 + camCikintiUst * 0.0015;

      leftDoorRef.current.position.y = 0 - camCikintiAlt * 0.00036 + camCikintiUst * 0.00036;
    }
    if (rightDoorRef.current) {
      rightDoorRef.current.scale.y = 1 + camCikintiAlt * 0.0015 + camCikintiUst * 0.0015;
      rightDoorRef.current.position.y = 0 - camCikintiAlt * 0.00036 + camCikintiUst * 0.00036;
    }
  }, [camCikintiAlt, camCikintiUst]);

  useEffect(() => {
    if (isLeftSided) {
      leftDoorRef.current.visible = true;
      rightDoorRef.current.visible = false;
    } else {
      leftDoorRef.current.visible = false;
      rightDoorRef.current.visible = true;
    }
  }, [isLeftSided]);

  useEffect(() => {
    if (openAllDoors) {
      if (isLeftSided) {
        setLeftOpen(true);
        setRightOpen(false);
      } else {
        setLeftOpen(false);
        setRightOpen(true);
      }
    } else {
      setLeftOpen(false);
      setRightOpen(false);
    }
  }, [openAllDoors, isLeftSided]);

  useEffect(() => {
    if (cabinetRef.current) {
      //SCALE
      const minWidthDoor = 400;
      const currentWidthPerDoor = cabinetMirrorWidth / (doorNumber + 1);
      const scaleRatio = currentWidthPerDoor / minWidthDoor;
      cabinetRef.current.scale.x = scaleRatio * 2;
      // cabinetRef.current.position.y = doorIndex % 2 === 0 ? 0.5 : -0.5;

      //POSITION
      cabinetRef.current.position.x = scaleRatio * doorIndex;
    }
  }, [cabinetMirrorWidth, doorNumber]);

  useEffect(() => {
    if (leftOpen) {
      gsap.to(leftDoorRef.current.scale, {
        x: (cabinetMirrorWidth / doorNumber / 400) * 0.85 * 0.75,
      });
      gsap.to(leftDoorRef.current.rotation, { y: -Math.PI * 0.5 });
    } else {
      gsap.to(leftDoorRef.current.scale, { x: 1 });
      gsap.to(leftDoorRef.current.rotation, { y: 0 });
    }
    if (rightOpen) {
      gsap.to(rightDoorRef.current.scale, {
        x: (cabinetMirrorWidth / doorNumber / 400) * 0.85 * 0.75,
      });
      gsap.to(rightDoorRef.current.rotation, { y: Math.PI * 0.5 });
    } else {
      gsap.to(rightDoorRef.current.scale, { x: 1 });
      gsap.to(rightDoorRef.current.rotation, { y: 0 });
    }
  }, [openAllDoors, leftOpen, rightOpen, cabinetMirrorWidth]);

  const isSideLedVisible = isAllVisible || externalLeds === 4;
  const IS_MUNCHEN_TYPE = [EnumCabinetMirrors.MUNCHEN_1, EnumCabinetMirrors.MUNCHEN_4, EnumCabinetMirrors.MUNCHEN_5, EnumCabinetMirrors.MUNCHEN_6].includes(selectedMirror);

  return (
    <group visible={cabinetVisible} position-z={cabinetMirrorType} scale={[1, 1, 1]} ref={cabinetRef}>
      <group name="bodyParts" ref={mainBodyRef} position={[0, 0, 0]}>
        <SocketUSB base={socketUsbBase} />
        <DimmerSocketUSB base={socketUsbBase} />
        {isAllVisible && <DışAydınlatma scaleY={0.5} position={[0, 0.4, 0.065]} rotation={[Math.PI * 1.5, 0, Math.PI * 1.5]} />}
        {isAllVisible && <DışAydınlatma scaleY={0.5} position={[0, -0.4, 0.065]} rotation={[Math.PI * 0.5, 0, Math.PI * 0.5]} />}
        {isSideLedVisible && isFirstForLeftLight && <DışAydınlatma scaleY={isAllVisible ? 0.8 : 0.7} position={[-0.25, 0, 0.065]} rotation={[0, Math.PI * 1.5, 0]} />}
        {isSideLedVisible && isFirstForLastLight && <DışAydınlatma scaleY={isAllVisible ? 0.8 : 0.7} position={[0.25, 0, 0.065]} rotation={[0, Math.PI * 0.5, 0]} />}

        {cabinetVisible && <DoorOpener testCabinet={true} posX={1} posX2={0.85} openLeft={setLeftOpen} openRight={setRightOpen} rightDoor={rightOpen} leftDoor={leftOpen} isLeftSided={isLeftSided} />}

        {cabinetVisible && cabinetRef.current && draggerVisible && <IndividualWidth testCabinet={true} width={cabinetRef.current.scale.x} />}

        {/* ÜST BÖLME */}
        <mesh ref={cabinetBody} castShadow receiveShadow geometry={nodes.body.geometry} material={materials.Material} position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]} />
        <mesh ref={cabinetBackPanel} castShadow receiveShadow geometry={nodes.bodyRear.geometry} material={materials.white} position={[0, 0, -0.1]} />

        <CabinetShelves />
        {rightOpen && <RightHinge />}
        {leftOpen && <LeftHinge />}
        <group ref={leftDoorRef} position={[-0.25, 0, IS_MUNCHEN_TYPE ? 0.12 : 0.1]}>
          {doorIndex === 0 && hasMakeupMirror && <MakyajAynasi isLeftDoor={true} />}
          <DoorHandleLeft />
          <CabinetLeftDoor type={doorType} />
        </group>

        <group ref={rightDoorRef} position={[0.25, 0, IS_MUNCHEN_TYPE ? 0.12 : 0.1]} rotation={[0, 0, Math.PI]}>
          {leftDoorRef?.current?.visible === false && <DoorHandleRight />}
          {doorIndex === 0 && hasMakeupMirror && <MakyajAynasi isLeftDoor={false} />}
          <CabinetRightDoor type={doorType} />
        </group>
      </group>
    </group>
  );
};
