import React, { useEffect } from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setSensor, setSocketUsb } from "../../../../store/cabinetMirror";
import { setSensorFiyatı, setSocketUsbFiyatı } from "../../../../store/cabinetMirrorFee";
import RenderModal from "../../../OrtakComponents/Modal/RenderModal";

export const soketlerIcerdeOptions = [
  {
    label: "---",
    price: 0.0,
    value: "754",
  },
  {
    label: "1x Steckdose links",
    price: 39.0,
    value: "755",
  },
  {
    label: "1x Steckdose rechts",
    price: 39.0,
    value: "756",
  },
  {
    label: "1x Steckdose in der Mitte",
    price: 39.0,
    value: "9666",
  },
  {
    label: "2x Steckdose links (Doppelframe)",
    price: 49.0,
    value: "758",
  },
  {
    label: "2x Steckdose in der Mitte (Doppelframe)",
    price: 49.0,
    value: "9667",
  },
  {
    label: "2x Steckdose rechts (Doppelframe)",
    price: 49.0,
    value: "757",
  },
  {
    label: "1x Steckdose links + 1x Steckdose rechts",
    price: 78.0,
    value: "759",
  },
  {
    label: "2x Steckdose links + 2x Steckdose rechts",
    price: 98.0,
    value: "760",
  },
  {
    label: "1x USB links",
    price: 45.0,
    value: "6793",
  },
  {
    label: "1x USB in der Mitte",
    price: 45.0,
    value: "9669",
  },
  {
    label: "1x USB rechts",
    price: 45.0,
    value: "6794",
  },
  {
    label: "1x USB + 1x Steckdose links (2-fach Rahmen)",
    price: 79.0,
    value: "6795",
  },
  {
    label: "1x USB + 1x Steckdose in der Mitte (2-fach Rahmen)",
    price: 79.0,
    value: "9670",
  },
  {
    label: "1x USB + 1x Steckdose rechts (2-fach Rahmen)",
    price: 79.0,
    value: "6796",
  },
  {
    label: "1x USB + 2x Steckdose links (3-fach Rahmen)",
    price: 119.0,
    value: "6797",
  },
  {
    label: "1x USB + 2x Steckdose in der Mitte (3-fach Rahmen)",
    price: 119.0,
    value: "9671",
  },
  {
    label: "1x USB + 2x Steckdose rechts (3-fach Rahmen)",
    price: 119.0,
    value: "6798",
  },
  {
    label: "2x Steckdosen links, 1x USB in der Mitte, 2x Steckdosen rechts",
    price: 143.0,
    value: "9665",
  },
  {
    label: "2x Steckdosen links + 2x Steckdosen rechts + 2x Steckdosen in der Mitte",
    price: 147.0,
    value: "9668",
  },
];

const Soketler = () => {
  const dispatch = useDispatch();
  const { socketUsb } = useSelector((state) => state.cabinetMirror);
  const { selectedMirror } = useSelector((state) => state.mirror);
  useEffect(() => {
    const matchedOption = soketlerIcerdeOptions.find((item) => item.value === socketUsb);
    if (matchedOption) {
      dispatch(setSocketUsbFiyatı(matchedOption.price));
    }
  }, [socketUsb]);

  useEffect(() => {
    dispatch(setSocketUsbFiyatı(0));
    dispatch(setSocketUsb("754"));
  }, [selectedMirror]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <RenderModal id="24">
        <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
          Steckdose/USB innen
        </Typography>
      </RenderModal>
      <input name="id[91]" value={soketlerIcerdeOptions.find((item) => item.value === socketUsb)?.value || "754"} />
      <Select
        onChange={(e) => {
          dispatch(setSocketUsb(e.target.value));
          dispatch(setSensor("250"));
          dispatch(setSensorFiyatı(0));
        }}
        value={socketUsb}
        sx={{ height: "2.2rem", flexGrow: "1", fontSize: "0.7rem" }}
      >
        {soketlerIcerdeOptions.map((item) => (
          <MenuItem value={item.value}>
            {item.label} {item.price <= 0 ? "" : "+ €" + item.price}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default Soketler;
