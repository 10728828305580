import React, { useState, useEffect } from "react";
import { Stack, Box, Typography, Avatar, MenuItem, Select } from "@mui/material";

import { setFrontBodyDecor } from "../../../store/cabinet";
import { useDispatch, useSelector } from "react-redux";

import { renklerKategoriliFront } from "../../../utils/data/data";
import { setDekorFiyatı } from "../../../store/cabinetFee";

const FrontGovdeDoku = () => {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("1091");

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Typography variant="caption" fontWeight={700} sx={{ maxWidth: "100px", minWidth: "100px", mr: "1rem" }}>
        Dekor Front
      </Typography>

      <input name="id[128]" value={selectedValue} />

      <Select
        defaultValue={"W1100_ST9.jpeg,0,1091"}
        sx={{
          width: "100%",
          height: "2.2rem",
          fontSize: 12,
          ".MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
        }}
        onChange={(e) => {
          const splitValue = e.target.value.split(",");
          const imageName = splitValue[0];
          const price = splitValue[1];
          const value = splitValue[2];

          dispatch(setFrontBodyDecor(imageName));
          dispatch(setDekorFiyatı(price));
          setSelectedValue(value);
        }}
      >
        {renklerKategoriliFront.map((item) => {
          const spliteItemTitle = item.title.split(" ");
          const imageName = spliteItemTitle[0] + "_" + spliteItemTitle[1] + ".jpeg";
          const imageLink = "./decors/" + imageName + "";
          const itemTitle = item.title + " €" + item.price;
          return (
            <MenuItem
              value={`${imageName},${item.price},${item.value}`}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {itemTitle}
              <Avatar
                alt={item.title}
                src={imageLink}
                sx={{
                  height: "1.5rem",
                  width: "1.5rem",
                  alignSelf: "flex-end",
                }}
              />
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
};

export default FrontGovdeDoku;
