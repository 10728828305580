import React from "react";
import { useGLTF } from "@react-three/drei";

const AnalogWatch = ({ position }) => {
  const { nodes, materials } = useGLTF("/rectangle-mirror-rev01.glb");

  return (
    <group scale={[1, 1, 1]} position={position} rotation={[Math.PI / 2, 0, 0]}>
      <mesh geometry={nodes.Analog_Clock_1.geometry} material={materials.siyah} />
      <mesh geometry={nodes.Analog_Clock_2.geometry} material={materials.wire_086086086} />
      <mesh geometry={nodes.Analog_Clock_3.geometry} material={materials.grimetal} />
    </group>
  );
};

export default AnalogWatch;
