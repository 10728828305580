const Header = (props) => {
  return (
    <div
      style={{
        height: "10%",
        display: "flex",
        alignItems: "center",
        padding: 15,
        borderBottom: "1px solid lightgray",
      }}
    >
      {props.children}
    </div>
  );
};
export default Header;
