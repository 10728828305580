import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { EnumMirrors, changeMirror } from "../store/mirrors";
import { EnumCabinetMirrors } from "../store/cabinetMirror";
import { EnumCabinets } from "../store/cabinet";

const CategoriesMenu = ({ setSelectedCategory, setFinalCategory, finalCategory, selectedCategory }) => {
  const categoriesDivElement = useRef();
  const dispatch = useDispatch();
  const changeView = () => {
    setTimeout(() => {
      categoriesDivElement.current.classList.add("hiddenView");
      setFinalCategory(selectedCategory);
    }, 500);
  };

  useEffect(() => {
    const rootElement = document.getElementById("root");
    if (finalCategory == null) {
      rootElement.classList.add("responsiveCategoryMenu");
    } else {
      rootElement.classList.remove("responsiveCategoryMenu");
    }
  }, [finalCategory]);

  return (
    <div className="categoriesContainer slide-bck-center" ref={categoriesDivElement}>
      <div className="categories">
        <fieldset className="checkbox-group">
          <div
            className="checkbox"
            onClick={() => {
              setSelectedCategory(1);
              dispatch(changeMirror(EnumMirrors.CHARON));
              changeView();
            }}
          >
            <label className="checkbox-wrapper">
              <input type="radio" name="category" className="checkbox-input" />
              <span className="checkbox-tile">
                <span className="checkbox-icon">
                  <img src="ikonlar/ikon_ayna.png" alt="" />
                </span>
                <span className="checkbox-label">Spiegel</span>
              </span>
            </label>
          </div>
          <div
            className="checkbox"
            onClick={() => {
              setSelectedCategory(2);
              dispatch(changeMirror(EnumCabinets.BHS006));
              changeView();
            }}
          >
            <label className="checkbox-wrapper">
              <input type="radio" name="category" className="checkbox-input" />
              <span className="checkbox-tile">
                <span className="checkbox-icon">
                  <img src="ikonlar/ikon_dolapdolap.png" alt="" />
                </span>
                <span className="checkbox-label">Schränke</span>
              </span>
            </label>
          </div>
          <div
            className="checkbox"
            onClick={() => {
              setSelectedCategory(3);
              dispatch(changeMirror(EnumCabinetMirrors.OXFORD));
              changeView();
            }}
          >
            <label className="checkbox-wrapper">
              <input type="radio" name="category" className="checkbox-input" />
              <span className="checkbox-tile">
                <span className="checkbox-icon">
                  <img src="ikonlar/ikon_aynadolap.png" alt="" />
                </span>
                <span className="checkbox-label">Spiegelschränke</span>
              </span>
            </label>
          </div>
        </fieldset>
      </div>
      {/* {selectedCategory && (
        <div className="categoriesButton" onClick={() => changeView()}>
          <p>Nach vorne</p>
          <svg className="svg-icon" width={20} height={20} viewBox="0 0 20 20">
            <path d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z"></path>
          </svg>
        </div>
      )} */}
    </div>
  );
};

export default CategoriesMenu;
