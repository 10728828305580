import { useGLTF, useTexture } from "@react-three/drei";
import React from "react";
import { useSelector } from "react-redux";
import * as THREE from "three";
const Wunschdekor = () => {
  const bodyTextureDefault = useTexture(`./decors/H1145_ST10.jpeg`);
  const { mirrorWidth, mirrorHeight } = useSelector((state) => state.mirror);
  const { bodyDecor } = useSelector((state) => state.mirror);

  const bodyTexture = useTexture(`./decors/${bodyDecor}`) || bodyTextureDefault;
  bodyTexture.rotation = Math.PI * 1.5;
  bodyTexture.repeat.set(0.2, 5);
  bodyTexture.wrapS = THREE.RepeatWrapping;
  bodyTexture.wrapT = THREE.RepeatWrapping;

  const horizontalFixer = (mirrorWidth - 400) / 1500;
  const verticalFixer = (mirrorHeight - 400) / 1500;

  return (
    <group position={[0.01, 0.43, -0.5]} scale={[0.8, 0.86, 1]}>
      <mesh scale-x={0.2} scale-y={0.03 + mirrorHeight * 0.003945} name="right" position={[0 + mirrorWidth * 0.00218, -0.73 + mirrorHeight * 0.000665, 0.6]}>
        <boxGeometry args={[0.11, 0.9, 0.11]} />
        <meshStandardMaterial map={bodyTexture} />
      </mesh>
      <mesh scale-x={0.2} scale-y={0.03 + mirrorHeight * 0.003945} name="left" position={[0 - mirrorWidth * 0.0021, -0.73 + mirrorHeight * 0.000665, 0.6]}>
        <boxGeometry args={[0.11, 0.9, 0.11]} />
        <meshStandardMaterial map={bodyTexture} />
      </mesh>
      <mesh scale-y={0.2} scale-x={0.02 + mirrorWidth * 0.0042475} name="top" position={[-0.02 + mirrorWidth * 0.000065, -0.725 + mirrorHeight * 0.00244, 0.6]}>
        <boxGeometry args={[1, 0.11, 0.11]} />
        <meshStandardMaterial map={bodyTexture} />
      </mesh>
      <mesh scale-y={0.2} scale-x={0.02 + mirrorWidth * 0.0042475} name="bottom" position={[-0.02 + mirrorWidth * 0.000065, -0.735 - mirrorHeight * 0.00111, 0.6]}>
        <boxGeometry args={[1, 0.11, 0.11]} />
        <meshStandardMaterial map={bodyTexture} />
      </mesh>
    </group>
  );
};

export default Wunschdekor;
